import React from 'react'
import { Form as FinalForm } from 'react-final-form'
import { RailsForm } from '@support/RailsForm'
import PropTypes from 'prop-types'

import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import Button from '@material-ui/core/Button'

import { validate } from '@models/analysis/analysis'
import httpService from '@services/httpService'
import AnalysesList from './AnalysesList'
import Form from './Form'
import Dialog from '../../ui/Dialog'
import styles from './styles'

class Index extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      dialog: {
        open: false,
        handler: () => { },
        title: '',
        values: {},
      },
      analyses: []
    }

    this.handleDialogToggle = this.handleDialogToggle.bind(this)

    this.handleNewAnalysis = this.handleNewAnalysis.bind(this)
    this.handleAddAnalysis = this.handleAddAnalysis.bind(this)

    this.handleAnalysisEdit = this.handleAnalysisEdit.bind(this)
    this.handleAnalysisUpdate = this.handleAnalysisUpdate.bind(this)

    this.handleAnalysisDelete = this.handleAnalysisDelete.bind(this)
  }

  componentDidMount() {
    this.setState({ analyses: this.props.analyses })
  }

  handleDialogToggle() {
    this.setState({
      dialog: {
        ...this.state.dialog,
        open: !this.state.dialog.open
      }
    })
  }

  handleAddAnalysis = async (values) => {
    const { handleError } = this.props

    try {
      const { data } = await httpService.post('soil_analyses', values)

      this.setState(
        { analyses: [...this.state.analyses, data] },
        this.handleDialogToggle
      )
    }
    catch (error) {
      handleError(error)
    }
  }

  handleAnalysisUpdate = async (values) => {
    const { handleError, o } = this.props

    try {
      const { data } = await httpService.put(`/v4/o/${o}/soil_analyses/${values.id}`, values)

      this.setState({
        analyses: this.state.analyses.map(analysis => {
          if (analysis.id === data.id) {
            return { ...analysis, ...data }
          }

          return analysis
        })
      }, this.handleDialogToggle)
    }
    catch (error) {
      handleError(error)
    }
  }

  handleNewAnalysis() {
    this.setState({
      dialog: {
        open: true,
        handler: this.handleAddAnalysis,
        title: I18n.t('v4/analysis.actions.add'),
        values: {},
      }
    })
  }

  handleAnalysisEdit(analysis) {
    this.setState({
      dialog: {
        open: true,
        handler: this.handleAnalysisUpdate,
        title: I18n.t('v4/analysis.actions.edit'),
        values: analysis,
      }
    })
  }

  handleAnalysisDelete = async (id) => {
    const { handleError, o } = this.props

    try {
      await httpService.delete(`/v4/o/${o}/soil_analyses/${id}`)
      this.setState({
        analyses: this.state.analyses.filter(analysis => analysis.id !== id)
      })
    }
    catch (error) {
      handleError(error)
    }
  }

  render() {
    const { classes, handleError, o } = this.props

    return (
      <div>
        <div className={classes.analysesList__newAnalysis}>
          <Typography>
            {I18n.t('activerecord.models.v4/analysis.other')}
          </Typography>
          <Button
            color='secondary'
            variant='outlined'
            onClick={this.handleNewAnalysis}
          >
            {I18n.t('v4/analysis.actions.add')}
          </Button>
        </div>

        <AnalysesList
          analyses={this.state.analyses}
          handleAnalysisEdit={this.handleAnalysisEdit}
          handleAnalysisDelete={this.handleAnalysisDelete}
          handleError={handleError}
          o={o}
        />

        <Dialog
          open={this.state.dialog.open}
          onClose={this.handleDialogToggle}
          title={this.state.dialog.title}
        >
          <RailsForm
            component={FinalForm}
            handleSubmit={this.state.dialog.handler}
            initialValues={this.state.dialog.values}
            validate={validate}
            render={(props) => (
              <Form
                {...props}
                onCancel={this.handleDialogToggle}
                action={this.state.dialog.title}
              />
            )}
          />
        </Dialog>
      </div>
    )
  }
}

Index.defaultProps = {
  analyses: []
}

Index.propTypes = {
  analyses: PropTypes.array
}

export default withStyles(styles)(Index)
