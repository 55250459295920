import React, { useState } from 'react'

import withStyles from '@material-ui/core/styles/withStyles'

import { useMenusStatus, MENUS } from '@contexts/MenusStatusProvider'
import { useSessionData } from '@contexts/SessionDataProvider'
import { useErrorHandler } from '@contexts/ErrorHandlerProvider'
import { useHttpService } from '@contexts/HttpServiceProvider'

import Selector from './Selector'
import LatLongForm from './LatLongForm'
import FpNameForm from './FpNameForm'

import {
  isValidLatValue,
  isValidLongValue
} from '@models/map'

const SetupNewForecastPoint = ({ addPin, classes, selectedForecast, updateForecastList }) => {
  const { currentOrganization } = useSessionData()
  const { menus, setMenuData, toggleMenu } = useMenusStatus()
  const { apiRequest } = useHttpService()
  const handleError = useErrorHandler()

  const { step, onFlow, data } = menus[MENUS.weatherForecastProduct]
  const menuData =  { ...data }

  const [state, setState] = useState({
    currentStep: 0,
    latValue: '',
    lngValue: '',
    invalidLat: false,
    invalidLng: false,
    fpNameValue: '',
    invalidName: false
  })

  const changeContext = (field, value) => {
    setMenuData(MENUS.weatherForecastProduct, field, value)
  }

  const handleCoordinatesClick = () => {
    setState({
      ...state,
      currentStep: 1
    })

    changeContext('step', 1)
    changeContext('onFlow', true)
  }

  const onLatLngChange = type => event => {
    setState({
      ...state,
      [type]: event.target.value
    })
  }

  const onFpNameChange = event => {
    setState({
      ...state,
      fpNameValue: event.target.value
    })
  }

  const latLngValidator = () => {
    const isInvalidLat = _.isEmpty(state.latValue) || !isValidLatValue(Number(state.latValue))
    const isInvalidLng = _.isEmpty(state.lngValue) || !isValidLongValue(Number(state.lngValue))
    setState({
      ...state,
      invalidLat: isInvalidLat,
      invalidLng: isInvalidLng
    })

    if(!isInvalidLat && !isInvalidLng) {
      menuData.lat = state.latValue
      menuData.lon = state.lngValue
      changeContext('data', menuData)
      addPin([state.latValue, state.lngValue])
    }
  }

  const changesOnMapFlow = (step) => {
    toggleMenu(MENUS.weatherForecastSetupFixedModal, true)
    changeContext('onFlow', false)
    changeContext('step', step)
  }

  const onBackHandler = () => {
    const clickFlow = menus[MENUS.weatherForecastProduct].onMapFlow
    const searchFlow = menus[MENUS.weatherForecastProduct].onSearchFlow

    if(clickFlow) {
      return changesOnMapFlow(1)
    }

    if(searchFlow) {
      return changesOnMapFlow(2)
    }

    changeContext('onFlow', true)
    changeContext('step', 1)
  }

  const handleSave = () => {
    const invalidName = _.isEmpty(state.fpNameValue.trim())
    setState({ ...state, invalidName })

    if(!invalidName) {
      handleSubmit({
          ...data,
          name: state.fpNameValue
        },
        selectedForecast,
        currentOrganization.id
      )
    }
  }

  const handleSubmit = async (values, forecast, organizationId) => {
    try {
      const updatedForecast = await apiRequest.put(`/forecasts/points/${forecast.id}`, values)
      updateForecastList(updatedForecast)
      changeContext('onFlow', false)
    }
    catch(error) {
      handleError(error)
    }
  }

  const getStep = (currentStep) => {
    const actualStep = onFlow ? step : currentStep

    switch (actualStep) {
      case 0:
        return <Selector onCoordinatesClick={handleCoordinatesClick}/>
      case 1:
        return (
          <LatLongForm
           onLatLngChange={onLatLngChange}
           latValue={state.latValue}
           lngValue={state.lngValue}
           validator={latLngValidator}
           isValidLat={!state.invalidLat}
           isValidLng={!state.invalidLng}
         />
        )
      case 2:
        return (
          <FpNameForm
            fpNameValue={state.fpNameValue}
            onFpNameChange={onFpNameChange}
            onBackHandler={onBackHandler}
            onSave={handleSave}
            invalidName={state.invalidName}
            setupData={menuData}
          />
        )
      default: <Selector onCoordinatesClick={handleCoordinatesClick}/>
    }
  }

  return (
    <div className={classes.Body_wrapper}>
      {getStep(state.currentStep)}
    </div>
  )
}

const styles = (theme) => ({
  Body_wrapper: {
    padding: `0 ${theme.spacing.unit * 2 + 4}px`,
    maxWidth: 352
  }
})

export default withStyles(styles)(SetupNewForecastPoint)
