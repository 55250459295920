import React from 'react'

import { Typography } from '@material-ui/core'

const Header = () =>
  <Typography variant='body1'>
    {I18n.t('v4/equipment.card.view_the_cumulative')}
  </Typography>

export default Header
