import React from 'react'

import { Typography, withStyles } from '@material-ui/core'

import InfoIcon from '@material-ui/icons/Info'

import renderHTML from 'react-render-html'

const ExpiredPhase = ({ classes, phaseName, isCropLastStage }) =>
  <div className={classes.ExpiredPhase_wrapper}>
    <InfoIcon className={classes.ExpiredPhase_info} />

    <Typography
      variant='body1'
      className={classes.ExpiredPhase_info}
    >
      {renderHTML(I18n.t(isCropLastStage ? 'v4/area.dashboard.card.harvest_delayed' : 'v4/area.dashboard.card.delayed_cultivation', { phase: phaseName }))}
    </Typography>
  </div>

const styles = theme => ({
  ExpiredPhase_wrapper: {
    display: 'flex',
    alignItems: 'center',
    marginLeft: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    gap: '2px'
  },
  ExpiredPhase_info: {
    color: theme.palette.red,
    marginRight: theme.spacing.unit
  }

})

export default withStyles(styles)(ExpiredPhase)
