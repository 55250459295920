import React from 'react'

import httpService from '@services/httpService'

import Aqua from '@images/area/aqua.svg'
import Harvest from '@images/area/harvest.svg'
import NoAlert from '@images/area/no_alert.svg'
import Alert from '@images/area/with_alert.svg'
import PlantedAt from '@images/area/planted_at.svg'
import PlantedCulture from '@images/area/planted_culture.svg'
import LeafMoisture from '@images/area/leaf_moisture.svg'
import View from '@images/area/view.svg'

import moment from 'moment'

import { getManagementStatus } from '@models/irrigation_managements/format'

export const headers = [
  { field: 'name' },
  {
    field: 'size_ha',
    readonly: true
  },
  {
    field: 'crop_name'
  },
  {
    field: 'stage'
  }
]

export const getAreaHeaders = () =>
  headers.map(({ field, ...options }) => ({
    title: I18n.t(`activerecord.attributes.v4/area.${field}`),
    field, ...options
  }))

export const validate = values => {
  const requiredFields = ['name']
  const errors = {}

  requiredFields.forEach(field => {
    if (!values[field]) errors[field] = I18n.t('validations.required')
  })

  return errors
}

export const areEqual = (areaA = {}, areaB = {}) => areaA.id === areaB.id

export const areNotEqual = (areaA, areaB) => !areEqual(areaA, areaB)

export const handleMultipleAreasSave = async (areas = [], handleError, o) => {
  areas = {
    areas: areas.map(
      ({ name, size_ha, center, kind, coordinates, latitude, longitude }) =>
        ({ name, size_ha, center, kind, coordinates, latitude, longitude })
    )
  }

  try {
    await httpService.post(`/v4/o/${o}/areas/bulk_create`, areas)

    Turbolinks.visit(`/v4/o/${o}/maps`)
  }
  catch (error) {
    handleError(error)
  }
}

export const deserialize = (areas = []) =>
  areas.map(area => ({
    ...area,
    size_ha: Number(area.size_ha),
    crop_name: !_.isEmpty(area.crop) && area['crop'].name,
    stage: !_.isEmpty(area.crop) && area['crop'].current_stage.name
  }))

export const Icon = ({ variant }) =>
  iconsMap[variant]
    ? <img
      style={{ width: '100%', height: '100%' }}
      src={iconsMap[variant]}
      alt={variant}
    />
    : null

const iconsMap = {
  aqua: Aqua,
  expected_harvest_date: Harvest,
  noAlert: NoAlert,
  alert: Alert,
  planting_date: PlantedAt,
  name: PlantedCulture,
  expected_phase_change: LeafMoisture,
  view: View
}

export const getHydricStatus = (data = {}) => {
  const status = getManagementStatus(data)
  if (!status.status) {
    status.status = 'no_data'
  }
  const needIrrigation = data.demanded_irrigation > 0

  return {
    title: I18n.t(`v4/irrigation_management.hydric_status.${status.status}.title`),
    value: status.need_irrigation
      ? I18n.t(`v4/irrigation_management.hydric_status.${status.status}.title_reduced`)
      : needIrrigation
        ? I18n.t(`v4/irrigation_management.hydric_status.${status.status}.irrigate`)
        : I18n.t(`v4/irrigation_management.hydric_status.ideal_moisture.irrigate`)
  }
}

export const getProgressBarStatus = (data = {}) => {
  if (!data.started_at || !data.expected_stage_finish) return 0

  const start = moment(data.started_at)
  const finish = moment(data.expected_stage_finish)
  const today = moment()

  const totalDays = finish.diff(start, 'days')

  if (totalDays === 0) return 0

  const daysFromStartToToday = today.diff(start, 'days')

  const percentStatus = daysFromStartToToday / totalDays * 100

  return percentStatus > 100 ? 100 : percentStatus
}

export const validateFormCrop = values => {
  const requiredFields = ['culture_id', 'planting_date']
  const errors = {}

  requiredFields.forEach(field => {
    if (!values[field]) errors[field] = I18n.t('validations.required')
  })
  return errors
}

export const getStartAndFinishDateFilter = () => {
  const currentDate = new Date()
  currentDate.setMinutes(0)

  const finish = currentDate.toISOString()

  currentDate.setHours(0)
  currentDate.setDate(currentDate.getDate() - 30)

  const start = currentDate.toISOString()

  return {
    start_date: start,
    end_date: finish
  }
}

const options = I18n.t('v4/area.dashboard.card.select')

export const units = Object.keys(options).map(option => ({ id: options[option], value: options[option] }))

export const kinds = [
  { name: I18n.t('activerecord.models.v4/product.other'), id: 0 },
  { name: I18n.t('activerecord.models.v4/crop.other'), id: 1 }
]