import React from 'react'
import { Form as FinalForm } from 'react-final-form'
import PropTypes from 'prop-types'

import { Button, Paper, Typography, Grid, Divider } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'

import styles from '@theme/styles/forms'
import { apiService } from '@services/httpService'
import { validate, perennialOptions } from '@models/culture'
import { updateStagesOrder } from '@models/phenologicalStages'
import { RailsForm } from '@support/RailsForm'
import Form from './Form'
import SearchSelect from '../ui/Fields/SearchSelect'

import { withSessionData } from '@contexts/SessionDataProvider'

class CultureForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedTemplate: null,
      initialValues: {},
      phenologicalStages: [],
      edited: false,
      loading: false
    }
  }

  componentDidMount() {
    const { template_culture } = this.props.initialValues
    let selectedTemplate = null

    if (template_culture) selectedTemplate = {
      label: template_culture.name,
      value: template_culture.id
    }

    this.setState({
      initialValues: this.props.initialValues,
      selectedTemplate,
      phenologicalStages: _.sortBy(this.props.initialValues.phenological_stages, ['order'])
    })
  }

  stagesHandlers = {
    new: (values) => this.setState({
      phenologicalStages: [...this.state.phenologicalStages, values],
      edited: true
    }),
    edit: (values) => this.setState({
      phenologicalStages: this.state.phenologicalStages.map(stage =>
        stage.order === values.order ? { ...stage, ...values } : stage
      ),
      edited: true
    }),
    delete: (order) => this.setState({
      phenologicalStages: this.state.phenologicalStages.filter(stage =>
        stage.order !== order
      ).map(updateStagesOrder),
      edited: true
    }),
    changeOrder: (fromIndex, toIndex) => {
      const stages = this.state.phenologicalStages
      const item = stages.splice(fromIndex, 1)[0]
      if (item !== undefined) {
        stages.splice(toIndex, 0, item)
        this.setState({
          phenologicalStages: stages.map(updateStagesOrder),
          edited: true
        })
      }
    }
  }

  handleSelectTemplate = (template) =>
    this.setState({ selectedTemplate: template })

  handleLoadTemplate = async () => {
    const { selectedTemplate } = this.state
    const { handleError, o, sessionData } = this.props
    const { currentOrganization } = sessionData

    if (selectedTemplate) {
      confirmDialog(async () => {
        this.setState({ loading: true })
        try {
          const params = {
            id: selectedTemplate.value,
            organization_id: currentOrganization.id
          }

          const { data } = await apiService().get(`/api/v4/template_cultures`, { params })

          this.setState({
            initialValues: {
              ...data,
              template_culture_id: selectedTemplate.value,
            },
            phenologicalStages: _.sortBy(data.phenological_stages, ['order']),
            loading: false
          })
        }
        catch (error) {
          handleError(error)
          this.setState({ loading: false })
        }
      }, {
        title: I18n.t('v4/culture.load_default_parameters'),
        content: I18n.t('v4/culture.confirm_template')
      })
    }
  }

  render() {
    const {
      cultivars,
      classes,
      cultureTemplates,
      title,
      url,
      action,
      successUrl,
      hideBackButton,
      handleSubmit,
      hideHeader,
      o
    } = this.props
    return (
      <Paper className={classes.form__wrapper} elevation={0}>
        <header className={classes.form__header}>
          {
            hideHeader || (
              <Typography component='h2' variant='h5'>
                {title}
              </Typography>
            )
          }
        </header>

        <Grid
          className={classes.form__headerWithFields}
          container
          spacing={24}
        >
          <Grid item xs={7}>
            <SearchSelect
              fullWidth
              dataTestid='template_culture_id'
              margin='normal'
              data={_.sortBy(cultureTemplates, ['name'])}
              className={classes.form__fieldWithError}
              fields={{ label: 'name', value: 'id' }}
              variant='outlined'
              isClearable
              usePaperClass={true}
              placeholder={I18n.t('actions.search')}
              autocomplete={true}
              textFieldProps={{
                label: I18n.t('v4/culture.template'),
                InputLabelProps: {
                  shrink: true,
                }
              }}
              handleChange={this.handleSelectTemplate}
              value={this.state.selectedTemplate}
            />
          </Grid>

          <Grid item xs='auto'>
            <Button
              className={classes.form__action}
              color='primary'
              type='submit'
              variant='outlined'
              onClick={this.handleLoadTemplate}
              disabled={this.state.loading}
            >
              {I18n.t('v4/culture.new.load_template')}
            </Button>
          </Grid>
        </Grid>

        <Divider variant='middle' />

        <RailsForm
          component={FinalForm}
          action={action}
          url={url}
          handleSubmit={handleSubmit}
          successUrl={successUrl}
          initialValues={this.state.initialValues}
          validate={validate}
          render={(props) => (
            <Form
              {...props}
              classes={classes}
              perennialOptions={perennialOptions()}
              cultivars={cultivars}
              stages={this.state.phenologicalStages}
              stagesHandlers={this.stagesHandlers}
              action={title}
              hideBackButton={hideBackButton}
              edited={this.state.edited}
              o={o}
            />
          )}
        />
      </Paper>
    )
  }
}

CultureForm.propTypes = {
  initialValues: PropTypes.object,
  cultivars: PropTypes.array,
  cultureTemplates: PropTypes.array,
  title: PropTypes.string,
  url: PropTypes.string,
  action: PropTypes.string,
  successUrl: PropTypes.string
}

export default withSessionData(withStyles(styles)(CultureForm))
  

