import React from 'react'
import { Form as FinalForm } from 'react-final-form'
import { RailsForm } from '@support/RailsForm'
import InputForm from '../Form'
import CultivarForm from './Form'
import { validate, cultivarShape } from '@models/inputs/cultivar'

const EditCultivar = ({ model, o }) => {
  const {
    name, brand, category, cultivar, detail, kind
  } = model

  return (
    <RailsForm
      component={FinalForm}
      action='update'
      initialValues={{
        name, brand, category, cultivar, detail, kind
      }}
      url={`/v4/o/${o}/inputs/cultivar/${model.id}`}
      successUrl={`/v4/o/${o}/inputs/cultivar/${model.id}`}
      validade={validate}
      render={(props) => (
        <InputForm
          title={`${I18n.t('actions.edit')} ${I18n.t(`v4/input.kinds.${kind}`)}`}
        >
          <CultivarForm
            {...props}
            action={I18n.t('actions.update')}
            o={o}
          />
        </InputForm>
      )}
    />
  )
}

EditCultivar.propTypes = {
  model: cultivarShape
}

export default EditCultivar
