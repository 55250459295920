import React, { Component } from 'react'

import Typography from '@material-ui/core/Typography'
import { WiDaySunny, WiThermometer, WiStrongWind, WiRaindrop } from 'weather-icons-react'
import { Grid } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'

import withStyles from '@material-ui/core/styles/withStyles'

import styles from './styles'

class Resume extends Component {
  render() {
    const { classes, pcpMaxTotal, pcpMinTotal, daysWithRain, greaterVol } = this.props
    return (
      <Paper className={classes.forecast_weather_resume}>
        <div className={classes.forecast__header}>
          <Typography>
            <span className={classes.forecast__title}>
              {I18n.t('v4/forecast.summary')}
            </span>
          </Typography>
        </div>

        <div className={classes.forecast__resume__body}>
          <Typography className={classes.forecast__resume__h2}>
            <span>{I18n.t('v4/forecast.rain_total')}</span>
          </Typography>
          <Typography className={classes.forecast__resume__h3}>
            <span>{pcpMinTotal} - {pcpMaxTotal} mm</span>
          </Typography>

          <Typography className={classes.forecast__resume__h2}>
            <span>{I18n.t('v4/forecast.days_with_rain')}</span>
          </Typography>
          <Typography className={classes.forecast__resume__h3}>
            <span>{`${daysWithRain} ${daysWithRain > 1 ? I18n.t('v4/forecast.days') : I18n.t('v4/forecast.day')}`}</span>
          </Typography>

          <Typography className={classes.forecast__resume__h2}>
            <span>{I18n.t('v4/forecast.rainfall')}</span>
          </Typography>
          <Typography className={classes.forecast__resume__h3}>
            <span>{greaterVol} mm</span>
          </Typography>
        </div>
      </Paper>
    )
  }
}

export default withStyles(styles)(Resume)
