import React, { useRef } from 'react'

import Table from '@ui/Table'
import TableCell from './TableCell'
import { EditAction, GraphicAction, RemoveAction } from './Actions'
import Filter from '../Filter'

import { alphaSorting, notAlphaSorting } from '@models/sorting'

import { useRouter } from '@contexts/RouterProvider'

export const CustomTable = ({
  orderBy,
  describer,
  sortedData,
  getManagementStatusButton,
  setSortedData,
  availableDates,
  handleOpenDialog,
  running
}) => {
  const tableRef = useRef(null)
  const router = useRouter()

  const setTablePage = () => !!tableRef.current && tableRef.current.dataManager.changeCurrentPage(0)

  const handleTableFilter = (values) => {
    
    const finalSortedData = running.content[values.date]

    if(values.orderBy) {
      if (values.orderBy === 'alpha') {
        finalSortedData.sort((a, b) => alphaSorting(a, b, values))
      } else {
        finalSortedData.sort((a, b) => notAlphaSorting(a, b, values))
      }
    }
    setTablePage()

    
    setSortedData(values)
  }

  return (
    <>
      <Filter
        orderBy={orderBy}
        availableDates={availableDates}
        setSortedData={handleTableFilter}
      />

      <Table
        tableRef={tableRef}
        key='equipment-table'
        components={{
          Container: props => <div>{props.children}</div>,
          Cell: props => <TableCell {...props} />
        }}
        columns={describer}
        data={sortedData}
        options={{
          showTitle: false,
          search: false
        }}
        actions={[
          rowData => GraphicAction(rowData, router),
          rowData => EditAction(rowData, router),
          rowData => getManagementStatusButton(rowData),
          rowData => RemoveAction(rowData, id => handleOpenDialog(id))
        ]}
        tableKey='irrigationManagementIndexTable'
      />
    </>
  )
}
