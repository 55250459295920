import React from 'react'

import {
  Typography,
  withStyles
} from '@material-ui/core'

const PreviewMode = ({ classes, selected, label }) =>
  <Typography
    variant='body2'
    className={
      selected
        ? classes.PreviewMode_selected
        : classes.PreviewMode_disabled
    }
  >
    {label}
  </Typography>

const styles = theme => ({
  PreviewMode_selected: {
    fontSize: '13px',
    color: theme.palette.primary.main
  },
  PreviewMode_disabled: {
    fontSize: '13px',
    color: theme.palette.gray.dark
  }
})

export default withStyles(styles)(PreviewMode)
