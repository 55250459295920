import React from 'react'

import TelephoneMaterialField from 'material-ui-phone-number'

const TelephoneField = ({
  input,
  meta: { touched, error },
  ...props 
}) => {
  return (
    <TelephoneMaterialField 
      error={!!(touched && error)}
      helperText={touched && error}
      {...props}
      {...input}
      defaultCountry='br'
    />
  )
}

export default TelephoneField
