import React, { Component } from "react";

import Table from "@ui/Table";

import Filter from "../Filter";
import Cell from "./CustomCellComponents";

import { Button, withStyles } from "@material-ui/core";
import ExportTableIcon from "@material-ui/icons/SaveAlt";

import { MTableToolbar } from "material-table";

import { exportTable } from "@models/irrigation_managements";

class Planning extends Component {
  constructor(props) {
    super(props);

    this.state = {
      sortedData: [],
    };
  }

  componentDidMount() {
    const { data } = this.props;

    this.setState({ sortedData: data });
  }

  sorting(a, b, sortDescriber, selectedDate) {
    if (!a[selectedDate]) {
      return 1;
    }
    if (!b[selectedDate]) {
      return -1;
    }

    const aValue = a[selectedDate][sortDescriber.orderBy];
    const bValue = b[selectedDate][sortDescriber.orderBy];

    return sortDescriber.order === "up" ? aValue - bValue : bValue - aValue;
  }

  areaSorting(a, b, sortDescriber) {
    const aValue = a.area;
    const bValue = b.area;

    if (aValue < bValue) {
      return sortDescriber.order === "up" ? -1 : 1;
    }
    if (aValue > bValue) {
      return sortDescriber.order === "up" ? 1 : -1
    }
    return 0;
  }

  sortData(sortDescriber) {
    const { data, dates } = this.props;
    const { sortedData } = this.state;

    if (sortedData.length > 0) {
      const selectedDate = dates[sortDescriber.date].field;

      if (sortDescriber.orderBy) {
        const newlySortedData =
          sortDescriber.orderBy === "area"
            ? data.sort((a, b) => this.areaSorting(a, b, sortDescriber))
            : data.sort((a, b) =>
                this.sorting(a, b, sortDescriber, selectedDate)
              );
        this.setState({ sortedData: newlySortedData });
      }
    }
  }

  render() {
    const { classes, dates, describer, fields } = this.props;
    const { sortedData } = this.state;
    const orderBy = [
      { field: "area", name: I18n.t("v4/irrigation_management.alpha") },
      ...fields,
    ];

    return (
      <div>
        <Filter
          availableDates={dates}
          orderBy={orderBy.filter((datum) => datum.field !== "hydricStatus")}
          setSortedData={(data) => this.sortData(data)}
          disabled={_.isEmpty(sortedData)}
          hiddenFields={["showFinishedManagements"]}
        />

        <Table
          id="planning-table"
          key="equipment-table"
          components={{
            Container: (props) => <div>{props.children}</div>,
            Cell: (props) => <Cell variablesFields={fields} {...props} />,
            Toolbar: (props) => (
              <div className={classes.Planning_wrapper_action}>
                <MTableToolbar {...props} />

                <Button
                  className={classes.Planning_action}
                  title={I18n.t("v4/equipment.dashboard.table.export_table")}
                  onClick={() => exportTable(sortedData)}
                >
                  <ExportTableIcon />
                </Button>
              </div>
            ),
          }}
          columns={describer}
          data={sortedData}
          options={{
            headerStyle: HeaderStyle,
            search: false,
            showTitle: false,
            sorting: false,
          }}
          tableKey="irrigationManagementPlanningTable"
        />
      </div>
    );
  }
}

const HeaderStyle = { padding: "4px 16px 4px 24px" };

Planning.defaultProps = {
  data: [],
  dates: [],
  fields: [],
};

const styles = (theme) => ({
  Planning_wrapper_action: {
    display: "flex",
    justifyContent: "flex-end",
  },
  Planning_action: {
    margin: "20px",
    minWidth: 0,
    padding: "10px",
  },
});

export default withStyles(styles)(Planning);
