import React, { Component } from 'react'
import { withSnackbar } from 'notistack'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

import Points from "./Points"
import httpService from "@services/httpService"
import styles from '@theme/styles/forms'

class Form extends Component {
  constructor(props) {
    super(props)

    this.state = {
      points: [],
      deletedPoints: [],
      selectedPoint: null,
    }
  }

  selectPoint(selectedPoint) {
    this.setState({ selectedPoint })
  }

  handleNewForecastPoint(forecastPoint) {
    this.setState(st => {
      forecastPoint.action = ACTIONS.CREATE
      st.points.push(forecastPoint)
      return { points: st.points }
    })
  }

  handleEditForecastPoint(forecastPoint, reset) {
    this.setState(st => {

      if (!reset && compareForecastPoints(forecastPoint, st.points[forecastPoint.index])) {
        return { ...st }
      }

      if (!!forecastPoint.id) {
        forecastPoint.action = ACTIONS.UPDATE
      }

      let newForecastPoint = {
        ...st.points[forecastPoint.index],
        ...forecastPoint
      }

      if (reset) {
        newForecastPoint.lat = null
        newForecastPoint.lon = null
        newForecastPoint.name = null
        newForecastPoint.forecast_machine_id = null,
        newForecastPoint.state = null,
        newForecastPoint.country = null
      }

      const index = forecastPoint.tableData ? forecastPoint.tableData.id : forecastPoint.index

      st.points[index] = newForecastPoint

      return { points: st.points }
    })
  }

  handleDeleteForecastPoint(fpIndex) {
    this.setState(st => {
      let point = st.points[fpIndex]

      if (!!point.id) {
        point.action = ACTIONS.DELETE
        st.deletedPoints.push(point)
      }

      st.points = st.points.filter((f, index) => index !== fpIndex)
      return { points: st.points, deletedPoints: st.deletedPoints }
    })
  }

  handlers = {
    new: fp => this.handleNewForecastPoint(fp),
    edit: (fp, reset) => this.handleEditForecastPoint(fp, reset),
    delete: fp => this.handleDeleteForecastPoint(fp),
    error: err => this.props.enqueueSnackbar(err, { variant: 'error' })
  }

  handleSubmit() {
    let { organization, organization_product, o } = this.props
    let { points, deletedPoints } = this.state
    let errPoints = []

    points = points.filter(p => p.action)

    let actionPoints = [...points, ...deletedPoints]

    Promise.all(
      actionPoints.map(p => checkActions(p)
        .then(res => {
          switch (p.action) {
            case ACTIONS.CREATE:
              return httpService.post(`/v4/o/${o}/admin/organizations/${organization.id}/products/${organization_product.id}/forecast/points`, p)
            case ACTIONS.UPDATE:
              return httpService.put(`/v4/o/${o}/admin/organizations/${organization.id}/products/${organization_product.id}/forecast/points/${p.id}`, p)
            default:
              return httpService.delete(`/v4/o/${o}/admin/organizations/${organization.id}/products/${organization_product.id}/forecast/points/${p.id}`)
          }
        })
        .then(res => res)
        .catch(err => errPoints.push(p))
      )
    )
      .then(res => {
        errPoints.length > 0
          ? this.props.enqueueSnackbar(I18n.t('v4/organization.products.forecast.forecast_point.new_error'), { variant: 'error' })
          : Turbolinks.visit(`/v4/o/${o}/admin/organizations/${organization.id}/products`)
      })
  }

  componentDidMount() {
    const forecastList = this.props.model
    const points = forecastList.map((forecast) => {

      if (!forecast.forecast_machine_id) {
        const lat = null
        const lon = null
        const name = null
        return { ...forecast, lat, lon, name }
      }
      return forecast
    })

    this.setState({ points: points })
  }

  render() {
    let { classes, organization, o } = this.props
    let { points } = this.state

    return (
      <Paper className={classes.form__larger__wrapper} elevation={0}>
        <header className={classes.form__header}>
          <Typography component='h2' variant='h5'>
            {organization.name} - {I18n.t('v4/organization.products.forecast.title')}
          </Typography>
        </header>
        <main style={{ marginTop: "16px", marginBottom: "8px" }}>
          <Points
            points={points}
            handlers={this.handlers}
            selectPoint={fp => this.selectPoint(fp)}
          />
        </main>
        <footer className={classes.form__actions}>
          <Button
            className={classes.form__action}
            color='primary'
            onClick={() => this.handleSubmit()}
            variant='contained'
          >
            {I18n.t('actions.save')}
          </Button>

          <Button
            className={classes.form__action}
            color='secondary'
            href={`/v4/o/${o}/admin/organizations/${organization.id}/products`}
          >
            {I18n.t('actions.cancel')}
          </Button>
        </footer>
      </Paper>
    )
  }
}

const ACTIONS = {
  CREATE: 1,
  UPDATE: 2,
  DELETE: 3
}

const checkActions = ({ action }) =>
  new Promise((res, rej) =>
    action ? res() : rej())

const compareForecastPoints = (fp1, fp2) => {
  return (fp1.country === fp2.country
    && fp1.state === fp2.state
    && fp1.lat === fp2.lat
    && fp1.lon === fp2.lon
    && fp1.name === fp2.name)
}

export default withSnackbar(
  withStyles(styles)(Form)
)
