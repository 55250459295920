import React from 'react'

import { Typography, withStyles } from '@material-ui/core'

const CardBottomPanel = ({ hydricStatus, classes, needIrrigation }) => {
  const containerClass = `${classes.BottomPanel}
                          ${classes[`BottomPanel__${hydricStatus || 'no_data'}`]}`

  return (
    <div className={containerClass}>
      { hydricStatus && (
        <Typography variant='body1' className={classes.BottomPanel_Content}>
          {I18n.t(`v4/irrigation_management.hydric_status.${hydricStatus}.title`)}
        </Typography>
      )}

      <Typography variant='body2' className={classes.BottomPanel_Content}>
        {
          hydricStatus
            ? needIrrigation
              ? I18n.t(`v4/irrigation_management.hydric_status.${hydricStatus}.irrigate`)
              : ''
            : I18n.t(`v4/irrigation_management.hydric_status.no_data.title`)
        }
      </Typography>
    </div>
  )
}

const styles = theme => ({
  BottomPanel: {
    position: 'absolute',
    top: '100%',
    textAlign: 'center',
    width: '86%',
    zIndex: '-100',
    borderRadius: `0 0 ${theme.spacing.unit}px ${theme.spacing.unit}px`,
    paddingTop: theme.spacing.unit * 0.5,
  },
  BottomPanel_Content: {
    margin: theme.spacing.unit,
    color: theme.palette.white
  },
  BottomPanel__saturated: {
    background: theme.palette.irrigationManagement.blue
  },
  BottomPanel__ideal_moisture: {
    background: theme.palette.irrigationManagement.green
  },
  BottomPanel__alert_moisture: {
    background: theme.palette.irrigationManagement.yellow
  },
  BottomPanel__hydric_deficit: {
    background: theme.palette.irrigationManagement.red
  },
  BottomPanel__no_data: {
    background: theme.palette.irrigationManagement.gray
  },
})

export default withStyles(styles)(CardBottomPanel)
