import React from 'react'
import { Typography } from '@material-ui/core'
import PropTypes from 'prop-types'
import { convertToLocaleDate } from '@services/locale'

const SampleCard = ({ classes, data, handleClick, selected }) =>
  <div
    className={`${classes.SampleCard} ${selected && classes.SampleCard__selected}`}
    onClick={handleClick}
  >
    <img className={classes.SampleCard__image} alt={data.date} src={data.image} />

    <Typography component='p'>
      {convertToLocaleDate(data.date)}
    </Typography>

    <Typography component='p'>
      {data.clouds_percentage}
    </Typography>
  </div>

SampleCard.proptypes = {
  data: PropTypes.shape({
    image: PropTypes.string,
    date: PropTypes.string,
  })
}

export default SampleCard
