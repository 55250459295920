import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import { themeProviderWrapper } from '@contexts/ThemeProvider'

import styles from './styles'

const Index = ({ title, subtitle, error, logo_url, info, classes }) =>
  <div className={classes.Error__wrapper}>
    <img
      src={logo_url || '/rails-errors/logo_verde.png'}
      className={classes.Error__logo}
    />
    <h1 className={classes.Error__number}>
      {error}
    </h1>

    <div className={classes.Error__content}>
      <div className={classes.Error__content_message}>
        <h1 className={classes.Error__message}>
          {title}
        </h1>
        {subtitle && <p>{subtitle}</p>}
      </div>
      <p className={classes.Error__info}>
        {info}
      </p>
    </div>
  </div>

Index.defaultProps = {
  title: I18n.t('error_pages.500.title'),
  info: I18n.t('error_pages.500.info'),
  error: 500
}

export default themeProviderWrapper(
  withStyles(styles)(Index)
)
