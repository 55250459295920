import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { useHttpService } from '@contexts/HttpServiceProvider'
import { useSessionData } from '@contexts/SessionDataProvider'

import Maps from './Maps'
import { getCenterCoordinates } from '@models/map'

const Comparison = ({ google_maps_api_key }) => {
  const { apiRequest } = useHttpService()
  const sessionData = useSessionData()
  const { currentOrganization } = sessionData
  const [areas, setAreas] = useState([])
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    initializeGoogleMapsScript(google_maps_api_key)

    fetchAreas()
  }, [])

  const initializeGoogleMapsScript = apiKey => {
    const googleMapsScriptId = 'google-maps-script'

    if (!document.querySelector(`#${googleMapsScriptId}`)) {
      const script = document.createElement('script')
      script.id = googleMapsScriptId
      script.async = true
      script.defer = true
      script.type = 'text/javascript'
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`

      return document.head.appendChild(script)
    }
  }

  const fetchAreas = async () => {
    const params = { organization_id: currentOrganization.id }

    try {
      const { data } = await apiRequest('areas', { params })
      setAreas(data)
    }
    catch (error) {
      console.error(error)
    }

    setLoading(false)
  }

  return (
    <Maps
      loading={loading}
      areas={areas}
      {...getCenterCoordinates(areas)}
    />
  )
}

Comparison.defaultProps = {
  key: ''
}

Comparison.propTypes = {
  key: PropTypes.string
}

export default Comparison
