import React from 'react'
import PropTypes from 'prop-types'
import { Form as FinalForm } from 'react-final-form'
import { validate, activeValues } from '@models/admin/organizations/alerts'
import {AlertCategories} from '@models/alerts'
import { RailsForm } from '@support/RailsForm'
import Form from './Form'
import httpService from '@services/httpService'

const handleSubmit = async (values, handleError, o) => {
  try {
    const payload = {
      name: values.name,
      active: values.active,
      category: values.category
    }

    if (values.notification_interval) payload['notification_interval'] = values.notification_interval
    const custom_messages = {}
    if (values.custom_message_en) custom_messages['en'] = values.custom_message_en
    if (values.custom_message_es) custom_messages['es-MX'] = values.custom_message_es
    if (values.custom_message_pt) custom_messages['pt-BR'] = values.custom_message_pt
    if (custom_messages) payload['custom_messages'] = custom_messages
    
    const {data} = await httpService.put(`/v4/o/${o}/admin/organizations/${values.organization_id}/alerts/${values.id}`, payload)

    Turbolinks.visit(`/v4/o/${o}/admin/organizations/${data.organization_id}/alerts`)
  }
  catch(error){
    handleError(error)
  }
}

export const Edit = ({ model, handleError, o }) => {

  const initialValues = {
    ...model,
    custom_message_en: model.custom_messages?.['en'],
    custom_message_es: model.custom_messages?.['es-MX'],
    custom_message_pt: model.custom_messages?.['pt-BR']
  };

  return <RailsForm
    component={FinalForm}
    action='update'
    url={`/v4/o/${o}/admin/organizations/${model.organization_id}/alerts/${model.id}`}
    successUrl={`/v4/o/${o}/admin/organizations/${model.organization_id}/alerts`}
    validate={validate}
    handleSubmit={(values) => handleSubmit(values, handleError, o)}
    initialValues={initialValues}
    render={(props) => (
      <Form
        {...props}
        title={I18n.t('v4/alerts.title.update')}
        activeValues={activeValues}
        alertCategories={AlertCategories}
        organizationId={model.organization_id}
        o={o}
      />
    )}
  />
}

Edit.propTypes = {
  model: PropTypes.object
}

export default Edit
