import React, { Fragment } from 'react'
import { Paper, Typography, Divider, Button, Grid, IconButton } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import { CreditCardNumber } from './CreditCard'
import { withStyles } from '@material-ui/core/styles'
import Dialog from '@ui/ActionDialog'

const CheckoutResume = ({
  classes,
  coupon,
  goToStep,
  plan,
  product,
  finalValue,
  paymentMethod,
  paymentMethodDescription,
  personalData,
  handleSave,
  handleCancel,
  sendingSubscription
}) => {
  const [open, setOpen] = React.useState(false)

  const handleCancelPurchase = () => {
    setOpen(false)
    handleCancel()
  }

  return(
    <Fragment>
      <Paper className={classes.CheckoutResume}>
        <Typography variant='h6' className={classes.CheckoutResume__title}>
          {I18n.t('v4/payment_checkout.checkout_resume.data_review')}
        </Typography>

        <Typography>
          {I18n.t('v4/payment_checkout.checkout_resume.review_confirmation')}
        </Typography>

        <section className={classes.CheckoutResume__Info}>
          <Typography variant='subtitle1' className={classes.CheckoutResume__subtitle}>
            {I18n.t('v4/payment_checkout.checkout_resume.checkout_details.title')}

            <IconButton onClick={() => goToStep(0)}>
              <EditIcon />
            </IconButton>
          </Typography>

          <Grid container spacing={16}>
            <Grid item xs={6} sm={4} className={classes.CheckoutResume__Item}>
              <Typography className={classes.CheckoutResume__Item_title}>
                {I18n.t('v4/payment_checkout.checkout_resume.checkout_details.product')}
              </Typography>

              <Typography>
                {product.name}
              </Typography>
            </Grid>

            <Grid item xs={6} sm={4} className={classes.CheckoutResume__Item}>
              <Typography className={classes.CheckoutResume__Item_title}>
                {I18n.t('v4/payment_checkout.checkout_resume.checkout_details.subscription_period')}
              </Typography>

              <Typography>
                {plan.name}
              </Typography>
            </Grid>

            <Grid item xs={6} sm={4} className={classes.CheckoutResume__Item}>
              <Typography className={classes.CheckoutResume__Item_title}>
                {I18n.t('v4/payment_checkout.checkout_resume.checkout_details.payment_method')}
              </Typography>

              <Typography>
                {I18n.t('activerecord.attributes.v4/organization_account.payment_profiles.'+paymentMethodDescription)}
              </Typography>
            </Grid>

            <Grid item xs={6} sm={4} className={classes.CheckoutResume__Item}>
              <Typography className={classes.CheckoutResume__Item_title}>
                {I18n.t('v4/payment_checkout.checkout_resume.checkout_details.total_value')}
              </Typography>

              <Typography>
                {finalValue}
              </Typography>
            </Grid>

            {
              coupon &&
              <Grid item xs={6} sm={4} className={classes.CheckoutResume__Item}>
                <Typography className={classes.CheckoutResume__Item_title}>
                  {I18n.t('v4/payment_checkout.checkout_resume.checkout_details.coupon_applied')}
                </Typography>

                <Typography>
                  {coupon.code}
                </Typography>
              </Grid>
            }
          </Grid>
          <Divider />
        </section>

        <section className={classes.CheckoutResume__Info}>
          <Typography className={classes.CheckoutResume__subtitle}>
            {I18n.t('v4/payment_checkout.checkout_resume.personal_data.title')}

            <IconButton onClick={() => goToStep(1)}>
              <EditIcon />
            </IconButton>
          </Typography>

          <Grid container spacing={16}>
            <Grid item xs={6} sm={4} className={classes.CheckoutResume__Item}>
              <Typography className={classes.CheckoutResume__Item_title}>
                {I18n.t('v4/payment_checkout.checkout_resume.personal_data.name')}
              </Typography>

              <Typography>
                {personalData.legal_name}
              </Typography>
            </Grid>

            <Grid item xs={6} sm={4} className={classes.CheckoutResume__Item}>
              <Typography className={classes.CheckoutResume__Item_title}>
                {I18n.t('v4/payment_checkout.checkout_resume.personal_data.CPF')}
              </Typography>

              <Typography>
                {personalData.registry_code}
              </Typography>
            </Grid>

            <Grid item xs={6} sm={4} className={classes.CheckoutResume__Item}>
              <Typography className={classes.CheckoutResume__Item_title}>
                {I18n.t('v4/payment_checkout.checkout_resume.personal_data.CEP')}
              </Typography>

              <Typography>
                {personalData.zipcode}
              </Typography>
            </Grid>

            <Grid item xs={6} sm={4} className={classes.CheckoutResume__Item}>
              <Typography className={classes.CheckoutResume__Item_title}>
                {I18n.t('v4/payment_checkout.checkout_resume.personal_data.city_state')}
              </Typography>

              <Typography>
                {personalData.city} - {personalData.state}
              </Typography>
            </Grid>

            <Grid item xs={6} sm={4} className={classes.CheckoutResume__Item}>
              <Typography className={classes.CheckoutResume__Item_title}>
                {I18n.t('v4/payment_checkout.checkout_resume.personal_data.address')}
              </Typography>

              <Typography>
                {personalData.address} - {personalData.number} {personalData.additional_details}
              </Typography>
            </Grid>
          </Grid>
          <Divider />
        </section>

        <section className={classes.CheckoutResume__Info}>
          <Typography className={classes.CheckoutResume__subtitle}>
            {I18n.t('v4/payment_checkout.checkout_resume.billing_data.title')}

            <IconButton onClick={() => goToStep(2)}>
              <EditIcon />
            </IconButton>
          </Typography>

          <PaymentData paymentMethod={paymentMethod} classes={classes} />

        </section>
      </Paper>

      <footer>
        <Button
          className={classes.CheckoutResume__action_cancel}
          disabled={sendingSubscription}
          onClick={() => setOpen(true)}
          variant='contained'
        >
          {I18n.t('actions.cancel')}
        </Button>

        <Button
          className={classes.CheckoutResume__action}
          disabled={sendingSubscription}
          onClick={handleSave}
          color='primary'
          variant='contained'
          data-intercom-target="Checkout"
        >
          {I18n.t('v4/payment_checkout.checkout_resume.checkout')}
        </Button>
      </footer>

      <Dialog
        open={open}
        cancelHandler={handleCancelPurchase}
        confirmHandler={() => setOpen(false)}
        onClose={() => setOpen(false)}
        dialogTitle={I18n.t('v4/payment_checkout.checkout_resume.dialog.title')}
        dialogText={I18n.t('v4/payment_checkout.checkout_resume.dialog.text')}
        cancelActionText={I18n.t('v4/payment_checkout.checkout_resume.dialog.cancel')}
        confirmActionText={I18n.t('v4/payment_checkout.checkout_resume.dialog.keep')}
        cancelButtonType='danger'
        confirmButtonType='primary'
      />
    </Fragment>
  )
}

const PaymentData = ({paymentMethod}, classes) => {
  if (paymentMethod.id == "000000") {
    return <SlipData paymentMethod={paymentMethod} classes={classes} />
  } else {
    return <CardData paymentMethod={paymentMethod} classes={classes} />
  }
}

const CardData = ({paymentMethod}, classes) => {
  return(
    <Grid container spacing={16}>
      <Grid item xs={6} sm={4} className={classes.CheckoutResume__Item}>
        <Typography className={classes.CheckoutResume__Item_title}>
          {I18n.t('v4/payment_checkout.checkout_resume.billing_data.credit_card')}
        </Typography>

        <Typography>
          <CreditCardNumber card={paymentMethod} />
        </Typography>
      </Grid>

      <Grid item xs={6} sm={4} className={classes.CheckoutResume__Item}>
        <Typography className={classes.CheckoutResume__Item_title}>
          {I18n.t('v4/payment_checkout.checkout_resume.billing_data.credit_card_name')}
        </Typography>

        <Typography>
          {paymentMethod.name}
        </Typography>
      </Grid>

      <Grid item xs={6} sm={4} className={classes.CheckoutResume__Item}>
        <Typography className={classes.CheckoutResume__Item_title}>
          {I18n.t('v4/payment_checkout.checkout_resume.billing_data.expiration_date')}
        </Typography>

        <Typography>
          {paymentMethod.card_expiration}
        </Typography>
      </Grid>
    </Grid>
  );
}

const SlipData = ({paymentMethod}, classes) => {
  return(
    <Grid container spacing={16}>
      <Grid item xs={6} sm={4} className={classes.CheckoutResume__Item}>
        <Typography className={classes.CheckoutResume__Item_title}>
          {I18n.t('v4/payment_checkout.checkout_resume.billing_data.bank_slip')}
        </Typography>
      </Grid>
    </Grid>
  );
}


const styles = (theme) => ({
  CheckoutResume: {
    margin: `${theme.spacing.unit * 3}px 0`,
    maxWidth: 'max-content',
    padding: theme.spacing.unit * 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  CheckoutResume__title: {
    fontWeight: 'bold'
  },
  CheckoutResume__subtitle: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    margin: `${theme.spacing.unit}px 0`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  CheckoutResume__Info: {
    width: '100%'
  },
  CheckoutResume__Item: {
    margin: `0 0 ${theme.spacing.unit}px`,
  },
  CheckoutResume__Item_title: {
    fontWeight: 'bold'
  },
  CheckoutResume__action: {
    '&:not(:last-child)': {
      marginRight: `${theme.spacing.unit * 2}px`
    }
  },
  CheckoutResume__action_cancel: {
    ...theme.danger,
    '&:not(:last-child)': {
      marginRight: `${theme.spacing.unit * 2}px`
    }
  }
})

CheckoutResume.defaultProps = {
  handleSave: () => { },
  handleCancel: () => { },
  plan: {},
  product: {},
  personalData: {},
  finalValue: 0,
  paymentMethod: {},
}

export default withStyles(styles)(CheckoutResume)
