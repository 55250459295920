import React from 'react'
import { Form as FinalForm } from 'react-final-form'

import { RailsForm } from '@support/RailsForm'
import Form from './Form'

export const ApiWrapper = ({ handleSubmit, onCancel, action }) => (
  <RailsForm
    component={FinalForm}
    handleSubmit={handleSubmit}
    render={(props) => (
      <Form
        {...props}
        onCancel={onCancel}
        action={action}
      />
    )}
  />
)

export default ApiWrapper
