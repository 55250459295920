import React from 'react'
import { Typography, withStyles } from '@material-ui/core'
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery'

import { useRouter } from '@contexts/RouterProvider'

const Banner = ({ classes, remainingDays, expirationDate = false }) => {
  const router = useRouter()
  const isMobile = useMediaQuery('(max-width:800px)')

  const getTitle = () =>
    isMobile
      ?
      expirationDate
        ? I18n.t('v4/trial.mobile_expiration')
        : I18n.t('v4/trial.mobile_title', { day: remainingDays })
      :
      expirationDate
        ? I18n.t('v4/trial.expiration')
        : I18n.t('v4/trial.title', { day: remainingDays })

  return (
    <div
      className={expirationDate ? classes.Banner_wrapperExpirantion : classes.Banner_wrapper}
      onClick={() => router.visit('store')}
      data-intercom-target={expirationDate ? 'click_banner_trial_expired' : 'click_banner_trial'}
    >
      <Typography variant='caption' className={classes.Banner_title}>
        {getTitle()}

        <span className={classes.Banner_info}>
          {` ${I18n.t('v4/trial.click').toUpperCase()}`}
        </span>
      </Typography>
    </div >
  )
}

const styles = theme => ({
  Banner_wrapper: {
    width: '100%',
    minHeight: '30px',
    background: theme.palette.trialColor,
    textAlign: 'center',
    padding: theme.spacing.unit,
    cursor: 'pointer'
  },
  Banner_title: {
    color: theme.palette.white
  },
  Banner_wrapperExpirantion: {
    width: '100%',
    minHeight: '30px',
    background: theme.palette.trialColorRed,
    textAlign: 'center',
    padding: theme.spacing.unit,
    cursor: 'pointer'
  },
  Banner_info: {
    fontSize: '15px',
    fontWeight: '500'
  },
})

export default withStyles(styles)(Banner)
