import validateFormFields from '@services/validateFormFields'
import { getBoundedCalculationResultInfo } from './irrigation_system_bounded'
import { getPivotCalculationResultInfo } from './irrigation_system_pivot'
import httpService from '@services/httpService'

import TimePicker from '@ui/Fields/TimePicker'
import TextField from '@ui/Fields/TextField'

import TableExport from 'tableexport'

export const irrigationHeaders = [
  'name',
  'irrigation_system_type_text',
  'irrigation_efficiency',
  'manufacturer',
  'percentage_irrigated_areas'
]

export const validate = values => {
  let selectedFields = fields.defaultFields

  if (values.form_type) {
    selectedFields = [...selectedFields, ...fields[values.form_type]]
  }

  return { equipment: validateFormFields(values.equipment, selectedFields) }
}

export const fields = {
  defaultFields: [
    { name: 'name', validations: { required: true } },
    { name: 'irrigation_system_type', validations: { required: true } },
    { name: 'irrigation_efficiency', validations: { required: true, bounds: { min: 30, max: 100 } } },
    { name: 'engine_power', validations: { bounds: { min: 0, max: 2000 } } }
  ],
  pivot: [
    { name: 'irrigation_limitation', validations: { required: true } },
    { name: 'irrigation_limit_value', validations: { required: true, bounds: { min: 0, max: 100 } } },
    { name: 'manufacturer', validations: { required: true } },
    { name: 'lap_time', validations: { required: true, bounds: { min: 0, max: 100 } } },
    { name: 'maximum_system_capacity', validations: { required: true, bounds: { min: 0, max: 100 } } },
    { name: 'percentage_irrigated_areas', validations: { required: true, bounds: { min: 0, max: 100 } } },
    { name: 'slices', validations: { required: true } },
    { name: 'pivot_adequacy_degree', validations: { bounds: { min: 0, max: 100 } } },
    { name: 'irrigation_engine_power' }
  ],
  bounded: [
    { name: 'irrigation_limitation', validations: { required: true } },
    { name: 'irrigation_limit_value', validations: { required: true, bounds: { min: 0, max: 100 } } },
    { name: 'manufacturer', validations: { required: true } },
    { name: 'operations', validations: { required: true, bounds: { min: 1, max: 100 } } },
    { name: 'flow_unit', validations: { required: true } },
    { name: 'emitters_flow_rate', validations: { required: true, bounds: { min: 0, max: 500 } } },
    { name: 'spacing_between_plants', validations: { bounds: { min: 0, max: 50 } } },
    { name: 'spacing_between_lanes', validations: { bounds: { min: 0, max: 50 } } },
    { name: 'emitters_per_plant', validations: { bounds: { min: 0, max: 40 } } },
    { name: 'percentage_irrigated_areas', validations: { required: true, bounds: { min: 0, max: 100 } } },
  ]
}

export const getCalculationResultInfo = (calculationResult) =>
  calculationResult.irrigation_system_type === 'pivot'
    ? getPivotCalculationResultInfo({ calculationResult })
    : getBoundedCalculationResultInfo({ calculationResult })

export const calculateParams = async (values, organization_id, handleError) => {
  try {
    const { data } = await httpService.post(`/api/v4/equipment/irrigation_system/specs`, values, { params: { organization_id } })

    return data
  }
  catch (error) {
    handleError(error)
  }
}

export const includesInfo = (array, attribute, value) =>
  array.map(element => element[attribute]).includes(value)

export const getIdealEfficiencyValue = (type) => {
  const selectedType = idealEfficiencies.find(idealEfficiency => idealEfficiency.types.includes(type))

  if (!selectedType) return null

  return selectedType.value
}

const idealEfficiencies = [
  {
    types: ['pivot', 'sprinkler'],
    value: 85
  },
  {
    types: ['drip', 'micro_sprinkler'],
    value: 95
  }
]

const pivotUnits = [
  { name: I18n.t('v4/irrigation.units.milimeter'), id: 'mm' },
  { name: I18n.t('v4/irrigation.units.percentage'), id: 'pm' }
]

const nonPivotUnits = [
  { name: I18n.t('v4/irrigation.units.milimeter'), id: 'mm' },
  { name: I18n.t('v4/irrigation.units.hour'), id: 'h' }
]

export const irrigationUnits = irrigationSystemType =>
  irrigationSystemType === 'pivot'
    ? pivotUnits
    : nonPivotUnits

const irrigationComponentTimePickerProps = { ampm: false }
const irrigationComponentTextFieldProps = { type: 'number', inputProps: { min: '0', step: 0.01 } }

export const irrigationFieldComponent = unitState => {
  const irrigationComponent = unitState && unitState === 'h' ? TimePicker : TextField

  return {
    irrigationComponent,
    irrigationProps: irrigationComponent === TimePicker
      ? irrigationComponentTimePickerProps
      : irrigationComponentTextFieldProps
  }
}

const getCustomFieldName = (irrigationSystemType) =>
  irrigationSystemType === 'pivot' ? 'sensor_irrigated' : 'operations'

export const getIrrigationFieldStates = (values, area, irrigationSystemType) => {
  if (!values[`area_${area.uuid}`]) return {}

  return ({
    startedAtState: values[`area_${area.uuid}`]['started_at'],
    irrigationState: values[`area_${area.uuid}`]['irrigation'],
    finishedAtState: values[`area_${area.uuid}`]['finished_at'],
    customFieldState: values[`area_${area.uuid}`][getCustomFieldName(irrigationSystemType)],
    unitState: values[`area_${area.uuid}`]['unit'],
    areaId: area.id
  })
}

export const dateToHours = (data) =>
  Number(data.getHours() + data.getMinutes() / 60).toFixed(2)

export const convertNumbertToDateHour = value => {
  const hours = Math.trunc(value)
  const minutes = Math.round((Number(value) % 1) * 60)

  const todayStringDate = new Date().toDateString()

  const date = new Date(`${todayStringDate} ${hours}:${minutes}`)

  return date
}

export const reportIrrigation = ({
  equipment,
  irrigationState,
  startedAtState,
  finishedAtState,
  unitState,
  customFieldState
}) => {
  const unit = unitState
  const irrigation = irrigationState
  const startedAt = startedAtState
  const finishedAt = finishedAtState
  const customField = customFieldState

  if (!equipment || !unit || !irrigation) {
    return null
  }

  const calculatedFields = equipment.data
    ? equipment.data.calculation_result
    : equipment.calculation_result

  if (!calculatedFields) {
    return null
  }

  if (equipment.irrigation_system_type === 'pivot') {
    if (calculatedFields.lap_time == null || !calculatedFields.area_percentage || !calculatedFields.irrigation_flow || !calculatedFields.slices) {
      return null
    }

    const slices = calculatedFields.slices
    const lapTime = calculatedFields.lap_time
    const PPA = calculatedFields.area_percentage
    const irrigationFlow = calculatedFields.irrigation_flow

    const irrigationInMm = unit === 'mm'
      ? irrigation
      : Number(irrigationFlow * 100 / irrigation).toFixed(2)

    const irrigationTrue = customField
      ? irrigationInMm
      : 0

    const areaPercent = PPA * 100
    const pivotFormatDegrees = areaPercent * 3.6
    const lapTimeDuration = lapTime * PPA * irrigationInMm / (irrigationFlow * slices)

    const irrigationDuration = (!startedAt || !finishedAt)
      ? lapTimeDuration
      : differenceInHours(finishedAt, startedAt)

    let irrigationDegrees = 0
    if (!startedAt || !finishedAt) {
      irrigationDegrees = customField ? pivotFormatDegrees : 0
    } else {
      irrigationDegrees = pivotFormatDegrees * irrigationDuration / lapTimeDuration

      if (isNaN(irrigationDegrees) || !isFinite(irrigationDegrees)) {
        irrigationDegrees = 0
      } else if (irrigationDegrees > pivotFormatDegrees) {
        irrigationDegrees = pivotFormatDegrees
      }
    }

    return {
      irrigationDegrees,
      irrigationInMm: isFinite(irrigationInMm) ? irrigationInMm : 0,
      irrigationTrue: isFinite(irrigationTrue) ? irrigationTrue : 0,
      irrigationPivotSize: irrigationDegrees < pivotFormatDegrees ? 'partial' : 'total',
      areaPercentage: areaPercent
    }
  } else {
    const irrigationFlow = calculatedFields.irrigation_flow
    const operations = customFieldState ? Number(customFieldState) : 0

    let irrigationTrue = irrigation
    let irrigationDuration = null

    if (!!irrigationFlow) {
      if (unit === 'mm') {
        irrigationDuration = irrigation / irrigationFlow
      } else {
        const irrigationHours = typeof irrigation === 'object'
          ? dateToHours(irrigation)
          : 0

        irrigationTrue = _.round(irrigationFlow * irrigationHours, 2)
        irrigationDuration = _.round(irrigationHours, 2)
      }
    }

    return { irrigationTrue, irrigationDuration, operations }
  }
}

const differenceInHours = (date1, date2) => {
  let diff = (date1.getTime() - date2.getTime()) / 36e5

  diff < 0 && (diff += 24)

  return Math.round(diff)
}

export const acceptedAttachmentsMimeTypes = [
  'application/vnd.ms-excel', //.xls Microsoft Excel
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', //.xlsx Microsoft Excel (OpenXML)
  'application/vnd.oasis.opendocument.spreadsheet', //OpenDocument Spreadsheet
  'image/*', //All image formats
  'application/zip', //.zip ZIP archive
  'application/pdf', //.pdf Adobe Portable Document Format (PDF)
  'text/plain', //.txt Text, (generally ASCII or ISO 8859-n)
  'application/msword', //.doc Microsoft Word
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document', //.docx Microsoft Word (OpenXML)
  'application/vnd.oasis.opendocument.text' //OpenDocument Text
]

export const exportTable = (title) => {
  const table = TableExport(document.getElementsByTagName('table'), {
    exportButtons: false,
    filename: title,
    formats: ['xlsx']
  })

  const exportData = table.getExportData()['tableexport-1'].xlsx

  exportData['data'] = exportData.data.map(data => data.pop() && data)

  table.reset()
  table.export2file(exportData.data, exportData.mimeType, exportData.filename, exportData.fileExtension, exportData.merges)
}

export const convertDateAndTime = (date, hours) => {
  return new Date(
    date.getFullYear(),
    date.getMonth(),
    date.getDate(),
    hours.getHours(),
    hours.getMinutes(),
    hours.getSeconds()
  )
}

const convertDate = date => !!date ? new Date(date) : null

export const deserializeValues = (values = {}) => ({
  ...values,
  irrigation_date: new Date(`${values.irrigation_date}T00:00:00`),
  started_at: convertDate(values.started_at),
  finished_at: values.finished_at ? convertDateAndTime(convertDate(values.started_at), convertDate(values.finished_at)) : null,
  irrigation:
    values.unit === 'h'
      ? convertNumbertToDateHour(values.irrigation)
      : values.irrigation
})
