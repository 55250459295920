import React from 'react'
import Table from '@ui/Table'

import { filesTableHeaders } from '@models/files'
import { withStyles } from '@material-ui/core/styles'

const UploadedFilesTable = ({ files, classes, onDelete }) =>
  <div className={classes.UploadedFilesTable}>
    <Table
      tableKey='directUploadDropzone-filesTable'
      data={files}
      components={{
        Toolbar: () => null
      }}
      columns={filesTableHeaders}
      actions={[
        rowData => ({
          icon: 'delete',
          iconProps: { 'data-testid': `delete-${rowData.id}` },
          tooltip: I18n.t('actions.remove'),
          onClick: (event, rowData) => onDelete(rowData)
        })
      ]}
      options={{
        search: false,
        paging: false,
        sorting: false
      }}
    />
  </div>

UploadedFilesTable.defautProps = {
  files: [],
  onDelete: () => { }
}

const styles = theme => ({
  UploadedFilesTable: {
    margin: `${theme.spacing.unit * 2}px 0`
  }
})

export default withStyles(styles)(UploadedFilesTable)
