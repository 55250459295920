import React from 'react'

import ReactHtmlParser from 'react-html-parser'

import {
  Button,
  Grid,
  Typography,
  withStyles
} from '@material-ui/core'

import { Field } from 'react-final-form'

import TextField from '@ui/Fields/TextField'

import styles from '@theme/styles/forms'

const Form = ({
  classes,
  handleSubmit,
  onCancel,
  loading
}) => {
  return (
    <form
      onSubmit={handleSubmit}
      className={classes.Form_wrapper}
    >
      <header>
        <Typography variant='body1'>
          {ReactHtmlParser(I18n.t('v4/equipment.card.form_title'))}
        </Typography>
      </header>

      <Grid container spacing={8} alignItems="center">
        <Grid item xs={12}>
          <Field
            className={classes.form__fieldWithError}
            component={TextField}
            fullWidth
            InputLabelProps={{ shrink: true }}
            label={'Volumes'}
            margin='normal'
            name='reading_estimate'
            required
            type='number'
            variant='outlined'
            InputProps={{
              endAdornment: 'm³',
              inputProps: {
                className: classes.Form_no_arrows
              }
            }}
          />
        </Grid>
      </Grid>

      <footer className={classes.Form_actions}>
        <Button
          color='secondary'
          onClick={onCancel}
          disabled={loading}
        >
          {I18n.t('actions.cancel')}
        </Button>

        <Button
          color='primary'
          type='submit'
          variant='contained'
          disabled={loading}
        >
          {I18n.t('actions.save')}
        </Button>
      </footer>
    </form>
  )
}

const customStyles = theme => ({
  ...styles(theme),
  Form_wrapper: {
    maxWidth: '400px'
  },
  Form_no_arrows: {
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none'
    },
    '-moz-appearance': 'textfield'
  },
  Form_actions: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing.unit * 2,
    width: '100%'
  }
})

export default withStyles(customStyles)(Form)
