import React from 'react'

import { Typography, InputLabel } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import UploadIcon from '@material-ui/icons/Cloud'
import Dropzone from 'react-dropzone'
import styles from '@theme/styles/forms'

const CustomDropZone = ({ label, acceptedMimeTypes, setFile, previewImage, classes, currentFileName, className, multiple, disabled }) => {
  const [preview, setPreview] = React.useState(previewImage)
  const [filesNames, setFilesNames] = React.useState(
    multiple
      ? currentFileName
        ? [currentFileName]
        : []
      : currentFileName)

  const onFileDrop = (files, rejectedFiles) => {
    if (rejectedFiles.length > 0) return
    const newFiles = multiple ? files : files[0]

    const newFilesNames = _.isArray(filesNames)
      ? [...filesNames, ...newFiles.map(file => file.name)]
      : newFiles.name

    setFile(newFiles)
    setFilesNames(newFilesNames)
    setPreview(null)
  }

  const dropzoneClassName = ({ isDragActive, isDragReject, disabled }) => {
    if (disabled) {
      return classes.form__dropzone_disabled
    }
    if (isDragReject) {
      return classes.form__dropzone_onDragReject
    }
    if (isDragActive) {
      return classes.form__dropzone_onDrag
    }

    return null
  }

  return <div className={className}>
    <InputLabel shrink>
      {label}
    </InputLabel>
    <Dropzone
      onDrop={onFileDrop}
      disabled={disabled}
      accept={acceptedMimeTypes}
      multiple={multiple}
    >
      {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
        const className = dropzoneClassName({ isDragActive, isDragReject, disabled })

        return (
          <div
            variant='contained'
            color='primary'
            {...getRootProps()}
            className={`${classes.form__dropzone} ${className}`}
          >
            <input {...getInputProps()} />
            <UploadIcon />
            <div>
              {
                !isDragReject
                  ? I18n.t('dropzone.upload_new_file')
                  : I18n.t('dropzone.reject_new_file')
              }
            </div>
            {
              !_.isEmpty(filesNames) &&
              <div className={classes.form__dropzone_selectedFiles}>
                <Typography className={classes.form__dropzone_selectedFile}>
                  {I18n.t('dropzone.selected')}:
                </Typography>
                {
                  _.isArray(filesNames)
                    ? filesNames.map((fileName, index) =>
                      <Typography key={index} className={classes.form__dropzone_selectedFile} variant='body1'>
                        {fileName}
                      </Typography>
                    )
                    : <Typography className={classes.form__dropzone_selectedFile} variant='body1'>
                      {filesNames}
                    </Typography>
                }
              </div>
            }
          </div>
        )
      }}
    </Dropzone>

    {
      preview &&
      <div className={classes.form__currentIcon_wrapper}>
        <InputLabel shrink>
          {I18n.t('dropzone.current_file')}
        </InputLabel>
        <div className={classes.form__currentIcon}>
          <img
            src={preview}
            alt={label}
            width='50%'
          />
        </div>
      </div>
    }
  </div>
}

CustomDropZone.defaultProps = {
  acceptedMimeTypes: [
    'image/jpeg',
    'image/png',
    'image/svg+xml'
  ],
  label: I18n.t('dropzone.file'),
  multiple: false,
  currentFileName: '',
  disabled: false
}

export default withStyles(styles)(CustomDropZone)
