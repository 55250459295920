import React from 'react'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from '@theme/styles/forms'
import MaterialTable from '@ui/Table'
import { getNotificationsHeaders } from '@models/notifications'
import { withSnackbar } from 'notistack'
import { deserialize } from '@models/notifications'
import httpService from '@services/httpService'
import DeleteDialog from '@ui/DeleteDialog'

const ACTIONS = {
  CREATE: 1,
  DELETE: 2
}

const checkActions = ({ action }) =>
  new Promise((res, rej) =>
    action ? res() : rej())

class Index extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      notifications: [],
      deletedNotifications: [],
      isDeleteDialogOpen: false,
      selectedNotificatioIndex: null
    }
  }

  componentDidMount() {
    const { model } = this.props

    this.setState({ notifications: !_.isEmpty(model) ? model : [] })
  }

  handleDeleteDialogToggle(selectedNotificatioIndex) {
    this.setState({
      isDeleteDialogOpen: !this.state.isDeleteDialogOpen,
      selectedNotificatioIndex
    })
  }

  handleNewNotifications = () => {
    const { organization } = this.props
    const { notifications } = this.state

    const notification = {
      organizationId: organization.id,
      notificationsEnabled: false,
      action: ACTIONS.CREATE
    }

    notifications.push(notification)

    this.setState({ notifications })
  }

  handleDeleteNotifications = (deletedNotification) => {

    const { notifications, deletedNotifications } = this.state

    const indexDeleted = deletedNotification.tableData.id

    const newNotifications = notifications.filter((notification, index) => index !== indexDeleted)

    if (deletedNotification.id) {
      const notification = { ...notifications[indexDeleted] }
      notification.action = ACTIONS.DELETE
      deletedNotifications.push(notification)
    }

    this.setState({ notifications: newNotifications, deletedNotifications })
  }

  handleSubmit = (event) => {
    const { organization, enqueueSnackbar, organization_product, o } = this.props
    const { notifications, deletedNotifications } = this.state
    let errNotifications = []

    const notificationsList = notifications.filter(notification => notification.action).map(_notification => {
      const { organizationId: v4_organization_id, notificationsEnabled: notifications_enabled, action } = _notification
      return { v4_organization_id, notifications_enabled, action }
    })

    const actionNotifications = [...notificationsList, ...deletedNotifications]
    event.preventDefault()
    Promise.all(
      actionNotifications.map(notification => checkActions(notification)
        .then(res => {
          switch (notification.action) {
            case ACTIONS.CREATE:
              return httpService.post(`/v4/o/${o}/admin/organizations/${organization.id}/products/${organization_product.id}/notifications/weather_forecast`, notification)
            default:
              return httpService.delete(`/v4/o/${o}/admin/organizations/${organization.id}/products/${organization_product.id}/notifications/weather_forecast/${notification.id}`)
          }
        })
        .then(res => res)
        .catch(err => errNotifications.push(notification))
      )
    )
      .then(res => {
        errNotifications.length > 0
          ? enqueueSnackbar(I18n.t('errors.default'), { variant: 'error' })
          : Turbolinks.visit(`/v4/o/${o}/admin/organizations/${organization.id}/products`)
      })
  }

  render() {
    const { classes, organization, o } = this.props
    const { isDeleteDialogOpen, selectedNotificatioIndex, notifications } = this.state

    return (
      <Paper className={classes.form__wrapper} elevation={0}>
        <header className={classes.form__header}>
          <Typography component='h2' variant='h5'>
            {organization.name} - {I18n.t('v4/forecast.actions.config')}
          </Typography>
        </header>
        <main className={classes.form__fields}>
          <MaterialTable
            columns={getNotificationsHeaders()}
            data={deserialize(notifications)}
            title={I18n.t('v4/forecast.actions.config')}
            actions={[
              {
                icon: 'add',
                tooltip: I18n.t('actions.add'),
                isFreeAction: true,
                onClick: this.handleNewNotifications
              },
              rowData => ({
                icon: 'delete',
                iconProps: { 'data-testid': `delete-${rowData.id}` },
                tooltip: I18n.t('actions.remove'),
                onClick: () => this.handleDeleteDialogToggle(rowData)
              })
            ]}
            localization={{
              body: {
                emptyDataSourceMessage: I18n.t('info.no_data')
              }
            }}
            options={{
              search: false,
              paging: false,
              showEmptyDataSourceMessage: true,
              sorting: false,
              actionsColumnIndex: -1,
              headerStyle: {
                backgroundColor: '#f5f5f5'
              },
              rowStyle: {
                cursor: 'move'
              }
            }}
          />
          <DeleteDialog
            dialogTitle={`${I18n.t('actions.remove')} ${I18n.t('v4/organization.products.notifications.title')}?`}
            dialogText={I18n.t('confirmation.remove')}
            onCancel={() => this.handleDeleteDialogToggle()}
            onClose={() => this.handleDeleteDialogToggle()}
            handleDelete={() => {
              this.handleDeleteNotifications(selectedNotificatioIndex)
              this.handleDeleteDialogToggle(selectedNotificatioIndex)
            }}
            open={isDeleteDialogOpen}
          />
        </main>
        <footer className={classes.form__actions}>
          <Button
            className={classes.form__action}
            color='primary'
            type='submit'
            variant='contained'
            onClick={(event) => this.handleSubmit(event)}
          >
            {I18n.t('actions.save')}
          </Button>

          <Button
            className={classes.form__action}
            color='secondary'
            href={`/v4/o/${o}/admin/organizations/${organization.id}/products`}
          >
            {I18n.t('actions.back')}
          </Button>
        </footer>
      </Paper>
    )
  }
}

export default withSnackbar(
  withStyles(styles)(Index)
)
