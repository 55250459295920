import React from 'react'

import { Button, Grid, Typography, withStyles } from '@material-ui/core'

import { useMenusStatus, MENUS } from '@contexts/MenusStatusProvider'

const Selector = ({ classes, onCoordinatesClick }) => {
  const { menus, toggleMenu } = useMenusStatus()

  return(
    <div className={classes.Selector_wrapper}>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant="body1" className={classes.Selector_wrapper} >
            {I18n.t('v4/forecast.configuration.selector')}
          </Typography>
        </Grid>
      </Grid>
      <Grid container>
        <Grid item xs={12} className={classes.Selector_button}>
          <Button
            color='primary'
            className={classes.Selector_text}
            onClick={onCoordinatesClick}
            data-intercom-target='atmos-new-fp-insert-coordinates'
          >
            {I18n.t('v4/forecast.configuration.coordinates')}
          </Button>
        </Grid>
        <Grid item xs={12} className={classes.Selector_button}>
          <Typography variant="body1" className={classes.Selector_wrapper} >
            {I18n.t('v4/forecast.configuration.or')}
          </Typography>
        </Grid>
        <Grid item xs={12} className={classes.Selector_button}>
          <Button
            color='primary'
            className={classes.Selector_text}
            onClick={() => toggleMenu(MENUS.weatherForecastSetupFixedModal, true)}
            data-intercom-target='atmos-new-fp-use-map'
          >
            {I18n.t('v4/forecast.configuration.map')}
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

const styles = (theme) => ({
  Selector_wrapper: {
    padding: `${theme.spacing.unit}px 0px`
  },
  Selector_button: {
    display: 'flex',
    justifyContent: 'center'
  }
})

export default withStyles(styles)(Selector)
