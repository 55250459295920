import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

import styles from './styles'
import { Button } from '@material-ui/core'

class StoreNavbar extends Component {
  render() {
    const {
      classes,
      plans,
      currentPlan,
      handleChangePlan,
      organization_id,
      o
    } = this.props

    return (
      <div className={classes.navbar__wrapper}>
        <div className={classes.navbar__header}>
          <Typography gutterBottom component='h2' variant='h5'>
            {I18n.t('v4/store.title')}
          </Typography>

          <Button
            color='primary'
            variant='contained'
            onClick={() => Turbolinks.visit(`/v4/o/${o}/organizations/${organization_id}/invoices`)}
          >
            {I18n.t('v4/store.my_orders')}
          </Button>
        </div>
        <Typography gutterBottom component='h3' variant='h6' className={classes.navbar__plan}>
          {`${I18n.t('v4/store.plan')}: `}
        </Typography>
        <Select
          displayEmpty
          id='store-plan-select'
          value={currentPlan}
          onChange={handleChangePlan}
        >
          {
            plans.map(plan =>
              <MenuItem value={plan} key={`plan-${plan.name}`}>
                {plan.name}
              </MenuItem>
            )
          }
        </Select>
      </div>
    )
  }
}

export default withStyles(styles)(StoreNavbar)
