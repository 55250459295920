export default theme => ({
  Dashboard__wrapper: {
    margin: `${theme.spacing.unit * 5}px auto 0`,
    padding: theme.spacing.unit * 4,
    maxWidth: '1600px'
  },
  Dashboard__header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing.unit * 7,
  },
  Dashboard__loading: {
    display: 'flex',
    justifyContent: 'center',
    margin: '50px'
  },
  Dashboard__no_data: {
    display: 'flex',
    justifyContent: 'center',
    margin: '50px'
  },
  Dashboard__table_head: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  Dashboard__table_export_button: {
    margin: '20px',
    minWidth: 0,
    padding: '10px'
  },
  Dashboard__table_container: {
    width: '100%',
    paddingTop: '10px',
    display: 'flex',
    justifyContent: 'center'
  },
  Dashboard__table_wrapper: {
    width: '75%',
    overflow: 'auto',
    maxHeight: '60vh',
    '& > div': { boxShadow: 'unset' },
    '& div': { overflowX: 'unset!important' },
    '& table:first-child': {
      '& tr': {
        '& td:first-child, th:first-child': {
          backgroundColor: theme.palette.gray.light,
          position: 'sticky',
          left: 0,
        },
        '& th:first-child': {
          zIndex: 999
        },
        '& th': {
          backgroundColor: theme.palette.gray.light
        }
      }
    }
  },
  Dashboard__charts_wrapper: {
    width: '100%',
    display: 'flex',
    flexWrap: 'wrap'
  },
  Dashboard__content: {
    display: 'flex',
    flexDirection: 'column',
    marginTop: '20px',
    overflow: 'auto'
  },
  Dashboard__visualization_wrapper: {
    display: 'flex',
    alignItems: 'center',
    margin: '20px 0',
    justifyContent: 'center',
    position: 'relative'
  },
  Dashboard__visualization: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    left: 0
  },
  chart__wrapper: {
    flex: '1 1 33%',
    border: '1px solid rgb(204, 204, 204)',
    padding: '25px',
    margin: '20px',
    display: 'flex',
    flexDirection: 'column',
    fontFamily: 'Helvetica, Arial, sans-serif'
  },
  chart__header: {
    padding: '15px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '0 0 10px'
  },
  export_button: {
    minWidth: 0,
    padding: '10px'
  },
  chart__brush: {
    margin: '5px 0'
  },
  chart__legend_wrapper: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 15px',
    justifyContent: 'space-between'
  },
  chart__legend_colors: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  chart__legend: {
    display: 'flex',
    justifyContent: 'space-around',
    alignItems: 'center',
    marginLeft: theme.spacing.unit * 2
  },
  chart__legend_color: {
    margin: '0 10px',
    height: '20px',
    width: '20px'
  },
  tooltip__wrapper: {
    margin: '0px',
    padding: '10px',
    backgroundColor: 'rgb(255, 255, 255)',
    border: '1px solid rgb(204, 204, 204)',
    whiteSpace: 'nowrap'
  }
})
