import React from 'react'

import { Typography, Button, Divider, Grid } from '@material-ui/core'
import Check from '@material-ui/icons/CheckCircleOutline'
import withStyles from '@material-ui/core/styles/withStyles'

import { useMenusStatus, MENUS } from '@contexts/MenusStatusProvider'

import InfoMenuWrapper from '../InfoMenuWrapper'

const AlertsInfo = ({
  classes,
  alert,
  handleRemoveAlerts,
  handleRemoveAllAlerts
}) => {
  const { menus, toggleMenu } = useMenusStatus()
  const { open } = menus[MENUS.mapAlertsMenu]

  return (
    <InfoMenuWrapper open={open} onClose={() => toggleMenu(MENUS.mapAlertsMenu, false)}>
      <div className={classes.Alert_Wrapper}>
        <Typography variant='body2' className={classes.Alert_title}>
          {alert.areaName}
        </Typography>

        {
          !_.isEmpty(alert.alerts) &&
          <div className={classes.Alert_Container}>
            {
              alert.alerts.map((currentAlert, index) =>
                <div className={classes.Alert_Current} key={`alert-area-${index}`}>
                  <div className={classes.Alerts}>
                    <Typography variant='body2'>
                      {currentAlert.alert.name}
                    </Typography>

                    <Check
                      className={classes.Alert_check}
                      onClick={() => handleRemoveAlerts(currentAlert.alert.id, index)}
                    />
                  </div>

                  <Typography variant='caption' className={classes.Alert_date}>
                    {`${currentAlert.dateAndTime.infoDate}, ${currentAlert.dateAndTime.infoHour} `}
                  </Typography>

                  {
                    index < alert.alerts.length - 1
                      ? <Grid item xs={12}>
                        <Divider />
                      </Grid>
                      : null
                  }

                </div>
              )
            }

            <div className={classes.Alert_Actions}>
              <Button
                color='primary'
                onClick={() => handleRemoveAllAlerts(alert)}
              >
                {I18n.t('v4/alerts.alerts.completed_alerts')}
              </Button>
            </div>
          </div>

        }
      </div>
    </InfoMenuWrapper>
  )
}

const styles = theme => ({
  Alert_Wrapper: {
    padding: theme.spacing.unit
  },
  Alert_Container: {
    padding: theme.spacing.unit,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  Alerts: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  Alert_check: {
    color: theme.palette.primary.main,
    cursor: 'pointer'
  },
  Alert_date: {
    padding: `${theme.spacing.unit}px 0`,
    color: theme.palette.gray.dark
  },
  Alert_Actions: {
    display: 'flex',
    justifyContent: 'center'
  },
  Alert_Current: {
    padding: `${theme.spacing.unit}px 0`,
    width: '90%'
  },
  Alert_title: {
    display: 'flex',
    marginTop: theme.spacing.unit * 2,
    justifyContent: 'center',
    alignItems: 'center'
  }
})

export default withStyles(styles)(AlertsInfo)
