import React from 'react'
import PropTypes from 'prop-types'
import { Form as FinalForm } from 'react-final-form'

import { RailsForm } from '@support/RailsForm'
import InputForm from '../Form'
import ChemicalForm from './Form'
import { validate, chemicalShape } from '@models/inputs/chemical'

const EditChemical = ({ model, categories, formulations, o }) => {
  const {
    name, brand, category, formulation, security_interval,
    reg_code, active_principle, detail, kind
  } = model

  return (
    <RailsForm
      component={FinalForm}
      action='update'
      initialValues={{
        name, brand, category, formulation, security_interval,
        reg_code, active_principle, detail, kind
      }}
      url={`/v4/o/${o}/inputs/chemical/${model.id}`}
      successUrl={`/v4/o/${o}/inputs/chemical/${model.id}`}
      validade={validate}
      render={(props) => (
        <InputForm
          title={`${I18n.t('actions.edit')} ${I18n.t(`v4/input.kinds.${kind}`)}`}
        >
          <ChemicalForm
            {...props}
            action={I18n.t('actions.update')}
            categories={categories}
            formulations={formulations}
            o={o}
          />
        </InputForm>
      )}
    />
  )
}

EditChemical.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  })),
  formulations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  })),
  model: chemicalShape
}

export default EditChemical
