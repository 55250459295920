export default theme => ({
  form: {
    marginLeft: 'auto',
    marginRight: 'auto'
  },
  form__wrapper: {
    backgroundColor: 'transparent',
    maxWidth: 700,
    margin: `${theme.spacing.unit * 5}px auto`,
    padding: theme.spacing.unit * 4
  },
  form__larger__wrapper: {
    backgroundColor: 'transparent',
    maxWidth: 1000,
    margin: `${theme.spacing.unit * 5}px auto`,
    padding: theme.spacing.unit * 4
  },
  form__headerWithFields: {
    padding: '10px',
    justifyContent: 'space-between',
    margin: '0 -12px 10px'
  },
  form__header: {
    marginBottom: theme.spacing.unit * 4,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  form__inline_fields: {
    display: 'flex'
  },
  form__fields: {
    display: 'flex',
    flexDirection: 'column',
    margin: '10px'
  },
  form__field__wrapper: {
    minHeight: theme.spacing.unit * 12.5
  },
  form__fieldWithError: {
    minHeight: theme.spacing.unit * 6
  },
  form__inlineField: {
    minHeight: theme.spacing.unit * 6,
    '&:not(:last-child)': {
      marginRight: theme.spacing.unit * 2
    }
  },
  form__datePickerContainer: {
    marginTop: theme.spacing.unit,
    marginBottom: theme.spacing.unit
  },
  form__actions: {
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-start',
    alignItems: 'center',
    marginTop: theme.spacing.unit * 4
  },
  form__action: {
    '&:not(:last-child)': {
      marginLeft: theme.spacing.unit * 2
    }
  },
  form__snackbar__warning: {
    backgroundColor: theme.palette.darkRed
  },
  form__snackbar__icon: {
    fontSize: 20,
    marginRight: theme.spacing.unit
  },
  form__snackbar__message: {
    display: 'flex',
    alignItems: 'center'
  },
  form__dropzone: {
    margin: '5px 0',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    color: theme.palette.black,
    backgroundColor: theme.palette.gray.light,
    padding: '10px 16px',
    fontSize: '0.875rem',
    minWidth: '64px',
    borderRadius: '4px',
    fontWeight: 500,
    textTransform: 'uppercase',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    cursor: 'pointer',
    outline: 'none',
    '&:hover': {
      backgroundColor: theme.palette.gray.main,
    }
  },
  form__dropzone_onDrag: {
    backgroundColor: theme.palette.white,
    color: theme.palette.primary.dark,
    border: `2px dashed ${theme.palette.primary.dark}`,
    margin: '4px 0'
  },
  form__dropzone_onDragReject: {
    backgroundColor: theme.palette.white,
    color: theme.palette.darkRed,
    border: `2px dashed ${theme.palette.darkRed}`,
    margin: '4px 0'
  },
  form__dropzone_disabled: {
    opacity: 0.6,
    '&:hover': {
      backgroundColor: 'inherit',
      cursor: 'not-allowed'
    }
  },
  form__dropzone_selectedFile: {
    color: 'inherit',
    margin: `${theme.spacing.unit * 0.5}px 0`,
    textTransform: 'none'
  },
  form__dropzone_selectedFiles: {
    padding: `${theme.spacing.unit * 0.5}px ${theme.spacing.unit * 3}px`,
    alignSelf: 'flex-start'
  },
  form__currentIcon_wrapper: {
    margin: '15px 0'
  },
  form__currentIcon: {
    padding: '10px 0'
  },
  form__terms_use: {
    textAlign: 'justify',
    margin: 'auto',
    padding: '10px',
    color: 'black',
    border: '1px solid gray',
    backgroundColor: theme.palette.gray.light,
    borderRadius: '5px',
    overflowY: 'scroll',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    height: '500px',
    '&::-webkit-scrollbar': {
      width: '5px'
    },
    '&::-webkit-scrollbar-track': {
      background: 'gray',
      borderRadius: '5px',
    }
  },
  form__input_number_no_arrows: {
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none'
    },
    '-moz-appearance': 'textfield'
  },
  form__checkbox_list: {
    display: 'flex',
    flexDirection: 'column'
  },
  form__area_handler: {
    marginTop: theme.spacing.unit * 2,
  },
  form__area_fields: {
    display: 'contents',
    justifyContent: 'center'
  }
})
