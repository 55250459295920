import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Close from '@material-ui/icons/Close'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'
import Grow from '@material-ui/core/Grow'
import Paper from '@material-ui/core/Paper'
import Popper from '@material-ui/core/Popper'
import MenuItem from '@material-ui/core/MenuItem'
import MenuList from '@material-ui/core/MenuList'

import withStyles from '@material-ui/core/styles/withStyles'

import styles from './styles'

class ForecastControl extends Component {
  constructor(props) {
    super(props)

    this.state = {
      anchorEl: null,
      open: false
    }
  }

  handleMenuItemClick = (product) => {
    const { handleProductChange } = this.props

    this.setState({
      open: false
    }, () => handleProductChange(product))
  }

  handleToggle = (event) => {
    this.setState({ open: !this.state.open, anchorEl: event.currentTarget })
  }

  handleClose = (event) => {
    const { anchorEl } = this.state

    if (anchorEl.current && anchorEl.current.contains(event.target)) {
      return
    }

    this.setState({ open: false, anchorEl: null })
  }

  render() {
    const { anchorEl, open } = this.state
    const { handleMenuItemClick, handleToggle, handleClose } = this
    const { handleClick, visible, classes, products, selectedProduct } = this.props

    return (
      <div className={classes.forecast__control__wrapper}>
        <Button
          variant='contained'
          aria-label='split button'
          data-intercom-target='product_layers'
          onClick={handleToggle}
          className={classes.forecast__points__map__control}
        >
          {
            visible
              ? selectedProduct.label
              : I18n.t('activerecord.models.v4/product.other')
          }
        </Button>

        {
          visible &&
          <Button
            size='small'
            aria-owns={open ? 'menu-list-grow' : undefined}
            aria-haspopup='true'
            onClick={handleClick}
            variant='contained'
            className={classes.cards__map__control}
          >
            <Close />
          </Button>
        }
        <Popper open={open} anchorEl={anchorEl} transition disablePortal placement={'top-start'}>
          {({ TransitionProps }) => (
            <Grow
              {...TransitionProps}
            >
              <Paper id='menu-list-grow'>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList>
                    {
                      products.map((product) => (
                        <div
                          key={product.id}
                          data-intercom-target={product.intercomKey}
                        >
                          <MenuItem
                            selected={product.id === selectedProduct.id}
                            onClick={() => handleMenuItemClick(product)}
                          >
                            {product.label}
                          </MenuItem>
                        </div>
                      ))
                    }
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </div>
    )
  }
}

export default withStyles(styles)(ForecastControl)
