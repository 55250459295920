import { createMuiTheme } from '@material-ui/core/styles'

import defaultTheme, { themeOptions } from './defaultTheme'

const fixThemeOptions = (theme = { menus: {} }, themeOption = themeOptions.default) => {
  let themeOverride = theme

  if (themeOption === themeOptions.light) {
    themeOverride = {
      ...themeOverride,
      menus: {
        ...themeOverride.menus,
        icons: '#FAFAFA'
      }
    }
  }

  if (themeOverride && themeOverride.page && themeOverride.page.background) {
    return {
      ...themeOverride,
      palette: {
        ...themeOverride.palette,
        background: {
          ...themeOverride.palette.background,
          default: themeOverride.page.background
        },
      }
    }
  }

  return themeOverride
}

export const mountNewTheme = theme =>
  mountTheme(defaultTheme, fixThemeOptions(theme))

const mountTheme = (defaultTheme, theme = {}) =>
  Object.keys(defaultTheme).reduce((newTheme, key) => {
    if (_.isObject(defaultTheme[key])) {
      const currentTheme = theme ? theme[key] : {}
      newTheme[key] = mountTheme(defaultTheme[key], currentTheme)
    }
    else {
      newTheme[key] = theme[key] ? theme[key] : defaultTheme[key]
    }
    return newTheme
  }, {})

export default (theme, themeOption) =>
  createMuiTheme(
    mountTheme(defaultTheme, fixThemeOptions(theme, themeOption))
  )
