import React, { useState, useEffect } from "react";
import EditIcon from '@material-ui/icons/Edit'
import { Typography } from "@material-ui/core";
import { useHttpService } from "@contexts/HttpServiceProvider";
import { useSessionData } from '@contexts/SessionDataProvider'
import Dialog from "@ui/Dialog";
import Form from "@ui/Form";

const EditProductivityDialog = ({
  isOpen,
  setIsOpen,
  yield_value,
  yield_unity,
  id,
  harvesting_date
}) => {

  const { apiRequest } = useHttpService()
  const handleChangeState = async (action, values) => {
    try {
      await apiRequest.put(
        `/crops/${id}`,
        {
          crop: values,
        }
      )
    } catch (error) {
      console.log(`Erro ao atualizar ${action} Erro: ${error}`)
    }
    window.location.reload()
  }
  const handleEdit = (values) => {
    try {
      handleChangeState("_harvest", {
        finished_at: harvesting_date,
        yield_value: values.yield,
        yield_unity: values.unity
      })
    } catch (error) {
      console.log(`Erro ao atualizar ${action} Erro: ${error}`)
    }
  }

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      title={I18n.t("v4/area.dashboard.card.edit_produtivity")}
    >
      <Form
        action={I18n.t("actions.save")}
        handler={(values) => handleEdit(values)}
        onCancel={() => setIsOpen(false)}
        yieldValue={yield_value}
        yieldUnity={yield_unity}
        selectedAction
        hideDate
      />
    </Dialog>
  )
}

function ShowProdutivity({ yield_value, yield_unity, id, harvesting_date }) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(isOpen)
  }, [isOpen])

  return (
    <>
      <div style={{ display: 'flex' }}>
        <Typography variant="caption">
          {yield_value} {yield_unity}
        </Typography>

        <EditIcon
          color="primary"
          onClick={() => setIsOpen(true)}
          style={{ cursor: "pointer", marginLeft:'8px'}}
        />
      </div>
      <div>
        <EditProductivityDialog
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          yield_unity={yield_unity}
          yield_value={yield_value}
          id={id}
          harvesting_date={harvesting_date}
        />
      </div>
    </>
  )
}

export default ShowProdutivity;
