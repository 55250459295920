import React from 'react'
import { convertToLocaleDate, parseDecimalToTime } from '@services/locale'

export const chartSeries = [
  {
    name: 'saturated',
    unit: 'mm',
    type: 'area',
    id: 'saturated',
    color: '#B5E0FF',
    yReference: 'irrigationChart'
  },
  {
    name: 'ideal_moisture',
    unit: 'mm',
    type: 'area',
    id: 'ideal_moisture',
    color: '#CBFFB8',
    yReference: 'irrigationChart'
  },
  {
    name: 'alert_moisture',
    unit: 'mm',
    type: 'area',
    yReference: 'irrigationChart',
    id: 'alert_moisture',
    color: '#FFEFB5'
  },
  {
    name: 'hydric_deficit',
    unit: 'mm',
    type: 'area',
    yReference: 'irrigationChart',
    id: 'hydric_deficit',
    color: '#FFCAC7'
  },
  {
    name: 'pluviometer',
    hide: false,
    unit: 'mm',
    type: 'bar',
    id: 'pluviometer',
    stackId: 1,
    color: '#808080',
    yReference: 'irrigationChart'
  },
  {
    name: 'demanded_irrigation',
    hide: false,
    unit: 'mm',
    type: 'bar',
    id: 'demanded_irrigation',
    color: '#0066FF',
    yReference: 'irrigationChart'
  },
  {
    name: 'final_time',
    unit: 'h',
    type: 'line',
    yReference: 'irrigationChart',
    id: 'final_time',
    color: '#FFFF00'
  },
  {
    name: 'realized_irrigation',
    hide: false,
    unit: 'mm',
    type: 'bar',
    id: 'realized_irrigation',
    color: '#00BE02',
    yReference: 'irrigationChart'
  },
  {
    name: 'availability_soil_water',
    unit: 'mm',
    type: 'line',
    yReference: 'irrigationChart',
    id: 'availability_soil_water',
    color: '#000000'
  }
]

export const eventsTypes = [
  {
    name: 'registered_realized_irrigation',
    axisValue: 'realized_irrigation_registeded_axis_value',
    dot: ({ cx, cy, color = '#666666' }) =>
      <svg viewBox='0 0 28.77 40.61' x={cx - 10} y={cy - 10} width={20} height={20} fill={color}>
        <path fillRule='evenodd' d='M97.74,106.93a14.35,14.35,0,0,1-10.17-4.26,14.63,14.63,0,0,1-4.21-10.29c0-9.7,14.38-26.06,14.38-26.06s14.38,16.36,14.38,26.06a14.63,14.63,0,0,1-4.21,10.29,14.35,14.35,0,0,1-10.17,4.26Z' transform='translate(-83.36 -66.32)' />
      </svg>
  },
  {
    name: 'registered_demanded_irrigation',
    axisValue: 'demanded_irrigation_axis_value',
    dot: ({ cx, cy, color = '#666666' }) =>
      <svg x={cx - 10} y={cy - 10} viewBox='0 0 135 135' width={20} height={20} fill={color}>
        <rect x='51' y='19' width='34' height='103' fill='white' />
        <path d='M67.5503 1.87766e-05C54.198 -0.00993825 41.1426 3.94039 30.0357 11.3513C18.9287 18.7623 10.2693 29.3009 5.15262 41.634C0.035986 53.9671 -1.30792 67.5407 1.29089 80.6377C3.88971 93.7347 10.3145 105.767 19.7525 115.212C29.1905 124.657 41.2178 131.091 54.3129 133.699C67.408 136.308 80.9825 134.974 93.3195 129.867C105.656 124.759 116.201 116.108 123.621 105.006C131.04 93.9048 135 80.8524 135 67.5C135.007 58.6382 133.267 49.8618 129.88 41.6727C126.493 33.4835 121.526 26.042 115.262 19.7734C108.998 13.5048 101.56 8.53195 93.3738 5.13914C85.1872 1.74633 76.4122 1.6309e-05 67.5503 1.87766e-05V1.87766e-05ZM74.849 111.997H60.2517V97.3993H74.849V111.997ZM74.849 85.7215H60.2517L57.2315 22.953H77.8691L74.849 85.7215Z' fill={color} />
      </svg>
  },
  {
    name: 'phenological_stage',
    axisValue: 'phenological_stage_axis_value',
    dot: ({ cx, cy, color = '#666666' }) =>
      <svg x={cx - 10} y={cy - 10} viewBox='0 0 28 27' width={20} height={20} fill={color}>
        <path fillRule='evenodd' d='M81.82,100.78V97.64a50.07,50.07,0,0,1,15.92-3.13,50.07,50.07,0,0,1,15.92,3.13v3.14ZM96.63,80.56C94.71,74.44,85,75.86,85,75.86s.32,12.22,9.4,10.34c-.64-4.55-3-5.8-3-5.8,4.46,0,4.78,5.33,4.78,5.33v7.21h3.18V86.36s0-6.12,4.78-7.68c0,0-3.19,4.7-3.19,7.83,11.15,1.1,11.15-14,11.15-14S97.9,71,96.63,80.56Z' transform='translate(-81.82 -72.47)' />
      </svg>
  },
  {
    name: 'equipment_status',
    axisValue: 'equipment_status_axis_value',
    dot: ({ cx, cy, color = '#666666' }) =>
      <svg x={cx - 10} y={cy - 10} viewBox='0 0 28 27' width={20} height={20} fill={color}>
        <path fillRule='evenodd' d='M84.72,74.44,83,76.16l2,2c-.58.35-1.16.75-1.72,1.17l2.44,3.24a20.39,20.39,0,0,1,2.24-1.45l3,3a15.19,15.19,0,0,0-2.83,1.68l2.44,3.25a11.61,11.61,0,0,1,3.52-1.8l3.45,3.42A8.51,8.51,0,0,0,93,92.3l4.88,6.5,3.32-4.43,4.44,4.43,1.71-1.73Zm13.16,0a24.23,24.23,0,0,0-8.26,1.45l3.24,3.25a19.91,19.91,0,0,1,5-.64,20.14,20.14,0,0,1,12.17,4.06l2.44-3.24A24.21,24.21,0,0,0,97.88,74.44Zm0,8.12a10.91,10.91,0,0,0-1.52.07L100.68,87a11.93,11.93,0,0,1,4.5,2.1l2.44-3.25A16.15,16.15,0,0,0,97.88,82.56Z' transform='translate(-82.99 -74.44)' />
      </svg>
  },
  {
    name: 'observations',
    axisValue: 'observations_axis_value',
    dot: ({ cx, cy, color = '#666666' }) =>
      <svg x={cx - 10} y={cy - 10} width='20' height='20' viewBox='0 0 147 134' fill='none' xmlns='http://www.w3.org/2000/svg'>
        <rect width='108.813' height='133.107' rx='10.2653' fill={color} />
        <path d='M77.3323 93.6445V106.76H90.4473L129.128 68.079L116.013 54.964L77.3323 93.6445ZM139.27 57.9367C140.634 56.5728 140.634 54.3695 139.27 53.0055L131.086 44.8217C129.722 43.4578 127.519 43.4578 126.155 44.8217L119.755 51.2219L132.87 64.3369L139.27 57.9367V57.9367Z' fill={color} />
        <path fillRule='evenodd' clipRule='evenodd' d='M70.8308 90.9515L121.558 40.2245C125.461 36.3216 131.78 36.3216 135.683 40.2245L131.086 44.8217C129.722 43.4578 127.519 43.4578 126.155 44.8217L119.755 51.2218L132.87 64.3368L139.27 57.9367C140.634 56.5728 140.634 54.3694 139.27 53.0055L131.086 44.8217L135.683 40.2245L143.867 48.4083C147.77 52.3112 147.77 58.631 143.867 62.5339L93.1402 113.261H70.8308V90.9515ZM129.128 68.079L116.013 54.964L77.3322 93.6445V106.759H90.4472L129.128 68.079Z' fill='white' />
        <path d='M19.1624 25.6633H89.9932' stroke='white' strokeWidth='11.2919' />
        <path d='M19.1621 107.102H59.5391' stroke='white' strokeWidth='11.2919' />
        <path d='M19.1621 66.55H72.1997' stroke='white' strokeWidth='11.2919' />
      </svg>
  }
]

export const dateVisualizations = [
  {
    id: 'date',
    name: I18n.t('v4/irrigation_management.dashboard.visualizations.date')
  },
  {
    id: 'dap',
    name: I18n.t('v4/irrigation_management.dashboard.visualizations.dap')
  },
]

export const getDemandedIrrigationData = data => {
  const today = new Date().toLocaleDateString(I18n.locale, { timeZone: 'UTC' })

  const currentDate = data.find(({ date }) => date === today)

  if (_.isEmpty(currentDate)) return []

  return [
    {
      title: I18n.t('v4/irrigation_management.dashboard.demanded_irrigation'),
      value: currentDate.demanded_irrigation,
      unit: 'mm'
    },
    {
      title: I18n.t('v4/irrigation_management.dashboard.final_time'),
      value: parseDecimalToTime(currentDate.final_time),
      unit: ''
    },
    {
      title: I18n.t('v4/irrigation_management.dashboard.final_speed_relay'),
      value: currentDate.final_speed_relay,
      unit: '%'
    },
  ]
}

export const getAcumulatedInCropData = data => {

  if (_.isEmpty(data)) return []

  return [
    {
      title: I18n.t('v4/irrigation_management.dashboard.realized_irrigation'),
      value: data.realized_irrigation,
      unit: 'mm'
    },
    {
      title: I18n.t('v4/irrigation_management.dashboard.pluviometer'),
      value: data.pluviometer,
      unit: 'mm'
    },
  ]
}

const existManagementWithDate = (managements, date) =>
  managements.some(management => management.date === convertToLocaleDate(date))

const roundNumberData = (object = {}) =>
  Object.keys(object).reduce((newObject, objectKey) => {
    const value = object[objectKey]

    newObject[objectKey] =
      (value != null && !_.isObject(value) && !isNaN(value))
        ? _.round(value, 2)
        : value

    return newObject
  }, {})

export const deserializeData = (managements = []) => {
  if (_.isEmpty(managements)) return []

  const managementsData = managements.map(management => ({
    ...roundNumberData(management.data),
    date: convertToLocaleDate(management.date),
    dap: management.dap
  }))

  let forecasts = []

  const lastManagementForecasts = managements[managements.length - 1].forecast

  if (!_.isEmpty(lastManagementForecasts)) {
    forecasts = lastManagementForecasts
      .filter(forecast => !existManagementWithDate(managementsData, forecast.date))
      .map(forecast => ({
        ...roundNumberData(forecast),
        date: convertToLocaleDate(forecast.date)
      }))
  }

  return [...managementsData, ...forecasts]
}

export const deserializeEventsData = (events = [], data = []) => {
  const eventAxisValues = eventsTypes.reduce(
    (values, eventType, index) => {
      values[eventType.axisValue] = index + 1
      return values
    }, {})

  return data.map(({ date, dap }) => {
    const selectedEvent =
      events.find(
        event => convertToLocaleDate(event.date) === date
      ) || {}

    const normalizedEvent = roundNumberData(selectedEvent)

    return {
      date,
      dap,
      ...eventsData(normalizedEvent),
      ...eventAxisValues
    }
  })
}

const eventsData = (event = {}) => {
  const deserializedData = {}
  const { phenological_stage, registered_demanded_irrigation, registered_realized_irrigation, observations, equipment_status } = event

  if (!_.isEmpty(phenological_stage)) {
    deserializedData.phenological_stage =
      `${Object.values(phenological_stage.name_translations)[0]} (${phenological_stage.order})`
  }

  if (registered_demanded_irrigation != null) {
    deserializedData.registered_demanded_irrigation
      = `${registered_demanded_irrigation} mm`
  }

  if (registered_realized_irrigation != null) {
    deserializedData.registered_realized_irrigation
      = `${registered_realized_irrigation} mm`
  }

  if (observations) {
    deserializedData.observations = observations.map(({ title }) => title)
  }

  if (!_.isEmpty(equipment_status)) {
    deserializedData.equipment_status = equipment_status.map(occurrence => {
      const status = occurrence.offline
        ? 'offline'
        : occurrence.online
          ? 'online'
          : ''

      const date = new Date(occurrence[status]).toLocaleTimeString(I18n.locale, { timeZone: 'UTC', hour: '2-digit', minute: '2-digit' })

      return `${I18n.t('activerecord.models.v4/equipment.one')}: ${occurrence.equipment}, ${status}: ${date}`
    })
  }

  return deserializedData
}

export const deserializePhenologicalStages = phases =>
  phases.map(phase => ({
    ...phase,
    data: [
      {
        title: I18n.t('v4/irrigation_management.dashboard.realized_irrigation'),
        value: phase.realized_irrigation,
        unit: 'mm'
      },
      {
        title: I18n.t('v4/irrigation_management.dashboard.pluviometer'),
        value: phase.pluviometer,
        unit: 'mm'
      }
    ],
    start: convertToLocaleDate(phase.start),
    end: convertToLocaleDate(phase.end),
  }))

export const getArrayMaxIndexMinus = (array, number = 1) =>
  array.length - number > 0
    ? array.length - number
    : 0
