import status from 'http-status'

const deserializeError = (error) => {
  if (!error) return []

  if (error.response) {
    switch (error.response.status) {
      case status.UNPROCESSABLE_ENTITY:
        return getEntityErrors(error.response.data.errors ? error.response.data.errors : error.response.data)
      case status.UNAUTHORIZED:
        return [I18n.t(`errors.${status.UNAUTHORIZED}`)]
      case status.NOT_FOUND:
        return [I18n.t(`errors.${status.NOT_FOUND}`)]
      default:
        return [I18n.t('errors.default')]
    }
  }

  return []
}

const getEntityErrors = (errors) =>
  Object.keys(errors).map(errorKey =>
    `${errorKey}: ${errors[errorKey].map(
      (error, index) =>
       `${index === 0 ? '' : ' '}${_.capitalize(error)}`
    )}`
  )

export default deserializeError
