export default theme => ({
  PillTextInput__pills: {
    display: 'inline-block',
    margin: '3px',
    fontSize: '14px',
    padding: '5px 7px',
    border: '1px solid white',
    borderRadius: '15px',
    background: theme.palette.gray.main,
    borderColor: theme.palette.gray.main,
  },
  PillTextInput__pill__container: {
    listStyle: 'none',
    display: 'flex',
    padding: 0,
  },
})

