import React from 'react'
import { Form as FinalForm } from 'react-final-form'
import PropTypes from 'prop-types'

import { RailsForm } from '@support/RailsForm'
import InputForm from '../Form'
import OtherInputForm from './Form'
import { validate, otherInputShape } from '@models/inputs/other'

const EditOtherInput = ({ model, categories, o }) => {
  const {
    name, brand, category, detail, kind
  } = model;

  return (
    <RailsForm
      component={FinalForm}
      action='update'
      initialValues={{
        name, brand, category, detail, kind
      }}
      url={`/v4/o/${o}/inputs/other/${model.id}`}
      successUrl={`/v4/o/${o}/inputs/other/${model.id}`}
      validade={validate}
      render={(props) => (
        <InputForm
          title={`${I18n.t('actions.edit')} ${I18n.t(`v4/input.kinds.${kind}`)}`}
        >
          <OtherInputForm
            {...props}
            categories={categories}
            action={I18n.t('actions.update')}
            o={o}
          />
        </InputForm>
      )}
    />
  )
}

EditOtherInput.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  })),
  model: otherInputShape
}

export default EditOtherInput
