import React                 from 'react'
import { Form as FinalForm } from 'react-final-form'
import { RailsForm }         from '@support/RailsForm'
import Form                  from './Form'

export const AlertSettings = ({ model, organization, organization_product, o }) =>
  <RailsForm
    component={FinalForm}
    action='create'
    url={`/v4/o/${o}/admin/organizations/${organization.id}/products/${organization_product.id}/alert/product_configs/alert_settings`}
    successUrl={`/v4/o/${o}/admin/organizations/${organization.id}/products`}
    initialValues={model}
    render={(props) => (
      <Form
        {...props}
        organization_id={organization.id}
        title={I18n.t('v4/v4_alert_product_configs.configuration.title')}
        o={o}
      />
    )}
  />

export default AlertSettings
