import React, { useEffect, useState } from 'react'

import {
  Grid,
  withStyles
} from '@material-ui/core'

import { alphaSorting, notAlphaSorting } from '@models/sorting'
import Filter from '../Filter'
import Card from './Card'
import NoCards from './NoCards'

const Cards = ({
  classes,
  data
}) => {
  const [sortedData, setSortedData] = React.useState([])
  const [availableDates, setAvailableDates] = React.useState([])
  const [orderBy, setOrderBy] = React.useState([])
  const [running, setRunning] = React.useState({})

  const setInitialData = () => {
    if (!!data) {
      const { running } = data
      setRunning(running || [])
      setInitialSortedData(running)
    }
  }

  const setInitialSortedData = (running) => {
    if (!!running.content && !_.isEmpty(running.content)) {
      setSortedData(running.content[0])
      setAvailableDates(running.dates)
      setOrderBy(running.orderBy)
    }
  }

  const getSortedData = (sortDescriber) => {
    if (sortedData) {
      const finalSortedContent = running.content[sortDescriber.date]

      if (sortDescriber.orderBy) {
        if (sortDescriber.orderBy === 'alpha') {
          finalSortedContent.sort((a, b) => alphaSorting(a, b, sortDescriber))
        } else {
          finalSortedContent.sort((a, b) => notAlphaSorting(a, b, sortDescriber))
        }
      }

      setSortedData([...finalSortedContent])
    }
  }

  useEffect(() => {
    setInitialData()
  }, [])

  return (
    <div>
      <Filter
        availableDates={availableDates}
        orderBy={orderBy}
        hiddenFields={['showFinishedManagements']}
        setSortedData={content => getSortedData(content)}
      />

      {
        !_.isEmpty(sortedData)
          ? <Grid container spacing={40}>
            {
              sortedData.map((datum, index) =>
                <Grid
                  item xs={12} md={6} lg={4}
                  key={`${datum.irrigationSystem}__${index}`}
                  className={classes.Card_wrapper}
                >
                  <Card data={datum} />
                </Grid>
              )
            }
          </Grid>
          : <NoCards />
      }
    </div >
  )
}

const styles = () => ({
  Card_wrapper: {
    display: 'flex',
    justifyContent: 'center'
  }
})

export default withStyles(styles)(Cards)
