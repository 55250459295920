import React, { createContext, useContext, useMemo } from 'react'
import { visit, getBaseURL, getLink } from '@services/httpService'

const RouterContext = createContext()

export const useRouter = () => useContext(RouterContext)

const RouterProvider = ({ organizationIndex, children }) => {
  const value = useMemo(() => ({
    baseURL: getBaseURL(organizationIndex),
    visit: url => visit(organizationIndex, url),
    getLink: url => getLink(organizationIndex, url)
  }), [organizationIndex])

  return (
    <RouterContext.Provider value={value}>
      {children}
    </RouterContext.Provider>
  )
}

export const withRouter = component => props =>
  React.createElement(component, { ...props, router: useRouter() })

export default RouterProvider
