import React from 'react'
import DateFnsUtils from '@date-io/date-fns'
import { localeFormats, localeMap } from '@services/locale'
import { DatePicker, MuiPickersUtilsProvider } from 'material-ui-pickers'
import { withStyles } from '@material-ui/core/styles'

const labelsTranslations = {
  cancelLabel: I18n.t('datepicker.cancel'),
  clearLabel: I18n.t('datepicker.clear'),
  format: localeFormats.date[I18n.locale],
  maxDateMessage: I18n.t('datepicker.max_date_message'),
  minDateMessage: I18n.t('datepicker.min_date_message'),
  okLabel: I18n.t('datepicker.accept'),
  invalidDateMessage: I18n.t('datepicker.invalid_date')
}

const styles = () => ({
  withInfo: {
    boxSizing: 'border-box'
  },
  removeAutoComplete: {
    '-webkit-text-fill-color': 'black',
    'transition': 'background-color 5000s ease-in-out 0s'
  }
})

export const SimpleDatePicker = withStyles(styles)(({ classes, InputProps, variant, label, ...props }) =>
  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[I18n.locale]}>
    <DatePicker
      clearable={false}
      label={label}
      keyboard
      variant={variant}
      {...props}
      InputProps={{
        ...InputProps,
        className: classes.withInfo,
        classes: {
          input: classes.removeAutoComplete
        }
      }}
      {...labelsTranslations}
    />
  </MuiPickersUtilsProvider>
)

SimpleDatePicker.defaultProps = {
  variant: 'outlined'
}

const ControlledDatePicker = ({ initialValue, input, label, meta: { touched, error }, InputProps, classes, variant, ...props }) =>
  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[I18n.locale]}>
    <DatePicker
      clearable={false}
      error={!!(touched && error)}
      helperText={touched && error}
      keyboard
      label={label}
      variant={variant}
      {...input}
      {...props}
      InputProps={{
        ...InputProps,
        className: classes.withInfo,
        classes: {
          input: classes.removeAutoComplete
        }
      }}
      {...labelsTranslations}
    />
  </MuiPickersUtilsProvider>

ControlledDatePicker.defaultProps = {
  meta: {},
  variant: 'outlined'
}

export default withStyles(styles)(ControlledDatePicker)
