export default (theme) => ({
  page__wrapper: {
    margin: '40px auto 0',
    padding: 32,
    maxWidth: '80%'
  },
  media: {
    height: 180
  },
  store__wrapper: {
  },
  store__product__card: {
    display: 'flex',
    justifyContent: 'center'
  },
  navbar__wrapper: {
    maxWidth: '100%',
    marginBottom: 32
  },
  navbar__header: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  card: {
    width: 270,
    height: 510
  },
  card__footer: {
    justifyContent: 'center',
    height: 79
  },
  card__max__value: {
    textDecoration: 'line-through',
    marginRight: 10,
    color: theme.palette.gray.dark
  },
  card__discount: {
    padding: '2px 12px',
    background: theme.palette.primary.main,
    color: theme.palette.white,
    fontWeight: 700,
    borderRadius: 100
  },
  card__item__value: {
    color: theme.palette.gray.dark
  },
  card__button: {
    width: '100%'
  },
  card__discount__value: {
    fontWeight: 700
  },
  card__center: {
    justifyContent: 'center',
    textAlign: 'center'
  },
  card__green: {
    color: theme.palette.primary.main,
    height: '60px'
  },
  card__sign: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingTop: 5
  },
  card__value: {
    color: theme.palette.primary.main,
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 30,
    fontWeight: 600,
    padding: '0 2px'
  },
  card__item__value__monthly: {
    display: 'flex',
    justifyContent: 'center'
  },
  card__monthly: {
    display: 'flex',
    alignItems: 'flex-end',
    paddingBottom: 5
  },
  navbar__plan: {
    display: 'inline'
  },
  showPage__action: {
    marginTop: theme.spacing.unit * 3
  },
  showPage__wrapper: {
    backgroundColor: 'transparent',
    margin: `${theme.spacing.unit * 5}px auto 0`,
    maxWidth: 1200,
    padding: theme.spacing.unit * 4
  },
  showPage__header: {
    marginBottom: theme.spacing.unit * 4
  },
  showPage__wrapperImage: {
    padding: '10px 0',
    display: 'flex',
    justifyContent: 'center'
  },
  showPage__imageProduct: {
    height: '150px'
  },
  showPage__titleProduct: {
    fontWeight: '900',
    fontSize: '20px'
  },
  showPage__descriptionProduct: {
    padding: '5px 0 10px 0',
    textAlign: 'justify'
  },
  showPage__cartWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    color: theme.palette.gray.dark
  },
  showPage__priceProdut: {
    color: 'black'
  },
  showPage__cart: {
    padding: '10px'
  },
  showPage__select__wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: theme.spacing.unit
  },
  showPage__dollar__sign: {
    display: 'flex',
    alignItems: 'flex-start',
    paddingTop: 2
  },
  showPage__monthly__price: {
    color: theme.palette.primary.main,
    alignItems: 'center',
    justifyContent: 'center',
    fontSize: 30,
    fontWeight: 600,
    padding: '0 2px'
  },
  showPage__wrapper: {
    backgroundColor: 'transparent',
    margin: `${theme.spacing.unit * 5}px auto 0`,
    maxWidth: 1200,
    padding: theme.spacing.unit * 4
  },
  showPage__item__value__monthly: {
    display: 'flex'
  },
  showPage__monthly__monthly: {
    display: 'flex',
    alignItems: 'flex-end',
    paddingBottom: 5,
    color: theme.palette.gray.dark
  },
  showPage__wrapper_values: {
    justifyContent: 'center',
    margin: '0px 70px 0 0'
  },
  showPage__total__price: {
    color: theme.palette.gray.dark
  },
  showPage__reference: {
    color: theme.palette.gray.dark
  },
  showPage__wrapper__description__plan: {
    display: 'flex'
  },
  showPage__select_plan_title: {
    marginRight: theme.spacing.unit
  },
  showPage__billing_column: {
    display: 'flex',
    margin: 'auto',
    justifyContent: 'center',
    flexDirection: 'column'
  },
  card_subtitle: {
    color: theme.palette.gray.dark,
    height: '60px',
    overflow: 'hidden'
  },
  card_discount: {
    background: theme.palette.primary.main,
    width: '180px',
    height: '57px',
    borderRadius: '0px 0px 5px 5px',
    borderTopRightRadius: 0,
    borderTopLeftRadius: 0,
    display: 'flex',
    alignItems: 'center'
  },
  card_discount_text: {
    color: theme.palette.white,
    '&:not(:last-child)': {
      padding: theme.spacing.unit
    }
  },
  card_content: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginBottom: '70px'
  }
})
