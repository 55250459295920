import React from 'react'

import {
  Grid,
  IconButton,
  Link,
  Paper,
  Typography
} from '@material-ui/core'
import { Clear } from '@material-ui/icons'
import withStyles from '@material-ui/core/styles/withStyles'

import { useRouter } from '@contexts/RouterProvider'

const Header = ({
  classes,
  handleClose
}) => {
  const router = useRouter()

  return (
    <Paper square className={classes.Header_wrapper}>
      <Grid container spacing={0}>
        <Grid item xs={6}>
          <Typography variant='subtitle2'>
            {I18n.t('v4/forecast.aqua')}
          </Typography>
        </Grid>
        <Grid item xs={6} className={classes.Header_rightAlign}>
          <Link
            component='button'
            onClick={() => router.visit('/irrigation_managements')}
            variant="button"
            color='primary'
          >
            {I18n.t('v4/forecast.details')}
          </Link>

          <IconButton aria-label="Delete" className={classes.Header_button} onClick={handleClose}>
            <Clear />
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  )
}

const styles = (theme) => ({
  Header_wrapper: {
    background: theme.palette.white,
    padding: (theme.spacing.unit * 2) + 4
  },
  Header_button: {
    padding: 0,
    marginLeft: theme.spacing.unit + 2
  },
  Header_label: {
    textTransform: 'capitalize',
    fontSize: '10pt',
    fontWeight: 400
  },
  Header_rightAlign: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  }
})

export default withStyles(styles)(Header)
