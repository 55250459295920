import React from 'react'

import {
  DialogContentText,
  DialogActions,
  Button,
  withStyles
} from '@material-ui/core'

import Delete from '@material-ui/icons/Delete'

const CancelHarvest = ({ classes, onCancel, handler, intercomTarget }) =>
  <>
    <DialogContentText>
      {I18n.t('v4/area.dashboard.phenological_stages.actions.cancel.content')}
    </DialogContentText>

    <DialogContentText>
      {I18n.t('v4/area.dashboard.phenological_stages.actions.cancel.alert')}
    </DialogContentText>

    <DialogActions>
      <Button
        color='secondary'
        onClick={onCancel}
      >
        {I18n.t('actions.back')}
      </Button>

      <Button
        className={classes.Dialog_delete}
        type='submit'
        variant='contained'
        onClick={handler}
        data-intercom-target={intercomTarget}
      >
        {I18n.t('v4/area.dashboard.phenological_stages.actions.cancel.title')}

        <Delete />
      </Button>
    </DialogActions>
  </>

const styles = theme => ({
  Dialog_delete: {
    backgroundColor: theme.palette.trialColorRed,
    '&:hover': {
      backgroundColor: theme.palette.hoverRed,
    },
    color: theme.palette.white
  }
})

export default withStyles(styles)(CancelHarvest)
