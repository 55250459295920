export default theme => ({
  part_number_registries: {
    backgroundColor: 'transparent',
    margin: `${theme.spacing.unit * 5}px auto 0`,
    maxWidth: 1200,
    overflowX: 'auto',
    padding: theme.spacing.unit * 4,
    width: '100%'
  },

  part_number_registries__newPart: {
    marginBottom: theme.spacing.unit * 4
  },

  part_number_registries__header: {
    marginBottom: theme.spacing.unit * 4
  },

  part_number_registries__table: {
    width: '100%'
  },

  part_number_registries__tableHead: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white,
    fontSize: theme.typography.subheading.fontSize,
    fontWeight: theme.typography.subheading.fontWeight
  },

  part_number_registries__tableRowWithLink: {
    cursor: 'pointer'
  },

  part_number_registries__tableWrapper: {
    margin: '0 auto',
    maxWidth: 1200,
    overflowX: 'auto'
  }
})
