import React from 'react'

import { Fade } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Clear'

import withStyles from '@material-ui/core/styles/withStyles'

import styles from './styles'

const InfoMenuWrapper = ({
  classes,
  children,
  open,
  onClose
}) =>
  <div className={classes.menu__info__wrapper__fade}>
    <Fade in={open} timeout={800}>
      <div className={classes.menu__info__wrapper__fade}>
        <div className={classes.menu__info__container}>
          <CloseIcon
            className={classes.close__info__menu}
            onClick={onClose}
          />
          {children}
        </div>
      </div>
    </Fade>
  </div>

export default withStyles(styles)(InfoMenuWrapper)
