import React from 'react'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import { Field } from 'react-final-form'
import SelectFlagIcon from '@ui/Fields/FlagIcon/Index'
import TextField from '../../ui/Fields/TextField'
import styles from '@theme/styles/forms'

const Form = ({
  handleSubmit,
  classes,
  organization,
  title,
  o
}) =>
  <form className={classes.form} onSubmit={handleSubmit} data-testid="invitation-form">
    <Paper className={classes.form__wrapper} elevation={0}>
      <header className={classes.form__header}>
        <Typography component='h2' variant='h5'>
          {title}
        </Typography>
      </header>
      <main className={classes.form__fields}>
        <div className={classes.form__field__wrapper}>
          <Field
            component={TextField}
            className={classes.form__fieldWithError}
            fullWidth
            InputLabelProps={{ shrink: true }}
            label={I18n.t('activerecord.attributes.v4/users.email')}
            margin='normal'
            name='email'
            type='email'
            variant="outlined"
            required
          />
        </div>
        <div className={classes.form__field__wrapper}>
          <Field
            component= {SelectFlagIcon}
            className={classes.form__fieldWithError}
            InputLabelProps={{ shrink: true, required: true }}
            label={I18n.t('activerecord.attributes.v4/organization.language')}
            name='email_locale'
            margin='normal'
            fields={{ id:'id', idIcon: 'idIcon', name: 'name' }}
            data={countries}
            fullWidth
            variant="outlined"
            required
          >
          </Field>
        </div>
      </main>
      <footer className={classes.form__actions}>
        <Button
          className={classes.form__action}
          color='primary'
          type='submit'
          variant='contained'
        >
          {title}
        </Button>

        <Button
          className={classes.form__action}
          color='secondary'
          href={`/v4/o/${o}/organizations/${organization.id}/users`}
        >
          {I18n.t('actions.back')}
        </Button>
      </footer>
    </Paper>
  </form>

const countries = [
  {
    id: 'pt-BR',
    idIcon: 'br',
    name: I18n.t('activerecord.attributes.v4/organization.countries.br')
  },
  {
    id: 'es-MX',
    idIcon: 'es',
    name: I18n.t('activerecord.attributes.v4/organization.countries.es')
  },
  {
    id: 'en',
    idIcon: 'us',
    name: I18n.t('activerecord.attributes.v4/organization.countries.us')
  }
]

export default withStyles(styles)(Form)
