import { apiService } from '@services/httpService'
import { getRemainingDays } from '@services/locale'

export const fetchProduct = async (product, productsList, params) => {
  const selectedProduct = productsList.find(p => p.id === product.id)

  if (!selectedProduct) return

  const { data } = await apiService().get(selectedProduct.url, { params })

  selectedProduct.data = data || selectedProduct.data
  selectedProduct.fetched = true

  const products = productsList.map(product =>
    product.id === selectedProduct.id
      ? selectedProduct
      : product
  )

  return {
    selectedProduct,
    products
  }
}

export const getTrialInfo = (trial = {}) => {
  if (!_.isEmpty(trial) && !!trial.is_trial) {
    return {
      isTrial: trial.is_trial,
      expirationDate: getRemainingDays(trial.expiration_date)
    }
  }

  return {
    isTrial: false
  }
}
