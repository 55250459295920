export default (theme) => ({
  samples__header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '10px 0',
    padding: '5px'
  },
  showPage__wrapper: {
    margin: '2px 3%',
    maxWidth: 700,
    padding: theme.spacing.unit
  },
  sample__content: {
    display: 'flex',
    flexWrap: 'wrap',
  },
  sample__newSample: {
    borderRadius: '50%',
    minWidth: 'auto',
    padding: '10px'
  },
  sample__item: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '2px',
    width: '100%'
  }
})
