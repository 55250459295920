const fields = ['id', 'name', 'url']

const requiredFields = ['name', 'url']

export const getInsightHeaders = () => 
fields.map(field => ({
  title: I18n.t(`v4/insight.headers.${field}`),
  field
}))

export const validate = (values) => {
  const errors = {}

  requiredFields.forEach(field => {
    if (!values[field]) errors[field] = I18n.t('validations.required')
  })

  const httpsRegEx = /(^https?:\/\/)/g
  const isValidUrl = httpsRegEx.test(values['url'])

  if(values['url'] && !isValidUrl) errors['url'] = I18n.t('validations.https_required')

  return errors
}
