import React from 'react'

import { Paper, withStyles, Typography, Button } from '@material-ui/core'

import {
  irrigationVisualizationsOptions,
  irrigationVisualizationsComponents,
  irrigationManagementsStatus,
  visualizations,
  updateManagement
} from '@models/irrigation_managements'
import { formatApiData } from '@models/irrigation_managements/format'
import Loading from '@ui/LoadingComponent'
import ChangeStatusDateDialog from '@models/irrigation_managements/ChangeStatusDateDialog'

import ButtonArea from './ButtonArea'

import { createLocalStorageObject } from '@services/localStorage'
import updateIfSameAttribute from '@services/updateIfSameAttribute'
import { withHttpService } from '@contexts/HttpServiceProvider'

import styles from './styles'

const irrigationSavedParamsStorage = createLocalStorageObject('irrigationSavedParams')

export class Index extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedVisualization: this.getInitialVisualization(),
      loading: false,
      finishedManagements: [],
      runningManagements: [],
      managements: [],
      updateStatusDialog: {
        open: false,
        title: '',
        handleSubmit: () => { },
        description: '',
        action: '',
        selectedId: ''
      }
    }
  }

  getInitialVisualization = () => {
    const defaultVisualization = visualizations.table

    const { dashboardVisualization } = irrigationSavedParamsStorage.get({})

    return dashboardVisualization || defaultVisualization
  }

  async componentDidMount() {
    this.setState({ loading: true })
    try {
      const { organization_id, httpService } = this.props
      const params = { organization_id }

      const irrigationManagements = await httpService.apiRequest('irrigation_managements/hydric_balances', { params })
      const areas = await httpService.apiRequest('areas', { params })

      const { finishedManagements, runningManagements } = formatApiData(irrigationManagements.data)

      this.setState({
        managements: irrigationManagements.data,
        runningManagements,
        finishedManagements,
        areas: areas.data
      })
    } catch (e) {
      console.error(e)
    }
    this.setState({ loading: false })
  }

  handleVisualizationChange = visualization => {
    const irrigationSavedParams = irrigationSavedParamsStorage.get({})

    irrigationSavedParamsStorage.set({
      ...irrigationSavedParams,
      dashboardVisualization: visualization
    })

    this.setState({
      selectedVisualization: visualization
    })
  }

  handleUpdateManagement = async (id, data) => {
    const { handleError, httpService } = this.props
    const { managements } = this.state

    this.setState({ loading: true })

    try {
      const updatedManagement = await updateManagement(id, data, httpService)

      const updatedManagements = managements.map(
        updateIfSameAttribute(['id', updatedManagement.id], (management) => ({
          ...management,
          ...updatedManagement
        }))
      )

      const { finishedManagements, runningManagements } = formatApiData(updatedManagements)

      this.setState({
        managements: updatedManagements,
        runningManagements,
        finishedManagements
      }, this.handleDialogClose)
    }
    catch (error) {
      handleError(handleError)
      this.handleDialogClose()
    }

    this.setState({ loading: false })
  }

  handleDialogClose = () => {
    this.setState(({ updateStatusDialog }) => ({
      updateStatusDialog: {
        ...updateStatusDialog,
        open: false
      }
    }))
  }

  handleOpenDialog = (selectedId, status) => {
    const statusData = this.getSelectedStatusData(status)

    this.setState(({ updateStatusDialog }) => ({
      updateStatusDialog: {
        ...updateStatusDialog,
        ...statusData,
        selectedId,
        open: true
      }
    }))
  }

  getSelectedStatusData = status => {
    const avaliableStatus = {
      [irrigationManagementsStatus.created]: {
        title: '',
        handleSubmit: (id, { date }) => this.handleUpdateManagement(id, { started_at: date }),
        description: I18n.t('v4/irrigation_management.status.created.dialog.description'),
        action: I18n.t('v4/irrigation_management.status.created.action')
      },
      [irrigationManagementsStatus.started]: {
        title: I18n.t('v4/irrigation_management.status.started.dialog.title'),
        handleSubmit: (id, { date }) => this.handleUpdateManagement(id, { finished_at: date }),
        description: I18n.t('v4/irrigation_management.status.started.dialog.description'),
        action: I18n.t('v4/irrigation_management.status.started.action')
      }
    }

    return avaliableStatus[status] || {}
  }

  getManagementStatusButton = ({ id, status }) => {
    const actions = {
      [irrigationManagementsStatus.created]: {
        icon: 'play_arrow',
        tooltip: I18n.t('v4/irrigation_management.status.created.action'),
        onClick: () =>
          this.handleOpenDialog(id, irrigationManagementsStatus.created)
      },
      [irrigationManagementsStatus.started]: {
        icon: 'power_settings_new',
        tooltip: I18n.t('v4/irrigation_management.status.started.action'),
        onClick: () =>
          this.handleOpenDialog(id, irrigationManagementsStatus.started)
      }
    }

    return actions[status] || null
  }

  render() {
    const { classes, handleError, organization_id, o } = this.props
    const {
      selectedVisualization,
      areas,
      loading,
      updateStatusDialog,
      finishedManagements,
      runningManagements
    } = this.state

    return (
      <Paper className={classes.Page_wrapper} elevation={0}>
        <header className={classes.page__header}>
          <Typography component='h2' variant='h5'>
            {I18n.t('v4/irrigation_management.title')}
          </Typography>
        </header>

        <ButtonArea
          selectedVisualization={selectedVisualization}
          visualizations={irrigationVisualizationsOptions}
          organization_id={organization_id}
          setVisualization={this.handleVisualizationChange}
          areas={areas}
          handleError={handleError}
          o={o}
        />

        {
          loading
            ? <Loading />
            : irrigationVisualizationsComponents[selectedVisualization]({
              finishedManagements,
              runningManagements,
              o,
              getManagementStatusButton: this.getManagementStatusButton
            })
        }

        <ChangeStatusDateDialog
          {...updateStatusDialog}
          handleClose={this.handleDialogClose}
        />
      </Paper>
    )
  }
}

const customStyles = theme => ({
  ...styles(theme),
  Page_wrapper: {
    margin: '40px auto 0',
    padding: theme.spacing.unit * 4,
    maxWidth: '1300px',
    backgroundColor: 'transparent'
  }
})

export default withHttpService(
  withStyles(customStyles)(Index)
)
