import React, { Component } from 'react'

import ReactMde from "react-mde"
import * as Showdown from "showdown"
import "react-mde/lib/styles/css/react-mde-all.css"

import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

import styles from './styles'

const converter = new Showdown.Converter({
  tables: true,
  simplifiedAutoLink: true,
  strikethrough: true,
  tasklists: true
})

const Index = ({ classes, ...props }) => {
  const [selectedTab, setSelectedTab] = React.useState("write")

  return (
    <div className={classes.mdeditor__container}>
      <Typography
        className={
          props.meta.error && props.meta.modified
            ? classes.mdeditor__label__warning
            : classes.mdeditor__label
        }
      >
        {props.label}{props.required && ' *'}
      </Typography>
      <div
        className={
          props.meta.error && props.meta.modified
            ? classes.mdeditor__wrapper__warning
            : classes.mdeditor__wrapper
        }
      >
        <ReactMde
          value={props.input.value}
          textAreaProps={{ required: props.required }}
          onChange={props.input.onChange}
          selectedTab={selectedTab}
          onTabChange={setSelectedTab}
          readOnly={props.readOnly}
          generateMarkdownPreview={markdown =>
            Promise.resolve(converter.makeHtml(markdown))
          }
        />
      </div>
      {
        props.meta.error && props.meta.modified &&
          <Typography
            className={classes.mdeditor__label__warning}
          >
            {props.meta.error}
          </Typography>
      }
    </div>
  )
}

Index.defaultProps = {
  initialValue: ""
}

export default withStyles(styles)(Index)
