import React from 'react'
import { Form as FinalForm } from 'react-final-form'
import PropTypes from 'prop-types'
import httpService from '@services/httpService'

import { RailsForm } from '@support/RailsForm'
import { productConfigurationUrls } from '@models/admin/organizationProducts'
import Form from './Form'

export const NewProduct = ({ products, organization, handleError, o, show_trial }) =>
  <RailsForm
    component={FinalForm}
    action='create'
    url={`/v4/o/${o}/admin/organizations/${organization.id}/products`}
    successUrl={`/v4/o/${o}/admin/organizations/${organization.id}/products`}
    initialValues={{ organization_id: organization ? organization.name : '' }}
    handleSubmit={values => {
      const product = products.find(p => p.id === values.product_id)

      handleSubmit(values, product.kind, organization.id, handleError, o)
    }}
    render={(props) => (
      <Form
        {...props}
        currentOrganization={organization}
        products={products}
        title={`${I18n.t('actions.add')} ${I18n.t('activerecord.models.v4/product.one')}`}
        o={o}
        showTrial={show_trial}
      />
    )}
  />


NewProduct.propTypes = {
  model: PropTypes.object,
}

const handleSubmit = async (values, kind, organizationId, handleError, o) => {
  try {
    const { data } = await httpService({
      method: 'POST',
      url: `/v4/o/${o}/admin/organizations/${organizationId}/products.json`,
      data: values
    })

    Turbolinks.visit(productConfigurationUrls(kind, data.organization_id, data.id, o))
  }
  catch (error) {
    handleError(error)
  }
}

export default NewProduct
