import React from 'react'

import { Divider } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'

import Card from './Card'

const Body = ({ cards, classes }) =>
  <div className={classes.Body_wrapper}>
    {
      cards.map((card, index) =>
        <div key={`weather-forecast-day-${index}`}>
          <Card
            values={card.values}
            header={card.header}
            index={index}
            sprayingCondition={card.sprayingCondition}
          />
          {index < cards.length - 1 && <Divider className={classes.Body_divider} />}
        </div>
      )}
  </div>

const styles = (theme) => ({
  Body_wrapper: {
    padding: `0 ${theme.spacing.unit + 2}px`,
    maxHeight: 'calc(100vh - 300px)',
    overflowY: 'auto',
    ...theme.scrollbar
  },
  Body_divider: {
    height: 2
  }
})

export default withStyles(styles)(Body)
