import React from 'react'
import PropTypes from 'prop-types'

import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

const Show = ({ classes, variables }) =>
  <Paper
    className={classes.showPage__wrapper}
    elevation={0}
  >
    <div className={classes.sensor__content}>

      {
        !_.isEmpty(variables) ?
          variables.map(field =>
            <div key={field.name} className={classes.sensor__variable}>
              <Typography
                color='textSecondary'
                style={{ fontSize: 'inherit' }}
              >
                {field.name}

              </Typography>
              {
                field.type == 'Boolean' ?
                  I18n.t(String(field.value))
                  :
                  <Typography
                    style={{ fontSize: 'inherit' }}
                  >
                    {field.value}
                  </Typography>
              }
            </div>
          )
          :
          <Typography
            style={{ fontSize: 'inherit', margin: 'auto' }}
          >
            {I18n.t('info.no_data')}
          </Typography>
      }
    </div>
  </Paper>

Show.propTypes = {
  variables: PropTypes.array
}

Show.defaultProps = {
  variables: {}
}

export default Show
