import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { getDashboardSeasonsHeaders } from '@models/seasons'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import Table from '@ui/Table'

import styles from '@styles/indexPage'

class Index extends Component {
  formatDate = (date) => {
    let newDate = new Date(date).toLocaleDateString(
      I18n.locale,
      {timeZone: 'UTC'}
    )

    return newDate
  }

  render() {
    const { classes, model } = this.props
    let start_date = this.formatDate(model.start_date)
    let end_date = this.formatDate(model.end_date)

    return(
      <Paper className={classes.page__wrapper} elevation={0}>
        <header className={classes.page__header}>
          <Typography component='h2' variant='h5'>
            {`${model.name}`}
          </Typography>
          <Typography component='h2' variant='caption'>
            {`Inicio: ${start_date} - Fim: ${end_date}`}
          </Typography>
        </header>

        <Table
          tableKey='seasons-dashboard-index'
          components={{
            Container: props => <div>{props.children}</div>,
          }}
          columns={getDashboardSeasonsHeaders()}
          data={model.areas}
          options={{
            showTitle: false
          }}
        />
      </Paper>
    )
  }
}


Index.defaultProps = {
  model: []
}

export default withStyles(styles)(Index)
