import React from 'react'

import {
  ExpansionPanel,
  ExpansionPanelDetails,
  ExpansionPanelSummary,
  Typography,
  withStyles
} from '@material-ui/core'

import ExpandMoreIcon from '@material-ui/icons/ExpandMore'

const Panel = ({ title, children, disabled, expandIcon, classes }) =>
  <ExpansionPanel
    disabled={disabled}
  >
    <ExpansionPanelSummary
      expandIcon={expandIcon}
      className={classes.ExpansionPanelSummary}
    >
      <Typography variant='body2'>{title}</Typography>
    </ExpansionPanelSummary>

    <ExpansionPanelDetails
      classes={{ root: classes.ExpansionPanelDetails_root }}
    >
      {children}
    </ExpansionPanelDetails>
  </ExpansionPanel>

Panel.defaultProps = {
  disabled: false,
  expandIcon: <ExpandMoreIcon />
}

const styles = theme => ({
  ExpansionPanelDetails_root: {
    display: 'block'
  },
  ExpansionPanelSummary: {
    backgroundColor: theme.palette.gray.light
  }
})

export default withStyles(styles)(Panel)
