import React from 'react'

import { Paper, Typography, withStyles } from '@material-ui/core'
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery'

import styles from './styles'

import DashboardTable from './Table'
import ForecastWrapper from './ForecastWrapper'
import Header from './Header'

const Dashboard = ({
  classes,
  descriptions,
  hourlyData,
  dailyData,
  dailyDescriptions,
  handleSelectedPreviewMode,
  handleSelectedForecastPoint,
  forecastList,
  organizationId,
  onRef,
  previewMode,
  selectedForecastIndex
}) => {
  const isMobile = useMediaQuery('(max-width:800px)')

  return (
    <Paper
      className={classes.Dashboard__wrapper}
      elevation={0}
    >
      <Header
        previewMode={previewMode}
        handleSelectedPreviewMode={handleSelectedPreviewMode}
        forecastList={forecastList}
        selectedForecastIndex={selectedForecastIndex}
        handleSelectedForecastPoint={handleSelectedForecastPoint}
      />

      {
        previewMode === 'hourly'
          ? (
            !_.isEmpty(hourlyData[selectedForecastIndex])
              ? (
                <DashboardTable
                  descriptions={descriptions}
                  data={hourlyData[selectedForecastIndex].data}
                  isMobile={isMobile}
                />
              )
              : (
                <Typography className={classes.Dashboard__no_data} component='h3' variant='h5'>
                  {I18n.t('info.no_data')}
                </Typography>
              )
          )
          : (
            !_.isEmpty(dailyData)
              ? (
                <ForecastWrapper
                  dailyData={dailyData}
                  organizationId={organizationId}
                  onRef={onRef}
                  descriptors={dailyDescriptions}
                  data={dailyData[selectedForecastIndex].data}
                />
              )
              : (
                <Typography className={classes.Dashboard__no_data} component='h3' variant='h5'>
                  {I18n.t('info.no_data')}
                </Typography>
              )
          )
      }
    </Paper>
  )
}

export default withStyles(styles)(Dashboard)
