import React from 'react'
import { InputLabel, Button } from '@material-ui/core'
import PropTypes from 'prop-types'
import DatePicker from '@ui/Fields/DatePicker'
import moment from 'moment'
import { Slider } from 'material-ui-slider'

const FilterForm = ({ classes, filter, handleSubmit, disabled }) => {
  const [state, setState] = React.useState({
    clouds_percentage: 100,
    start_date: null,
    end_date: null
  })

  const handleChange = ({ name, value }) => {
    setState({
      ...state,
      [name]: value
    })
  }

  return filter && <form
    className={classes.SatelliteImages__filter_form}
    onSubmit={(
      e => {
        e.preventDefault()
        handleSubmit(state)
      })
    }
  >

    <div className={classes.SatelliteImages__filter_form__clouds_slider}>
      <InputLabel shrink>
        {I18n.t('v4/satellite_images.attributes.clouds')}: {state.clouds_percentage}%
      </InputLabel>
      <div>
        <Slider
          value={state.clouds_percentage}
          onChange={(value) => handleChange({ name: 'clouds_percentage', value })}
          name='clouds_percentage'
          scaleLength={10}
          disabled={disabled}
          min={20}
          max={100}
        />
      </div>
    </div>

    <div className={classes.SatelliteImages__filter_form__dates_wrapper}>
      <InputLabel shrink>
        {I18n.t('v4/satellite_images.attributes.dates')}
      </InputLabel>
      <div className={classes.SatelliteImages__filter_form__dates}>
        <DatePicker
          className={classes.SatelliteImages__filter_form__date}
          onChange={value => handleChange({
            value,
            name: 'start_date'
          })}
          maxDate={state.end_date ? state.end_date : moment()}
          value={state.start_date}
          name='start_date'
          margin='dense'
          variant='outlined'
          disabled={disabled}
          required
          disableFuture
        />

        <DatePicker
          className={classes.SatelliteImages__filter_form__date}
          onChange={value => handleChange({
            value,
            name: 'end_date'
          })}
          value={state.end_date}
          name='end_date'
          margin='dense'
          variant='outlined'
          disabled={disabled}
          required
          disableFuture
        />
      </div>
    </div>

    <div className={classes.SatelliteImages__filter_form__actions}>
      <Button
        type='submit'
        color='primary'
        variant='contained'
        disabled={disabled}
      >
        {I18n.t('v4/satellite_images.actions.filter')}
      </Button>
    </div>
  </form>
}

FilterForm.defaultProps = {
  handleSubmit: () => { },
  filter: '',
  classes: {}
}

FilterForm.propTypes = {
  filter: PropTypes.object,
  handleSubmit: PropTypes.func,
  classes: PropTypes.object
}

export default FilterForm
