import React from 'react'
import PropTypes from 'prop-types'

import Divider from '@material-ui/core/Divider'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

import { setCurrentOrganization, organizationShape } from '@models/organization'
import styles from '../styles'

const OrganizationsList = ({ organizations, classes, router }) =>
  organizations.map((organization, index) =>
    <div key={`itemListOrg-${organization.id}`}>
      <ListItem
        button
        onClick={() => setCurrentOrganization(organization.id, router.getLink)}
        key={`menu-list-${index}`}
        className={classes.organizations__item}
        data-testid={`menu-list-${organization.id}`}
      >
        <div className={classes.organizations__icon__list__wrapper}>
          <Typography className={classes.organizations__icon}>
            {organization.name[0]}
          </Typography>
        </div>
        <div>
          <ListItemText primary={organization.name} />
          <Typography variant='caption'>
            {organization.label}
          </Typography>
        </div>
      </ListItem>
      <Divider />
    </div>
  )

OrganizationsList.propTypes = {
  organizations: PropTypes.arrayOf(organizationShape)
}

export default withStyles(styles)(OrganizationsList)
