import React from 'react'
import PropTypes from 'prop-types'
import { Form as FinalForm } from 'react-final-form'
import moment from 'moment'

import { convertDateAndTime, dateToHours } from '@models/equipment/irrigation'
import { RailsForm } from '@support/RailsForm'

import { withHttpService } from '@contexts/HttpServiceProvider'
import { withRouter } from '@contexts/RouterProvider'
import Form from './Form'

const New = ({ areas, equipment, organization_id, handleError, httpService, o, router }) => {
  const [loading, setLoading] = React.useState(false)

  const handleSubmit = (data, equipment) => {
    setLoading(true)

    _.forOwn(data, async (informIrrigation, key) => {
      if (informIrrigation) {
        try {
          const areaId = informIrrigation.areaId

           if(!!informIrrigation.started_at) {
            informIrrigation.started_at = convertDateAndTime(informIrrigation.irrigation_date, informIrrigation.started_at)
          }

          if (!!informIrrigation.finished_at) {
            informIrrigation.finished_at = convertDateAndTime(informIrrigation.irrigation_date, informIrrigation.finished_at)
          }

          const data = {
            ...informIrrigation,
            irrigation: informIrrigation.unit === 'h' ? dateToHours(informIrrigation.irrigation) : informIrrigation.irrigation,
            area_id: areaId
          }

          await httpService.request.post(`/equipments/irrigation/${equipment.id}/irrigations`, {
            irrigation: {
              ...data
            }
          })

          router.visit(`/equipments/irrigation/${equipment.id}/irrigations`)
        } catch (err) {
          setLoading(false)

          handleError(err)
        }
      }
    })
  }

  return <RailsForm
    component={FinalForm}
    handleSubmit={data => handleSubmit(data, equipment)}
    render={props => (
      <Form
        {...props}
        organization_id={organization_id}
        equipment={equipment}
        areas={areas}
        o={o}
        loading={loading}
      />
    )}
  />
}

New.propTypes = {
  equipment: PropTypes.object,
  areas: PropTypes.array
}

export default withHttpService(withRouter(New))
