import React from 'react'

import { Typography } from '@material-ui/core'

import ConfirmEmail from '@ui/Icons/ConfirmEmail'

const ContentBody = ({ classes }) => (
  <>
    <div>
      <Typography variant='body1'>
        {I18n.t('v4/login.confirm_email_dialog.access')}
      </Typography>

      <Typography variant='body2'>
        {I18n.t('v4/login.confirm_email_dialog.dont_worry')}
      </Typography>
    </div>

    <ConfirmEmail className={classes.DialogContent_ConfirmEmail} />

    <div>
      <Typography variant='body1'>
        {I18n.t('v4/login.confirm_email_dialog.we_will_send')}
      </Typography>
    </div>
  </>
)

export default ContentBody
