import React from 'react'

import Select, { components } from 'react-select'
import Typography from '@material-ui/core/Typography'
import TextField from '@material-ui/core/TextField'
import MenuItem from '@material-ui/core/MenuItem'
import Paper from '@material-ui/core/Paper'
import withStyles from '@material-ui/core/styles/withStyles'

import styles from './styles'

const NoOptionsMessage = (props) =>
  <Typography
    color="textSecondary"
    className={props.selectProps.classes.noOptionsMessage}
    {...props.innerProps}
  >
    {I18n.t('info.no_options')}
  </Typography>

const inputComponent = ({ inputRef, ...props }) =>
  <div ref={inputRef} {...props} />

const DropdownIndicator = props =>
  <div data-testid={props.selectProps.dataTestid}>
    <components.DropdownIndicator {...props} />
  </div>

const Control = (props) => {
  let name = props.selectProps.autocomplete
    ? props.selectProps.classes.inputAutocomplete
    : props.selectProps.classes.input

  return (
    <TextField
      fullWidth
      variant={props.selectProps.variant}
      error={props.selectProps.hasError}
      helperText={props.selectProps.helperText}
      required
      InputProps={{
        inputComponent,
        inputProps: {
          className: name,
          inputRef: props.innerRef,
          children: props.children,
          ...props.innerProps,
          ...props.selectProps.inputProps
        },
      }}
      {...props.selectProps.textFieldProps}
    />
  );
}

const Placeholder = (props) =>
  <Typography
    color="textSecondary"
    className={props.selectProps.classes.placeholder}
    {...props.innerProps}
  >
    {props.children}
  </Typography>


const SingleValue = (props) =>
  <Typography className={props.selectProps.classes.singleValue} {...props.innerProps}>
    {props.children}
  </Typography>

const ValueContainer = (props) =>
  <div className={props.selectProps.classes.valueContainer}>
    {props.children}
  </div>

const Menu = (props) =>
  <Paper className={props.selectProps.usePaperClass && props.selectProps.classes.paper} {...props.innerProps}>
    {props.children}
  </Paper>

const Option = (props) =>
  <MenuItem
    buttonRef={props.innerRef}
    selected={props.isFocused}
    component="div"
    style={{
      fontWeight: props.isSelected ? 500 : 400,
    }}
    {...props.innerProps}
  >
    {props.children}
  </MenuItem>

class ReactSelect extends React.Component {
  render() {
    const { classes, handleChange, value, fields, data, input } = this.props;

    const selectComponents = {
      Control,
      Menu,
      NoOptionsMessage,
      Option,
      Placeholder,
      SingleValue,
      ValueContainer,
      DropdownIndicator
    };

    const options = data.map(option => ({
      label: option[fields.label],
      value: option[fields.value]
    }))

    const handlers = {}

    if (!!input && input.onChange) {
      handlers.onChange = input.onChange
    }
    else if (handleChange) {
      handlers.onChange = handleChange
    }

    if (!!input && input.value != null) {
      handlers.value = input.value
    }
    else if (value != null) {
      handlers.value = value
    }

    return (
      <div className={classes.root}>
        <Select
          isDisabled={!!this.props.disabled}
          classes={classes}
          options={options}
          components={selectComponents}
          {...this.props}
          {...handlers}
        />
      </div>
    );
  }
}

export default withStyles(styles)(ReactSelect);
