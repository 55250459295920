import validateFormFields from '@services/validateFormFields'

export const perennialOptions = () => [
  { id: true, name: I18n.t('confirmation.true') },
  { id: false, name: I18n.t('confirmation.false') }
]

export const getCultureHeaders = () => [
  {
    field: 'name',
    title: I18n.t('activerecord.attributes.v4/culture.name'),
  },
  {
    field: 'cultivar',
    title: I18n.t('activerecord.attributes.v4/culture.cultivar'),
    render: rowData => rowData.cultivar ? rowData.cultivar.name : ''
  }
]

export const validate = values => validateFormFields(values, fields())

export const fields = () => [
  { name: 'name', validations: { required: true } },
  { name: 'template_culture', translation: (value) => value.name },
  { name: 'cultivar', translation: (value) => value.name },
  { name: 'perennial', translation: (value) => I18n.t(`confirmation.${value}`) },
  { name: 'zrma', measure: 'cm', validations: { required: true, bounds: { min: 0, max: 200 } } },
  { name: 'zrme', measure: 'cm', validations: { required: true, bounds: { min: 0, max: 200 } } },
  { name: 'ndc', measure: I18n.t('days'), validations: { required: true, bounds: { min: 1, max: 365 } } },
  { name: 'ftab', validations: { required: true, bounds: { min: 0.1, max: 0.7 } } },
  { name: 'coeff', validations: { required: true, bounds: { min: 0, max: 0.04 } } }
]
