import React from 'react'
import PropTypes from 'prop-types'

import { RailsForm } from '@support/RailsForm'
import { Form as FinalForm } from 'react-final-form'
import { validate, getOwnershipTypes } from '@models/equipment/tractor'
import EquipmentForm from '../Form'
import Form from './Form'

const Edit = ({ model, o }) =>
  <RailsForm
    component={FinalForm}
    action='update'
    url={`/v4/o/${o}/equipments/${model.kind}/${model.id}`}
    successUrl={`/v4/o/${o}/equipments/${model.kind}/${model.id}`}
    validate={validate}
    initialValues={{ equipment: model }}
    render={props => (
      <EquipmentForm
        title={`${I18n.t('actions.update')} ${I18n.t(`v4/equipment.kinds.${model.kind}`)}`}
      >
        <Form
          {...props}
          selectValues={{
            ownershipTypes: getOwnershipTypes()
          }}
          action={I18n.t('actions.update')}
          o={o}
        />
      </EquipmentForm>
    )}
  />

Edit.propTypes = {
  model: PropTypes.object
}

export default Edit
