import React from 'react'

import Select from '@ui/Fields/Select'
import {sendAmplitudeEvent} from '@utils/amplitudeEvent'

const EquipmentSelect = ({
  classes,
  selectedEquipment,
  equipments,
  fetchEquipmentMeasures
}) =>
  <Select
    className={classes.Equipment_Select}
    variant='outlined'
    value={selectedEquipment.id}
    onChange={(event) => fetchEquipmentMeasures(event.target.value)}
    onClick={() => sendAmplitudeEvent('Area Card - Used  Area selection')}
    data={equipments}
    fields={{ id: 'id', name: 'name' }}
    name='name'
    InputLabelProps={{ shrink: true }}
    label={I18n.t('activerecord.models.v4/equipment.one')}
    data-intercom-target="Area Card - Used Area selection"
  />

export default EquipmentSelect
