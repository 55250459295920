import React from 'react'
import Table from '@ui/Table'
import { Switch } from '@material-ui/core'
import Show from './Show'
import Dialog from '@ui/Dialog'
import Form from './Form'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './styles'

class ConfigurationForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      showDialog: false,
      sensors: [],
      selectedSensor: []
    }
  }

  componentDidMount() {
    const { sensors_list, handleSensorsUpdate } = this.props
    const { sensors } = sensors_list[0]
    this.setState({
      sensors
    }, () => handleSensorsUpdate(sensors))
  }

  componentDidUpdate(prevProps, prevState) {
    const { handleSensorsUpdate } = this.props

    if (prevProps.sensors_list !== this.props.sensors_list) {
      const { sensors_list } = this.props
      const { sensors } = sensors_list[0]
      this.setState({
        sensors
      }, () => handleSensorsUpdate(sensors))
    }
  }

  toggleDialog = (selectedSensor = {}) => {
    this.setState({
      showDialog: !this.state.showDialog,
      selectedSensor: selectedSensor
    })
  }

  closeDialog = () => this.setState({ showDialog: false })

  toggleDialogUpdate = () => {
    this.setState({
      showDialog: !this.state.showDialog,
    })
  }

  updateSensor = (id, update) => {
    return (sensor) => {
      if (sensor.id !== id) {
        return sensor
      };
      return update(sensor)
    }
  }

  toggleSensorStatus = (sensor) => {
    const { handleSensorsUpdate } = this.props

    const sensors = this.state.sensors.map(
      this.updateSensor(sensor.id, sensor => ({
        ...sensor,
        active: !sensor.active
      }))
    )

    this.setState({
      sensors
    }, () => handleSensorsUpdate(sensors))
  }

  handleVariablesChange = (variables) => {
    const { handleSensorsUpdate } = this.props
    const { selectedSensor } = this.state;
    const indexSelectedSensor = this.state.sensors.findIndex(sensor => sensor.id === selectedSensor.id)

    this.setState(state => {
      state.sensors[indexSelectedSensor].metadata.params = Object.keys(variables).map((element, index) => variables[index])
      return state;
    }, () => {
      handleSensorsUpdate(this.state.sensors)
      this.toggleDialogUpdate()
    })
  }

  render() {
    const { sensors, selectedSensor, showDialog } = this.state
    const { classes } = this.props

    return (
      <>
        <Table
          tableKey='equipments-automatic-sensorConfiguration-index'
          data={sensors.filter(sensor => !sensor.hidden)}
          options={{
            paging: false,
            sorting: false,
            search: false
          }}
          columns={[
            {
              title: I18n.t('v4/equipment.attributes.sensor.active'),
              field: 'hidden',
              render: data =>
                <Switch
                  checked={data.active}
                  onChange={() => this.toggleSensorStatus(data)}
                  color='primary'
                />
            },
            {
              title: I18n.t('v4/equipment.attributes.sensor.name'),
              field: 'name'
            },
          ]}
          detailPanel={sensor =>
            sensor.active &&
            <Show
              classes={classes}
              variables={sensor.metadata.params}
            />
          }
          title={I18n.t('v4/equipment.attributes.sensor.other')}
          actions={[
            rowData => ({
              icon: 'edit',
              iconProps: { 'data-testid': `edit-${rowData.id}` },
              tooltip: I18n.t('actions.edit'),
              disabled: (!rowData.active || _.isEmpty(rowData.metadata.params)),
              onClick: (event, rowData) =>
                this.toggleDialog(rowData)
            })
          ]}
        >
        </Table>

        <Dialog
          open={showDialog}
          onClose={this.closeDialog}
          onCancel={this.closeDialog}
          title={`${I18n.t('actions.edit')} ${selectedSensor.name}`}
        >
          <Form
            onCancel={this.toggleDialogUpdate}
            handleSubmit={this.handleVariablesChange}
            variables={selectedSensor.metadata}
            classes={classes}
          />
        </Dialog>
      </>
    )
  }
}

Form.defaultProps = {
  sensors: []
}

export default withStyles(styles)(ConfigurationForm)
