import React from 'react'

const MapColorsLegend = ({ classes, scale }) => {
  try {
    scale = JSON.parse(scale)
  }
  catch (error) {
    console.error('scale is not a JSON')
  }

  return <div className={classes.ColorsLegend}>
    {scale.title}
    <ul className={classes.ColorsLegend__colors_list}>
      {
        scale.gradients.map((item, index) =>
          <li
            className={classes.ColorsLegend__colors_item}
            key={index}
          >
            <div
              className={classes.ColorsLegend__color}
              style={{
                background: index === scale.gradients.length - 1
                  ? item.color
                  : `linear-gradient(${item.color}, ${scale.gradients[index + 1].color})`
              }}
            >
            </div>
            <div
              className={classes.ColorsLegend__label}
            >
              {item.label}
            </div>
          </li>
        )
      }
    </ul>
  </div>
}

export default MapColorsLegend
