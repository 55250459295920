import React from 'react'

import { Typography, withStyles } from '@material-ui/core'

import ApplicationIcon from '@images/weather-forecast/application.svg'

const Application = ({ classes }) =>
  <div className={classes.Application_wrapper}>
    <img
      src={ApplicationIcon}
      className={classes.Application_icon}
    />

    <Typography variant='caption'>
      {I18n.t('v4/forecast.conditions_for_Spraying')}
    </Typography>
  </div>

const styles = (theme) => ({
  Application_wrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  Application_icon: {
    width: 15,
    marginRight: theme.spacing.unit
  }
})

export default withStyles(styles)(Application)
