import React, { Component } from 'react'

import classNames from 'classnames'
import Typography from '@material-ui/core/Typography'
import Fade from '@material-ui/core/Fade'
import { WiStrongWind, WiRaindrop, WiRain } from 'weather-icons-react'
import CloudyIcon from '@images/weather-forecast/wi-cloudy.svg'
import CloudyDayIcon from '@images/weather-forecast/wi-day-cloudy.svg'
import RainDayIcon from '@images/weather-forecast/wi-day-rain.svg'
import RainIcon from '@images/weather-forecast/wi-rain.svg'
import SunnyDayIcon from '@images/weather-forecast/wi-day-sunny.svg'
import SunnyOvercastDayIcon from '@images/weather-forecast/wi-day-sunny-overcast.svg'
import ThunderstormDayIcon from '@images/weather-forecast/wi-day-thunderstorm.svg'
import ThunderstormIcon from '@images/weather-forecast/wi-day-thunderstorm.svg'
import WithoutRainIcon from '@images/weather-forecast/pluvi-without-rain.svg'
import RainHighIcon from '@images/weather-forecast/pluvi-rain-high.svg'
import RainVeryHighIcon from '@images/weather-forecast/pluvi-rain-very-high.svg'
import Arrow from '@material-ui/icons/TrendingFlat'
import Paper from '@material-ui/core/Paper'
import ClickAwayListener from '@material-ui/core/ClickAwayListener'

import withStyles from '@material-ui/core/styles/withStyles'

import styles from './styles'

class Forecast extends Component {
  state = {
    open: false
  }

  onElementClick = () => {
    this.setState({ open: !this.state.open })
  }

  handleClickAway = () => {
    this.setState({ open: false })
  }

  render() {
    const { classes, header, body, rain } = this.props
    const { open } = this.state

    const icons = {
      arrowUp: <Arrow className={classes.arrow__up} />,
      arrowDown: <Arrow className={classes.arrow__down} />,
      wind: <WiStrongWind className={classes.wind} />,
      very_high: <img src={RainVeryHighIcon} className={classes.raindrop} />,    
      high: <img src={RainHighIcon} className={classes.raindrop} />,
      moderate: <img src={RainHighIcon} className={classes.raindrop} />,
      low: <img src={RainHighIcon} className={classes.raindrop} />,
      very_low: <img src={WithoutRainIcon} className={classes.raindrop} />,
      raindrop: <WiRaindrop className={classes.raindrop} />
    }

    const weatherIcons = {
      'wi-cloudy': CloudyIcon,
      'wi-day-cloudy': CloudyDayIcon,
      'wi-day-rain': RainDayIcon,
      'wi-rain': RainIcon,
      'wi-day-sunny': SunnyDayIcon,
      'wi-day-sunny-overcast': SunnyOvercastDayIcon,
      'wi-day-thunderstorm': ThunderstormDayIcon,
      'wi-thunderstorm': ThunderstormIcon,
    }

    return (
      <ClickAwayListener onClickAway={this.handleClickAway}>
        <Paper
          onClick={this.onElementClick}
          className={classNames(classes.forecast, { [classes.forecastOpen]: open, [classes.forecastClose]: !open })}
        >

          <div className={classes.forecast__header}>
            {
              open ?
                <Fade in={open} timeout={1000}>
                  <Typography>
                    <span className={classes.forecast__title}>{header.day}</span>
                  </Typography>
                </Fade>
                :
                <Typography>
                  <span className={classes.forecast__title}>{header.shortDay}</span>
                </Typography>
            }
            <Typography className={classes.forecast__subtitle}>
              <span>{header.date}</span>
            </Typography>
          </div>

          <div className={classes.forecast__icon}>
            <img src={weatherIcons[header.icon]} />
          </div>

          <div className={classes.forecast__weather}>
            {
              body.map((info, i) => (
                open ?
                  <Fade in={open} timeout={1000} key={`info-${i}`}>
                    <Typography className={classes.forecast__info__wrapper}>
                      <span className={classes.forecast_weather_info}>
                        {info.label}
                      </span>
                      <span className={classes[info.icon === rain.chance ? `forecast_weather_info_${rain.chance}`:'forecast_weather_info']}>
                        {icons[info.icon]}{info.value}
                      </span>
                    </Typography>
                  </Fade> :
                  <Typography key={`full-info-${i}`}>
                    <span className={classes[info.icon === rain.chance ? `forecast_weather_info_${rain.chance}`:'forecast_weather_info']}>
                      {icons[info.icon]}{info.value}
                    </span>
                  </Typography>
              ))}
          </div>
          <div className={classes.forecast__rain}>
            {
              open ?
                <Typography className={classes[`forecast_weather_rain_${rain.chance}`]}>
                  <Fade in={open} timeout={800}>
                    <span className={classes.forecast_weather_rain_footer}>
                      <WiRain className={classes.forecast_weather_rain_chance} />
                      {`${I18n.t(`v4/forecast.${rain.chance}_open`)} ${rain.pop}%`}
                    </span>
                  </Fade>
                </Typography>
                :
                <Typography className={classes[`forecast_weather_rain_${rain.chance}`]}>
                  <span className={classes.forecast_weather_rain_footer}>
                    <WiRain className={classes.forecast_weather_rain_chance} />
                    {I18n.t(`v4/forecast.${rain.chance}_close`)}
                  </span>
                </Typography>
            }
          </div>
        </Paper>
      </ClickAwayListener>
    )
  }
}

export default withStyles(styles)(Forecast)
