import React from 'react'
import Divider from '@material-ui/core/Divider'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from '@theme/styles/forms'

const InputForm = ({ classes, title, children }) =>
  <Paper
    className={classes.form__wrapper}
    elevation={0}
  >
    <header className={classes.form__header}>
      <Typography component='h2' variant='h5' gutterBottom className={classes.title}>
        {title}
      </Typography>
      <Divider light className={classes.divider} />
    </header>

    {children}

  </Paper>

const pageWithStyles = withStyles(styles)(InputForm)

export default pageWithStyles
