import React from 'react'
import PropTypes from 'prop-types'
import { Form as FinalForm } from 'react-final-form'
import { RailsForm } from '@support/RailsForm'
import MaterialTable from '@ui/Table'
import Form from './Form'
import Dialog from '@ui/Dialog'
import DeleteDialog from '@ui/DeleteDialog'
import { getProductsPlansHeaders } from '@models/admin/coupon'

class PlansProducts extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      listProductPlan: [],
      dialog: {
        open: false,
        title: '',
        values: {},
        handler: () => { }
      },
      isDeleteDialogOpen: false,
      selectedStage: null
    }

  }

  componentDidMount() {
    if (this.props.listProductPlan) {
      this.setState({ listProductPlan: this.props.listProductPlan })
    }
  }

  handleDeleteDialogToggle = (id) => {
    this.setState({
      isDeleteDialogOpen: !this.state.isDeleteDialogOpen,
      selectedStage: id
    })
  }

  handleDialogToggle = () => {
    this.setState({
      dialog: {
        ...this.state.dialog,
        open: !this.state.dialog.open
      }
    })
  }

  handleNewPlanProduct = () => {
    const { handlers } = this.props

    this.setState({
      dialog: {
        open: true,
        title: I18n.t('v4/coupons.plans_produts.actions.add'),
        handler: (values) => {
          this.handleDialogToggle()
          handlers.new(values)
        },
        values: { order: this.props.listProductPlan.length + 1 }
      }
    })
  }

  handleEditPlanProduct = (planProducts) => {
    const { handlers } = this.props

    this.setState({
      dialog: {
        open: true,
        title: I18n.t('v4/coupons.plans_produts.actions.edit'),
        handler: (values) => {
          this.handleDialogToggle()
          handlers.edit(values)
        },
        values: planProducts
      }
    })
  }

  render() {
    const { listProductPlan, handlers, plansList, productList } = this.props

    return (
      <React.Fragment>
          <MaterialTable
            tableKey='store-admin-coupons-plans_products'
            columns={getProductsPlansHeaders()}
            data={listProductPlan}
            title={I18n.t('activerecord.attributes.v4/coupon.plans_products')}
            actions={[
              {
                icon: 'add',
                tooltip: I18n.t('actions.add'),
                isFreeAction: true,
                onClick: this.handleNewPlanProduct
              },
              rowData => ({
                icon: 'create',
                iconProps: { 'data-testid': `edit-${rowData.id}` },
                tooltip: I18n.t('actions.edit'),
                onClick: () => this.handleEditPlanProduct(rowData)
              }),
              rowData => ({
                icon: 'delete',
                iconProps: { 'data-testid': `delete-${rowData.id}` },
                tooltip: I18n.t('actions.remove'),
                onClick: () => this.handleDeleteDialogToggle(rowData.order)
              })
            ]}
            localization={{
              body: {
                emptyDataSourceMessage: I18n.t('info.no_data')
              }
            }}
            options={{
              search: false,
              paging: false,
              showEmptyDataSourceMessage: true,
              sorting: false,
              actionsColumnIndex: -1,
              headerStyle: {
                backgroundColor: '#f5f5f5'
              },
              rowStyle: {
                cursor: 'move',
              }
            }}
          />
        <Dialog
          open={this.state.dialog.open}
          onClose={this.handleDialogToggle}
          title={this.state.dialog.title}
        >
          <RailsForm
            component={FinalForm}
            handleSubmit={this.state.dialog.handler}
            initialValues={this.state.dialog.values}
            render={(props) => (
              <Form
                {...props}
                plansList={plansList}
                productList={productList}
                onCancel={this.handleDialogToggle}
                action={this.state.dialog.title}
              />
            )}
          />
        </Dialog>

        <DeleteDialog
          dialogTitle={`${I18n.t('actions.remove')}`}
          dialogText={I18n.t('confirmation.remove')}
          onCancel={this.handleDeleteDialogToggle}
          onClose={this.handleDeleteDialogToggle}
          handleDelete={() => {
            handlers.delete(this.state.selectedStage)
            this.handleDeleteDialogToggle()
          }}
          open={this.state.isDeleteDialogOpen}
        />
      </React.Fragment>
    )
  }
}

PlansProducts.defaultProps = {
  listProductPlan: []
}

PlansProducts.propTypes = {
  listProductPlan: PropTypes.array
}

export default PlansProducts
