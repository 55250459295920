import React, { Fragment } from 'react'


import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import withStyles from '@material-ui/core/styles/withStyles'

import styles from '@styles/indexPage'

const PaymentInfoTabs = ({ classes, tab, buttonURL, buttonText, children }) => {
  const selectedTab = tabs.indexOf(tabs.find(tab_info => tab_info.id === tab))

  return (
    <Fragment>
      <Paper className={classes.page__wrapper} elevation={0}>
        {
          buttonURL &&
          <Button
            variant='outlined'
            color='primary'
            href={buttonURL}>

            {buttonText || I18n.t('actions.add')}
          </Button>
        }
        <header className={classes.page__header} />
        <Tabs
          className={classes.tabs}
          variant='scrollable'
          scrollButtons='on'
          value={selectedTab}
          indicatorColor='primary'
        >
          {
            tabs.map(tab =>
              <Tab
                label={tab.name}
                key={tab.id}
                onClick={() => Turbolinks.visit(`${tab.id}`)}
              />
            )
          }
        </Tabs>
        {children}
      </Paper>
    </Fragment>
  )
}

const tabs = [
  { id: 'invoices', name: I18n.t('v4/account.kinds.invoices') },
  { id: 'subscriptions', name: I18n.t('v4/account.kinds.subscriptions') },
  { id: 'payment_profiles', name: I18n.t('v4/account.kinds.payment_profiles') },
  { id: 'billing_info', name: I18n.t('v4/account.kinds.billing_infos')},
]

export default withStyles(styles)(PaymentInfoTabs)
