import React from 'react'
import InputMask from 'react-input-mask'
import TextField from '@material-ui/core/TextField'


import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'

const CheckBox = ({
  input: { onFocus, onBlur, ...inputRest },
  onChange,
  checked,
  label,
  meta: { error },
  ...textFieldProps
}) =>
  <FormControlLabel
    control={
      <Checkbox
        key={inputRest.key}
        checked={checked}
        onChange={onChange}
        color="primary"
      />
    }
    label={label}
  >
    {
      () =>
        <TextField
          error={!!error}
          helperText={error}
          {...inputRest}
          {...textFieldProps}
        />
    }
  </FormControlLabel>

CheckBox.defaultProps = {
  meta: {}
}

export default CheckBox
