import React from 'react'

import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  TextField,
  Typography,
  withStyles
} from '@material-ui/core'

const FpNameForm = ({
  classes,
  fpNameValue,
  invalidName,
  onBackHandler,
  onFpNameChange,
  onSave,
  setupData
}) => {
  const lat = setupData.lat.toString()
  const lon = setupData.lon.toString()

  const latTruncate = lat.slice(0, lat.indexOf('.') + 5)
  const lonTruncate = lon.slice(0, lon.indexOf('.') + 5)

  return(
    <div className={classes.FpNameForm_wrapper}>
      <Grid container spacing={16}>
        <Grid item xs={12}>
          <FormControl className={classes.formControl} error fullWidth>
            <TextField
              InputLabelProps={{ shrink: true }}
              error={invalidName}
              value={fpNameValue}
              onChange={onFpNameChange}
              variant='outlined'
              label={I18n.t('v4/forecast.configuration.name_form_label')}
              helperText={invalidName ? I18n.t('validations.empty') : null}
              fullWidth
            />
          </FormControl>
        </Grid>

        <Grid item xs={6} className={classes.FpNameForm_lat} style={{ paddingTop: 0}}>
          <Typography variant="caption" className={classes.FpNameForm_text}>
            {`${I18n.t('units.latitude')}: ${latTruncate}`}
          </Typography>
        </Grid>
        <Grid item xs={6} className={classes.FpNameForm_lon} style={{ paddingTop: 0}}>
          <Typography variant="caption" className={classes.FpNameForm_text}>
            {`${I18n.t('units.longitude')}: ${lonTruncate}`}
          </Typography>
        </Grid>

        <Grid item xs={6}>
          <Button
            color='primary'
            className={classes.Selector_text}
            onClick={onBackHandler}
            fullWidth
          >
            {I18n.t('actions.back')}
          </Button>
        </Grid>
        <Grid item xs={6}>
          <Button
            color='primary'
            className={classes.Selector_text}
            variant='contained'
            onClick={onSave}
            fullWidth
          >
            {I18n.t('actions.save')}
          </Button>
        </Grid>
      </Grid>
    </div>
  )
}

const styles = theme => ({
  FpNameForm_wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: `${theme.spacing.unit}px 0px`,
    marginTop: theme.spacing.unit * 2
  },
  FpNameForm_lon: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  FpNameForm_text:{
    color: theme.palette.gray.dark
  },
  Selector_inputs: {
    margin: `${theme.spacing.unit}px 0px`
  },
  Selector_text: {
    margin: `${theme.spacing.unit}px 0px`,
  }
})

export default withStyles(styles)(FpNameForm)
