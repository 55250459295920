import React from 'react'
import { Form as FinalForm } from 'react-final-form'
import PropTypes from 'prop-types'

import { validate } from '@models/seasons'
import { RailsForm } from '@support/RailsForm'
import Form from './Form'

export const New = ({ model, areas, data, o }) => {
  const { name, start_date, end_date } = data

  return (
    <RailsForm
      component={FinalForm}
      action='create'
      url={`/v4/o/${o}/seasons`}
      validate={validate}
      initialValues={{ name, start_date, end_date }}
      render={(props) => (
        <Form
          {...props}
          title={I18n.t('v4/seasons.new.title')}
          areas={areas}
          o={o}
        />
      )}
    />
  )
}

New.propTypes = {
  model: PropTypes.object,
  areas: PropTypes.array
}

New.defaultProps = {
  data: {},
  areas: []
}

export default New
