import { billingInfoHeaders } from './account/billing_info'
import { paymentProfilesHeaders } from './account/payment_profiles'
import { subscriptionsHeaders } from './account/subscriptions'
import { invoicesHeaders } from './account/invoices'

const createAccountHeaders = (headers) =>
  headers.map(header => ({
    title: I18n.t(`v4/account.attributes.${header}`),
    field: header
  }))

export const getAccountHeaders = (header) => {
  const accountTableHeaders = {
    billing_info: createAccountHeaders(billingInfoHeaders),
    payment_profiles: createAccountHeaders(paymentProfilesHeaders),
    invoices: createAccountHeaders(invoicesHeaders),
    subscriptions: createAccountHeaders(subscriptionsHeaders)
  }

  return accountTableHeaders[header]
}
