import React from 'react'
import PropTypes from 'prop-types'

import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

import { fields } from '@models/admin/products/forecastPoints'
import styles from './styles'

const Show = ({ classes, forecastPoint }) =>
  <Paper className={classes.showPage__wrapper} elevation={0}>
    <div className={classes.forecastPoint__content}>
      {
        fields().map((field) =>
          forecastPoint[field.name] && !field.hidden &&
          <div key={field.name} className={classes.forecastPoint__item}>
            <Typography
              color="textSecondary"
              style={{ fontSize: 'inherit' }}
            >
              {I18n.t(`activerecord.attributes.v4/organization_products.forecast.forecast_points.${field.name}`)}
            </Typography>

            <Typography
              style={{ fontSize: 'inherit' }}
            >
              {
                field.translation
                  ? field.translation(forecastPoint[field.name])
                  : forecastPoint[field.name]
              } {field.measure}
            </Typography>
          </div>
        )
      }
    </div>
  </Paper>

Show.propTypes = {
  forecastPoint: PropTypes.object
}

export default withStyles(styles)(Show)
