import React from 'react'

const ToolTip = ({ active, payload, label, classes, descriptors, getDescriptor }) =>
  active
    ? (
      <div className={classes.tooltip__wrapper}>
        <p>{label}</p>
        {
          payload.map(({ dataKey, stroke, fill, value, payload }) =>
            <div key={dataKey}>
              <p
                style={{
                  color: stroke || fill
                }}
              >
                {
                  `${getDescriptor(descriptors, dataKey).description}: ${value}${getDescriptor(descriptors, dataKey).unit
                    ? ` ${getDescriptor(descriptors, dataKey).unit}`
                    : ''}`
                }
              </p>
            </div>
          )
        }
        < p >
          {I18n.t('v4/weather.charts.wind_velocity.wind_direction')}: {payload[0] ? payload[0].payload.wd10_avg : 0}º
        </p>
      </div >
    )
    : null

export default ToolTip
