import React from 'react'

import { withStyles, Button } from '@material-ui/core'

import Select from '@ui/Fields/Select'

import indexStyles from '@styles/indexPage'

import { useRouter } from '@contexts/RouterProvider'

import Inform from './inform'

import { AddIntecomTag } from '@models/irrigation_managements'

import { sendAmplitudeEvent } from '@utils/amplitudeEvent'
import { IntercomAPI } from '@utils/IntercomAPI';

const ButtonArea = ({
  selectedVisualization,
  visualizations,
  classes,
  setVisualization,
  areas,
  organization_id,
  handleError
}) => {
  const router = useRouter()

  return (
    <div className={classes.ButtonArea}>
      <div className={classes.ButtonArea_LeftSection}>
        <Button
          variant='outlined'
          color='primary'
          onClick={() => {
            router.visit('irrigation_managements/new');
            sendAmplitudeEvent('Aqua - Add management')
            IntercomAPI('trackEvent', 'Aqua - Add management')
          }}
        >
          {I18n.t('v4/irrigation_management.add_irrigation_management')}
        </Button>
        <Select
          className={classes.ButtonArea_SelectVisualization}
          data={AddIntecomTag(visualizations)}
          fields={{ id: 'id', name: 'name' }}
          label={I18n.t('v4/irrigation_management.visualizations_label')}
          margin='normal'
          variant='outlined'
          value={selectedVisualization}
          onChange={event => setVisualization(event.target.value)}
        />
      </div>
      <div className={classes.ButtonArea_RightSection}>
        <Inform
          areas={areas}
          handleError={handleError}
          organization_id={organization_id}
        />
      </div>
    </div>
  )
}

const styles = theme => {
  const indexPageTheme = indexStyles(theme)

  return {
    ButtonArea: {
      display: 'flex',
      justifyContent: 'space-between',
      flexWrap: 'wrap',
      alignItems: 'center',
      ...indexPageTheme.page__header
    },
    ButtonArea_RightSection: {
      display: 'flex',
      flex: 1,
      justifyContent: 'flex-end',
      alignItems: 'center'
    },
    ButtonArea_LeftSection: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      flexWrap: 'wrap',
    },
    ButtonArea_SelectVisualization: {
      width: theme.spacing.unit * 20,
      marginBottom: theme.spacing.unit * 2,
      marginLeft: theme.spacing.unit * 2
    }
  }
}

export default withStyles(styles)(ButtonArea)
