import React from 'react'

const irrigationDashboardHeaders = isPivot => {
  let headers = [
    'area_name',
    'started_at',
    'finished_at',
    'operation_time',
    'irrigation',
    'energy_consumption',
    'water_consumption'
  ]

  if (isPivot) {
    headers.push('sensor_irrigated')
  } else {
    headers.push('operations')
  }

  return headers
}

const dateHeaders = [
  'started_at',
  'finished_at'
]

const floatHeaders = [
  'operation_time',
  'irrigation',
  'energy_consumption',
  'water_consumption'
]

export const getIrrigationDashboardHeaders = isPivot =>
  irrigationDashboardHeaders(isPivot).map(header => ({
    title: I18n.t(`v4/equipment.irrigation_dashboard.headers.${header}`),
    field: header,
    cellStyle: {
      paddingLeft: '10px',
      paddingRight: '10px'
    },
    render: rowData => {
      if (header === 'sensor_irrigated') {
        return <span>{I18n.t(String(!!rowData.sensor_irrigated))}</span>
      }

      if (floatHeaders.includes(header)) {
        return <span>
          {
            typeof rowData[header] === "string"
              ? rowData[header]
              : Number(rowData[header]).toFixed(2)
          }
        </span>
      }

      if (dateHeaders.includes(header)) {
        if (rowData[header]) {
          const date = new Date(rowData[header])

          const options = {
            hour: '2-digit',
            minute: '2-digit',
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
          }

          return <span>{date.toLocaleDateString(I18n.locale, options)}</span>
        } else if (rowData['irrigation_date']) {
          const date = new Date(rowData['irrigation_date'])
          const options = {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
          }
          return <span>{date.toLocaleDateString(I18n.locale, options)}</span>
        }
        
        return <span>--</span>
      }

      return <span>{rowData[header]}</span>
    }
  }))
