import React from 'react'
import { Typography } from '@material-ui/core'
import ColorPicker from '@ui/Fields/ColorPicker'

import withStyles from '@material-ui/core/styles/withStyles'
import styles from './style'

const DefaultColorForm = ({ title, children, classes, color, updateColor, label, subtitle }) =>
  <div className={classes.ColorsForm__fields_section}>
    <Typography component='h3' variant='h6' style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
      {title}
    </Typography>
    {
      subtitle &&
      <Typography style={{ color: 'gray' }}>
        {subtitle}
      </Typography>
    }
    <div className={classes.ColorsForm__fields_wrapper}>
      <div className={classes.ColorsForm__fields}>
        <ColorPicker
          value={color}
          label={label}
          onChange={(color) => updateColor(color)}
        />
      </div>

      <div className={classes.ColorsForm__fields}>
        {children}
      </div>
    </div>
  </div>

export default withStyles(styles)(DefaultColorForm)
