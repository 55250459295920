import React from 'react'
import PropTypes from 'prop-types'
import { RailsForm } from '@support/RailsForm'
import { Form as FinalForm } from 'react-final-form'
import EquipmentForm from '../Form'
import Form from './Form'

import { validate, calculateParams } from '@models/equipment/irrigation'
import httpService from '@services/httpService'

const New = ({ model: { kind }, handleError, manufacturers, irrigation_types, organization_id, o }) => {
  const [calculationResult, setCalculationResult] = React.useState(undefined)
  const [loading, setLoading] = React.useState(false)

  const onSubmit = values => {
    if (values.calculate) {
      return handleCalculation(values.equipment)
    }

    return handleSubmit(values)
  }

  const handleSubmit = async values => {
    try {
      await httpService.post(`/v4/o/${o}/equipments/${kind}`, values)

      Turbolinks.visit(`/v4/o/${o}/equipments/${kind}`)
    }
    catch (error) {
      handleError(error)
    }
  }

  const handleCalculation = async (values) => {
    setLoading(true)
    const data = await calculateParams(values, organization_id, handleError)

    setCalculationResult({ ...values, ...data })
    setLoading(false)
  }

  return <RailsForm
    component={FinalForm}
    action="create"
    url={`/v4/o/${o}/equipments/${kind}`}
    successUrl={`/v4/o/${o}/equipments/${kind}`}
    validate={validate}
    handleSubmit={onSubmit}
    initialValues={{ kind }}
    render={props => (
      <EquipmentForm
        title={`${I18n.t('actions.add')} ${I18n.t(`v4/equipment.kinds.${kind}`)}`}
      >
        <Form
          {...props}
          o={o}
          action={I18n.t('actions.add')}
          loading={loading}
          manufacturers={manufacturers}
          organization_id={organization_id}
          irrigation_types={irrigation_types}
          handleCalculation={handleCalculation}
          calculationResult={calculationResult}
          initialAttachments={[]}
        />
      </EquipmentForm>
    )}
  />
}

New.propTypes = {
  kind: PropTypes.string
}

export default New
