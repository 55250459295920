import React from 'react'
import Table from '@ui/Table'

const getActions = ({ data, actions, validate, update }) => {
  const selectedActions = {}

  const availableActions = {
    onRowAdd: newData =>
      new Promise((resolve, reject) => {
        if (validate(newData)) {
          const updatedData = [...data, newData]

          update(updatedData)
          resolve()
        }
        else {
          reject()
        }
      }),
    onRowUpdate: (newData, oldData) =>
      new Promise((resolve, reject) => {
        if (validate(newData)) {
          const updatedData = [...data]
          updatedData[updatedData.indexOf(oldData)] = newData

          update(updatedData)
          resolve()
        }
        else {
          reject()
        }
      }),
    onRowDelete: oldData =>
      new Promise(resolve => {
        const updatedData = [...data]
        updatedData.splice(updatedData.indexOf(oldData), 1)

        update(updatedData)
        resolve()
      })
  }

  Object.keys(actions).map(key => {
    if (actions[key]) selectedActions[key] = availableActions[key]
  })

  return selectedActions
}

const AdvancedParamsTable = ({
  add,
  columns,
  data,
  edit,
  key,
  options,
  remove,
  updateData,
  validate,
  ...tableProps
}) => {
  const editableActions = getActions({
    data,
    actions: {
      onRowAdd: add,
      onRowUpdate: edit,
      onRowDelete: remove
    },
    validate,
    update: updateData
  })

  return <Table
    tableKey={`irrigationManagements-advancedParams-${key}`}
    columns={columns}
    data={data}
    title={I18n.t('v4/irrigation_management.advanced_params.table_title')}
    options={{
      search: false,
      paging: false,
      showEmptyDataSourceMessage: false,
      ...options
    }}
    editable={editableActions}
    {...tableProps}
  />
}

AdvancedParamsTable.defaultProps = {
  validate: () => true,
  updateData: () => { },
  add: true,
  edit: true,
  remove: true,
  tableProps: {},
  options: {}
}

export default AdvancedParamsTable
