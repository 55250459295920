import React from 'react'

import { Collapse } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'

import LoadingComponent from '@ui/LoadingComponent'
import Forecast from '../../forecast-cards/Index'
import IrrigationManagement from '../IrrigationManagement'
import SatelliteImages from '../SatelliteImages/Index'
import ProductsControll from '@ui/ButtonGroup'
import { fetchProduct } from '@models/productsList'

import { MENUS, withMenusStatus } from '@contexts/MenusStatusProvider'

import styles from './styles'

class Products extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      products: [],
      selectedProduct: {},
      fetching: true
    }

    this.forecastProductReference = null
  }

  componentDidMount() {
    const { productsList, onRef } = this.props

    onRef(this)
    const products = this.deserializeProducts(productsList)

    const selectedProduct = !_.isEmpty(products)
      ? products.find(product => product.kind === 'weather_forecast') || products[0]
      : {}

    this.setState({
      products,
      selectedProduct
    }, () => !_.isEmpty(selectedProduct) && selectedProduct.fetch(selectedProduct))
  }

  deserializeProducts = products =>
    products.reduce((deserializedProducts, product) => {
      if (this.avaliableProducts[product.kind]) {
        deserializedProducts.push({
          key: product.label,
          data: {},
          fetched: false,
          ...product,
          ...this.avaliableProducts[product.kind]
        })
      }
      return deserializedProducts
    }, [])

  avaliableProducts = {
    weather_forecast: {
      fetch: product => {
        const { currentOrganizationId } = this.props

        this.defaultFetcher(product, { organization_id: currentOrganizationId })
      },
      intercomKey: 'product_weather',
      render: props =>
        <Forecast
          key='forecast-weather-product'
          onRef={(ref) => this.forecastProductReference = ref}
          handleError={this.props.handleError}
          onForecastChange={(coordinates) => {
            this.props.changeMapCenter(coordinates)
            this.toggleProductsMenu(true)
          }}
          hasControl
          {...props}
        />,
      url: 'api/v4/forecasts/daily'
    },
    satellite_imagery: {
      fetch: product => {
        const { currentOrganizationId } = this.props

        this.defaultFetcher(product, { organization_id: currentOrganizationId })
      },
      intercomKey: 'product_satellite_imagery',
      render: props =>
        <SatelliteImages
          areas={this.props.areas}
          handleSelectArea={(areaCoordinates, size) => this.props.changeMapCenter(areaCoordinates, size)}
          handleError={this.props.handleError}
          applyFilter={this.props.applyFilterToMap}
          removeFilter={this.props.removeFilterFromMap}
          key='satellite-images-product'
          {...props}
        />,
      url: 'api/v4/satellite_images/filters'
    },
    irrigation_management: {
      fetch: product => {
        const { currentOrganizationId } = this.props

        this.defaultFetcher(product, { organization_id: currentOrganizationId })
      },
      intercomKey: 'product_irrigation_management',
      render: props =>
        <IrrigationManagement
          key='irrigation-management-product'
          changeAreasColors={this.props.changeAreasColors}
          {...props}
        />,
      url: 'api/v4/irrigation_managements/hydric_balances'
    },
  }

  defaultFetcher = async (product, params, customFetcher) => {
    const { handleError } = this.props
    const { selectedProduct: currentProduct, products: productsList } = this.state
    const fetcher = customFetcher ? customFetcher : fetchProduct

    if (product.fetched) {
      if (product.id === currentProduct.id) return

      this.setState({
        currentProduct: product
      })
    }
    else {
      this.setState({
        fetching: true
      })
      try {
        const { selectedProduct, products } = await fetcher(product, productsList, params)

        this.setState({
          fetching: false,
          selectedProduct,
          products
        })
      }
      catch (error) {
        handleError(error)

        this.setState({
          fetching: false
        })
      }
    }
  }

  toggleProductsMenu = value => {
    const { menusStatus: { menus, toggleMenu } } = this.props

    const productsMenuStatus = menus[MENUS.mapProductsMenu]

    const { selectedProduct } = this.state

    if (!productsMenuStatus && selectedProduct.fetched === false) {
      selectedProduct.fetch(selectedProduct)
    }

    toggleMenu(MENUS.mapProductsMenu, value)
  }

  setForecastProduct = (point) => {
    const { products } = this.state
    const forecastProduct = products.find(product => product.kind === 'weather_forecast')

    this.handleProductChange(forecastProduct)
    this.forecastProductReference.handleForecastChange(point)
  }

  handleProductChange = (product) => {
    const { menusStatus: { menus, toggleMenu }, removeFilterFromMap, removeColorsFromAreas } = this.props
    const { selectedProduct } = this.state

    const productsMenuStatus = menus[MENUS.mapProductsMenu]

    if (product.id !== selectedProduct.id) {
      if (product.kind !== 'satellite_images') {
        removeFilterFromMap()
      }
      if (product.kind !== 'irrigation_managements') {
        removeColorsFromAreas()
      }

      if (product.fetched) {
        return this.setState({
          selectedProduct: product
        }, () => toggleMenu(MENUS.mapProductsMenu, true))
      }

      product.fetch(product)
    }

    !productsMenuStatus && toggleMenu(MENUS.mapProductsMenu, true)
  }

  handleSelectedLatLng = ({ lat, lng }) => {
    this.forecastProductReference && this.forecastProductReference.setSelectedLatLng({ lat, lng })
  }

  render() {
    const { classes, currentOrganizationId, hide, menusStatus } = this.props
    const { fetching, selectedProduct, products } = this.state
    const { menus } = menusStatus

    const menuIsExpanded = menus[MENUS.mapProductsMenu]

    return (
      !_.isEmpty(products) &&
      <div className={hide ? classes.Products__wrapper__hidden : classes.Products__wrapper} >
        <ProductsControll
          handleClick={() => this.toggleProductsMenu()}
          products={products}
          visible={menuIsExpanded}
          selectedProduct={selectedProduct}
          handleProductChange={this.handleProductChange}
        />

        <Collapse
          className={classes.Products__collapse}
          classes={{
            wrapperInner: classes.Products__wrapperInner,
            wrapper: fetching ? classes.Collapse__wrapper : ''
          }}
          collapsedHeight='0px'
          in={menuIsExpanded}
        >
          <div className={classes.Products__content}>
            {
              !fetching
                ? selectedProduct.render({
                  data: selectedProduct.data,
                  organizationId: currentOrganizationId,
                  menuIsExpanded,
                  hasForecastProduct: products.some(product => product.kind === 'weather_forecast')
                })
                : <LoadingComponent />
            }
          </div>
        </Collapse>
      </div>
    )
  }
}

Products.defaultProps = {
  onRef: () => { }
}

export default withMenusStatus(
  withStyles(styles)(Products)
)
