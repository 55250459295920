import React from 'react'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import { Field } from 'react-final-form'
import { Switch, InputLabel } from '@material-ui/core'

import TextField from '@ui/Fields/TextField'
import MultipleSelect from '@ui/Fields/MultipleSelect'
import styles from '@theme/styles/forms'
import ParamsTable from './ParamsTable'

import MenuItem from '@material-ui/core/MenuItem';

const Form = ({
  handleSubmit,
  classes,
  title,
  params,
  variables,
  values,
  o
}) => {
  const [hidden, toggleHidden] = React.useState(!!values.hidden)

  const toggleVisibility = () => {
    toggleHidden(!hidden)
    values.hidden = !hidden
  }

  const updateParams = (params) => {
    values['params'] = params
  }

  return <form className={classes.form} onSubmit={handleSubmit} data-testid='sensor-form'>
    <Paper className={classes.form__wrapper} elevation={0}>
      <header className={classes.form__header}>
        <Typography component='h2' variant='h5'>
          {title}
        </Typography>
      </header>
      <main className={classes.form__fields}>

        <div className={classes.form__field__wrapper}>
          <Field
            component={TextField}
            className={classes.form__fieldWithError}
            margin='normal'
            fullWidth
            InputLabelProps={{ shrink: true }}
            variant='outlined'
            label={I18n.t('activerecord.attributes.v4/sensor.name')}
            name='name'
            required
          />
        </div>

        <div className={classes.form__field__wrapper}>
          <Field
            component={TextField}
            className={classes.form__fieldWithError}
            margin='normal'
            fullWidth
            InputLabelProps={{ shrink: true }}
            variant='outlined'
            label={I18n.t('activerecord.attributes.v4/sensor.part_number')}
            name='part_number'
          />
        </div>

        <div className={classes.form__field__wrapper}>
          <Field
            component={TextField}
            className={classes.form__fieldWithError}
            margin='normal'
            fullWidth
            InputLabelProps={{ shrink: true }}
            variant='outlined'
            label={I18n.t('activerecord.attributes.v4/sensor.manufacturer')}
            name='manufacturer'
            required
          />
        </div>

        <div className={classes.form__field__wrapper}>
          <Field
            component={TextField}
            className={classes.form__fieldWithError}
            margin='normal'
            fullWidth
            InputLabelProps={{ shrink: true }}
            variant='outlined'
            label={I18n.t('activerecord.attributes.v4/sensor.description')}
            name='description'
            multiline
          />
        </div>

        <div className={classes.form__field__wrapper}>
          <Field
            format={value => Array.isArray(value) ? value : []}
            component={MultipleSelect}
            className={classes.form__fieldWithError}
            margin='normal'
            fullWidth
            label={I18n.t('activerecord.attributes.v4/sensor.variables')}
            name='variables'
            fields={{ id: 'id', name: 'name' }}
            required
            data={variables}
          />
        </div>

        <div className={classes.form__field__wrapper}>
          <InputLabel shrink>
            {I18n.t('activerecord.attributes.v4/sensor.hidden')}
          </InputLabel>
          <Field
            initialValue={hidden}
            component={Switch}
            className={classes.form__fieldWithError}
            color='primary'
            margin='normal'
            name='hidden'
            onChange={toggleVisibility}
            checked={hidden}
          />
        </div>

        <ParamsTable
          updateParams={updateParams}
          params={params}
        />
      </main>

      <footer className={classes.form__actions}>
        <Button
          className={classes.form__action}
          color='primary'
          type='submit'
          variant='contained'
        >
          {title}
        </Button>

        <Button
          className={classes.form__action}
          color='secondary'
          href={`/v4/o/${o}/admin/sensors`}
        >
          {I18n.t('actions.back')}
        </Button>
      </footer>
    </Paper>
  </form>
}

export default withStyles(styles)(Form)

Form.defaultProps = {
  variables: [],
  params: []
}
