import React from 'react'

import { Grid, Typography, withStyles } from '@material-ui/core'

const CardContent = ({ data, classes }) => {

  return (
    <Grid
      container direction='row' spacing={0}
    >
      <Grid item>
        <Typography variant='body2' align='left'>
          {data.title}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='caption' align='left'>
          {data.description}
        </Typography>
      </Grid>
    </Grid>
  )
}
export default (CardContent)
