import React from 'react'

import { Form as FinalForm } from 'react-final-form'
import { RailsForm } from '@support/RailsForm'

import Form from './Form'
import { validate } from '@models/insight'

const DialogForm = ({ initialValues, onCancel, handleSubmit }) => {
  return (
    <RailsForm
      component={FinalForm}
      handleSubmit={(data) => handleSubmit(data)}
      validate={validate}
      initialValues={initialValues}
      render={(props) => 
        <Form
          title={I18n.t('v4/insight.form_title')}
          onCancel={onCancel}
          {...props}
        />
      }
    />
  )
}


export default DialogForm
