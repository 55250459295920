import React from 'react'

import { Typography, withStyles } from '@material-ui/core'

import Dialog from '@ui/Dialog'

import ContentBody from './ContentBody'
import ContentControl from './ContentControl'

import styles from './styles'

const Content = ({ classes, email, handleError }) => (
  <div className={classes.DialogContent}>
    <ContentBody classes={classes} />

    <ContentControl 
      classes={classes} 
      email={email} 
      handleError={handleError} 
    />

    <Typography variant='body1' className={classes.DialogContent_BottomText}>
      {I18n.t('v4/login.confirm_email_dialog.cant_find')}
    </Typography>
  </div>
)

const DialogWrapper = ({ 
  open, 
  onClose, 
  classes, 
  email, 
  name, 
  handleError 
}) => (
  <Dialog
    title={
      <Typography variant='h4'>
        {I18n.t('v4/login.confirm_email_dialog.title', { name })}
      </Typography>
    }
    children={
      <Content
        classes={classes}
        email={email}
        handleError={handleError}
      />
    }
    open={open}
    onClose={onClose}
    disableTypography
    withCloseButton
  />
)

export default withStyles(styles)(DialogWrapper)
