export default theme => ({
  Warning_Bar: {
    backgroundColor: 'red',
    padding: '5px',
    position: 'relative',
    zIndex: '1',
    width: '100%',
    display: 'flex',
    color: 'white'
  },
  Warning_Bar__Text: {
    margin: '0 auto',
    width: 'fit-content',
    fontWeight: 'bold',
    display: 'inline-block',
  },
  Warning_Bar__Close_Button: {
    backgroundColor: 'transparent',
    backgroundRepeat: 'no-repeat',
    border: 'none',
    cursor: 'pointer',
    overflow: 'hidden',
    outline: 'none',
    color: 'white',
    display: 'inline-block',
    float: 'right',
    marginRight: '12px',
    fontWeight: 'bold',
  },
  Warning_Bar__Link: {
    textDecoration: 'underline',
    color: 'white',
    cursor: 'pointer',
    display: 'inline'
  }
})
