import React from 'react'
import { Form as FinalForm } from 'react-final-form'

import { RailsForm } from '@support/RailsForm'
import Form from './Form'

import { useRouter } from '@contexts/RouterProvider'
import { useHttpService } from '@contexts/HttpServiceProvider'
import { useErrorHandler } from '@contexts/ErrorHandlerProvider'

import { validate } from '@models/irrigation_managements'

export const Edit = ({ organization_id, o, model, algorithms }) => {
  const router = useRouter()
  const httpService = useHttpService()
  const handleError = useErrorHandler()

  const deserializeValues = values => {
    const deserializedValues = values

    if (deserializedValues.limit_client != null) {
      deserializedValues.optimal_water_loss_real = deserializedValues.limit_client
    }

    if (deserializedValues.fixed_watering != null) {
      deserializedValues.type_irrigation_interval_real = deserializedValues.fixed_watering
    }

    return deserializedValues
  }

  const handleSubmit = async values => {
    try {
      const formattedValues = Object.assign({}, values)

      if (!formattedValues.v4_area_id) {
        throw (I18n.t('errors.messages.missing_field'))
      }

      if (!formattedValues.soil_sensor_id && !formattedValues.weather_station_id && formattedValues.algorithm_name != 'simplified') {
        throw (I18n.t('v4/irrigation_management.errors.missing_weather_soil_equipment'))
      }

      _.forOwn(values, (value, key) => {
        if (key.includes('__real')) {
          const originalKey = key.split('__real')[0]

          if (isNaN(value)) {
            formattedValues[originalKey] = value
          } else {
            switch (originalKey) {
              case 'optimal_water_loss':
                formattedValues['limit_client'] = value
                break
              case 'type_irrigation_interval':
                formattedValues['fixed_watering'] = value
                break
            }
          }

          delete formattedValues[key]
        }
      })

      await httpService.request.put(`irrigation_managements/${values.id}`, formattedValues)
      router.visit(`irrigation_managements`)
    }
    catch (error) {
      handleError(error)
      console.error(error)
    }
  }

  return (
    <RailsForm
      component={FinalForm}
      handleSubmit={handleSubmit}
      initialValues={deserializeValues(model)}
      validate={validate}
      render={(props) => (
        <Form
          {...props}
          o={o}
          title={I18n.t('v4/irrigation_management.update_irrigation_management')}
          action={I18n.t('v4/irrigation_management.update_irrigation_management')}
          isEditing
          organization_id={organization_id}
          irrigationTypes={algorithms}
          handleError={handleError}
          checkError={handleError}
        />
      )}
    />
  )
}

export default Edit
