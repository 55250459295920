import React from 'react'
import PropTypes from 'prop-types'

import { RailsForm } from '@support/RailsForm'
import { Form as FinalForm } from 'react-final-form'
import { validate, getOwnershipTypes } from '@models/equipment/tractor'
import EquipmentForm from '../Form'
import Form from './Form'

const New = props => {
  const { kind } = props.model
  const { o } = props

  return (
    <RailsForm
      component={FinalForm}
      action="create"
      url={`/v4/o/${o}/equipments/${kind}`}
      validate={validate}
      initialValues={{ kind, equipment: {} }}
      render={props => (
        <EquipmentForm
          title={`${I18n.t('actions.add')} ${I18n.t(`v4/equipment.kinds.${kind}`)}`}
        >
          <Form
            {...props}
            selectValues={{
              ownershipTypes: getOwnershipTypes()
            }}
            action={I18n.t('actions.add')}
            o={o}
          />
        </EquipmentForm>
      )}
    />
  )
}

New.propTypes = {
  kind: PropTypes.string
}

export default New
