import React from 'react'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const Card = ({ children, onClick, classes, title, style }) =>
  <div className={classes.Card} onClick={onClick} style={style}>
    <Typography variant='body2' className={classes.Card__title} >
      {title}
    </Typography>

    {children}
  </div>

Card.defaultProps = {
  style: {}
}

const styles = theme => ({
  Card: {
    alignItems: 'center',
    backgroundColor: theme.palette.gray.light,
    border: `1px solid ${theme.palette.gray.main}`,
    borderRadius: '5px',
    display: 'flex',
    flex: '1 1 0px',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '210px',
    minHeight: '250px',
    padding: theme.spacing.unit * 2,
    '&:not(:last-child)': {
      marginRight: `${theme.spacing.unit}px`
    }
  },
  Card__title: {
    marginBottom: theme.spacing.unit,
    textAlign: 'center',
    textTransform: 'uppercase'
  }
})

export default withStyles(styles)(Card)
