import React from 'react'

import { Modal, withWidth } from '@material-ui/core';
import ScreenRotationIcon from '@material-ui/icons/ScreenRotation';

const ModalCharts = ({ width, classes }) => {
  const [open, setOpen] = React.useState(true);
  
  const handleOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    width === 'xs' &&
    <Modal
      className={classes.Dashboard__modal}
      open={open}
      onClose={handleClose}
    >
      <div>
        <ScreenRotationIcon style={{ fontSize: 70, color: 'white' }} />
      </div>
    </Modal>
  )
}
export default withWidth()(ModalCharts)
