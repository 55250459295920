import React from 'react'
import PropTypes from 'prop-types'
import { Form as FinalForm } from 'react-final-form'

import { validate, handleProductSubmit } from '@models/admin/product'
import { RailsForm } from '@support/RailsForm'
import Form from './Form'

export const Edit = (props) => {
  const { model, product_kinds, handleError, o } = props
  return <RailsForm
    component={FinalForm}
    handleSubmit={
      data =>
        handleProductSubmit({
          data,
          url: `/v4/o/${o}/admin/products/${model.id}`,
          successUrl: `/v4/o/${o}/admin/products`,
          method: 'put',
          handleError
        })
    }
    validate={validate}
    initialValues={model}
    render={(props) => {
      const params = model.params
      return <Form
        {...props}
        kinds={product_kinds}
        disableSelect={true}
        params={params}
        title={I18n.t('v4/product.actions.update')}
        o={o}
      />
    }}
  />
}
Edit.propTypes = {
  model: PropTypes.object,
}

export default Edit
