import React from 'react'

import { withStyles } from '@material-ui/core/styles'

import { Typography, Checkbox } from '@material-ui/core'

import Step from './Step'

const SelectAreaStep = ({
  activeArea,
  advanceStep,
  areas,
  classes,
  handleAreaSelect,
  isFromLastStep,
  retreatStep,
  save,
  variables,
  actionCancel,
  actionEdit,
  width,
  minHeight,
  description
}) =>
  <Step
    title={I18n.t('v4/alerts.new.steps.select_area.title')}
    description={description && I18n.t('v4/alerts.new.steps.select_area.info')}
    advanceStep={advanceStep}
    retreatStep={retreatStep}
    disableButton={activeArea}
    showBackButton={!isFromLastStep}
    save={save}
    isFromLastStep={isFromLastStep}
    actionCancel={actionCancel}
    actionEdit={actionEdit}
    width={width}
    minHeight={minHeight}
  >
    <Typography className={classes.SelectAreaStep_variables} variant='body2'>
      {
        variables
          .map(variable => variable.variable_description)
          .toString()
          .replace(/,/g, ', ')
      }
    </Typography>

    <div
      className={classes.SelectAreaStep_areas}
    >
      {
        areas.map(area =>
          <Area
            area={area}
            onCheck={() => handleAreaSelect(area)}
            selected={area.selected}
            classes={classes}
            key={area.id}
          />
        )
      }
    </div>
  </Step>

const Area = ({ area, classes, selected, onCheck }) =>
  <div className={classes.SelectedAreaStep_Area}>
    <Typography variant='body2'>
      {area.name || area.area_name}
    </Typography>

    <Checkbox
      checked={selected}
      onClick={onCheck}
    />
  </div>

const styles = theme => ({
  SelectAreaStep_variables: {
    textAlign: 'center',
    margin: `${theme.spacing.unit * 2}px 0`
  },
  SelectAreaStep_areas: {
    width: '100%',
    textAlign: 'center',
    [theme.breakpoints.up('md')]: {
      maxWidth: '650px',
      margin: `${theme.spacing.unit * 2}px auto`
    }
  },
  SelectedAreaStep_Area: {
    alignItems: 'center',
    borderBottom: `1px solid ${theme.palette.gray.main}`,
    display: 'flex',
    justifyContent: 'space-between',
    padding: `0 ${theme.spacing.unit}px`
  }
})

export default withStyles(styles)(SelectAreaStep)
