import React from 'react'
import { Typography } from '@material-ui/core'
import ColorPicker from '@ui/Fields/ColorPicker'
import AdminIcon from '@material-ui/icons/Settings'
import ProfileIcon from '@material-ui/icons/PermIdentity'
import AppsIcon from '@material-ui/icons/Apps'

import withStyles from '@material-ui/core/styles/withStyles'
import styles from './style'

const BarsColorsForm = ({ classes, colors, updateColor }) => {
  const setColor = (color, key) => {
    updateColor({
      ...colors,
      [key]: color
    })
  }

  return <div className={classes.ColorsForm__fields_section}>
    <Typography component='h3' variant='h6' style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
      {`${I18n.t('v4/tenant.styles.navbar')} / ${I18n.t('v4/tenant.styles.sidebar')}`}
    </Typography>
    <div className={classes.ColorsForm__fields_wrapper}>
      <div className={classes.ColorsForm__fields}>
        <ColorPicker
          value={colors.background}
          label={I18n.t('v4/tenant.styles.background')}
          onChange={(color) => setColor(color, 'background')}
        />

        <ColorPicker
          value={colors.icons}
          label={I18n.t('v4/tenant.styles.icons')}
          onChange={(color) => setColor(color, 'icons')}
        />
      </div>

      <div className={classes.ColorsForm__fields}>
        <div className={classes.ColorsForm__fields_Menus}>
          <AdminIcon />
          <AppsIcon />
          <ProfileIcon />
        </div>
      </div>
    </div>
  </div>
}

export default withStyles(styles)(BarsColorsForm)
