import React, { useEffect } from 'react'

import { Button, FormHelperText, Typography, withStyles } from '@material-ui/core'

import styles from './styles'

import TextField from '@ui/Fields/TextField'
import DeviseScreen from './DeviseScreen'

import httpService from '@services/httpService'
import { themeProviderWrapper } from '@contexts/ThemeProvider'
import { errorHandlerProviderWrapper } from '@contexts/ErrorHandlerProvider'
import { validatePassword } from '@models/users'

const EditPassword = ({ classes, logo_url, background_image_url, handleError, reset_password_token }) => {
  const [state, setState] = React.useState({
    password: '',
    confirm_password: '',
    errorPasswordInvalid: '',
    errorPasswordsMatch: ''
  })

  useEffect(() => {
    const {password, confirm_password} = state

    if(!_.isEmpty(state.errorPasswordsMatch) && password === confirm_password) {
      setState({ ...state, errorPasswordsMatch: '' })
    }
  }, [state.password])

  const matchPasswords = () => {
    const {password, confirm_password} = state
    const errorPasswordsMatch = I18n.t('validations.match_password')

    password !== confirm_password
    ? setState({
        ...state,
        errorPasswordsMatch
      })
    : setState({
        ...state,
        errorPasswordsMatch: ''
      })
  }

  const handleChange = ({ target }) => {
    setState({
      ...state,
      [target.name]: target.value
    })
  }

  const handleBlurPassword = () => {
    const errorPasswordInvalid = validatePassword(state.password).message

    if(errorPasswordInvalid !== state.errorPasswordInvalid) {
      !_.isEmpty(errorPasswordInvalid)
      ? setState({
          ...state,
          errorPasswordInvalid
        })
      : setState({
          ...state,
          errorPasswordInvalid: ''
        })
    }
  }

  const valid = () => {
    const {
      errorPasswordInvalid,
      password,
      confirm_password
    } = state

    const invalidPassword = !_.isEmpty(errorPasswordInvalid)

    if (invalidPassword || password !== confirm_password) {
      handleError(I18n.t('validations.form_error'))

      return false
    }

    return true
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    if(valid()) {
      try {
        const { headers } = await httpService.put('/users/password', { user: { ...state, reset_password_token } })
        location.replace(headers.location)
      }
      catch ({ response }) {
        Object.keys(response.data.errors).forEach((error) =>
          handleError(`${I18n.t(`activerecord.attributes.v4/users.${error}`)} ${response.data.errors[error]}`)
        )
      }
    }
  }

  return (
    <DeviseScreen
      classes={classes}
      logo_url={logo_url}
      background_image_url={background_image_url}
    >
      <form
        className={classes.Login__form}
        onSubmit={handleSubmit}
      >
        <Typography
          className={classes.Login__retrieve_password_help_text}
          component='h5'
          variant='h5'
        >
          {I18n.t('devise.passwords.edit.change_password')}
        </Typography>

        <TextField
          className={classes.Login__form_field}
          InputLabelProps={{
            classes: {
              root: classes.Login__form_field_label,
              focused: classes.Login__form_field_label_focused,
            },
          }}
          InputProps={{
            classes: {
              root: classes.Login__form_field_outlined_input,
              focused: classes.Login__form_field_focused,
              notchedOutline: classes.notchedOutline,
            },
            inputProps: {
              className: classes.Login_remove_autocomplete_background
            }
          }}
          margin='normal'
          color='default'
          value={state.password}
          onChange={handleChange}
          onBlur={handleBlurPassword}
          fullWidth
          variant='outlined'
          label={I18n.t('activerecord.attributes.v4/users.password')}
          name='password'
          type='password'
          required
        />

        {
          !_.isEmpty(state.errorPasswordInvalid) &&
          <div className={classes.Login_Span__Wrapper}>
            <FormHelperText className={classes.Login_Span}>
              {`* ${state.errorPasswordInvalid}`}
            </FormHelperText>
          </div>
        }

        <TextField
          className={classes.Login__form_field}
          InputLabelProps={{
            classes: {
              root: classes.Login__form_field_label,
              focused: classes.Login__form_field_label_focused,
            },
          }}
          InputProps={{
            classes: {
              root: classes.Login__form_field_outlined_input,
              focused: classes.Login__form_field_focused,
              notchedOutline: classes.notchedOutline,
            }
          }}
          margin='normal'
          color='default'
          value={state.confirm_password}
          onChange={handleChange}
          onBlur={matchPasswords}
          fullWidth
          variant='outlined'
          label={I18n.t('activerecord.attributes.v4/users.confirm_password')}
          name='confirm_password'
          type='password'
          required
        />

        {
          !_.isEmpty(state.errorPasswordsMatch) &&
          <div className={classes.Login_Span__Wrapper}>
            <FormHelperText className={classes.Login_Span}>
              {`* ${state.errorPasswordsMatch}`}
            </FormHelperText>
          </div>
        }

        <div className={classes.Login__form_actions}>
          <Button
            className={classes.Login__form_action}
            type='submit'
            color='primary'
            variant='contained'
            fullWidth
          >
            {I18n.t('devise.passwords.edit.change_password')}
          </Button>
        </div>

        <Button
          variant='contained'
          fullWidth
          href={`/users/sign_in?l=${I18n.locale}`}
        >
          {I18n.t('login')}
        </Button>
      </form>
    </DeviseScreen>
  )
}

export default errorHandlerProviderWrapper(
  themeProviderWrapper(
    withStyles(styles)(EditPassword)
  )
)
