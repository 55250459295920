import React from 'react'
import { MuiThemeProvider } from '@material-ui/core/styles'
import mountTheme from '@theme/mountTheme'
import MainColorsForm from './MainColorsForm'
import BarsColorsForm from './BarsColorsForm'
import BackgroundColorForm from './BackgroundColorForm'
import PrimaryTextForm from './PrimaryTextForm'
import SecondaryTextForm from './SecondaryTextForm'
import PaperBackgroundColorForm from './PaperBackgroundColorForm'

const ColorsForm = ({ theme, setThemeValues }) =>
  <MuiThemeProvider theme={mountTheme(theme)}>
    <MainColorsForm
      title={I18n.t('v4/tenant.styles.colors.primary')}
      updateColors={
        (colors) => setThemeValues({
          ...theme,
          palette: {
            ...theme.palette,
            primary: colors
          }
        })
      }
      name='primary'
      colors={theme.palette.primary}
    />

    <MainColorsForm
      title={I18n.t('v4/tenant.styles.colors.secondary')}
      updateColors={
        (colors) => setThemeValues({
          ...theme,
          palette: {
            ...theme.palette,
            secondary: colors
          }
        })
      }
      name='secondary'
      colors={theme.palette.secondary}
    />

    <BarsColorsForm
      colors={theme.menus}
      updateColor={
        (colors) => {
          setThemeValues({
            ...theme,
            menus: colors
          })
        }
      }
    />

    <BackgroundColorForm
      color={theme.palette.background.default}
      updateColor={
        (color) => {
          setThemeValues({
            ...theme,
            palette: {
              ...theme.palette,
              background: {
                ...theme.palette.background,
                default: color
              }
            }
          })
        }
      }
    />

    <PaperBackgroundColorForm
      color={theme.palette.background.paper}
      updateColor={
        (color) => {
          setThemeValues({
            ...theme,
            palette: {
              ...theme.palette,
              background: {
                ...theme.palette.background,
                paper: color
              }
            }
          })
        }
      }
    />

    <PrimaryTextForm
      color={theme.palette.text.primary}
      updateColor={
        (color) => {
          setThemeValues({
            ...theme,
            palette: {
              ...theme.palette,
              text: {
                ...theme.palette.text,
                primary: color
              }
            }
          })
        }
      }
    />

    <SecondaryTextForm
      color={theme.palette.text.secondary}
      updateColor={
        (color) => {
          setThemeValues({
            ...theme,
            palette: {
              ...theme.palette,
              text: {
                ...theme.palette.text,
                secondary: color
              }
            }
          })
        }
      }
    />
  </MuiThemeProvider>

export default ColorsForm
