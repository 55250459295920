import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'

import styles from './styles'

import Card from './Card'

class Store extends Component {
  render() {
    const {
      currentPlan,
      plans,
      products,
      classes
    } = this.props

    return(
      <div className={classes.store__wrapper}>
        <Grid container spacing={8} >
          {products.map(product =>
            <Grid
              item xs={12} sm={6} md={4} lg={4} xl={3}
              className={classes.store__product__card}
              key={`product-${product.id}`}
            >
              <Card
                product={ product }
                planId={currentPlan.id}
                interval={currentPlan.interval_count}
                plans={plans}
              />
            </Grid>
          )}
        </Grid>
      </div>
    )
  }
}

export default withStyles(styles)(Store)
