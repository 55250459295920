import React, { Fragment } from 'react'
import NavBar from './NavBarPreview'
import SideBar from './SideBarPreview'
import FormPreview from './FormPreview'

const PreviewScreen = ({ logo_url, small_logo_url }) =>
  <Fragment>
    <NavBar
      admin={true}
      organizations={[]}
      products={[{}]}
      selectedOrganization={{}}
      logo_url={logo_url}
      small_logo_url={small_logo_url}
    />

    <div
      style={{
        display: 'flex',
        width: '100%'
      }}
    >

      <SideBar />
      <div
        style={{
          width: '100%'
        }}
      >
        <FormPreview />
      </div>
    </div>
  </Fragment>

export default PreviewScreen
