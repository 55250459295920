import { Area, Bar, Line } from 'recharts'

import React from 'react';

const i18nPrefixer = text => I18n.t(`v4/irrigation_system.chart_labels.${text}`)

const ChartGenerator = (series) => {
  const isShowingSerie = serie => !serie.hide

  return series.map(serie => isShowingSerie(serie) && chartsTypes[serie.type](serie))
}

const chartsTypes = {
  line: (data) =>
    <Line
      key={data.id}
      type='monotone'
      name={i18nPrefixer(data.name)}
      dataKey={data.id}
      stroke={data.color}
      fill={data.color}
    />,
  bar: (data) =>
    <Bar
      key={data.id}
      type='monotone'
      name={i18nPrefixer(data.name)}
      dataKey={data.id}
      stroke={data.color}
      fill={data.color}
      barSize={data.barSize}
    />,
  area: (data) =>
    <Area
      activeDot={null}
      key={data.id}
      type='monotone'
      name={i18nPrefixer(data.name)}
      dataKey={data.id}
      stroke={data.color}
      fill={data.color}
      fillOpacity={1}
    />
}

export default ChartGenerator;
