export default (theme) => ({
  actions: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  areaImg: {
    maxWidth: '100%'
  },
  bold: {
    fontWeight: 600
  },
  map: {
    height: 'calc(100vh - 1px)',
  },
  menuDivider: {
    margin: '20px 0'
  },
  middle: {
    padding: '8px 24px'
  },
  moreInfo: {
    padding: '10px 20px',
    display: 'flex',
    justifyContent: 'space-between'
  },
  picIcon: {
    fontSize: '10em',
    color: theme.palette.secondary.light,
    background: '#fff',
    borderRadius: '50%',
    padding: 30
  },
  wrapperList: {
    paddingBottom: 20,
    width: 350
  },
  product__wrapper: {
    position: 'fixed',
    bottom: 0,
    zIndex: 1050,
    right: 0
  },
  product__wrapper__editing: {
    display: 'none'
  },
  product__collapse: {
    maxWidth: 'calc(100vw - 70px)',
    overflowX: 'auto'
  },
  ColorsLegend: {
    zIndex: 800,
    fontFamily: '"Helvetica", "Arial", sans-serif',
    minWidth: 70,
    background: `${theme.palette.background.default}C7`,
    padding: '20px',
    borderRadius: '5px',
    position: 'absolute',
    fontSize: '12px',
    margin: '80px 20px',
    textAlign: 'center'
  },
  ColorsLegend__colors_list: {
    paddingInlineStart: '0px',
    maxWidth: '70px',
    textAlign: 'center'
  },
  ColorsLegend__colors_item: {
    listStyleType: 'none',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    fontSize: '10px',
    maxHeight: '15px'
  },
  ColorsLegend__color: {
    height: '15px',
    width: '30px'
  },
  ColorsLegend__label: {
    width: '30px',
    display: 'flex',
    alignItems: 'center',
    height: '15px',
    fontWeight: 'bold',
    fontSize: '100%'
  },
  field: {
    margin: `${theme.spacing.unit * 2}px 0`
  },
  menu__info__wrapper__fade: {
    zIndex: 1050,
    position: 'absolute',
    display: 'flex'
  },
  menu__info__container: {
    background: '#FFF',
    maxHeight: 'calc(100vh - 64px)',
    margin: 10,
    overflow: 'auto'
  },
  close__info__menu: {
    position: 'absolute',
    zIndex: 1,
    right: 30,
    top: 30,
    color: '#FFF',
    cursor: 'pointer',
  },
  Picker_unit: {
    margin: `0 !important`,
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    color: theme.palette.gray.dark
  },
  Picker_coordinates: {
    margin: `0 !important`,
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: 13
  },
  Picker_wrapper: {
    marginBottom: theme.spacing.unit
  },
  Picker_button: {
    width: theme.spacing.unit * 12,
    background: theme.palette.white,
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    color: theme.palette.primary.main,
    border: `solid 1px ${theme.palette.primary.main}`,
    textTransform: 'uppercase',
    cursor: 'pointer',
    borderRadius: theme.spacing.unit / 2,
    fontWeight: 500,
    padding: '5px 16px',
    fontSize: 13,
    transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:focus': {
      outline: 'none'
    },
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}14`
    }
  }
})
