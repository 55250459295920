import React from 'react'
import NewAreaForm from './NewAreaForm'
import NewAreaFileForm from './NewAreaFileForm'

const NewAreaDialog = ({ dialogType, ...props }) =>
  dialogType === 'draw'
    ? <NewAreaForm {...props} />
    : <NewAreaFileForm {...props} />

export default NewAreaDialog
