import React from 'react'
import PropTypes from 'prop-types'
import { withStyles } from '@material-ui/core/styles'
import clsx from 'clsx'
import Stepper from '@material-ui/core/Stepper'
import Step from '@material-ui/core/Step'
import StepLabel from '@material-ui/core/StepLabel'
import StepConnector from '@material-ui/core/StepConnector'
import Button from '@material-ui/core/Button'
import styles from './style'

const stepperLineStyle = (theme) => ({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage:
        `linear-gradient( -95deg, ${theme.palette.secondary.light} 100%, ${theme.palette.secondary.main} 50%, ${theme.palette.secondary.dark} 0%)`,
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        `linear-gradient( -95deg, ${theme.palette.secondary.light} 100%, ${theme.palette.secondary.main} 50%, ${theme.palette.secondary.dark} 0%)`
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  }
})

const ColorlibConnector = withStyles(stepperLineStyle)(StepConnector)

const ColorlibStepIcon = ({ active, completed, classes, steps, icon }) =>
  <div
    className={clsx(classes.root, {
      [classes.stepActive]: active,
      [classes.stepCompleted]: completed,
    })}
  >
    {steps[icon].icon}
  </div>

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
}

const Steppers = ({ classes, steps, children, formClasses, handleSubmit, canAdvance, cancelLink }) => {
  const [activeStep, setActiveStep] = React.useState(0)

  const handleNext = () => {
    setActiveStep(prevActiveStep => prevActiveStep + 1)
  }

  const handleBack = () => {
    setActiveStep(prevActiveStep => prevActiveStep - 1)
  }

  const isLastStep = activeStep === Object.keys(steps).length - 1

  return (
    <div>
      <Stepper alternativeLabel activeStep={activeStep} connector={<ColorlibConnector />}>
        {
          Object.keys(steps).map((key) =>
            <Step key={key}>
              <StepLabel
                StepIconComponent={
                  (props) =>
                    <ColorlibStepIcon
                      {...props}
                      steps={steps}
                      classes={classes}
                    />
                }
              >
                {steps[key].label}
              </StepLabel>
            </Step>
          )
        }
      </Stepper>

      <div>
        {children[activeStep]}
      </div>

      <footer className={formClasses.form__actions}>
        <Button
          variant='contained'
          color='primary'
          onClick={isLastStep ? handleSubmit : handleNext}
          className={formClasses.form__action}
          disabled={!canAdvance}
        >
          {
            isLastStep
              ? I18n.t('actions.save')
              : I18n.t('actions.advance')
          }
        </Button>

        <Button
          disabled={activeStep === 0}
          onClick={handleBack}
          className={formClasses.form__action}
          variant='outlined'
          color='secondary'
        >
          {I18n.t('actions.back')}
        </Button>

        {
          cancelLink &&
          <Button
            href={cancelLink}
            className={formClasses.form__action}
          >
            {I18n.t('actions.cancel')}
          </Button>
        }
      </footer>
    </div>
  )
}

Steppers.defaultProps = {
  steps: {},
  canAdvance: false
}

export default withStyles(styles)(Steppers)
