import httpService from '@services/httpService'

export const fetchCoupon = async ({ coupon, plan, products }) => {
  const coupon_code = serializeCoupon(coupon)
  const store_products_ids = products.map(({ id }) => ({ id }))

  const { data } = await httpService.post(`/api/v4/coupons/check_coupon`,
    {
      coupon_code,
      plan_id: plan.id,
      store_products: store_products_ids
    })

  return data
}

const serializeCoupon = coupon => coupon.replace(' ', '')
