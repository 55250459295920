import React from 'react'

import PropTypes from 'prop-types'
import { convertToLocaleDate } from '@services/locale'

import { Button, Grid, Typography, withStyles } from '@material-ui/core'
import { GetApp } from '@material-ui/icons'

import Cloud from '@material-ui/icons/CloudQueue'

const SampleCard = ({ classes, data, handleClick, selected, filterName }) => {
  const downloadImage = async () => {
    const imageDate = convertToLocaleDate(data.date).replaceAll('/', '-')
    const imageFilter = filterName.replaceAll('/', '-').replaceAll(/\s/g, '')
    const fileName = `${imageDate}-${imageFilter}`

    const imageLink = document.createElement("a")
    imageLink.href = data.image
    imageLink.download = `${fileName}.png`
    document.body.appendChild(imageLink)
    imageLink.click()
    document.body.removeChild(imageLink)
  }

  return (
    <Grid
      container
      spacing={16}
      className={`${classes.SampleCard} ${selected && classes.SampleCard_selected}`}
      onClick={handleClick}
    >
      <Grid item xs={selected ? 6 : 12} className={classes.SampleCard_info}>
        <img className={classes.SampleCard_image} alt={data.date} src={data.image} />

        <Typography variant='body1'>
          {convertToLocaleDate(data.date)}
        </Typography>

        <div className={classes.SampleCard_icon}>
          <Cloud />
          <Typography variant='body1' className={classes.SampleCard_cloud_percentage}>
            {`${Math.round(data.clouds_percentage.replace(/%/, ''))}%`}
          </Typography>
        </div>
      </Grid>

      {
        selected &&
        <Grid item xs={6}>
          <Button
            variant='outlined'
            color='primary'
            data-intercom-target='download-image-view'
            onClick={downloadImage}
            className={classes.SampleCard_download}
          >
            {I18n.t('v4/satellite_images.download_image')}
            <GetApp />
          </Button>
        </Grid>
      }
    </Grid>
  )
}

const styles = theme => ({
  SampleCard: {
    cursor: 'pointer',
    '&:not(:first-child)': {
      marginLeft: theme.spacing.unit * 2
    },
    borderRadius: '5%',
    alignItems: 'center',
    '&:hover': {
      outline: `1px solid ${theme.palette.primary.light}`
    },
    flexWrap: 'inherit',
    padding: '0 10px',
    margin: 1
  },
  SampleCard_selected: {
    background: 'rgb(179 209 160 / 0.15)',
    '&:hover': {
      outline: 'none'
    }
  },
  SampleCard_info: {
    textAlign: 'center'
  },
  SampleCard_image: {
    height: '100px',
    width: '100px'
  },
  SampleCard_icon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  SampleCard_cloud_percentage: {
    paddingLeft: theme.spacing.unit
  },
  SampleCard_download: {
    padding: theme.spacing.unit
  }
})

SampleCard.proptypes = {
  data: PropTypes.shape({
    image: PropTypes.string,
    date: PropTypes.string
  })
}

export default withStyles(styles)(SampleCard)
