import React from 'react'
import DateFnsUtils from '@date-io/date-fns'
import { localeFormats, localeMap } from '@services/locale'
import { DateTimePicker, MuiPickersUtilsProvider } from 'material-ui-pickers';

const ControlledDateTimePicker = ({ initialValue, input, label, meta: { touched, error }, ...props }) =>
  <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[I18n.locale]}>
    <DateTimePicker
      clearable={false}
      error={!!(touched && error)}
      helperText={touched && error}
      keyboard
      label={label}
      variant='outlined'
      {...input}
      {...props}
      cancelLabel={I18n.t('datepicker.cancel')}
      clearLabel={I18n.t('datepicker.clear')}
      format={localeFormats.dateTime[I18n.locale]}
      maxDateMessage={I18n.t('datepicker.max_date_message')}
      minDateMessage={I18n.t('datepicker.min_date_message')}
      okLabel={I18n.t('datepicker.accept')}
    />
  </MuiPickersUtilsProvider>

ControlledDateTimePicker.defaultProps = {
  meta: {}
}

export default ControlledDateTimePicker
