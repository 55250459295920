const validators = {
  required: (value) => value == null || value === '' ? I18n.t('validations.required') : null,
  bounds: (value, rule) => {
    if (!!value && Number(rule.min) > Number(value) || Number(rule.max) < Number(value)) {
      return I18n.t('validations.field_between', { min: rule.min, max: rule.max })
    }

    return null
  }
}

export default (values = {}, fields) => {
  const errors = fields.reduce((errors, field) => {
    if (field.validations) {
      Object.keys(field.validations).forEach(validation => {
        const validationResult = validators[validation](values[field.name], field.validations[validation])
        if (validationResult) errors[field.name] = validationResult
      })
    }
    return errors
  }, {})

  return errors
}
