import React, { Component } from 'react'

import { Paper, Typography } from '@material-ui/core'
import Steppers from '@ui/Stepper'

import PersonalDataIcon from '@material-ui/icons/PermIdentity'
import PaymentIcon from '@material-ui/icons/Payment'
import CheckoutResumeIcon from '@material-ui/icons/Receipt'
import ShoppingCartIcon from '@material-ui/icons/ShoppingCart'

import BillingInfo from './BillingInfo'
import BillingData from './BillingData'
import CheckoutResume from './CheckoutResume'
import PersonalDataForm from './PersonalDataForm'

import withStyles from '@material-ui/core/styles/withStyles'
import indexPageStyles from '@styles/indexPage'
import checkoutPageStyles from './styles'

import { formatPrice, calculateTotalValue } from '@models/checkout'
import httpService from '@services/httpService'
import SuccessScreen from './SuccessScreen'

const steps = [
  {
    icon: <ShoppingCartIcon />,
    label: I18n.t('v4/payment_checkout.steps.billing_info')
  },
  {
    icon: <PersonalDataIcon />,
    label: I18n.t('v4/payment_checkout.steps.clients_data')
  },
  {
    icon: <PaymentIcon />,
    label: I18n.t('v4/payment_checkout.steps.billing_data')
  },
  {
    icon: <CheckoutResumeIcon />,
    label: I18n.t('v4/payment_checkout.steps.purchase_review')
  }
]

class Index extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentStep: 0,
      coupon: null,
      personalData: undefined,
      paymentMethod: undefined,
      paymentMethodDescription: undefined,
      checkoutDetails: undefined,
      success: false,
      sendingSubscription: false,
      isFromResumeStep: false
    }
  }

  componentDidMount() {
    const { billing_info, payment_profiles } = this.props

    this.setState({
      personalData: billing_info,
      creditCards: payment_profiles
    })
  }

  setCoupon = (coupon) => this.setState({ coupon })

  setPersonalData = (personalData) => this.setState({ personalData })

  isNotLastStep = (steps, currentStep) => steps.length - 1 > currentStep

  isNotFirstStep = (currentStep) => currentStep !== 0

  isValidStep = (steps, step) => step > -1 && step < steps.length

  advanceStep = () => {
    const { isFromResumeStep } = this.state

    isFromResumeStep
    ? this.setState({
        currentStep: steps.length - 1,
        isFromLastStep: false
      })
    : this.isNotLastStep(steps, this.state.currentStep) &&
        this.setState(prevState => ({
          currentStep: prevState.currentStep + 1
      }))
  }

  retreatStep = () => {
    const { isFromResumeStep } = this.state

    isFromResumeStep
    ? this.setState({
        currentStep: steps.length - 1,
        isFromLastStep: false
      })
    : this.isNotFirstStep(this.state.currentStep) &&
        this.setState(prevState => ({
          currentStep: prevState.currentStep - 1
      }))
  }

  goToStep = (step) => {
    this.isValidStep(steps, step) &&
      this.setState({
        currentStep: step,
        isFromResumeStep: true
      })
  }

  handleNewCreditCard = (card) => {
    card && this.setState(prevState =>
      ({
        creditCards: [
          ...prevState.creditCards,
          card
        ],
        paymentMethodDescription: "credit_card",
        paymentMethod: card
      }), () => {
        this.advanceStep()
      }
    )
  }

  handleSelectCreditCard = (card) => {
    card && this.setState({
      paymentMethodDescription: "credit_card",
      paymentMethod: card
    }, () => {
      this.advanceStep()
    })
  }

  handleSelectBankSlip = () => {
    this.setState({
      paymentMethodDescription: "bank_slip",
      paymentMethod: {"id": "000000"}
    }, () => {
      this.advanceStep()
    })
  }

  handleSendSubscription = async () => {
    const { plan, product, handleError, o } = this.props
    const { paymentMethod, coupon } = this.state

    const subscription = {
      plan_id: plan.id,
      payment_profile_id: paymentMethod.id,
      subscription_items: [
        {
          store_product_id: product.id,
          quantity: 1,
          coupon_code: coupon ? coupon.code : '',
          price: product.price
        }
      ]
    }

    this.setState({ sendingSubscription: true })
    try {
      await httpService.post(`/v4/o/${o}/store/subscription`, subscription)

      this.setState({ success: true })
    }
    catch (error) {
      handleError(error)
    }
    this.setState({ sendingSubscription: false })
  }

  render() {
    const { classes, product, plan, handleError, organization, gateway_base_url, gateway_public_key, o } = this.props
    const { currentStep, coupon, personalData, paymentMethod, paymentMethodDescription, checkoutDetails, creditCards, success, sendingSubscription } = this.state

    return (
      <Paper className={classes.page__wrapper} elevation={0}>
        <header className={classes.page__header}>
          <Typography component='h2' variant='h5'>
            {I18n.t('v4/payment_checkout.title')}
          </Typography>
        </header>

        <main>
          <Steppers
            currentStep={currentStep}
            steps={steps}
          >
            <BillingInfo
              advanceStep={this.advanceStep}
              coupon={coupon}
              product={product}
              plan={plan}
              setCoupon={this.setCoupon}
              o={o}
            />
            <PersonalDataForm
              model={personalData}
              advanceStep={this.advanceStep}
              handleError={handleError}
              retreatStep={this.retreatStep}
              setPersonalData={this.setPersonalData}
              o={o}
            />
            <BillingData
              gateway_base_url={gateway_base_url}
              gateway_public_key={gateway_public_key}
              selectCreditCard={this.handleSelectCreditCard}
              handleNewCreditCard={this.handleNewCreditCard}
              handleSelectBankSlip={this.handleSelectBankSlip}
              creditCards={creditCards}
              organization_id={organization.id}
              o={o}
              handleError={handleError}
              retreatStep={this.retreatStep}
            />
            {
              success
                ? <SuccessScreen
                  organization_id={organization.id}
                  o={o}
                />
                : <CheckoutResume
                  coupon={coupon}
                  goToStep={this.goToStep}
                  product={product}
                  plan={plan}
                  personalData={personalData}
                  paymentMethod={paymentMethod}
                  paymentMethodDescription={paymentMethodDescription}
                  checkoutDetails={checkoutDetails}
                  finalValue={formatPrice(calculateTotalValue(product.price, coupon))}
                  sendingSubscription={sendingSubscription}
                  handleCancel={() => Turbolinks.visit(`/v4/o/${o}/store`)}
                  handleSave={this.handleSendSubscription}
                />
            }
          </Steppers>
        </main>
      </Paper>
    )
  }
}

const styles = (theme) => ({
  ...indexPageStyles(theme),
  ...checkoutPageStyles(theme)
})

export default withStyles(styles)(Index)
