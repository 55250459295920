import React from 'react'

import { withStyles } from '@material-ui/core'

import RightGroupMenu from './RightGroupMenu'

import { useRouter } from '@contexts/RouterProvider'
import { useSessionData } from '@contexts/SessionDataProvider'

import styles from './styles'

import { useErrorHandler } from '@contexts/ErrorHandlerProvider'

export const NavBar = ({ classes, isTrial, expiredTrial }) => {
  const {
    organizations,
    products,
    currentOrganization,
    currentTenant,
    alertsNotifications,
    currentUser,
    currentView
  } = useSessionData()

  const handleError = useErrorHandler()

  const organizationList = organizations.filter(organization => organization.id !== currentOrganization.id)

  const router = useRouter()

  return (
    <div className={isTrial ? classes.menu_floatingTrial : classes.menu_floating}>
      <RightGroupMenu
        products={products}
        organizations={organizationList}
        currentOrganization={currentOrganization}
        currentUser={currentUser}
        currentTenant={currentTenant}
        alertsNotifications={alertsNotifications}
        router={router}
        handleError={handleError}
        currentView={currentView}
        expiredTrial={expiredTrial}
      />
    </div>
  )
}


export default withStyles(styles)(NavBar)
