export default (theme) => ({
  root: {
    flexGrow: 1,
  },
  input: {
    display: 'flex',
    padding: 0
  },
  inputAutocomplete: {
    display: 'flex'
  },
  valueContainer: {
    display: 'flex',
    flexWrap: 'wrap',
    flex: 1,
    alignItems: 'center'
  },
  noOptionsMessage: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`
  },
  singleValue: {
    fontSize: 16,
    maxWidth: '200px',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis'
  },
  placeholder: {
    position: 'absolute',
    left: 16,
    fontSize: 16
  },
  paper: {
    position: 'absolute',
    zIndex: 5,
    marginTop: theme.spacing.unit,
    left: 0,
    right: 0,
    top: 65
  }
})
