import httpService from '@services/httpService'

export const fields = [
  'name', 'part_number', 'manufacturer', 'description', 'variables', 'hidden'
]

export const paramsTableHeaders = [
  { title: I18n.t('activerecord.attributes.v4/sensor.param.name'), field: 'name' },
  { title: I18n.t('activerecord.attributes.v4/sensor.param.param'), field: 'param' },
  { title: I18n.t('activerecord.attributes.v4/sensor.param.type'), field: 'type' },
  { title: I18n.t('activerecord.attributes.v4/sensor.param.value'), field: 'value' }
]

export const sensorTableHeaders = [
  { title: I18n.t('activerecord.attributes.v4/sensor.name'), field: 'name' },
  { title: I18n.t('activerecord.attributes.v4/sensor.part_number'), field: 'part_number' },
  { title: I18n.t('activerecord.attributes.v4/sensor.manufacturer'), field: 'manufacturer' },
  { title: I18n.t('activerecord.attributes.v4/sensor.hidden'), field: 'hidden', render: rowData => I18n.t(String(rowData.hidden)) }
]

export const validate = (values) => {
  const requiredFields = ['name', 'variables', 'manufacturer']
  const errors = {}

  requiredFields.forEach(field => {
    if (!values[field]) errors[field] = I18n.t('validations.required')
  })

  return errors
}

export const handleSubmit = (values, handleError, id, o) => {
  values.hidden = values.hidden === undefined ? false : values.hidden
  submit(values, id, handleError, o)
}

const submit = async (values, id, handleError, o) => {
  try {
    const url = `/v4/o/${o}/admin/sensors${!!id ? `/${id}` : ''}.json`
    await httpService({ method: !!id ? 'PUT' : 'POST', url, data: values })
    Turbolinks.visit(`/v4/o/${o}/admin/sensors/${id || ''}`)
  }
  catch (error) {
    handleError(error)
  }
}
