import React from 'react'
import DashboardIndex from './dashboard/Index'

const Dashboard = ({ model, organization_id, equipments_list, o }) =>
  <DashboardIndex
    model={model}
    organizationId={organization_id}
    equipmentsList={equipments_list}
    o={o}
  />

export default Dashboard
