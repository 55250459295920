import React from 'react'

import { Typography } from '@material-ui/core'

import Panel from './Panel'
import TextField from '@ui/Fields/TextField'

import AdvancedParamsTable from './Table'

const correctionFactorColumns = [
  {
    field: 'depth',
    title: I18n.t('v4/irrigation_management.advanced_params.correction_factor.fields.depth'),
    editable: 'never',
    editComponent: ({ value }) => value
  },
  {
    field: 'value',
    title: I18n.t('v4/irrigation_management.advanced_params.correction_factor.fields.value'),
    editComponent: ({ value, onChange }) => (
      <TextField
        value={value}
        onChange={({ target }) => onChange(target.value)}
        step='0.001'
        type='number'
        required
      />
    )
  }
]

const validate = data =>
  !correctionFactorColumns.some(({ field }) => !data[field])

const CorrectionFactor = ({ data, update }) =>
  <Panel
    title={I18n.t('v4/irrigation_management.advanced_params.correction_factor.title')}
    disabled={_.isEmpty(data)}
  >
    <Typography variant='body1' paragraph>
      {I18n.t('v4/irrigation_management.advanced_params.correction_factor.info')} ({data.length})
    </Typography>

    <AdvancedParamsTable
      data={data}
      validate={validate}
      updateData={update}
      columns={correctionFactorColumns}
      add={false}
      remove={false}
      components={{ Toolbar: () => null }}
    />
  </Panel>

CorrectionFactor.defaultProps = {
  data: [],
  update: () => { }
}

export default CorrectionFactor
