import React from 'react'
import Dropzone from '@ui/Fields/Dropzone'
import { LinearProgress } from '@material-ui/core'
import { getDirectUploadLinks, uploadFile, deleteFile } from '@services/directUpload'
import FilesTable from './FilesTable'

import { useErrorHandler } from '@contexts/ErrorHandlerProvider'

const DirectUploadDropzone = ({ organization_id, resource_name, initialFiles, onFilesChange, loading, setLoading, ...props }) => {
  const [loadedPercentage, setLoadedPercentage] = React.useState(0.1)
  const [uploadedFiles, setupUploadedFiles] = React.useState(initialFiles)
  const handleError = useErrorHandler()

  const onFilesDrop = async (files) => {
    const totalSize = files.reduce((total, file) => total + file.size, 0)

    const calculateLoadedPercentage = size => size / totalSize * 100
    const increasePercentage = value => setLoadedPercentage(prevLoadedPercentage => prevLoadedPercentage + value)

    setLoading(true)

    try {
      const filenames = files.map(file => file.name)
      const response = await getDirectUploadLinks({ organization_id, resource_name, filenames })

      response.data.forEach(({ url, key }, index) => {
        files[index].url = url
        files[index].key = key
      })

      await Promise.all(files.map(async file => {
        await uploadFile(file)

        increasePercentage(calculateLoadedPercentage(file.size))

        setupUploadedFiles(currentFiles => {
          const newFilesList = [...currentFiles, file]

          onFilesChange(newFilesList)

          return newFilesList
        })
      }))

      setLoadedPercentage(100)
    }
    catch (error) {
      handleError(error)
    }

    setLoading(false)
    setLoadedPercentage(0.1)
  }

  const handleDeleteFile = deletedFile =>
    confirmDialog(() => {
      if (deletedFile.id != null) {
        try {
          deleteFile(deletedFile.id, organization_id)
        }
        catch (error) {
          handleError(error)
        }
      }

      setupUploadedFiles(files => {
        const newFilesList = files.filter(file => file.key !== deletedFile.key)

        onFilesChange(newFilesList)

        return newFilesList
      })
    })

  return organization_id == null || resource_name == null
    ? null
    : <>
      <Dropzone
        {...props}
        disabled={loading}
        setFile={onFilesDrop}
      />

      {
        loading &&
        <LinearProgress variant='determinate' value={loadedPercentage} />
      }

      {
        !_.isEmpty(uploadedFiles) &&
        <FilesTable files={uploadedFiles} onDelete={handleDeleteFile} />
      }
    </>
}

DirectUploadDropzone.defaultProps = {
  organization_id: null,
  resource_name: null,
  initialFiles: [],
  onFilesChange: () => { }
}

export default DirectUploadDropzone
