import React from 'react'

import Charts from '@ui/Charts'
import { getDataRanges } from '@models/equipment/telemetry/charts'

const DashboardCharts = ({ series, data }) =>
  Object.keys(series).map(serie =>
    <Charts
      key={serie}
      data={getDataRanges(data, series[serie])}
      xData='date'
      series={series[serie]}
    />
  )

export default DashboardCharts
