import React from 'react'
import PropTypes from 'prop-types'
import { Form as FinalForm } from 'react-final-form'

import { validate } from '@models/seasons'
import { RailsForm } from '@support/RailsForm'
import Form from './Form'

export const Edit = ({ model, areas, data, o }) => (
  <RailsForm
    component={FinalForm}
    action='update'
    url={`/v4/o/${o}/seasons/${model.id}`}
    successUrl={`/v4/o/${o}/seasons/${model.id}`}
    validate={validate}
    initialValues={model}
    render={(props) => (
      <Form
        {...props}
        title={`${I18n.t('actions.update')} ${I18n.t('v4/seasons.index.season')}`}
        areas={areas}
      />
    )}
  />
)

Edit.propTypes = {
  model: PropTypes.object,
  areas: PropTypes.array
}

Edit.defaultProps = {
  model: {
    name: '',
    id: 0,
    start_date: '',
    end_date: '',
    areas: [],
  },
  areas: [],
}

export default Edit
