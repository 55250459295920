import PropTypes from 'prop-types'

export const validate = values => {
  const fields = [
    'name', 'brand'
  ]

  const errors = {}

  fields.forEach(field => {
    if (!values[field]) errors[field] = I18n.t('validations.required')
  })

  return errors
}

export const unities = [
  { id: 1, name: I18n.t('v4/input.unities.volume') },
  { id: 2, name: I18n.t('v4/input.unities.weight') }
]

export const ureaValues = [
  { id: 1, name: I18n.t('true'), value: true },
  { id: 2, name: I18n.t('false'), value: false }
]

export const fertilizerHeaders = [
  { title: I18n.t('activerecord.attributes.v4/input.unit'), field: 'unit' },
  { title: I18n.t('activerecord.attributes.v4/input.density'), field: 'density' }
]

export const fertilizerShape = PropTypes.shape({
  name: PropTypes.string,
  brand: PropTypes.string,
  unit: PropTypes.string,
  density: PropTypes.string,
  nitrogen: PropTypes.string,
  phosphate: PropTypes.string,
  potassium: PropTypes.string,
  zinc: PropTypes.string,
  sulfate: PropTypes.string,
  toc: PropTypes.string,
  urea: PropTypes.bool,
  detail: PropTypes.string,
  kind: PropTypes.string
})
