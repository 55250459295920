export default theme => ({
  Dashboard__wrapper: {
    backgroundColor: 'transparent',
    margin: `${theme.spacing.unit * 5}px auto 0`,
    maxWidth: 1500,
    padding: theme.spacing.unit * 4,
  },

  Dashboard__header: {
    marginBottom: theme.spacing.unit * 4
  },

  Dashboard__select: {
    marginTop:  theme.spacing.unit * 4
  },
  Dashboard__activeCulture_phenologicalStages: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '20px 0'
  },

  Dashboard__activeCulture_phenologicalStage: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    height: '350px',
    padding: '20px 0',
  },

  Dashboard__newAreaCulture_form: {
    margin: '20px 10px'
  },

  Dashboard__newAreaCulture_field: {
    minWidth: 500
  },

  Dashboard__newAreaCulture_action: {
    marginLeft: 10
  },

  PhenologicalStage: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    alignContent: 'space-between',
    width: '310px',
    padding: '20px 25px',
    height: '350px'
  },

  PhenologicalStage__header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    padding: '1px'
  },

  PhenologicalStage__header_daysSinceStarted: {
    backgroundColor: theme.palette.gray.light
  },

  PhenologicalStage__content: {
    padding: '10px 15px 0'
  },

  PhenologicalStage__values: {
    textAlign: 'end'
  },

  PhenologicalStage__item: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '5px 0',
    fontSize: '14px'
  },

  PhenologicalStage__footer: {
    height: '36px',
    marginTop: '15px'
  },

  PhenologicalStage__actions: {
    display: 'flex',
    justifyContent: 'space-between'
  },

  PhenologicalStage__action: {
    fontSize: '14px'
  },

  'PhenologicalStage--previous': {
    backgroundColor: theme.area.dashboard.phenologicalStages.previous
  },

  'PhenologicalStage--current': {
    backgroundColor: theme.area.dashboard.phenologicalStages.current
  },

  'PhenologicalStage--next': {
    backgroundColor: theme.area.dashboard.phenologicalStages.next
  },

  'PhenologicalStage--previous_borders': {
    borderColor: theme.area.dashboard.phenologicalStages.previous
  },

  'PhenologicalStage--current_borders': {
    borderColor: theme.area.dashboard.phenologicalStages.current
  },

  'PhenologicalStage--next_borders': {
    borderColor: theme.area.dashboard.phenologicalStages.next
  },

  Dashboard__activeCulture_cardHead: {
    borderTop: '190px solid transparent',
    borderBottom: '190px solid transparent',
    borderLeft: '22px solid',
    borderLeftColor: 'inherit'
  },

  Dashboard__activeCulture_cardTail: {
    borderTop: '190px solid',
    borderBottom: '190px solid',
    borderLeft: '22px solid transparent',
    backgroundColor: 'transparent',
    borderTopColor: 'inherit',
    borderBottomColor: 'inherit',
  },
  Dashboard_action_delete: {
    cursor: 'pointer'
  },
})
