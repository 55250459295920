import React from 'react'
import MaterialTable from 'material-table'
import { getInitialConfigurations, getColumnsSorting, setTableConfiguration } from '@models/table'

const Table = ({ options, localization, tableKey, columns, ...props }) => {
  let savedConfigurations = {}

  if (!tableKey) {
    console.error('Add a tableKey to your table')
  }
  else {
    const { sorting, pageSize } = getInitialConfigurations(tableKey)

    if (pageSize != null) {
      savedConfigurations.pageSize = pageSize
    }

    columns = getColumnsSorting(sorting, columns)
  }

  return <MaterialTable
    {...props}
    columns={columns}
    options={{
      showEmptyDataSourceMessage: true,
      actionsColumnIndex: -1,
      emptyRowsWhenPaging: false,
      pageSizeOptions: [5, 10, 20],
      ...savedConfigurations,
      ...options
    }}
    localization={{
      ...localization,
      header: {
        actions: I18n.t('actions.actions'),
        ...localization.header
      },
      body: {
        emptyDataSourceMessage: I18n.t('info.no_data'),
        editRow: {
          deleteText: I18n.t('material_table.body.editRow.deleteText'),
          cancelTooltip: I18n.t('actions.cancel'),
          saveTooltip: I18n.t('actions.save'),
        },
        ...localization.body
      },
      pagination: {
        labelRowsSelect: I18n.t('material_table.pagination.labelRowsSelect'),
        labelRowsPerPage: I18n.t('material_table.pagination.labelRowsPerPage'),
        firstAriaLabel: I18n.t('material_table.pagination.firstAriaLabel'),
        firstTooltip: I18n.t('material_table.pagination.firstTooltip'),
        previousAriaLabel: I18n.t('material_table.pagination.previousAriaLabel'),
        previousTooltip: I18n.t('material_table.pagination.previousTooltip'),
        nextAriaLabel: I18n.t('material_table.pagination.nextAriaLabel'),
        nextTooltip: I18n.t('material_table.pagination.nextTooltip'),
        lastAriaLabel: I18n.t('material_table.pagination.lastAriaLabel'),
        lastTooltip: I18n.t('material_table.pagination.lastTooltip'),
        labelDisplayedRows: I18n.t('material_table.pagination.labelDisplayedRows'),
        ...localization.pagination
      },
      toolbar: {
        searchPlaceholder: I18n.t('material_table.toolbar.searchPlaceholder'),
        searchTooltip: I18n.t('material_table.toolbar.searchTooltip'),
        ...localization.toolbar
      }
    }}
    onChangePage={page => tableKey && setTableConfiguration(tableKey, { initialPage: page })}
    onChangeRowsPerPage={pageSize => tableKey && setTableConfiguration(tableKey, { pageSize })}
    onOrderChange={(id, order) => tableKey && setTableConfiguration(tableKey, { sorting: { id, order } })}
  />
}

Table.defaultProps = {
  localization: {}
}

export default Table
