import React from 'react'

import { Button, Typography, withStyles } from '@material-ui/core'

import { useMenusStatus, MENUS } from '@contexts/MenusStatusProvider'

const Intercom = ({ classes }) => {
  const { toggleMenu } = useMenusStatus()

  return (
    <div className={classes.Intercom_wrapper}>
      <Typography variant='body1' align='justify'>
        <span>
          {`${I18n.t('v4/product.no_products.text1')} `}
        </span>

        <strong>
          {`${I18n.t('v4/product.no_products.text2')} `}
        </strong>

        <span>
          {`${I18n.t('v4/product.no_products.text3')} `}
        </span>
      </Typography>

      <Button
        className={classes.Intercom_action}
        color='primary'
        variant='contained'
        onClick={() => {
          toggleMenu(MENUS.mapAreaMenu, false)
          window.Intercom('show')
        }}
      >
        {I18n.t('v4/product.no_products.actions.title')}
      </Button>
    </div>
  )
}

const styles = theme => ({
  Intercom_wrapper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing.unit * 2
  },
  Intercom_action: {
    marginTop: theme.spacing.unit * 2
  }
})

export default withStyles(styles)(Intercom)
