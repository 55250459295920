import React from 'react'
import { Form as FinalForm } from 'react-final-form'
import { RailsForm } from '@support/RailsForm'
import InputForm from '../Form'
import NewCultivarForm from './Form'
import { validate, cultivarShape } from '@models/inputs/cultivar'

const NewCultivar = ({ model, o }) => {
  const initialValues = {}

  const title = `${I18n.t('actions.add')} ${I18n.t(`v4/input.kinds.${model.kind}`)}`

  return (
    <RailsForm
      component={FinalForm}
      action='create'
      initialValues={initialValues}
      url={`/v4/o/${o}/inputs/cultivar`}
      validade={validate}
      render={(props) => (
        <InputForm
          title={title}
        >
          <NewCultivarForm
            {...props}
            action={title}
            o={o}
          />
        </InputForm>
      )}
    />
  )
}

NewCultivar.propTypes = {
  model: cultivarShape
}

export default NewCultivar
