import React, { useEffect } from 'react'

import OdometerDigit from './OdometerDigit'

const CustomOdometer = ({ value, customId }) => {
  const initialValue = 0

  const animateValue = (currentElement, start, end, duration) => {
    let startTimestamp = null

    const step = (timestamp) => {
      if (!startTimestamp) {
        startTimestamp = timestamp
      }

      const progress = Math.min((timestamp - startTimestamp) / duration, 1) 

      currentElement.innerHTML = Math.floor(progress * (end - start) + start)

      if (progress < 1) {
        window.requestAnimationFrame(step)
      }
    }

    window.requestAnimationFrame(step)
  }

  useEffect(() => {
    setTimeout(() => {
      const currentElement = document.getElementById(customId)

      animateValue(currentElement, initialValue, value, 300)
    }, 300)
  }, [value])

  return (
    <OdometerDigit
      initialValue={initialValue}
      customId={customId}
    />
  )
}

export default CustomOdometer
