export const getAvailableResources = (resources) =>
  avaliableResources.filter(available => resources.includes(available.id))

const avaliableResources = [
  {
    id: 'V4::Admin::Products',
    link: 'admin/products',
    label: I18n.t('v4/product.index.title')
  },
  {
    id: 'V4::Admin::HardwareProducts',
    link: 'admin/hardware_products',
    label: I18n.t('v4/hardware_products.index.title')
  },
  {
    id: 'V4::Admin::PartNumbers',
    link: 'admin/part_number_registries',
    label: I18n.t('v4/part_number.index.title')
  },
  {
    id: 'V4::Admin::Organizations',
    link: 'admin/organizations',
    label: I18n.t('v4/organization.index.title')
  },
  {
    id: 'V4::Admin::Tenants',
    link: 'admin/tenants',
    label: I18n.t('activerecord.models.v4/tenant.other')
  },
  {
    id: 'V4::Admin::SensorConfigs',
    link: 'admin/sensor_configs',
    label: I18n.t('activerecord.models.v4/configurations.other')
  },
  {
    id: 'V4::Admin::Sensors',
    link: 'admin/sensors',
    label: I18n.t('activerecord.models.v4/sensor.other')
  },
  {
    id: 'V4::Store::Admin::Plans',
    link: 'store/admin/plans',
    label: I18n.t('activerecord.models.v4/plans.other')
  },
  {
    id: 'V4::Admin::PrivacyPolicy',
    link: 'admin/privacy_policies',
    label: I18n.t('activerecord.models.v4/privacy_policies.other')
  }
]
