import React from 'react'
import PropTypes from 'prop-types'
import { Form as FinalForm } from 'react-final-form'

import { RailsForm } from '@support/RailsForm'
import Form from "./EditForm"

export const Index = ({ model, organization, organization_product, o }) => {
  return <RailsForm
    component={FinalForm}
    action='update'
    render={(props) => (
      <Form
        {...props}
        model={model}
        organization_product={organization_product}
        organization={organization}
        o={o}
      />
    )}
  />
}

Index.propTypes = {
  model: PropTypes.array
}

export default Index
