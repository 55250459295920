import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import withStyles from '@material-ui/core/styles/withStyles'

import Table from '@ui/Table'
import DeleteDialog from '../../ui/DeleteDialog'

import styles from '@styles/indexPage'

import { getAccountHeaders } from '@models/account'
import PaymentInfoTabs from '../PaymentInfoTabs'

import MasterCard from '@ui/Icons/MasterCard'
import Visa from '@ui/Icons/Visa'
import Elo from '@ui/Icons/Elo'

const Index = ({ model, organization_id, o }) => {
  var [dialogOpen, setState] = React.useState(null)
  const tab = 'payment_profiles'

  const openDialog = (value) => {
    setState(value)
  }

  const closeDialog = () => {
    setState(null)
  }

  const flags = {
    mastercard: <MasterCard />,
    visa: <Visa />,
    elo: <Elo />
  }

  const decorateData = (data) =>
    data.map(item => ({
      ...item,
      flag: flags[item.flag],
      card_number: `**** **** **** ${item.card_number}`
    }))

  return (
    <PaymentInfoTabs tab={tab} buttonURL='payment_profiles/new'>
      <Table
        tableKey='organizations-paymentProfiles-index'
        key='account-table'
        components={{
          Container: props => <div>{props.children}</div>
        }}
        columns={getAccountHeaders(tab)}
        data={
          decorateData(model)
        }
        options={{
          showTitle: false
        }}
        actions={
          [
            rowData => ({
              icon: 'delete',
              iconProps: { 'data-testid': `delete-${rowData.id}` },
              tooltip: I18n.t('actions.remove'),
              onClick: (event, rowData) => {
                openDialog(rowData.id)
              }
            })
          ]
        }
      />
      <DeleteDialog
        dialogTitle={`${I18n.t('activerecord.attributes.v4/organization_account.payment_profiles.actions.remove')}?`}
        dialogText={I18n.t('confirmation.remove')}
        successUrl={`/v4/o/${o}/organizations/${organization_id}/payment_profiles`}
        deleteUrl={`/v4/o/${o}/organizations/${organization_id}/payment_profiles/${dialogOpen}.json`}
        onCancel={closeDialog}
        onClose={closeDialog}
        open={!!dialogOpen}
      />
    </PaymentInfoTabs>
  )
}

export default withStyles(styles)(Index)
