import React from 'react'

import {
  Button,
  IconButton,
  Typography,
  withStyles,
} from '@material-ui/core'

import { Clear, Store as StoreIcon } from '@material-ui/icons'

const ExpiredProductCard = ({ classes, router, handleClose, title, trial, header = true }) =>
  <>
    {
      header &&
      <Header
        classes={classes}
        handleClose={handleClose}
        title={title}
        trial={trial}
      />
    }
    <div className={classes.ProductExpired_container}>
      <div className={classes.ProductExpired_wrapper}>
        <Typography variant='body2' align='center' className={classes.ProductExpired_title}>
          {I18n.t('v4/product.trial.test_period')}
        </Typography>

        <div className={classes.ProductExpired_infos}>
          <Typography variant='caption' align='center' className={classes.ProductExpired_info}>
            {I18n.t('v4/product.trial.text1')}
          </Typography>

          <Typography variant='caption' align='center' className={classes.ProductExpired_info}>
            {I18n.t('v4/product.trial.text2')}
          </Typography>
        </div>

        <Button
          className={classes.ProductExpired_action}
          color='primary'
          variant='contained'
          onClick={() => router.visit('store')}
        >
          {I18n.t('v4/product.trial.action.store')}
          <StoreIcon
            className={classes.ProductExpired_action_icon}
          />
        </Button>

        <Typography variant='caption' align='center' className={classes.ProductExpired_infos}>
          <span className={classes.ProductExpired_info}>
            {`${I18n.t('v4/product.trial.text3')} `}
          </span>
          <span className={classes.ProductExpired_info_link}>
            {`${I18n.t('v4/product.trial.text4')} `}
          </span>
          <span className={classes.ProductExpired_info}>
            {I18n.t('v4/product.trial.text5')}
          </span>
        </Typography>
      </div>
    </div>
  </>

const Header = ({ classes, handleClose, title, trial }) =>
  <div className={
    trial.expirationDate <= 0
      ? classes.ProductExpired_header_trial
      : classes.ProductExpired_header}
  >
    <Typography className={classes.ProductExpired_title} variant='subtitle2'>
      {title}
    </Typography>

    <div className={classes.ProductExpired_close}>
      <IconButton
        className={classes.ProductExpired_iconButton}
        onClick={handleClose}
      >

        <Clear />
      </IconButton>
    </div>
  </div>

const styles = theme => ({
  ProductExpired_container: {
    background: 'rgba(255, 255, 255, 0.6)',
    backdropFilter: 'blur(5px)',
    zIndex: 2,
    position: 'fixed',
    height: '90%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  ProductExpired_wrapper: {
    padding: theme.spacing.unit * 2
  },
  ProductExpired_title: {
    paddingBottom: theme.spacing.unit * 2,
    fontSize: '15px'
  },
  ProductExpired_infos: {
    cursor: 'pointer',
    paddingBottom: theme.spacing.unit * 3
  },
  ProductExpired_info: {
    fontSize: '13px'
  },
  ProductExpired_info_link: {
    fontSize: '13px',
    fontWeight: '500'
  },
  ProductExpired_action: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginBottom: theme.spacing.unit
  },
  ProductExpired_action_icon: {
    marginLeft: theme.spacing.unit
  },
  ProductExpired_header_trial: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginBottom: 10,
    padding: theme.spacing.unit * 2
  },
  ProductExpired_title: {
    flex: 3
  },
  ProductExpired_close: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  ProductExpired_iconButton: {
    padding: 0
  },
  ProductExpired_header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginBottom: 10
  },
})

export default withStyles(styles)(ExpiredProductCard)
