import React from 'react'

import httpService from '@services/httpService'

import { withStyles } from '@material-ui/core/styles'

import Steppers from './Stepper'
import CreateAreaStep from './new_alert/CreateAreaStep'
import LandingStep from './new_alert/LandingStep'
import SelectVariablesStep from './new_alert/SelectVariablesStep'
import SelectAreaStep from './new_alert/SelectAreaStep'
import SelectEquipmentStep from './new_alert/SelectEquipmentStep'
import SetValuesForVariablesStep from './new_alert/SetValuesForVariablesStep'
import AlertNameStep from './new_alert/AlertNameStep'
import ReceiveAlertStep from './new_alert/ReceiveAlertStep'
import ResumeAlertStep from './new_alert/AlertPreviewStep'
import { convertValueLocale, convertVariableWindSpeed } from '@models/alerts'

const steps = ['variables', 'areas', 'equipments', 'values', 'name', 'medias', 'preview']

class NewAlert extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      areasCount: 0,
      currentStep: 0,
      alert: '',
      showStepper: false,
      selectedVariables: [],
      conflicts: [],
      selectedAreas: [],
      activeArea: true,
      medias: {
        whatsapp: false,
        email: false
      },
      mediasValues: {
        whatsapp: [],
        email: []
      },
      mediasError: {
        whatsapp: false,
        email: false
      },
      isFromLastStep: false,
      sortedVariables: {},
      values: {}
    }
  }

  componentDidMount() {
    const { all_variables, areas } = this.props.model
    const sortedVariables = _.cloneDeep(all_variables)

    Object.values(sortedVariables).forEach(variable => {
      const sortedGroup = []
      variable.groups.forEach(group => {
        group.variables.forEach(groupVariable => groupVariable.groupDescription = group.group_description)
        return sortedGroup[group.index] = group
      })
      variable.groups = sortedGroup
    })

    const selectedAreas = areas.map(area => ({ ...area, selected: !!area.selected }))

    this.setState({ selectedAreas, sortedVariables })
  }

  handleEquipment = (selectedEquipment, areaId, isFromLastStep = false) => {
    const { selectedAreas } = this.state

    const activeAreas = selectedAreas.filter(area => area.selected)
    const inactiveAreas = selectedAreas.filter(area => !area.selected)

    const newAreas = activeAreas.map(area => {
      if (area.id === areaId) {
        const data_sources = area.data_sources.filter(data => data.kind !== selectedEquipment.kind)

        data_sources.push(selectedEquipment)
        return {
          ...area,
          data_sources
        }
      }

      return area
    })

    const conflicts = this.handleEquipmentConflits(newAreas)

    if (_.isEmpty(conflicts)) {
      return this.setState({
        conflicts: [],
        selectedAreas: [...inactiveAreas, ...newAreas]
      }, isFromLastStep ? this.returnToLastStep : this.advanceStep)
    }

    this.setState({ conflicts, selectedAreas: [...inactiveAreas, ...newAreas] })
  }

  handleEquipmentConflits = selectedAreas => {
    const equipments = []

    selectedAreas.forEach(area => {
      const areasKinds = _.groupBy(area.data_sources, 'kind')
      const areaGroups = Object.keys(areasKinds).map(key => areasKinds[key])

      const data_sources = areaGroups.filter(_area => _area.length > 1)
      const areas = { id: area.id, name: area.name, data_sources }

      equipments.push(areas)
    })

    return equipments.filter(equipment => !_.isEmpty(equipment.data_sources))
  }

  isNotLastStep = (steps, currentStep) => steps.length - 1 > currentStep

  isNotFirstStep = (currentStep) => currentStep !== 0

  isValidStep = (steps, step) => step > -1 && step < steps.length

  hasAreas = (areas) => !_.isEmpty(areas)

  advanceStep = () => {
    this.isNotLastStep(steps, this.state.currentStep) &&
      this.setState(prevState => ({
        currentStep: prevState.currentStep + 1
      }))
  }

  retreatStep = () => {
    this.isNotFirstStep(this.state.currentStep) &&
      this.setState(prevState => ({
        currentStep: prevState.currentStep - 1
      }))
  }

  goToStep = (step) => {
    this.isValidStep(steps, step) &&
      this.setState({
        currentStep: step
      })
  }

  getDataSources = (area, selectedVariables) => {
    const areasKinds = _.groupBy(selectedVariables, 'kind')
    const areaGroups = Object.keys(areasKinds).map(key => areasKinds[key])
    let dataSourceCurrent = []
    let dataSourcesAreas = {}

    areaGroups.forEach(group => {
      const variables = group.map(area => area.variable)
      const kind = group.map(area => area.kind)[0]

      const kinds = area.data_sources.filter(_kind => _kind.kind === kind)

      let filteredDataSources = kinds.filter(dataSource => {
        let countVariable = 0

        variables.forEach(_variable => {
          let checkVariable = dataSource.variables.includes(_variable)

          countVariable = checkVariable ? countVariable + 1 : countVariable
        })
        return countVariable === variables.length
      })

      if (!_.isEmpty(filteredDataSources)) {
        dataSourceCurrent.push(filteredDataSources)
      }
    })

    dataSourcesAreas = { id: area.id, data_sources: [...new Set(dataSourceCurrent.flat())] }

    return dataSourcesAreas
  }

  getVariablesFilter = () => {
    const { selectedVariables } = this.state
    const { areas } = this.props.model

    if (_.isEmpty(selectedVariables)) {
      return this.setState({
        selectedAreas: [],

        areasCount: 0
      })
    }

    let dataSources = []

    const variablesByGroup = _.groupBy(selectedVariables, 'kind')

    const availableAreas = areas.filter(area => {
      const includesAllVariables = Object.keys(variablesByGroup).every(group => {
        const filteredDataSources = area.data_sources.some(dataSource => {
          const hasVariablesByGroup = variablesByGroup[group].every(variable => {
            return dataSource.variables.includes(variable.variable)
          })
          return hasVariablesByGroup
        })
        return filteredDataSources
      })
      if (includesAllVariables) {
        dataSources.push(this.getDataSources(area, selectedVariables))
      }
      return includesAllVariables
    })

    const addDataSourcesInAvailableAreas = availableAreas.map(area => {
      const newDataSources = dataSources.find(data => data.id === area.id)

      return {
        ...area, data_sources: [...newDataSources.data_sources], selected: !!area.selected
      }
    })

    this.setState({
      selectedAreas: addDataSourcesInAvailableAreas,
      areasCount: addDataSourcesInAvailableAreas.length
    })
  }

  showStepper = () => {
    this.setState({ showStepper: true })
  }

  handleVariableSelect = (variable, kind, source) => {
    const { selectedVariables } = this.state
    const newVariablesArray = selectedVariables

    if (selectedVariables.some(_variable => _variable.variable === variable.variable)) {
      return this.setState({
        selectedVariables: newVariablesArray.filter(oldVariable => oldVariable.variable !== variable.variable)
      }, this.getVariablesFilter)
    } else {
      variable.kind = kind
      variable.source = source
      newVariablesArray.push(variable)

      this.setState({
        selectedVariables: newVariablesArray
      }, this.getVariablesFilter)
    }
  }

  handleAreaSelect = selectedArea => {
    const { selectedAreas } = this.state

    const newAreas = selectedAreas.map(area => ({ ...area, selected: area.id === selectedArea.id ? !selectedArea.selected : area.selected }))
    const activeAreas = newAreas.filter(area => area.selected)
    const checkArea = _.isEmpty(activeAreas)

    this.setState({
      selectedAreas: newAreas,
      conflicts: this.handleEquipmentConflits(activeAreas),
      activeArea: checkArea
    })
  }

  handleChangeAlertName = name => this.setState({ alertName: name })
  
  handleChangeAlertCategory = category => this.setState({alertCategory: category})

  handleSetVariableValues = (selectedVariables, isFromLastStep = false) =>
    this.setState(
      { selectedVariables },
      !isFromLastStep ? this.advanceStep : this.returnToLastStep
    )

  advanceStepSelectAreas = () => {
    const { conflicts } = this.state

    this.setState(prevState => ({
      currentStep: _.isEmpty(conflicts) ? prevState.currentStep + 2 : prevState.currentStep + 1
    }))
  }

  retreatStepSetValues = () => {
    this.setState(prevState => ({
      currentStep: prevState.currentStep - 2
    }))
  }

  handleChangeMedias = (mediasWhatsapp, mediasEmail, isFromLastStep = false) => {
    this.setState({
      mediasValues: {
        whatsapp: mediasWhatsapp,
        email: mediasEmail
      }
    }, isFromLastStep ? this.returnToLastStep : this.advanceStep)
  }

  backToStep = (step) => {
    step === 'variables' ? step = 'values' : step

    this.setState(prevState => ({
      currentStep: steps.indexOf(step),
      isFromLastStep: true
    }))
  }

  returnToLastStep = () => {
    this.setState(prevState => ({
      currentStep: steps.indexOf('preview'),
      isFromLastStep: false
    }))
  }

  save = () => {
    const { alertName, alertCategory, selectedAreas, selectedVariables, mediasValues } = this.state

    const dataAreas = selectedAreas
      .filter(area => area.selected)
      .map(area => ({ id: area.id, name: area.name, data_sources: area.data_sources }))

    const actions = Object.keys(mediasValues)
      .map(media => ({ kind: media, contacts: mediasValues[media] }))
      .filter(media => !_.isEmpty(media.contacts))

    const serialize = 'serialize'

    const data = {
      alert: {
        name: alertName,
        areas: dataAreas,
        alert_params: {
          category: alertCategory,
        },
        rules: convertVariableWindSpeed(convertValueLocale(selectedVariables, serialize), serialize),
        actions
      }
    }

    this.handleSubmit(data)
  }

  handleSubmit = async (data) => {
    const { handleError, o } = this.props

    try {
      await httpService.post(`/v4/o/${o}/alerts/`, data)
      Turbolinks.visit(`/v4/o/${o}/alerts`)
    }
    catch (error) {
      handleError(error)
    }
  }

  render() {
    const { classes, model } = this.props
    const { areas, available_rules } = model
    const {
      activeArea,
      alertName,
      alertCategory,
      areasCount,
      conflicts,
      currentStep,
      medias,
      selectedAreas,
      selectedVariables,
      showStepper,
      sortedVariables,
      isFromLastStep,
      mediasValues,
    } = this.state

    return (
      <div className={classes.NewAlert}>
        <main>
          {
            !showStepper
              ? this.hasAreas(areas)
                ? (
                  <div className={classes.NewAlert_landing}>
                    <LandingStep handleClick={this.showStepper} />
                  </div>
                )
                : (
                  <div className={classes.NewAlert_landing}>
                    <CreateAreaStep advanceStep={() => Turbolinks.visit('/v4/o/${o}')} />
                  </div>
                )
              :
              <Steppers
                currentStep={currentStep}
                steps={steps}
              >
                <SelectVariablesStep
                  areasCount={areasCount}
                  advanceStep={this.advanceStep}
                  retreatStep={this.retreatStep}
                  handleVariableSelect={this.handleVariableSelect}
                  selectedVariables={selectedVariables}
                  variables={sortedVariables}
                />
                <SelectAreaStep
                  variables={selectedVariables}
                  areas={selectedAreas}
                  handleAreaSelect={this.handleAreaSelect}
                  advanceStep={this.advanceStepSelectAreas}
                  retreatStep={this.retreatStep}
                  activeArea={activeArea}
                  isFromLastStep={isFromLastStep}
                  save={!_.isEmpty(conflicts) ? this.advanceStepSelectAreas : this.returnToLastStep}
                  description={true}
                />
                {
                  !_.isEmpty(conflicts) &&
                  <SelectEquipmentStep
                    dataSources={conflicts[0]['data_sources'][0]}
                    advanceStep={this.handleEquipment}
                    areaName={conflicts[0].name}
                    idArea={conflicts[0].id}
                    retreatStep={this.retreatStep}
                    save={this.returnToLastStep}
                    isFromLastStep={isFromLastStep && conflicts[0]['data_sources'].length === 1}
                  />

                }
                <SetValuesForVariablesStep
                  advanceStep={this.handleSetVariableValues}
                  retreatStep={this.retreatStepSetValues}
                  selectedVariables={selectedVariables}
                  availableRules={available_rules}
                  isFromLastStep={isFromLastStep}
                />
                <AlertNameStep
                  handleChangeAlertName={this.handleChangeAlertName}
                  handleChangeAlertCategory={this.handleChangeAlertCategory}
                  areas={selectedAreas}
                  alertName={alertName}
                  alertCategory={alertCategory}
                  advanceStep={this.advanceStep}
                  retreatStep={this.retreatStep}
                />
                <ReceiveAlertStep
                  advanceStep={this.handleChangeMedias}
                  retreatStep={this.retreatStep}
                  medias={medias}
                  isFromLastStep={isFromLastStep}
                  showBackButton={!isFromLastStep}
                  mediasValues={mediasValues}
                />
                <ResumeAlertStep
                  advanceStep={this.save}
                  retreatStep={this.retreatStep}
                  goToStep={this.backToStep}
                  name={alertName}
                  data={{
                    areas: selectedAreas,
                    variables: selectedVariables,
                    medias: {
                      whatsapp: !_.isEmpty(mediasValues.whatsapp),
                      email: !_.isEmpty(mediasValues.email)
                    }
                  }}
                  handleChangeAlertName={this.handleChangeAlertName}
                  handleChangeAlertCategory={this.handleChangeAlertCategory}
                  alertCategory={alertCategory}
                />
              </Steppers>
          }
        </main>
      </div>
    )
  }
}

NewAlert.defaultProps = {
  model: {
    available_rules: [],
    all_variables: {},
    areas: []
  }
}

const styles = theme => ({
  NewAlert: {
    margin: `${theme.spacing.unit * 5}px auto 0`,
    maxWidth: 1200,
    padding: theme.spacing.unit * 4,
    [theme.breakpoints.up('md')]: {
      padding: '50px 256px 50px'
    }
  },
  NewAlert_landing: {
    display: 'flex',
    justifyContent: 'center'
  }
})

export default withStyles(styles)(NewAlert)
