import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'
import MembersIcon from '@material-ui/icons/Group'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import Table from '@ui/Table'

import { getOrganizationHeaders, customPadding } from '@models/organization'
import DeleteDialog from '../../ui/DeleteDialog'
import styles from '@styles/indexPage'

class Index extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isDialogOpen: false,
      id: null
    }

    this.closeDialog = this.closeDialog.bind(this)
  }

  closeDialog() {
    this.setState({ isDialogOpen: false })
  }

  render() {
    const { model, classes, o } = this.props
    const { isDialogOpen, id } = this.state

    return (
      <Paper className={classes.Table_wrapper} elevation={0}>
        <header className={classes.page__header}>
          <Typography component='h2' variant='h5'>
            {I18n.t('activerecord.models.v4/organization.other')}
          </Typography>
        </header>
        <Button
          variant='outlined'
          color='primary'
          className={classes.page__newResourceButton}
          href={`/v4/o/${o}/admin/organizations/new`}
        >
          {I18n.t('actions.create')} {I18n.t('activerecord.models.v4/organization.one')}
        </Button>

        <Table
          tableKey='admin-organizations-index'
          components={{
            Container: props => <div>{props.children}</div>,
          }}
          columns={[
            ...getOrganizationHeaders(),
            {
              title: I18n.t('activerecord.models.v4/tenant.one'),
              field: 'tenant[name]',
              cellStyle: customPadding
            }
          ]}
          data={model}
          actions={[
            rowData => ({
              icon: 'create',
              iconProps: { 'data-testid': `edit-${rowData.id}` },
              tooltip: I18n.t('actions.edit'),
              onClick: (event, rowData) => {
                Turbolinks.visit(`/v4/o/${o}/admin/organizations/${rowData.id}/edit`)
              }
            }),
            rowData => ({
              icon: 'cloud',
              iconProps: { 'data-testid': `products-of-${rowData.id}` },
              tooltip: I18n.t('v4/product.index.title'),
              onClick: (event, rowData) => {
                Turbolinks.visit(`/v4/o/${o}/admin/organizations/${rowData.id}/products`)
              }
            }),
            rowData => ({
              icon: MembersIcon,
              iconProps: { 'data-testid': `members-of-${rowData.id}` },
              tooltip: I18n.t('v4/users.members.other'),
              onClick: (event, rowData) => {
                Turbolinks.visit(`/v4/o/${o}/admin/organizations/${rowData.id}/users`)
              }
            }),
            rowData => ({
              icon: 'notifications',
              iconProps: { 'data-testid': `alerts-of-${rowData.id}` },
              tooltip: I18n.t('v4/alerts.alerts.title'),
              onClick: (event, rowData) => {
                Turbolinks.visit(`/v4/o/${o}/admin/organizations/${rowData.id}/alerts`)
              }
            }),
            rowData => ({
              icon: 'delete',
              iconProps: { 'data-testid': `delete-${rowData.id}` },
              tooltip: I18n.t('actions.remove'),
              onClick: (event, rowData) => {
                this.setState({ isDialogOpen: true, id: rowData.id })
              }
            })
          ]}
          options={{
            showTitle: false,
            headerStyle: customPadding
          }}
        />

        <DeleteDialog
          dialogTitle={`${I18n.t('actions.remove')} ${I18n.t('activerecord.models.v4/organization.one')}?`}
          dialogText={I18n.t('confirmation.remove')}
          successUrl={`/v4/o/${o}/admin/organizations`}
          deleteUrl={`/v4/o/${o}/admin/organizations/${id}.json`}
          onCancel={this.closeDialog}
          onClose={this.closeDialog}
          open={isDialogOpen}
        />
      </Paper>
    )
  }
}

const customStyles = theme => ({
  ...styles(theme),
  Table_wrapper: {
    backgroundColor: 'transparent',
    margin: `${theme.spacing.unit * 5}px auto 0`,
    maxWidth: 1250,
    padding: theme.spacing.unit * 4
  }
})

Index.propTypes = {
  model: PropTypes.array
}

export default withStyles(customStyles)(Index)
