import React, { useEffect } from 'react'
import PropTypes from 'prop-types'

import {
  Button,
  Checkbox,
  FormControlLabel,
  Paper,
  Typography,
  withStyles
} from '@material-ui/core'

import { Field } from 'react-final-form'
import TextField from '../../ui/Fields/TextField'
import Select from '../../ui/Fields/Select'
import styles from '@styles/forms'

export const validate = values => {
  const fields = ['barcode', 'pin', 'status', 'v4_part_number_registry_id', 'communication_address']
  const errors = {}

  fields.forEach(field => {
    if (!values[field]) errors[field] = I18n.t('validations.required')
  })

  return errors
}

const Form = ({
  classes,
  edit,
  handleSubmit,
  part_number_registries,
  statuses,
  title,
  values,
  o
}) => {
  const [state, setState] = React.useState({
    registeredGlobalstar: false
  })

  useEffect(() => {
    !!values.notes1 && (values.notes1 === 'f'
      ? setState({ registeredGlobalstar: false })
      : setState({ registeredGlobalstar: true })) 
  }, [])

  useEffect(() => {
    values.notes1 = state.registeredGlobalstar
  }, [state.registeredGlobalstar])

  useEffect(() => {
    !values.notes2 &&
    (values.notes2 = '')
  }, [values.notes2])

  const handleChangeRegisteredGlobalstar = () => {
    setState({ registeredGlobalstar: !state.registeredGlobalstar })
  }

  return (
    <form className={classes.form} onSubmit={handleSubmit} data-testid="hardware-products-form" >
      <Paper className={classes.form__wrapper} elevation={0}>
        <header className={classes.form__header}>
          <Typography component='h2' variant='h5'>
            {title}
          </Typography>
        </header>
        <main className={classes.form__fields}>
          <div className={classes.form__field__wrapper}>
            <Field
              component={TextField}
              className={classes.form__fieldWithError}
              fullWidth
              InputLabelProps={{ shrink: true }}
              variant='outlined'
              label={I18n.t('v4/hardware_products.labels.barcode')}
              name='barcode'
              required
              disabled={edit}
            />
          </div>
          <div className={classes.form__field__wrapper}>
            <Field
              component={TextField}
              className={classes.form__fieldWithError}
              fullWidth
              InputLabelProps={{ shrink: true }}
              variant='outlined'
              label={I18n.t('v4/hardware_products.labels.identifier')}
              name='identifier'
              required
            />
          </div>
          <div className={classes.form__field__wrapper}>
            <Field
              component={TextField}
              className={classes.form__fieldWithError}
              fullWidth
              InputLabelProps={{ shrink: true }}
              variant='filled'
              label={I18n.t('v4/hardware_products.labels.pin')}
              name='pin'
              required
              inputProps={{ readOnly: true }}
            />
          </div>
          <div className={classes.form__field__wrapper}>
            <Field
              component={Select}
              className={classes.form__fieldWithError}
              fullWidth
              InputLabelProps={{ shrink: true }}
              variant='outlined'
              label={I18n.t('v4/hardware_products.labels.status')}
              name='status'
              fields={{ id: 'value', name: 'value' }}
              data={statuses}
              required
              disabled={edit}
            />
          </div>
          <div className={classes.form__field__wrapper}>
            <Field
              component={Select}
              className={classes.form__fieldWithError}
              fullWidth
              InputLabelProps={{ shrink: true }}
              variant='outlined'
              label={I18n.t('v4/hardware_products.labels.part_number')}
              name='v4_part_number_registry_id'
              fields={{ id: 'id', name: 'value' }}
              data={part_number_registries}
              required
            />
          </div>
          <div className={classes.form__field__wrapper}>
            <Field
              component={TextField}
              className={classes.form__fieldWithError}
              fullWidth
              InputLabelProps={{ shrink: true }}
              variant='outlined'
              label={I18n.t('v4/hardware_products.labels.communication_address')}
              name='communication_address'
              required
            />
          </div>
          <div className={classes.form__field__wrapper}>
            <Field
              component={TextField}
              className={classes.form__fieldWithError}
              fullWidth
              InputLabelProps={{ shrink: true }}
              variant='outlined'
              label={I18n.t('v4/hardware_products.labels.pcb_version')}
              name='pcb_version'
            />
          </div>
          <div className={classes.form__field__wrapper}>
            <Field
              component={TextField}
              className={classes.form__fieldWithError}
              fullWidth
              InputLabelProps={{ shrink: true }}
              variant='outlined'
              label={I18n.t('v4/hardware_products.labels.firmware')}
              name='firmware'
            />
          </div>
          <div className={classes.form__field__wrapper}>
            <Field
              component={TextField}
              className={classes.form__fieldWithError}
              fullWidth
              InputLabelProps={{ shrink: true }}
              variant='outlined'
              label={I18n.t('v4/hardware_products.labels.notes_2')}
              name='notes2'
            />
          </div>
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.registeredGlobalstar}
                  onChange={handleChangeRegisteredGlobalstar}
                  value='checked'
                  color='primary'
                />
              }
              label={I18n.t('v4/hardware_products.labels.globalstar')}
              classes={{
                label: classes.UserTerms_text
              }}
            />
          </div>
        </main>
        <footer className={classes.form__actions}>
          <Button
            className={classes.form__action}
            color='primary'
            type='submit'
            variant='contained'
          >
            {title}
          </Button>

          <Button
            className={classes.form__action}
            color='secondary'
            href={`/v4/o/${o}/admin/hardware_products`}
          >
            {I18n.t('actions.back')}
          </Button>
        </footer>
      </Paper>
    </form>
  )
}

Form.propTypes = {
  values: PropTypes.object,
  title: PropTypes.string,
  statuses: PropTypes.array.isRequired,
  part_number_registries: PropTypes.array.isRequired
}

export default withStyles(styles)(Form)
