import React from 'react'

import { Paper, withStyles } from '@material-ui/core'

import Table from '@ui/Table'

import { customPadding, deserializeHourlyData, getForecastPointHeaders } from '@models/weather/dashboard'

const DashboardTable = ({ classes, data, descriptions, isMobile }) =>
  <Paper className={classes.DashboardTable_wrapper} elevation={0}>
    <Table
      tableKey='weather-index'
      key='weather-table'
      components={{
        Container: props => <div>{props.children}</div>,
      }}
      columns={getForecastPointHeaders(descriptions, isMobile)}
      data={deserializeHourlyData(data)}
      options={{
        showTitle: false,
        search: false,
        headerStyle: customPadding,
        toolbar: false
      }}
    />
  </Paper>

const styles = theme => ({
  DashboardTable_wrapper: {
    backgroundColor: 'transparent',
    margin: `${theme.spacing.unit * 5}px auto 0`,
    padding: 0
  }
})

export default withStyles(styles)(DashboardTable)
