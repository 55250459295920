import React from 'react'

import TextField from '@ui/Fields/TextField'

import IconButton from '@material-ui/core/IconButton'
import InputAdornment from '@material-ui/core/InputAdornment'
import Visibility from '@material-ui/icons/Visibility'
import VisibilityOff from '@material-ui/icons/VisibilityOff'

const SimpleTextFieldWithIcon = ({ value, name, label, required, onChange, onBlur, classes }) => {
  const [showPassword, changePasswordVisibility] = React.useState(false)
  
  return (
    <TextField
      className={classes.Login__form_field}
      InputLabelProps={{
        classes: {
          root: classes.Login__form_field_label,
          focused: classes.Login__form_field_label_focused,
        },
        shrink: true
      }}
      InputProps={{
        classes: {
          root: classes.Login__form_field_outlined_input,
          focused: classes.Login__form_field_focused,
          notchedOutline: classes.notchedOutline,
        },
        inputProps: {
          className: classes.Login_remove_autocomplete_background
        },
        endAdornment:
          <InputAdornment position='end'>
            <IconButton
              onClick={() => changePasswordVisibility(!showPassword)}
            >
              {showPassword ? <Visibility /> : <VisibilityOff />}
            </IconButton>
          </InputAdornment>
      }}
      margin='normal'
      value={value}
      onBlur={onBlur}
      onChange={onChange}
      fullWidth
      variant='outlined'
      label={label}
      name={name}
      type={showPassword ? 'input' : 'password'}
      required={required}
    />
  )
}

export default SimpleTextFieldWithIcon
