import FileSaver from 'file-saver'
import { toPng } from 'html-to-image'

export const getMinMaxValues = (array, value) => {
  let data = []

  if (Array.isArray(value)) {
    data = _.flatten(
      value.map(serie =>
        array.map(item => Number(item[serie.id]))
      )
    )
  }
  else {
    data = array.map(item => Number(item[value]))
  }

  const min = Math.floor(_.min(data) * 0.95) || 0
  const max = Math.ceil(_.max(data) * 1.05)

  return [min, max]
}

export const getYAxisLabelPosition = (index) =>
  index % 2 === 0 ? 'left' : 'right'

export const getYAxisOrientation = (index) =>
  index % 2 === 0 ? 'left' : 'right'

export const exportChartToPng = async (svgData) =>
  toPng(svgData, { backgroundColor: '#FAFAFA' })
    .then((dataUrl) =>
      FileSaver.saveAs(dataUrl, `chart-${new Date().toISOString()}.png`)
    )
    .catch(function (error) {
      console.error('Error on save image', error)
    });

export const chartsFontFamily = 'Roboto, Helvetica, Arial, sans-serif'
