import keyMirror from '@utils/keyMirror'

export default ({
  palette: {
    background: {
      paper: '#FAFAFA',
      default: '#FAFAFA'
    },
    text: {
      primary: 'rgba(0, 0, 0, 0.87)',
      secondary: 'rgba(0, 0, 0, 0.54)'
    },
    primary: {
      dark: '#497530',
      light: '#b3D1A1',
      main: '#66A343',
    },
    secondary: {
      dark: '#3A5D26',
      light: '#66A343',
      main: '#41692B'
    },
    gray: {
      light: '#F5F5F5',
      main: '#D9D9D9',
      dark: '#767676'
    },
    hydrometerValueColor: '#393939',
    white: '#FFF',
    darkRed: '#D32F2F',
    red: '#FB4343',
    black: '#101010',
    trialColor: '#FF9213',
    trialColorRed: '#F03939',
    hoverRed: 'rgb(220, 0, 78)',
    gavish: {
      main: '#77a31c',
      gray: '#BDBDBD',
      red: '#DC143C',
      black: '#6E6E6E',
      darkGray: '#e6e6e6',
      blue: 'blue',
      grayMain: 'gray',
      grayLight: 'rgba(0, 0, 0, 0.26)'
    },
    cards: {
      blue: '#3EA2FF',
      green: '#63B345',
      yellow: '#FF9900',
      red: '#FF3C3C',
      gray: '#A5A5A5',
      shadowColor: 'rgba(0, 0, 0, .25)'
    },
    irrigationManagement: {
      blue: '#3EA2FF',
      green: '#63B345',
      yellow: '#FF9900',
      red: '#FF3C3C',
      gray: '#A5A5A5',
      shadowColor: 'rgba(0, 0, 0, .25)'
    },
    forecast: {
      red: '#FF3C3C',
      blue: '#3EA2FF',
      darkBlue: '#0070D8',
      gray: '#757575'
    },
    marginalDrop: 'rgb(252 193 45)'
  },
  danger: {
    backgroundColor: '#FB4343',
    color: '#FFF',
    '&:hover': {
      backgroundColor: '#D32F2F'
    }
  },
  menus: {
    background: '#FFF',
    icons: 'rgba(0, 0, 0, 0.54)',
    sideBarIcons: 'rgba(0, 0, 0, 0.56)'
  },
  area: {
    dashboard: {
      phenologicalStages: {
        current: '#D4ECC5',
        next: '#F8F8F8',
        previous: '#CDCDCD'
      }
    }
  },
  scrollbar: {
    '&::-webkit-scrollbar': {
      width: '5px',
      height: '10px',
      borderRadius: '5px'
    },
    '&::-webkit-scrollbar-track': {
      background: '#F5F5F5',
      borderRadius: '5px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#767676',
      borderRadius: '5px'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: '#767676'
    }
  },
  typography: {
    useNextVariants: true,
    h5: {
      fontSize: '18pt',
      fontWeight: '500'
    },
    h6: {
      fontSize: '15pt',
    },
    body1: {
      fontSize: '11.5pt',
      fontWeight: 'normal'
    },
    body2: {
      fontSize: '11.5pt',
      fontWeight: '500'
    },
    caption: {
      fontSize: '9pt',
      fontWeight: 'normal'
    }
  }
})

export const themeOptions = keyMirror({
  light: null,
  default: null
})
