import React from 'react'
import PropTypes from 'prop-types'
import { Form as FinalForm } from 'react-final-form'

import { validate } from '@models/organization'
import { RailsForm } from '@support/RailsForm'
import Form from './Form'
import httpService from '@services/httpService'

const handleSubmit = async (values, handleError, o) => {
  const organization = {
    ...values,
    timezone: values.timezone.value
  }

  try {
    await httpService.put(`/v4/o/${o}/organizations/${values.id}`, organization)

    Turbolinks.visit(`/v4/o/${o}/account/edit#organizations`)
  }
  catch(error){
    handleError(error)
  }
}

export const Edit = ({ model, organization_kinds, predominant_cultures, timezone_list, handleError, o }) => {
  const initialValues={
    ...model,
    timezone: {
      label: model.timezone,
      name: model.timezone
    }
  }

  return <RailsForm
    component={FinalForm}
    action='update'
    url={`/v4/o/${o}/organizations/${model.id}`}
    successUrl={`/v4/o/${o}/account/edit#organizations`}
    handleSubmit={(values) => handleSubmit(values, handleError, o)}
    validate={validate}
    initialValues={initialValues}
    render={(props) => (
      <Form
        {...props}
        title={I18n.t('v4/organization.actions.update')}
        organizationKinds={organization_kinds}
        predominantCultures={predominant_cultures}
        timezoneList={timezone_list}	
        o={o}
      />
    )}
  />
}

Edit.propTypes = {
  model: PropTypes.object,
  organization_kinds: PropTypes.array
}

export default Edit
