import React from 'react'

import Dialog from '@ui/Dialog'

import AreaNotes from './FormWrapper'

const NoteDialog = ({ dialogOpen, handleCloseDialog, areas, handleError, organization_id }) => (
  <Dialog
    open={dialogOpen}
    onClose={handleCloseDialog}
    title={I18n.t('v4/area.notes.title')}
  >
    <AreaNotes
      areas={areas}
      onCancel={handleCloseDialog}
      handleError={handleError}
      organization_id={organization_id}
    />
  </Dialog>
)

NoteDialog.defaultProps = {
  areas: []
}

export default NoteDialog
