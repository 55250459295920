import React from 'react'

import { Grid, Typography, withStyles } from '@material-ui/core'

import { getBatteryStatus } from '@models/equipment/equipment'

const BatteryStatus = ({ classes, data }) =>
  <>
    <Grid item xs={1} className={classes.Card_batteryIcon}>
      {getBatteryStatus(data.value, classes)['icon']}
    </Grid>
    <Grid item xs={11} className={classes.Card_batteryInfo}>
      <Grid item xs={12}>
        <Typography variant='body1' className={classes.Card_bold}>
          {getBatteryStatus(data.value, classes)['status']}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        <Typography variant='caption'>
          {getBatteryStatus(data.value, classes)['info']}
        </Typography>
      </Grid>
    </Grid>
  </>

  const styles = theme => ({
    Card_batteryInfo: {
      display: 'flex',
      alignItems: 'center',
      flexWrap: 'wrap'
    },
    Card_batteryIcon: {
      display: 'flex',
      alignItems: 'center'
    },
    Card_bold: {
      fontWeight: 500
    },
    Equipment_icons: {
      height: theme.spacing.unit * 3
    }
  })

  export default withStyles(styles)(BatteryStatus)
