import React from 'react'

import { Typography, withStyles } from '@material-ui/core'

import WindRoseCircle from '@images/wind_rose_circle'
import WindRosePoints from '@images/wind_rose_points'

const getCardinalPoint = (angle = 0) => {
  const cardinalPoints = [
    {
      name: I18n.t('v4/equipment.cardinal_points.north'),
      start: 337.5,
      end: 360
    },
    {
      name: I18n.t('v4/equipment.cardinal_points.north'),
      start: 0,
      end: 22.5
    },
    {
      name: I18n.t('v4/equipment.cardinal_points.northeast'),
      start: 22.5,
      end: 67.5
    },
    {
      name: I18n.t('v4/equipment.cardinal_points.east'),
      start: 67.5,
      end: 112.5
    },
    {
      name: I18n.t('v4/equipment.cardinal_points.southeast'),
      start: 112.5,
      end: 157.5
    },
    {
      name: I18n.t('v4/equipment.cardinal_points.south'),
      start: 157.5,
      end: 202.5
    },
    {
      name: I18n.t('v4/equipment.cardinal_points.southwest'),
      start: 202.5,
      end: 247.5
    },
    {
      name: I18n.t('v4/equipment.cardinal_points.west'),
      start: 247.5,
      end: 292.5
    },
    {
      name: I18n.t('v4/equipment.cardinal_points.northwest'),
      start: 292.5,
      end: 337.5
    }
  ]

  return cardinalPoints.find(point => _.inRange(angle, point.start, point.end))
}

const WindDirection = ({ classes, angle, size = 25, align }) =>
  <div className={
    align === 'right'
      ? classes.WindDirection_container
      : `${classes.WindDirection_container} ${classes.WindDirection_container_left}`
  }
  >
    <div className={classes.WrapperWindRose}>
      <div
        className={classes.WindRosePoint}
        style={{ transform: `rotate(${angle || 0}deg)` }}
      >
        <img src={WindRoseCircle} style={{ width: `${size}px` }} />
      </div>

      <img
        src={WindRosePoints}
        style={{
          width: `${size * 2}px`,
          maxHeight: (size * 2) + 2
        }}
      />
    </div>

    <Typography
      component='h2'
      variant='caption'
      className={
        align === 'right'
          ? classes.WindDirection_cardinal_point_direction_right
          : classes.WindDirection_cardinal_point_direction_left
      }
    >
      {getCardinalPoint(angle)['name']}
    </Typography>
  </div>

const styles = (theme) => ({
  WindDirection_container: {
    display: 'flex',
    alignItems: 'center'
  },
  WindDirection_container_left: {
    flexDirection: 'row-reverse'
  },
  WrapperWindRose: {
    display: 'flex',
    justifyContent: 'center',
    position: 'relative',
  },
  WindRosePoint: {
    position: 'absolute',
    marginTop: '10px',
  },
  WindDirection_cardinal_point_direction_left: {
    marginRight: theme.spacing.unit,
    fontSize: '10pt'
  },
  WindDirection_cardinal_point_direction_right: {
    marginLeft: theme.spacing.unit,
    fontSize: '10pt'
  }
})

export default withStyles(styles)(WindDirection)
