import React from 'react'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

import httpService from '@services/httpService'
import { getPlansHeaders } from '@models/admin/plans'

import Table from '@ui/Table'

import styles from '@styles/indexPage'

const Index = ({
  classes,
  model,
  o
}) =>
  <Paper className={classes.page__wrapper} elevation={0}>
    <header className={classes.page__header}>
      <Typography component='h2' variant='h5'>
        {I18n.t('activerecord.models.v4/plans.other')}
      </Typography>
    </header>

    <Button
      variant='outlined'
      color='primary'
      className={classes.page__newResourceButton}
      href={`/v4/o/${o}/store/admin/plans/new`}
    >
      {I18n.t('actions.add')} {I18n.t('activerecord.models.v4/plans.one')}
    </Button>

    <Table
      tableKey='admin-plans-index'
      components={{
        Container: props => <div>{props.children}</div>,
      }}
      columns={getPlansHeaders()}
      data={model}
      actions={[
        rowData => ({
          icon: 'create',
          iconProps: { 'data-testid': `edit-${rowData.id}` },
          tooltip: I18n.t('actions.edit'),
          onClick: (event, rowData) => {
            Turbolinks.visit(`/v4/o/${o}/store/admin/plans/${rowData.id}/edit`)
          }
        }),
      ]}
      options={{
        showTitle: false
      }}
    />
  </Paper>

Index.propTypes = {
  model: PropTypes.array
}

Index.defaultProps = {
  model: {}
}

export default withStyles(styles)(Index)
