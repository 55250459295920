import React from 'react'
import PropTypes from 'prop-types'

import CultureForm from './CultureForm'

const Edit = ({ cultivars, template_cultures, model, handleError, o }) =>
  <CultureForm
    cultivars={cultivars}
    cultureTemplates={template_cultures}
    title={I18n.t('v4/culture.actions.edit')}
    initialValues={model}
    action='update'
    url={`/v4/o/${o}/cultures/${model.id}`}
    successUrl={`/v4/o/${o}/cultures/${model.id}`}
    handleError={handleError}
    o={o}
  />

Edit.propTypes = {
  template_cultures: PropTypes.array,
  cultivars: PropTypes.array
}

export default Edit
