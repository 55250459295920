import React from 'react'

import moment from 'moment'

import { Clear, HelpOutline } from '@material-ui/icons'
import {
  Button,
  Divider,
  IconButton,
  MenuItem,
  TextField,
  Tooltip,
  Typography,
  withStyles,
} from '@material-ui/core'

import Slider from 'rc-slider'
import 'rc-slider/assets/index.css'

import Select from '@ui/Fields/Select'
import LoadingComponent from '@ui/LoadingComponent'
import DatePicker from '@ui/Fields/DatePicker'
import SearchSelect from '@ui/Fields/SearchSelect'

import styles from '@theme/styles/forms'

import { getPercentageCloud, getInitialValuesSearch } from '@models/satelliteImagery'
import { useMenusStatus, MENUS } from '@contexts/MenusStatusProvider'
import { useRouter } from '@contexts/RouterProvider'

import Tabs from './Tabs'

const marks = { 0: '', 20: '', 70: '', 100: '' }
const defaultCloudsPercentage = 100
const defaultValue = 0

const FilterForm = ({
  classes,
  areas,
  handleClose,
  changeMapCenter,
  filterGroup,
  handleCancelLoading,
  handleSubmit,
  isComparison,
  loading,
  names,
  values
}) => {
  const router = useRouter()
  const menusStatus = useMenusStatus()
  const { setMenuData } = menusStatus

  const [state, setState] = React.useState({
    cloudsPercentage: values.cloudsPercentage || defaultCloudsPercentage,
    startDate: values.startDate || new Date(new Date().setDate(new Date().getDate() - 30)),
    endDate: values.endDate || new Date(),
    filterId: values.filterId || defaultValue,
    currentAreaId: values.currentAreaId || defaultValue,
    errors: {}
  })
  const [tabContent, setTabContent] = React.useState('search')

  const [areaValue, setAreaValue] = React.useState(getInitialValuesSearch(values))
  const [selectAreaValue, setSelectAreaValue] = React.useState({
    value: values.currentAreaId || defaultValue
  })

  const handleChange = ({ name, value }) => {
    if (name === 'filterId') {
      setState({
        ...state,
        [name]: value,
        errors: {
          ...state.errors,
          filterId: null
        }
      })
    } else {
      setState({
        ...state,
        [name]: value,
      })
    }
    values[name] = value
  }

  const onChangeArea = value => {
    const currentArea = areas.find(area => area.id === value)

    changeMapCenter([currentArea.latitude, currentArea.longitude], currentArea.size_ha)

    setState({
      ...state,
      errors: {
        ...state.errors,
        currentAreaId: null
      }
    })

    isComparison && setMenuData(MENUS[names.filterMenu], 'area', value)
  }

  const handleAreaSelect = ({ value }) => {
    setSelectAreaValue({ value })
    onChangeArea(value)

    values['currentAreaId'] = value
  }

  const handleSearchSelect = (selectedArea) => {
    setAreaValue(selectedArea)
    selectedArea && onChangeArea(selectedArea.value)

    values['currentAreaId'] = selectedArea ? selectedArea.value : null
  }

  const hasError = () => {
    const requiredFields = ['currentAreaId', 'filterId']
    const errors = {}

    requiredFields.forEach(field => {
      if (!values[field]) errors[field] = I18n.t('validations.required')
    })

    setState({
      ...state,
      errors
    })

    return !_.isEmpty(errors)
  }

  const submit = (e) => {
    e.preventDefault()
    setRequestParams()

    !hasError() && handleSubmit(values)
  }

  const setRequestParams = () => {
    values['cloudsPercentage'] = !!values['cloudsPercentage'] ? values.cloudsPercentage : state.cloudsPercentage
    values['startDate'] = !!values['startDate'] ? values.startDate : state.startDate
    values['endDate'] = !!values['endDate'] ? values.endDate : state.endDate
  }

  const changeTabContent = type => {
    setTabContent(type)
  }

  return (
    <form
      onSubmit={submit}
      className={classes.SatelliteImagery_Container}
    >
      {
        !loading
          ? <>
            <Header
              classes={classes}
              handleClose={handleClose}
              isComparison={isComparison}
            />

            {
              !isComparison
              && <Tabs
                changeContent={changeTabContent}
                active={tabContent}
              />
            }

            {
              tabContent === 'search'
                ? <>
                  <Selector
                    classes={classes}
                    areas={areas}
                    error={state.errors.currentAreaId}
                    handleAreaSelect={handleAreaSelect}
                    handleSearchSelect={handleSearchSelect}
                    areaValue={areaValue}
                    selectAreaValue={selectAreaValue}
                  />

                  <TextFieldWithGroup
                    classes={classes}
                    handleChange={handleChange}
                    state={state}
                    filterGroup={filterGroup}
                  />

                  <Footer
                    classes={classes}
                    handleChange={handleChange}
                    state={state}
                  />
                </>
                : <Compare classes={classes} router={router} />
            }

          </>

          : (
            <LoadingImages
              classes={classes}
              handleCancelLoading={handleCancelLoading}
            />
          )
      }
    </form >
  )
}

const Selector = ({
  areas,
  classes,
  handleAreaSelect,
  handleSearchSelect,
  areaValue,
  error,
  selectAreaValue
}) => areas.length > 5
    ? <SearchSelect
      fullWidth
      margin='normal'
      data={areas}
      className={classes.SearchSelect}
      fields={{ label: 'name', value: 'id' }}
      variant='outlined'
      name='currentAreaId'
      isClearable
      placeholder={I18n.t('actions.search')}
      autocomplete={true}
      textFieldProps={{
        label: I18n.t('v4/satellite_images.area'),
        InputLabelProps: {
          shrink: true,
        }
      }}
      onChange={handleSearchSelect}
      value={areaValue}
      usePaperClass={true}
      isRequired={true}
      hasError={error}
      helperText={error}
    />
    : <Select
      className={classes.form__fieldWithError}
      InputLabelProps={{ shrink: true, required: true }}
      label={I18n.t('v4/satellite_images.area')}
      margin='normal'
      fields={{ id: 'id', name: 'name' }}
      name='currentAreaId'
      data={areas}
      fullWidth
      variant='outlined'
      onChange={(event) => handleAreaSelect(event.target)}
      value={selectAreaValue.value}
      required
      error={error}
      helperText={error}
    />

const TextFieldWithGroup = ({ classes, state, handleChange, filterGroup }) =>
  <div className={classes.SatelliteImagery_Filter}>
    <TextField
      error={!!state.errors.filterId}
      helperText={state.errors.filterId}
      select
      className={classes.SatelliteImagery_textField}
      variant='outlined'
      value={state.filterId}
      onChange={(event) => handleChange(event.target)}
      name='filterId'
      InputLabelProps={{ shrink: true }}
      label={I18n.t('v4/satellite_images.attributes.filter')}
      required
    >
      {
        Object.keys(filterGroup).map(images =>
          filterGroup[images].map((image, index) =>
            <MenuItem
              key={`image-${index}-${image.name}`}
              disabled={index === 0 || index === filterGroup[images].length - 1}
              value={image.id}
              classes={{ root: classes.SatelliteImagery_menu }}
            >
              {
                index === 0 ?
                  <div className={classes.SatelliteImagery_group}>
                    <Typography variant='h6' className={classes.SatelliteImagery_name}>{image.groupName}</Typography>
                    <Typography variant='body1' className={classes.SatelliteImagery_description}>{image.description}</Typography>
                  </div>
                  : (
                    <ListItem
                      currentIndex={index}
                      currentImage={image}
                      classes={classes}
                      filterGroup={filterGroup[images]}
                    />
                  )
              }
            </MenuItem>
          )
        )
      }
    </TextField>

    <Button
      color='secondary'
      className={classes.Satellite_Action}
      onClick={() => window.open('http://suporte.cultivointeligente.com.br/pt-BR/articles/3762793-agrosmart-view-produto-de-imagens', '_blank')}
    >
      {I18n.t('v4/satellite_images.actions.how_it_works')}
    </Button>
  </div >

const LoadingImages = ({ classes, handleCancelLoading }) =>
  <div className={classes.SatelliteImagery_Loading}>
    <Typography variant='body1' align='center' className={classes.SatelliteImagery_loading_title}>
      {I18n.t('v4/satellite_images.loading_images')}
    </Typography>

    <LoadingComponent />

    <Button
      type='submit'
      color='secondary'
      className={classes.SatelliteImagery_loading_action}
      onClick={handleCancelLoading}
    >
      {I18n.t('actions.cancel')}
    </Button>
  </div>

const Footer = ({ classes, handleChange, state }) =>
  <>
    <div className={classes.SatelliteImagery_tooltip}>
      <Typography >
        {I18n.t('v4/satellite_images.attributes.clouds')}
      </Typography>
      <Tooltip title={I18n.t('v4/satellite_images.tooltip')}>
        <HelpOutline />
      </Tooltip>
    </div>

    <div className={classes.SatelliteImagery_slider}>
      <Slider
        dots={false}
        min={0}
        max={100}
        marks={marks}
        step={null}
        onChange={(value) => handleChange({ name: 'cloudsPercentage', value })}
        handleStyle={{
          backgroundColor: '#66A343',
          border: 0
        }}
        trackStyle={{
          background: '#66A343'
        }}
        dotStyle={{
          borderColor: '#66A343'
        }}
        defaultValue={state.cloudsPercentage}
      />

      <Typography className={classes.SatelliteImagery_clouds_percentage}>
        {getPercentageCloud(state.cloudsPercentage)}
      </Typography>
    </div>

    <div className={classes.SatelliteImagery_dates}>
      <DatePicker
        onChange={value => handleChange({
          value,
          name: 'startDate'
        })}
        maxDate={state.endDate ? state.endDate : moment()}
        value={state.startDate}
        name='startDate'
        margin='dense'
        variant='outlined'
        required
        disableFuture
        InputLabelProps={{ shrink: true }}
        label={I18n.t('v4/satellite_images.start_date')}
      />

      <DatePicker
        onChange={value => handleChange({
          value,
          name: 'endDate'
        })}
        value={state.endDate}
        name='endDate'
        margin='dense'
        variant='outlined'
        required
        disableFuture
        InputLabelProps={{ shrink: true }}
        label={I18n.t('v4/satellite_images.end_date')}
      />
    </div>

    <Button
      type='submit'
      color='primary'
      variant='contained'
      className={classes.SatelliteImagery_actions}
    >
      {I18n.t('v4/satellite_images.actions.filter')}
    </Button>
  </>

const Header = ({ classes, handleClose, isComparison }) =>
  <div className={classes.SatelliteImagery_header}>
    <Typography className={classes.SatelliteImagery_title} variant='subtitle2'>
      {I18n.t('v4/products.view')}
    </Typography>

    {
      !isComparison &&
      <div className={classes.SatelliteImagery_close}>
        <IconButton
          className={classes.SatelliteImagery_iconButton}
          onClick={handleClose}>

          <Clear />
        </IconButton>
      </div>
    }

  </div>

const ListItem = ({ currentIndex, currentImage, classes, filterGroup }) =>
  currentIndex !== filterGroup.length - 1
    ? <>
      <Typography variant='body1'>
        {currentImage.name}
      </Typography>

      <Typography variant='body1' className={classes.SatelliteImagery_data}>
        {currentImage.data}
      </Typography>
    </>
    : (
      !currentImage.component &&
      <div className={classes.SatelliteImagery_divider}>
        <Divider />
      </div>
    )

const Compare = ({ classes, router }) =>
  <div className={classes.Compare_wrapper}>
    <Typography variant='body1' className={classes.Compare_text}>
      {I18n.t('v4/satellite_images.tabs.compare_text')}
    </Typography>

    <Button
      onClick={() => router.visit('comparison')}
      color='primary'
      variant='contained'
    >
      {I18n.t('v4/satellite_images.tabs.redirect')}
    </Button>
  </div>

const customStyles = theme => ({
  ...styles(theme),

  SatelliteImagery_Container: {
    maxHeight: 'calc(100vh - 80px)',
    overflowY: 'auto',
    maxWidth: '350px',
    padding: theme.spacing.unit * 2,
    ...theme.scrollbar
  },
  SatelliteImagery_header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginBottom: 10
  },
  SatelliteImagery_title: {
    flex: 3
  },
  SatelliteImagery_close: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  SatelliteImagery_iconButton: {
    padding: 0
  },
  SatelliteImagery_textField: {
    width: '100%'
  },
  SatelliteImagery_Filter: {
    display: 'flex',
    paddingTop: theme.spacing.unit * 2
  },
  Satellite_Action: {
    marginLeft: theme.spacing.unit + 2
  },
  SatelliteImagery_clouds_percentage: {
    display: 'flex',
    justifyContent: 'center',
    padding: `${theme.spacing.unit}px 0`,
  },
  SatelliteImagery_dates: {
    display: 'flex',
    flexDirection: 'column'
  },
  SatelliteImagery_actions: {
    width: '100%',
    marginTop: theme.spacing.unit
  },
  SatelliteImagery_tooltip: {
    display: 'flex',
    alignItems: 'end',
    marginTop: theme.spacing.unit * 2
  },
  SatelliteImagery_data: {
    fontSize: '11px',
    color: theme.palette.forecast.gray
  },
  SatelliteImagery_menu: {
    display: 'block',
    height: 'auto',
    opacity: 1,
    '&:focus': {
      backgroundColor: 'transparent'
    }
  },
  SatelliteImagery_group: {
    display: 'flex',
    alignItems: 'center'
  },
  SatelliteImagery_name: {
    fontSize: '15px'
  },
  SatelliteImagery_description: {
    paddingLeft: theme.spacing.unit,
    fontSize: '11px',
    color: theme.palette.forecast.gray
  },
  SatelliteImagery_slider: {
    display: 'flex',
    flexDirection: 'column',
    margin: `${theme.spacing.unit}px ${theme.spacing.unit}px ${theme.spacing.unit * 2}px ${theme.spacing.unit - 3}px`
  },
  SatelliteImagery_Loading: {
    width: '300px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  SatelliteImagery_loading_title: {
    color: theme.palette.primary.main,
    fontSize: '18px'
  },
  SatelliteImagery_loading_action: {
    border: `1px solid ${theme.palette.primary.main}`
  },
  SatelliteImagery_divider: {
    paddingTop: theme.spacing.unit
  },
  SearchSelect: {
    marginTop: theme.spacing.unit * 3
  },
  Compare_wrapper: {
    textAlign: 'center'
  },
  Compare_text: {
    margin: `${theme.spacing.unit}px 0 ${theme.spacing.unit * 2}px 0`
  }
})

FilterForm.defaultProps = {
  names: {},
  areas: [],
  filterGroup: [],
  isComparison: false
}

export default withStyles(customStyles)(FilterForm)
