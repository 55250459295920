import moment from 'moment'

import ptLocale from "date-fns/locale/pt-BR"
import enLocale from "date-fns/locale/en-US"
import esLocale from "date-fns/locale/es"

export const convertToLocaleDate = (value, options = {}) => new Date(moment(value).format('YYYY/MM/DD')).toLocaleDateString(I18n.locale, options)

export const parseDecimalToTime = (value) => {
  if (value != null) {
    let minute = ((value % 1) * 60)
    if (!minute) {
      return (`${Math.floor(value) % 24}h`)
    } else {
      return (`${Math.floor(value) % 24}h ${minute.toFixed()}min`)
    }
  } else return null
}

export const getDateWithHours = (dateTime = {}) => {
  const infoTime = new Date(dateTime).toLocaleTimeString(I18n.locale)
  const infoDate = new Date(dateTime).toLocaleDateString(I18n.locale)
  const currenteDate = new Date().toLocaleDateString(I18n.locale)

  const date = new Date()
  const yesterdayDate = new Date(date.getFullYear(), date.getMonth(), date.getDate() - 1).toLocaleDateString()

  if (infoDate === currenteDate) {
    return {
      infoDate: I18n.t('date_and_time.today'),
      infoHour: infoTime
    }
  } else {
    if (infoDate === yesterdayDate) {
      return {
        infoDate: I18n.t('date_and_time.yesterday'),
        infoHour: infoTime
      }
    } else {
      return {
        infoDate: infoDate,
        infoHour: infoTime
      }
    }
  }
}

export const getRemainingDays = (date) => {
  const currentDate = moment(new Date());
  const expirationDate = moment(date);
  const differenceDates = moment.duration(expirationDate.diff(currentDate))

  const days = parseInt(differenceDates.asDays())

  return (
    days >= 0
      ? (days === 0 ? days + 1 : days)
      : days
  )
}

export const localeFormats = {
  date: {
    'pt-BR': 'dd/MM/yyyy',
    'en-US': 'MM/dd/yyyy',
    'en': 'MM/dd/yyyy',
    'es-MX': 'dd/MM/yyyy'
  },
  dateTime: {
    'pt-BR': 'dd/MM/yyyy HH:mm',
    'en-US': 'MM/dd/yyyy hh:mm a',
    'en': 'MM/dd/yyyy hh:mm a',
    'es-MX': 'dd/MM/yyyy HH:mm'
  },
  time: {
    'pt-BR': 'HH:mm',
    'en-US': 'hh:mm a',
    'en': 'hh:mm a',
    'es-MX': 'HH:mm'
  },
  mask: {
    'pt-BR': [/\d/, /\d/, ":", /\d/, /\d/],
    'en-US': [/\d/, /\d/, ":", /\d/, /\d/, " ", /a|p|A|P/i, "M"],
    'en': [/\d/, /\d/, ":", /\d/, /\d/, " ", /a|p|A|P/i, "M"],
    'es-MX': [/\d/, /\d/, ":", /\d/, /\d/]
  }
}

export const localeMap = {
  'pt-BR': ptLocale,
  'en-US': enLocale,
  'en': enLocale,
  'es-MX': esLocale
}
