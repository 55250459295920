import { isValidLatitude, isValidLongitude } from './equipment'

export const validate = ({ equipment }) => {
  const fields = [
    'name',
    'latitude',
    'longitude'
  ]
  const errors = {}

  fields.forEach(field => {
    if (!equipment[field]) errors[field] = I18n.t('validations.required')
  })

  if (equipment.latitude && !isValidLatitude(equipment.latitude)) {
    errors.latitude = I18n.t('validations.field_between', { min: -90, max: 90 })
  }

  if (equipment.longitude && !isValidLongitude(equipment.longitude)) {
    errors.longitude = I18n.t('validations.field_between', { min: -180, max: 180 })
  }

  return { equipment: errors }
}

export const manualHeaders = [
  'name',
  'sensor_config_name',
  'equipment_model',
  'brand',
  'latitude',
  'longitude'
]

const manualTypes = [
  'pluviometer',
  'tensiometer'
]

export const getManualTypes = () =>
  manualTypes.map(manual => ({
    id: manual,
    name: I18n.t(`v4/equipment.enums.types.${manual}`)
  }))

export const getPrecipitation = (today, precipitationValue) =>
  !!today && !!precipitationValue
    ? `${precipitationValue} mm`
    : I18n.t('v4/equipment.pluviometer.no_rain_registered')

const fields = [
  'date',
  'precipitation'
]

export const getManualDashboardHeaders = () =>
  fields.map(field => ({
    title: I18n.t(`v4/equipment.pluviometer.${field}`),
    field
  }))

export const deserialize = (measures = []) => measures.map(measure => ({
  ...measure,
  precipitation: measure.pl1,
  date: new Date(measure.measured_at).toLocaleDateString(I18n.locale, { timeZone: 'UTC' })
}))
