import React from 'react'
import PropTypes from 'prop-types'

import { RailsForm } from '@support/RailsForm'
import { Form as FinalForm } from 'react-final-form'
import { validate, getManualTypes } from '@models/equipment/manual'
import httpService from '@services/httpService'
import EquipmentForm from '../Form'
import Form from './Form'

const Edit = props => {
  const { model, o, sensor_configs, handleError} = props
  
  const handleSubmit = async (values) => {
    const sensor = sensor_configs.find(sensor => sensor.id === values.equipment.sensor_config_id)
    values.equipment['sensors'] =  sensor.sensors.map(sensor => sensor.id)  
    
    try {
      await httpService.put(`/v4/o/${o}/equipments/${model.kind}/${model.id}`, values)  
      Turbolinks.visit(`/v4/o/${o}/equipments/${model.kind}/${model.id}`)
    }
    catch(error){
      handleError(error)
    }
  }
  
  return <RailsForm
    component={FinalForm}
    action='update'
    validate={validate}
    initialValues={{ equipment: model }}
    handleSubmit={handleSubmit}
    render={props => (
      <EquipmentForm
        title={`${I18n.t('actions.update')} ${I18n.t(`v4/equipment.kinds.${model.kind}`)}`}
      >
        <Form
          {...props}
          sensor_configs={sensor_configs}
          selectValues={{
            manualTypes: getManualTypes()
          }}
          action={I18n.t('actions.update')}
          o={o}
        />
      </EquipmentForm>
    )}
  />
}

Edit.propTypes = {
  model: PropTypes.object
}

export default Edit
