import React from 'react'

import { MTableCell } from 'material-table'

import ArrayCell from './ArrayCell'
import ObjectCell from './ObjectCell'

const ColumnCellStyle = { padding: '4px 16px 4px 24px' }

const Cell = ({ variablesFields, ...props }) => {
  const columnDefinition = props.columnDef
  let data = props.value

  columnDefinition.cellStyle = ColumnCellStyle

  if (Array.isArray(data)) {
    data = <ArrayCell data={data} />
  } else if (typeof data === 'object') {
    data = <ObjectCell data={data} variablesFields={variablesFields} {...props}/>
  }

  return <MTableCell {...props} value={data} />
}

export default Cell
