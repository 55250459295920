import React from 'react'
import TextField from '@ui/Fields/TextField'
import { Button, Grid } from '@material-ui/core'
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

const Form = ({ variables, handleSubmit, classes, onCancel }) => {
  const [state, setState] = React.useState(variables.params)

  function isNumeral(type) {
    if (type === 'number' ||
      type === 'Integer' ||
      type === 'Decimal')
      return true
    else
      return false
  }

  function selectStep(step) {
    if (step === 'Integer')
      return '1'
    else if (step === 'Decimal')
      return '0.01'
    else
      return null
  }

  return <form onSubmit={e => {
    e.preventDefault()
    e.stopPropagation()

    handleSubmit(state)
  }}
  >
    <Grid container spacing={24}>
      {
        Object.keys(variables.params).map((element, index) =>
          <Grid key={element.param} item xs={6}>
            {
              variables.params[index].type !== 'Boolean' ?
                <TextField
                  label={variables.params[index].name}
                  name={element.param}
                  value={state[index].value}
                  type={isNumeral(variables.params[index].type) ? 'number' : 'text'}
                  inputProps={{ step: selectStep(variables.params[index].type) }}
                  variant='outlined'
                  margin='normal'
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  required
                  onChange={({ target }) => {
                    setState({
                      ...state,
                      [index]: {
                        ...state[index],
                        value: target.value
                      },
                    })
                  }
                  }
                />
                :
                <FormControlLabel
                  control={
                    <Checkbox
                      key={index}
                      checked={state[index].value}
                      onChange={({ target }) => {
                        setState({
                          ...state,
                          [index]: {
                            ...state[index],
                            value: !state[index].value
                          },
                        })
                      }}
                      color="primary"
                    />
                  }
                  label={variables.params[index].name}
                />
            }
          </Grid>
        )
      }
    </Grid>

    <div
      className={classes.variables__actions}
    >
      <Button
        color='secondary'
        onClick={onCancel}
        className={classes.variables__action}
      >
        {I18n.t('actions.cancel')}
      </Button>

      <Button
        color='primary'
        variant='contained'
        className={classes.variables__action}
        type='submit'
      >
        {I18n.t('actions.update')}
      </Button>
    </div>
  </form>
}

Form.defaultProps = {
  variables: {}
}

export default Form
