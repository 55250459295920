import React from 'react'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import { Field } from 'react-final-form'

import TextField from '../ui/Fields/TextField'
import Select from '../ui/Fields/Select'
import styles from '@theme/styles/forms'
import SearchSelect from '../ui/Fields/SearchSelect'

const Form = ({
  handleSubmit,
  classes,
  title,
  organizationKinds,
  predominantCultures,
  timezoneList,
  o
}) => (
    <form className={classes.form} onSubmit={handleSubmit} data-testid="organization-form">
      <Paper className={classes.form__wrapper} elevation={0}>
        <header className={classes.form__header}>
          <Typography component='h2' variant='h5'>
            {title}
          </Typography>
        </header>
        <main className={classes.form__fields}>
          <div className={classes.form__field__wrapper}>
            <Field
              component={TextField}
              className={classes.form__fieldWithError}
              margin='normal'
              fullWidth
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              label={I18n.t('activerecord.attributes.v4/organization.name')}
              name='name'
              required
            />
          </div>
          <div className={classes.form__field__wrapper}>
            <Field
              component={Select}
              className={classes.form__fieldWithError}
              InputLabelProps={{ shrink: true, required: true }}
              label={I18n.t('activerecord.attributes.v4/organization.kind_text')}
              name='kind'
              margin='normal'
              fields={{ id: 'id', name: 'name' }}
              data={organizationKinds}
              fullWidth
              variant="outlined"
              required
            >
            </Field>
          </div>
          <div className={classes.form__field__wrapper}>
            <Field
              component={Select}
              className={classes.form__fieldWithError}
              InputLabelProps={{ shrink: true, required: true }}
              label={I18n.t('activerecord.attributes.v4/organization.predominant_culture')}
              name='predominant_culture'
              margin='normal'
              fields={{ id: 'id', name: 'name' }}
              data={predominantCultures}
              fullWidth
              variant="outlined"
              required
            >
            </Field>
          </div>
          {
            timezoneList &&
            <div className={classes.form__field__wrapper}>
              <Field
                component={SearchSelect}
                fullWidth
                dataTestid='timezone'
                margin='normal'
                data={timezoneList}
                className={classes.form__fieldWithError}
                fields={{ label: 'name', value: 'name' }}
                variant='outlined'
                name='timezone'
                isClearable
                placeholder={I18n.t('actions.search')}
                autocomplete={true}
                textFieldProps={{
                  label: I18n.t('activerecord.attributes.v4/organization.timezone'),
                  InputLabelProps: {
                    shrink: true,
                  }
                }}
              >
              </Field>
            </div>
          }
        </main>
        <footer className={classes.form__actions}>
          <Button
            className={classes.form__action}
            color='primary'
            type='submit'
            variant='contained'
          >
            {title}
          </Button>

          <Button
            className={classes.form__action}
            color='secondary'
            href={`/v4/o/${o}/account/edit#organizations`}
          >
            {I18n.t('actions.back')}
          </Button>
        </footer>
      </Paper>
    </form>
  )

export default withStyles(styles)(Form)
