import React from 'react'

import Button from '@material-ui/core/Button'
import Fab from '@material-ui/core/Fab'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Divider from '@material-ui/core/Divider'
import Typography from '@material-ui/core/Typography'
import PicIcon from '@material-ui/icons/CameraAlt'
import WorldIcon from '@material-ui/icons/Public'
import FlowerIcon from '@material-ui/icons/LocalFlorist'
import StageIcon from '@material-ui/icons/Spa'
import AlertIcon from '@material-ui/icons/NotificationImportant'
import EditIcon from '@material-ui/icons/Edit'

import withStyles from '@material-ui/core/styles/withStyles'
import styles from './styles'

class AreaInfo extends React.Component {
  hasImageSource = (imgSrc) => (
    imgSrc
      ? imgSrc.length > 0
      : false
  )

  renderNotifications = (notifications) => (
    notifications
      ? notifications.map(notification =>
        <ListItem className={classes.middle} key={notification.id}>
          <AlertIcon color="error" />
          <Typography className={classes.item}>
            {notification.val}
          </Typography>
        </ListItem>
      )
      : null
  )

  render() {
    const {
      areaGroup,
      area,
      classes,
      culture,
      demand,
      imgSrc,
      notifications,
      stage,
      o
    } = this.props

    const { hasImageSource, renderNotifications } = this

    return (
      <div className={classes.menu__wrapper__list}>
        <List className={classes.menu__info__list}>
          <ListItem className={classes.menu__info__header}>
            <Typography className={classes.menu__info__header__name}>
              {area.name}
            </Typography>
          </ListItem>
          <ListItem
            className={
              hasImageSource(imgSrc)
              ? classes.menu__list__item
              : classes.menu__empty__img
            }
          >
            {
              hasImageSource(imgSrc)
              ? <img src={imgSrc} className={classes.menu__img} />
              : <PicIcon className={classes.menu__pic__icon} />
            }
          </ListItem>

          <ListItem>
            {
              areaGroup &&
              <Typography className={classes.menu__info}>
                {areaGroup}
              </Typography>
            }

            {
              false &&
              <div className={classes.menu__edit__button} onClick={() => location.href = '#'}>
                <Fab aria-label="Edit" className={classes.menu__fab__edit}>
                  <EditIcon color="primary" />
                </Fab>
                <Typography className={classes.menu__edit}>Editar</Typography>
              </div>
            }

          </ListItem>

          {
            area.size_ha &&
            <ListItem className={classes.menu__body}>
              <WorldIcon color="primary" />
              <Typography className={classes.menu__item}>
                {I18n.t('v4/area.show.area_size', { area: area.size_ha ? area.size_ha : "-" })}
              </Typography>
            </ListItem>
          }

          {
            culture &&
            <ListItem className={classes.menu__body}>
              <FlowerIcon color="primary" />
              <Typography className={classes.menu__item}>
                {culture.length <= 0 ? "Esta área não está sendo cultivada" : `O cultivo atual é de ${culture}`}
              </Typography>
            </ListItem>
          }

          {
            stage &&
            <ListItem className={classes.menu__body}>
              <FlowerIcon color="primary" />
              <Typography className={classes.menu__item}>
                {stage.length <= 0 ? "Esta área não está sendo cultivada" : `A fase fenológica é a de ${stage}`}
              </Typography>
            </ListItem>
          }

          <Divider className={classes.menu__divider} />

          <ListItem className={classes.menu__actions}>
            <Button
              color="primary"
              variant="contained"
              onClick={() => Turbolinks.visit(`/v4/o/${o}/areas/${area.id}/dashboard`)}
              data-testid="btnSeeArea"
            >
              {I18n.t('actions.view_more')}
            </Button>

            <Button
              variant="outlined"
              color="secondary"
              onClick={() => Turbolinks.visit(`/v4/o/${o}/areas/${area.id}/edit`)}
              data-testid="btnManageArea"
            >
              {`${I18n.t('actions.manage')} ${I18n.t('activerecord.models.v4/area.one')}`}
            </Button>
          </ListItem>

          { renderNotifications(notifications) }
        </List>
      </div>
    )
  }
}

export default withStyles(styles)(AreaInfo)
