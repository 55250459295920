import React from 'react'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from '@styles/showPage'
import { fields } from '@models/admin/product'

export const Show = ({ classes, model, o }) =>
  <Paper
    className={classes.showPage__wrapper}
    elevation={0}
  >
    <header className={classes.showPage__header}>
      <Typography component='h2' variant='h5'>
        {I18n.t('v4/product.show.title')}
      </Typography>
    </header>

    <main className={classes.showPage__values}>
      {
        fields.map(attribute =>
          model[attribute] && attribute !== 'params' &&
          <div key={attribute} className={classes.showPage__value}>
            <Typography
              color='textSecondary'
              component='h6'
              gutterBottom
              variant='h6'
            >
              {I18n.t(`activerecord.attributes.v4/product.${attribute}`)}
            </Typography>

            <Typography paragraph variant='subtitle1' data-testid={attribute}>
              {model[attribute]}
            </Typography>
          </div>
        )
      }

      {
        model.icon_url &&
        <div className={classes.showPage__value}>
          <Typography
            color='textSecondary'
            component='h6'
            gutterBottom
            variant='h6'
          >
            {I18n.t('activerecord.attributes.v4/product.icon')}
          </Typography>

          <Typography paragraph variant='subtitle1' >
            <img
              src={model.icon_url}
              width='80%'
              alt={I18n.t('activerecord.attributes.v4/product.icon')}
            />
          </Typography>
        </div>
      }
    </main>

    <footer className={classes.showPage__actions}>
      <Button
        className={classes.showPage__action}
        color='primary'
        variant='contained'
        href={`/v4/o/${o}/admin/products/${model.id}/edit`}
      >
        {I18n.t('v4/product.actions.edit')}
      </Button>

      <Button
        className={classes.showPage__action}
        color='secondary'
        href={`/v4/o/${o}/admin/products`}
      >
        {I18n.t('actions.back')}
      </Button>
    </footer>
  </Paper>

Show.propTypes = {
  model: PropTypes.object
}

const pageWithStyles = withStyles(styles)(Show)

export default pageWithStyles
