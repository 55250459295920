import React from 'react'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from '@styles/showPage'
import { fields, deserialize } from '@models/equipment/equipment'

export const Show = ({ classes, model, o }) => {
  const equipment = deserialize(model)

  return (
    <Paper
      className={classes.showPage__wrapper}
      elevation={0}
    >
      <header className={classes.showPage__header}>
        <Typography component="h2" variant="h5">
          {
            I18n.t('v4/equipment.show.title',
              { title: I18n.t(`v4/equipment.kinds.${equipment.kind}`) }
            )
          }
        </Typography>
      </header>

      <main className={classes.showPage__values}>
        {
          fields.map(attribute =>
            equipment[attribute] &&
            <div key={attribute} className={classes.showPage__value}>
              <Typography
                color="textSecondary"
                component="h6"
                gutterBottom
                variant="h6"
              >
                {I18n.t(`v4/equipment.attributes.${attribute}`)}
              </Typography>

              <Typography paragraph variant="subtitle1" data-testid={attribute}>
                {equipment[attribute] || ''}
              </Typography>
            </div>
          )
        }
      </main>

      <footer className={classes.showPage__actions}>
        <Button
          className={classes.showPage__action}
          color="primary"
          variant="contained"
          href={`/v4/o/${o}/equipments/${equipment.kind}/${equipment.id}/edit`}
        >
          {`${I18n.t('actions.update')} ${I18n.t(`v4/equipment.kinds.${equipment.kind}`)}`}
        </Button>

        <Button
          className={classes.showPage__action}
          color='secondary'
          href={`/v4/o/${o}/equipments/${equipment.kind}`}
        >
          {I18n.t('actions.back')}
        </Button>
      </footer>
    </Paper>
  )
}

Show.propTypes = {
  model: PropTypes.object
}

const pageWithStyles = withStyles(styles)(Show)

export default pageWithStyles
