import React, { Component } from 'react'

import {
  Grid,
  Button,
} from '@material-ui/core'

import LoadingComponent from '@ui/LoadingComponent'
import Card from './Card'
import Header from './Header'

import { withSessionData } from '@contexts/SessionDataProvider'
import { withHttpService } from '@contexts/HttpServiceProvider'
import { withRouter } from '@contexts/RouterProvider'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from '@styles/indexPage'

import placeHolderArea from '@images/field_notebook/place_holder_area.png'
import placeHolderCultura from '@images/field_notebook/place_holder_cultura.png'
import placeHolderSafra from '@images/field_notebook/place_holder_safra.png'

import AreaIcon from '@images/field_notebook/areas.svg'
import CulturaIcon from '@images/field_notebook/cultura.svg'
import SeasonIcon from '@images/field_notebook/season.svg'

const cards = [
  {
    title: I18n.t('v4/field_notebook.area.title'),
    description: I18n.t('v4/field_notebook.area.description'),
    action: I18n.t('v4/field_notebook.area.action'),
    url: '/maps',
    placeHolder: placeHolderArea,
    icon: AreaIcon,
    tracking:'setupscreen_click_areabutton'
  },
  {
    title: I18n.t('v4/field_notebook.culture.title'),
    description: I18n.t('v4/field_notebook.culture.description'),
    action: I18n.t('v4/field_notebook.culture.action'),
    url: '/cultures/new',
    placeHolder: placeHolderCultura,
    icon: CulturaIcon,
    tracking:'setupscreen_click_culturebutton'
  },
  {
    title: I18n.t('v4/field_notebook.season.title'),
    description: I18n.t('v4/field_notebook.season.description'),
    action: I18n.t('v4/field_notebook.season.action'),
    url: '/seasons/new',
    placeHolder: placeHolderSafra,
    icon: SeasonIcon,
    tracking:'setupscreen_click_sessionbutton'
  }
]

export class Index extends Component {
  constructor(props) {
    super(props)

    this.state = {
      has_created_area: false,
      has_created_culture: false,
      has_created_season: false,
      loading: false,
      is_pre_requisites_done: null
    }
  }
  async componentDidMount() {
    this.setState({ loading: true })
    try {
      const { router, sessionData: { currentOrganization }, httpService: { apiRequest } } = this.props
      const params = { organization_id: currentOrganization.id }
      const response = await apiRequest('/check_records', { params })

      if (
        response.data.has_created_culture
        && response.data.has_created_season
        && currentOrganization.externalId) {
        const spaUrl = process.env.REACT_APP_SPA_URL
        this.setState({ is_pre_requisites_done: true })
        Turbolinks.visit(`${spaUrl}/field-notebook/`)
      } else {
        this.setState({ is_pre_requisites_done: false })
      }

      this.setState({
        has_created_area: response.data.has_created_area,
        has_created_culture: response.data.has_created_culture,
        has_created_season: response.data.has_created_season
      })
    } catch (e) {
      console.error(e)
    }
    this.setState({ loading: false })
  }

  handleBackButton = () => {
    history.back();
  }

  handleCardStatus = (index) => {
    const {
      has_created_area,
      has_created_culture,
      has_created_season
    } = this.state

    if (index === 0) {
      return (has_created_area)
    }
    if (index === 1) {
      return (has_created_culture)
    }
    if (index === 2) {
      return (has_created_season)
    }
  }

  render() {
    const { classes } = this.props
    const { is_pre_requisites_done, isLoading } = this.state


    const NeedFill = () => (
      <>
        <Grid
          container
          justify='space-around'
          alignItems='center'
          style={{
            width: '100%',
            height: '70%',
          }}
        >
          {
            cards.map((card, index) => (
              <Grid item key={index}>
                <Card
                  data={card}
                  data-intercom-target={card.tracking}
                  buttonStatus={this.handleCardStatus(index)}
                />
              </Grid>
            ))
          }
        </Grid>
      </>
    );

    return (
      <div
        className={classes.Field_notebook_container}
      >
        <Header 
          isFilled={is_pre_requisites_done}
        />
        {isLoading ? <LoadingComponent /> : <NeedFill />}
        
      </div>
    )
  }
}

export default withHttpService(withRouter(withSessionData(withStyles(styles)(Index))))
