import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import Table from '@ui/Table'
import { MTableCell } from 'material-table'

import DeleteDialog from '@ui/DeleteDialog'
import styles from './style'

class Index extends Component {
  constructor(props) {
    super(props)

    this.state = {
      model: [],
      isDialogOpen: false,
      id: null
    }
  }

  closeDialog = () => {
    this.setState({ isDialogOpen: false })
  }

  componentDidMount() {
    const { model } = this.props

    this.setState({
      model: model.map(conf => {
        conf.tags = []
        conf.usage = I18n.t(`v4/equipment.attributes.${conf.usage}`)

        if (conf.metadata && conf.metadata.tags) {
          conf.tags = conf.metadata.tags
        }

        return conf
      })
    })
  }

  render() {
    const { classes, o } = this.props
    const { model, isDialogOpen, id } = this.state

    return (
      <Paper className={classes.page__wrapper} elevation={0}>
        <header className={classes.page__header}>
          <Typography component='h2' variant='h5'>
            {I18n.t('activerecord.models.v4/configurations.other')}
          </Typography>
        </header>
        <Button
          variant='outlined'
          color='primary'
          className={classes.page__newResourceButton}
          href={`/v4/o/${o}/admin/sensor_configs/new`}
        >
          {I18n.t('actions.create')} {I18n.t('activerecord.models.v4/configuration.one')}
        </Button>

        <Table
          tableKey='admin-sensorConfigs-index'
          components={{
            Container: props => <div>{props.children}</div>,
            Cell: props =>
              <MTableCell
                {...props}
                value={!Array.isArray(props.value)
                  ? props.value
                  : props.value.map((val, index) =>
                    <span key={`${val}-${index}`} className={classes.Dashboard__tag__cells}>
                      {val}
                    </span>
                  )
                }
              />
          }}
          columns={[
            { field: 'name', title: I18n.t('activerecord.attributes.v4/configuration.name') },
            { field: 'tags', title: 'Tags' },
            { field: 'usage', title: I18n.t('activerecord.models.v4/equipment.one') }
          ]}
          data={model}
          actions={[
            rowData => ({
              icon: 'create',
              iconProps: { 'data-testid': `edit-${rowData.id}` },
              tooltip: I18n.t('actions.edit'),
              onClick: (event, rowData) => {
                Turbolinks.visit(`/v4/o/${o}/admin/sensor_configs/${rowData.id}/edit`)
              }
            }),
            rowData => ({
              icon: 'delete',
              iconProps: { 'data-testid': `delete-${rowData.id}` },
              tooltip: I18n.t('actions.remove'),
              onClick: (event, rowData) => {
                this.setState({ isDialogOpen: true, id: rowData.id })
              }
            })
          ]}
          options={{
            showTitle: false
          }}
        />

        <DeleteDialog
          dialogTitle={`${I18n.t('actions.remove')} ${I18n.t('activerecord.models.v4/configuration.one')}?`}
          dialogText={I18n.t('confirmation.remove')}
          successUrl={`/v4/o/${o}/admin/sensor_configs`}
          deleteUrl={`/v4/o/${o}/admin/sensor_configs/${id}.json`}
          onCancel={this.closeDialog}
          onClose={this.closeDialog}
          open={isDialogOpen}
        />
      </Paper>
    )
  }
}

Index.propTypes = {
  model: PropTypes.array
}

export default withStyles(styles)(Index)
