import React from 'react'
import InputMask from 'react-input-mask'
import TextField from '@material-ui/core/TextField'

const ControlledTextFieldWithMask = ({
  input: { onFocus, onBlur, ...inputRest },
  mask,
  meta: { error },
  ...textFieldProps
}) =>
  <InputMask
    mask={mask}
    value={inputRest.value}
    onChange={inputRest.onChange}
  >
    {
      () =>
        <TextField
          error={!!error}
          helperText={error}
          {...inputRest}
          {...textFieldProps}
        />
    }
  </InputMask>

ControlledTextFieldWithMask.defaultProps = {
  meta: {}
}

export default ControlledTextFieldWithMask
