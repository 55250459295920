import React from 'react'
import { Form as FinalForm } from 'react-final-form'

import { RailsForm } from '@support/RailsForm'
import { validate } from '@models/privacyPolicy'
import { withRouter } from '@contexts/RouterProvider'
import { withSessionData } from '@contexts/SessionDataProvider'
import { withHttpService } from '@contexts/HttpServiceProvider'
import { withErrorHandler } from '@contexts/ErrorHandlerProvider'

import Form from './Form'

class Edit extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      initialValues: {}
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  handleSubmit = async (data) => {
    const {
      router,
      errorHandler,
      httpService: { apiRequest }
    } = this.props

    try {
      await apiRequest.put(`/admin/privacy_policies/${data.id}`, { privacy_policy: data })
      Turbolinks.visit(`${router.baseURL}/admin/privacy_policies`)
    }
    catch (error) {
      errorHandler(error)
    }
  }

  fetchData = async () => {
    const {
      errorHandler,
      httpService: { apiRequest },
      sessionData: { currentOrganization }
    } = this.props

    const currentPath = window.location.href
    const urlParts = currentPath.split('/privacy_policies/', 2)
    const privacyPolicyId = urlParts[1].split('/edit', 1)[0]

    const url = `/admin/privacy_policies/${privacyPolicyId}`
    const params = {
      organization_id: currentOrganization.id
    }

    try {
      const { data } = await apiRequest.get(url, { params })

      this.setState({ initialValues: data })
    }
    catch (error) {
      errorHandler(error)
    }
  }

  render() {
    const { o } = this.props

    return (
      <RailsForm
        action='update'
        component={FinalForm}
        validate={validate}
        initialValues={this.state.initialValues}
        handleSubmit={(data) => this.handleSubmit(data)}
        render={(props) => (
          <Form
            {...props}
            data={this.state.initialValues}
            title={I18n.t('v4/terms.actions.update')}
            o={o}
          />
        )}
      />
    )
  }
}

Edit.defaultProps = {
  model: {
    name: '',
    description_pt: '',
    description_en: '',
    description_es: ''
  }
}

export default withSessionData(
  withRouter(
    withHttpService(
      withErrorHandler(
        Edit
      )
    )
  )
)
