import React, { Fragment } from 'react'

import CheckIcon from '@material-ui/icons/Check'
import ErrorIcon from '@material-ui/icons/Close'
import SearchIcon from '@material-ui/icons/Search'

import { Button, Typography, InputAdornment, IconButton, CircularProgress } from '@material-ui/core'
import TextField from '@ui/Fields/TextField'
import { fetchCoupon } from '@models/coupon'
import { withStyles } from '@material-ui/core/styles'

const TextFieldIcon = ({ status, fetching }) =>
  status.error
    ? <ErrorIcon style={{ color: 'red' }} />
    : status.success
      ? <CheckIcon style={{ color: 'green' }} />
      : null

const CouponForm = ({ classes, setCoupon, products, plan }) => {
  const [value, setValue] = React.useState('')
  const [fetching, setFetching] = React.useState(false)
  const [status, setStatus] = React.useState({
    error: null,
    success: false
  })

  const handleFetchCoupon = async () => {
    if (value) {
      setFetching(true)

      try {
        const data = await fetchCoupon({ coupon: value, products, plan })

        setCoupon(data)

        setStatus({
          ...status,
          success: true
        })
      }
      catch {
        setStatus({
          ...status,
          error: I18n.t('v4/payment_checkout.coupon_form.invalid_coupon')
        })
      }

      setFetching(false)
    }
  }

  const handleChange = ({ target }) => {
    if (status.error) setStatus({ ...status, error: null })
    if (status.success) setStatus({ ...status, success: null })

    setValue(target.value)
  }

  return <Fragment>
    <Typography variant='h6'>
      {I18n.t('v4/payment_checkout.coupon_form.you_have_coupon')}
    </Typography>

    <TextField
      className={classes.Coupon__field}
      disabled={fetching}
      value={value}
      onChange={handleChange}
      fullWidth
      InputLabelProps={{ shrink: true }}
      placeholder={I18n.t('v4/payment_checkout.coupon_form.type_here')}
      meta={{ error: status.error, touched: true }}
      name='coupon'
      InputProps={{
        endAdornment:
          fetching
            ? <CircularProgress size={25} />
            : <TextFieldIcon
              fetching={fetching}
              status={status}
            />
      }}
    />

    {
      status.success &&
      <Typography className={classes.Coupon__success_applied}>
        {I18n.t('v4/payment_checkout.coupon_form.successful_applied')}
      </Typography>
    }

    <Button
      disabled={fetching}
      onClick={handleFetchCoupon}
      color='primary'
      title={I18n.t('v4/payment_checkout.coupon_form.apply')}
    >
      {I18n.t('v4/payment_checkout.coupon_form.apply_discount')}
    </Button>
  </Fragment>
}

const styles = theme => ({
  Coupon__field: {
    margin: `${theme.spacing.unit}px 0 ${theme.spacing.unit}px`
  },
  Coupon__success_applied: {
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  }
})

CouponForm.defaultProps = {
  setCoupon: () => { }
}

export default withStyles(styles)(CouponForm)
