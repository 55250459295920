import React from 'react'
import TextField from '@material-ui/core/TextField'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import { InputAdornment, Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const ControlledTextField = ({
  classes,
  input,
  info,
  meta: { touched, error },
  InputProps,
  ...textFieldProps
}) =>
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    {...input}
    {...textFieldProps}
    InputProps={{
      className: classes.withInfo,
      ...InputProps,
      endAdornment: InputProps.endAdornment
        ? InputProps.endAdornment
        : info
          ? <Tooltip title={info} placement='top'>
            <InputAdornment position='end'>
              <InfoIcon />
            </InputAdornment>
          </Tooltip>
          : null
    }}
  />

ControlledTextField.defaultProps = {
  meta: {},
  InputProps: {}
}

const styles = () => ({
  withInfo: {
    boxSizing: 'border-box'
  }
})

export default withStyles(styles)(ControlledTextField)
