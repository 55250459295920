import React from 'react'

import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  TextField,
  Typography
} from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'

const LatLongForm = ({
  classes,
  isValidLat,
  isValidLng,
  latValue,
  lngValue,
  onLatLngChange,
  validator
}) =>
  <div className={classes.Selector_wrapper}>
    <Typography variant="body1" className={classes.Selector_text} >
      {I18n.t('v4/forecast.configuration.insert_coordinates')}
    </Typography>

    <Grid container spacing={24} className={classes.Selector_inputs}>
      <Grid item xs={6}>
        <FormControl className={classes.formControl} error>
          <TextField
            InputLabelProps={{ shrink: true }}
            error={!isValidLat}
            value={latValue}
            onChange={onLatLngChange('latValue')}
            variant='outlined'
            label={I18n.t('units.latitude')}
            helperText={!isValidLat ? I18n.t('validations.invalid_latitude') : null}
          />
        </FormControl>
      </Grid>

      <Grid item xs={6}>
        <FormControl className={classes.formControl} error>
          <TextField
            InputLabelProps={{ shrink: true }}
            error={!isValidLng}
            value={lngValue}
            onChange={onLatLngChange('lngValue')}
            variant='outlined'
            label={I18n.t('units.longitude')}
            helperText={!isValidLng ? I18n.t('validations.invalid_longitude') : null}
          />
        </FormControl>
      </Grid>
    </Grid>

    <Button
      color='primary'
      className={classes.Selector_text}
      variant='contained'
      fullWidth
      onClick={validator}
    >
      {I18n.t('actions.continue')}
    </Button>
  </div>

const styles = (theme) => ({
  Selector_wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    padding: `${theme.spacing.unit}px 0px`
  },
  Selector_inputs: {
    margin: `${theme.spacing.unit}px 0px`
  },
  Selector_text: {
    margin: `${theme.spacing.unit}px 0px`,
  }
})

export default withStyles(styles)(LatLongForm)
