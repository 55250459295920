import React from 'react'

import { Typography } from '@material-ui/core'

const Support = ({ classes }) =>
  <div className={classes.Equipment_Infos} >
    <Typography align='center' variant='body1' component='h5'>
      {I18n.t('v4/equipment.dashboard.info_support')}
    </Typography>

    <Typography align='center' component='h5'>
      {I18n.t('v4/equipment.dashboard.info_contact')}
    </Typography>
  </div>

export default Support
