import moment from 'moment'

const translateStatus = (status) =>
  I18n.t(`v4/irrigation_management_product.irrigation_status.${status}`)

export const isDateSelected = (selectedDate, date) => selectedDate === date

export const irrigationStatusLegends = [
  {
    status: 'saturated',
    color: '#0070D8',
    title: translateStatus('excess.title'),
    subtitle: translateStatus('excess.info')
  },
  {
    status: 'alert_moisture',
    color: '#FFE600',
    title: translateStatus('atention.title'),
    subtitle: translateStatus('atention.info')
  },
  {
    status: 'ideal_moisture',
    color: '#56D608',
    title: translateStatus('ideal.title'),
    subtitle: translateStatus('ideal.info')
  },
  {
    status: 'hydric_deficit',
    color: '#F03939',
    title: translateStatus('deficit.title'),
    subtitle: translateStatus('deficit.info')
  },
  {
    status: 'unavailable_data',
    color: '#767676',
    title: translateStatus('unavailable_data.title'),
    subtitle: translateStatus('unavailable_data.info')
  },
]

export const getAreasColors = (areas = [], areasStatus = []) =>
  areas.map(area => {
    if (_.isEmpty(areasStatus)) {
      return {
        ...area,
        color: null,
        hide: false
      }
    }

    const selectedAreaStatus = _.find(areasStatus, ['area_id', area.id]) || {}

    if (_.isEmpty(selectedAreaStatus)) {
      return {
        ...area,
        hide: true,
        color: null
      }
    }

    const selectedStatusLegend = _.find(irrigationStatusLegends, [
      'status',
      selectedAreaStatus.status
    ]) || {}

    const { color } = selectedStatusLegend

    return {
      ...area,
      color,
      hide: false
    }
  })

export const deserializeData = managements => {
  if (!_.isEmpty(managements)) {
    const calculatedStatus = calculateIrrigationStatus(managements)

    return calculatedStatus.reduce((reports, management) => {
      if (management.status === 'started') {
        reports = addAreaToDate({
          area_id: management.area_id,
          area_name: management.area_name,
          data: management.data,
          date: management.data.date,
          reports,
          status: management.data.status
        })

        management.forecast.map(forecast => {
          reports = addAreaToDate({
            area_id: management.area_id,
            area_name: management.area_name,
            data: forecast,
            date: forecast.date,
            reports,
            status: forecast.status
          })
        })
      }

      return reports
    }, [])
  }

  return [{}]
}

export const getManagementStatus = data => {
  return { status: data.hydric_status }
}

export const calculateGreatestDemandArea = (areas = []) => {
  if (_.isEmpty(areas)) return '--'

  return areas.reduce(
    (greatestDemandArea, area) =>
      area.demanded_irrigation > greatestDemandArea.demanded_irrigation
        ? area
        : greatestDemandArea
    , { demanded_irrigation: 0 }
  ).area_name || '--'
}

export const calculateNumberOfAreasThatNeedIrrigation = (areas = []) => ({
  areasThatNeedIrrigation: areas.reduce((total, area) => area.need_irrigation ? total + 1 : total, 0),
  totalAreas: areas.length
})

const normalizeManagementDate = (management = { data: {} }) => {
  if (!management.data.date) {
    const today = moment().format('YYYY-MM-DD')

    return {
      ...management,
      data: {
        ...management.data,
        date: today
      }
    }
  }

  return management
}

export const calculateIrrigationStatus = managements =>
  managements.map(management => {
    const normalizedManagement = normalizeManagementDate(management)

    return {
      ...normalizedManagement,
      data: {
        ...normalizedManagement.data,
        ...getManagementStatus(normalizedManagement.data),
      },
      forecast: normalizedManagement.forecast.map(forecast => ({
        ...forecast,
        ...getManagementStatus(forecast)
      }))
    }
  })

export const addAreaToDate = ({ reports, date, area_id, area_name, status, data }) => {
  const managementDate = reports.find(management => management.date === date)

  if (managementDate) {
    return reports.map(report => {
      if (report.date === date && !report.areas.some(area => area.area_id === area_id)) {
        return {
          ...report,
          areas: [
            ...report.areas,
            {
              area_id,
              area_name,
              ...data,
              status
            }
          ]
        }
      }

      return report
    })
  }

  return [
    ...reports,
    {
      date,
      areas: [{
        area_id,
        area_name,
        ...data,
        status
      }]
    }
  ]
}
