import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Button, Paper, Typography, withStyles } from '@material-ui/core'

import { withHttpService } from '@contexts/HttpServiceProvider'
import { withSessionData } from '@contexts/SessionDataProvider'
import { withRouter } from '@contexts/RouterProvider'
import { withErrorHandler } from '@contexts/ErrorHandlerProvider'
import { getPrivacyPolicyHeaders, privacyPolicyActionsHandler } from '@models/privacyPolicy'

import Dialog from '@ui/DeleteDialog'
import Table from '@ui/Table'

import styles from '@styles/indexPage'

class Index extends Component {
  state = {
    isDialogOpen: false,
    privacyPolicyId: null,
    privacyPolicies: []
  }

  fetchDataPrivacyPolicies = async () => {
    const {
      errorHandler,
      router,
      sessionData: { currentOrganization },
      httpService: { apiRequest }
    } = this.props

    const organizationId = currentOrganization.id

    const url = 'admin/privacy_policies/index'
    const params = {
      organization_id: organizationId
    }

    try {
      const { data } = await apiRequest(url, { params })

      this.setState({ privacyPolicies: data })
    }
    catch (error) {
      errorHandler(error)
    }
  }

  componentDidMount() {
    this.fetchDataPrivacyPolicies()
  }

  handleClonePrivacyPolicy = async (privacyPolicy) => {
    const {
      o,
      errorHandler,
      router,
      sessionData: { currentOrganization },
      httpService: { apiRequest }
    } = this.props

    const newPrivacyPolicy = {
      description_pt: privacyPolicy.description_pt,
      description_en: privacyPolicy.description_en,
      description_es: privacyPolicy.description_es,
      name: `${privacyPolicy.name}-${I18n.t('v4/terms.copy')}`
    }

    const organizationId = currentOrganization.id
    const url = 'admin/privacy_policies/create'

    try {
      const { data } = await apiRequest.post(url, {
        organization_id: organizationId,
        privacy_policy: newPrivacyPolicy
      })

      this.setState({
        privacyPolicies: [
          data,
          ...this.state.privacyPolicies
        ]
      })
    }
    catch (error) {
      errorHandler(error)
    }
  }

  render() {
    const {
      classes,
      handleError,
      httpService: { apiRequest },
      o
    } = this.props

    const {
      isDialogOpen,
      privacyPolicyId,
      privacyPolicies
    } = this.state

    return (
      <Paper className={classes.page__wrapper} elevation={0}>
        <header className={classes.page__header}>
          <Typography component='h2' variant='h5'>
            {I18n.t('activerecord.models.v4/privacy_policies.other')}
          </Typography>
        </header>

        <Button
          variant='outlined'
          color='primary'
          className={classes.page__newResourceButton}
          href={`/v4/o/${o}/admin/privacy_policies/new`}
        >
          {I18n.t('actions.add')} {I18n.t('activerecord.models.v4/privacy_policies.one')}
        </Button>

        <Table
          tableKey='admin-privacy-policy-index'
          components={{
            Container: props => <div>{props.children}</div>,
          }}
          columns={getPrivacyPolicyHeaders()}
          data={privacyPolicies}
          actions={[
            rowData => ({
              icon: 'create',
              iconProps: { 'data-testid': `edit-${rowData.id}` },
              tooltip: I18n.t('actions.edit'),
              onClick: (event, rowData) => {
                Turbolinks.visit(`/v4/o/${o}/admin/privacy_policies/${rowData.id}/edit`)
              }
            }),
            rowData => ({
              icon: 'file_copy',
              iconProps: { 'data-testid': `clone-${rowData.id}` },
              tooltip: I18n.t('v4/terms.actions.copy'),
              onClick: (event, rowData) => {
                this.handleClonePrivacyPolicy(rowData)
              }
            }),
            rowData => ({
              icon: rowData.active ? 'bookmark' : 'bookmark_border',
              disabled: rowData.active || rowData.deactivated_at || rowData.activated_at ? true : false,
              iconProps: { 'data-testid': `edit-${rowData.id}` },
              tooltip: rowData.active || rowData.deactivated_at || rowData.activated_at
                ? I18n.t('actions.inactive')
                : I18n.t('actions.activate'),
              onClick: (event, rowData) => { this.setState({ isDialogOpen: true, privacyPolicyId: rowData.id }) }
            })
          ]}
          options={{
            showTitle: false
          }}
        />

        <Dialog
          dialogTitle={I18n.t('v4/terms.dialog.title')}
          dialogText={I18n.t('v4/terms.dialog.text')}
          handleDelete={
            privacyPolicyActionsHandler(privacyPolicyId, apiRequest, handleError, o)
          }
          onCancel={() => this.setState({ isDialogOpen: false })}
          onClose={() => this.setState({ isDialogOpen: false })}
          open={isDialogOpen}
          actionText={I18n.t('v4/terms.dialog.title')}
        />
      </Paper>
    )
  }
}

export default withSessionData(
  withRouter(
    withHttpService(
      withErrorHandler(
        withStyles(styles)(Index)
      )
    )
  )
)
