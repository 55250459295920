import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import Table from '@ui/Table'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import DeleteDialog from '../ui/DeleteDialog'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from '@styles/indexPage'
import { inputsTableHeaders } from '@models/inputs/input'

class Inputs extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isDialogOpen: false,
      id: null
    }
  }

  render() {
    const {
      classes,
      model,
      kinds,
      kind,
      o
    } = this.props

    const selectedTab = kinds.indexOf(kinds.find(tab => tab.id === kind))

    return (
      <Fragment>
        <Paper className={classes.page__wrapper} elevation={0}>
          <Button
            variant='outlined'
            color='primary'
            href={`/v4/o/${o}/inputs/${kind}/new`}
          >
            {I18n.t('actions.add')} {I18n.t(`v4/input.kinds.${kind}`)}
          </Button>
          <header className={classes.page__header} />
          <Tabs
            className={classes.tabs}
            variant="scrollable"
            scrollButtons="on"
            value={selectedTab}
            indicatorColor="primary"
          >
            {
              kinds.map(tab =>
                <Tab
                  label={tab.name}
                  key={tab.id}
                  onClick={() => Turbolinks.visit(`/v4/o/${o}/inputs/${tab.id}`)}
                />
              )
            }
          </Tabs>

          <Table
            tableKey='inputs-index'
            key='input-table'
            components={{
              Container: props => <div>{props.children}</div>,
            }}
            columns={inputsTableHeaders[kind]}
            data={model}
            options={{
              showTitle: false
            }}
            actions={[
              rowData => ({
                icon: 'create',
                iconProps: { 'data-testid': `edit-${rowData.id}` },
                tooltip: I18n.t('actions.edit'),
                onClick: (event, rowData) => {
                  Turbolinks.visit(`/v4/o/${o}/inputs/${kind}/${rowData.id}/edit`)
                }
              }),
              rowData => ({
                icon: 'delete',
                iconProps: { 'data-testid': `delete-${rowData.id}` },
                tooltip: I18n.t('actions.remove'),
                onClick: (event, rowData) => {
                  this.setState({ isDialogOpen: true, id: rowData.id })
                }
              })
            ]}
          />
        </Paper>

        <DeleteDialog
          dialogTitle={`${I18n.t('actions.remove')} ${I18n.t(`v4/input.kinds.${kind}`)}?`}
          dialogText={I18n.t('confirmation.remove')}
          successUrl={`/v4/o/${o}/inputs/${kind}`}
          deleteUrl={`/v4/o/${o}/inputs/${kind}/${this.state.id}.json`}
          onCancel={() => this.setState({ isDialogOpen: false })}
          onClose={() => this.setState({ isDialogOpen: false })}
          open={this.state.isDialogOpen}
        />
      </Fragment>
    )
  }
}

Inputs.defaultProps = {
  kinds: [
    { name: I18n.t('v4/input.kinds.cultivar'), id: 'cultivar' },
    { name: I18n.t('v4/input.kinds.fertilizer'), id: 'fertilizer' },
    { name: I18n.t('v4/input.kinds.chemical'), id: 'chemical' },
    { name: I18n.t('v4/input.kinds.other'), id: 'other' }
  ],
  model: []
}

Inputs.propTypes = {
  model: PropTypes.array,
  kinds: PropTypes.array,
  kind: PropTypes.string
}

const pageWithStyles = withStyles(styles)(Inputs)

export default pageWithStyles
