import React, { useState } from 'react'

import { Button, Typography, Checkbox, Link, withStyles, FormHelperText } from '@material-ui/core'

import httpService from '@services/httpService'

import SimpleTextField from '@ui/SimpleFields/TextField'
import SimpleTextFieldWithIcon from '@ui/SimpleFields/TextFieldWithIcon'

import SimpleTelephoneField from '@ui/SimpleFields/Telephone'
import LoadingComponent from '@ui/LoadingComponent'

import { validateName, validPhone, validateEmail, validatePassword } from '@models/users'

import DeviseScreen from './DeviseScreen'
import styles from './styles'

import { themeProviderWrapper } from '@contexts/ThemeProvider'
import { errorHandlerProviderWrapper } from '@contexts/ErrorHandlerProvider'

const SignUp = ({
  classes,
  logo_url,
  background_image_url,
  terms_of_use,
  user_privacy_policy,
  handleError
}) => {
  const [state, setState] = useState({
    user: {
      email: '',
      password: '',
      first_name: '',
      last_name: '',
      phone: '',
      locale: I18n.locale,
      accepted_terms_and_conditions: 0,
      accepted_user_privacy_policy: 0
    },
    firstNameError: '',
    lastNameError: '',
    emailStatusError: '',
    emailError: '',
    phoneError: '',
    passwordError: ''
  })

  const [loading, setLoading] = useState(false)

  const handleChange = ({ target }) => {
    setState({
      ...state,
      user: {
        ...state.user,
        [target.name]: target.value
      }
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    let values = _.cloneDeep(state.user)

    if (!terms_of_use)
      values['accepted_terms_and_conditions'] = 1

    if (!user_privacy_policy)
      values['accepted_user_privacy_policy'] = 1

    if (validForm(values)) {

      setLoading(true)
      try {
        const response = await httpService.post('/sign_up', { user: values })

        handleError(response.data.message, { variant: 'success' })
        setTimeout(() => location.replace('/v4/organizations/new'), 4000)
      }
      catch ({ response }) {
        Array.isArray(response.data)
          ? (
            Object.keys(response.data.errors).forEach((error) =>
              handleError(`${I18n.t(`activerecord.attributes.v4/users.${error}`)} ${response.data.errors[error]}`)
            )
          ) : (
            handleError(I18n.t('errors.default'))
          )
      }
      setLoading(false)
    }
  }

  const checkName = ({ target }) => {
    const name = target.value
    const fieldName = target.name
    const stateName = fieldName === 'first_name' ? 'firstNameError' : 'lastNameError'
    const { message } = validateName(name)
    setState({
      ...state,
      [stateName]: message
    })
  }

  const checkPhone = ({ target }) => {
    const phone = target.value
    const { message } = validPhone(phone)
    setState({
      ...state,
      phoneError: message
    })
  }

  const checkPassword = ({ target }) => {
    const password = target.value
    const { message } = validatePassword(password)
    setState({
      ...state,
      passwordError: message
    })
  }

  const checkEmail = async ({ target }) => {
    try {
      const { status, message } = await validateEmail(target.value)

      setState({
        ...state,
        emailStatusError: status,
        emailError: message
      })
    } catch (error) {
      console.error(error)
    }
  }

  const formBlank = (values) => {
    const required = I18n.t('validations.required')
    const { first_name, last_name, email, phone, password } = values
    setState({
      ...state,
      firstNameError: first_name ? '' : required,
      lastNameError: last_name ? '' : required,
      phoneError: phone ? state.phoneError : required,
      emailError: email ? state.emailError : required,
      passwordError: password ? state.passwordError : required
    })
  }

  const validForm = (values) => {
    formBlank(values)

    let is_valid = true

    if (state.phoneError || state.emailError || state.passwordError || state.firstNameError || state.lastNameError) {
      handleError(I18n.t('validations.form_error'))

      is_valid = false
    }

    if (!values.accepted_terms_and_conditions && !!terms_of_use) {
      handleError(I18n.t('validations.accept_terms_of_use'))

      is_valid = false
    }

    if (!values.accepted_user_privacy_policy && !!user_privacy_policy) {
      handleError(I18n.t('validations.accept_user_privacy_policy'))

      is_valid = false
    }

    return is_valid
  }

  return (
    <DeviseScreen classes={classes} logo_url={logo_url} background_image_url={background_image_url} >
      <form className={classes.Login__form} onSubmit={handleSubmit} noValidate >
        <SimpleTextField
          value={state.user.first_name}
          name='first_name'
          type='text'
          label={I18n.t('activerecord.attributes.v4/users.first_name')}
          classes={classes}
          onChange={handleChange}
          onBlur={checkName}
          required
        />

        {
          !!state.firstNameError &&
          <div className={classes.Login_Span__Wrapper}>
            <FormHelperText className={classes.Login_Span}>* {state.firstNameError}</FormHelperText>
          </div>
        }

        <SimpleTextField
          value={state.user.last_name}
          name='last_name'
          type='text'
          label={I18n.t('activerecord.attributes.v4/users.last_name')}
          classes={classes}
          onChange={handleChange}
          onBlur={checkName}
          required
        />

        {
          !!state.lastNameError &&
          <div className={classes.Login_Span__Wrapper}>
            <FormHelperText className={classes.Login_Span}>* {state.lastNameError}</FormHelperText>
          </div>
        }

        <SimpleTextField
          value={state.user.email}
          name='email'
          type='email'
          label={I18n.t('activerecord.attributes.v4/users.email')}
          classes={classes}
          onChange={handleChange}
          onBlur={checkEmail}
          required
        />

        {
          !!state.emailError &&
          <div className={classes.Login_Span__Wrapper}>
            <FormHelperText className={classes.Login_Span}>* {state.emailError}
              {
                state.emailStatusError === 'taken' &&
                <a href="/users/sign_in" className={classes.Login_Span__Link}>
                  {I18n.t('actions.do_login')}.
                  </a>
              }
            </FormHelperText>
          </div>
        }

        <SimpleTelephoneField
          value={state.user.phone}
          name='phone'
          label={I18n.t('activerecord.attributes.v4/users.phone')}
          classes={classes}
          onChange={handleChange}
          onBlur={checkPhone}
          required
        />

        {
          !!state.phoneError &&
          <div className={classes.Login_Span__Wrapper}>
            <FormHelperText className={classes.Login_Span}>* {state.phoneError}</FormHelperText>
          </div>
        }


        <SimpleTextFieldWithIcon
          value={state.user.password}
          name='password'
          label={I18n.t('activerecord.attributes.v4/users.password')}
          classes={classes}
          onChange={handleChange}
          onBlur={checkPassword}
          required
        />

        {
          !!state.passwordError &&
          <div className={classes.Login_Span__Wrapper}>
            <FormHelperText className={classes.Login_Span}>* {state.passwordError}</FormHelperText>
          </div>
        }

        {!!terms_of_use && <div className={classes.Login_checkTerms}>
          <Typography className={classes.Login_termsLink} variant='body2'>
            <Checkbox
              checked={Boolean(state.user.accepted_terms_and_conditions)}
              classes={{
                root: classes.Login__form_field_checkbox,
                checked: classes.Login__form_field_checkbox
              }}
              onChange={(event) => handleChange({
                target: {
                  name: 'accepted_terms_and_conditions',
                  value: Number(event.target.checked)
                }
              })}
            />
            {`${I18n.t('v4/login.terms')} `}

            <Link
              href={`/v5/terms`}
              target='_blank'
              underline='always'
              className={classes.Login_termsLink}
              variant='body1'
            >
              {I18n.t('v4/users.terms_of_use')}
            </Link>
          </Typography>
        </div>}

        {!!user_privacy_policy && <div className={classes.Login_checkTerms}>
          <Typography className={classes.Login_termsLink} variant='body2'>
            <Checkbox
              checked={Boolean(state.user.accepted_user_privacy_policy)}
              classes={{
                root: classes.Login__form_field_checkbox,
                checked: classes.Login__form_field_checkbox
              }}
              onChange={(event) => handleChange({
                target: {
                  name: 'accepted_user_privacy_policy',
                  value: Number(event.target.checked)
                }
              })}
            />
            {`${I18n.t('activerecord.attributes.v4/users.accept_privacy_policies')} `}

            <Link
              href={`/v5/privacy_policies`}
              target='_blank'
              underline='always'
              className={classes.Login_termsLink}
              variant='body1'
            >
              {I18n.t('v4/users.privacy_policies')}
            </Link>
          </Typography>
        </div>}

        {loading && <LoadingComponent size={40} />}

        <div className={classes.Login__form_actions}>
          <Button
            className={classes.Login__form_action}
            type='submit'
            color='primary'
            variant='contained'
            fullWidth
          >
            {I18n.t('sign_up')}
          </Button>
        </div>
      </form>

      <Typography>
        <a href='/users/sign_in' className={`${classes.Login__password_recovery}`} >
          {I18n.t('v4/users.sign_up.back_to_sign_in')}
        </a>
      </Typography>
    </DeviseScreen>
  )
}

export default errorHandlerProviderWrapper(
  themeProviderWrapper(
    withStyles(styles)(SignUp)
  )
)
