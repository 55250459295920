import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Active from '@material-ui/icons/Bookmark'
import Inactive from '@material-ui/icons/BookmarkBorder'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

import httpService from '@services/httpService'
import { getTermsHeaders, termsActionsHandler } from '@models/terms'

import Dialog from '@ui/DeleteDialog'
import Table from '@ui/Table'

import styles from '@styles/indexPage'

class Index extends Component {
  state = {
    isDialogOpen: false,
    termId: null,
    terms: []
  }

  componentDidMount() {
    this.setState({ terms: this.props.model })
  }

  handleCloneTerms = async (terms) => {
    const { handleError, tenant, o } = this.props

    const newTerm = {
      description_pt: terms.description_pt,
      description_en: terms.description_en,
      description_es: terms.description_es,
      name: `${terms.name}-${I18n.t('v4/terms.copy')}`
    }

    try {
      const { data } = await httpService.post(`/v4/o/${o}/admin/tenants/${tenant.id}/terms`, newTerm)

      this.setState({
        terms: [
          data,
          ...this.state.terms
        ]
      })
    }
    catch (error) {
      handleError(error)
    }
  }

  render() {
    const {
      classes,
      model,
      handleError,
      tenant,
      o
    } = this.props

    const {
      isDialogOpen,
      termId,
      terms
    } = this.state

    return (
      <Paper className={classes.page__wrapper} elevation={0}>
        <header className={classes.page__header}>
          <Typography component='h2' variant='h5'>
            {I18n.t('activerecord.models.v4/terms.other')}
          </Typography>
        </header>
        <Button
          variant='outlined'
          color='primary'
          className={classes.page__newResourceButton}
          href={`/v4/o/${o}/admin/tenants/${tenant.id}/terms/new`}
        >
          {I18n.t('actions.add')} {I18n.t('activerecord.models.v4/terms.one')}
        </Button>

        <Table
          tableKey='admin-tenant-terms-index'
          components={{
            Container: props => <div>{props.children}</div>,
          }}
          columns={getTermsHeaders()}
          data={terms}
          actions={[
            rowData => ({
              icon: 'create',
              iconProps: { 'data-testid': `edit-${rowData.id}` },
              tooltip: I18n.t('actions.edit'),
              onClick: (event, rowData) => {
                Turbolinks.visit(`/v4/o/${o}/admin/tenants/${tenant.id}/terms/${rowData.id}/edit`)
              }
            }),
            rowData => ({
              icon: 'file_copy',
              iconProps: { 'data-testid': `clone-${rowData.id}` },
              tooltip: I18n.t('v4/terms.actions.copy'),
              onClick: (event, rowData) => {
                this.handleCloneTerms(rowData)
              }
            }),
            rowData => ({
              icon: rowData.active ? 'bookmark' : 'bookmark_border',
              disabled: rowData.active || rowData.deactivated_at || rowData.activated_at ? true : false,
              iconProps: { 'data-testid': `edit-${rowData.id}` },
              tooltip: rowData.active || rowData.deactivated_at || rowData.activated_at
                ? I18n.t('actions.inactive')
                : I18n.t('actions.activate'),
              onClick: (event, rowData) => { this.setState({ isDialogOpen: true, termId: rowData.id }) }
            })
          ]}
          options={{
            showTitle: false
          }}
        />

        <Dialog
          dialogTitle={I18n.t('v4/terms.dialog.title')}
          dialogText={I18n.t('v4/terms.dialog.text')}
          handleDelete={termsActionsHandler(termId, tenant.id, handleError, o)}
          onCancel={() => this.setState({ isDialogOpen: false })}
          onClose={() => this.setState({ isDialogOpen: false })}
          open={isDialogOpen}
          actionText={I18n.t('v4/terms.dialog.title')}
        />
      </Paper>
    )
  }
}

Index.propTypes = {
  model: PropTypes.array
}

export default withStyles(styles)(Index)
