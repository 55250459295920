import React from 'react'

import Button from '@material-ui/core/Button'

import withStyles from '@material-ui/core/styles/withStyles'
import { Field } from 'react-final-form'

import DatePicker from '@ui/Fields/DatePicker'
import Select from '@ui/Fields/Select'
import styles from '@theme/styles/forms'

const Form = ({
  handleSubmit,
  classes,
  action,
  onCancel,
  isCultureDisabled,
  cultures
}) =>
  <form onSubmit={handleSubmit} data-testid='new-plantation-form'>
    <main className={classes.form__fields}>
      <div className={classes.form__field__wrapper}>
        <Field
          component={Select}
          data={cultures}
          fields={{ id: 'id', name: 'name' }}
          fullWidth
          InputLabelProps={{ shrink: true }}
          variant='outlined'
          label={I18n.t('activerecord.models.v4/culture.one')}
          name='culture_id'
          disabled={isCultureDisabled}
          required
        />
      </div>

      <div className={classes.form__field__wrapper}>
        <Field
          className={classes.form__fieldWithError}
          component={DatePicker}
          margin='normal'
          InputLabelProps={{ shrink: true }}
          variant='outlined'
          label={I18n.t('activerecord.attributes.v4/culture.phenological_stages.date')}
          name='planting_date'
          disableFuture
          fullWidth
          required
        />
      </div>
    </main>

    <footer className={classes.form__actions}>
      <Button
        className={classes.form__action}
        color='primary'
        type='submit'
        variant='contained'
      >
        {action}
      </Button>

      <Button
        className={classes.form__action}
        color='secondary'
        onClick={onCancel}
      >
        {I18n.t('actions.cancel')}
      </Button>
    </footer>
  </form>

Form.defaultProps = {
  cultures: []
}

export default withStyles(styles)(Form)
