import React, { Component } from 'react'
import MaterialTable from '@ui/Table'

import { locate_forecast_machine } from "@models/coordinates"
import { RailsForm } from '@support/RailsForm'
import Dialog from '@ui/Dialog'
import DeleteDialog from '@ui/DeleteDialog'

import Form from './TableForm'
import ShowStage from './Show'

import { getForecastPointsHeaders } from '@models/admin/products/forecastPoints'

class ForecastPoints extends Component {
  constructor(props) {
    super(props)

    this.state = {
      dialog: {
        open: false,
        title: '',
        forecastPoint: null,
        handler: () => { }
      },
      isDeleteDialogOpen: false,
      selectedForecastPointIndex: null
    }
  }

  handleDeleteDialogToggle(selectedForecastPointIndex) {
    this.setState({
      isDeleteDialogOpen: !this.state.isDeleteDialogOpen,
      selectedForecastPointIndex
    })
  }

  handleDialogToggle() {
    this.setState({
      dialog: {
        ...this.state.dialog,
        open: !this.state.dialog.open
      }
    })
  }

  handleForecastPointName(forecastPoint) {
    if (forecastPoint.name) {
      forecastPoint.name = forecastPoint.name.replaceAll(",", " ")
      forecastPoint.name = forecastPoint.name.replaceAll(";", " ")
    }
  }

  handleNewForecastPoint() {
    const { handlers } = this.props

    this.setState({
      dialog: {
        open: true,
        title: `${I18n.t('actions.add')} ${I18n.t('v4/organization.products.forecast.forecast_point.one')}`,
        forecastPoint: null,
        handler: values => {
          let forecast_machine_id = locate_forecast_machine(values)
          this.handleForecastPointName(values)
          if (forecast_machine_id) {
            values.forecast_machine_id = forecast_machine_id
            this.handleDialogToggle()
            handlers.new(values)
          } else if(values.lat != "" && values.lon != "") {
            handlers.error(I18n.t('v4/organization.products.forecast.forecast_point.coordinate_error'))
          } else if (values.name.includes(",") || values.name.includes(";")) {
            handlers.error(I18n.t('v4/organization.products.forecast.forecast_point.coordinate_error'))
          } else {
            this.handleDialogToggle()
            handlers.new(values)
          }
        }
      }
    })
  }

  handleEditForecastPoint(forecastPoint) {
    const { handlers } = this.props

    this.setState({
      dialog: {
        open: true,
        title: `${I18n.t('actions.edit')} ${I18n.t('v4/organization.products.forecast.forecast_point.one')}`,
        forecastPoint,
        handler: values => {
          const forecast_machine_id = locate_forecast_machine(values)
          this.handleForecastPointName(values)
          if (forecast_machine_id) {
            const { forecastPoint } = this.state.dialog

            values.index = forecastPoint.tableData.id
            values.forecast_machine_id = forecast_machine_id
            values.id = forecastPoint.id

            this.handleDialogToggle()
            handlers.edit(values)
          } else {
            handlers.error(I18n.t('v4/organization.products.forecast.forecast_point.coordinate_error'))
          }
        }
      }
    })
  }

  render() {
    const { points, handlers } = this.props
    const { dialog, selectedForecastPointIndex, isDeleteDialogOpen } = this.state

    return (
      <>
        <MaterialTable
          columns={getForecastPointsHeaders()}
          detailPanel={forecastPoint =>
            <ShowStage
              forecastPoint={forecastPoint}
            />
          }
          data={points}
          title={I18n.t('v4/organization.products.forecast.forecast_point.other')}
          actions={[
            {
              icon: 'add',
              tooltip: I18n.t('actions.add'),
              isFreeAction: true,
              onClick: () => this.handleNewForecastPoint()
            },
            rowData => ({
              icon: 'refresh',
              tooltip: I18n.t('actions.reset'),
              disabled: !rowData.lat,
              onClick: () => handlers.edit(rowData, true)
            }),
            rowData => ({
              icon: 'create',
              iconProps: { 'data-testid': `edit-${rowData.id}` },
              tooltip: I18n.t('actions.edit'),
              onClick: () => this.handleEditForecastPoint(rowData)
            }),
            rowData => ({
              icon: 'delete',
              iconProps: { 'data-testid': `delete-${rowData.id}` },
              tooltip: I18n.t('actions.remove'),
              onClick: () => this.handleDeleteDialogToggle(rowData.tableData.id)
            })
          ]}
          localization={{
            body: {
              emptyDataSourceMessage: I18n.t('info.no_data')
            }
          }}
          options={{
            search: false,
            paging: false,
            showEmptyDataSourceMessage: true,
            sorting: false,
            actionsColumnIndex: -1,
            headerStyle: {
              backgroundColor: '#f5f5f5'
            }
          }}
        />
        <Dialog
          open={dialog.open}
          onClose={() => this.handleDialogToggle()}
          title={dialog.title}
        >
          <Form
            handleSubmit={values => dialog.handler(values)}
            initialValues={dialog.values}
            onCancel={() => this.handleDialogToggle()}
            action={dialog.title}
            forecastPoint={dialog.forecastPoint}
          />
        </Dialog>

        <DeleteDialog
          dialogTitle={`${I18n.t('actions.remove')} ${I18n.t('v4/organization.products.forecast.forecast_point.one')}?`}
          dialogText={I18n.t('confirmation.remove')}
          onCancel={() => this.handleDeleteDialogToggle()}
          onClose={() => this.handleDeleteDialogToggle()}
          handleDelete={() => {
            handlers.delete(selectedForecastPointIndex)
            this.handleDeleteDialogToggle()
          }}
          open={isDeleteDialogOpen}
        />
      </>
    )
  }
}

export default ForecastPoints
