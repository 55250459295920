import React from 'react'

import withStyles from '@material-ui/core/styles/withStyles'

import styles from './styles'

const InfoMenu = ({
  classes,
  data,
  type,
  o
}) =>
  <div className={classes.menu__info__container}>

  </div>

export default withStyles(styles)(InfoMenu)
