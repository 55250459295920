import React from 'react'

const Dot = ({ cx, cy, stroke, payload }) =>
  <svg x={cx - 15} y={cy - 15} width='25' height='25' viewBox='0 0 24 24'>
    <path
      transform={`rotate(${payload.wd10_avg || 0} 12 12)`}
      fill={stroke}
      d='M2.47677 0.80731L9.43234 4.85958C9.73689 5.03701 10.1123 5.04101 10.4206 4.87012L17.8618 0.744833C18.6882 0.286699 19.6304 1.12448 19.2719 1.99875L10.8718 22.4891C10.5294 23.3244 9.34297 23.3142 9.0149 22.4732L1.04175 2.0348C0.697586 1.15256 1.65851 0.3306 2.47677 0.80731Z'
    />
  </svg>

export default Dot
