const headers = ['name', 'lat', 'lon']

export const getForecastPointsHeaders = () =>
  [
    ...headers.map(header => ({
      title: I18n.t(`activerecord.attributes.v4/organization_products.forecast.forecast_points.${header}`),
      field: header,
      render: rowData => (header === 'lat' || header === 'lon') ? Number(rowData[header]).toFixed(4) : rowData[header]
    }))
  ]

export const fields = () => [
  { name: 'name', validations: { required: true } },
  { name: 'forecast_machine_id' },
  { name: 'lat', validations: { required: true, bounds: { min: -90, max: 90 } } },
  { name: 'lon', validations: { required: true, bounds: { min: -180, max: 180 } } },
  { name: 'state', validations: { required: true } },
  { name: 'country', validations: { required: true } },
]
