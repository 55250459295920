export const validate = values => {
  const requiredFields = [
    'name'
  ]
  const errors = {}

  requiredFields.forEach(field => {
    if (!values[field]) errors[field] = I18n.t('validations.required')
  })

  if (values.latitude && !isValidLatitude(values.latitude)){
    errors.latitude = I18n.t('validations.field_between', { min: -90, max: 90 })
  }

  if (values.longitude && !isValidLongitude(values.longitude)){
     errors.longitude = I18n.t('validations.field_between', { min: -180, max: 180 })
  }

  return errors
}

export const isValidLatitude = (latitude) => {
  const lat = Number(latitude)

  return  lat >= -90 && lat <= 90
}

export const isValidLongitude = (longitude) => {
  const lon = Number(longitude)

  return  lon >= -180 && lon <= 180
}
