import React, { useEffect } from 'react'
import { Form as FinalForm } from 'react-final-form'

import {
  Paper,
  withStyles
} from '@material-ui/core'

import { useHttpService } from '@contexts/HttpServiceProvider'
import { useSessionData } from '@contexts/SessionDataProvider'
import { useErrorHandler } from '@contexts/ErrorHandlerProvider'
import { useMenusStatus, MENUS } from '@contexts/MenusStatusProvider'

import { validate, getFilterGroups, getSupportLink } from '@models/satelliteImagery'
import { RailsForm } from '@support/RailsForm'

import FilterForm from '../maps/productsFloatingMenu/View/FilterForm'
import CardCreateArea from '../maps/productsFloatingMenu/View/CardCreateArea'
import LoadingComponent from '@ui/LoadingComponent'

const Filter = ({ areas, changeMapCenter, classes, names, loadingAreas }) => {
  const [filters, setFilters] = React.useState([])
  const [loading, setLoading] = React.useState(false)
  const [fetchingImages, setFetchingImages] = React.useState(false)

  const { menus, setMenuData } = useMenusStatus()
  const { apiRequest } = useHttpService()
  const errorHandler = useErrorHandler()
  const sessionData = useSessionData()
  const { currentOrganization } = sessionData
  const { data: initialValues } = menus[names.satelliteImageryMenu]

  useEffect(() => {
    fetchFilters()
  }, [])

  const fetchFilters = async () => {
    const params = { organization_id: currentOrganization.id }

    try {
      const { data } = await apiRequest('satellite_images/filters', { params })
      setFilters(data.filters)
    }
    catch (error) {
      errorHandler(error)
    }
  }

  const fetchImages = async (values) => {
    setLoading(true)

    try {
      const params = {
        ...getParams(values),
        organization_id: currentOrganization.id
      }

      const { data } = await apiRequest('satellite_images', { params })
      const images = orderImages(data.images)

      _.isEmpty(images)
        ? errorHandler(I18n.t('v4/satellite_images.errors.no_image'))
        : handleFetchImages(values, images)

    }
    catch (error) {
      errorHandler(error)
    }

    setLoading(false)
  }

  const handleFetchImages = (values, images) => {
    setMenuData(MENUS[names.satelliteImageryMenu], 'data', getMenuData(values, images))
    setMenuData(MENUS[names.satelliteImageryMenu], 'open', true)
    setMenuData(MENUS[names.filterMenu], 'open', false)
  }

  const getMenuData = (values, images) => {
    const currentFilter = filters.find(filter => filter.id === values.filterId)
    const currentArea = areas.find(area => area.id === values.currentAreaId)

    return {
      filterName: currentFilter.name,
      scale: !!currentFilter['legend_info'] ? currentFilter.legend_info : {},
      cloudsPercentage: values.cloudsPercentage,
      startDate: values.startDate,
      endDate: values.endDate,
      currentArea,
      images,
      currentAreaId: currentArea.id,
      filterId: currentFilter.id
    }
  }

  const getParams = (values) => {
    return {
      area_id: values.currentAreaId,
      filter_id: values.filterId,
      clouds_percentage: values.cloudsPercentage,
      start_date: values.startDate,
      end_date: values.endDate,
      organization_id: currentOrganization.id
    }
  }

  const orderImages = (images) => _.orderBy(images, 'date')
  const handleCancelLoading = () => setFetchingImages(false)

  return (
    <Paper className={classes.Filter_wrapper}>
      {
        loadingAreas
          ? <LoadingComponent />
          : (
            _.isEmpty(areas)
              ? (
                <CardCreateArea
                  getSupportLink={getSupportLink}
                />
              )
              : (
                <RailsForm
                  component={FinalForm}
                  validate={validate}
                  handleSubmit={fetchImages}
                  initialValues={initialValues}
                  render={
                    (props) => (
                      <FilterForm
                        {...props}
                        filterGroup={getFilterGroups(filters)}
                        areas={areas}
                        loading={loading}
                        handleCancelLoading={handleCancelLoading}
                        changeMapCenter={changeMapCenter}
                        isComparison={true}
                        names={names}
                      />
                    )}
                />
              )
          )
      }
    </Paper>
  )
}

const styles = () => ({
  Filter_wrapper: {
    position: 'absolute',
    zIndex: 401,
    top: 8,
    right: 8
  }
})

export default withStyles(styles)(Filter)
