import React from 'react'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import { Field } from 'react-final-form'
import Select from '@ui/Fields/Select'
import styles from '@theme/styles/forms'

const Form = ({
  handleSubmit,
  classes,
  action,
  onCancel,
  plansList,
  productList
}) => (
    <form className={classes.form} onSubmit={handleSubmit} data-testid="coupon-form">
      <main className={classes.form__fields}>
        <div className={classes.form__field__wrapper} >
          <Field
            component={Select}
            className={classes.form__fieldWithError}
            InputLabelProps={{ shrink: true, required: true }}
            label={I18n.t('activerecord.attributes.v4/coupon.plan_kind')}
            name='plan_id'
            margin='normal'
            fields={{ id: 'id', name: 'name' }}
            data={plansList}
            fullWidth
            variant="outlined"
            required
          >
          </Field>
        </div>
        <div className={classes.form__field__wrapper}>
          <Field
            component={Select}
            className={classes.form__fieldWithError}
            InputLabelProps={{ shrink: true, required: true }}
            label={I18n.t('activerecord.attributes.v4/coupon.product')}
            name='store_product_id'
            margin='normal'
            fields={{ id: 'id', name: 'name' }}
            data={productList}
            fullWidth
            variant="outlined"
            required
          >
          </Field>
        </div>
      </main>
      <footer className={classes.form__actions}>
        <Button
          className={classes.form__action}
          color='primary'
          type='submit'
          variant='contained'
        >
          {action}
        </Button>

        <Button
          className={classes.form__action}
          color='secondary'
          onClick={onCancel}
        >
          {I18n.t('actions.cancel')}
        </Button>
      </footer>
    </form>
  )

export default withStyles(styles)(Form)
