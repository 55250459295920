import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import { withSnackbar } from 'notistack'
import { Form as FinalForm } from 'react-final-form'
import { Paper, CircularProgress, Typography } from '@material-ui/core'
import momentTz from 'moment-timezone'
import withStyles from '@material-ui/core/styles/withStyles'

import { RailsForm } from '@support/RailsForm'
import { validate, filterData } from '@models/equipment/telemetry/dateFilter'
import Select from '@ui/SimpleSelect'
import {
  getDefaultTableHeaders,
  getDefaultChartTypes,
  deserialize,
  group,
} from '@models/equipment/telemetry/charts'

import DashboardCharts from './DashboardCharts'
import DashboardTable from './DashboardTable'
import styles from './styles'
import Filter from './Filter'
import ModalCharts from './ModalCharts'

import { sendAmplitudeEvent } from '@utils/amplitudeEvent'
import { IntercomAPI } from '@utils/IntercomAPI';

class Index extends React.Component {
  constructor(props) {
    super(props)

    const { start, finish, group_by } = this.getInitialValues()

    this.state = {
      series: [],
      data: [],
      sortedDataByDate: [],
      loading: false,
      equipment: {},
      finish,
      currentEquipIndex: 0,
      tableHeaders: [],
      group_by,
      start
    }
  }

  componentDidMount() {
    const { model: equipment, organizationId, equipmentsList } = this.props
    const { start, finish, group_by } = this.state

    const timezone = momentTz.tz.guess()

    this.setState({
      equipment,
      currentEquipIndex: equipmentsList.findIndex(equip => equip.id === equipment.id)
    })

    const params = {
      start,
      finish,
      group_by,
      organization_id: organizationId,
      timezone
    }

    this.fetchData(equipment.id, params)
  }

  getInitialValues = () => {
    const urlParams = new URLSearchParams(window.location.search)
    const changeEquipment = urlParams.get('change_equipment')

    const savedFilterParams = this.savedFilter.get()

    let group_by = null

    if (!_.isEmpty(savedFilterParams)) {
      const params = JSON.parse(savedFilterParams)
      group_by = params.group_by

      if (changeEquipment) {
        return params
      }
    }

    const currentDate = new Date()
    currentDate.setMinutes(0)

    const finish = currentDate.toISOString()

    currentDate.setHours(0)
    currentDate.setDate(currentDate.getDate() - 7)
    const start = currentDate.toISOString()

    return {
      finish,
      group_by: group_by || 'hour',
      start
    }
  }

  formatWindTable = (tableHeaders) =>
    tableHeaders.map(header => {
      if (header.name === 'ws1') {
        const [ws1Min, ws1Avg, ws1Max] = header.data

        return {
          ...header,
          data: [
            {
              ...ws1Min,
              title: I18n.t('v4/telemetry.wg1'),
              field: 'wg1_max'
            },
            ws1Avg
          ]
        }
      }

      return header
    })

  handleWindHeader = (tableHeaders, noGrouping) => {
    if (tableHeaders.find(header => header.name === 'ws1') &&
      tableHeaders.find(header => header.name === 'wd1') &&
      tableHeaders.find(header => header.name === 'wg1') &&
      !noGrouping
    ) {
      _.remove(tableHeaders, { name: 'wg1' })
      return this.formatWindTable(tableHeaders)
    } else {
      return tableHeaders
    }
  }

  fetchData = async (id, params) => {
    const { enqueueSnackbar } = this.props
    this.setState({ loading: true })

    try {
      const { data } = await filterData(id, params)
      
      const formattedData = data.grouped_data || data.data || []
      const sortedDates = [...formattedData].sort((a, b) => new Date(b.date) - new Date(a.date))
      const noGrouping = params.group_by === 'none'
      const keys = !_.isEmpty(formattedData)
        ? Object.keys(formattedData[0])
        : []
      
      const descriptions = data.descriptions || []
      const chartTypes = getDefaultChartTypes(descriptions, noGrouping, keys)
      const series = group(chartTypes)

      const tableHeaders = this.handleWindHeader(getDefaultTableHeaders(descriptions, noGrouping, keys), noGrouping)

      this.setState({
        data: formattedData.map(data => deserialize(data, params.group_by)),
        sortedDataByDate: sortedDates.map(data => deserialize(data, params.group_by)),
        series,
        tableHeaders
      })
    }
    catch (error) {
      // Check if error was generated from the api request
      if (error.response && error.response.data && error.response.data.errors) {
        const { response: { data } } = error
        data.errors['opts.date_range'].forEach(err => enqueueSnackbar(err, { variant: 'error' }))
        this.setState({
          data: []
        })
      } else {
        enqueueSnackbar(I18n.t('errors.default'), { variant: 'error' })
        console.error(error)
      }
    }
    this.setState({ loading: false })
  }

  handleFilter = (values) => {
    const { model: equipment, organizationId } = this.props
    const timezone = momentTz.tz.guess()

    values.start = moment(values.start).format()
    values.finish = moment(values.finish).format()

    const params = {
      ...values,
      organization_id: organizationId,
      timezone
    }

    this.savedFilter.set(params)

    this.fetchData(equipment.id, params)

    IntercomAPI('trackEvent', 'Telemetry - Clicked Filter button')

    sendAmplitudeEvent('Telemetry - Clicked Filter button', {
      periodicity: params.group_by
    })
  }

  handleEquipmentChange = ({ target }) => {
    const { equipmentsList, o } = this.props
    const { currentEquipIndex } = this.state
    const index = target.value

    if (index === currentEquipIndex) {
      return
    }

    Turbolinks.visit(`/v4/o/${o}/equipments/${equipmentsList[index].id}/dashboard?change_equipment=true`)
  }

  savedFilter = {
    id: 'telemetry_saved_filter_params',
    get: function () {
      return localStorage.getItem(this.id)
    },
    set: function (params) {
      return localStorage.setItem(this.id, JSON.stringify(params))
    }
  }

  render() {
    const {
      loading,
      series,
      data,
      sortedDataByDate,
      start,
      finish,
      tableHeaders,
      currentEquipIndex,
      group_by
    } = this.state
    const { classes, equipmentsList } = this.props

    return (
      <Paper
        className={classes.Dashboard__wrapper}
        elevation={0}
      >
        <header className={classes.Dashboard__header}>
          <Typography component="h2" variant="h5">
            {I18n.t('v4/equipment.dashboard.title')}:
            <Select
              values={equipmentsList}
              selectedIndex={currentEquipIndex}
              handleChange={(v) => this.handleEquipmentChange(v)}
              onClick={() => {
                sendAmplitudeEvent('Telemetry - Selected equipment')
                IntercomAPI('trackEvent', 'Telemetry - Selected equipment')
              }}
              isTitle
            />
          </Typography>

          {
            !!data.length &&
            <Typography component="h4">
              {I18n.t('v4/equipment.dashboard.showing_data_range')}
              <strong> {data[0].date}</strong> {I18n.t('and')} <strong>{data[data.length - 1].date}</strong>
            </Typography>
          }
        </header>

        <RailsForm
          component={FinalForm}
          handleSubmit={this.handleFilter}
          validate={validate}
          render={props => (
            <Filter
              {...props}
              initialStart={start}
              initialFinish={finish}
              initialGroup={group_by}
              disabled={loading}
            />
          )}
        />

        {
          !loading ?
            (
              !!data.length ?
                (
                  <div>
                    <DashboardCharts
                      series={series}
                      data={data}
                    />

                    <DashboardTable
                      data={sortedDataByDate}
                      classes={classes}
                      tableHeaders={tableHeaders}
                      equipment={equipmentsList[currentEquipIndex]}
                    />
                    <ModalCharts classes={classes} />
                  </div>
                ) : (
                  <Typography className={classes.Dashboard__no_data} component='h3' variant='h5'>
                    {I18n.t('info.no_data')}
                  </Typography>
                )
            ) : (
              <div className={classes.Dashboard__loading}>
                <CircularProgress size={80} />
              </div>
            )
        }
      </Paper >
    )
  }
}

Index.defaultProps = {
  model: {},
  measures: []
}

Index.propTypes = {
  model: PropTypes.object,
  measures: PropTypes.array,
}

export default withSnackbar(
    withStyles(styles)(Index)
  )
