export default theme => ({
  container: {
    display: 'flex'
  },
  menu: {
    backgroundColor: theme.menus.background,
    display: 'flex',
    justifyContent: 'space-between'
  },
  menu__logoLink: {
    textDecoration: 'none',
    width: 50,
    display: 'grid'
  },
  menu_floating: {
    backgroundColor: 'transparent',
    position: 'absolute',
    alignSelf: 'flex-end',
    top: theme.spacing.unit,
    right: theme.spacing.unit,
    zIndex: 1000,
  },
  menu_floatingTrial: {
    backgroundColor: 'transparent',
    position: 'absolute',
    alignSelf: 'flex-end',
    top: theme.spacing.unit * 8,
    right: theme.spacing.unit,
    zIndex: 1000,
  }
})
