import React from 'react'
import DefaultColorsForm from './DefaultColorsForm'

import withStyles from '@material-ui/core/styles/withStyles'
import styles from './style'

const BackgroundColorForm = ({ classes, color, updateColor }) =>
  <DefaultColorsForm
    classes={classes}
    title={I18n.t('v4/tenant.styles.background')}
    color={color}
    label={I18n.t('v4/tenant.styles.colors.one')}
    updateColor={updateColor}
  >
    <div className={classes.ColorsForm__fields_Background} />
  </DefaultColorsForm>

export default withStyles(styles)(BackgroundColorForm)
