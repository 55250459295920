import React, { Component } from 'react'
import PropTypes from 'prop-types'


import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

import { partNumberShape } from '@models/partNumber'

import DeleteDialog from '../../ui/DeleteDialog'

import styles from './stylesShow'

class Show extends Component {
  constructor(props) {
    super(props)
    this.state = {
      isDialogOpen: false
    }

    this.closeDialog = this.closeDialog.bind(this)
    this.openDialog = this.openDialog.bind(this)
  }

  closeDialog() {
    this.setState({ isDialogOpen: false })
  }

  openDialog() {
    this.setState({ isDialogOpen: true })
  }

  render() {
    const {
      classes,
      model,
      o
    } = this.props
    const { isDialogOpen } = this.state

    return (
      <Paper
        className={classes.showPartNumberRegistries__wrapper}
        elevation={0}
      >
        <header className={classes.showPartNumberRegistries__header}>
          <Typography component='h2' variant='h5'>
            Informações sobre o Part Number
          </Typography>
        </header>

        <main className={classes.showPartNumberRegistries__values}>
          <div className={classes.showPartNumberRegistries__value}>
            <Typography
              color='textSecondary'
              component='h6'
              gutterBottom
              variant='h6'
            >
              Part Number
            </Typography>

            <Typography paragraph variant='subtitle1' data-testid='part_number'>
              {model.part_number}
            </Typography>
          </div>

          <div className={classes.showPartNumberRegistries__value}>
            <Typography
              color='textSecondary'
              component='h6'
              gutterBottom
              variant='h6'
            >
              Part Name
            </Typography>

            <Typography variant='subtitle1' paragraph data-testid='part_name'>
              {model.part_name}
            </Typography>
          </div>

          <div className={classes.showPartNumberRegistries__value}>
            <Typography
              color='textSecondary'
              variant='h6'
              component='h6'
              gutterBottom
            >
              Connectivity
            </Typography>

            <Typography paragraph variant='subtitle1' data-testid='connectivity'>
              {model.hardware_integration_product}
            </Typography>
          </div>
        </main>

        <footer className={classes.showPartNumberRegistries__actions}>
          <Button
            className={classes.showPartNumberRegistries__action}
            color='primary'
            variant='contained'
            href={`/v4/o/${o}/admin/part_number_registries/${model.id}/edit`}
          >
            {I18n.t('actions.edit')}
          </Button>

          <Button
            className={classes.showPartNumberRegistries__action}
            color='secondary'
            variant="contained"
            onClick={this.openDialog}
          >
            {I18n.t('actions.remove')}
          </Button>
          <Button
            className={classes.showPartNumberRegistries__action}
            color='secondary'
            href={`/v4/o/${o}/admin/part_number_registries`}
          >
            {I18n.t('actions.back')}
          </Button>
        </footer>

        <DeleteDialog
          dialogTitle={I18n.t('v4/part_number.dialog.title')}
          dialogText={I18n.t('v4/part_number.dialog.text')}
          successUrl={`/v4/o/${o}/part_number_registries`}
          deleteUrl={`/v4/o/${o}/part_number_registries/${model.id}.json`}
          onCancel={this.closeDialog}
          onClose={this.closeDialog}
          open={isDialogOpen}
        />
      </Paper>
    )
  }
}

Show.propTypes = {
  model: partNumberShape.isRequired
}


export default withStyles(styles)(Show)
