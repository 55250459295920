import React, { Fragment } from 'react'
import { Paper, Typography, Divider, Button, Grid } from '@material-ui/core'
import { formatPrice, calculateTotalValue } from '@models/checkout'
import CouponForm from './CouponForm'
import { withStyles } from '@material-ui/core/styles'

const BillingInfo = ({ advanceStep, classes, product, coupon, setCoupon, plan, o }) =>
  <Fragment>
    <Grid container className={classes.BillingInfo_container}>
      <Paper className={classes.BillingInfo}>
        <Typography variant='subtitle1'>
          {I18n.t('v4/payment_checkout.billing_info.you_are_acquiring')}
        </Typography>

        <Typography className={classes.BillingInfo__product_title}>
          {product.name}
        </Typography>

        <section className={classes.BillingInfo__Coupon}>
          <CouponForm
            product={product}
            setCoupon={setCoupon}
            products={[product]}
            plan={plan}
          />
        </section>

      </Paper>

      <Paper className={classes.BillingInfo}>
        <section className={classes.BillingInfo__Billing}>
          <Typography variant='h6' className={classes.BillingInfo__Price}>
            {I18n.t('v4/payment_checkout.billing_info.product_price')}: <span>{formatPrice(product.price)}</span>
          </Typography>

          {
            coupon &&
            <Typography variant='subtitle1' className={classes.BillingInfo__Price}>
              {I18n.t('v4/payment_checkout.billing_info.coupon_discont')}: <span>-{formatPrice(coupon.discount)}</span>
            </Typography>
          }

          <Divider />

          <Typography variant='h6' className={classes.BillingInfo__FinalPrice}>
            {I18n.t('v4/payment_checkout.billing_info.total')}: <span>{formatPrice(calculateTotalValue(product.price, coupon))}</span>
          </Typography>
        </section>
      </Paper>
    </Grid>

    <div className={classes.BillingInfo__Actions}>
      <Button
        onClick={() => Turbolinks.visit(`/v4/o/${o}/store`)}
        variant='contained'
        className={classes.BillingInfo__Action}
      >
        {I18n.t('actions.back')}
      </Button>

      <Button
        onClick={advanceStep}
        className={classes.BillingInfo__Action}
        color='primary'
        variant='contained'
        data-intercom-target="Store Step 2"
      >
        {I18n.t('actions.advance')}
      </Button>
    </div>
  </Fragment>

const styles = (theme) => ({
  BillingInfo_container: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'baseline'
  },
  BillingInfo: {
    margin: `${theme.spacing.unit * 3}px ${theme.spacing.unit * 2}px`,
    maxWidth: 'max-content',
    padding: theme.spacing.unit * 3,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    borderRadius: '10px'
  },
  BillingInfo__product_title: {
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  },
  BillingInfo__Coupon: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  BillingInfo__Billing: {
    width: '100%'
  },
  BillingInfo__Price: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  BillingInfo__FinalPrice: {
    display: 'flex',
    justifyContent: 'space-between',
    color: theme.palette.primary.main,
    fontWeight: 'bold'
  },
  BillingInfo__Actions: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: `${theme.spacing.unit * 2}px`
  },
  BillingInfo__Action: {
    '&:not(:last-child)': {
      marginRight: `${theme.spacing.unit * 2}px`
    }
  },
})

BillingInfo.defaultProps = {
  advanceStep: () => { },
  product: {},
  setCoupon: () => { }
}

export default withStyles(styles)(BillingInfo)
