import React from 'react'

import { Typography } from '@material-ui/core'

const InfoDate = ({ classes, dateAndTime, label, alignCenter }) =>
  <Typography className={classes.Equipment_last_update} variant='body1' align={alignCenter ? 'center' : 'left'}>
    {`${label} ${dateAndTime.infoDate} ${dateAndTime.infoHour}`}
  </Typography>

export default InfoDate
