import React from 'react'
import { Field } from 'react-final-form'
import { FormControlLabel, IconButton, InputAdornment } from '@material-ui/core'
import { Checkbox } from 'final-form-material-ui'
import { Visibility, VisibilityOff } from '@material-ui/icons'
import TextField from '@ui/Fields/TextField'

const EmailConfigurationForm = ({ classes, use_default_configuration }) => {
  const [showPassword, changePasswordVisibility] = React.useState(false)

  return <React.Fragment>
    <div className={classes.form__field__wrapper}>
      <FormControlLabel
        label={I18n.t('activerecord.attributes.v4/tenant.use_default_configuration')}
        control={
          <Field
            name='use_default_configuration'
            component={Checkbox}
            type='checkbox'
          />
        }
      />

      <Field
        component={TextField}
        className={classes.form__fieldWithError}
        disabled={use_default_configuration}
        margin='normal'
        fullWidth
        InputLabelProps={{ shrink: true }}
        variant='outlined'
        label={I18n.t('activerecord.attributes.v4/tenant.mail_from')}
        name='mail_from'
        required={!use_default_configuration}
      />
    </div>

    <div className={classes.form__field__wrapper}>
      <Field
        component={TextField}
        disabled={use_default_configuration}
        className={classes.form__fieldWithError}
        margin='normal'
        fullWidth
        InputLabelProps={{ shrink: true }}
        variant='outlined'
        label={I18n.t('activerecord.attributes.v4/tenant.smtp_settings.port')}
        name='smtp_settings.port'
        type='number'
        required={!use_default_configuration}
      />
    </div>

    <div className={classes.form__field__wrapper}>
      <Field
        component={TextField}
        disabled={use_default_configuration}
        className={classes.form__fieldWithError}
        margin='normal'
        fullWidth
        InputLabelProps={{ shrink: true }}
        variant='outlined'
        label={I18n.t('activerecord.attributes.v4/tenant.smtp_settings.domain')}
        name='smtp_settings.domain'
        required={!use_default_configuration}
      />
    </div>

    <div className={classes.form__field__wrapper}>
      <Field
        component={TextField}
        disabled={use_default_configuration}
        className={classes.form__fieldWithError}
        margin='normal'
        fullWidth
        InputLabelProps={{ shrink: true }}
        variant='outlined'
        label={I18n.t('activerecord.attributes.v4/tenant.smtp_settings.address')}
        name='smtp_settings.address'
        required={!use_default_configuration}
      />
    </div>

    <div className={classes.form__field__wrapper}>
      <Field
        component={TextField}
        disabled={use_default_configuration}
        className={classes.form__fieldWithError}
        margin='normal'
        fullWidth
        InputLabelProps={{ shrink: true }}
        variant='outlined'
        label={I18n.t('activerecord.attributes.v4/tenant.smtp_settings.user_name')}
        name='smtp_settings.user_name'
        required={!use_default_configuration}
      />
    </div>

    <div className={classes.form__field__wrapper}>
      <Field
        component={TextField}
        disabled={use_default_configuration}
        className={classes.form__fieldWithError}
        margin='normal'
        InputLabelProps={{ shrink: true }}
        variant='outlined'
        label={I18n.t('activerecord.attributes.v4/tenant.smtp_settings.password')}
        name='smtp_settings.password'
        required={!use_default_configuration}
        fullWidth
        type={showPassword ? 'input' : 'password'}
        InputProps={{
          endAdornment:
            <InputAdornment position='end'>
              <IconButton
                onClick={() => changePasswordVisibility(!showPassword)}
              >
                {showPassword ? <Visibility /> : <VisibilityOff />}
              </IconButton>
            </InputAdornment>
        }}
      />
    </div>

    <FormControlLabel
      label={I18n.t('activerecord.attributes.v4/tenant.smtp_settings.enable_starttls_auto')}
      control={
        <Field
          disabled={use_default_configuration}
          name='smtp_settings.enable_starttls_auto'
          component={Checkbox}
          type='checkbox'
        />
      }
    />
  </React.Fragment>
}

export default EmailConfigurationForm
