import React from 'react'

import { Typography } from '@material-ui/core'

import Elo from '@ui/Icons/Elo'
import MasterCardIcon from '@ui/Icons/MasterCard'
import VisaIcon from '@ui/Icons/Visa'
import DefaultCreditCardIcon from '@material-ui/icons/Payment'

const getCreditCardIcon = (creditCardCode) => {
  const creditCardIcon = creditCardIcons[creditCardCode]

  if (!creditCardIcon) return <DefaultCreditCardIcon style={{ fontSize: '45px' }} />

  return creditCardIcon
}

const creditCardIcons = {
  Elo: <Elo />,
  mastercard: <MasterCardIcon />,
  visa: <VisaIcon />
}

const CreditCard = ({ card, classes, handleCardClick }) =>
  <Typography className={classes.CreditCard} onClick={handleCardClick} data-intercom-target="Saved Credit Card">
    <span className={classes.CreditCard__name}>
      <span className={classes.CreditCard__icon}>
        {getCreditCardIcon(card.flag)}
      </span>
      {card.flag}
    </span>
    <CreditCardNumber
      card={card}
      classes={classes}
    />
  </Typography>

export const CreditCardNumber = ({ card, classes = {} }) =>
  <span className={classes.CreditCard__number}>
    &bull;&bull;&bull;&bull; {card.card_number}
  </span>

export default CreditCard
