
import React from 'react'
import {
  ResponsiveContainer, ComposedChart, XAxis, YAxis,
  Tooltip, Line, CartesianGrid, Legend,
} from 'recharts'
import CustomTooltip from './Tooltip'
import CustomDot from './Dot'
import { getYAxisOrientation, getMinMaxValues, chartsFontFamily } from '@models/chart'
import PropTypes from 'prop-types'

const WindVelocityChart = ({ data, xData, classes, descriptors, showingData, getDescriptor }) => {
  const chartDomains = getMinMaxValues(data, showingData)

  return (
    <ResponsiveContainer width='100%' height={400}>
      <ComposedChart
        data={data}
      >
        <CartesianGrid strokeDasharray='3 3' />

        <XAxis
          fontFamily={chartsFontFamily}
          dataKey={xData}
        />
        {
          showingData.filter(serie => !serie.yAxisId).map((serie, index) =>
            <YAxis
              yAxisId={serie.id}
              key={serie.id}
              fontFamily={chartsFontFamily}
              domain={chartDomains}
              orientation={getYAxisOrientation(index)}
            />
          )
        }

        <Legend
          formatter={value =>
            <span>
              {getDescriptor(descriptors, value).description}
            </span>
          }
        />

        <Tooltip
          content={
            <CustomTooltip
              series={showingData}
              descriptors={descriptors}
              getDescriptor={getDescriptor}
              classes={classes}
            />
          }
        />

        {
          showingData.map(data =>
            <Line
              type='monotone'
              strokeWidth={2}
              yAxisId={data.yAxisId || data.id}
              dataKey={data.id}
              key={data.id}
              stroke={data.color}
              dot={data.id === 'ws10_avg' ? <CustomDot /> : undefined}
            />
          )
        }
      </ComposedChart>
    </ResponsiveContainer>
  )
}

WindVelocityChart.defaultProps = {
  data: [],
  showingData: [],
  descriptors: []
}

WindVelocityChart.propTypes = {
  descriptors: PropTypes.array,
  data: PropTypes.array,
  xData: PropTypes.string.isRequired,
  classes: PropTypes.object,
  showingData: PropTypes.array
}

export default WindVelocityChart
