import React from 'react'
import { Form as FinalForm } from 'react-final-form'
import PropTypes from 'prop-types'

import { RailsForm } from '@support/RailsForm'
import Form from './Form'

export const Edit = ({ model, payment_profiles, organization_id, o }) =>
  <RailsForm
    component={FinalForm}
    action='update'
    url={`/v4/o/${o}/organizations/${organization_id}/subscriptions/${model.id}`}
    successUrl={`/v4/o/${o}/organizations/${organization_id}/subscriptions`}
    initialValues={model}
    render={(props) => (
      <Form
        {...props}
        title={I18n.t('activerecord.attributes.v4/organization_account.subscriptions.actions.edit')}
        organization_id={organization_id}
        payment_profiles={payment_profiles}
        o={o}
      />
    )}
  />

Edit.propTypes = {
  model: PropTypes.object,
  organization_kinds: PropTypes.array
}

export default Edit
