import React, { Component } from 'react'

import { Button, Grid, Paper, Typography } from '@material-ui/core'
import ControlledFormField from '@ui/Fields/ControlledFormField'
import TextField from '@ui/Fields/TextField'
import TextFieldWithMask from '@ui/Fields/TextFieldWithMask'
import { Field } from 'react-final-form'

import Elo from '@ui/Icons/Elo'
import MasterCard from '@ui/Icons/MasterCard'
import Visa from '@ui/Icons/Visa'

import withStyles from '@material-ui/core/styles/withStyles'
import styles from '@theme/styles/forms'

import { getCardFlag, getTypeCard, blockCharacters } from '@models/account/payment_profiles'

class Form extends Component {
  render() {
    const {
      classes,
      actions,
      handleError,
      disableFields,
      isOnCheckoutScreen,
      organization_id,
      checkoutScreenStyle,
      title,
      values,
      o,
      handleSubmit
    } = this.props

    const currentCard = getCardFlag(values)

    return (
      <form className={classes.form} onSubmit={handleSubmit} data-testid='payment-profile-form'>
        <Paper className={isOnCheckoutScreen ? checkoutScreenStyle : classes.form__wrapper} elevation={Number(!!isOnCheckoutScreen)}>
          {
            !isOnCheckoutScreen &&
            <header className={classes.form__header}>
              <Typography component='h2' variant='h5'>
                {title}
              </Typography>
            </header>
          }
          <main className={classes.form__fields}>
            <div>
              <MasterCard />
              <Visa />
              <Elo />
            </div>
            <Grid
              container
              className={classes.form__field__wrapper}
              spacing={8}
            >
              <Grid item xs={12}>
                <ControlledFormField
                  component={TextField}
                  className={classes.form__fieldWithError}
                  margin='normal'
                  disabled={!!disableFields}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  variant='outlined'
                  label={I18n.t('activerecord.attributes.v4/organization_account.payment_profiles.card_number')}
                  name='card_number'
                  InputProps={{
                    endAdornment: currentCard,
                    inputProps: {
                      className: classes.form__input_number_no_arrows
                    }
                  }}
                  type='number'
                  required
                  onChange={() => blockCharacters('card_number')}
                />
              </Grid>
            </Grid>
            <div className={classes.form__field__wrapper}>
              <Field
                component={TextField}
                className={classes.form__fieldWithError}
                margin='normal'
                disabled={!!disableFields}
                fullWidth
                InputLabelProps={{ shrink: true }}
                variant='outlined'
                label={I18n.t('activerecord.attributes.v4/organization_account.payment_profiles.holder_name')}
                name='holder_name'
                required
              />
            </div>
            <Grid
              container
              spacing={16}
            >
              <Grid item xs={12} sm={6}>
                <Field
                  component={TextFieldWithMask}
                  className={classes.form__fieldWithError}
                  required
                  label={I18n.t('activerecord.attributes.v4/organization_account.payment_profiles.card_expiration')}
                  name='card_expiration'
                  fullWidth
                  mask='99/99'
                  InputLabelProps={{ shrink: true }}
                  margin='normal'
                  variant='outlined'
                  placeholder={I18n.t('activerecord.attributes.v4/organization_account.payment_profiles.month_year')}
                />
              </Grid>

              <Grid item xs={12} sm={6}>
                <ControlledFormField
                  component={TextField}
                  className={classes.form__fieldWithError}
                  margin='normal'
                  disabled={!!disableFields}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  variant='outlined'
                  label={I18n.t('activerecord.attributes.v4/organization_account.payment_profiles.card_cvv')}
                  name='card_cvv'
                  required
                  type='number'
                  inputProps={{
                    className: classes.form__input_number_no_arrows
                  }}
                  onChange={() => blockCharacters('card_cvv')}
                />
              </Grid>
            </Grid>
          </main>

          {
            !isOnCheckoutScreen &&
            <footer className={classes.form__actions}>
              <Button
                className={classes.form__action}
                color='primary'
                type='submit'
                variant='contained'
              >
                {title}
              </Button>

              <Button
                className={classes.form__action}
                color='secondary'
                href={`/v4/o/${o}/organizations/${organization_id}/payment_profiles`}
              >
                {I18n.t('actions.back')}
              </Button>
            </footer>
          }
        </Paper>

        {isOnCheckoutScreen && actions}
      </form>
    )
  }
}

export default withStyles(styles)(Form)
