import React from 'react'
import demanded_irrigation_realized from '@assets/images/demanded_irrigation_realized'
import demanded_irrigation_registered from '@assets/images/demanded_irrigation_registered'
import observations from '@assets/images/observations'
import phenologycal_phase_change from '@assets/images/phenologycal_phase'
import system_offline from '@assets/images/offline'
import ChartLegend from '@components/v4/irrigation/management/chartLegend'

const legends = [
  {
    label: 'events.registered_demanded_irrigation',
    icon: {
      isSvg: true,
      src: demanded_irrigation_registered
    }
  },
  {
    label: 'events.registered_realized_irrigation',
    icon: {
      isSvg: true,
      src: demanded_irrigation_realized
    }
  },
  {
    label: 'events.phenological_stage',
    icon: {
      isSvg: true,
      src: phenologycal_phase_change
    }
  },
  {
    label: 'events.equipment_status',
    icon: {
      isSvg: true,
      src: system_offline
    }
  },
  {
    label: 'events.observations',
    icon: {
      isSvg: true,
      src: observations
    }
  }
]

const EventsMapLegend = () => <ChartLegend legends={legends} />

export default EventsMapLegend
