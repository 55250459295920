import React from 'react'

import { Typography } from '@material-ui/core'

import validator from 'validator'

import Area from '@images/alerts/area_icon.svg'
import Alert from '@images/alerts/alert_icon.svg'

const whatsappValidate = values => {
  const errors = {}

  const requiredFields = ['name', 'identifier', 'locale']

  requiredFields.forEach(field => {
    if (!values[field]) errors[field] = I18n.t('validations.required')
  })

  if (!!values.identifier) {
    const phoneBrazil = /^((\+?([5]{2})))/g
    const isBrazil = phoneBrazil.test(values['identifier'])

    if (isBrazil) {
      const regExp = /^((\+?([5]{2}))?)?\s?(\(\d{2}\)|\d{2})?\s?\d{5}\-?\d{4}$/g
      const result = regExp.test(values['identifier'])
      if (!(result)) {
        errors['identifier'] = I18n.t('validations.invalid_cellphone')
      }
    }
    else {
      const phoneForeign = /^(\+)/g
      const isForeign = phoneForeign.test(values['identifier'])
      if (!(isForeign)) {
        const regExpression = /^\s?(\(\d{2}\)|\d{2})?\s?\d{5}\-?\d{4}$/g
        const results = regExpression.test(values['identifier'])
        if (!(results)) {
          errors['identifier'] = I18n.t('validations.invalid_cellphone')
        }
      }
    }
  }

  return errors
}

const emailValidate = values => {
  const errors = {}

  const requiredFields = ['name', 'identifier', 'locale']

  requiredFields.forEach(field => {
    if (!values[field]) errors[field] = I18n.t('validations.required')
  })

  if (!!values.identifier && !validator.isEmail(values.identifier)) errors['identifier'] = I18n.t('validations.invalid_email')

  return errors
}

export const getLabel = value =>
  value.area_name ? value.area_name : (!!value.contacts ? value.kind : value)

const getLabelAverage = (kind) => kind === 'weather_forecast' ? 'label_days_weather_forecast' : 'label_days'

export const getVariableLabel = (value, available_rules = []) => {
  let name = ''
  if (!_.isEmpty(available_rules)) {
    const rule = available_rules.find(rule => rule.name === value.name)
    name = rule ? I18n.t(`v4/alerts.available_rules.${rule.translation}`) : value.name
  }

  const label_average = `${I18n.t(`v4/alerts.alerts.variables.${getLabelAverage(value.kind)}`,
    {
      description: I18n.t(`v4/alerts.variable_names.${value.variable}`),
      rule: !!name ? name : I18n.t(`v4/alerts.available_rules.${value.name}`),
      value: value.value,
      unity: value.variable === variableWindSpeed_M_S ? variableWindSpeed_KM_H : value.unity,
      days: value.days
    })}`

  const label = `${I18n.t('v4/alerts.alerts.variables.label',
    {
      description: I18n.t(`v4/alerts.variable_names.${value.variable}`),
      rule: !!name ? name : I18n.t(`v4/alerts.available_rules.${value.name}`),
      value: value.value,
      unity: value.variable === variableWindSpeed_M_S ? variableWindSpeed_KM_H : value.unity,
    })}`

  return value.frequency == 'multiple_days' ? label_average : label
}

export const getLocales = () =>
  [
    {
      name: I18n.t('activerecord.attributes.v4/organization.countries.br'),
      id: 'pt-BR',
      idIcon: 'br'
    },
    {
      name: I18n.t('activerecord.attributes.v4/organization.countries.es'),
      id: 'es-MX',
      idIcon: 'es'
    },
    {
      name: I18n.t('activerecord.attributes.v4/organization.countries.us'),
      id: 'en',
      idIcon: 'us'
    }
  ]

export const getLanguages = () => ({
  ptBR: I18n.t('activerecord.attributes.v4/organization.countries.br'),
  esMX: I18n.t('activerecord.attributes.v4/organization.countries.es'),
  en: I18n.t('activerecord.attributes.v4/organization.countries.us')
})

export const validate = () => ({
  whatsapp: whatsappValidate,
  email: emailValidate
})

const days = [
  { id: 1, name: 1 },
  { id: 2, name: 2 },
  { id: 3, name: 3 },
  { id: 4, name: 4 },
  { id: 5, name: 5 },
  { id: 6, name: 6 },
  { id: 7, name: 7 }
]

export const getDays = () => days

export const getFieldType = (type) => ({ whatsapp: 'telephone', email: 'text' })[type]

export const getTimeLabel = () => {
  const defaultTime = '9:32:18'

  if (I18n.locale === 'en') {
    return `${I18n.t('v4/alerts.alerts.last_time')}: ${I18n.t('date_and_time.today')}, ${defaultTime} AM`
  } else {
    return `${I18n.t('v4/alerts.alerts.last_time')}: ${I18n.t('date_and_time.today')}, ${defaultTime}`
  }
}

const fields = [
  'alert_name', 'date', 'area', 'status'
]

export const getAlertsHeaders = () =>
  fields.map(field => ({
    title: I18n.t(`v4/alerts.alerts.${field}`),
    field
  }))

export const deserialize = (data = [], classes) => data.map((alert) => ({
  ...alert,
  date:
    <div>
      <Typography variant='body1'>{new Date(alert.triggered_in).toLocaleDateString(I18n.locale)}</Typography>
      <Typography variant='body1'>{new Date(alert.triggered_in).toLocaleTimeString(I18n.locale)}</Typography>
    </div>,
  area: alert.areas,
  status: (
    <Typography variant='body1' className={!alert.read && classes.Alert_not_viewed}>
      {
        alert.read
          ? I18n.t('v4/alerts.alerts.viewed')
          : I18n.t('v4/alerts.alerts.not_viewed')
      }
    </Typography>
  )
}))

export const getInitialValues = () => {
  const currentDate = new Date()
  currentDate.setMinutes(0)

  const finish = currentDate.toISOString()

  currentDate.setHours(0)
  currentDate.setDate(currentDate.getDate() - 7)
  const start = currentDate.toISOString()

  return {
    start_date: start,
    end_date: finish
  }
}

export const getHistoryIcon = (icon) =>
  historyIcons[icon]
    ? <img
      src={historyIcons[icon]}
      alt={icon}
    />
    : null

const historyIcons = {
  area: Area,
  alert: Alert
}

export const checkLocale = () =>
  (I18n.locale === 'pt-BR' || I18n.locale === 'es-MX')

export const blockCharactersLocale = name => {
  const characters = checkLocale() ? ['.'] : [',']

  document.getElementsByName(name)[0].addEventListener('keypress', (event) => {
    if (characters.includes(event.key)) {
      event.preventDefault()
      return ''
    }
  })
}

export const formatValue = (value = 0) => {
  if (!!value && (value.indexOf(',') !== -1 || value.indexOf('.') !== -1)) {
    const characterIndex = checkLocale() ? value.indexOf(',') : value.indexOf('.')
    const maximumDecimals = 4

    return value.substring(0, characterIndex + maximumDecimals)
  } else {
    return value
  }
}

const getReplace = (value) => ({
  'serialize': _.replace(value, ',', '.'),
  'deserialize': _.replace(value, '.', ','),
})

export const convertValueLocale = (variables, kind) => variables.map(variable => ({
  ...variable,
  value: checkLocale() ? getReplace(variable.value)[kind] : variable.value
}))

export const variableWindSpeed_KM_H = 'KM/h'

export const variableWindSpeed_M_S = 'ws1'

const conversionVariable = 3.6

const setConvertValue = (value) => ({
  'serialize': (value / conversionVariable).toFixed(2),
  'deserialize': (value * conversionVariable).toFixed(2)
})

export const convertVariableWindSpeed = (variables, kind) => variables.map(variable => ({
  ...variable,
  value: variable.variable === variableWindSpeed_M_S ? setConvertValue(variable.value)[kind] : variable.value
}))

export const setTranslateVariables = (variables = []) => variables.map(variable => ({
  ...variable,
  variable_description: I18n.t(`v4/alerts.variable_names.${variable.variable}`)
}))

export const setTranslateVariablesRules = (variables = []) => variables.map(variable => ({
  ...variable,
  name: I18n.t(`v4/alerts.available_rules.${variable.identifier}`)
}))

export const AlertCategories = [
  {
    id: 1,
    name: I18n.t('v4/alerts.new.steps.category.alerts_categories.agrometeorological'),
    value: 'agrometeorological'
  },
  {
    id: 2,
    name: I18n.t('v4/alerts.new.steps.category.alerts_categories.pests'),
    value: 'pests'
  },
  {
    id: 3,
    name: I18n.t('v4/alerts.new.steps.category.alerts_categories.diseases'),
    value: 'diseases'
  },
  {
    id: 4,
    name: I18n.t('v4/alerts.new.steps.category.alerts_categories.others'),
    value: 'others'
  },
];

export const convertAlertCategories = (id) => {
  return AlertCategories.find(alert => alert.id === id).value
}