import PropTypes from 'prop-types'

export const validate = values => {
  const fields = [
    'name', 'brand', 'category'
  ]

  const errors = {}

  fields.forEach(field => {
    if (!values[field]) errors[field] = I18n.t('validations.required')
  })

  return errors
}

export const otherHeaders = [
  { title: I18n.t('activerecord.attributes.v4/input.category'), field: 'category_text' },
]

export const otherInputShape = PropTypes.shape({
  name: PropTypes.string,
  brand: PropTypes.string,
  category: PropTypes.string,
  category_text: PropTypes.string,
  detail: PropTypes.string,
  kind: PropTypes.string
})
