import React from 'react'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import { Field } from 'react-final-form'

import ControlledFormField from '@ui/Fields/ControlledFormField'
import TextField from '@ui/Fields/TextField'
import styles from '@theme/styles/forms'
import { Grid } from '@material-ui/core'

import axios from 'axios'
import { blockCharacters } from '@models/account/payment_profiles'

const Form = ({
  classes,
  isOnCheckoutScreen,
  checkoutScreenStyle,
  disableFields,
  handleSubmit,
  title,
  controller,
  values
}) => {

  const setZipCode = async (zipcode) => {
    blockCharacters('zipcode')

    if (I18n.locale == 'pt-BR' && zipcode.length === 8) {
      try {
        const { data } = await axios.get(`https://viacep.com.br/ws/${values.zipcode}/json`)
        values.city = data.localidade ? data.localidade : ''
        values.state = data.uf ? data.uf : ''
        values.address = data.logradouro ? data.logradouro : ''
        values.neighborhood = data.bairro ? data.bairro : ''
        values.country = data.localidade ? I18n.t('activerecord.attributes.v4/organization_account.country_locale') : ''
      }
      catch (error) {
        console.error(error)
      }
    }
  }

  return (
    <form className={classes.form} onSubmit={handleSubmit} data-testid='billing_info-form'>
      <Paper className={isOnCheckoutScreen ? checkoutScreenStyle : classes.form__wrapper} elevation={Number(!!isOnCheckoutScreen)}>
        {
          !isOnCheckoutScreen && <header className={classes.form__header}>
            <Typography component='h2' variant='h5'>
              {title}
            </Typography>
          </header>
        }

        <main className={classes.form__fields}>
          <div className={classes.form__field__wrapper}>
            <ControlledFormField
              component={TextField}
              className={classes.form__fieldWithError}
              margin='normal'
              disabled={!!disableFields}
              fullWidth
              InputLabelProps={{ shrink: true }}
              variant='outlined'
              label={I18n.t('activerecord.attributes.v4/organization_account.registry_code')}
              name='registry_code'
              type='number'
              required
              InputProps={{
                inputProps: {
                  className: classes.form__input_number_no_arrows
                }
              }}
              onChange={() => blockCharacters('registry_code')}
            />
          </div>
          <div className={classes.form__field__wrapper}>
            <Field
              component={TextField}
              className={classes.form__fieldWithError}
              margin='normal'
              disabled={!!disableFields}
              fullWidth
              InputLabelProps={{ shrink: true }}
              variant='outlined'
              label={I18n.t('activerecord.attributes.v4/organization_account.legal_name')}
              name='legal_name'
              required
            />
          </div>
          <div className={classes.form__field__wrapper}>
            <Field
              component={TextField}
              className={classes.form__fieldWithError}
              fullWidth
              InputLabelProps={{ shrink: true }}
              label={I18n.t('activerecord.attributes.v4/organization_account.email')}
              margin='normal'
              name='email'
              type='email'
              variant="outlined"
              required
            />
          </div>
          <Grid
            container
            spacing={24}
          >
            <Grid item xs={4}>
              <ControlledFormField
                component={TextField}
                disabled={!!disableFields}
                onChange={setZipCode}
                className={classes.form__fieldWithError}
                margin='normal'
                InputLabelProps={{ shrink: true }}
                fullWidth
                variant='outlined'
                label={I18n.t('activerecord.attributes.v4/organization_account.zipcode')}
                name='zipcode'
                type='number'
                required
                InputProps={{
                  inputProps: {
                    className: classes.form__input_number_no_arrows
                  }
                }}
              />
            </Grid>
            <Grid item xs={8}>
              <Field
                component={TextField}
                className={classes.form__fieldWithError}
                disabled={!!disableFields}
                margin='normal'
                fullWidth
                InputLabelProps={{ shrink: true }}
                variant='outlined'
                label={I18n.t('activerecord.attributes.v4/organization_account.address')}
                name='address'
                required
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={24}
          >
            <Grid item xs={4}>
              <Field
                component={TextField}
                className={classes.form__fieldWithError}
                disabled={!!disableFields}
                margin='normal'
                fullWidth
                InputLabelProps={{ shrink: true }}
                variant='outlined'
                label={I18n.t('activerecord.attributes.v4/organization_account.number')}
                name='number'
                required
              />
            </Grid>
            <Grid item xs={8}>
              <Field
                component={TextField}
                className={classes.form__fieldWithError}
                disabled={!!disableFields}
                margin='normal'
                fullWidth
                InputLabelProps={{ shrink: true }}
                variant='outlined'
                label={I18n.t('activerecord.attributes.v4/organization_account.additional_details')}
                name='additional_details'
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={24}
          >
            <Grid item xs={6}>
              <Field
                component={TextField}
                className={classes.form__fieldWithError}
                disabled={!!disableFields}
                margin='normal'
                fullWidth
                InputLabelProps={{ shrink: true }}
                variant='outlined'
                label={I18n.t('activerecord.attributes.v4/organization_account.neighborhood')}
                name='neighborhood'
                required
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={TextField}
                className={classes.form__fieldWithError}
                disabled={!!disableFields}
                margin='normal'
                fullWidth
                InputLabelProps={{ shrink: true }}
                variant='outlined'
                label={I18n.t('activerecord.attributes.v4/organization_account.city')}
                name='city'
                required
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={24}
          >
            <Grid item xs={6}>
              <Field
                component={TextField}
                className={classes.form__fieldWithError}
                disabled={!!disableFields}
                margin='normal'
                InputLabelProps={{ shrink: true }}
                fullWidth
                variant='outlined'
                label={I18n.t('activerecord.attributes.v4/organization_account.state')}
                name='state'
                required
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={TextField}
                className={classes.form__fieldWithError}
                disabled={!!disableFields}
                margin='normal'
                fullWidth
                InputLabelProps={{ shrink: true }}
                variant='outlined'
                label={I18n.t('activerecord.attributes.v4/organization_account.country')}
                name='country'
                required
              />
            </Grid>
          </Grid>
        </main>

        {
          !isOnCheckoutScreen &&
          <footer className={classes.form__actions}>
            <Button
              className={classes.form__action}
              color='primary'
              type='submit'
              variant='contained'
            >
              {title}
            </Button>
          </footer>
        }
      </Paper>

      {isOnCheckoutScreen && controller}
    </form>
  )
}

export default withStyles(styles)(Form)
