import React from 'react'

import { Typography, withStyles } from '@material-ui/core'

import PreviewSetting from '../PreviewSetting'
import Select from '@ui/Fields/Select'
import styles from '@theme/styles/forms'

const Header = ({
  classes,
  forecastList,
  handleSelectedPreviewMode,
  handleSelectedForecastPoint,
  previewMode,
  selectedForecastIndex
}) =>
  <header>
    <Typography className={classes.Header_title} component='h2' variant='h5'>
      {I18n.t('v4/weather.dashboard.title')}
    </Typography>

    <Typography component='h2' variant='h6'>
      {I18n.t('v4/weather.dashboard.visualization')}
    </Typography>

    <div className={classes.Header_wrapper_select}>
      <PreviewSetting
        previewMode={previewMode}
        handleSelectedPreviewMode={handleSelectedPreviewMode}
      />

      <div className={classes.Header_select}>
        <Select
          className={classes.form__fieldWithError}
          InputLabelProps={{ shrink: true, required: true }}
          label={I18n.t('v4/organization.products.forecast.forecast_point.one')}
          name='forecast_point'
          margin='normal'
          fields={{ id: 'index', name: 'name' }}
          data={forecastList}
          fullWidth
          variant='outlined'
          onChange={(event) => handleSelectedForecastPoint(event.target.value)}
          required
          value={selectedForecastIndex}
        />
      </div>
    </div>
  </header>

const CustomStyles = theme => ({
  ...styles,
  Header_wrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  Header_title: {
    marginBottom: theme.spacing.unit * 2
  },
  Header_wrapper_select: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  Header_select: {
    width: '180px'
  }
})

export default withStyles(CustomStyles)(Header)
