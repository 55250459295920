import React from 'react'
import { Typography } from '@material-ui/core'
import DefaultColorsForm from './DefaultColorsForm'

import withStyles from '@material-ui/core/styles/withStyles'
import styles from './style'

const PrimaryTextForm = ({ classes, color, updateColor }) =>
  <DefaultColorsForm
    classes={classes}
    title={I18n.t('v4/tenant.styles.primary_text_color')}
    label={I18n.t('v4/tenant.styles.colors.one')}
    color={color}
    updateColor={updateColor}
  >
    <Typography component='h2' variant='h5' style={{ textAlign: 'center' }}>
      {I18n.t('v4/tenant.styles.title')}
    </Typography>
  </DefaultColorsForm>

export default withStyles(styles)(PrimaryTextForm)
