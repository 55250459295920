import React, { useState } from 'react'

import { Typography, CircularProgress, Tabs, Tab } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'

import styles from '@styles/indexPage'

import Select from '@ui/Fields/Select'

import CropContent from './CropContent'
import ProductsContent from './ProductsContent'

import { useMenusStatus, MENUS } from '@contexts/MenusStatusProvider'
import { useHttpService } from '@contexts/HttpServiceProvider'
import { useRouter } from '@contexts/RouterProvider'
import { useSessionData } from '@contexts/SessionDataProvider'

import InfoMenuWrapper from '../InfoMenuWrapper'
import EditArea from './EditArea'
import Form from '@ui/Form'
import CancelHarvest from './CancelHarvest'
import moment from 'moment'
import {IntercomAPI} from '@utils/IntercomAPI'

const AreaInfoMenu = ({
  classes,
  area,
  areas,
  cultures,
  handleAreaSelect,
  loading,
  setLoading,
  handleError,
  trialInfo,
  handleEditAreaMenu
}) => {
  const router = useRouter()

  if (_.isEmpty(area)) return null

  const [selectedTab, setSelectedTab] = useState(0)
  const [dialog, setDialog] = useState({
    isOpen: false,
    title: '',
    component: '',
    selectedAction: ''
  })

  const data = area.data

  const { menus, toggleMenu } = useMenusStatus();
  const open = menus[MENUS.mapAreaMenu]

  const { apiRequest } = useHttpService()
  const { currentOrganization } = useSessionData()

  const handleChange = selectedTab => {
    setSelectedTab(selectedTab)
  }


  const handleCropSubmit = async (values) => {
    const crop = {
      ...values,
      area_id: area.id
    }

    try {
      setLoading()

      await apiRequest.post('crops', crop)

      handleAreaSelect(area.id, true)
    }
    catch (error) {
      handleError(error)
    }

    setLoading(false)
  }

  const dialogHandler = (action) => {
    const { component, title } = actions()[action]

    if (component) {
      setDialog({
        ...dialog,
        selectedAction: action,
        isOpen: true,
        component,
        title
      })
    }
  }
  const handleClose = () => {
    toggleMenu(MENUS.mapAreaMenu, false)
  }

  const handleChangeState = async (action, values) => {
    try {
      await apiRequest.put(`/crops/${data.crop.id}?organization_id=${currentOrganization.id}`, {
        [action]: values
      })
      setDialog({
        isOpen: false,
        title: '',
        component: '',
        selectedAction: ''
      })
      handleAreaSelect(area.id, true)
    }

    catch (error) {
      console.log('error')
      handleError(error)
    }
  }

  const getMinDate = (action) => {
    if (action === 'editDate' && data.crop.previous_stage) {
      const minDate = new Date(data.crop.previous_stage.finished_at)
      return (minDate.setDate(minDate.getDate() + 1))
    }
    if (action === 'advance') {
      const minDate = new Date(data.crop.current_stage.started_at)
      return (minDate.setDate(minDate.getDate() + 1))
    }
  }

  const actions = (stage, onClose, minDate) => ({
    harvest: {
      title: I18n.t('v4/area.dashboard.phenological_stages.actions.harvest.title'),
      component: <Form
        action={I18n.t('v4/area.dashboard.phenological_stages.actions.harvest.title')}
        handler={(values) => {
          handleChangeState('_harvest', { finished_at: values.date, yield_value: values.yield, yield_unity: values.unity })
          IntercomAPI('trackEvent', 'Harvest Card - Used harvest button')
        }
        }
        content={I18n.t('v4/area.dashboard.phenological_stages.actions.harvest.content')}
        onCancel={() => setDialog({ ...dialog, isOpen: false })}
        intercomTarget="Harvest Card"
        selectedAction
      />
    },
    editDate: {
      title: I18n.t('v4/area.dashboard.phenological_stages.actions.edit.title'),
      component: <Form
        action={I18n.t('v4/area.dashboard.phenological_stages.actions.edit.title')}
        handler={(values) =>
          handleChangeState('_update_stage_date', { order: data.crop.current_stage.order, started_at: values.date })
        }
        onCancel={() => setDialog({ ...dialog, isOpen: false })}
        minDate={getMinDate('editDate')}
      />
    },
    advance: {
      title: I18n.t('v4/area.dashboard.phenological_stages.actions.advance.title'),
      component: <Form
        action={I18n.t('v4/area.dashboard.phenological_stages.actions.advance.title')}
        defaultDate={data.crop.expected_phase_change === 0 ? data.crop.current_stage.expected_stage_finish : new Date()}
        handler={(values) => {
          handleChangeState('_forward_stage', { finished_at: values.date })
          IntercomAPI('trackEvent', 'Advance Card - Used advance phase button')
        }}
        content={I18n.t('v4/area.dashboard.phenological_stages.actions.advance.content')}
        onCancel={() => setDialog({ ...dialog, isOpen: false })}
        minDate={getMinDate('advance')}
        intercomTarget="Pheno. Stage Card"
      />
    },
    cancel: {
      title: I18n.t('v4/area.dashboard.phenological_stages.actions.cancel.title'),
      component: <CancelHarvest
        onCancel={() => setDialog({ ...dialog, isOpen: false })}
        handler={() => handleChangeState('_cancel', true)}
      />
    }
  })

  return (
    <InfoMenuWrapper
      open={open}
      onClose={() => toggleMenu(MENUS.mapAreaMenu, false)}
    >
      {open && (
        <div className={classes.Area_Container}>
          <div className={classes.Area_select_wrapper}>
            <Select
              className={classes.Area_Select}
              variant="outlined"
              value={area.id || ""}
              onChange={(event) => handleAreaSelect(event.target.value)}
              data={areas}
              fields={{ id: "id", name: "name" }}
              name="name"
              InputLabelProps={{ shrink: true }}
              label={I18n.t("activerecord.models.v4/area.one")}
              data-intercom-target="areacard_crop_click_areas"
            />

            <EditArea
              handleEditAreaMenu={handleEditAreaMenu}
              loading={loading}
            />
          </div>

          <Tabs
            className={classes.tabs}
            value={selectedTab}
            indicatorColor="primary"
            centered
          >
            <Tab
              label={I18n.t('activerecord.models.v4/product.other')}
              key={0}
              onClick={() => handleChange(0)}
              data-intercom-target="areacard_click_productbutton"
            />
            <Tab
              label={I18n.t('activerecord.models.v4/crop.other')}
              key={1}
              onClick={() => handleChange(1)}
              icon={(data.crop && data.crop.expected_phase_change === 0) ? <div className={classes.Alert_Received} /> : null}
              data-intercom-target="areacard_click_cropbutton"
            />
          </Tabs>
          {!loading ? (
            <>
              <TabPanel value={selectedTab} index={0}>
                <ProductsContent
                  irrigationManagement={data.irrigation_management}
                  alertsCount={data.alerts_count}
                  classes={classes}
                  areas={areas}
                  trialInfo={trialInfo}
                  currentArea={area}
                />
              </TabPanel>

              <TabPanel
                value={selectedTab}
                index={1}
              >
                <CropContent
                  area={area}
                  handleCropSubmit={handleCropSubmit}
                  crop={data.crop}
                  cultures={cultures}
                  classes={classes}
                  router={router}
                  dialog={dialog}
                  setDialog={setDialog}
                  dialogHandler={dialogHandler}
                />
              </TabPanel>
            </>
          ) : (
            <div className={classes.Area_Loading}>
              <CircularProgress size={80} />
            </div>
          )}
        </div>
      )}
    </InfoMenuWrapper>
  );
}

const TabPanel = ({ children, value, index, ...other }) => {
  return value === index && (
    <Typography
      component="div"
      role="tabpanel"
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      <div>{children}</div>
    </Typography>
  )
}

AreaInfoMenu.defaultProps = {
  data: {
    crop: {}
  },
  cultures: [],
  areas: []
}

const Styles = theme => ({
  ...styles(theme),
  Area_Container: {
    padding: theme.spacing.unit * 2
  },
  Area_Select: {
    margin: theme.spacing.unit,
    width: '85%',
    marginTop: theme.spacing.unit * 2
  },
  Area_Product_Wrapper_Title: {
    display: 'flex',
    padding: theme.spacing.unit,
    alignItems: 'center',
  },
  Area_Product_Title: {
    display: 'flex',
    paddingLeft: theme.spacing.unit,
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '308px'
  },
  Area_Product_Title__Info: {
    fontSize: '13px'
  },
  Area_Infos: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing.unit * 0.5
  },
  Area_Info: {
    fontSize: '13px'
  },
  Area_Product_Phase: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    width: '100%'
  },
  Area_Loading: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing.unit * 6.25
  },
  Area_ProgressBar_Wrapper: {
    margin: `0px ${theme.spacing.unit + 2}px 0px ${theme.spacing.unit + 2}px`,
    background: theme.palette.gray.main
  },
  Area__ProgressBar: {
    background: theme.palette.primary.main,
    height: '10px'
  },
  Area_Icon: {
    width: '25px',
    height: '25px'
  },
  Area_Last_Stage: {
    marginRight: theme.spacing.unit + 2,
    display: 'flex',
    justifyContent: 'flex-end',
    color: '#757575',
    fontSize: '11px'
  },
  NoProductsMessage: {
    padding: theme.spacing.unit * 2,
    textAlign: 'center'
  },
  NoProductsMessage_Button: {
    width: '70%'
  },
  NoProductsMessage_Icon: {
    marginLeft: theme.spacing.unit
  },
  Area_select_wrapper: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing.unit
  },
  Wrapper_actions: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing.unit,
    width: '100%',
    gap: '5px'
  },
  Edit_phenologicalStage: {
    cursor: 'pointer'
  },
  Wrapper_edit: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px'
  },
  Alert_Received: {
    background: theme.palette.cards.red,
    borderRadius: '100%',
    width: `${theme.spacing.unit}px`,
    height: `${theme.spacing.unit}px`,
    position: 'absolute',
    right: '0px',
    '@media (min-width: 960px)': {
      right: 30,
    },
  },
})

export default withStyles(Styles)(AreaInfoMenu)
