import React from 'react'

import { Typography, withStyles, IconButton } from '@material-ui/core'
import EditIcon from '@material-ui/icons/Edit'
import DashboardIcon from '@images/irrigation/card_dashboard_button.svg'

import CardContent from './CardContent'
import CardBottomPanel from './CardBottomPanel'
import { useRouter } from '@contexts/RouterProvider'

import styles from './CardStyles'

const Card = ({ classes, data }) => {
  const cardClass = `${classes.Card} ${classes[`Card__${data.hydricStatus || 'no_data'}`]}`
  const primaryButtonClass = `${classes.Card_ButtonPrimary} ${classes[`Card_ButtonPrimary__${data.hydricStatus || 'no_data'}`]}`
  const { visit } = useRouter()
  const needIrrigation = data.content.find(data => data.name === 'di').value > 0
  
  return (
    <div className={cardClass}>
      <div className={classes.Card_TitleContainer}>
        <Typography className={classes.Card_Title} variant='h5'>
          {data.area}
        </Typography>

        <IconButton
          onClick={() => visit(`irrigation_managements/${data.id}/edit`)}
          title={I18n.t('v4/irrigation_management.edit')}
        >
          <EditIcon />
        </IconButton>
      </div>

      <CardContent data={data.content} />
      <CardBottomPanel hydricStatus={data.hydricStatus} needIrrigation={needIrrigation} />

      <div className={classes.Card_ButtonsContainer}>
        <button className={primaryButtonClass} onClick={() => visit(`irrigation_managements/${data.id}/dashboard`)}>
          <img className={classes.Card_ButtonIcon} src={DashboardIcon} alt='dashboard' />

          {I18n.t('v4/irrigation_management.graphic')}
        </button>
      </div>
    </div>
  )
}

export default withStyles(styles)(Card)
