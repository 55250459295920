import React from 'react'

import { Tab, Tabs, withStyles } from '@material-ui/core'

import { tabs } from '@models/equipment/automatic'

const HoursTabs = ({ classes, hourtab, handleChangeTabs }) =>
  <Tabs
    value={hourtab}
    indicatorColor='primary'
  >
    {
      tabs.map((tab, index) =>
        <Tab
          classes={{
            root: classes.Tabs_root,
            labelContainer: classes.Tab_label,
            tabIndicador: classes.Tab_indicador
          }}
          label={tab.title}
          key={`Tab_${tab.key}`}
          onClick={() => handleChangeTabs(tab, index)}
          data-intercom-target={tab.intercomTag}
        />
      )
    }
  </Tabs>

const Styles = theme => ({
  Tabs_root: {
    minWidth: 90
  },
  Tab_label: {
    padding: theme.spacing.unit * 2
  },
  Tab_indicador: {
    width: 110
  }
})

export default withStyles(Styles)(HoursTabs)
