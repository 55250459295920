import React from 'react'

import { Grid, Button, withStyles } from '@material-ui/core'

import Field from '@ui/Fields/Field'

import styles from '@theme/styles/forms'

const Form = ({
  classes,
  handleSubmit
}) => (
  <form className={classes.form} onSubmit={handleSubmit}>
    <Field
      fullWidth
      label={I18n.t(`activerecord.attributes.v4/organization_products.forecast.forecast_points.name`)}
      name='name'
      required
    />

    <Grid container spacing={24}>
      <Field
        gridItem
        inputProps={{ step: '0.0001' }}
        label={I18n.t('activerecord.attributes.v4/organization_products.forecast.forecast_points.lat')}
        name='lat'
        placeholder={I18n.t('v4/forecast.configuration.placeholder')}
        type='number'
        required
      />

      <Field
        gridItem
        inputProps={{ step: '0.0001' }}
        label={I18n.t('activerecord.attributes.v4/organization_products.forecast.forecast_points.lon')}
        name='lon'
        placeholder={I18n.t('v4/forecast.configuration.placeholder')}
        required
        type='number'
      />
    </Grid>

    <footer className={classes.form__actions}>
      <Button
        className={classes.form__action}
        color='primary'
        type='submit'
        variant='contained'
      >
        {I18n.t('v4/forecast.configuration.update')}
      </Button>
    </footer>
  </form>
)


export default withStyles(styles)(Form)
