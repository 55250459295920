import React from 'react'
import { Grid } from '@material-ui/core'
import Charts from './charts/Charts'
import { deserializeData } from '@models/weather/dashboard'

const DashboardCharts = ({ charts, data, descriptors }) =>
  <Grid
    container
    direction='row'
    justify='space-around'
  >
    {
      charts.map(chart =>
        <Grid item xs={24} sm={6} key={chart.id}>
          <Charts
            key={chart.id}
            chart={chart}
            data={deserializeData(data)}
            xData='forecast_date'
            series={chart.rows}
            descriptors={descriptors}
          />
        </Grid>
      )}
  </Grid>
export default DashboardCharts
