export default () => ({
  Preview__header: {
    height: '48px',
    padding: '5px 10px',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    backgroundColor: 'white',
    color: 'black'
  },
  Preview__header_title: {
    color: 'black'
  }
})
