import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'
import Checkbox from '@material-ui/core/Checkbox'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import MenuItem from '@material-ui/core/MenuItem'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

import { Field } from 'react-final-form'

import TextField from '../../ui/Fields/TextField'
import Select from '../../ui/Fields/Select'

import styles from '../../../../v4/theme/styles/forms'

export const validate = values => {
  const fields = ['part_number', 'part_name', 'hardware_integration_id']
  const errors = {}

  fields.forEach(field => {
    if (!values[field]) errors[field] = I18n.t('validations.required')
  })

  return errors
}

const kinds = [
  {
    id: 'String',
    name: 'String'
  },
  {
    id: 'Key',
    name: 'Key'
  }
]

class Form extends Component {
  state = {
    customFields: [],
    checkedObsolete: false
  }

  componentDidMount() {
    const { values } = this.props
    this.setState({
      customFields: this.getLabel(values, 'kind')
    })

  }

  getIndex = (value) => value.replace(/\D/g, '')

  applyRegex = (label, kind) => {
    const index = this.getIndex(label)

    if (!index) {
      return false
    } else {
      return new RegExp(`custom_field${index}_${kind}`).test(label)
    }
  }

  getLabel = (data, kind) => Object.keys(data).filter(label => this.applyRegex(label, kind))

  addCustomField = label => {
    let customFields = this.state.customFields
    let len = customFields.length
    { len < 4 && customFields.push(`Custom Field ${len + 1}`) }
    this.setState({ customFields })
  }

  handleChange = checkbox => event => {
    this.setState({ ...this.state, [checkbox]: event.target.checked })
  }

  render() {
    const {
      already_in_use,
      classes,
      connectivities,
      handleSubmit,
      manufacturers,
      pristine,
      reset,
      submitting,
      title,
      values,
      o
    } = this.props

    values.obsolete = this.state.checkedObsolete

    return (
      <form className={classes.form} onSubmit={handleSubmit} data-testid="part-numbers-form" >
        <Paper className={classes.form__wrapper} elevation={0}>
          <header className={classes.form__header}>
            <Typography component='h2' variant='h5'>
              {title}
            </Typography>
          </header>
          <main className={classes.form__fields}>
            <div className={classes.form__field__wrapper}>
              <Field
                component={Select}
                className={classes.form__fieldWithError}
                InputLabelProps={{ shrink: true, required: true }}
                label={I18n.t('v4/part_number.form.manufacturer')}
                name='v4_manufacturer_id'
                fields={{ id: 'id', name: 'name' }}
                data={manufacturers}
                fullWidth
                margin='normal'
                variant="outlined"
                required
              >
              </Field>
            </div>
            <div className={classes.form__field__wrapper}>
              <Field
                component={TextField}
                className={classes.form__fieldWithError}
                margin='normal'
                fullWidth
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                label={I18n.t('v4/part_number.form.part_number')}
                name='part_number'
                required
                disabled={already_in_use}
              />
            </div>
            <div className={classes.form__field__wrapper}>
              <Field
                component={TextField}
                className={classes.form__fieldWithError}
                margin='normal'
                fullWidth
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                label={I18n.t('v4/part_number.form.manufacturer_part_number')}
                name='manufacturer_part_number'
                disabled={already_in_use}
              />
            </div>
            <div className={classes.form__field__wrapper}>
              <Field
                component={TextField}
                className={classes.form__fieldWithError}
                fullWidth
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                label={I18n.t('v4/part_number.form.part_name')}
                margin='normal'
                name='part_name'
                required
              />
            </div>
            <div className={classes.form__field__wrapper}>
              <Field
                component={Select}
                className={classes.form__fieldWithError}
                InputLabelProps={{ shrink: true, required: true }}
                label={I18n.t('v4/part_number.form.label_connectivity')}
                name='hardware_integration_id'
                fields={{ id: 'id', name: 'product' }}
                data={connectivities}
                fullWidth
                margin='normal'
                variant="outlined"
                required
              >
              </Field>
            </div>
            <div className={classes.form__field__wrapper}>
              <Grid container justify='space-between'>
                <Grid item xs={12} md={3}>
                  <Field
                    component={TextField}
                    className={classes.form__fieldWithError}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    label={`${I18n.t('v4/part_number.form.identifier')} ${I18n.t('v4/part_number.form.pt_label')}`}
                    fullWidth
                    margin='normal'
                    name="identifier_label_translations[pt-BR]"
                    required
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Field
                    component={TextField}
                    className={classes.form__fieldWithError}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    label={`${I18n.t('v4/part_number.form.identifier')} ${I18n.t('v4/part_number.form.en_label')}`}
                    margin='normal'
                    name="identifier_label_translations[en]"
                    fullWidth
                    required
                  />
                </Grid>
                <Grid item xs={12} md={3}>
                  <Field
                    component={TextField}
                    className={classes.form__fieldWithError}
                    InputLabelProps={{ shrink: true }}
                    variant="outlined"
                    label={`${I18n.t('v4/part_number.form.identifier')} ${I18n.t('v4/part_number.form.es_label')}`}
                    margin='normal'
                    name="identifier_label_translations[es-MX]"
                    fullWidth
                    required
                  />
                </Grid>
              </Grid>
            </div>
            <div className={classes.Form_Checkbox}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={this.state.checkedObsolete}
                    onChange={this.handleChange('checkedObsolete')}
                    value="checkedObsolete"
                    inputProps={{
                      'aria-label': 'primary checkbox',
                    }}
                  />
                }
                label={I18n.t('v4/part_number.form.obsolete')}
              />
            </div>
            <div className={classes.Form_Checkbox}>
              <Button variant="outlined" className={classes.button} onClick={this.addCustomField} color="primary">
                {I18n.t('v4/part_number.form.add_custom_field')}
              </Button>
            </div>
            {this.state.customFields.map((field, i) => (
              <div className={classes.form__field__wrapper} key={field}>
                <Grid container spacing={3} justify='space-between'>
                  <Grid item xs={3} className={classes.Form_Grid}>
                    <Field
                      component={TextField}
                      className={classes.form__fieldWithError}
                      InputLabelProps={{ shrink: true }}
                      variant='outlined'
                      label={I18n.t('v4/part_number.form.pt_label')}
                      margin='normal'
                      name={`custom_field${i + 1}_label_translations[pt-BR]`}
                      data-testid={`custom-field-ptbr-${i}`}
                    />
                  </Grid>
                  <Grid item xs={3} className={classes.Form_Grid}>
                    <Field
                      component={TextField}
                      className={classes.form__fieldWithError}
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      label={I18n.t('v4/part_number.form.en_label')}
                      margin='normal'
                      name={`custom_field${i + 1}_label_translations[en]`}
                      data-testid={`custom-field-en-${i}`}
                    />
                  </Grid>
                  <Grid item xs={3} className={classes.Form_Grid}>
                    <Field
                      component={TextField}
                      className={classes.form__fieldWithError}
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      label={I18n.t('v4/part_number.form.es_label')}
                      margin='normal'
                      name={`custom_field${i + 1}_label_translations[es-MX]`}
                      data-testid={`custom-field-esmx-${i}`}
                    />
                  </Grid>

                  <Grid item xs={3} className={classes.Form_Grid}>
                    <Field
                      component={Select}
                      className={classes.form__fieldWithError}
                      InputLabelProps={{ shrink: true }}
                      variant="outlined"
                      label={I18n.t('v4/part_number.form.kind')}
                      margin='normal'
                      data={kinds}
                      fields={{ id: 'id', name: 'name' }}
                      fullWidth
                      name={`custom_field${i + 1}_kind`}
                      defaultValue={'String'}
                    />
                  </Grid>
                </Grid>
              </div>
            ))}
          </main>
          <footer className={classes.form__actions}>
            <Button
              className={classes.form__action}
              color='primary'
              type='submit'
              variant='contained'
            >
              {title}
            </Button>

            <Button
              className={classes.form__action}
              color='secondary'
              href={`/v4/o/${o}/admin/part_number_registries`}
            >
              {I18n.t('v4/part_number.buttons.back')}
            </Button>
          </footer>
        </Paper>
      </form>
    )
  }
}

Form.propTypes = {
  title: PropTypes.string.isRequired
}

Form.defaultProps = {
  manufacturers: []
}

const Styles = theme => ({
  ...styles(theme),
  Form_Grid: {
    '&:not(:first-child)': {
      paddingLeft: theme.spacing.unit * 2
    }
  },
  Form_Checkbox: {
    marginBottom: theme.spacing * 3.75
  }
})

export default withStyles(Styles)(Form)
