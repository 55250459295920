import React, { useState, useEffect } from 'react'

import L from 'leaflet'
import 'leaflet.sync'

import { Grid, withStyles } from '@material-ui/core'

import { useMenusStatus } from '@contexts/MenusStatusProvider'

import { namesMapA, namesMapB } from '@models/comparison/maps'
import { maxZoom } from '@models/map'

import Map from './Map'

const Maps = ({ areas, centerCoord, classes, loading, zoom }) => {
  const { menus } = useMenusStatus()
  const { area: selectedAreaMapA, map: mapA } = menus['satelliteImageryProductA']
  const { area: selectedAreaMapB, map: mapB } = menus['satelliteImageryProductB']

  const options = {
    center: centerCoord,
    zoom: zoom,
    minZoom: 2.5,
    maxZoom: maxZoom,
    zoomControl: true,
    maxBounds: [[-90, -180], [90, 180]
    ]
  }

  useEffect(() => {    
    if(!!mapA && !!mapB) {
      mapA.sync(mapB)
      mapB.sync(mapA)
    }
  }, [mapA, mapB])

  useEffect(() => {
    const areaA = selectedAreaMapA
    const areaB = selectedAreaMapB

    if (!!areaA && !!areaB) {
      areaA !== areaB
        ? (mapB.unsync(mapA) && mapA.unsync(mapB))
        : (mapA.sync(mapB) && mapB.sync(mapA))
    }
  }, [selectedAreaMapA, selectedAreaMapB])

  return (
    <Grid container spacing={0} className={classes.Map_container}>
      <Grid item xs={6} className={classes.Map_wrapper}>
        <Map
          loading={loading}
          names={namesMapA}
          id='comparison-map-a'
          areas={areas}
          options={options}
        />
      </Grid>

      <Grid item xs={6} className={classes.Map_wrapper}>
        <Map
          loading={loading}
          names={namesMapB}
          id='comparison-map-b'
          areas={areas}
          options={options}
        />
      </Grid>
    </Grid>
  )
}

const styles = () => ({
  Map_wrapper: {
    '&:first-child': {
      paddingRight: 5
    },
    '&:last-child': {
      paddingLeft: 5
    }
  },
  Map_container: {
    marginTop: 65
  }
})

export default withStyles(styles)(Maps)
