import React from 'react'
import { Form as FinalForm } from 'react-final-form'

import { RailsForm } from '@support/RailsForm'
import httpService from '@services/httpService'

import Form from './Form'

const FormWrapper = ({ areas, onCancel, handleError, organization_id }) => (
  <RailsForm
    component={FinalForm}
    handleSubmit={data => handleSubmit(data, onCancel, handleError, organization_id)}
    render={props =>
      <Form
        {...props}
        areas={areas.filter(area => area.irrigation_system_id)}
        onCancel={onCancel}
      />
    }
  />
)

const handleSubmit = (data, onCancel, handleError, organization_id) => {
  _.forOwn(data, async (areaData, key) => {
    if (areaData.title !== '' && areaData.description !== '') {
      try {
        const areaId = areaData.areaId ? areaData.areaId : key.split('_')[1]

        await httpService.post(`/api/v4/areas/${areaId}/notes`, areaData, { params: { organization_id } })
      } catch (err) {
        handleError(err)
      }
    }
  })

  onCancel()
}


FormWrapper.defaultProps = {
  areas: [],
  onCancel: () => { },
  handleError: () => { }
}

export default FormWrapper
