import React from 'react'

import {
  ComposedChart,
  Brush,
  ReferenceArea,
  ReferenceLine,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'

import Card from '@material-ui/core/Card';
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './styles'

import IrrigationChartLegend from '../irrigationChart/irrigationChartLegend'
import ChartsGenerator from './ChartsGenerator/ChartsGenerator'

import updateIfSameAttribute from '@services/updateIfSameAttribute'
import { getArrayMaxIndexMinus } from '@models/irrigation_managements/dashboard'
import { chartsFontFamily } from '@models/chart'
import PhenologicalStageReferenceLabel from './PhenologicalStageReferenceLabel'

const addHideAttributeToData = chartSeries =>
  chartSeries.map(serie => ({
    ...serie,
    hide: serie.hide || false
  }))

const IrrigationChart = ({
  classes,
  irrigationData,
  areaChartsSeries,
  chartSeries: nonAreaChartsSeries,
  CustomTooltip,
  selectedVisualization,
  dateVisualization,
  phenologicalStages,
  visualizations
}) => {
  const [chartSeries, setChartsSeries] = React.useState(addHideAttributeToData(nonAreaChartsSeries))

  const toggleChartSerie = id => {
    setChartsSeries(
      chartSeries.map(
        updateIfSameAttribute(['id', id], serie => ({
          ...serie,
          hide: !serie.hide
        }))
      )
    )
  }

  const today = new Date()
  const tomorrow = new Date()
  const maximumDates = 100
  tomorrow.setDate(tomorrow.getDate() + 1)
  const isCropView = view => visualizations[view] === 'crop'
  const todayData = irrigationData.find(data => data.date === today.toLocaleDateString())
  const referenceX1 = dateVisualization === 'date'
    ? irrigationData.length > maximumDates
      ? tomorrow.toLocaleDateString().slice(0, 5)
      : tomorrow.toLocaleDateString()
    : todayData
      ? todayData.dap + 1
      : null

  if (irrigationData.length > maximumDates) {
    irrigationData.forEach(data => data.date = data.date.slice(0, 5))
  }

  return <div className={classes.irrigationChart}>
    <Card >
      <ResponsiveContainer width='100%' height={400}>
        <ComposedChart
          data={irrigationData}
          syncId='irrigation'
          margin={{ top: 10, right: 20, left: 0, bottom: 0 }}
          isAbove
        >
          <XAxis
            fontFamily={chartsFontFamily}
            tick={{ fontSize: 15 }}
            dataKey={dateVisualization}
          />
          <YAxis
            domain={[0, 'dataMax']}
            fontFamily={chartsFontFamily}
            label={{
              value: `${I18n.t('v4/irrigation.units.milimeter')} (mm)`,
              angle: -90,
              position: 'left',
              offset: -15,
              style: {
                fontFamily: chartsFontFamily,
                textAnchor: 'middle'
              }
            }}
          />

          <Tooltip
            content={
              <CustomTooltip keys={chartSeries} showDate={dateVisualization} />
            }
          />

          {ChartsGenerator([...areaChartsSeries, ...(chartSeries.filter(chart => chart.name !== 'final_time'))])}

          <Brush
            dataKey={dateVisualization}
            height={40}
            stroke="#000000"
            startIndex={getArrayMaxIndexMinus(irrigationData, 11)}
            endIndex={getArrayMaxIndexMinus(irrigationData)}
          >
            <ComposedChart>
              {ChartsGenerator([...areaChartsSeries, ...(chartSeries.filter(chart => chart.name !== 'final_time'))])}
            </ComposedChart>
          </Brush>

          <ReferenceArea
            x1={referenceX1}
            stroke='gray'
            isFront
          />

          {
            isCropView(selectedVisualization) &&
            phenologicalStages.map(({ title, start }) => {
              return <ReferenceLine
                isFront
                label={<PhenologicalStageReferenceLabel name={title}/>}
                key={start}
                x={start}
                stroke='gray'
                strokeWidth={2}
              />
            })
          }
        </ComposedChart>
      </ResponsiveContainer>
      <IrrigationChartLegend legends={chartSeries.filter(chart => chart.name !== 'final_time')} toggleChartSerie={toggleChartSerie} />
    </Card>
  </div >
}

export default withStyles(styles)(IrrigationChart)
