import React from 'react'

import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem'
import withStyles from '@material-ui/core/styles/withStyles'

import styles from './styles'

const SimpleSelect = ({
  handleChange,
  values,
  selectedIndex,
  isTitle,
  startEmpty,
  classes,
  ...props
}) => {
  const item = values[selectedIndex]
  const itemValue = startEmpty ? '' : (item ? (item.index || selectedIndex ) : 0)

  return <FormControl>
    <Select
      {...props}
      value={itemValue}
      onChange={handleChange}
      className={isTitle ? classes.SimpleSelect__Title : classes.SimpleSelect__Text}
    >
      {values.map((value, index) =>
        <MenuItem key={index} value={index}>{value.name}</MenuItem>
      )}
    </Select>
  </FormControl>
}

export default withStyles(styles)(SimpleSelect)
