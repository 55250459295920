import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './styles'
import Card from '@material-ui/core/Card';
import Typography from '@material-ui/core/Typography'
import { parseDecimalToTime } from '@services/locale'

const ChartTooltip = ({ classes, active, payload, keys, showDate }) => {
  const initialPoint = payload ? payload[0] : payload
  const isInactive = (value) => value > 0 ? 'textPrimary' : 'textSecondary'

  if (active && !!initialPoint) {
    const headerText = showDate ? initialPoint.payload.date : initialPoint.payload.dap

    return <Card className={classes.ChartTooltip}>
      <Typography variant='body2'>{headerText}</Typography>
      {keys.map(({ name }) => {
        const value = initialPoint.payload[name]
        if (name === 'final_time') {
          return (
            <span key={name} className={classes.PointData}>
              <Typography variant='body1' color={isInactive(value)}>{I18n.t(`v4/irrigation_system.chart_labels.${name}`)}</Typography>
              <span className={classes.PointData__value}>
                <Typography variant='body2' color={isInactive(value)}>
                  {value ? parseDecimalToTime(value) : 0}
                </Typography>
              </span>
            </span>
          )
        } else return (<span key={name} className={classes.PointData}>
          <Typography variant='body1' color={isInactive(value)}>{I18n.t(`v4/irrigation_system.chart_labels.${name}`)}</Typography>
          <span className={classes.PointData__value}>
            <Typography variant='body2' color={isInactive(value)}>
              {value ? _.round(value, 2) : 0}
            </Typography>
            <Typography className={classes.PointData__unit} variant='caption' color={isInactive(value)}>mm</Typography>
          </span>
        </span>)
      })}
    </Card>
  }
  return null
}

ChartTooltip.defaultProps = {
  payload: []
}

export default withStyles(styles)(ChartTooltip)
