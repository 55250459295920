import httpService from '@services/httpService'

export const validate = values => {
  const errors = {}
  const requiredFields = ['start', 'finish', 'group_by']
  const startDate = new Date(values.start)
  const endDate = new Date(values.finish)
  const currentDate = new Date()

  requiredFields.forEach(field => {
    if (!values[field]) errors[field] = I18n.t('validations.required')
  })

  if (startDate && endDate && startDate > endDate) {
    errors.start = I18n.t('validations.start_date')
    errors.finish = I18n.t('validations.end_date')
  }

  if (endDate > currentDate) {
    errors.finish = I18n.t('validations.invalid_end_date')
  }

  return errors
}

export const getAvaliableGroupings = () => [
  { id: 'hour', description: I18n.t('v4/equipment.dashboard.filter.avaliable_grouping.hour') },
  { id: 'day', description: I18n.t('v4/equipment.dashboard.filter.avaliable_grouping.day') },
  { id: 'week', description: I18n.t('v4/equipment.dashboard.filter.avaliable_grouping.week') },
  { id: 'biweek', description: I18n.t('v4/equipment.dashboard.filter.avaliable_grouping.biweek') },
  { id: 'month', description: I18n.t('v4/equipment.dashboard.filter.avaliable_grouping.month') },
  { id: 'year', description: I18n.t('v4/equipment.dashboard.filter.avaliable_grouping.year') },
  { id: 'none', description: I18n.t('v4/equipment.dashboard.filter.avaliable_grouping.none') }
]

export const filterData = async (equipmentId, params) =>
  httpService.get(`/api/v4/equipments/${equipmentId}/measures`, { params })
