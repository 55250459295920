import React from 'react'
import { Form as FinalForm } from 'react-final-form'
import PropTypes from 'prop-types'

import { RailsForm } from '@support/RailsForm'
import Form from './Form'
import { validate } from '@models/admin/coupon'

export const New = ({ plans, store_products, discount_types, model, o }) => (
  <RailsForm
    component={FinalForm}
    action='create'
    url={`/v4/o/${o}/store/admin/coupons`}
    successUrl={`/v4/o/${o}/store/admin/coupons`}
    validate={validate}
    initialValues={model}
    render={(props) => (
      <Form
        {...props}
        title={I18n.t('v4/coupons.actions.add')}
        plansList={plans}
        productList={store_products}
        discountType={discount_types}
        o={o}
      />
    )}
  />
)

New.propTypes = {
  model: PropTypes.object,
}

export default New

