import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

import DeleteDialog from '../../ui/DeleteDialog'
import styles from '@styles/showPage'

export class Show extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isDialogOpen: false
    }

    this.toggleDialog = this.toggleDialog.bind(this)
  }

  toggleDialog() {
    this.setState({ isDialogOpen: !this.state.isDialogOpen })
  }

  render() {
    const {
      classes,
      model,
      o
    } = this.props

    const { isDialogOpen } = this.state

    return (
      <Paper
        className={classes.showPage__wrapper}
        elevation={0}
      >
        <header className={classes.showPage__header}>
          <Typography component='h2' variant='h5'>
            {I18n.t('v4/organization.show.title')}
          </Typography>
        </header>

        <main className={classes.showPage__values}>
          <div className={classes.showPage__value}>
            <Typography
              color='textSecondary'
              component='h6'
              gutterBottom
              variant='h6'
            >
              {I18n.t('activerecord.attributes.v4/organization.name')}
            </Typography>

            <Typography paragraph variant='subtitle1' data-testid='name'>
              {model.name}
            </Typography>
          </div>

          <div className={classes.showPage__value}>
            <Typography
              color='textSecondary'
              component='h6'
              gutterBottom
              variant='h6'
            >
              {I18n.t('activerecord.attributes.v4/organization.kind')}
            </Typography>

            <Typography variant='subtitle1' paragraph data-testid='kind'>
              {model.kind_text}
            </Typography>
          </div>
          <div className={classes.showPage__value}>
            <Typography
              color='textSecondary'
              component='h6'
              gutterBottom
              variant='h6'
            >
              {I18n.t('activerecord.attributes.v4/organization.predominant_culture')}
            </Typography>

            <Typography variant='subtitle1' paragraph data-testid='kind'>
              {model.predominant_culture_text}
            </Typography>
          </div>

          <div className={classes.showPage__value}>
            <Typography
              color='textSecondary'
              component='h6'
              gutterBottom
              variant='h6'
            >
              {I18n.t('activerecord.attributes.v4/organization.customer_code')}
            </Typography>

            <Typography paragraph variant='subtitle1' data-testid='customer_code'>
              {model.customer_code}
            </Typography>
          </div>

          <div className={classes.showPage__value}>
            <Typography
              color='textSecondary'
              component='h6'
              gutterBottom
              variant='h6'
            >
              {I18n.t('activerecord.attributes.v4/organization.timezone')}
            </Typography>

            <Typography variant='subtitle1' paragraph data-testid='kind'>
              {model.timezone}
            </Typography>
          </div>
          {
            model.tenant &&
            <div className={classes.showPage__value}>
              <Typography
                color='textSecondary'
                variant='h6'
                component='h6'
                gutterBottom
              >
                {I18n.t('activerecord.models.v4/tenant.one')}
              </Typography>

              <Typography paragraph variant='subtitle1' paragraph data-testid='tenant'>
                {model.tenant.name}
              </Typography>
            </div>
          }
        </main>

        <footer className={classes.showPage__actions}>
          <Button
            className={classes.showPage__action}
            color='primary'
            variant='contained'
            href={`/v4/o/${o}/admin/organizations/${model.id}/edit`}
          >
            {I18n.t('v4/organization.actions.edit')}
          </Button>

          <Button
            className={classes.showPage__action}
            color='secondary'
            variant="contained"
            onClick={this.toggleDialog}
          >
            {I18n.t('v4/organization.actions.remove')}
          </Button>
          <Button
            className={classes.showPage__action}
            color='secondary'
            href={`/v4/o/${o}/admin/organizations`}
          >
            {I18n.t('actions.back')}
          </Button>
        </footer>

        <DeleteDialog
          dialogTitle={`${I18n.t('v4/organization.actions.remove')}?`}
          dialogText={I18n.t('confirmation.remove')}
          successUrl={`/v4/o/${o}/admin/organizations`}
          deleteUrl={`/v4/o/${o}/admin/organizations/${model.id}.json`}
          onCancel={this.toggleDialog}
          onClose={this.toggleDialog}
          open={isDialogOpen}
        />
      </Paper>
    )
  }
}

Show.propTypes = {
  model: PropTypes.object
}

export default withStyles(styles)(Show)
