import React from 'react'
import Card from '@material-ui/core/Card'
import Typography from '@material-ui/core/Typography'

import styles from '../../chartTooltip/styles'

import { withStyles } from '@material-ui/core/styles'

const valueIsEmpty = value =>
  typeof value === 'object' ?
    _.isEmpty(value)
    : value == undefined

const dataIsNotEmpty = data =>
  !!data && !_.isEmpty(data) && data.some(line => !_.isEmpty(line.payload[line.name]))

const getLastElements = (array = [], numberOfElements) => {
  if (array.length < numberOfElements) return array

  return _.takeRight(array, numberOfElements)
}

const EventsMapTooltip = ({ classes = {}, active, payload, dateVisualization }) => {
  if (active && dataIsNotEmpty(payload)) {
    const firstPoint = payload[0]
    const headerText = dateVisualization ? firstPoint.payload.date : firstPoint.payload.dap

    return <div className={classes.ChartTooltip}>
      <Typography variant='body2'>{I18n.t('v4/irrigation_system.events.title')} - {headerText}</Typography>
      {
        payload.map(line =>
          !valueIsEmpty(line.payload[line.name])
            ? <span key={line.name} className={classes.PointData}>
              <Typography variant='body1'>{I18n.t(`v4/irrigation_system.events.${line.name}`)}</Typography>
              <span className={classes.PointData__value}>
                {
                  Array.isArray(line.payload[line.name])
                    ? <div>
                      {
                        getLastElements(line.payload[line.name], 3).map((data, index) =>
                          <Typography variant='body2' key={`${data}-${index}`} align='right'>
                            {String(data)}
                          </Typography>
                        )
                      }
                    </div>
                    : <>
                      <Typography variant='body2'>
                        {String(line.payload[line.name])}
                      </Typography>
                    </>
                }
              </span>
            </span>
            : null
        )
      }
    </div>
  }
  return null
}

export default withStyles(styles)(EventsMapTooltip)
