import React from 'react'
import { Form as FinalForm } from 'react-final-form'
import { RailsForm } from '@support/RailsForm'
import InputForm from '../Form'
import NewFertilizerForm from './Form'
import { validate, unities, ureaValues, fertilizerShape } from '@models/inputs/fertilizer'

const NewFertilizer = ({ model, o }) => {
  const initialValues = {
    nitrogen: 0,
    sulfate: 0,
    phosphate: 0,
    potassium: 0,
    zinc: 0,
    toc: 0,
    urea: false
  }

  const title = `${I18n.t('actions.add')} ${I18n.t(`v4/input.kinds.${model.kind}`)}`

  return (
    <RailsForm
      component={FinalForm}
      action='create'
      initialValues={initialValues}
      url={`/v4/o/${o}/inputs/fertilizer`}
      validade={validate}
      render={(props) => (
        <InputForm
          title={title}
        >
          <NewFertilizerForm
            {...props}
            unities={unities}
            action={title}
            o={o}
            ureaValues={ureaValues}
          />
        </InputForm>
      )}
    />
  )
}

NewFertilizer.propTypes = {
  model: fertilizerShape
}

export default NewFertilizer
