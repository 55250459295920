import React from 'react'
import { Form as FinalForm } from 'react-final-form'
import PropTypes from 'prop-types'

import { validateNewUser, getUserTypes } from '@models/users'
import httpService from '@services/httpService'
import { RailsForm } from '@support/RailsForm'
import Form from './NewForm'

export const NewInvitation = ({ organization, handleError, o }) => {
  const initialValues = { role: getUserTypes()[0].id }

  const submit = data => {
    httpService({
      method: 'POST',
      url: `/v4/o/${o}/admin/organizations/${organization.id}/users/invitation.json`,
      data
    })
      .then(() =>
        Turbolinks.visit(`/v4/o/${o}/admin/organizations/${organization.id}/users`)
      )
      .catch(error => handleError(error))
  }

  return <RailsForm
    component={FinalForm}
    action='create'
    validate={validateNewUser}
    initialValues={initialValues}
    handleSubmit={data => submit(data)}
    render={(props) => (
      <Form
        {...props}
        userTypes={getUserTypes()}
        title={I18n.t('v4/users.new.title')}
        organization={organization}
        o={o}
      />
    )}
  />
}


NewInvitation.defaultProps = {
  data: {
    role: I18n.t('v4/user.members.owner')
  }
}

NewInvitation.propTypes = {
  model: PropTypes.object,
}

export default NewInvitation
