import httpService from '@services/httpService'

export const fields = [
  'name', 'description', 'status'
]

export const requiredFields = [
  'name', 'status', 'interval_count'
]

export const getPlansHeaders = () =>
  fields.map(field => ({
    title: I18n.t(`v4/plans.${field}`),
    field,
    render: rowData =>
      field === 'status'
        ? I18n.t(`v4/plans.${rowData['status']}`)
        : rowData[field]
  }))

export const validate = (values) => {
  const errors = {}

  requiredFields.forEach(field => {
    if (field === 'interval_count' && values[field] < 1) errors[field] = I18n.t('validations.positive_number')
    if (!values[field]) errors[field] = I18n.t('validations.required')
  })

  return errors
}


export const handlePlanSubmit = async ({ data, url, method, successUrl, handleError }) => {
  try {
    const { data } = await httpService[method](url, formData)
    Turbolinks.visit(successUrl)
  }
  catch (error) {
    handleError(error)
  }
}
