export const validatePhoneRegex = value => { 
  const phoneRegex = /(?:\+?\d{1,3}\s*-?)?\(?(?:\d{2,3})?\)?[- ]?\d{3,5}[- ]?\d{3,4}$/

  return phoneRegex.test(value)
}

export const validatePasswordRegex = value => {
  const passwordRegex = /(?=.{8,})(?=.*\d)(?=.*[a-z])(?=.*[A-Z])/

  return passwordRegex.test(value)
}

export const validateEmailRegex = value => {
  const emailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

  return emailRegex.test(value)
}