import { isFinished, isCreated } from '@models/irrigation_managements/index'

export const GraphicAction = (management, router) => ({
  icon: 'bar_chart',
  iconProps: { 'data-testid': `dashboard-${management.id}` },
  tooltip: I18n.t('v4/irrigation_management.graphic_tooltip'),
  onClick: (event, { id }) => {
    router.visit(`irrigation_managements/${id}/dashboard`)
  },
  disabled: isCreated(management)
})

export const EditAction = (management, router) => ({
  icon: 'settings',
  iconProps: { 'data-testid': `edit-${management.id}` },
  tooltip: I18n.t('actions.edit'),
  onClick: (event, { id }) => {
    router.visit(`irrigation_managements/${id}/edit`)
  },
  disabled: isFinished(management)
})

export const RemoveAction = (rowData, handleClick) => ({
  icon: 'delete',
  iconProps: { 'data-testid': `delete-${rowData.id}` },
  tooltip: I18n.t('actions.remove'),
  onClick: (event, { id }) => handleClick(id)
})
