import React from 'react'

import { withStyles } from '@material-ui/core'

import EditAreaIcon from '@images/area/edit_area.svg'
import InactiveEditAreaIcon from '@images/area/inactive_edit_area.svg'

const EditArea = ({ classes, loading, handleEditAreaMenu }) =>
  <div
    className={
      loading
        ? classes.EditArea_inactive
        : classes.EditArea_active
    }
    onClick={() => !loading && handleEditAreaMenu(true)}
    data-intercom-target='edit-areas-map'
  >
    <img
      className={classes.EditArea_icon}
      src={
        loading
          ? InactiveEditAreaIcon
          : EditAreaIcon
      }
    />
  </div>

const styles = theme => ({
  EditArea_active: {
    cursor: 'pointer'
  },
  EditArea_inactive: {
    cursor: 'default'
  },
  EditArea_icon: {
    width: '30px',
    height: '30px'
  }
})

export default withStyles(styles)(EditArea)
