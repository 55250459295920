import React from 'react'
import PropTypes from 'prop-types'
import { Form as FinalForm } from 'react-final-form'

import { RailsForm } from '@support/RailsForm'
import { validate } from '@models/terms'
import Form from './Form'

const New = ({ model, tenant, o }) => (
  <RailsForm
    component={FinalForm}
    action='create'
    url={`/v4/o/${o}/admin/tenants/${tenant.id}/terms`}
    successUrl={`/v4/o/${o}/admin/tenants/${tenant.id}/terms`}
    validate={validate}
    initialValues={model}
    render={(props) => (
      <Form
        {...props}
        title={I18n.t('v4/terms.actions.create')}
        model={model}
        tenantId={tenant.id}
        o={o}
      />
    )}
  />
)

New.defaultProps = {
  model: {
    name: '',
    description_pt: '',
    description_en: '',
    description_es: ''
  }
}

export default New
