import React from 'react'

import { Button, Grid,  withStyles,  Paper } from '@material-ui/core'

import CardContent from './CardContent'
import { useRouter } from '@contexts/RouterProvider'

const Card = ({ classes, data, buttonStatus }) => {
  const { visit } = useRouter()
  return (
    <Paper
      className={classes.Card_paper}>
      <Grid
        container
        direction='row'
        justify='space-between'
        alignItems='center'
      >
        <Grid item >
          <img src={data.placeHolder} width='100%' />
        </Grid>
        <div className={classes.Card_field_container}>
          <Grid item >
            <CardContent data={data} />
          </Grid>
            <Button
              className={classes.Card_button}
              variant="contained"
              color='primary'
              disabled={buttonStatus}
              onClick={() => visit(data.url)}>
              {data.action}
              <img src={data.icon} className={classes.Card_field_svg}/>
            </Button>
        </div>
      </Grid>
    </Paper>
  )
}

const styles = (theme) => ({
  Card_paper: {
    borderRadius: theme.spacing.unit,
    padding: `0px`,
    margin: `1rem 0 0 0`,
    width: theme.spacing.unit * 40,
    maxWidth: theme.spacing.unit * 40,
    height: theme.spacing.unit * 44
  },
  Card_button: {
    width: '100%',
    marginBottom: '1rem'
  },
  Card_field_container: {
    height: '180px',

    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    
    margin: '5px auto 0',
    padding: 4,
    maxWidth: '95%',
  },
  Card_field_svg:{
    marginLeft: '10px',
  }
})

export default withStyles(styles)(Card)
