import React from 'react'

import {
  Grid,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  withStyles
} from '@material-ui/core'

import Soil from '@images/equipment/soil.svg'

const CardWithDepth = ({
  classes,
  data
}) => {
  const getHeaders = () =>
    Array.from(
      new Set(
        data.equipmentsWithDepth.map(equipment => equipment.short_description)
      ))

  const getRows = () => {
    const variables = getHeaders()
    const rows = []

    data.groupByDepth.forEach(group => {
      const depth = group.depth
      let row = { depth }

      variables.forEach((variable, index) => {
        const element = group.equipments.find(equipment => equipment.short_description === variable)
        row = { ...row, [variable]: !!element && !!element.value ?  `${element.value} ${element.unit}` : '-' }
      })

      rows.push(row)
    })

    return rows
  }

  return (
    <Grid item xs={12}>
      <Table className={classes.Table} padding='checkbox'>
        <TableHead>
          <TableRow>
            <TableCell align='center'>
              <img src={Soil} className={classes.Card_icon} />
            </TableCell>
            {
              getHeaders().map(header =>
                <TableCell align='center' key={header}>
                  {header}
                </TableCell>
              )
            }
          </TableRow>
        </TableHead>
        
        <TableBody>
          {
            getRows().map(row => {
              return (
                <TableRow key={row.depth}>
                  <TableCell align='center'>
                    {row.depth}
                  </TableCell>
                  {
                    getHeaders().map((header, index) =>
                      <TableCell align='center' key={index} className={classes.Table_bold}>
                        {row[header]}
                      </TableCell>
                    )
                  }
                </TableRow>
              )
            })
          }
        </TableBody>
      </Table>
    </Grid>
  )
}

const styles = theme => ({
  Card_icon: {
    height: theme.spacing.unit * 2
  },
  Table: {
    maxWidth: 360
  },
  Table_bold: {
    fontWeight: 500
  }
})

export default withStyles(styles)(CardWithDepth)
