import React from 'react'
import { lastPlantationsTableHeaders } from '@models/dashboardArea'
import Table from '@ui/Table'


const LastPlantationsTable = ({ lastPlantations }) => {
  return (
  <Table
    tableKey='areas-dashboard-lastPlantations'
    key='last-plantations-table'
    components={{
      Container: props => <div>{props.children}</div>,
      Toolbar: () => null
    }}
    columns={lastPlantationsTableHeaders()}
    data={lastPlantations}
    options={{
      showTitle: false,
      search: false,
      paging: false,
    }}
  >
  </Table>
)}

export default LastPlantationsTable
