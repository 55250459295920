import React from 'react'
import Analyses from './analyses/Index'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

import Field from '@ui/Fields/Field'

import styles from '@theme/styles/forms'

const Form = ({
  handleSubmit,
  classes,
  title,
  equipments,
  irrigation_systems,
  forecast_points,
  analyses,
  handleError,
  o
}) => (
    <form className={classes.form} onSubmit={handleSubmit} data-testid='area-form'>
      <Paper className={classes.form__wrapper} elevation={0}>
        <header className={classes.form__header}>
          <Typography component='h2' variant='h5'>
            {title}
          </Typography>
        </header>
        <main className={classes.form__fields}>
          <Field
            required
            name='name'
            label={I18n.t('activerecord.attributes.v4/area.name')}
          />

          <Field
            required
            name='size_ha'
            disabled
            type='number'
            label={I18n.t('activerecord.attributes.v4/area.size_ha')}
          />

          <Field
            format={value => Array.isArray(value) ? value : []}
            type='multiSelect'
            data={equipments}
            label={I18n.t('activerecord.attributes.v4/area.equipment')}
            name="equipment_ids"
          />

          <Field
            type='select'
            allowEmpty
            data={irrigation_systems}
            label={I18n.t('activerecord.attributes.v4/area.irrigation_system')}
            name='irrigation_system_id'
          />

          <Field
            type='select'
            allowEmpty
            data={forecast_points}
            label={I18n.t('activerecord.attributes.v4/area.forecast_point')}
            name='forecast_point_id'
          />

          <Analyses
            analyses={analyses}
            handleError={handleError}
            o={o}
          />

        </main>
        <footer className={classes.form__actions}>
          <Button
            className={classes.form__action}
            color='primary'
            type='submit'
            variant='contained'
          >
            {title}
          </Button>

          <Button
            className={classes.form__action}
            color='secondary'
            href={`/v4/o/${o}/maps`}
          >
            {I18n.t('actions.back')}
          </Button>
        </footer>
      </Paper>
    </form>
  )

export default withStyles(styles)(Form)
