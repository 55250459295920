import React from 'react'

import {
  Button,
  Typography,
  withStyles,
} from '@material-ui/core'

const ExpiredTrial = ({ classes, router, handleCloseDialogTrial, intercomTarget }) =>
  <div className={classes.ProductExpired_wrapper}>
    <Typography variant='body2' align='center' className={classes.ProductExpired_header}>
      {I18n.t('v4/product.trial.test_period')}
    </Typography>

    <Typography variant='caption' align='center' className={classes.ProductExpired_content}>
      {I18n.t('v4/product.trial.text2')}
    </Typography>

    <Button
      className={classes.ProductExpired_action}
      color='primary'
      variant='contained'
      onClick={() => router.visit('store')}
      data-intercom-target={intercomTarget}
    >
      {I18n.t('v4/product.trial.action.renew')}
    </Button>

    <Typography
      variant='caption'
      align='center'
      className={classes.ProductExpired_footer}
      onClick={() => {
        handleCloseDialogTrial()
        window.Intercom('show')
      }}
    >
      <span className={classes.ProductExpired_info}>
        {`${I18n.t('v4/product.trial.text3')} `}
      </span>
      <span className={classes.ProductExpired_info_link}>
        {`${I18n.t('v4/product.trial.text4')} `}
      </span>
      <span className={classes.ProductExpired_info}>
        {I18n.t('v4/product.trial.text5')}
      </span>
    </Typography>
  </div>

const styles = theme => ({
  ProductExpired_wrapper: {
    maxWidth: '344px'
  },
  ProductExpired_header: {
    paddingBottom: theme.spacing.unit * 2,
    fontSize: '18px',
    fontWeight: '500',
    width: '200px',
    margin: 'auto'
  },
  ProductExpired_infos: {
    cursor: 'pointer',
    paddingBottom: theme.spacing.unit * 3
  },
  ProductExpired_content: {
    paddingBottom: theme.spacing.unit * 3,
    fontSize: '13px'
  },
  ProductExpired_footer: {
    cursor: 'pointer',
    paddingTop: theme.spacing.unit
  },
  ProductExpired_info: {
    fontSize: '13px'
  },
  ProductExpired_info_link: {
    fontSize: '13px',
    fontWeight: '500'
  },
  ProductExpired_action: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginBottom: theme.spacing.unit
  }
})

export default withStyles(styles)(ExpiredTrial)
