import React from 'react'

import TelephoneField from '@ui/Fields/Telephone'
import TextField from '@ui/Fields/TextField'
import ControlledSelect from '@ui/Fields/ControlledSelect'
import Select from '@ui/Fields/Select'
import MultipleSelect from '@ui/Fields/MultipleSelect'
import SelectWithVisitButton from '@ui/Fields/SelectWithVisitButton'
import DatePicker from '@ui/Fields/DatePicker'
import SelectFlagIcon from '@ui/Fields/FlagIcon/Index'


const Fields = {
  text: ({ classes, className, ...props }) => (
    <TextField
      {...props}
      className={className || classes.form__fieldWithError}
    />
  ),
  number: ({ classes, className, inputProps, ...props }) => (
    <TextField
      {...props}
      inputProps={inputProps || { step: '1' }}
      type='number'
      className={className || classes.form__fieldWithError}
    />
  ),
  select: ({ classes, className, fields, ...props }) => (
    <Select
      {...props}
      fields={fields || { id: 'id', name: 'name' }}
      className={className || classes.form__fieldWithError}
    />
  ),
  controlledSelect: ({ classes, className, fields, ...props }) => (
    <ControlledSelect
      {...props}
      fields={fields || { id: 'id', name: 'name' }}
      className={className || classes.form__fieldWithError}
    />
  ),
  multiSelect: ({ classes, className, fields, ...props }) => (
    <MultipleSelect
      {...props}
      fields={fields || { id: 'id', name: 'name' }}
      className={className || classes.form__fieldWithError}
    />
  ),
  selectWithVisitButton: ({ classes, className, fields, ...props }) => (
    <SelectWithVisitButton
      {...props}
      fields={fields || { id: 'id', name: 'name' }}
      className={className || classes.form__fieldWithError}
    />
  ),
  telephone: ({ classes, className, ...props }) => (
    <TelephoneField
      {...props}
      className={className || classes.form__fieldWithError}
    />
  ),
  date: ({ classes, className, ...props }) => (
    <DatePicker
      {...props}
      className={className || classes.form__fieldWithError}
    />
  ),
  selectFlagIcon: ({ classes, className, ...props }) => (
    <SelectFlagIcon
      {...props}
      fields={{ id: 'id', idIcon: 'idIcon', name: 'name' }}
      className={className || classes.form__fieldWithError}
    />
  ),
}

export default Fields
