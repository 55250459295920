import React from 'react'

import { IconButton } from '@material-ui/core'
import DownloadIcon from '@material-ui/icons/CloudDownload'
import { getFileSize } from './files'

export const tableHeaders = [
  {
    field: 'filename',
    title: I18n.t('v4/attachments.headers.name')
  },
  {
    field: 'size',
    title: I18n.t('v4/attachments.headers.size'),
    render: ({ byte_size }) => getFileSize(byte_size)
  },
  {
    field: 'resource_name',
    title: I18n.t('v4/attachments.headers.associated_resource')
  },
  {
    field: 'file_link',
    title: I18n.t('v4/attachments.headers.resource_link'),
    render: ({ resource_link }) =>
      <IconButton
        title={I18n.t('actions.download')}
        href={resource_link}
      >
        <DownloadIcon />
      </IconButton>
  }
]
