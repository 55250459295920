import httpService from '@services/httpService'
import axios from 'axios'

export const getDirectUploadLinks = ({ organization_id, ...params }) =>
  httpService.post(`/api/v4/organizations/${organization_id}/attachments/presigned_links`, params)

export const uploadFile = file => {
  if (file.url) {
    const options = {
      headers: {
        'Content-Type': 'application/octet-stream'
      }
    }

    return axios.put(file.url, file, options)
  }
  else {
    throw new Error('Invalid Link')
  }
}

export const deleteFile = (id, organization_id) =>
  httpService.delete(`/api/v4/organizations/${organization_id}/attachments/${id}`)
