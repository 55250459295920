import React from 'react'
import { Form as FinalForm } from 'react-final-form'
import PropTypes from 'prop-types'

import { validate } from '@models/organization'
import { RailsForm } from '@support/RailsForm'
import Form from './Form'
import httpService from '@services/httpService'

const timezone_browser = Intl.DateTimeFormat().resolvedOptions().timeZone

const handleSubmit = async (values, handleError, o) => {
  const organization = {
    ...values,
    timezone_user: timezone_browser
  }

  try {
    const {data} = await httpService.post(`/v4/o/${o}/admin/organizations`, organization)

    Turbolinks.visit(`/v4/o/${o}/admin/organizations/${data.id}`)
  }
  catch(error){
    handleError(error)
  }
}

export const New = ({ model, organization_kinds, predominant_cultures, tenants, handleError, o }) => (
  <RailsForm
    component={FinalForm}
    action='create'
    url={`/v4/o/${o}/admin/organizations`}
    validate={validate}
    handleSubmit={(values) => handleSubmit(values, handleError, o)}
    render={(props) => (
      <Form
        {...props}
        title={I18n.t('v4/organization.actions.add')}
        organizationKinds={organization_kinds}
        predominantCultures={predominant_cultures}
        tenants={tenants}
        o={o}
        timezone_user={timezone_browser}
      />
    )}
  />
)

New.propTypes = {
  model: PropTypes.object,
  organization_kinds: PropTypes.array
}

export default New
