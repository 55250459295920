import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withSnackbar } from 'notistack';

import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import Table from '@ui/Table'

import { getorganizationAlertsHeaders } from '@models/admin/organizations/alerts'
import styles from '@styles/indexPage'

class Index extends Component {
  constructor(props) {
    super(props)

  }

  render() {
    const { model, classes, organization, o } = this.props

    return (
      <Paper className={classes.page__wrapper} elevation={0}>
        <header className={classes.page__header}>
          <Typography component='h2' variant='h5'>
            {organization.name}: {I18n.t('v4/alerts.title.index')}
          </Typography>
        </header>
        <main style={{ marginTop: "16px", marginBottom: "8px" }}>
          <Table
            tableKey='admin-organizations-alerts-index'
            components={{
              Container: props => <div>{props.children}</div>,
            }}
            columns={getorganizationAlertsHeaders()}
            data={model}
            actions={[
              rowData => ({
                icon: 'create',
                iconProps: { 'data-testid': `edit-${rowData.id}` },
                tooltip: I18n.t('actions.edit'),
                onClick: (event, rowData) =>
                  Turbolinks.visit(`/v4/o/${o}/admin/organizations/${organization.id}/alerts/${rowData.id}/edit`)
              })
            ]}
            options={{ showTitle: false, exportAllData: true }}
          />
        </main>      
      </Paper>
    )
  }
}

Index.propTypes = {
  model: PropTypes.array
}

export default withSnackbar(
  withStyles(styles)(Index)
)
