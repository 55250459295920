import React from 'react'

import Panel from './Panel'
import DatePicker from '@ui/Fields/DatePicker'
import TextField from '@ui/Fields/TextField'

import { convertToLocaleDate } from '@services/locale'

import AdvancedParamsTable from './Table'

const rootDepthTextFieldProps = { inputProps: { min: '0', step:0.01 } }
const rootDepthColumns = [
  {
    field: 'date',
    title: I18n.t('v4/irrigation_management.advanced_params.root_depth.fields.date'),
    editComponent: ({ value, onChange }) => (
      <DatePicker
        value={value || null}
        onChange={date => onChange(date)}
        variant='standard'
        required
      />
    ),
    render: ({ date }) => date ? convertToLocaleDate(date) : null
  },
  {
    field: 'value',
    title: I18n.t('v4/irrigation_management.advanced_params.root_depth.fields.value'),
    editComponent: ({ value, onChange }) => (
      <TextField
        value={value}
        onChange={({ target }) => onChange(target.value)}
        type='number'
        inputProps = { rootDepthTextFieldProps }
        required
      />
    )
  }
]

const validate = data =>
  !rootDepthColumns.some(({ field }) => !data[field])

const RootDepth = ({ data, update }) =>
  <Panel title={I18n.t('v4/irrigation_management.advanced_params.root_depth.title')}>
    <AdvancedParamsTable
      data={data}
      validate={validate}
      updateData={update}
      columns={rootDepthColumns}
    />
  </Panel>

RootDepth.defaultProps = {
  data: [],
  update: () => { }
}

export default RootDepth
