import React from 'react'

import {
  Button,
  IconButton,
  Typography,
  withStyles
} from '@material-ui/core'

import Clear from '@material-ui/icons/Clear'

const CardCreateArea = ({ classes, handleClose, getSupportLink }) =>
  <div className={classes.CardCreateArea_wrapper}>
    <div className={classes.CardCreateArea_header}>
      <Typography className={classes.CardCreateArea_title} variant='subtitle2'>
        {I18n.t('v4/products.view')}
      </Typography>

      <div className={classes.CardCreateArea_close}>
        <IconButton
          className={classes.SatelliteImagery_iconButton}
          onClick={handleClose}
        >

          <Clear />
        </IconButton>
      </div>
    </div>

    <div className={classes.CardCreateArea_infos}>
      <Typography variant='body1' align='center'>
        {I18n.t('v4/satellite_images.text1')}
      </Typography>

      <Typography variant='body2' align='center'>
        {I18n.t('v4/satellite_images.text2')}
      </Typography>
    </div>

    <Typography variant='caption' align='center' className={classes.CardCreateArea_info}>
      {I18n.t('v4/satellite_images.text3')}
    </Typography>

    <Button
      type='submit'
      color='primary'
      variant='contained'
      className={classes.CardCreateArea_action}
      href={getSupportLink()}
    >
      {I18n.t('v4/satellite_images.actions.create_area')}
    </Button>
  </div>

const styles = theme => ({
  CardCreateArea_header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '100%',
    marginBottom: 10
  },
  CardCreateArea_title: {
    flex: 3
  },
  CardCreateArea_close: {
    flex: 1,
    display: 'flex',
    justifyContent: 'flex-end'
  },
  CardCreateArea_wrapper: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    minWidth: '320px'
  },
  CardCreateArea_action: {
    width: '100%'
  },
  CardCreateArea_infos: {
    padding: `0 ${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px`
  },
  CardCreateArea_info: {
    paddingBottom: theme.spacing.unit
  }
})

export default withStyles(styles)(CardCreateArea)