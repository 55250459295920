import React from 'react'
import { Form as FinalForm } from 'react-final-form'

import { RailsForm } from '@support/RailsForm'
import Form from './Form'

import { useRouter } from '@contexts/RouterProvider'
import { useHttpService } from '@contexts/HttpServiceProvider'
import { useErrorHandler } from '@contexts/ErrorHandlerProvider'

import { validate } from '@models/irrigation_managements'

export const New = ({ organization_id, o, algorithms }) => {
  const router = useRouter()
  const httpService = useHttpService()
  const handleError = useErrorHandler()

  const handleSubmit = async values => {
    try {
      const formattedValues = Object.assign({}, values)

      if (!formattedValues.v4_area_id) {
        throw (I18n.t('errors.messages.missing_field'))
      }

      if (!formattedValues.soil_sensor_id && !formattedValues.weather_station_id && formattedValues.algorithm_name != 'simplified') {
        throw (I18n.t('v4/irrigation_management.errors.missing_weather_soil_equipment'))
      }

      _.forOwn(values, (value, key) => {
        if (key.includes('__real')) {
          const originalKey = key.split('__real')[0]

          if (isNaN(value)) {
            formattedValues[originalKey] = value
          } else {
            switch (originalKey) {
              case 'optimal_water_loss':
                formattedValues['limit_client'] = value
                break
              case 'type_irrigation_interval':
                formattedValues['fixed_watering'] = value
                break
            }
          }

          delete formattedValues[key]
        }
      })

      await httpService.request.post('irrigation_managements', formattedValues)
      router.visit('irrigation_managements')
    }
    catch (error) {
      handleError(error)
      console.error(error)
    }
  }

  return (
    <RailsForm
      component={FinalForm}
      validate={validate}
      handleSubmit={handleSubmit}
      render={(props) => (
        <Form
          {...props}
          action={I18n.t('v4/irrigation_management.add_irrigation_management')}
          title={I18n.t('v4/irrigation_management.new_irrigation_management')}
          o={o}
          organization_id={organization_id}
          irrigationTypes={algorithms}
          handleError={handleError}
          checkError={handleError}
        />
      )}
    />
  )
}


export default New
