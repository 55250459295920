
import React from 'react'

import { Popover } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'

const ProductsWrapper = ({
  classes,
  children,
  open,
  onClose,
  anchor
}) =>
  <Popover
    open={open}
    anchorEl={anchor}
    onClose={onClose}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'center',
    }}
    transformOrigin={{
      vertical: 'top',
      horizontal: 'center',
    }}
    style={{
      zIndex: 1050,
      position: 'absolute'
    }}
    classes={{
      paper: classes.ProductsWrapper_paper
    }}
  >
    {children}
  </Popover>

const styles = (theme) => ({
  ProductsWrapper_paper: {
    maxHeight: 'calc(100vh - 80px)',
    '@media(min-width: 500px)': {
      minWidth: 350
    },
    minWidth: 320,
    maxWidth: 351,
    minHeight: 200,
    marginRight: theme.spacing.unit,
    overflow: 'hidden'
  }
})

export default withStyles(styles)(ProductsWrapper)
