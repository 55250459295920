const valueIsEmpty = value =>
  typeof value === 'object' ?
    _.isEmpty(value)
    : value == undefined

const CustomizedDotHandler = ({ cx, cy, event, payload, color }) =>
  valueIsEmpty(payload[event.name])
    ? null
    : event.dot({ cx, cy, color })

export default CustomizedDotHandler
