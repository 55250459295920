export default theme => ({
  Alert_Wrapper: {
    padding: theme.spacing.unit
  },
  Alert_Menu: {
    maxHeight: '300px',
    overflowY: 'auto',
    borderBottom: `1px solid ${theme.palette.gray.main}`,
    '&::-webkit-scrollbar': {
      width: '10px',
      borderRadius: '10px'
    },
  },
  Alert_Icon: {
    display: 'flex',
    width: `${theme.spacing.unit * 3.75}px`,
    height: `${theme.spacing.unit * 3.125}px`,
    color: theme.menus.icons
  },
  Alert_Header: {
    width: '100%',
    marginBottom: theme.spacing.unit * 2,
  },
  Alert_Popover: {
    overflow: 'hidden',
    width: `${theme.spacing.unit * 36.25}px`,
    padding: theme.spacing.unit,
    borderRadius: theme.spacing.unit,
    background: theme.palette.white,
  },
  Alerts: {
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between'
  },
  Alert_areas: {
    fontSize: '12px',
    color: theme.palette.gray.dark
  },
  Alert_check: {
    color: theme.palette.primary.main,
    cursor: 'pointer',
    width: '26px',
    height: '26px'
  },
  Alert_date: {
    fontSize: '12px',
    color: theme.palette.gray.dark,
    padding: `0 ${theme.spacing.unit}px`
  },
  Alert_infos: {
    display: 'flex',
    alignItems: 'baseline'
  },
  Alert_Button: {
    display: 'flex',
    justifyContent: 'center',
    margin: theme.spacing.unit,
    borderBottom: `1px solid ${theme.palette.gray.main}`,
    '&::-webkit-scrollbar': {
      width: '10px',
      borderRadius: '10px'
    },
  },
  Alert_Received: {
    background: theme.palette.cards.red,
    borderRadius: '100%',
    position: 'absolute',
    top: `${theme.spacing.unit * 3.25}px`,
    left: `${theme.spacing.unit * 2}px`,
    width: `${theme.spacing.unit}px`,
    height: `${theme.spacing.unit}px`
  },
  Alert_empty: {
    padding: theme.spacing.unit,
    color: theme.palette.gray.dark
  },
  Alert_count: {
    color: theme.palette.primary.main,
    fontSize: '12px'
  },
  Alert_Container: {
    marginBottom: theme.spacing.unit * 2
  },
  Alert_Footer: {
    display: 'flex',
    justifyContent: 'center',
    borderTop: `1px solid ${theme.palette.gray.main}`,
    '&::-webkit-scrollbar': {
      width: '10px',
      borderRadius: '10px'
    },
  },
  Empty_Notification: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    background: theme.palette.background.paper,
    borderTop: `0.5px solid ${theme.palette.gray.main}`,
    borderBottom: `0.5px solid ${theme.palette.gray.main}`,
  },
  Notification_Footer: {
    display: 'flex',
    justifyContent: 'center',
    borderTop: `1px solid ${theme.palette.gray.main}`,
    paddingTop: theme.spacing.unit,
    '&::-webkit-scrollbar': {
      width: '10px',
      borderRadius: '10px'
    },
  },
  Notification_Central_Header: {
    display: 'flex',
    padding: theme.spacing.unit,
    justifyContent: 'space-between',
  },
  Notification_Wrapper: {
    padding: theme.spacing.unit
  },
  Notification_List: {
    overflowY: 'auto',
    maxHeight: '400px',
    '&::-webkit-scrollbar': {
      width: '10px',
      borderRadius: '10px'
    },
  }
})
