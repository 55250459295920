import React from "react";
import PropTypes from "prop-types";

import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import Typography from "@material-ui/core/Typography";

import { useRouter } from "@contexts/RouterProvider";
import { useMenusStatus, MENUS } from "@contexts/MenusStatusProvider";
import WeatherForecastIcon from "@images/products/weather_forecast.png";
import SatelliteImageryIcon from "@images/products/satellite_imagery.png";
import IrrigationManagementIcon from "@images/products/irrigation_management.png";
import AlertIcon from "@images/products/alert.png";
import WeatherForecastNotificationIcon from "@images/products/weather_forecast_notification.png";
import InsightIcon from "@images/products/insight.png";
import FieldNotebookIcon from "@images/products/field_notebook.png";

const ProductsList = ({ classes, products, currentView }) => {
  const { toggleMenu } = useMenusStatus();
  const router = useRouter();

  const productsIcons = {
    weather_forecast: WeatherForecastIcon,
    satellite_imagery: SatelliteImageryIcon,
    irrigation_management: IrrigationManagementIcon,
    alert: AlertIcon,
    weather_forecast_notification: WeatherForecastNotificationIcon,
    insight: InsightIcon,
    field_notebook: FieldNotebookIcon,
  };

  const getMenuType = () => ({
    weather_forecast: "weatherForecastProduct",
    satellite_imagery: "satelliteImageryProduct",
    irrigation_management: "irrigationProduct",
  });

  const getDataIntercomTarget = () => ({
    weather_forecast: "product-atmos",
    satellite_imagery: "product-view",
    irrigation_management: "product-aqua",
    alert: "product-alert",
    weather_forecast_notification: "product-atmoswhats",
    insight: "product-insights",
    field_notebook: "product-fieldnotebook",
  });

  const handleOpenMenu = (kind) => {
    const selectedMenu = getMenuType()[kind];
    toggleMenu(MENUS[selectedMenu], true);
  };

  const handleProductClick = (product) => {
    const mapProducts = Object.keys(getMenuType());

    const isMapCurrentView =
      currentView === "maps/Index" && mapProducts.includes(product.kind);
    const isSatelliteImage = product.kind === "satellite_imagery";
    if (isMapCurrentView || product.kind === "weather_forecast") {
      handleOpenMenu(product.kind);
    } else if (isSatelliteImage) {
      Turbolinks.visit(`${router.baseURL}maps`);
    } else {
      router.visit(product.path);
    }
  };

  return (
    <List className={classes.products__menu}>
      {products.map((product) => (
        <ListItem className={classes.products__item} key={product.label}>
          <a
            onClick={() => handleProductClick(product)}
            className={classes.products__link}
            data-intercom-target={getDataIntercomTarget()[product.kind]}
          >
            <Typography className={classes.products__icon}>
              <span className={classes.products__icon__center}>
                <img
                  className={classes.product_icon}
                  src={productsIcons[product.kind]}
                  alt={product.kind}
                />
              </span>
            </Typography>
            <Typography className={classes.products__label}>
              {I18n.t(
                `activerecord.attributes.v4/product.labels.${product.kind}`
              ) || product.label}
            </Typography>
          </a>
        </ListItem>
      ))}
    </List>
  );
};

ProductsList.propTypes = {
  products: PropTypes.array,
};

export default ProductsList;
