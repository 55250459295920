import React from 'react'

import Dialog from '@ui/Dialog'
import AdvancedParams from './AdvancedParams'

const AdvancedParamsDialog = ({ open, handleSubmit, initialValues, handleClose, selectedSensor }) =>
  <Dialog
    open={open}
    title={I18n.t('v4/irrigation_management.advanced_params.title')}
    onClose={handleClose}
    maxWidth='md'
  >
    <AdvancedParams
      initialValues={initialValues}
      handleCancel={handleClose}
      handleSubmit={handleSubmit}
      selectedSensor={selectedSensor}
    />
  </Dialog>

export default AdvancedParamsDialog
