import React from "react";

import { Notifications, Create } from "@material-ui/icons";
import { Chip, Paper, Typography, IconButton } from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";

import TextField from "@ui/Fields/TextField";

import Select from "@ui/Fields/Select";

import Step from "./Step";

import {
  variableWindSpeed_KM_H,
  variableWindSpeed_M_S,
  AlertCategories,
} from "@models/alerts";

const AlertPreviewStep = ({
  advanceStep,
  classes,
  data,
  goToStep,
  name,
  preview,
  retreatStep,
  handleChangeAlertName,
  handleChangeAlertCategory,
  alertCategory,
}) => {
  const getAreasLabel = (areas) => {
    const labels = areas
      .filter((area) => area.selected)
      .map((filteredArea) => filteredArea.name);

    return labels;
  };

  const getMediasLabel = (medias) => {
    let labels = Object.keys(medias).filter((media) => {
      return medias[media];
    });

    _.isEmpty(labels)
      ? (labels = [I18n.t("v4/alerts.new.steps.preview.empty_medias")])
      : null;

    return labels;
  };

  const getVariablesLabel = (variables) => {
    const labels = variables.map((variable) => {
      const hasDays = variable.frequency === "average";

      const label_average = `${I18n.t(
        "v4/alerts.new.steps.preview.label_days",
        {
          name: variable.variable_description,
          rule: variable.translation.toLowerCase(),
          value: variable.value,
          metric:
            variable.variable === variableWindSpeed_M_S
              ? variableWindSpeed_KM_H
              : variable.unity,
          days: variable.days,
        }
      )}`;

      const label = `${I18n.t("v4/alerts.new.steps.preview.label", {
        name: variable.variable_description,
        rule: variable.translation.toLowerCase(),
        value: variable.value,
        metric:
          variable.variable === variableWindSpeed_M_S
            ? variableWindSpeed_KM_H
            : variable.unity,
      })}`;

      return hasDays ? label_average : label;
    });

    return labels;
  };

  const getLabel = (data, type) => {
    if (type === "areas") {
      return getAreasLabel(data);
    }
    if (type === "medias") {
      return getMediasLabel(data);
    }
    if (type === "variables") {
      return getVariablesLabel(data);
    }
  };

  return (
    <Step
      title={I18n.t("v4/alerts.new.steps.preview.title")}
      description={I18n.t("v4/alerts.new.steps.preview.description")}
      showBackButton={true}
      advanceStep={advanceStep}
      retreatStep={retreatStep}
      advanceButtonText={<ActionWithBell classes={classes} />}
      disableButton={!name}
    >
      <div className={classes.Alert_Selectors}>
        <TextField
          className={classes.Alert_error}
          onChange={(event) => handleChangeAlertName(event.target.value)}
          value={name}
          InputLabelProps={{ shrink: true }}
          variant="outlined"
          label={I18n.t("v4/alerts.new.steps.alert_name.default_alert_name")}
          error={!name}
          helperText={!name && I18n.t("validations.required")}
          required
        />

        <Select
          className={classes.Alert_Category}
          onChange={(event) => handleChangeAlertCategory(event.target.value)}
          value={alertCategory}
          InputLabelProps={{ shrink: true }}
          fields={{ id: "value", name: "name" }}
          variant="outlined"
          label={I18n.t("v4/alerts.new.steps.category.category_name")}
          error={!alertCategory}
          helperText={!alertCategory && I18n.t("validations.required")}
          data={AlertCategories}
          required
        />
      </div>

      {Object.keys(data).map((step) => (
        <Section
          classes={classes}
          step={step}
          key={step}
          preview={preview}
          goToStep={goToStep}
          label={getLabel(data[step], step)}
        />
      ))}
    </Step>
  );
};

const Section = ({ classes, step, preview, goToStep, label }) => (
  <Paper elevation={3} className={classes.AlertPaper}>
    <div className={classes.Alert_edit}>
      <Typography variant="subtitle2">
        {I18n.t(`v4/alerts.new.steps.preview.${step}`)}
      </Typography>

      <IconButton title={I18n.t("actions.edit")} onClick={() => goToStep(step)}>
        <Create />
      </IconButton>
    </div>

    <section className={classes.Variables}>
      {label.map((label, i) => (
        <Variable
          classes={classes}
          key={`step-value-${i}`}
          step={step}
          goToStep={goToStep}
          label={label}
        />
      ))}
    </section>
  </Paper>
);

const Variable = ({ classes, step, goToStep, label }) => (
  <Chip
    label={label}
    color="primary"
    className={classes.AlertChip}
    classes={{ label: classes.AlertChip_label }}
    component="a"
    onClick={() => goToStep(step)}
    clickable
  />
);

const ActionWithBell = ({ classes }) => (
  <div className={classes.AlertAction}>
    {I18n.t("v4/alerts.new.steps.preview.enable_alert")}
    <Notifications />
  </div>
);

const styles = (theme) => ({
  AlertPaper: {
    padding: theme.spacing.unit * 2,
    margin: `0 auto ${theme.spacing.unit * 3}px`,
    maxWidth: "500px",
  },
  AlertChip: {
    marginTop: theme.spacing.unit,
    height: "auto",
    "&:not(:last-child)": {
      marginRight: theme.spacing.unit,
    },
  },
  AlertChip_label: {
    fontWeight: 500,
    whiteSpace: "normal",
    paddingTop: 5,
    paddingBottom: 5,
  },
  Variables: {
    display: "flex",
    flexFlow: "row wrap",
  },
  AlertAction: {
    display: "flex",
    alignItems: "center",
  },
  Alert_Selectors: {
    display: "flex",
    justifyContent: "center",
    '@media(max-width: 800px)': {
      flexDirection: 'column'
    },
    paddingBottom: theme.spacing.unit * 2,
  },
  Alert_error: {
    "&.MuiFormHelperText-root.Mui-error": {
      color: theme.palette.red,
    },
  },
  Alert_edit: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
  },
  Alert_Category: {
    width: '30%',
    marginLeft: theme.spacing.unit * 4,

    '@media(max-width: 800px)': {
      marginLeft: 0,
      width: '100%',
      marginTop: theme.spacing.unit * 4
    }
  },
});

AlertPreviewStep.defaultProps = {
  preview: {
    variables: [],
    areas: [],
    medias: [],
  },
};

export default withStyles(styles)(AlertPreviewStep);
