import React from 'react'
import { withStyles, Card, Typography } from '@material-ui/core'

const PhenologicalStageReferenceLabel = ({ viewBox, name, classes }) => {
  const height = 100
  const width = 150
  const x = viewBox.width + viewBox.x + 5
  const y = viewBox.y + 5

  return (
    <foreignObject
      height={`${height}px`}
      width={`${width}px`}
      x={x}
      y={y}
    >
      <Card
        className={classes.PhenologicalStageReferenceLabel}
        title={I18n.t('v4/irrigation_management.phenological_stage')}
      >
        <div>
          <svg viewBox='0 0 28 27' width={20} height={20} fill='#666666'>
            <path fillRule='evenodd' d='M81.82,100.78V97.64a50.07,50.07,0,0,1,15.92-3.13,50.07,50.07,0,0,1,15.92,3.13v3.14ZM96.63,80.56C94.71,74.44,85,75.86,85,75.86s.32,12.22,9.4,10.34c-.64-4.55-3-5.8-3-5.8,4.46,0,4.78,5.33,4.78,5.33v7.21h3.18V86.36s0-6.12,4.78-7.68c0,0-3.19,4.7-3.19,7.83,11.15,1.1,11.15-14,11.15-14S97.9,71,96.63,80.56Z' transform='translate(-81.82 -72.47)' />
          </svg>
        </div>

        <Typography variant='caption' align='right'>
          {name}
        </Typography>
      </Card>
    </foreignObject>
  )
}

const styles = theme => ({
  PhenologicalStageReferenceLabel: {
    padding: theme.spacing.unit,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
})

export default withStyles(styles)(PhenologicalStageReferenceLabel)
