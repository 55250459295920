import React from 'react'
import PropTypes from 'prop-types'
import { Form as FinalForm } from 'react-final-form'

import { convertDateAndTime, dateToHours } from '@models/equipment/irrigation'
import { RailsForm } from '@support/RailsForm'

import { useHttpService } from '@contexts/HttpServiceProvider'
import { useErrorHandler } from '@contexts/ErrorHandlerProvider'

import { sendAmplitudeEvent } from '@utils/amplitudeEvent';
import { IntercomAPI } from '@utils/IntercomAPI';

import Form from './Form'

const FormWrapper = ({ areas, initialAreas, onCancel, organization_id }) => {
  const handleError = useErrorHandler()
  const httpService = useHttpService()
  const [loading, setLoading] = React.useState(false)

  const handleSubmit = (data) => {
    setLoading(true)

    const numberOfIrrigationDepths = Object.keys(data).length
    sendAmplitudeEvent('Aqua - Sent Irrigation', {
      number_of_irrigation_depths: numberOfIrrigationDepths
    })
    IntercomAPI('trackEvent', 'Aqua - Sent Irrigation');
    
    _.forOwn(data, async (informIrrigation, key) => {

      if (informIrrigation.unit && informIrrigation.irrigation) {
        try {
          const areaId = informIrrigation.areaId ? informIrrigation.areaId : key.split('_')[1]

          const selectedArea = areas.find(area => area.id === areaId)

          if (!!informIrrigation.finished_at) {
            informIrrigation.finished_at = convertDateAndTime(informIrrigation.irrigation_date, informIrrigation.finished_at)
          }

          if(!!informIrrigation.started_at) {
            informIrrigation.started_at = convertDateAndTime(informIrrigation.irrigation_date, informIrrigation.started_at)
          }

          const data = {
            ...informIrrigation,
            irrigation: informIrrigation.unit === 'h' ? dateToHours(informIrrigation.irrigation) : informIrrigation.irrigation,
          }

          if (selectedArea) {
            await httpService.request.post(`equipments/irrigation/${selectedArea.irrigation_system_id}/irrigations`, {
              irrigation: {
                ...data,
                area_id: selectedArea.id
              }
            })
            
          }
        } catch (err) {
          setLoading(false)
          handleError(err)
        }
      }
      onCancel()
    })
  }

  return <RailsForm
    component={FinalForm}
    handleSubmit={handleSubmit}
    render={props => (
      <Form
        {...props}
        withWrapper={false}
        organization_id={organization_id}
        areas={areas.filter(area => area.irrigation_system_id)}
        initialAreas={initialAreas && initialAreas.filter(initialArea => initialArea.irrigation_system_id)}
        handleError={handleError}
        onCancel={onCancel}
        loading={loading}
      />
    )}
  />
}

FormWrapper.propTypes = {
  equipment: PropTypes.object,
  areas: PropTypes.array
}

export default FormWrapper
