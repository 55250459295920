import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

import styles from '@styles/showPage'

class Show extends Component {
  constructor (props) {
    super(props)
  }

  render () {
    const {
      classes,
      model,
      o
    } = this.props

    return (
      <Paper
        className={classes.showPage__wrapper}
        elevation={0}
      >
        <header className={classes.showPage__header}>
          <Typography component='h2' variant='h5'>
            {I18n.t('v4/hardware_products.show.title')}
          </Typography>
        </header>

        <main className={classes.showPage__value}>
          <div className={classes.showPage__value}>
            <Typography
              color='textSecondary'
              component='h6'
              gutterBottom
              variant='h6'
            >
              {I18n.t('v4/hardware_products.labels.barcode')}
            </Typography>

            <Typography paragraph variant='subtitle1' data-testid='barcode'>
              {model.barcode}
            </Typography>
          </div>

          <div className={classes.showPage__value}>
            <Typography
              color='textSecondary'
              component='h6'
              gutterBottom
              variant='h6'
            >
              {I18n.t('v4/hardware_products.labels.identifier')}
            </Typography>

            <Typography paragraph variant='subtitle1' data-testid='identifier'>
              {model.identifier}
            </Typography>
          </div>

          <div className={classes.showPage__value}>
            <Typography
              color='textSecondary'
              component='h6'
              gutterBottom
              variant='h6'
            >
              {I18n.t('v4/hardware_products.labels.pin')}
            </Typography>

            <Typography variant='subtitle1' paragraph data-testid='pin'>
              {model.pin}
            </Typography>
          </div>

          <div className={classes.showPage__value}>
            <Typography
              color='textSecondary'
              variant='h6'
              component='h6'
              gutterBottom
            >
              {I18n.t('v4/hardware_products.labels.status')}
            </Typography>

            <Typography paragraph variant='subtitle1' data-testid='status'>
              {model.status}
            </Typography>
          </div>

          <div className={classes.showPage__value}>
            <Typography
              color='textSecondary'
              variant='h6'
              component='h6'
              gutterBottom
            >
              {I18n.t('v4/hardware_products.labels.part_number')}
            </Typography>

            <Typography paragraph variant='subtitle1' data-testid='part_number'>
              {model.part_number}
            </Typography>
          </div>

          <div className={classes.showPage__value}>
            <Typography
              color='textSecondary'
              variant='h6'
              component='h6'
              gutterBottom
            >
              {I18n.t('v4/hardware_products.labels.communication_address')}
            </Typography>

            <Typography paragraph variant='subtitle1' data-testid='communication_address'>
              {model.communication_address}
            </Typography>
          </div>

          <div className={classes.showPage__value}>
            <Typography
              color='textSecondary'
              variant='h6'
              component='h6'
              gutterBottom
            >
              {I18n.t('v4/hardware_products.labels.pcb_version')}
            </Typography>

            <Typography paragraph variant='subtitle1' data-testid='pcb_version'>
              {model.pcb_version}
            </Typography>
          </div>

          <div className={classes.showPage__value}>
            <Typography
              color='textSecondary'
              variant='h6'
              component='h6'
              gutterBottom
            >
              {I18n.t('v4/hardware_products.labels.firmware')}
            </Typography>

            <Typography paragraph variant='subtitle1' data-testid='firmware'>
              {model.firmware}
            </Typography>
          </div>

          <div className={classes.showPage__value}>
            <Typography
              color='textSecondary'
              variant='h6'
              component='h6'
              gutterBottom
            >
              {I18n.t('v4/hardware_products.labels.globalstar')}
            </Typography>

            <Typography paragraph variant='subtitle1' data-testid='notes1'>
              {I18n.t(`v4/hardware_products.booleans.${model.notes1}`)}
            </Typography>
          </div>

          <div className={classes.showPage__value}>
            <Typography
              color='textSecondary'
              variant='h6'
              component='h6'
              gutterBottom
            >
              {I18n.t('v4/hardware_products.labels.notes_2')}
            </Typography>

            <Typography paragraph variant='subtitle1' data-testid='notes2'>
              {model.notes2}
            </Typography>
          </div>
        </main>

        <footer className={classes.showPage__actions}>
          <Button
            className={classes.showPage__actions}
            color='primary'
            variant='contained'
            href={`/v4/o/${o}/admin/hardware_products/${model.id}/edit`}
          >
            {I18n.t('v4/hardware_products.actions.edit')}
          </Button>

          <Button
            className={classes.showPage__actions}
            color='secondary'
            href={`/v4/o/${o}/admin/hardware_products`}
          >
            {I18n.t('actions.back')}
          </Button>
        </footer>
      </Paper>
    )
  }
}

Show.propTypes = {
  model: PropTypes.shape({
    barcode: PropTypes.string,
    communication_address: PropTypes.string,
    part_number: PropTypes.string,
    id: PropTypes.number,
    pin: PropTypes.sting,
    status: PropTypes.string,
    v4_part_number_registry_id: PropTypes.number
  }),
}

export default withStyles(styles)(Show)
