import React from 'react'
import PropTypes from 'prop-types'
import { Form as FinalForm } from 'react-final-form'

import { RailsForm } from '@support/RailsForm'
import { validate } from '@models/accounts'

import Form from './Form'

export const New = ({ model, organizations, o }) => (
  <RailsForm
    component={FinalForm}
    action='create'
    url={`/v4/o/${o}/accounts`}
    validate={validate}
    initialValues={model}
    successUrl={`/v4/o/${o}/account/new`}
    render={(props) => (
      <Form
        {...props}
        organizations={organizations}
      />
    )}
  />
)

New.propTypes = {
  model: PropTypes.object,
  organizations: PropTypes.array
}

export default New
