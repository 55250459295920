import React, { Component } from 'react'

import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Typography from '@material-ui/core/Typography'
import EmptyCartIcon from '@material-ui/icons/RemoveShoppingCart'
import withStyles from '@material-ui/core/styles/withStyles'

import styles from './styles'

const Empty = ({ classes }) =>
  <List className={classes.cart__list}>
    <ListItem className={classes.cart__item}>
      <Grid container className={classes.root}>
        <Grid item xs={12} className={classes.cart__empty}>
          <Typography className={classes.empty__message}>
            {I18n.t('v4/cart.empty')}
          </Typography>
          <EmptyCartIcon className={classes.cart__icon}/>
        </Grid>
      </Grid>
    </ListItem>
  </List>

export default withStyles(styles)(Empty)
