import React from 'react'

import Button from '@material-ui/core/Button'
import TextField from '@material-ui/core/TextField'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import withStyles from '@material-ui/core/styles/withStyles'

import styles from './styles'

const NewAreaForm = ({ 
  classes, 
  size_ha, 
  handleAreaNameChange, 
  handleClose, 
  handleSave, 
  saveArea
 }) => (
  <div>
    <DialogTitle id="new-area-form-dialog-title">
      {I18n.t('v4/area.new.title')}
    </DialogTitle>

    <DialogContent>
      <DialogContentText>
        {I18n.t('v4/area.new.area_size')}
        <span className={classes.bold}>
          {` ${size_ha}.`}
        </span>
      </DialogContentText>
      <DialogContentText>
        {I18n.t('v4/area.new.save_confirmation')}
      </DialogContentText>
      <TextField
        onChange={handleAreaNameChange}
        margin="normal"
        id="area-name-input"
        label={I18n.t('activerecord.attributes.v4/area.name')}
        fullWidth
      />
    </DialogContent>

    <DialogActions>
      <Button
        onClick={handleClose}
        color="secondary"
        disabled={saveArea}
      >
        {I18n.t('actions.cancel')}
      </Button>
      
      <Button
        onClick={handleSave}
        color="primary"
        variant="contained"
        disabled={saveArea}
      >
        {I18n.t('actions.save')}
      </Button>
    </DialogActions>
  </div>
)

export default withStyles(styles)(NewAreaForm)
