import React from 'react'
import Table from '@ui/Table'
import { getAreaHeaders } from '@models/area'

const AreasTable = ({ areas, updateAreas }) =>
  <Table
    tableKey='maps-newAreasTable'
    columns={getAreaHeaders()}
    data={areas}
    options={{
      search: false,
      paging: false
    }}
    components={{
      Toolbar: () => null
    }}
    editable={{
      onRowUpdate: (newData, oldData) =>
        new Promise(resolve => {
          const data = [...areas]
          data[data.indexOf(oldData)].name = newData.name

          updateAreas(data)

          resolve()
        }),
      onRowDelete: oldData =>
        new Promise(resolve => {
          const data = [...areas]
          data.splice(data.indexOf(oldData), 1)

          updateAreas(data)

          resolve()
        }),
    }}
  />

AreasTable.defaultProps = {
  areas: [],
  updateAreas: () => { }
}

export default AreasTable
