import React from 'react'

import { RailsForm } from '@support/RailsForm'
import { Form as FinalForm } from 'react-final-form'
import { validate } from '@models/account/billing_info'

import withStyles from '@material-ui/core/styles/withStyles'

import styles from '@styles/indexPage'
import PaymentInfoTabs from '../PaymentInfoTabs'
import Form from './Form'

const Edit = ({ model, o }) => {
  const { organization_id } = model
  const tab = 'billing_info'

  const deserializeModel = (modelObject) =>
    Object.keys(modelObject).reduce((object, key) => {
      if (modelObject[key] == null) {
        object[key] = ''

        return object
      }

      object[key] = modelObject[key]

      return object
    }, {})

  const initialValues = deserializeModel(model)

  return (
    <PaymentInfoTabs tab={tab}>
      <RailsForm
        component={FinalForm}
        action='update'
        url={`/v4/o/${o}/organizations/${organization_id}/billing_info`}
        successUrl={`/v4/o/${o}/organizations/${organization_id}/billing_info`}
        validate={validate}
        initialValues={initialValues}
        render={(props) => (
          <Form
            {...props}
            title={I18n.t('activerecord.attributes.v4/organization_account.actions.edit')}
            organization_id={organization_id}
          />
        )}
      />
    </PaymentInfoTabs>
  )
}

export default withStyles(styles)(Edit)
