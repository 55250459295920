import React from 'react'

import { withStyles, Typography } from '@material-ui/core'

const ColorsLegend = ({ classes, scale }) => {
  try {
    scale = JSON.parse(scale)
  }
  catch (error) {
    console.error('scale is not a JSON')
  }

  return (
    <div className={classes.ColorsLegend_colors_list}>
      {
        scale.gradients.map((item, index) =>
          <div
            className={classes.ColorsLegend_item}
            key={index}
          >
            <div
              className={classes.ColorsLegend_color}
              style={{
                background: index === scale.gradients.length - 1
                  ? item.color
                  : `linear-gradient(${item.color}, ${scale.gradients[index + 1].color})`
              }}
            >
            </div>

            <Typography className={classes.ColorsLegend_label} variant='body1'>
              {item.label}
            </Typography>
          </div>
        )
      }
    </div>
  )
}

const styles = theme => ({
  ColorsLegend_colors_list: {
    paddingInlineStart: '0px',
    textAlign: 'center',
    display: 'flex',
    width: '100%'
  },
  ColorsLegend_item: {
    listStyleType: 'none',
    fontSize: '10px',
    maxHeight: '15px',
    width: '100%'
  },
  ColorsLegend_color: {
    height: '15px',
    width: '100%'
  },
  ColorsLegend_label: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    height: '15px',
    fontWeight: 'bold',
    fontSize: '100%'
  }
})

ColorsLegend.defaultProps = {
  scale: {}
}

export default withStyles(styles)(ColorsLegend)
