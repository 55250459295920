import styles from '@theme/styles/forms'

export default theme => ({
  ...styles(theme),
  Whatsapp_Container: {
    display: 'flex',
    alignItems: 'center'
  },
  Whatsapp_Wrapper: {
    flex: 1,
    minWidth: '48px',
    minHeight: '48px'
  },
  Whatsapp_Label: {
    flex: 8,
    fontSize: '12px',
  }
})
