import React from 'react'

import withStyles from '@material-ui/core/styles/withStyles'
import styles from '@styles/forms'

import { Button, Typography } from '@material-ui/core'

import Step from './Step'

const SelectEquipmentStep = ({ classes, advanceStep, retreatStep, dataSources, areaName, idArea, isFromLastStep }) => {
  const [equipments, setSelectedEquipment] = React.useState({
    selected: {}
  })

  const handleSelectEquipment = (selected) => {
    setSelectedEquipment({
      selected
    })
  }

  return (
    <Step
      title={I18n.t('v4/alerts.new.steps.select_equipment.title')}
      advanceStep={() => advanceStep(equipments.selected, idArea, isFromLastStep)}
      retreatStep={retreatStep}
      disableButton={!equipments.selected.data_source_id}
    >
      <div className={classes.SelectEquipmentStep__info__wrapper} >
        <Typography className={classes.SelectEquipmentStep_info}>
          {
            `${I18n.t('v4/alerts.new.steps.select_equipment.info_alert',
              {
                kind: I18n.t(`v4/alerts.products.${dataSources[0].kind}`)
              }
            )}:`
          }
        </Typography>
      </div>
      <div className={classes.SelectEquipmentStep__info__wrapper} >
        <Typography variant='subtitle2'>
          {areaName}
        </Typography>
      </div>
      <div className={classes.SelectEquipmentStep__indo__wrapper} >
        <Typography className={classes.SelectEquipmentStep_info} >
          {I18n.t('v4/alerts.new.steps.select_equipment.info')}:
        </Typography>
      </div>
      <div className={classes.SelectEquipmentStep_sensor__wrapper}>
        {
          dataSources.map(data =>
            <div className={classes.SelectEquipmentStep_sensor} key={`equipment_id${data.data_source_id}`}>
              <Button
                color={data.data_source_id === equipments.selected.data_source_id ? 'primary' : 'default'}
                type='submit'
                variant='contained'
                onClick={() => handleSelectEquipment(data)}
              >
                {data.name}
              </Button>
            </div>
          )}
      </div>
    </Step>
  )
}

const customStyles = theme => ({
  ...styles(theme),
  SelectEquipmentStep__header: {
    margin: `${theme.spacing.unit * 1}px auto`,
    padding: theme.spacing.unit * 2,
    maxWidth: `${theme.spacing.unit * 87.5}px`
  },
  SelectEquipmentStep__info__wrapper: {
    paddingBottom: `${theme.spacing.unit + 2}px`,
    textAlign: 'center'
  },
  SelectEquipmentStep_info: {
    fontWeight: '400',
    textAlign: 'center'
  },
  SelectEquipmentStep_sensor__wrapper: {
    display: 'flex',
    padding: `${theme.spacing.unit + 2}px `,
    alignItems: 'center',
    flexDirection: 'column'
  },
  SelectEquipmentStep_sensor: {
    minHeight: `${theme.spacing.unit * 9.3}px`,
    display: 'block'
  }
})

export default withStyles(customStyles)(SelectEquipmentStep)
