import React from 'react'
import { Form as FinalForm } from 'react-final-form'
import PropTypes from 'prop-types'
import { RailsForm } from '@support/RailsForm'
import Form from './Form'
import { validate } from '@models/admin/coupon'

export const Edit = ({ plans, store_products, model, discount_types, o }) => (
  <RailsForm
    component={FinalForm}
    action='update'
    validate={validate}
    initialValues={model}
    url={`/v4/o/${o}/store/admin/coupons/${model.id}`}
    successUrl={`/v4/o/${o}/store/admin/coupons`}
    render={(props) => (
      <Form
        {...props}
        title={I18n.t('v4/coupons.actions.edit')}
        plansList={plans}
        productList={store_products}
        discountType={discount_types}
        o={o}
      />
    )}
  />
)

Edit.propTypes = {
  model: PropTypes.object,
}

export default Edit
