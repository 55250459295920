import React from 'react'

import { Grid, Paper, Typography, withStyles } from '@material-ui/core'

import Card from './Card/Card'
import NoEquipments from './NoEquipments'
import Loading from '@ui/LoadingComponent'

import { withHttpService } from '@contexts/HttpServiceProvider'
import { withErrorHandler } from '@contexts/ErrorHandlerProvider'
import { apiService } from '@services/httpService'
import { withSessionData } from '@contexts/SessionDataProvider'

import { deserializeEquipments } from '@models/equipment/equipment'

import styles from '@styles/indexPage'

class Index extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      data: [],
      loading: false,
      loadingOffline: false
    }
  }

  fetchData = async () => {
    const {
      errorHandler,
      httpService: { apiRequest },
      current_organization_id
    } = this.props

    const params = { organization_id: current_organization_id }
    const url = 'widgets/equipment_measures'

    try {
      this.setState({ loading: true })

      const { data } = await apiRequest(url, params)
      const deserializedData = deserializeEquipments(data)

      this.setState({ data: deserializedData })
    }
    catch (error) {
      errorHandler(error)
    }

    this.setState({ loading: false })
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchOfflineStatus = async (equipment, status) => {
    const {
      errorHandler,
      sessionData: { currentOrganization }
    } = this.props

    const equipmentId = equipment.equipment_id
    const params = {
      organization_id: currentOrganization.id,
      equipment: {
        installed: `${status}`
      }
    }

    try {
      this.setState({ loadingOffline: true })

      await apiService().put(`/api/v4/equipments/${equipmentId}/installed`, params)
        .then(() => {
          const { data } = this.state

          data.find(equipmentData => {
            if (equipmentId === equipmentData.equipment_id) {
              equipmentData.equipment_status.installed = status
              return true
            }
          })

          this.setState({ data })
        })
    }
    catch (error) {
      errorHandler(error)
    }

    this.setState({ loadingOffline: false })
  }

  handleSubmitReadingEstimate = async (value, handleDialogOpenOrClose, equipmentId) => {
    const {
      errorHandler,
      sessionData: { currentOrganization }
    } = this.props

    const params = {
      organization_id: currentOrganization.id,
      measuring_adjustment: {
        adjusted_variable: 'water_estimate',
        adjusted_value: value.reading_estimate
      }
    }

    handleDialogOpenOrClose(true)

    try {
      await apiRequest.post(`equipments/${equipmentId}/measuring_adjustments`, params)

      this.fetchData()
    }
    catch (error) {
      errorHandler(error)
    }

    handleDialogOpenOrClose(false)
  }

  render() {
    const { classes } = this.props
    const { data, loading, loadingOffline } = this.state

    const sortedData = [...data]
    sortedData.sort((a, b) => a.name.localeCompare(b.name))

    return (
      <Paper className={classes.page__wrapper} elevation={0}>
        <header className={classes.page__header}>
          <Typography component='h2' variant='h5'>
            {I18n.t('v4/widgets.title')}
          </Typography>
        </header>

        <Grid container spacing={16} justify={loading ? 'center' : 'flex-start'}>
          {
            !_.isEmpty(data)
              ? sortedData.map(equipmentData =>
                <Card
                  data={equipmentData}
                  key={equipmentData.equipment_id}
                  fetchOfflineStatus={this.fetchOfflineStatus}
                  loadingOffline={loadingOffline}
                  handleSubmitReadingEstimate={this.handleSubmitReadingEstimate}
                />
              )
              : loading
                ? <Loading />
                : <NoEquipments />
          }
        </Grid>
      </Paper>
    )
  }
}

export default
  withSessionData(
    withHttpService(
      withErrorHandler(
        withStyles(styles)(Index)
      )
    )
  )
