import React from 'react'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import { Field } from 'react-final-form'
import TextField from '@ui/Fields/TextField'
import Select from '@ui/Fields/Select'
import DatePicker from '@ui/Fields/DatePicker'
import styles from '@theme/styles/forms'
import Grid from '@material-ui/core/Grid'

import PlansProducts from './plans_products/Index'
import PropTypes from 'prop-types'

class Form extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      edited: false,
      listProductPlan: []
    }
  }

  componentDidMount() {
    const { values } = this.props
    const { coupon_store_products } = values

    if (!_.isEmpty(coupon_store_products)) {

      const listProductPlan = coupon_store_products.map((coupon) => {
        const { id: order, plan, store_product } = coupon
        const { id: plan_id, name: plan_kind } = plan
        const { id: store_product_id, name: product } = store_product
        return { order, plan_id, plan_kind, store_product_id, product }
      })

      this.setState({ listProductPlan })
    }
  }

  getFieldsTable = (values) => {
    const { plansList, productList } = this.props

    const { name: plan_kind } = plansList.find(plan => plan.id === values.plan_id)
    const { name: product } = productList.find(product => product.id === values.store_product_id)

    const order = values.order
    const plan_id = values.plan_id
    const store_product_id = values.store_product_id

    return {
      plan_id,
      store_product_id,
      order,
      plan_kind,
      product
    }
  }

  formatValues = () => {
    const { listProductPlan } = this.state

    if (!_.isEmpty(listProductPlan)) {
      const coupon_store_products = listProductPlan.map(element => {
        let { plan_id, store_product_id } = element
        return { plan_id, store_product_id }
      })
      return coupon_store_products
    }
  }

  planProductsHandlers = {
    new: (values) => this.setState({
      listProductPlan: [...this.state.listProductPlan, this.getFieldsTable(values)],
      edited: true
    }),
    edit: (values) => this.setState({
      listProductPlan: this.state.listProductPlan.map(planProducts =>
        planProducts.order === values.order ? { ...planProducts, ...this.getFieldsTable(values) } : planProducts
      ),
      edited: true
    }),
    delete: (order) => this.setState({
      listProductPlan: this.state.listProductPlan.filter(planProducts =>
        planProducts.order !== order
      ).map(element => ({ ...element, order: order + 1 })),
      edited: true
    })
  }

  render() {
    const { handleSubmit, classes, title, discountType, plansList, productList, values, o } = this.props
    const coupon_store_products = this.formatValues()
    values.coupon_store_products = coupon_store_products ? coupon_store_products : values.coupon_store_products

    return (
      <form className={classes.form} onSubmit={handleSubmit} data-testid='coupon-form'>
        <Paper className={classes.form__wrapper} elevation={0}>
          <header className={classes.form__header}>
            <Typography component='h2' variant='h5'>
              {title}
            </Typography>
          </header>
          <main className={classes.form__fields}>
            <div className={classes.form__field__wrapper}>
              <Field
                component={TextField}
                className={classes.form__fieldWithError}
                margin='normal'
                fullWidth
                InputLabelProps={{ shrink: true }}
                variant='outlined'
                label={I18n.t('activerecord.attributes.v4/coupon.code')}
                name='code'
                type='string'
                required
              />
            </div>
            <Grid container spacing={24}>
              <Grid item xs={6}>
                <div className={classes.form__field__wrapper}>
                  <Field
                    component={Select}
                    className={classes.form__fieldWithError}
                    InputLabelProps={{ shrink: true, required: true }}
                    label={I18n.t('activerecord.attributes.v4/coupon.discount_type')}
                    name='discount_type'
                    margin='normal'
                    fields={{ id: 'id', name: 'name' }}
                    data={discountType}
                    fullWidth
                    variant='outlined'
                    required
                  >
                  </Field>
                </div>
              </Grid>
              <Grid item xs={6}>
                <div className={classes.form__field__wrapper} >
                  <Field
                    component={TextField}
                    className={classes.form__fieldWithError}
                    InputLabelProps={{ shrink: true, required: true }}
                    label={I18n.t('activerecord.attributes.v4/coupon.discount')}
                    name='discount'
                    margin='normal'
                    inputProps={{ step: '10' }}
                    fullWidth
                    variant='outlined'
                    required
                    type='number'
                  />
                </div>
              </Grid>
            </Grid>
            <div className={classes.form__field__wrapper} style={{ width: '98%' }}>
              <Field
                component={DatePicker}
                className={classes.form__fieldWithError}
                margin='normal'
                InputLabelProps={{ shrink: true }}
                variant='outlined'
                label={I18n.t('activerecord.attributes.v4/coupon.expiration_date')}
                name='expiration_date'
                initialValue={values.expiration_date || new Date()}
                required
                fullWidth
              />
            </div>
            <div className={classes.form__field__wrapper} >
              <Field
                component={TextField}
                className={classes.form__fieldWithError}
                InputLabelProps={{ shrink: true, required: true }}
                label={I18n.t('activerecord.attributes.v4/coupon.description')}
                name='description'
                margin='normal'
                fullWidth
                variant='outlined'
                required
              >
              </Field>
            </div>
            <PlansProducts
              plansList={plansList}
              productList={productList}
              listProductPlan={this.state.listProductPlan}
              handlers={this.planProductsHandlers}
            >
            </PlansProducts>
          </main>
          <footer className={classes.form__actions}>
            <Button
              className={classes.form__action}
              color='primary'
              type='submit'
              variant='contained'
            >
              {title}
            </Button>

            <Button
              className={classes.form__action}
              color='secondary'
              href={`/v4/o/${o}/store/admin/coupons`}
            >
              {I18n.t('actions.back')}
            </Button>
          </footer>
        </Paper>
      </form>
    )
  }
}


Form.defaultProps = {
  discountType: []
}

Form.propTypes = {
  discountType: PropTypes.array
}

export default withStyles(styles)(Form)
