import React from 'react'

import { Typography, withStyles } from '@material-ui/core'

const ConditionDays = ({ classes, condition, label }) =>
  <div className={classes.ConditionDays_wrapper}>
    <Typography variant='caption'>
      {label}
    </Typography>

    <div className={`${classes.ConditionDays_icon} ${classes[`ConditionDays_${condition}`]}`} />
  </div>

const styles = (theme) => ({
  ConditionDays_wrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  ConditionDays_icon: {
    width: '10px',
    height: '10px',
    borderRadius: '100%',
    marginLeft: theme.spacing.unit
  },
  ConditionDays_unsuitable: {
    background: theme.palette.red
  },
  ConditionDays_marginal: {
    background: theme.palette.marginalDrop
  },
  ConditionDays_preferred: {
    background: '#66A343'
  }
})

export default withStyles(styles)(ConditionDays)
