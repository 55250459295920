import React from 'react'

import { Grid, Typography, withStyles } from '@material-ui/core'

import { reportIrrigation } from '@models/equipment/irrigation'

import formStyles from '@theme/styles/forms'

import BoundedGraphic from './BoundedGraphic'
import PivotGraphic from './PivotGraphic'

const InformIrrigation = ({ classes, fieldStates, irrigationSystem }) => {
  const irrigationReport = reportIrrigation({ equipment: irrigationSystem, ...fieldStates })

  return !!irrigationReport && (
    <Grid item xs={12}>
      <div className={`${classes.form__field__wrapper} ${classes.ReportIrrigation_container}`}>
        <div className={classes.ReportIrrigation_text}>
          <Typography variant='body1'>
            <strong>
              { 
                I18n.t('v4/irrigation.irrigation_report.base', { 
                  irrigation: irrigationReport.irrigationInMm || irrigationReport.irrigationTrue
                }) 
              }
            </strong>
            { 
              irrigationSystem.irrigation_system_type === 'pivot'
                ? ` ${I18n.t(`v4/irrigation.irrigation_report.pivot.${irrigationReport.irrigationPivotSize}`)}` 
                : ` ${I18n.t('v4/irrigation.irrigation_report.non_pivot')}`
            }
          </Typography>
        </div>
        <div className={classes.ReportIrrigation_graph}>
          {
            irrigationSystem.irrigation_system_type === 'pivot'
              ? (
                <PivotGraphic
                  areaPercentage={irrigationReport.areaPercentage}
                  irrigationDegrees={irrigationReport.irrigationDegrees / 3.6}
                />
              )
              : <BoundedGraphic operations={irrigationReport.operations}/>
          }
        </div>
      </div>
    </Grid>
  )
}

const styles = theme => ({
  ...formStyles(theme),
  ReportIrrigation_container: {
    display: 'flex',
    alignItems: 'center'
  },
  ReportIrrigation_text: {
    textAlign: 'center',
    flex: 4
  },
  ReportIrrigation_graph: {
    flex: 1
  }
})

export default withStyles(styles)(InformIrrigation)
