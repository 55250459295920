import styles from '@styles/indexPage'

export default (theme) => ({
  ...styles(theme),
  page__wrapper: {
    backgroundColor: '#FAFAFA',
    margin: `${theme.spacing.unit * 5}px auto 0`,
    maxWidth: 1200,
    padding: theme.spacing.unit * 4,
  },
  page__header: {
    marginBottom: theme.spacing.unit * 4,
    marginLeft: theme.spacing.unit * 2
  },
  page__card_container: {
    display: 'flex',
    flexDirection: 'column',
    padding: theme.spacing.unit * 2,
    backgroundColor: '#FFFFFF'
  },
  page__switch_category: {
    paddingTop: theme.spacing.unit * 2,
    paddingBottom: theme.spacing.unit,
  },
  page__switch_item: {
    display: 'flex',
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
    gap: `${theme.spacing.unit * 3}px`,
  },
  page__card_header: {

  },
})
