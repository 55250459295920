export default theme => ({
  organizations__item: {
    background: theme.palette.gray.light,
    display: 'flex',
  },
  organizationsMenu__footer: {
    justifyContent: 'space-between'
  },
  organizationsMenu__list: {
    padding: 0,
    maxHeight: '250px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '10px',
      borderRadius: '10px'
    },
    '&::-webkit-scrollbar-track': {
      background: theme.palette.gray.light,
      borderRadius: '10px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: '10px'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme.palette.primary.dark
    }
  },
  organization__label: {
    fontSize: 13,
    textAlign: 'center',
    padding: '10px'
  },
  organizationsMenu__button: {
    textTransform: 'none',
    fontSize: 13,
    minHeight: 30,
    paddingLeft: 10,
    paddingRight: 10,
  },
  organizations__icon: {
    color: theme.palette.primary.dark,
    width: 18,
    height: 18,
    display: 'flex',
    justifyContent: 'center',
    fontWeight: 500
  },
  organizations__icon__selected: {
    color: theme.palette.primary.dark,
    width: 26,
    height: 27,
    display: 'flex',
    justifyContent: 'center',
    fontSize: 20,
    fontWeight: 600
  },
  organizations__icon__selected__wrapper: {
    background: theme.palette.primary.light,
    borderRadius: 100,
    marginRight: 20,
    padding: 20,
    fontSize: 25,
  },
  organizations__icon__list__wrapper: {
    padding: 10,
    background: theme.palette.primary.light,
    borderRadius: 100,
    marginRight: 20,
  },
  organizations__edit: {
    textTransform: 'none',
    paddingLeft: 10,
    paddingRight: 10,
    marginTop: 5,
    marginBottom: 5,
    fontSize: 13,
    fontWeight: 600,
    minHeight: 30,
  },
  Navbar__Icon: {
    color: theme.menus.icons
  },
  Popover__List: {
    overflow: 'hidden',
    maxWidth: '500px'
  },
  organization__header : {
    height: '40px',
    width: '100%',
    background: '#d9d9d9'
  },
  organizationsMenu_terms: {
    height: theme.spacing.unit * 8,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  organizationsMenu_termsLink: {
    color: theme.palette.text.primary,
    fontSize: 13,
    padding: `0 ${theme.spacing.unit}px`
  }
})
