
import React from 'react'
import PropTypes from 'prop-types'

import SampleCard from './SampleCard'
import LoadingComponent from '@ui/LoadingComponent'
import withStyles from '@material-ui/core/styles/withStyles'
import Select from '@ui/Fields/Select'
import SearchSelect from '@ui/Fields/SearchSelect'

import httpService from '@services/httpService'
import FilterForm from './FilterForm'
import styles from './styles'

class Index extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      fetchingImages: false,
      filter: null,
      filterId: '',
      images: [],
      currentArea: '',
      selectedArea: '',
      selectedImageDate: null
    }
  }

  handleChangeArea = (selectedArea) => {
    const { handleSelectArea, areas } = this.props
    const areaId = selectedArea.value
    const currentArea = areas.find(area => area.id === areaId)

    handleSelectArea([currentArea.latitude, currentArea.longitude], currentArea.size_ha)
    this.setState({ selectedArea, currentArea, images: [] })
  }

  handleFilterChange = (event) => {
    const filters = this.props.data
    const filterId = event.target.value
    const filter = filters.find(filter => filter.id === filterId)

    this.setState({
      filter,
      filterId
    })
  }

  handleFilter = async (values) => {
    const { selectedArea, filterId } = this.state
    const { organizationId, handleError } = this.props

    const params = {
      ...values,
      area_id: selectedArea.value,
      organization_id: organizationId,
      filter_id: filterId
    }

    this.setState({
      fetchingImages: true
    })

    try {
      const orderImages = (images) => _.orderBy(images, 'date')

      const { data } = await httpService.get('/api/v4/satellite_images', { params })

      const images = orderImages(data.images)

      if (_.isEmpty(images)) {
        handleError(I18n.t('v4/satellite_images.errors.no_image'))
      }

      this.setState({
        images,
        selectedImageDate: null,
        fetchingImages: false
      })
    }
    catch (error) {
      handleError(error)
      this.setState({
        fetchingImages: false
      })
    }
  }

  render() {
    const {
      selectedArea, filter, filterId,
      images, fetchingImages, currentArea,
      selectedImageDate,
    } = this.state
    const { classes, areas, applyFilter, removeFilter, data } = this.props

    return (
      <div className={classes.SatelliteImages}>
        <div className={classes.SatelliteImages__filter_form}>
          <div className={classes.SatelliteImages__filter_form__area_select}>
            <SearchSelect
              fullWidth
              margin='normal'
              data={areas}
              fields={{ label: 'name', value: 'id' }}
              variant='outlined'
              placeholder={I18n.t('actions.search')}
              autocomplete={true}
              textFieldProps={{
                label: I18n.t('activerecord.models.v4/area.one'),
                InputLabelProps: {
                  shrink: true,
                }
              }}
              disabled={fetchingImages}
              onChange={this.handleChangeArea}
              value={selectedArea}
            />
          </div>

          {
            this.state.selectedArea &&
            <Select
              InputLabelProps={{ shrink: true }}
              label={I18n.t('v4/satellite_images.attributes.filter')}
              name='filterId'
              margin='normal'
              fields={{ id: 'id', name: 'name' }}
              data={data}
              fullWidth
              onChange={this.handleFilterChange}
              value={filterId}
              disabled={fetchingImages}
              variant='outlined'
            />
          }
        </div>

        <FilterForm
          classes={classes}
          filter={this.state.filter}
          disabled={fetchingImages}
          handleSubmit={this.handleFilter}
        />

        {
          fetchingImages
            ? <LoadingComponent />
            : !_.isEmpty(images) &&
            <div className={classes.SatelliteImages__cards}>
              {
                images.map((image, index) =>
                  <SampleCard
                    key={index}
                    selected={selectedImageDate === image.date}
                    classes={classes}
                    data={image}
                    handleClick={() => {
                      if (selectedImageDate !== image.date) {
                        this.setState({
                          selectedImageDate: image.date
                        }, () => {
                          applyFilter(image.image, filter.legend_info, currentArea)
                        })
                      }
                      else {
                        this.setState({
                          selectedImageDate: null
                        }, () => {
                          removeFilter()
                        })
                      }
                    }}
                  />
                )
              }
            </div>
        }
      </div>
    )
  }
}

Index.defaultProps = {
  handleSelectArea: () => { },
  applyFilter: () => { },
  areas: [],
  classes: {},
  data: []
}

Index.propTypes = {
  handleSelectArea: PropTypes.func,
  data: PropTypes.array,
  applyFilter: PropTypes.func,
  areas: PropTypes.array,
  classes: PropTypes.object
}

export default withStyles(styles)(Index)
