import React from 'react'

import { Button, withStyles } from '@material-ui/core'

const Action = ({
  classes,
  variant,
  onChange,
  label
}) =>
  <Button
    color='primary'
    className={classes.Action}
    onClick={onChange}
    variant={variant}
  >
    {label}
  </Button>

const styles = theme => ({
  Action: {
    width: '100%',
    '&:not(:last-child)': {
      marginRight: theme.spacing.unit * 2
    }
  }
})

export default withStyles(styles)(Action)
