import React from 'react'
import PropTypes from 'prop-types'


import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import Table from '@ui/Table'

import styles from '@styles/indexPage'

const Index = ({ model, classes, o }) =>
  <Paper className={classes.page__wrapper} elevation={0}>
    <header className={classes.page__header}>
      <Typography component='h2' variant='h5'>
        {I18n.t('v4/hardware_products.index.title')}
      </Typography>
    </header>
    <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
      <Button
        variant='outlined'
        color='primary'
        href={`/v4/o/${o}/admin/hardware_products/new`}
      >
        {I18n.t('v4/hardware_products.actions.create')}
      </Button>
      <Button
        variant='outlined'
        color='primary'
        className={classes.page__part_numbers}
        href={`/v4/o/${o}/admin/part_number_registries`}
      >
        {I18n.t('v4/hardware_products.index.button')}
      </Button>
    </div>

    <Table
      tableKey='admin-hardwareProducts-index'
      components={{
        Container: props => <div>{props.children}</div>,
      }}
      columns={[
        { title: I18n.t('v4/hardware_products.labels.barcode'), field: 'barcode' },
        { title: I18n.t('v4/hardware_products.labels.part_number'), field: 'part_number' },
        { title: I18n.t('v4/hardware_products.labels.pin'), field: 'pin' },
        { title: I18n.t('v4/hardware_products.labels.status'), field: 'status' },
        { title: I18n.t('v4/hardware_products.labels.identifier'), field: 'identifier' },
        {
          title: I18n.t('v4/hardware_products.labels.globalstar'),
          field: 'notes1',
          render: rowData => <span>
            {!!rowData.notes1
              ? I18n.t(`v4/hardware_products.booleans.${rowData.notes1}`)
              : I18n.t('v4/hardware_products.booleans.f')
            }
          </span>
        }
      ]}
      data={model}
      actions={[
        rowData => ({
          icon: 'create',
          iconProps: { 'data-testid': `edit-${rowData.id}` },
          tooltip: I18n.t('v4/hardware_products.actions.edit'),
          onClick: (event, rowData) => {
            Turbolinks.visit(`/v4/o/${o}/admin/hardware_products/${rowData.id}/edit`)
          }
        })
      ]}
      options={{
        showTitle: false
      }}
    />
  </Paper>

Index.propTypes = {
  model: PropTypes.array
}

export default withStyles(styles)(Index)
