import React from 'react'

import { Button, Grid, IconButton, Paper, Typography, withStyles } from '@material-ui/core'
import { Close } from '@material-ui/icons'

import { useMenusStatus, MENUS } from '@contexts/MenusStatusProvider'

const FixedDialog = ({ classes, getCenterOfView }) => {
  const { menus, setMenuData, toggleMenu } = useMenusStatus()
  const { data } = menus[MENUS.weatherForecastProduct]

  const changeContext = (field, value) => {
    setMenuData(MENUS.weatherForecastProduct, field, value)
  }

  const getStep = () => {
    const contextStep = menus[MENUS.weatherForecastProduct].step

    switch(contextStep) {
      case 0: return(
        <IsVisible
          classes={classes}
          handleIsVisibleClick={handleIsVisibleClick}
          handleNotVisibleClick={handleNotVisibleClick}
        />
      )
      case 1: return <MapTouch classes={classes} />
      case 2: return <SearchByPlace classes={classes} handleFoundClick={handleFoundClick}/>
    }
  }

  const handleIsVisibleClick = () => {
    changeContext('onMapFlow', true)
    changeContext('step', 1)
  }

  const handleNotVisibleClick = () => {
    changeContext('onMapFlow', true)
    changeContext('onSearchFlow', true)
    changeContext('step', 2)
  }

  const handleFoundClick = () => {
    if(_.isEmpty(data)) {
      const coordinates = getCenterOfView()
      changeContext('data', {
        ...data,
        lat: coordinates.lat,
        lon: coordinates.lng
      })
    }

    toggleMenu(MENUS.weatherForecastProduct, true)
    changeContext('step', 2)
    changeContext('onMapFlow', false)
    changeContext('onFlow', true)
  }

  const handleClose = () => {
    changeContext('onMapFlow', false)
    changeContext('step', 0)
    changeContext('data', {})
    toggleMenu([MENUS.weatherForecastSetupFixedModal], false)
  }

  return(
    <Paper className={classes.FixedDialog_wrapper}>
      <IconButton className={classes.FixedDialog_close} onClick={handleClose}>
        <Close className={classes.FixedDialog_icon}/>
      </IconButton>
      {getStep()}
    </Paper>
  )
}

const IsVisible = ({ classes, handleIsVisibleClick, handleNotVisibleClick }) =>
  <>
    <Typography variant='body2' align='center' className={classes.FixedDialog_title}>
      {I18n.t('v4/forecast.configuration.is_visible')}
    </Typography>

    <Grid container spacing={0}>
      <Grid item xs={6} className={classes.FixedDialog_button}>
        <Button
          color='primary'
          onClick={handleNotVisibleClick}
        >
          {I18n.t('false')}
        </Button>
      </Grid>
      <Grid item xs={6} className={classes.FixedDialog_button}>
        <Button
          color='primary'
          variant='contained'
          onClick={handleIsVisibleClick}
        >
          {I18n.t('true')}
        </Button>
      </Grid>
    </Grid>
  </>

const SearchByPlace = ({classes, handleFoundClick}) =>
  <>
    <Typography variant='body2' className={classes.FixedDialog_title}>
      {I18n.t('v4/forecast.configuration.atmos')}
    </Typography>

    <Typography variant='body2' align='center' className={classes.FixedDialog_bold}>
      {I18n.t('v4/forecast.configuration.find_place')}
    </Typography>
    <Typography variant='body1' align='center' className={classes.FixedDialog_light}>
      {I18n.t('v4/forecast.configuration.search_tip')}
    </Typography>

    <div className={classes.FixedDialog_foundButtonWrapper}>
      <Button
        color='primary'
        variant='contained'
        onClick={handleFoundClick}
        className={classes.FixedDialog_foundButton}
      >
        {I18n.t('v4/forecast.configuration.found_button')}
      </Button>
    </div>
  </>

const MapTouch = ({ classes }) =>
  <>
    <Typography variant='body2' className={classes.FixedDialog_title}>
      {I18n.t('v4/forecast.configuration.atmos')}
    </Typography>
    <Typography variant='body2' align='center' className={classes.FixedDialog_bold}>
      {I18n.t('v4/forecast.configuration.map_touch')}
    </Typography>
    <Typography variant='body1' align='center' className={classes.FixedDialog_light}>
      {I18n.t('v4/forecast.configuration.map_define')}
    </Typography>
  </>

const styles = (theme) => ({
  FixedDialog_wrapper: {
    position: 'fixed',
    bottom: theme.spacing.unit * 2,
    zIndex: 1050,
    left: '50%',
    padding: `${theme.spacing.unit * 3}px ${theme.spacing.unit * 5}px`,
    transform: 'translate(-50%, 0%)',
    '@media(max-width: 600px)': {
      padding: `${theme.spacing.unit * 3}px ${theme.spacing.unit * 5}px`,
      bottom: 0,
      left: 0,
      right: 0,
      transform: 'inherit'
    },
    '@media (max-width: 773px) and (min-width: 601px)': {
      width: '65%'
    }
  },
  FixedDialog_close: {
    position: 'absolute',
    top: theme.spacing.unit,
    right: theme.spacing.unit,
    padding: theme.spacing.unit * 0
  },
  FixedDialog_icon: {
    fontSize: '0.8em'
  },
  FixedDialog_title: {
    marginBottom: `${theme.spacing.unit * 0.17}em`
  },
  FixedDialog_button: {
    textAlign: 'center'
  },
  FixedDialog_light: {
    fontWeight: 400,
    fontSize: '10.5pt'
  },
  FixedDialog_foundButtonWrapper: {
    textAlign: 'center'
  },
  FixedDialog_foundButton: {
    width: theme.spacing.unit * 22,
    marginTop: theme.spacing.unit * 3
  }
})

export default withStyles(styles)(FixedDialog)
