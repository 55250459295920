export const fields = [
  { name: 'name' },
  { name: 'description' },
  {
    name: 'sensors', render: (sensors) =>
      sensors.map((sensor, index) =>
        index === sensors.length - 1
          ? sensor.name
          : `${sensor.name}, `)
  },
  {
    name: 'part_number',
    render: (partNumber) => partNumber.part_name
      ? partNumber.part_name
      : ''
  }
]
