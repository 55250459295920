import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'

import { Form as FinalForm } from 'react-final-form'

import { filterOrderOptions } from '@models/irrigation_managements'
import { RailsForm } from '@support/RailsForm'

import Filter from './Filter'

const Index = ({
  availableDates,
  orderBy,
  classes,
  equipmentsList,
  setSortedData,
  disabled,
  hiddenFields
}) => (
  <RailsForm
    component={FinalForm}
    handleSubmit={values => setSortedData(values)}
    render={props => (
      <Filter
        availableDates={availableDates}
        orderBy={orderBy}
        orderOptions={filterOrderOptions}
        hiddenFields={hiddenFields}
        disableFields={disabled}
        {...props}
      />
    )}
  />
)

Index.defaultProps = {
  availableDates: [],
  orderBy: [],
  hiddenFields: []
}

Index.propTypes = {
  availableDates: PropTypes.array,
  orderBy: PropTypes.array,
}

export default Index
