export default theme => ({
  support_icon: {
    color: theme.menus.icons
  },
  supportMenu_list: {
    padding: 0,
    maxHeight: '250px',
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '10px',
      borderRadius: '10px'
    },
    '&::-webkit-scrollbar-track': {
      background: theme.palette.gray.light,
      borderRadius: '10px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: theme.palette.primary.main,
      borderRadius: '10px'
    },
    '&::-webkit-scrollbar-thumb:hover': {
      background: theme.palette.primary.dark
    }
  },
  support_item: {
    background: theme.palette.gray.light,
    display: 'flex',
  },
})
