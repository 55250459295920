import React from 'react'

import { Grid, Typography } from '@material-ui/core'

const DateAndTime = ({ dateAndTime }) =>
  <Grid item xs={12}>
    <Typography variant='caption'>
      {
        `${I18n.t('v4/equipment.dashboard.last_update.info')} 
      ${dateAndTime.infoDate} 
      ${dateAndTime.infoHour}`
      }
    </Typography>
  </Grid>

export default DateAndTime
