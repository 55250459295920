import React from 'react'

import WeatherForecast from './Atmos/Wrapper'
import SatelliteImageryProduct from './View/Index'
import IrrigationMenu from './IrrigationManagement/index'

import { MENUS, withMenusStatus } from '@contexts/MenusStatusProvider'

const ProductsFloatingMenu = ({
  addPin,
  cleanLayer,
  mapAreas,
  changeMapCenter,
  changeAreasColors,
  removeColorsFromAreas,
  menusStatus: { menus },
  hasForecastProduct
}) => <>
    {
      (
       menus[MENUS.weatherForecastProduct].open
       || menus[MENUS.weatherForecastSetupLatLongModal].open
       || menus[MENUS.weatherForecastSetupFixedModal].open
      )
       && <WeatherForecast addPin={addPin} cleanLayer={cleanLayer}/>
    }

    {
      menus[MENUS.satelliteImageryProduct].open &&
      <SatelliteImageryProduct
        areas={mapAreas}
        changeMapCenter={changeMapCenter}
      />
    }

    {
      menus[MENUS.irrigationProduct].open &&
      <IrrigationMenu
        changeAreasColors={changeAreasColors}
        removeColorsFromAreas={removeColorsFromAreas}
        hasForecastProduct={hasForecastProduct}
      />
    }
  </>

export default withMenusStatus(ProductsFloatingMenu)
