import React from 'react'
import {
  ResponsiveContainer, ComposedChart, XAxis, YAxis,
  Tooltip, Line, Bar, Area, CartesianGrid, Legend,
} from 'recharts'
import { getYAxisOrientation, getMinMaxValues, chartsFontFamily } from '@models/chart'
import PropTypes from 'prop-types'

import CustomTooltip from '../ToolTip'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from '../../styles'

const DefaultChart = ({ data, xData, classes, descriptors, showingData, handleToggleData, getDescriptor }) => {
  const chartDomains = getMinMaxValues(data, showingData)

  return (
    <ResponsiveContainer width='100%' height={400}>
      <ComposedChart
        data={data}
      >
        <CartesianGrid strokeDasharray='3 3' />

        <XAxis
          fontFamily={chartsFontFamily}
          dataKey={xData}
        />

        {
          showingData.filter(serie => !serie.yAxisId).map((serie, index) =>
            <YAxis
              yAxisId={serie.id}
              key={serie.id}
              domain={chartDomains}
              orientation={getYAxisOrientation(index)}
              fontFamily={chartsFontFamily}
            />
          )
        }

        <Legend
          onClick={handleToggleData}
          formatter={value =>
            <span>
              {getDescriptor(descriptors, value).description}
            </span>
          }
        />

        <Tooltip
          content={
            <CustomTooltip
              series={showingData}
              getDescriptor={getDescriptor}
              descriptors={descriptors}
              classes={classes}
            />
          }
        />

        {
          showingData.map(serie =>
            chartsTypes[serie.type](serie, serie.hide)
          )
        }
      </ComposedChart>
    </ResponsiveContainer>
  )
}

const chartsTypes = {
  line: (data, hide) =>
    <Line
      type='monotone'
      strokeWidth={2}
      yAxisId={data.yAxisId || data.id}
      dataKey={data.id}
      key={data.id}
      stroke={data.color}
      hide={hide}
    />,
  bar: (data, hide) =>
    <Bar
      type='monotone'
      yAxisId={data.yAxisId || data.id}
      dataKey={data.id}
      key={data.id}
      stroke={data.color}
      fill={data.color}
      hide={hide}
    />,
  area: (data, hide) =>
    <Area
      type='monotone'
      yAxisId={data.yAxisId || data.id}
      dataKey={data.id}
      key={data.id}
      stroke={data.color}
      fill={data.color}
      hide={hide}
      fillOpacity={data.opacity || 0.5}
    />
}

DefaultChart.defaultProps = {
  data: [],
  series: []
}

DefaultChart.propTypes = {
  data: PropTypes.array,
  xData: PropTypes.string.isRequired,
  classes: PropTypes.object,
  series: PropTypes.array
}

export default withStyles(styles)(DefaultChart)
