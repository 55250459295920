export default theme => ({
  ChartTooltip: {
    width: 'fit-content',
    padding: `${theme.spacing.unit * 2}px`,
    borderRadius: '4px',
    boxShadow: theme.shadows[1],
    background: theme.palette.background.paper
  },
  DAPLabel: {
    color: theme.palette.black
  },
  PointData: {
    display: 'flex',
    alignItems: 'start',
    justifyContent: 'space-between',
    margin: `${theme.spacing.unit * 0.5}px 0`
  },
  PointData__value: {
    display: 'inline-flex',
    alignItems: 'center',
    marginLeft: theme.spacing.unit * 3
  },
  PointData__unit: {
    marginLeft: `${theme.spacing.unit / 4 + 1}px`
  }
})
