import React from 'react'

import { Typography, withStyles } from '@material-ui/core'

import CustomOdometer from './CustomOdometer'

const WrapperOdometer = ({ classes, value }) =>
  <div className={classes.WrapperOdometer}>
    {
      !!value &&
      value.split('').map((_value, index) =>
        <CustomOdometer
          key={`addLeadingZeros_${_value}_${index}`}
          value={_value}
          customId={`addLeadingZeros_${_value}_${index}`}
        />
      )
    }
    
    <Typography variant='h5'>
      m³
    </Typography>
  </div>

const styles = theme => ({
  WrapperOdometer: {
    width: '100%',
    margin: `${theme.spacing.unit * 2}px 0`,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'flex-end'
  }
})

export default withStyles(styles)(WrapperOdometer)
