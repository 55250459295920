import React, { Fragment } from 'react'

import withStyles from '@material-ui/core/styles/withStyles'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'

import Table from '@ui/Table'
import DeleteDialog from '../../../ui/DeleteDialog'

import styles from '@styles/indexPage'

import { getAccountHeaders } from '@models/account'

const Index = ({ classes, model, organization_id, o }) => {
  var [dialogOpen, setState] = React.useState(null)
  const tab = 'subscriptions'

  const openDialog = (value) => {
    setState(value)
  }

  const closeDialog = () => {
    setState(null)
  }

  return (
    <Paper className={classes.page__wrapper} elevation={0}>
      <header className={classes.page__header}>
        <Typography component='h2' variant='h5'>
          {I18n.t('v4/account.kinds.subscriptions')}
        </Typography>
      </header>
      <Table
        tableKey='admin-organizations-subscriptions-index'
        key='account-table'
        components={{
          Container: props => <div>{props.children}</div>
        }}
        columns={getAccountHeaders(tab)}
        data={model}
        options={{ showTitle: false }}
        actions={[
          rowData => ({
            icon: 'delete',
            iconProps: { 'data-testid': `delete-${rowData.id}` },
            tooltip: I18n.t('actions.remove'),
            onClick: (event, rowData) => {
              openDialog(rowData.id)
            }
          })
        ]}
      />
      <DeleteDialog
        dialogTitle={`${I18n.t('actions.remove')} ${I18n.t('v4/account.kinds.subscription')}?`}
        dialogText={I18n.t('confirmation.remove')}
        successUrl={`/v4/o/${o}/admin/organizations/${organization_id}/subscriptions`}
        deleteUrl={`/v4/o/${o}/admin/organizations/${organization_id}/subscriptions/${dialogOpen}`}
        onCancel={closeDialog}
        onClose={closeDialog}
        open={!!dialogOpen}
      />
    </Paper>
  )
}

export default withStyles(styles)(Index)

