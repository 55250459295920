import React from 'react'

import loadable from '@loadable/component'

import ContextsProvider from '@contexts'
import App from './App'

import { themeOptions } from '@theme/defaultTheme'

const getComponent = componentPath => loadable(() => import(`./${componentPath}`))

const getThemeOption = component => /maps/.test(component) ? themeOptions.light : themeOptions.default

const Index = ({ component, o, session_data, ...props }) => {
  const Component = getComponent(component)
  const themeOption = getThemeOption(component)

  return (
    <ContextsProvider
      o={o}
      sessionData={{ ...session_data, current_view: component }}
      themeOption={themeOption}
    >
      <App
        Component={Component}
        o={o}
        {...props}
      />
    </ContextsProvider>
  )
}

export default Index
