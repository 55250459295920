import React, { useEffect, useRef } from 'react'

import {
  IconButton,
  Popover,
  RootRef,
  withStyles
} from '@material-ui/core'

import { Apps as AppsIcon, Close as CloseIcon } from '@material-ui/icons'

import { MENUS, useMenusStatus, withMenusStatus } from '@contexts/MenusStatusProvider'
import { useRouter } from '@contexts/RouterProvider'

import ProductsList from './ProductsList'
import ExpiredTrial from '@ui/ExpiredTrial'

import styles from './styles'

const ProductsMenu = ({
  activeProducts,
  classes,
  currentView,
  menusStatus: { menus, setMenuData },
  expiredTrial
}) => {
  const { toggleMenu } = useMenusStatus()
  const { anchor, open } = menus[MENUS.mapProductsMenu]
  const iconRef = React.useRef()
  const router = useRouter()

  const handleOpen = event => {
    toggleMenu(MENUS.mapProductsMenu, true)
  }

  const handleClose = () => {
    setMenuData(MENUS.mapProductsMenu, 'anchor', null)
    toggleMenu(MENUS.mapProductsMenu, false)
  }

  useEffect(() => {
    setMenuData(MENUS.mapProductsMenu, 'anchor', iconRef.current)
  }, [anchor, open])

  return (
    <>
      <div data-intercom-target='products'>
        <RootRef rootRef={iconRef}>
          <IconButton
            className={classes.Navbar__Icon}
            aria-owns={open ? 'render-props-popover' : null}
            aria-haspopup='true'
            variant='contained'
            onClick={handleOpen}
          >
            <AppsIcon />
          </IconButton>
        </RootRef>
      </div>
      {
        (!_.isEmpty(activeProducts) || expiredTrial) &&
        <Popover
          open={open}
          anchorEl={anchor}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
        >
          {
            !_.isEmpty(activeProducts) && !expiredTrial &&
            <ProductsList
              classes={classes}
              products={activeProducts}
              currentView={currentView}
            />
          }

          {
            expiredTrial &&
            <div className={classes.ExpiredCardTrial_wrapper}>
              <IconButton
                aria-label='Close'
                className={classes.Dialog_close_button}
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>

              <div className={classes.ExpiredCardTrial_content}>
                <ExpiredTrial
                  handleCloseDialogTrial={handleClose}
                  intercomTarget='click_cta_menu_trial_expired'
                  router={router}
                />
              </div>
            </div>
          }
        </Popover>
      }
    </>
  )
}

export default withMenusStatus(withStyles(styles)(ProductsMenu))
