import React, { useState, useEffect } from "react";
import {
  FormControl,
  Grid,
  Paper,
  Typography,
  Switch,
  withStyles,
  Divider,
} from "@material-ui/core";
import { useHttpService } from "@contexts/HttpServiceProvider";
import { httpServiceNotification } from "@services/httpService";
import { sendAmplitudeEvent } from "@utils/amplitudeEvent";
import styles from "./styles";

export const Index = ({ classes }) => {
  const { request } = useHttpService();
  const [loading, setLoading] = useState(true);
  const [delayedIrrigationDepth, setDelayedIrrigationDepth] = useState({});
  const [delayedStage, setDelayedStage] = useState({});
  const [delayedCrop, setDelayedCrop] = useState({});

  const notificationAPIURL = `${process.env.NOTIFICATION_SERVICE_URL}/api/v1/notification_managements`
  const fetchNotifications = async () => {
    try {
      const { data } = await request(
        notificationAPIURL
      );
      const irrigationDepthData = data.find(
        (datum) => datum.kind === "delayed_irrigation_depth"
      );
      const phenologicalStageData = data.find(
        (datum) => datum.kind === "advance_phenological_stage"
      );
      const cropEventData = data.find(
        (datum) => datum.kind === "register_crop_event"
      );
      setDelayedIrrigationDepth(irrigationDepthData);
      setDelayedStage(phenologicalStageData);
      setDelayedCrop(cropEventData);
    } catch (error) {
      console.error(error);
    }
    setLoading(false);
  };

  useEffect(() => {
    fetchNotifications();
  }, []);

  const handleSwitchChange = async (kind) => {
    if (kind === "delayed_irrigation_depth") {
      try {
        const { data } = await httpServiceNotification.put(
          `${notificationAPIURL}/${delayedIrrigationDepth.id}`,
          { enable: !delayedIrrigationDepth.enable }
        );
        fetchNotifications();
      } catch (error) {
        console.error(error);
      }
    } else if (kind === "advance_phenological_stage") {
      try {
        const { data } = await httpServiceNotification.put(
          `${notificationAPIURL}/${delayedStage.id}`,
          { enable: !delayedStage.enable }
        );
        fetchNotifications();
      } catch (error) {
        console.error(error);
      }
    } else if (kind === "register_crop_event") {
      try {
        const { data } = await httpServiceNotification.put(
          `${notificationAPIURL}/${delayedCrop.id}`,
          { enable: !delayedCrop.enable }
        );
        fetchNotifications();
      } catch (error) {
        console.error(error);
      }
    }
  };

  return (
    <div className={classes.page__wrapper}>
      <header className={classes.page__header}>
        <Typography component="h1" variant="h5">
          {I18n.t("v4/organization.products.notifications.management")}
        </Typography>
      </header>

      <Grid container direction="column" alignItems="center">
        <Paper className={classes.page__card_container}>
          <Grid item className={classes.page__card_header}>
            <Typography component="h2" variant="h5" align="left">
              {I18n.t("v4/organization.products.notifications.enable_text")}
            </Typography>
            <Typography
              component="caption"
              variant="subtitle2"
              align="left"
              color="textSecondary"
            >
              {I18n.t("v4/organization.products.notifications.enable_subtitle")}
            </Typography>
          </Grid>

          <Grid item className={classes.page__switch_category}>
            <Typography variant="subtitle2" color="textSecondary" align="left">
              {I18n.t(
                "v4/organization.products.notifications.irrigation_management"
              )}
            </Typography>
          </Grid>
          <FormControl>
            <Grid
              item
              justify="space-between"
              className={classes.page__switch_item}
            >
              <Grid item>
                <Typography component="h3" variant="subtitle1">
                  {I18n.t("v4/organization.products.notifications.delayed")}
                </Typography>
              </Grid>
              <Grid item>
                <Switch
                  checked={
                    _.isEmpty(delayedIrrigationDepth)
                      ? false
                      : delayedIrrigationDepth.enable
                  }
                  disabled={loading}
                  onChange={() => {
                    handleSwitchChange("delayed_irrigation_depth");
                    sendAmplitudeEvent(
                      "Aqua Notification - Used irrigation toggle switch"
                    );
                  }}
                />
              </Grid>
            </Grid>
          </FormControl>

          <Divider className={classes.page__divider} />

          <Grid item className={classes.page__switch_category}>
            <Typography variant="subtitle2" color="textSecondary" align="left">
              {I18n.t("v4/organization.products.notifications.crop")}
            </Typography>
          </Grid>
          <FormControl>
            <Grid
              item
              justify="space-between"
              className={classes.page__switch_item}
            >
              <Grid item>
                <Typography component="h3" variant="subtitle1">
                  {I18n.t("v4/organization.products.notifications.harvest")}
                </Typography>
                <Typography
                  component="h4"
                  variant="subtitle2"
                  align="left"
                  color="textSecondary"
                >
                  {I18n.t("v4/organization.products.notifications.harvest_subtitle")}
                </Typography>
              </Grid>
              <Grid item>
                <Switch
                  checked={_.isEmpty(delayedCrop) ? false : delayedCrop.enable}
                  disabled={loading}
                  onChange={() => {
                    handleSwitchChange("register_crop_event");
                  }}
                />
              </Grid>
            </Grid>
          </FormControl>

          <FormControl>
            <Grid
              item
              justify="space-between"
              className={classes.page__switch_item}
            >
              <Grid item>
                <Typography component="h3" variant="subtitle1">
                  {I18n.t("v4/organization.products.notifications.phenological")}
                </Typography>
                <Typography
                  component="h4"
                  variant="subtitle2"
                  align="left"
                  color="textSecondary"
                >
                  {I18n.t("v4/organization.products.notifications.phenological_subtitle")}
                </Typography>
              </Grid>
              <Grid item>
                <Switch
                  checked={_.isEmpty(delayedStage) ? false : delayedStage.enable}
                  disabled={loading}
                  onChange={() => {
                    handleSwitchChange("advance_phenological_stage");
                  }}
                />
              </Grid>
            </Grid>
          </FormControl>

        </Paper>
      </Grid>
    </div>
  );
};

export default withStyles(styles)(Index);
