import React from 'react'

import { Typography, withStyles } from '@material-ui/core'
import styles from '@theme/styles/forms'

import Select from '@ui/Fields/Select'

const AreaChangeHandler = ({ areas, handleChange, label, noAreaLabel, classes }) => (
  !_.isEmpty(areas) &&
  <Typography variant='h6' className={classes.form__area_handler}>
    {label || I18n.t('v4/irrigation.add_irrigation_area')} :
    <Select
      fields={{ name:'name', id:'id' }}
      data={areas}
      value=''
      onChange={handleChange}
    />
  </Typography>
)

export default withStyles(styles)(AreaChangeHandler)
