import axios from 'axios';

const getToken = () => document.querySelector("meta[name=csrf-token]");
const headers = {
  Accept: 'application/json, text/javascript',
  'X-CSRF-Token': (getToken() ? getToken().content : null),
  'Authorization': 'Bearer ' + window.action_token
}

const httpService = axios.create({ headers })

const createAxiosInstace = configuration =>
  axios.create({
    headers,
    ...configuration
  })

export const getRequestMethods = (baseURL, organization_id) => ({
  request: createAxiosInstace({ baseURL }),
  apiRequest: createAxiosInstace({ baseURL: '/api/v4', params: { organization_id } })
})

export const apiServiceVindi = (key) => {
  const headers = { "Authorization": "Basic " + btoa(`${key}:`) }

  return axios.create({
    headers
  })
}

export const apiService = () => {
  const splittedURL = window.location.href.split('/v4')
  if (splittedURL.length === 0) {
    return httpService
  }

  return axios.create({
    headers,
    baseURL: splittedURL[0]
  })
}

export const getLink = (o, url) => `${getBaseURL(o)}${formatLink(url)}`

export const getBaseURL = o => `/v4/o/${o}/`

const formatLink = link => link.replace(/^\/+/, "")

export const visit = (currentOrganizationIndex, providedLink = '') => {
  const baseURL = getBaseURL(currentOrganizationIndex)
  const link = formatLink(providedLink)

  currentOrganizationIndex != null
    ? Turbolinks.visit(`${baseURL}${link}`)
    : Turbolinks.visit('/v4')
}

export const httpServiceNotification = axios.create({ headers })

export default httpService
