import React from 'react'
import PropTypes from 'prop-types'

import ExpansionPanel from '@material-ui/core/ExpansionPanel'
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary'
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails'
import Typography from '@material-ui/core/Typography'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import EditIcon from '@material-ui/icons/Edit'
import Button from '@material-ui/core/Button'
import DeleteIcon from '@material-ui/icons/Delete'
import withStyles from '@material-ui/core/styles/withStyles'

import SamplesList from './samples/Index'
import DeleteDialog from '@ui/DeleteDialog'
import styles from './styles'

class AnalysesList extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      id: null,
      isDialogOpen: false
    }

    this.handleDialogToggle = this.handleDialogToggle.bind(this)
    this.handleAnalysisSelect = this.handleAnalysisSelect.bind(this)
    this.handleAnalysisDelete = this.handleAnalysisDelete.bind(this)
  }

  handleAnalysisSelect(id) {
    this.setState({ id }, this.handleDialogToggle)
  }

  handleAnalysisDelete() {
    const { handleAnalysisDelete } = this.props

    handleAnalysisDelete(this.state.id)
    this.handleDialogToggle()
  }

  handleDialogToggle() {
    this.setState({
      isDialogOpen: !this.state.isDialogOpen
    })
  }

  render() {
    const { analyses, classes, handleAnalysisEdit, handleError, o } = this.props

    return (
      <React.Fragment>
        {
          analyses.map(analysis =>
            <ExpansionPanel key={analysis.id}>
              <ExpansionPanelSummary
                expandIcon={<ExpandMoreIcon />}
                className={classes.analysesList}
              >
                <Typography>
                  {analysis.name}
                </Typography>
              </ExpansionPanelSummary>

              <ExpansionPanelDetails className={classes.analysis__header}>
                <Typography>
                  {analysis.name}
                </Typography>
                <Typography>
                  {I18n.t('activerecord.attributes.v4/analysis.latitude')}: {Number(analysis.latitude).toFixed(4)}
                </Typography>
                <Typography>
                  {I18n.t('activerecord.attributes.v4/analysis.longitude')}: {Number(analysis.longitude).toFixed(4)}
                </Typography>
                <Typography>
                  <Button
                    className={classes.analysesList__action}
                    title={I18n.t('actions.edit')}
                  >
                    <EditIcon
                      onClick={() => handleAnalysisEdit(analysis)}
                    />
                  </Button>

                  <Button
                    title={I18n.t('actions.remove')}
                    className={classes.analysesList__action}
                  >
                    <DeleteIcon
                      onClick={() => this.handleAnalysisSelect(analysis.id)}
                    />
                  </Button>
                </Typography>
              </ExpansionPanelDetails>
              <ExpansionPanelDetails className={classes.analysesList__samples}>
                <SamplesList
                  analysisId={analysis.id}
                  samples={analysis.soil_samples}
                  handleError={handleError}
                  o={o}
                />
              </ExpansionPanelDetails>
            </ExpansionPanel>
          )
        }

        <DeleteDialog
          dialogTitle={`${I18n.t('actions.remove')} ${I18n.t('activerecord.models.v4/analysis.one')}?`}
          dialogText={I18n.t('confirmation.remove')}
          onCancel={this.handleDialogToggle}
          onClose={this.handleDialogToggle}
          handleDelete={this.handleAnalysisDelete}
          open={this.state.isDialogOpen}
        />
      </React.Fragment>
    )
  }
}

AnalysesList.propTypes = {
  analyses: PropTypes.array,
  handleAnalysisEdit: PropTypes.func,
  handleAnalysisDelete: PropTypes.func
}

export default withStyles(styles)(AnalysesList)
