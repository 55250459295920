import React from 'react'

import { Typography } from '@material-ui/core'

import { getVariableIcon } from '@models/equipment/equipment'

const Offline = ({ classes }) =>
  <div className={classes.Equipment_Status}>
    {getVariableIcon(classes)['offline']}

    <Typography variant='body1' className={classes.Equipment_installed_or_uninstalled}>
      {I18n.t('v4/equipment.dashboard.status.offline')}
    </Typography>
  </div>

export default Offline
