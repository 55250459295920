import React, { useState, useEffect } from "react";
import { Form, Field } from "react-final-form";
import { useHttpService } from "@contexts/HttpServiceProvider";
import { withStyles, Divider, Typography } from "@material-ui/core";
import { FilterList } from "@material-ui/icons";
import { getDateWithHours } from "@services/locale";
import { useSessionData } from "@contexts/SessionDataProvider";
import { sendAmplitudeEvent } from "@utils/amplitudeEvent";
import { IntercomAPI } from "@utils/IntercomAPI";
import Button from "@ui/Button";
import CheckBox from "@ui/Fields/CheckBox";
import depthDelayedJSON from "./depthDelayed.json";
import IrrigationDialog from "../../equipments/irrigations/Dialog";

function DepthDelayedForm({ items, classes, handleError, dialog, setDialog }) {
  const [delayedAreas, setDelayedAreas] = useState([]);
  const [irrigationDialog, setIrrigationDialog] = useState(false);
  const { currentOrganization } = useSessionData();
  const { apiRequest } = useHttpService();

  const onSubmit = async () => {};

  const fetchIrrigation = async () => {
    try {
      const { data } = await apiRequest.get(`/irrigations/delayed`);
      setDelayedAreas(data);
    } catch (error) {
      console.error(error);
    }
  };

  const convertDate = (date) => {
    const time = getDateWithHours(date);
    return time.infoDate;
  };

  const handleCheck = (event) => {
    sendAmplitudeEvent(
      "Aqua - Notification - Selected water management to inform water depths"
    );
    IntercomAPI(
      "Aqua - Notification - Selected water management to inform water depths"
    );
  };

  useEffect(() => {
    fetchIrrigation({}); // Caso for usar dados mockados, apague essa linha
    //setDelayedAreas(depthDelayedJSON); // Caso for usar dados dinamicos, apagar essa linha
  }, []);

  return (
    <main className={classes.Main_Container}>
      <div className={classes.Depths_Container}>
        <Form
          onSubmit={onSubmit}
          render={({ form, submitting, pristine, values }) => (
            <form>
              {delayedAreas.map((data, index) => (
                <div key={data.irrigation_system_id}>
                  <div className={classes.Depths_Container_Items}>
                    <Field
                      name="selectedAreas"
                      type="checkbox"
                      component="input"
                      onFocus={handleCheck}
                      value={data}
                    />
                    <div className={classes.Depths_Container_Field}>
                      <Typography variant="subheading">
                        {data.name}
                      </Typography>
                      <Typography
                        variant="subtitle2"
                        style={{ color: "#767676" }}
                      >
                        {`${I18n.t(
                          "notifications_central.inform_depth_subtitle"
                        )} ${convertDate(data.irrigation_date)}`}
                      </Typography>
                    </div>
                  </div>
                  <Divider />
                </div>
              ))}
              <div className={classes.Depths_Container_Bottom}>
                <Button
                  text={I18n.t("activerecord.attributes.v4/area.menu.inform")}
                  onClick={() => {
                    setIrrigationDialog(true);
                    sendAmplitudeEvent(
                      "Aqua - Notification - Used to inform depths Button"
                    );
                    IntercomAPI(
                      "Aqua - Notification - Used to inform depths Button"
                    );
                  }}
                  size="large"
                  color="primary"
                  icon={<FilterList className={classes.Icon_Button} />}
                  variant="contained"
                  disabled={!delayedAreas.length}
                  fullWidth
                />
              </div>
              <IrrigationDialog
                  dialogOpen={irrigationDialog}
                  organization_id={currentOrganization.id}
                  handleCloseDialog={() => {
                    setIrrigationDialog(false)
                    setDialog(false);
                  }}
                  initialAreas={values.selectedAreas}
                  areas={delayedAreas}
                  handleError={handleError}
                />
            </form>
          )}
        />
      </div>
    </main>
  );
}

const styles = (theme) => ({
  Main_Container: {
    display: "flex",
    flexDirection: "column",
  },
  Depths_Container: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
  },
  Depths_Container_Items: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
  },
  Depths_Container_Field: {
    marginLeft: theme.spacing.unit,
  },
  Depths_Container_Bottom: {
    marginTop: theme.spacing.unit * 4,
    display: 'flex',
    justifyContent: 'center'
  },
  Icon_Button: {
    marginLeft: theme.spacing.unit,
  },
});

export default withStyles(styles)(DepthDelayedForm);
