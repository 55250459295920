import React from 'react'
import { Form as FinalForm } from 'react-final-form'

import { validate, handleTenantSubmit } from '@models/admin/tenant'
import { RailsForm } from '@support/RailsForm'
import Form from './Form'

export const New = ({ products, is_admin, handleError, o }) =>
  <RailsForm
    component={FinalForm}
    validate={validate}
    handleSubmit={
      async data => {
        try {
          await handleTenantSubmit({
            data,
            url: `/v4/o/${o}/admin/tenants`,
            method: 'post',
            successUrl: `/v4/o/${o}/admin/tenants`
          })
        }
        catch (error) {
          handleError(error)
        }
      }
    }
    render={(props) =>
      <Form
        title={I18n.t('v4/tenant.actions.add')}
        isAdmin={is_admin}
        products={products}
        o={o}
        {...props}
      />
    }
  />

New.defaultProps = {
  products: [],
  is_admin: false
}

export default New
