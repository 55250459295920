import React from 'react'

import { IconButton, withStyles, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close';
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContentText from '@material-ui/core/DialogContentText'
import MaterialDialog from '@material-ui/core/Dialog'
import withMobileDialog from '@material-ui/core/withMobileDialog'

const Dialog = ({
  fullScreen,
  onClose,
  open,
  children,
  title,
  subtitle,
  maxWidth,
  disableTypography,
  classes,
  withCloseButton,
  fullWidth,
  hasTitleIcon,
  titleIcon
}) =>
  <MaterialDialog maxWidth={maxWidth} fullWidth={fullWidth} fullScreen={fullScreen} open={open} onClose={onClose}>
    <DialogTitle disableTypography={disableTypography} className={classes.Dialog_Title}>
      {
        hasTitleIcon && (
          <div className={classes.Dialog_Icon}>
            {titleIcon}
          </div>
        )
      }
      <Typography variant="h6">
        {title}
      </Typography>
      {
        withCloseButton && (
          <IconButton aria-label="Close" className={classes.Dialog_CloseButton} onClick={onClose}>
            <CloseIcon />
          </IconButton>
        )
      }
    </DialogTitle>

    <DialogContent>
      <DialogContentText>
        {subtitle}
      </DialogContentText>
      {children}
    </DialogContent>
  </MaterialDialog>

Dialog.defaultProps = {
  disableTypography: false,
  withCloseButton: false,
  maxWidth: 'sm',
  fullWidth:true
}

const styles = theme => ({
  Dialog_CloseButton: {
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    position: 'absolute'
  },
  Dialog_Title: {
    display: 'flex'
  },
  Dialog_Icon: {
    
  }
})

export default withStyles(styles)(withMobileDialog()(Dialog))
