import React from 'react'

import { withStyles } from '@material-ui/core'

import PreviewMode from './PreviewMode'
import Switch from './Switch'

import { isHourly } from '@models/weather/dashboard'

const PreviewSetting = ({ classes, previewMode, handleSelectedPreviewMode }) => {
  const previewModeSelected = isHourly(previewMode)

  return (
    <div className={classes.PreviewSetting_wrapper}>
      <PreviewMode
        label={I18n.t('v4/weather.dashboard.visualizations.hourly')}
        selected={previewModeSelected}
      />

      <Switch
        previewMode={previewMode}
        checked={!previewModeSelected}
        handleSelectedPreviewMode={handleSelectedPreviewMode}
      />

      <PreviewMode
        label={I18n.t('v4/weather.dashboard.visualizations.daily')}
        selected={!previewModeSelected}
      />
    </div>
  )
}
const styles = theme => ({
  PreviewSetting_wrapper: {
    display: 'flex',
    alignItems: 'center'
  }
})

export default withStyles(styles)(PreviewSetting)
