import React from 'react'

import Panel from './Panel'
import DatePicker from '@ui/Fields/DatePicker'
import TextField from '@ui/Fields/TextField'

import AdvancedParamsTable from './Table'

import { convertToLocaleDate } from '@services/locale'

const availableWaterColumns = [
  {
    field: 'date',
    title: I18n.t('v4/irrigation_management.advanced_params.available_water.fields.date'),
    editComponent: ({ value, onChange }) => (
      <DatePicker
        value={value || null}
        onChange={date => onChange(date)}
        variant='standard'
        required
      />
    ),
    render: ({ date }) => date ? convertToLocaleDate(date) : null
  },
  {
    field: 'value',
    title: I18n.t('v4/irrigation_management.advanced_params.available_water.fields.value'),
    editComponent: ({ value, onChange }) => (
      <TextField
        value={value}
        onChange={({ target }) => onChange(target.value)}
        type='number'
        required
      />
    )
  }
]

const validate = data =>
  !availableWaterColumns.some(({ field }) => !data[field])

const AvailableWater = ({ data, update }) =>
  <Panel title={I18n.t('v4/irrigation_management.advanced_params.available_water.title')}>
    <AdvancedParamsTable
      data={data}
      validate={validate}
      updateData={update}
      columns={availableWaterColumns}
    />
  </Panel>

AvailableWater.defaultProps = {
  data: [],
  update: () => { }
}

export default AvailableWater
