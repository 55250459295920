export default () => ({
  chart__wrapper: {
    border: '1px solid rgb(204, 204, 204)',
    padding: '25px',
    margin: '20px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    fontFamily: 'Helvetica, Arial, sans-serif'
  },
  export_button: {
    margin: '0 0 20px',
    minWidth: 0,
    padding: '10px'
  },
  chart__brush: {
    margin: '5px 0'
  },
  tooltip__wrapper: {
    margin: '0px',
    padding: '10px',
    backgroundColor: 'rgb(255, 255, 255)',
    border: '1px solid rgb(204, 204, 204)',
    whiteSpace: 'nowrap'
  }
})
