import React from 'react'

import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import InfoIcon from '@material-ui/icons/InfoOutlined'
import { InputAdornment, Tooltip } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const ControlledSelect = ({
  classes,
  data,
  input,
  emptyOption,
  meta: { error, touched },
  selectBy,
  fields,
  initialValue,
  info,
  select,
  InputProps,
  allowEmpty,
  ...textFieldProps
}) =>
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    defaultValue={initialValue}
    select={select}
    {...input}
    {...textFieldProps}
    InputProps={{
      className: classes.withInfo,
      ...InputProps,
      endAdornment:
        InputProps.endAdornment
          ? InputProps.endAdornment
          : info
            ? <Tooltip title={info} placement='top'>
              <InputAdornment position='end'>
                <InfoIcon />
              </InputAdornment>
            </Tooltip>
            : null
    }}
  >
    {
      !textFieldProps.required && allowEmpty &&
      <MenuItem value={emptyOption.value}>
        {emptyOption.name}
      </MenuItem>
    }

    {
      data.map(datum => {
        const value = datum[fields.id] === undefined ? datum[fields.name] : datum[fields.id]

        return (
          <MenuItem
            value={value}
            key={value}
            data-intercom-target={datum['intercomTag']}
          >
            {datum[fields.name]}
          </MenuItem>
        )
      })
    }
  </TextField>

ControlledSelect.defaultProps = {
  meta: {},
  InputProps: {},
  select: true,
  emptyOption: {
    name: I18n.t('ui.fields.select.empty_option'),
    id: null
  }
}

const styles = () => ({
  withInfo: {
    boxSizing: 'border-box'
  }
})

export default withStyles(styles)(ControlledSelect)
