import React from 'react'
import PropTypes from 'prop-types'

import { Form as FinalForm } from 'react-final-form'
import { RailsForm } from '@support/RailsForm'

import { validate } from '@models/accounts'

import { Paper, Tab, Tabs, withStyles } from '@material-ui/core'

import Form from './Form'
import TabPanel from './TabPanel'
import OrganizationList from './OrganizationList'
import styles from '@styles/indexPage'

function tabPannelProps(index) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`,
  };
}

const tabs = { "#account": 0, "#organizations": 1 }

function currentTab() {
  return tabs[window.location.hash] || 0
}

export const Edit = ({ classes, model, organizations, organizations_headers, o }) => {
  const [value, setValue] = React.useState(currentTab());

  const handleChange = (event, newValue) => {
    setValue(newValue);

    window.location.hash = ['account', 'organizations'][newValue]
    Turbolinks.visit(window.location)
  };

  return(
    <Paper className={classes.page__wrapper} elevation={0}>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary"
        variant="fullWidth"
      >
        <Tab label={I18n.t('v4/account.one')} {...tabPannelProps(0)} />
        <Tab label={I18n.t('v4/organization.more')} {...tabPannelProps(1)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <RailsForm
          component={FinalForm}
          action='update'
          url={`/v4/o/${o}/account`}
          successUrl={`/v4/o/${o}/account/edit`}
          validate={validate}
          initialValues={model}
          render={(props) => (<Form {...props} />)}
        />
      </TabPanel>
      <TabPanel value={value} index={1}>
        <OrganizationList model={organizations} o={o} organizationsHeaders={organizations_headers} />
      </TabPanel>
    </Paper>
  )
}

Edit.propTypes = {
  model: PropTypes.object,
  organizations: PropTypes.array
}

export default withStyles(styles)(Edit)
