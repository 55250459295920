import React from 'react'

import { Divider, withStyles } from '@material-ui/core'

import Application from './Application'
import ConditionDays from './ConditionDays'

const Application_wrapper = ({ classes, sprayingCondition }) =>
  <div className={classes.Application_wrapper}>
    <Application />

    <div className={classes.Application_condition}>
      <ConditionDays condition={sprayingCondition['sprayingMorning']} label={I18n.t('v4/forecast.spraying_morning')} />
      <Divider variant="middle" className={classes.Application_divider} orientation='vertical' />

      <ConditionDays condition={sprayingCondition['sprayingAfternoon']} label={I18n.t('v4/forecast.spraying_afternoon')} />
      <Divider variant="middle" className={classes.Application_divider} orientation='vertical' />

      <ConditionDays condition={sprayingCondition['sprayingNight']} label={I18n.t('v4/forecast.spraying_night')} />
    </div>
  </div>

const styles = (theme) => ({
  Application_wrapper: {
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: theme.spacing.unit * 2
  },
  Application_condition: {
    display: 'flex',
    alignItems: 'center',
    width: '70%',
    justifyContent: 'space-between'
  },
  Application_divider: {
    height: '13px',
    margin: theme.spacing.unit,
    border: `1px solid ${theme.palette.gray.dark}`
  }
})

export default withStyles(styles)(Application_wrapper)
