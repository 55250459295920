import React, { Component } from 'react'

import { Field } from 'react-final-form'

import {
  Button,
  Paper,
  Typography,
  Divider,
  withStyles,
} from '@material-ui/core'

import httpService from '@services/httpService'
import ConfirmDialog from '@ui/ConfirmDialog/Dialog'
import Dialog from '@ui/Dialog'
import Select from '@ui/Fields/ControlledSelect'
import { irrigationFields } from '@models/irrigation_managements'

import AreaFields from './form/AreaFields'
import CustomField from './form/CustomField'
import ForecastPointForm from './form/ForecastPoint'
import SoilAnalyzesForm from '../areas/analyses/ApiWrapper'
import NewPlantationForm from '../areas/dashboard/NewPlantationFormWrapper'
import AdvancedParamsDialog from './form/AdvancedParamsDialog'

import styles from './styles'

class Form extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedSystemFields: [],
      selectedSensor: null,
      isTypeSelected: false,
      isAlgorithmSimplified: false,
      advancedParamsDialogOpen: false,
      dialog: {
        fullScreen: false,
        open: false,
        child: () => <div></div>,
        title: ''
      },
      confirmDialog: {
        open: false,
        content: '',
        title: '',
        onConfirm: () => { },
        confirmText: '',
        cancelText: ''
      },
      areas: [],
      crops: [],
      sensors: [],
      soilAnalyzes: [],
      isAreaSelected: false
    }
  }

  componentDidMount() {
    this.fetchInitialData()
    const { irrigationTypes } = this.props
    if (!_.isEmpty(irrigationTypes)) {
      this.showIrrigationAlgorithmFields({ value: irrigationTypes[0].id })
    }
  }

  async fetchInitialData() {
    try {
      const { organization_id, isEditing, values } = this.props

      const params = { organization_id }

      const [
        areas,
        cultures,
        irrigationSystems
      ] = await Promise.all([
        httpService.get('/api/v4/areas', { params }),
        httpService.get('/api/v4/cultures', { params }),
        httpService.get('/api/v4/equipments/irrigation', { params })
      ])

      this.setState({
        areas: areas.data,
        cultures: cultures.data,
        irrigationSystems: irrigationSystems.data
      }, () => {
        if (isEditing && values.v4_area_id) {
          this.fetchAreaData(values.v4_area_id)
        }
      })
    } catch (error) {
      console.error(error)
      this.props.checkError(error)
    }
  }

  showIrrigationAlgorithmFields(type) {
    const { irrigationTypes, form } = this.props
    const typeId = irrigationTypes.find(irrType => irrType.id === type.value).id

    if (typeId === 'simplified') {
      form.change('weather_station_id', null)
      form.change('soil_sensor_id', null)
    }

    this.setState({
      selectedSystemFields: irrigationFields.find(field => field.idType === typeId).fields,
      isAlgorithmSimplified: typeId === 'simplified' ? true : false
    })
  }

  handleDialogClose = () => {
    this.setState(st => ({
      dialog: {
        ...st.dialog,
        open: false
      },
      confirmDialog: {
        ...st.confirmDialog,
        open: false
      }
    }))
  }

  handleOpenCultureDialog = e => {
    const { form, organization_id } = this.props
    const { cultures, selectedAreaId } = this.state

    const title = I18n.t('v4/culture.actions.add')
    const child = NewPlantationForm
    const onCancel = this.handleDialogClose
    const selectedCultureId = e.target.value

    const handleSubmit = async values => {
      try {
        const plantation = { ...values, area_id: selectedAreaId }
        await httpService.post('/api/v4/crops', plantation, { params: { organization_id } })

        form.change('culture', values.culture_id)

        this.setState({
          isCropSelected: true,
          selectedCultureId: values.culture_id
        })
      }
      catch (error) {
        this.props.checkError(error)
      }

      this.handleDialogClose()
    }

    const props = {
      isCultureDisabled: true,
      selectedCultureId,
      handleSubmit,
      cultures: cultures,
      onCancel
    }

    this.setState({
      dialog: {
        open: true,
        title,
        child,
        fullScreen: true,
        props
      }
    })
  }

  handleOpenSoilAnalyzes = () => {
    const title = I18n.t('v4/analysis.actions.add')
    const onCancel = this.handleDialogClose
    const child = SoilAnalyzesForm
    const { o } = this.props

    const handleSubmit = async values => {
      const { selectedAreaId } = this.state
      try {
        const response = await httpService.post(`/v4/o/${o}/areas/${selectedAreaId}/soil_analyses`, values)

        this.setState(st => ({
          soilAnalyzes: [...st.soilAnalyzes, response.data]
        }))
      } catch (e) {
        this.props.checkError(e)
      }

      this.handleDialogClose()
    }

    const props = {
      action: title,
      handleSubmit,
      onCancel
    }

    this.setState({
      dialog: {
        open: true,
        title,
        child,
        fullScreen: false,
        props
      }
    })
  }

  handleOpenIrrigationSystemDialog = e => {
    const { checkError, organization_id, form } = this.props
    const { selectedAreaId } = this.state
    const irrigationSystemId = e.target.value

    const onConfirm = async () => {
      try {
        const params = {
          organization_id
        }

        const body = {
          equipment_id: irrigationSystemId,
          organization_id
        }

        await httpService.post(`/api/v4/areas/${selectedAreaId}/equipment`, body, { params })

        form.change('irrigation_system', irrigationSystemId)

        this.setState({
          selectedIrrigationSystemId: irrigationSystemId,
          isIrrigationSystemSelected: true
        })

        this.handleDialogClose()
      } catch (e) {
        checkError(e)
      }
    }

    this.setState({
      confirmDialog: {
        open: true,
        title: I18n.t('v4/irrigation_management.confirm_dialog.irrigation_system.title'),
        content: I18n.t('v4/irrigation_management.confirm_dialog.irrigation_system.content'),
        onConfirm,
        confirmText: I18n.t('confirm_dialog.confirmText'),
        cancelText: I18n.t('confirm_dialog.cancelText')
      }
    })
  }

  handleSelectForecastPoint = e => {
    const { form } = this.props
    const { forecastPoints } = this.state
    const forecastId = e.target.value || ''
    const selectedForecast = forecastPoints.find(forecast => forecast.id === forecastId) || {}

    if (_.isEmpty(selectedForecast) || selectedForecast['set_up?']) {
      form.change('forecast_point_id', forecastId)
      this.setState({ selectedForecastPointId: forecastId })
    } else {
      const onConfirm = () => {
        this.handleDialogClose()
        this.handleOpenForecastPointDialog(selectedForecast)
      }

      this.setState({
        confirmDialog: {
          open: true,
          title: I18n.t('v4/irrigation_management.confirm_dialog.forecast_point.title'),
          content: I18n.t('v4/irrigation_management.confirm_dialog.forecast_point.content'),
          onConfirm,
          confirmText: I18n.t('confirm_dialog.confirmText'),
          cancelText: I18n.t('confirm_dialog.cancelText')
        }
      })
    }
  }

  handleOpenForecastPointDialog = forecastPoint => {
    const { organization_id, checkError, form } = this.props

    const title = I18n.t('v4/irrigation_management.setup_forecast_point')
    const onCancel = this.handleDialogClose
    const child = ForecastPointForm
    const handleError = checkError
    const organizationId = organization_id
    const handleForecastUpdate = updatedForecast => {
      this.handleDialogClose()

      form.change('forecast_point_id', updatedForecast.id)
      this.setState(state => ({
        forecastPoints: state.forecastPoints.map(forecastPoint =>
          updatedForecast.id === forecastPoint.id
            ? updatedForecast
            : forecastPoint
        ),
        selectedForecastPointId: updatedForecast.id
      }))
    }

    this.setState({
      dialog: {
        open: true,
        child,
        fullScreen: true,
        props: {
          forecastPoint,
          onCancel,
          handleError,
          organizationId,
          handleForecastUpdate
        },
        title
      }
    })
  }

  async fetchAreaData(id) {
    try {
      const { organization_id, form, values } = this.props
      const { areas } = this.state

      const [
        culture,
        soilAnalyzes,
        pluviometers,
        sensors,
        weatherStations,
        forecastPoints
      ] = await Promise.all([
        httpService.get(`/api/v4/areas/${id}/crops`, {
          params: {
            organization_id
          }
        }),
        httpService.get(`/api/v4/areas/${id}/soil_analyses`, {
          params: {
            organization_id
          }
        }),
        httpService.get(`/api/v4/areas/${id}/equipment`, {
          params: {
            organization_id,
            tags: 'pluviometer'
          }
        }),
        httpService.get(`/api/v4/areas/${id}/equipment`, {
          params: {
            organization_id,
            tags: 'soil_sensor'
          }
        }),
        httpService.get(`/api/v4/areas/${id}/equipment`, {
          params: {
            organization_id,
            tags: 'weather_station'
          }
        }),
        httpService.get('/api/v4/forecast_points', {
          params: {
            organization_id,
            area_id: id
          }
        })
      ])

      const selectedArea = areas.filter(area => area.id === id)[0]

      const activeCrop = culture.data.find(crop => crop.status === 'active')
      const selectedCultureId = activeCrop ? activeCrop.culture_id : ''

      const selectedIrrigationSystemId = selectedArea.irrigation_system_id || ''
      const selectedForecastPointId = values.forecast_point_id || ''

      form.change('irrigation_system', selectedIrrigationSystemId)
      form.change('culture', selectedCultureId)

      this.setState({
        selectedAreaId: id,
        isAreaSelected: true,
        selectedCultureId,
        isCropSelected: selectedCultureId !== '',
        selectedIrrigationSystemId,
        isIrrigationSystemSelected: selectedIrrigationSystemId !== '',
        soilAnalyzes: soilAnalyzes.data,
        pluviometers: pluviometers.data,
        sensors: sensors.data,
        weatherStations: weatherStations.data,
        forecastPoints: forecastPoints.data,
        selectedForecastPointId
      })
    } catch (error) {
      this.props.checkError(error)
    }
  }

  handleOpenAdvancedParamsDialog = () => {
    const { sensors } = this.state
    const { values } = this.props

    const selectedSensor = sensors.find(sensor => sensor.id === values.soil_sensor_id)

    this.setState({
      selectedSensor,
      advancedParamsDialogOpen: true
    })
  }

  render() {
    const {
      action,
      handleSubmit,
      classes,
      form,
      title,
      values,
      irrigationTypes,
      o
    } = this.props

    const {
      areas,
      cultures,
      soilAnalyzes,
      dialog,
      confirmDialog,
      sensors,
      pluviometers,
      weatherStations,
      forecastPoints,
      irrigationSystems,
      selectedCultureId,
      selectedIrrigationSystemId,
      selectedForecastPointId,
      isAreaSelected,
      isCropSelected,
      isIrrigationSystemSelected,
      isAlgorithmSimplified,
      selectedSystemFields,
      advancedParamsDialogOpen,
      selectedSensor
    } = this.state

    return (
      <form className={classes.form} onSubmit={handleSubmit}>
        <Paper className={classes.form__wrapper} elevation={0}>
          <header className={classes.form__header}>
            <Typography component='h2' variant='h5'>
              {title}
            </Typography>
          </header>

          <main className={classes.form__fields}>
            <div className={classes.form__field__wrapper}>
              <Field
                component={Select}
                className={classes.form__fieldWithError}
                margin='normal'
                fullWidth
                InputLabelProps={{ shrink: true }}
                fields={{ id: 'id', name: 'name' }}
                onInputChange={e => this.fetchAreaData(e.target.value)}
                data={areas}
                variant='outlined'
                label={I18n.t('activerecord.models.v4/area.one')}
                name='v4_area_id'
                required
              />
            </div>

            <div className={classes.form__field__wrapper}>
              <Field
                component={Select}
                className={classes.form__fieldWithError}
                margin='normal'
                fullWidth
                InputLabelProps={{ shrink: true }}
                fields={{ id: 'id', name: 'name' }}
                data={irrigationTypes}
                variant='outlined'
                label={I18n.t('v4/irrigation_management.fields.available_algorithms')}
                name='algorithm_name'
                initialValue={irrigationTypes.length > 0 ? irrigationTypes[0].id : null}
                disabled={irrigationTypes.length <= 1}
                required
                onInputChange={evt => this.showIrrigationAlgorithmFields(evt.target)}
              />
            </div>

            <Divider variant='middle' />

            {
              selectedSystemFields.map(field =>
                <CustomField
                  key={field.name}
                  form={form}
                  {...field}
                  values={values}
                  classes={classes}
                />
              )
            }

            <Divider variant='middle' />

            {
              isAreaSelected &&
              <>
                <AreaFields
                  classes={classes}
                  handleOpenSoilAnalyzes={this.handleOpenSoilAnalyzes}
                  handleOpenCultureDialog={this.handleOpenCultureDialog}
                  handleOpenIrrigationSystemDialog={this.handleOpenIrrigationSystemDialog}
                  handleSelectForecastPoint={this.handleSelectForecastPoint}
                  cultures={cultures}
                  irrigationSystems={irrigationSystems}
                  weatherStations={weatherStations}
                  sensors={sensors}
                  pluviometers={pluviometers}
                  soilAnalyzes={soilAnalyzes}
                  isCropSelected={isCropSelected}
                  isIrrigationSystemSelected={isIrrigationSystemSelected}
                  isAlgorithmSimplified={isAlgorithmSimplified}
                  selectedIrrigationSystemId={selectedIrrigationSystemId}
                  selectedCulture={selectedCultureId}
                  selectedForecastPointId={selectedForecastPointId}
                  forecastPoints={forecastPoints}
                  values={values}
                />

                <Button
                  color='primary'
                  className={classes.AdvancedParams_Button}
                  onClick={this.handleOpenAdvancedParamsDialog}
                  variant='outlined'
                >
                  {I18n.t('v4/irrigation_management.advanced_params.button')}
                </Button>
              </>
            }

            <Dialog
              open={dialog.open}
              title={dialog.title}
              onClose={this.handleDialogClose}
            >
              <dialog.child {...dialog.props} />
            </Dialog>

            <ConfirmDialog
              open={confirmDialog.open}
              title={confirmDialog.title}
              content={confirmDialog.content}
              confirmText={confirmDialog.confirmText}
              cancelText={confirmDialog.cancelText}
              onConfirm={confirmDialog.onConfirm}
              onCancel={this.handleDialogClose}
              classes={classes}
            />

            <AdvancedParamsDialog
              initialValues={values}
              open={advancedParamsDialogOpen}
              selectedSensor={selectedSensor}
              handleSubmit={
                advancedParamsValues => {
                  Object.keys(advancedParamsValues).forEach(key =>
                    values[key] = advancedParamsValues[key]
                  )

                  this.setState({ advancedParamsDialogOpen: false })
                }
              }
              handleClose={() => this.setState({ advancedParamsDialogOpen: false })}
            />
          </main>
          <footer className={classes.form__actions}>
            <Button
              className={classes.form__action}
              color='primary'
              type='submit'
              variant='contained'
            >
              {action}
            </Button>

            <Button
              className={classes.form__action}
              color='secondary'
              href={`/v4/o/${o}/irrigation_managements`}
            >
              {I18n.t('actions.back')}
            </Button>
          </footer>
        </Paper>
      </form>
    )
  }
}

Form.defaultProps = {
  values: {}
}

export default withStyles(styles)(Form)
