import React from 'react'
import Form from './NewPlantationForm'
import { Form as FinalForm } from 'react-final-form'
import { RailsForm } from '@support/RailsForm'
import { validateNewPlantation } from '@models/dashboardArea'

const NewPlantationFormWrapper = ({ handleSubmit, cultures, onCancel, selectedCultureId, isCultureDisabled }) =>
  <RailsForm
    component={FinalForm}
    handleSubmit={(values) => handleSubmit(values)}
    initialValues={{ planting_date: new Date(), culture_id: selectedCultureId }}
    validate={validateNewPlantation}
    render={(props) => (
      <Form
        {...props}
        action={I18n.t('v4/area.dashboard.plant')}
        onCancel={() => onCancel()}
        cultures={cultures}
        isCultureDisabled={isCultureDisabled}
      />
    )}
  />

export default NewPlantationFormWrapper
