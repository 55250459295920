import React from 'react'
import Table from '@ui/Table'
import { paramsTableHeaders } from '@models/admin/product'

const ParamsTable = ({ params, updateParams }) => {
  const [state, setState] = React.useState({
    columns: paramsTableHeaders,
    data: params,
  });

  const handleUpdate = (data) => {
    updateParams(data)

    setState({
      ...state,
      data
    })
  }

  return (
    <Table
      tableKey='admin-products-paramsTable'
      title={I18n.t('activerecord.attributes.v4/product.params')}
      columns={state.columns}
      data={state.data}
      options={{
        search: false,
        paging: false
      }}
      editable={{
        onRowUpdate: (newData, oldData) =>
          new Promise(resolve => {
            const data = [...state.data]
            data[data.indexOf(oldData)] = {
              ...newData,
              name: oldData.name
            }
            handleUpdate(data)
            resolve()
          }),
      }}
    />
  );
}

ParamsTable.defaultProps = {
  params: []
}

export default ParamsTable
