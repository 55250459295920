const headers = [
  'label', 'expire_in'
]

export const getOrganizationProductsHeaders = () =>
  headers.map(head => ({
    title: I18n.t(`activerecord.attributes.v4/organization_products.${head}`),
    field: head
  }))

const baseURL = 'admin/organizations'

export const productConfigurationUrls = (kind, organizationId, productId, o) => {
  switch (kind) {
    case 'others':
      return `/v4/o/${o}/${baseURL}/${organizationId}/products/`
    default:
      return `/v4/o/${o}/${baseURL}/${organizationId}/products/${productId}/config/edit`
  }
}
