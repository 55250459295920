import React from 'react'

import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import Field from '@ui/Fields/Field'

import styles from '@theme/styles/forms'

const Form = ({
  handleSubmit,
  classes,
  action,
  onCancel
}) => (
    <form onSubmit={handleSubmit} data-testid='analysis-form'>
      <main className={classes.form__fields}>
        <Field
          label={I18n.t('activerecord.attributes.v4/analysis.name')}
          name='name'
          required
        />

        <Field
          label={I18n.t('activerecord.attributes.v4/analysis.latitude')}
          name='latitude'
          inputProps={{ step: '0.000001' }}
          required
          type='number'
        />

        <Field
          label={I18n.t('activerecord.attributes.v4/analysis.longitude')}
          name='longitude'
          inputProps={{ step: '0.000001' }}
          required
          type='number'
        />

      </main>
      <footer className={classes.form__actions}>
        <Button
          className={classes.form__action}
          color='primary'
          type='submit'
          variant='contained'
        >
          {action}
        </Button>

        <Button
          className={classes.form__action}
          color='secondary'
          onClick={onCancel}
        >
          {I18n.t('actions.cancel')}
        </Button>
      </footer>
    </form>
  )

export default withStyles(styles)(Form)
