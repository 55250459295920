import React, { useState, useEffect } from "react";
import { Form as FinalForm, Field } from "react-final-form";
import { withStyles, Divider, Typography } from "@material-ui/core";
import { withSnackbar } from "notistack";
import { useSessionData } from "@contexts/SessionDataProvider";
import { useErrorHandler } from "@contexts/ErrorHandlerProvider";
import { Button } from "@material-ui/core";
import { useHttpService } from "@contexts/HttpServiceProvider";
import { sendAmplitudeEvent } from "@utils/amplitudeEvent";
import httpService from "@services/httpService";
import LoadingComponent from "@ui/LoadingComponent";
import DatePicker from "@ui/Fields/DatePicker";
import styles from "@theme/styles/forms";
import Grid from "@material-ui/core/Grid";
import CultureIconEnabled from "@images/area/planted_culture_white.svg";
import CultureIconDisabled from '@images/area/planted_culture.svg'

const areasMock = [
  {
    area_id: 2,
    area_name: "Area 1",
    next_stage: "Próxima fase da area 1",
    initial_date: "2022-05-13T16:10:01.111Z",
  },
  {
    area_id: 9,
    area_name: "Area 2",
    next_stage: "Próxima fase da area 2",
    initial_date: "2022-05-05T16:05:01.111Z",
  },
  {
    area_id: 10,
    area_name: "Area 3",
    next_stage: "Próxima fase da area 3",
    initial_date: "2022-04-11T15:10:01.111Z",
  },
];

function AdvancePhenologicalForm({ dialog, setDialog, classes, enqueueSnackbar }) {
  const [areas, setAreas] = useState([]);
  const [loading, setLoading] = useState(false);
  const { apiRequest } = useHttpService();

  const { currentOrganization } = useSessionData();
  const handleError = useErrorHandler();

  const fetchAreasData = async () => {
    try {
      setLoading(true);
      const { data } = await httpService.get(
        `/api/v4/areas/delayed_phenological_stage?organization_id=${currentOrganization.id}`
      );
      setAreas(data);
      setLoading(false);
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    fetchAreasData({});
   // setAreas(areasMock);
  }, []);

  const onSubmit = async (values) => {
    setLoading(true);
    _.forOwn(values.selectedAreas, async (areaId, key) => {
      try {
        const data = {
          finished_at: values[`date_${areaId}`],
        };

        const { data: cropData } = await apiRequest.get(
          `/areas/${areaId}/crops`,
          {
            params: {
              organization_id: currentOrganization.id,
            },
          }
        );

        const activeCrop = cropData.filter(
          (data) => data.status === "active"
        )[0];

        const cropId = activeCrop.id;

        await apiRequest.put(`crops/${cropId}`, {
          _forward_stage: {
            ...data
          }
        });
        
        setLoading(false);
        setDialog(false);

        enqueueSnackbar(
          I18n.t("notifications_central.advance_phenological_modal_success_snackbar"),
          { variant: "success" }
        );

      } catch (err) {
        handleError(err);
        setLoading(false);
        setDialog(false);
      }
    });
  };

  const isChecked = (values, area_id) => {
    return values.selectedAreas && values.selectedAreas.includes(area_id);
  };

  const handleCheck = () => {
    sendAmplitudeEvent('Pheno Stage - Notification - Selected Area to Advance')
  }

  return (
    <main className={classes.Main_Container}>
      <div className={classes.Areas_Container}>
        {!loading ? (
          <FinalForm
            onSubmit={onSubmit}
            render={({ handleSubmit, values }) => (
              <form onSubmit={handleSubmit}>
                {areas.map((data, index) => (
                  <div key={data.area_id}>
                    <div className={classes.Areas_Container_Items}>
                      <Field
                        name="selectedAreas"
                        type="checkbox"
                        component="input"
                        onFocus={handleCheck}
                        value={data.area_id}
                      />
                      <div className={classes.Areas_Container_Field}>
                        <Typography variant="subheading">
                          {data.area_name}
                        </Typography>
                        <Typography
                          variant="subtitle2"
                          style={{ color: "#767676" }}
                        >
                          {`${I18n.t(
                            "notifications_central.advance_stage_modal_phase_title"
                          )} ${data.next_stage.name}`}
                        </Typography>
                      </div>
                    </div>
                    {isChecked(values, data.area_id) && (
                      <main className={classes.form__fields}>
                        <Grid container spacing={16}>
                          <Grid item xs={12}>
                            <Field
                              name={`date_${data.area_id}`}
                              className={classes.form__fieldWithError}
                              component={DatePicker}
                              margin="normal"
                              InputLabelProps={{ shrink: true }}
                              variant="outlined"
                              initialValue={new Date(`${data.expected_current_stage_finish}:00:00:00`)}
                              label={I18n.t(
                                "activerecord.attributes.v4/culture.phenological_stages.date"
                              )}
                              onFocus={() => sendAmplitudeEvent('Pheno Stage - Notification - Clicked Date Field')}
                              fullWidth
                              required
                            />
                          </Grid>
                        </Grid>
                      </main>
                    )}
                    <Divider />
                  </div>
                ))}

                {!loading && _.isEmpty(areas) && (
                  <Typography
                    variant="body1"
                    align="center"
                    className={classes.No_Areas_Text}
                  >
                    {I18n.t(
                      "notifications_central.advance_phenological_modal_no_areas"
                    )}
                  </Typography>
                )}

                <div className={classes.Areas_Container_Bottom}>
                  {!loading ? (
                    <>
                      <Button
                        size="large"
                        color="primary"
                        variant="contained"
                        type="submit"
                        disabled={_.isEmpty(values.selectedAreas)}
                        onClick={() => sendAmplitudeEvent('Pheno Stage - Notification - Clicked Advance Button')}
                        fullWidth
                      >
                        {I18n.t("actions.advance-phenological-stage")}
                        <img
                          src={
                            _.isEmpty(values.selectedAreas)
                              ? CultureIconDisabled
                              : CultureIconEnabled
                          }
                          alt="Icone de Colheita"
                          className={classes.Submit_Button_Icon}
                        />
                      </Button>
                    </>
                  ) : (
                    <LoadingComponent size={30} />
                  )}
                </div>
              </form>
            )}
          />
        ) : (
          <LoadingComponent size={40} />
        )}
      </div>
    </main>
  );
}

const customStyles = (theme) => ({
  ...styles(theme),
  Main_Container: {
    display: "flex",
    flexDirection: "column",
  },
  Areas_Container: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
  },
  Areas_Container_Items: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
  },
  Areas_Container_Field: {
    marginLeft: theme.spacing.unit,
  },
  Areas_Container_Bottom: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing.unit * 4,
  },
  Submit_Button_Icon: {
    marginLeft: theme.spacing.unit,
  },
});

export default withSnackbar(withStyles(customStyles)(AdvancePhenologicalForm));
