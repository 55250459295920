import React from 'react'
import Form from '@ui/Form'
import DepthDelayedForm from '@ui/NotificationsDialogs/depthDelayed';
import RegisterCropForm from '@ui/NotificationsDialogs/RegisterCrop';
import AdvancePhenologicalForm from '@ui/NotificationsDialogs/advancePhenological';

export default (dialog, setDialog) => ({
    depthDelayed: {
      title: I18n.t('notifications_central.depth_delayed_modal_title'),
      subtitle: I18n.t('notifications_central.depth_delayed_modal_subtitle'),
      component: <DepthDelayedForm dialog={dialog} setDialog={setDialog}/>
    },
    advanceStage: {
      title: I18n.t('notifications_central.advance_stage_modal_title'),
      subtitle: I18n.t('notifications_central.advance_stage_modal_subtitle'),
      component: <AdvancePhenologicalForm dialog={dialog} setDialog={setDialog} />
    },
    registerCrop: {
      title: I18n.t('notifications_central.register_crop_modal_title'),
      subtitle: I18n.t('notifications_central.register_crop_modal_subtitle'),
      component: <RegisterCropForm dialog={dialog} setDialog={setDialog} />
    }
  })