import React, { useMemo } from 'react'

import httpService from '@services/httpService'
import deserializeError from '@services/deserializeError'
import { withSnackbar } from 'notistack'
import createDecorator from 'final-form-focus'

const actions = {
  create: 'POST',
  update: 'PUT',
  patch: 'PATCH'
}

const RailsForm = withSnackbar(({
  component,
  render,
  method,
  url,
  validate,
  action,
  initialValues,
  successUrl,
  handleSubmit,
  enqueueSnackbar,
  decorators
}) => {
  const defaultOnSubmit = async values => {
    const form_method = !method ? actions[action] : method

    if (!form_method) {
      console.error('method or action not defined')
      throw 'method or action not defined'
    }

    try {
      const response = await httpService({
        method: form_method,
        url: `${url}.json`,
        data: values
      })

      Turbolinks.visit(successUrl ? successUrl : `${url}/${response.data.id}`)
    }
    catch (error) {
      deserializeError(error).map(e =>
        enqueueSnackbar(e, { variant: 'error' })
      )
    }
  }

  const focusOnErrors = createDecorator()

  const formDecorators = useMemo(() => decorators || [focusOnErrors], [])

  const onSubmit = handleSubmit || defaultOnSubmit

  return React.createElement(component, {
    onSubmit,
    validate,
    initialValues,
    decorators: formDecorators,
    render: ({
      handleSubmit,
      reset,
      submitting,
      pristine,
      form,
      values
    }) => render({
      handleSubmit,
      reset,
      submitting,
      pristine,
      form,
      values
    })
  })
})

export { RailsForm }
