import React from 'react'

import { withStyles } from '@material-ui/core'

const ArrayCell = ({ data, classes }) =>
  <div style={{ width: 'max-content' }}>
    { 
      data.map(value => 
        <div key={value} className={classes.ArrayCell}>
          {value}
        </div>
      )
    }
  </div>

const styles = theme => ({
  ArrayCell: {
    padding: `${theme.spacing.unit}px 0` 
  } 
})

export default withStyles(styles)(ArrayCell)
