export default theme => ({
  ColorsForm__fields_section: {
    border: `2px solid ${theme.palette.gray.main}`,
    borderRadius: '5px',
    padding: '18px 20px',
    '&:not(:last-child)': {
      marginBottom: theme.spacing.unit * 2
    }
  },
  ColorsForm__fields_wrapper: {
    marginTop: theme.spacing.unit * 2,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  ColorsForm__fields: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    width: '100%'
  },
  ColorsForm__button: {
    '&:not(:first-child)': {
      marginTop: theme.spacing.unit * 2
    }
  },
  ColorsForm__fields_Menus: {
    minWidth: '150px',
    color: theme.menus.icons,
    display: 'flex',
    backgroundColor: theme.menus.background,
    justifyContent: 'space-between',
    border: `1px solid ${theme.palette.gray.main}`,
    padding: '12px',
    borderRadius: '5px'
  },
  ColorsForm__fields_Background: {
    height: '120px',
    backgroundColor: theme.palette.background.default,
    border: `1px solid ${theme.palette.gray.main}`,
    borderRadius: '5px'
  },
  ColorsForm__fields_PaperBackground: {
    height: '120px',
    backgroundColor: theme.palette.background.paper,
    border: `1px solid ${theme.palette.gray.main}`,
    borderRadius: '5px'
  }
})
