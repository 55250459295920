import React from 'react'
import { Typography } from '@material-ui/core'
import DefaultColorsForm from './DefaultColorsForm'
import TextField from '@ui/Fields/TextField'

import withStyles from '@material-ui/core/styles/withStyles'
import styles from './style'

const SecondaryTextForm = ({ classes, color, updateColor }) =>
  <DefaultColorsForm
    classes={classes}
    title={I18n.t('v4/tenant.styles.secondary_text_color')}
    color={color}
    label={I18n.t('v4/tenant.styles.colors.one')}
    updateColor={updateColor}
  >
    <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Typography component='h2' variant='h5' color='textSecondary'>
        {I18n.t('v4/tenant.styles.subtitle')}
      </Typography>

      <TextField
        margin='normal'
        fullWidth
        InputLabelProps={{ shrink: true }}
        variant='outlined'
        label={I18n.t('v4/tenant.styles.label')}
        required
      />
    </div>
  </DefaultColorsForm>

export default withStyles(styles)(SecondaryTextForm)
