import React, { Fragment } from 'react'
import { Paper, Typography, Divider, Button } from '@material-ui/core'

import CreditCard from './CreditCard'
import BillingDataForm from './BillingDataForm'
import DefaultCreditCardIcon from '@ui/Icons/credit_card'
import DefaultBankSlipIcon from '@ui/Icons/bank_slip'

import { withStyles } from '@material-ui/core/styles'


const BillingData = ({ classes, creditCards, selectCreditCard, handleSelectBankSlip, handleNewCreditCard, organization_id, gateway_base_url, gateway_public_key, handleError, o, retreatStep }) => {

  const [adding, setAdding] = React.useState(false)

  const handleNewCreditCardClick = () => {
    setAdding(true)
  }

  const handleCancel = () => {
    setAdding(false)
  }

  return adding
    ? <BillingDataForm
      gateway_base_url={gateway_base_url}
      gateway_public_key={gateway_public_key}
      organization_id={organization_id}
      onHandleSubmit={handleNewCreditCard}
      onCancel={handleCancel}
      o={o}
      handleError={handleError}
    />
    : <Fragment>
      <Typography variant='h6'>
        {I18n.t('v4/payment_checkout.billing_data.payment_options')}
      </Typography>
      <Paper className={classes.BillingData}>
        <CreditCardList
          classes={classes}
          creditCards={creditCards}
          handleCardClick={selectCreditCard}
          handleNewCreditCardClick={handleNewCreditCardClick}
        />
        <SlipBank
          classes={classes}
          handleSelectBankSlip={handleSelectBankSlip}
        />
      </Paper>
      <footer className={classes.form__actions}>
        <Button
          className={classes.form__action}
          variant='contained'
          onClick={retreatStep}
        >
          {I18n.t('actions.back')}
        </Button>
      </footer>
    </Fragment>
}

const CreditCardList = ({ classes, creditCards, handleCardClick, handleNewCreditCardClick }) =>
  <Fragment>
    <section className={classes.BillingData__Credit_Cards}>
      <Typography>
        {
          !_.isEmpty(creditCards)
            ? I18n.t('v4/payment_checkout.billing_data.registered_credit_cards')
            : I18n.t('v4/payment_checkout.billing_data.no_card_registered')
        }
      </Typography>

      <section className={classes.BillingData__Cards_list}>
        {
          creditCards.map(card =>
            <CreditCard
              key={card.id}
              classes={classes}
              handleCardClick={() => handleCardClick(card)}
              card={card}
            />
          )
        }
      </section>
    </section>

    <Divider />

    <Typography
      variant='subtitle1'
      className={classes.CreditCard__New}
      onClick={handleNewCreditCardClick}
    >
      <DefaultCreditCardIcon className={classes.CreditCard__NewIcon} fontSize='large' />
      {I18n.t('v4/payment_checkout.billing_data.new_credit_card')}
    </Typography>

  </Fragment>

const SlipBank = ({ classes, handleSelectBankSlip }) =>
  <Fragment>
    <Divider />

    <Typography
      variant='subtitle1'
      className={classes.CreditCard__New}
      onClick={handleSelectBankSlip}
    >
      <DefaultBankSlipIcon className={classes.CreditCard__NewIcon} fontSize='large' />
      {I18n.t('v4/payment_checkout.billing_data.generate_bank_slip')}
    </Typography>
  </Fragment>

const styles = (theme) => ({
  BillingData: {
    margin: `${theme.spacing.unit * 3}px 0`,
    maxWidth: 'max-content',
    padding: theme.spacing.unit * 4,
    display: 'flex',
    flexDirection: 'column'
  },
  BillingData__Cards_list: {
    margin: `${theme.spacing.unit * 2}px 0`
  },
  CreditCard: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    cursor: 'pointer',
    padding: `0 ${theme.spacing.unit}px`,
    '&:hover': {
      borderRadius: '5px',
      boxShadow: `0 0 3px ${theme.palette.primary.main}`
    }
  },
  CreditCard__name: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    display: 'flex',
    alignItems: 'center',
    marginRight: `${theme.spacing.unit * 3}px`
  },
  CreditCard__icon: {
    marginRight: `${theme.spacing.unit}px`,
    display: 'flex',
    alignItems: 'center'
  },
  CreditCard__number: {
    minWidth: '75px',
    textAlign: 'end',
  },
  CreditCard__New: {
    cursor: 'pointer',
    display: 'flex',
    alignItems: 'center',
    margin: `${theme.spacing.unit * 2}px 0`
  },
  CreditCard__NewIcon: {
    color: theme.palette.primary.main,
    marginRight: `${theme.spacing.unit}px`
  }
})

BillingData.defaultProps = {
  creditCards: []
}

export default withStyles(styles)(BillingData)
