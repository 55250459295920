import styles from '@styles/indexPage'

export default theme => ({
  ...styles(theme),
  AlertWrapper: {
    position: 'relative',
    minHeight: 'calc(100vh - 135px)',
    paddingBottom: '40px',
    [theme.breakpoints.up('md')]: {
      minHeight: 'calc(100vh - 240px)'
    }
  },
  AlertDescription: {
    margin: `${theme.spacing.unit * 2}px 0`,
    [theme.breakpoints.up('md')]: {
      margin: `${theme.spacing.unit * 5}px 0`,
    }
  },
  AlertContent: {
    margin: `${theme.spacing.unit * 2}px auto`
  },
  AlertIndex: {
    padding: `${theme.spacing.unit * 2}px`,
    [theme.breakpoints.up('md')]: {
      padding: '50px 256px 50px'
    },
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  },
  AlertPaper: {
    padding: theme.spacing.unit * 2,
    margin: `0 auto ${theme.spacing.unit * 3}px`,
    maxWidth: '500px'
  },
  AlertChip: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit,
    height: 'auto'
  },
  AlertChip_label: {
    fontWeight: 500,
    whiteSpace: 'normal',
    paddingTop: 5,
    paddingBottom: 5
  },
  AlertVariables: {
    display: 'flex',
    flexFlow: 'row wrap'
  },
  AlertInfo: {
    padding: `${theme.spacing.unit + 2}px 0px 0px 0px`,
    fontSize: '12px'
  },
  Alert_actions: {
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      marginTop: `${theme.spacing.unit * 2}px`
    },
    padding: `${theme.spacing.unit + 2}px 0`
  },
  Alert_action: {
    '&:not(:last-child)': {
      marginRight: `${theme.spacing.unit * 2}px`
    }
  },
  AlertToggleWrapper: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  AlertRadioButton: {
    display: 'flex',
    alignItems: 'center'
  },
  Alert_add: {
    position: 'fixed',
    right: '1.76%',
    bottom: '3.12%',
    zIndex: 2,
    '@media(max-width: 800px)': {
      position: 'fixed',
      right: '6.13%',
      bottom: '2.85%',
      zIndex: 2
    }
  },
  Alert_Boxes: {
    display: 'flex',
    justifyContent: 'space-around',

    '@media(max-width: 800px)': {
      flexDirection: 'column',
    }
  },
  Alert_error: {
    '&.MuiFormHelperText-root.Mui-error': {
      color: theme.palette.red
    }
  },
  Alert_Category: {
    width: '40%',

    '@media(max-width: 800px)': {
      width: '100%',
      marginTop: theme.spacing.unit * 4
    }
  },
  Alert_Card: {
    display: 'flex',
    justifyContent: 'center'
  },
  AlertContent_index: {
    margin: `${theme.spacing.unit * 4}px auto`
  },
  AlertPaper_index: {
    padding: theme.spacing.unit * 2,
    width: 300,
    marginBottom: 70
  },
  AlertFilter_container: {
    display: 'flex',
    justifyContent: 'flex-start',
    minHeight: theme.spacing.unit * 12.5,
    flexWrap: 'wrap',
    padding: theme.spacing.unit
  },
  AlertFilter_action: {
    width: '100%',
    height: theme.spacing.unit * 7.5,
    marginTop: theme.spacing.unit * 2
  },
  Alert_table: {
    display: 'block',
    margin: theme.spacing.unit * 5,
    borderRadius: 10,
    width: '100%',
    zIndex: 1
  },
  AlertPage_wrapper: {
    margin: `${theme.spacing.unit * 5}px auto 0`,
    padding: theme.spacing.unit * 4,
    maxWidth: '1200px'
  },
  AlertFilter_field: {
    flex: 1,
    minHeight: '80px',
    minWidth: '300px',
    margin: theme.spacing.unit * 2
  },
  Alert_loading: {
    display: 'flex',
    justifyContent: 'center',
    margin: '50px'
  },
  Alert_noData: {
    display: 'flex',
    justifyContent: 'center',
    margin: '50px'
  },
  Alert_not_viewed: {
    color: theme.palette.red
  },
  AlertVariables_table: {
    display: 'flex',
    flexFlow: 'row wrap',
    marginLeft: theme.spacing.unit * 8,
    padding: `${theme.spacing.unit}px 0`,
    maxHeight: '150px',
    overflow: 'auto',
    ...theme.scrollbar
  },
  AlertCardHistory_collapse: {
    width: '310px',
    maxHeight: '400px',
    padding: theme.spacing.unit * 2,
    cursor: 'pointer',
    margin: theme.spacing.unit
  },
  AlertCardHistory_not_collapse: {
    width: '310px',
    height: '200px',
    padding: theme.spacing.unit * 2,
    cursor: 'pointer',
    margin: theme.spacing.unit
  },
  AlertCardHistory_header: {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between'
  },
  AlertCardHistory_infos: {
    display: 'flex',
    alignItems: 'flex-start'
  },
  AlertCardHistory_info: {
    paddingLeft: theme.spacing.unit
  },
  AlertCardHistory_area: {
    color: theme.palette.gray.dark,
    paddingTop: theme.spacing.unit
  },
  AlertCardHistory_container: {
    display: 'flex',
    flexDirection: 'column',
    paddingBottom: theme.spacing.unit * 2
  },
  AlertCardHistory_status: {
    paddingTop: theme.spacing.unit * 2
  },
  AlertCardHistory_card: {
    display: 'flex',
    flexFlow: 'row wrap',
    maxHeight: '160px',
    overflow: 'auto',
    ...theme.scrollbar
  },
  AlertCardHistory_icon: {
    background: theme.palette.gray.main
  },
  AlertCardHistory_title: {
    padding: `${theme.spacing.unit * 3}px ${theme.spacing.unit * 3}px  0 ${theme.spacing.unit * 3}px`,
    margin: 'auto'
  },
  AlertCardHistory_wrapper: {
    maxHeight: '1200px',
    overflowX: 'hidden',
    overflowY: 'auto',
    marginTop: theme.spacing.unit * 2,
    ...theme.scrollbar
  }
})
