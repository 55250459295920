import React from 'react'

import withWidth from '@material-ui/core/withWidth'
import DefaultLogo from '../../../../../assets/images/logo_verde.png'
import Icon from '../../../../../assets/images/green_leaf.svg'

const Logo = ({ width, logo, smallLogo }) => {
  const components = {
    xs: smallLogo || Icon,
    sm: smallLogo || Icon,
    md: logo || DefaultLogo,
    lg: logo || DefaultLogo,
  }

  const Component = components[width] || logo || DefaultLogo

  return (
    <div style={{ height: '50px' }}>
      <img
        src={Component}
        height='100%'
      />
    </div>
  )
}

export default withWidth()(Logo)
