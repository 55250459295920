import React from 'react'
import { Form as FinalForm } from 'react-final-form'
import { validate } from '@models/admin/sensor'
import { RailsForm } from '@support/RailsForm'

import Form from './Form'

export const New = ({ variables, o }) => {
  const formattedVariables = variables.map(variable => ({
    ...variable,
    id: variable.name
  }))

  return <RailsForm
    component={FinalForm}
    action='create'
    url={`/v4/o/${o}/admin/sensors`}
    validate={validate}
    render={(props) => {
      return <Form
        {...props}
        variables={formattedVariables}
        title={I18n.t('v4/sensor.actions.add')}
        o={o}
      />
    }}
  />
}

export default New
