import React from 'react'
import { IconButton, Typography } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Close'
import PreviewScreen from './PreviewScreen'
import { MuiThemeProvider, withStyles } from '@material-ui/core/styles'
import previewStyles from './previewStyles'

import mountTheme from '@theme/mountTheme'

const TenantPreview = ({ classes, handleClose, logo_url, small_logo_url, theme }) =>
  <MuiThemeProvider theme={mountTheme(theme)}>
    <div
      className={classes.Preview__header}
    >
      <Typography
        className={classes.Preview__header_title}
        component='h2'
        variant='h5'
      >
        {I18n.t('v4/tenant.preview.preview_screen')}
      </Typography>
      <IconButton
        title={I18n.t('actions.close')}
        onClick={handleClose}
      >
        <CloseIcon />
      </IconButton>
    </div>
    <PreviewScreen
      logo_url={logo_url}
      small_logo_url={small_logo_url}
      theme={theme}
    />
  </MuiThemeProvider>

export default withStyles(previewStyles)(TenantPreview)
