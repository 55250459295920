import React from 'react'
import L from 'leaflet'

import 'leaflet-draw'
import 'leaflet.icon.glyph'
import 'leaflet/dist/leaflet.css'
import 'leaflet-draw/dist/leaflet.draw.css'
import 'leaflet.gridlayer.googlemutant'
import 'leaflet/src/control'
import 'leaflet.markercluster/dist/MarkerCluster.css'
import 'leaflet.markercluster/dist/MarkerCluster.Default.css'
import 'leaflet.markercluster/dist/leaflet.markercluster.js'
import iconShadow from 'leaflet/dist/images/marker-shadow.png'
import icon from 'leaflet/dist/images/marker-icon.png'
import { withSnackbar } from 'notistack'

import AreaMenu from '@images/map/draw_area.svg'
import Alert from '@images/alerts/alert.svg'
import locationPickerIcone from '@images/leaflet/picker_default.svg'
import locationPickerActiveIcone from '@images/leaflet/picker_selected.svg'
import WeatherForecast from '@images/equipment/weather_forecast.svg'

import CustomMenu from './Controls'
import { withStyles } from '@material-ui/core'
import { areNotEqual } from '@models/area'
import {
  createCustomControl,
  changeCustomControl,
  locationPickerPopupContent
} from '@models/leaflet'
import {
  circleToPolygon,
  changeMapStyle,
  changeAreaMenuStyle,
  getLeafLetTranslations,
  getZoom,
  getImageBounds
} from '@models/map'

import { getEquipmentIcon } from '@models/equipment/equipment'

import { IntercomAPI } from '@utils/IntercomAPI'
import { sendAmplitudeEvent } from '@utils/amplitudeEvent';


import { withMenusStatus, MENUS } from '@contexts/MenusStatusProvider'

import ProductsFloatingMenu from './productsFloatingMenu/Index'
import styles from './styles'

const defaultAreaColor = '#00F0FF'
class Map extends React.Component {
  constructor(props) {
    super(props)

    this.forecastProductReference = React.createRef()

    this.state = {
      isActivePicker: false,
      drawing: false,
      drawItems: new L.FeatureGroup(),
      clusterLayer: null,
      markersLayer: new L.FeatureGroup(),
      filter: null,
      currentAreaWithFilter: undefined,
      scale: null,
      geocoderLayer: undefined,
      setupFPLayer: undefined,
      setupFPByMapLayer: undefined,
      openAreaMenu: false,
      pickerSnackbar: null
    }

    this.map = undefined
  }

  componentDidMount() {
    const {
      centerCoord,
      handleEdit,
      handleOpenDialog,
      handleDrawAction,
      onRef,
      zoom,
      handleMapClick,
      has_write_permissions,
      useCluster
    } = this.props

    onRef(this)

    const maxZoomTopography = 17
    const maxZoom = 20
    
    const satellite = L.gridLayer.googleMutant({
      type: 'hybrid',
      styles: [
        {
          featureType: 'poi',
          stylers: [{ visibility: 'off' }]
        },
      ]
    })

    const streets = L.gridLayer.googleMutant({
      type: 'roadmap',
      styles: [
        {
          featureType: 'poi',
          stylers: [{ visibility: 'off' }]
        }
      ]
    })

    const topography = L.tileLayer('https://{s}.tile.opentopomap.org/{z}/{x}/{y}.png', {
      maxZoom: 20,
      type: 'topography',
      attribution: 'Map data: &copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors, <a href="http://viewfinderpanoramas.org">SRTM</a> | Map style: &copy; <a href="https://opentopomap.org">OpenTopoMap</a> (<a href="https://creativecommons.org/licenses/by-sa/3.0/">CC-BY-SA</a>)'
    })

    const baseMaps = {
      [I18n.t('v4/area.satellite')]: satellite,
      [I18n.t('v4/area.streets')]: streets,
      [I18n.t('v4/area.topography')]: topography
    }

    const layer = baseMaps[localStorage.getItem('layers')] || satellite

    const map = L.map('dashboard-map', {
      center: centerCoord,
      zoom: layer.options.type === 'topography' ? maxZoomTopography : zoom,
      maxZoom: layer.options.type === 'topography' ? maxZoomTopography : maxZoom,
      layers: [layer],
      minZoom: 2.5,
      zoomControl: false
    })

    this.map = map

    L.control.layers(baseMaps, null, { position: 'bottomright' }).addTo(this.map)

    map.on('click', (e) => {
      this.openLocationPicker(e.latlng.lat, e.latlng.lng)
    })

    map.on('baselayerchange', (e) => {
      localStorage.setItem('layers', e.name)
      const current_zoom = map.getZoom()

      if (e.layer.options.type === 'topography' && current_zoom > maxZoomTopography) {
        map.setZoom(maxZoomTopography - 1)
        map.setMaxZoom(maxZoomTopography)

        setTimeout(() => {
          map.setZoom(maxZoomTopography)
        }, 1000)
      } else {
        const zoom_min = current_zoom - 1

        map.setZoom(zoom_min)
        map.setMaxZoom(maxZoom)

        setTimeout(() => {
          map.setZoom(current_zoom)
        }, 1000)
      }
    })

    const DefaultIcon = L.icon({
      iconUrl: icon,
      shadowUrl: iconShadow,
      iconSize: [25, 41],
      iconAnchor: [13, 40]
    })

    L.Marker.prototype.options.icon = DefaultIcon

    L.control.zoom({
      zoomInTitle: I18n.t('leaflet.control.zoom.zoomInTitle'),
      zoomOutTitle: I18n.t('leaflet.control.zoom.zoomOutTitle'),
      position: 'bottomright'
    }).addTo(map)

    const drawItems = new L.FeatureGroup()

    if (useCluster) {
      const clusterLayer = L.markerClusterGroup({
        removeOutsideVisibleBounds: true
      })
      const deflateLayer = L.deflate({ minSize: 30, markerLayer: clusterLayer })
      deflateLayer.addTo(map)

      this.setState({ drawItems: deflateLayer })
    } else {
      this.setState({ drawItems: drawItems })
    }

    map.addLayer(drawItems)
    map.addLayer(this.state.markersLayer)

    L.drawLocal = { ...L.drawLocal, ...getLeafLetTranslations() }

    const onLocationPickerClick = () => {
      const { isActivePicker, pickerSnackbar } = this.state
      const { enqueueSnackbar, closeSnackbar } = this.props

      this.setState({ isActivePicker: !isActivePicker }, () => {
        if (this.state.isActivePicker) {
          const pickerSnackbar = enqueueSnackbar(
            I18n.t('leaflet.location_picker.snackbar_msg'),
            {
              variant: 'success',
              autoHideDuration: null
            }
          )

          this.setState({ pickerSnackbar })
        } else {
          !!pickerSnackbar && closeSnackbar(pickerSnackbar)
        }

        this.changePickerStyles()
      })
    }

    const onAreaMenuClick = () => this.setState({ openAreaMenu: !this.state.openAreaMenu })

    map.addControl(
      createCustomControl({
        imageUrl: locationPickerIcone,
        title: I18n.t('leaflet.control.location_picker'),
        onClick: onLocationPickerClick,
        options: { position: 'bottomright' },
        dataSet: {
          intercomTarget: 'location-picker'
        },
        id: 'leaflet-control-location-picker'
      })
    )

    if (has_write_permissions) {
      map.addControl(
        createCustomControl({
          imageUrl: AreaMenu,
          title: 'Menu',
          onClick: onAreaMenuClick,
          options: { position: 'bottomright' },
          dataSet: {
            intercomTarget: 'area-controller-map'
          },
          id: 'leaflet-control-area-menu'
        })
      )

      const drawControl = new L.Control.Draw({
        position: 'bottomright',
        edit: {
          featureGroup: drawItems,
          remove: false
        },
        draw: {
          circlemarker: false,
          polyline: false,
          rectangle: false,
          marker: false,
          polygon: {
            allowIntersection: false,
            drawError: {
              color: '#e1e100',
              message: `<strong>${I18n.t('v4/map.intersection_error')}<strong>`
            },
          }
        }
      })

      this.setState({ drawControl: drawControl })
      map.addControl(drawControl)
    }

    map.on(L.Draw.Event.CREATED, (e) => {
      const type = e.layerType
      const layer = e.layer
      let size = 0

      layer.type = type
      drawItems.addLayer(layer)

      if (type === 'polygon') {
        const areaPol = L.GeometryUtil.geodesicArea(layer.getLatLngs()[0])
        layer.center = layer.getCenter()
        size = L.GeometryUtil.readableArea(areaPol, true)
      }

      if (type === 'circle') {
        layer.coordinates = circleToPolygon(L, layer, 36, this.map)
        const radius = layer._mRadius
        size = L.GeometryUtil.readableArea(3.14 * (radius * radius), true, 0)
      }

      layer.size = size
      handleOpenDialog(layer, drawItems)
    })

    map.on('draw:edited', (e) => {
      const layers = e.layers
      let size_ha = 0
      let radius

      layers.eachLayer(async layer => {
        const { id } = layer.options.info
        let center
        let coordinates

        if (layer.options.info.kind === 'polygon') {
          coordinates = layer.getLatLngs()[0]
          center = layer.getCenter()

          const newAreaPol = L.GeometryUtil.geodesicArea(layer.getLatLngs()[0])
          size_ha = L.GeometryUtil.readableArea(newAreaPol, true)
        }

        if (layer.options.info.kind === 'circle') {
          coordinates = circleToPolygon(L, layer, 36, this.map)
          center = layer._latlng
          radius = layer._mRadius
          size_ha = L.GeometryUtil.readableArea(3.14 * (radius * radius), true, 0)
        }

        await handleEdit({
          id,
          size_ha,
          radius,
          latitude: center.lat,
          longitude: center.lng,
          coordinates
        })
      })
    })

    map.on('draw:editstop', () => {
      handleDrawAction('editing', false)
    })

    map.on('draw:editstart', () => {
      handleDrawAction('editing', true)
    })

    map.on('draw:drawstop', () => {
      handleDrawAction('drawing', false)
    })

    map.on('draw:drawstart', () => {
      handleDrawAction('drawing', true)
    })

    const mapBounds = L.latLngBounds([
      [-90, -180],
      [90, 180]
    ]);
    map.setMaxBounds(mapBounds);
    map.on('click', handleMapClick)
    map.invalidateSize()
  }

  changeMapCenter = (center, areaSize) => {
    this.map.flyTo(center, getZoom(areaSize))
  }

  cleanGeocoderLayer = () => {
    const { geocoderLayer } = this.state

    if (geocoderLayer) {
      this.map.removeLayer(geocoderLayer)
    }
  }

  addSearchInfoInMap = (coordinates, info, hasPin) => {
    this.cleanGeocoderLayer()

    const layer = !!hasPin
      ? new L.marker(coordinates).addTo(this.map)
        .bindPopup(info)
        .openPopup()
      : new L.popup()
        .setLatLng(coordinates)
        .setContent(info)
        .openOn(this.map)

    this.setState({ geocoderLayer: layer })
  }

  cleanLayer = (layer) => {
    if (!!this.state[layer]) {
      this.map.removeLayer(this.state[layer])
    }
  }

  addNewLayerPin = ({ lat, lng }) => {
    const { menusStatus: { menus, toggleMenu, setMenuData } } = this.props
    const { data, step } = menus[MENUS.weatherForecastProduct]
    const { open } = menus[MENUS.weatherForecastSetupFixedModal]

    this.cleanLayer('setupFPByMapLayer')

    if (open && (step === 1 || step === 2)) {
      const layer = new L.marker([lat, lng]).addTo(this.map)
      this.setState({ setupFPByMapLayer: layer })

      step === 1 && toggleMenu(MENUS.weatherForecastSetupLatLongModal, true)
      setMenuData(MENUS.weatherForecastProduct, 'data', { ...data, lat, lon: lng })
    }
  }

  addPin = (coordinates) => {
    const { menusStatus: { toggleMenu } } = this.props

    this.cleanLayer('setupFPLayer')
    const layer = new L.marker(coordinates).addTo(this.map)

    this.changeMapCenter(coordinates)
    this.map.once('zoomend', () => {
      toggleMenu(MENUS.weatherForecastSetupLatLongModal, true)
    })

    this.setState({ setupFPLayer: layer })
  }

  applyFilter = (imageUrl, area) => {
    this.removeCurrentFilter()

    const filter = L.imageOverlay(imageUrl, getImageBounds(area))

    this.setState({ filter, currentAreaWithFilter: area }, () => {
      filter.addTo(this.map)
    })
  }

  removeCurrentFilter = () => {
    const { filter } = this.state

    if (filter) {
      this.setState({ filter: null, currentAreaWithFilter: undefined }, () =>
        this.map.removeLayer(filter)
      )
    }
  }

  getCenterOfView = () => this.map.getCenter()

  getAreaGroup = () => {
    const { alerts, mapAreas = [] } = this.props
    let alertsOnTheMap = []

    if (!_.isEmpty(mapAreas) && !_.isEmpty(alerts)) {
      const newAlerts = alerts.map(alert => {
        const { areas, ...noAreas } = alert
        const currentAlerts = areas.map(area => ({ ...area, ...noAreas }))

        return currentAlerts
      }).flat()

      const areaGroup = _.groupBy(newAlerts, 'id')

      alertsOnTheMap = Object.keys(areaGroup).map(area => {
        const currentArea = areaGroup[area][0].id

        const newArea = mapAreas.find(mapArea => mapArea.id === currentArea)

        return {
          areaName: newArea.name,
          latitude: newArea.latitude,
          longitude: newArea.longitude,
          alerts: areaGroup[area]
        }
      })
    }

    return alertsOnTheMap
  }

  openLocationPicker = (latitude, longitude) => {
    const { isActivePicker, pickerSnackbar } = this.state
    const { classes, closeSnackbar } = this.props

    if (isActivePicker) {
      let locationPickerPopup = L.popup({
        minWidth: 94
      })

      locationPickerPopup
        .setLatLng([latitude, longitude])
        .setContent(locationPickerPopupContent(latitude, longitude, classes))
        .openOn(this.map)

      this.setState({ isActivePicker: false },
        () => {
          this.changePickerStyles()
          closeSnackbar(pickerSnackbar)
        }
      )
    }
  }

  enableOrDisableArea = () => {
    const { areaMenuData } = this.props

    const newLayers = Object.keys(this.map._layers)
      .map(layer => this.map._layers[layer])
      .filter(isLayer => !_.isEmpty(isLayer.editing))

    return newLayers.find(layer => layer.options.info.id === areaMenuData.id)
  }


  changePickerStyles = () => {
    const { isActivePicker } = this.state

    const icon = isActivePicker ? locationPickerActiveIcone : locationPickerIcone
    const color = isActivePicker ? '#66A343' : '#FFF'
    changeCustomControl(icon, color, 'leaflet-control-location-picker')
  }

  handleCloseOrOpenEditAreaMenu = (closeOrOpen) => {
    const { menusStatus: { toggleMenu } } = this.props

    toggleMenu(MENUS.mapEditAreaMenu, closeOrOpen)
  }

  handleCancelAreaMenu = () => {
    this.enableOrDisableArea().editing.disable()

    this.handleCloseOrOpenEditAreaMenu(false)
  }

  handleSaveAreaMenu = () => {
    const { enqueueSnackbar } = this.props

    this.handleCloseOrOpenEditAreaMenu(false)

    enqueueSnackbar(I18n.t('v4/map.edit_area_menu.snackbar'), { variant: 'success' })

    return new L['EditToolbar'].Edit(this.map, {
      featureGroup: this.state.drawControl.options.edit.featureGroup,
      selectedPathOptions: this.state.drawControl.options.edit.selectedPathOptions
    }).save()
  }

  render() {
    const { drawItems, markersLayer, currentAreaWithFilter, openAreaMenu } = this.state

    const {
      circleAreas,
      classes,
      editing,
      forecastPointsList,
      handleAreaClick,
      polygonAreas,
      markers,
      menusStatus: { menus, setMenuData, toggleMenu },
      handleAlertSelect,
      handleEquipmentClick,
      changeAreasColors,
      mapAreas,
      removeColorsFromAreas,
      hasForecastProduct,
      handleAreaFileControlClick,
      has_write_permissions,
      equipment
    } = this.props
    
    const isOpenModal = menus[MENUS.weatherForecastSetupFixedModal].open
    const isOpenConfirmModal = menus[MENUS.weatherForecastSetupLatLongModal].open
    const isOpenMapSatelliteImagery =
      menus[MENUS.mapSatelliteImagery].open
      || menus[MENUS.mapSatelliteImageryScale].open
      || menus[MENUS.irrigationProduct].open

    !!this.map && changeMapStyle(isOpenMapSatelliteImagery)

    !isOpenMapSatelliteImagery && this.removeCurrentFilter()

    if (has_write_permissions) {
      !!this.map && changeAreaMenuStyle(openAreaMenu)
    }

    !isOpenModal && !isOpenConfirmModal && this.cleanLayer('setupFPByMapLayer')

    drawItems.clearLayers()
    markersLayer.clearLayers()
    circleAreas.map(area => {
      areNotEqual(area, currentAreaWithFilter) && !area.hide &&
        L.circle([area.latitude, area.longitude], {
          color: area.color || defaultAreaColor,
          radius: area.radius,
          size_ha: area.size_ha,
          info: { kind: area.kind, id: area.id }
        })
          .addTo(drawItems)
          .on('click', () => {
            if (!editing && !isOpenModal) {
              handleAreaClick(area.id)
            }
          })
    })

    polygonAreas.map(area => {
      areNotEqual(area, currentAreaWithFilter) && !area.hide &&
        new L.polygon(area.coordinates, {
          color: area.color || defaultAreaColor,
          size_ha: area.size_ha,
          info: { kind: area.kind, id: area.id }
        })
          .addTo(drawItems)
          .on('click', () => {
            if (!editing && !isOpenModal) {
              handleAreaClick(area.id)
            }
          })
    })

   markers.map(marker => {
      L.marker([
        marker.latitude, marker.longitude
      ],
        {
          icon: L.icon({
            iconUrl: getEquipmentIcon(marker),
            iconSize: [50, 50]
          })
        }
      )
        .addTo(markersLayer)
        .on('click', () => {
          if (!editing && !isOpenModal) {
            handleEquipmentClick(marker)
            this.openLocationPicker(marker.latitude, marker.longitude)
          }
        })
    })

    this.getAreaGroup().map(marker => {
      L.marker([
        marker.latitude, marker.longitude
      ],
        {
          icon: L.icon({
            iconUrl: Alert,
            iconSize: [25, 41]
          })
        }
      )
        .addTo(markersLayer)
        .on('click', () => {
          if (!editing && !isOpenModal) {
            handleAlertSelect(marker)
            this.openLocationPicker(marker.latitude, marker.longitude)
          }
        })
    })

    forecastPointsList.map(forecastPoint => {
      L.marker([
        forecastPoint.lat, forecastPoint.lon
      ],
        {
          icon: L.icon({
            iconUrl: WeatherForecast,
            iconSize: [50, 50]
          })
        }
      )
        .addTo(markersLayer)
        .on('click', () => {
          if (!editing && !isOpenModal) {
            setMenuData(MENUS.weatherForecastProduct, 'forecastPoint', forecastPoint)
            toggleMenu(MENUS.weatherForecastProduct, true)
            this.openLocationPicker(forecastPoint.lat, forecastPoint.lon)
            sendAmplitudeEvent('Map - Clicked weather forecast icon', {
              id_product: 1
            })
            IntercomAPI('trackEvent', 'Map - Clicked weather forecast icon')
          }
        })
    })

    return (
      <div id='dashboard-map' className={classes.map}>
        <ProductsFloatingMenu
          addPin={this.addPin}
          cleanLayer={this.cleanLayer}
          mapAreas={mapAreas}
          changeMapCenter={this.changeMapCenter}
          changeAreasColors={changeAreasColors}
          removeColorsFromAreas={removeColorsFromAreas}
          hasForecastProduct={hasForecastProduct}
        />

        {
          openAreaMenu &&
          <CustomMenu
            handleAreaFileControlClick={handleAreaFileControlClick}
            open={openAreaMenu}
            onClose={() => this.setState({ openAreaMenu: false })}
          />
        }
      </div>
    )
  }
}

Map.defaultProps = {
  onRef: () => { },
}

export default
  withMenusStatus(
    withSnackbar(
      withStyles(styles)(Map)
    )
  )
