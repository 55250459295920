import React from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";

import { withStyles } from '@material-ui/core'

const PivotGraphic = ({ classes, areaPercentage, irrigationDegrees }) => (
  <div className={classes.PivotGraphic_Container} >
    <CircularProgressbar
      value={areaPercentage}
      strokeWidth={50}
      styles={buildStyles({
        strokeLinecap: 'butt',
        pathColor: '#c6c6c6',
        trailColor: 'inherit',
        rotation: 0.5 + (1 - areaPercentage / 100) / 2
      })}
    />

    <div className={classes.PivotGraphic_InnerContainer}>
      <CircularProgressbar
        value={irrigationDegrees}
        strokeWidth={50}
        styles={buildStyles({
          strokeLinecap: 'butt',
          trailColor: 'inherit',
          rotation: 0.5 + (1 - irrigationDegrees / 100) / 2
        })}
      />
    </div>
  </div>
)

const styles = theme => ({
  PivotGraphic_Container: {
    width: theme.spacing.unit * 12.5,
    position: 'relative'
  },
  PivotGraphic_InnerContainer: {
    position: 'absolute',
    top: 0,
    left: 0,
  }
})

export default withStyles(styles)(PivotGraphic)
