import React from 'react'

import IrrigationChart from '../irrigationChart'
import EventsMap from '../eventsMap'
import HydricStatus from '../hydricStatus'

const IrrigationManagementContent = ({
  areaChartsSeries,
  ChartTooltip,
  classes,
  dateVisualization,
  deserializedData,
  events,
  nonAreaChartSeries,
  phenologicalStages,
  selectedVisualization,
  visualizations,
}) =>
  <section className={classes.IrrigationDashboard_Content}>
    <HydricStatus
      areaChartsSeries={areaChartsSeries}
      irrigationData={deserializedData}
    />

    <IrrigationChart
      irrigationData={deserializedData}
      chartSeries={nonAreaChartSeries}
      dateVisualization={dateVisualization}
      areaChartsSeries={areaChartsSeries}
      CustomTooltip={ChartTooltip}
      selectedVisualization={selectedVisualization}
      phenologicalStages={phenologicalStages}
      visualizations={visualizations}
    />

    <EventsMap
      irrigationData={events}
      dateVisualization={dateVisualization}
    />
  </section>

export default IrrigationManagementContent
