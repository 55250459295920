export const namesMapA = {
  mapName: 'mapA',
  filterMenu: 'satelliteImageryProductA',
  satelliteImageryMenu: 'mapSatelliteImageryA',
  scaleMenu: 'mapSatelliteImageryScaleA',
  dataItercomTargetPicker: 'location-picker-mapA',
  pickerId: 'leaflet-control-location-picker-mapA'
}

export const namesMapB = {
  mapName: 'mapB',
  filterMenu: 'satelliteImageryProductB',
  satelliteImageryMenu: 'mapSatelliteImageryB',
  scaleMenu: 'mapSatelliteImageryScaleB',
  dataItercomTargetPicker: 'location-picker-mapB',
  pickerId: 'leaflet-control-location-picker-mapB'
}