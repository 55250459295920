import React from 'react'
import { Form as FinalForm } from 'react-final-form'

import httpService from '@services/httpService'
import { serialize } from '@models/admin/sensor_config'
import { RailsForm } from '@support/RailsForm'
import Form from './Form'

export const New = ({ part_number_registries, sensors, handleError, o }) => {

  const handleSubmit = async values => {
    values['usage'] === 'manual' && delete values.part_number_id

    try {
      const body = serialize(values)

      await httpService.post(`/v4/o/${o}/admin/sensor_configs`, body)

      Turbolinks.visit(`/v4/o/${o}/admin/sensor_configs`)
    } catch (e) {
      handleError(e)
    }
  }

  return <RailsForm
    component={FinalForm}
    action='create'
    handleSubmit={handleSubmit}
    initialValues={{ usage: 'automatic' }}
    url={`/v4/o/${o}/admin/sensor_configs`}
    render={(props) => (
      <Form
        {...props}
        partNumbers={part_number_registries}
        sensors={sensors}
        title={I18n.t('v4/configuration.actions.add')}
        o={o}
        showSelecEquipment={true}
      />
    )}
  />
}


New.defaultProps = {
  sensors: [],
  part_number_registries: []
}

export default New
