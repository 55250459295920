import React from 'react'
import { Field } from 'react-final-form'

import {
  Button,
  Paper,
  withStyles,
} from '@material-ui/core'

import DatePicker from '@ui/Fields/DatePicker'
import SearchSelect from '@ui/Fields/SearchSelect'

import styles from './styles'

const Filter = ({
  handleSubmit,
  classes,
  values,
  areas,
  variables,
  alerts
}) => {

  const [state, setState] = React.useState({
    area_id: values['area_id'] || null,
    alert_id: values['alert_id'] || null,
    variable_id: values['variable_id'] || null,
  })

  const handleChange = (currentValue = {}, name) => {
    const data = { ...state }
    data[name] = currentValue

    values[name] = !_.isEmpty(currentValue) ? currentValue.value : currentValue

    setState({ ...data })
  }

  return (
    <form onSubmit={handleSubmit}>
      <Paper className={classes.AlertFilter_container}>
        <div className={classes.AlertFilter_field}>
          <SearchSelect
            fullWidth
            margin='normal'
            data={alerts}
            fields={{ label: 'name', value: 'id' }}
            variant='outlined'
            placeholder={I18n.t('actions.search')}
            autocomplete={true}
            isClearable
            textFieldProps={{
              label: I18n.t('v4/alerts.new.steps.alert_name.default_alert_name'),
              InputLabelProps: {
                shrink: true
              }
            }}
            onChange={(event) => handleChange(event, 'alert_id')}
            value={state.alert_id}
            usePaperClass={true}
          />
        </div>

        <div className={classes.AlertFilter_field}>
          <SearchSelect
            fullWidth
            margin='normal'
            data={variables}
            fields={{ label: 'variable_description', value: 'variable' }}
            variant='outlined'
            placeholder={I18n.t('actions.search')}
            autocomplete={true}
            isClearable
            textFieldProps={{
              label: I18n.t('v4/alerts.alerts.variables.variable'),
              InputLabelProps: {
                shrink: true
              }
            }}
            onChange={(event) => handleChange(event, 'variable_id')}
            value={state.variable_id}
            usePaperClass={true}
          />
        </div>

        <div className={classes.AlertFilter_field}>
          <SearchSelect
            fullWidth
            margin='normal'
            data={areas}
            fields={{ label: 'name', value: 'id' }}
            variant='outlined'
            placeholder={I18n.t('actions.search')}
            autocomplete={true}
            isClearable
            textFieldProps={{
              label: I18n.t('v4/alerts.alerts.area'),
              InputLabelProps: {
                shrink: true
              }
            }}
            onChange={(event) => handleChange(event, 'area_id')}
            value={state.area_id}
            usePaperClass={true}
          />
        </div>

        <div className={classes.AlertFilter_field}>
          <Field
            component={DatePicker}
            margin='normal'
            InputLabelProps={{ shrink: true }}
            variant='outlined'
            label={I18n.t('v4/equipment.dashboard.filter.fields.start_date')}
            name='start_date'
            fullWidth
            disableFuture
            required
          />
        </div>

        <div className={classes.AlertFilter_field}>
          <Field
            component={DatePicker}
            margin='normal'
            InputLabelProps={{ shrink: true }}
            variant='outlined'
            label={I18n.t('v4/equipment.dashboard.filter.fields.end_date')}
            name='end_date'
            fullWidth
            disableFuture
            required
          />
        </div>

        <div className={classes.AlertFilter_field}>
          <Button
            className={classes.AlertFilter_action}
            color='primary'
            type='submit'
            variant='contained'
          >
            {I18n.t('v4/irrigation_management.filter.action')}
          </Button>
        </div>
      </Paper>
    </form>
  )
}

export default withStyles(styles)(Filter)
