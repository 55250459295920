import React from 'react'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'

import { getSoilAnalysisTypes } from '@models/analysis/sample'
import Field from '@ui/Fields/Field'
import styles from '@theme/styles/forms'
import httpService from '@services/httpService'

import { useState } from 'react';

const Form = ({
  handleSubmit,
  classes,
  action,
  onCancel,
  form
}) => {

    // State to control visibility of fields
    const [showFields, setShowFields] = useState({
      depth: true,
      type_soil_analysis: true,
      soil_density: false,
      alpha: false,
      n: false,
      theta: false,
      critical_soil_tension: false,
      particle_density: false,
      sand: true,
      silt: true,
      clay: true,
      field_capacity: true,
      permanent_wilting_point: true
    });

  const isFillSiltAndClay = () => {
    const silt = form.getFieldState('silt')
    const clay = form.getFieldState('clay')

    return silt &&
      silt.value &&
      clay &&
      clay.value
  }

  const isFillFCAndPWP = () => {
    const permanent_wilting_point = form.getFieldState('permanent_wilting_point')
    const field_capacity = form.getFieldState('field_capacity')

    return permanent_wilting_point &&
      permanent_wilting_point.value &&
      field_capacity &&
      field_capacity.value
  }

  const calculate = async () => {
    const silt = form.getFieldState('silt').value
    const clay = form.getFieldState('clay').value

    try {
      const { data } = await httpService.post(`/api/v4/soil_samples/calculate?organization_id=1`, { silt, clay })
      form.change('field_capacity', data.fc_est)
      form.change('permanent_wilting_point', data.pwp_est)
    }
    catch (error) {
      console.log(error)
    }
  }

  return <form
    onSubmit={handleSubmit}
    data-testid="analysis-form"
  >
    <main className={classes.form__fields}>
      <Grid container spacing={24}>
      {showFields.depth && (
        <Field
          gridItem
          type='number'
          inputProps={{ min: 1, max: 200 }}
          label={I18n.t('activerecord.attributes.v4/analysis.depth')}
          name='depth'
          required
        />
      )}

      {showFields.type_soil_analysis && (
        <Field
          gridItem
          type='select'
          data={getSoilAnalysisTypes()}
          label={I18n.t('activerecord.attributes.v4/analysis.type_soil_analysis')}
          name='type_soil_analysis'
          required
        />
      )}

      {showFields.soil_density && (
        <Field
          gridItem
          type='number'
          inputProps={{ min: '0.5', max: '2.5', step: '0.000001' }}
          label={I18n.t('activerecord.attributes.v4/analysis.soil_density')}
          name='soil_density'
        />
      )}

      {showFields.alpha && (
        <Field
          gridItem
          type='number'
          inputProps={{ min: '0.01', max: '20', step: '0.000001' }}
          label={I18n.t('activerecord.attributes.v4/analysis.alpha')}
          name='alpha'
        />
      )}

      {showFields.n && (
        <Field
          gridItem
          type='number'
          inputProps={{ min: '0.01', max: '5', step: '0.000001' }}
          label={I18n.t('activerecord.attributes.v4/analysis.n')}
          name='n'
        />
      )}

      {showFields.theta && (
        <Field
          gridItem
          type='number'
          inputProps={{ min: '0.01', max: '0.8', step: '0.000001' }}
          label={I18n.t('activerecord.attributes.v4/analysis.theta')}
          name='theta'
        />
      )}

      {showFields.critical_soil_tension && (
        <Field
          gridItem
          type='number'
          inputProps={{ min: '0', max: '100', step: '0.01' }}
          label={I18n.t('activerecord.attributes.v4/analysis.critical_soil_tension')}
          name='critical_soil_tension'
        />
      )}

      {showFields.particle_density && (
        <Field
          gridItem
          type='number'
          inputProps={{ min: '0.5', max: '3.0', step: '0.01' }}
          label={I18n.t('activerecord.attributes.v4/analysis.particle_density')}
          name='particle_density'
        />
      )}

      {showFields.sand && (
        <Field
          gridItem
          type='number'
          inputProps={{ min: '0', max: '100', step: '0.01' }}
          label={I18n.t('activerecord.attributes.v4/analysis.sand')}
          name='sand'
        />
      )}

      {showFields.silt && (
        <Field
          gridItem
          type='number'
          inputProps={{ min: '0', max: '100', step: '0.01' }}
          label={I18n.t('activerecord.attributes.v4/analysis.silt')}
          name='silt'
        />
      )}

      {showFields.clay && (
        <Field
          gridItem
          type='number'
          inputProps={{ min: '0', max: '100', step: '0.01' }}
          label={I18n.t('activerecord.attributes.v4/analysis.clay')}
          name='clay'
        />
      )}

      <Button
        className={classes.form__action}
        color='secondary'
        onClick={calculate}
        variant='outlined'
        disabled={!isFillSiltAndClay()}
        fullWidth
      >
        {I18n.t('v4/analysis.samples.actions.calculate')}
      </Button>

      {showFields.field_capacity && (
        <Field
          gridItem
          type='number'
          inputProps={{ min: '0.01', max: '0.6', step: '0.000001' }}
          label={I18n.t('activerecord.attributes.v4/analysis.field_capacity')}
          name='field_capacity'
          required
        />
      )}

      {showFields.permanent_wilting_point && (
        <Field
          gridItem
          type='number'
          inputProps={{ min: '0.01', max: '0.6', step: '0.000001' }}
          label={I18n.t('activerecord.attributes.v4/analysis.permanent_wilting_point')}
          name='permanent_wilting_point'
          required
        />
      )}
      </Grid>
    </main>
    <footer className={classes.form__actions}>
      <Button
        className={classes.form__action}
        color='primary'
        type='submit'
        variant='contained'
        disabled={!isFillFCAndPWP()}
      >
        {action}
      </Button>

      <Button
        className={classes.form__action}
        color='secondary'
        onClick={onCancel}
      >
        {I18n.t('actions.cancel')}
      </Button>
    </footer>
  </form>
}

export default withStyles(styles)(Form)
