import React from 'react'

import {
  Button,
  Checkbox,
  FormControlLabel,
  Grow,
  Link,
  Paper,
  Typography,
  withStyles
} from '@material-ui/core'

import Terms from '@images/terms/terms.svg'
import Sign from '@images/terms/sign.svg'
import { useSessionData } from '@contexts/SessionDataProvider'
import { useHttpService } from '@contexts/HttpServiceProvider'
import { useErrorHandler } from '@contexts/ErrorHandlerProvider'

const PrivacyPolicy = ({ classes, o, privacy_policy_id }) => {
  const sessionData = useSessionData()
  const { currentUser, currentOrganization } = sessionData
  const { apiRequest } = useHttpService()
  const handleError = useErrorHandler()

  const [state, setState] = React.useState({
    accept_privacy_policy: false
  })

  const handleChangePrivacyPolicy = () => {
    setState({
      accept_privacy_policy: !state.accept_privacy_policy
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    try {
      const path = '/user_privacy_policies/sign_privacy_policy'
      
      const { data } = await apiRequest.post(
        path,
        {
          organization_id: currentOrganization.id,
          user_privacy_policy: {
            privacy_policy_id,
            signed: state.accept_privacy_policy
          }
        }
      )
      Turbolinks.visit(data.url || `/v4`)
    }
    catch (error) {
      handleError(error)
    }
  }

  return (
    <form className={classes.UserTerms_form} onSubmit={handleSubmit}>
      <Paper className={classes.UserTerms_wrapper} elevation={0}>
        <header className={classes.UserTemrs_title}>
          <Typography variant='body2'>
            {I18n.t('v4/privacy_policies.updated.hello', {name: currentUser.name})}
          </Typography>
          <Typography variant='body2'>
            {I18n.t('v4/privacy_policies.updated.title')}
          </Typography>
        </header>

        <main className={classes.UserTems_main}>
          <div className={classes.UserTerms_imageWrapper}>
            <div className={classes.UserTerms_image}>
              <img src={Terms} />
              <Grow in={state.accept_privacy_policy}>
                <img src={Sign} className={classes.UserTerms_sign} />
              </Grow>
            </div>
          </div>

          <Typography variant='body2' className={classes.UserTerms_text}>
            <Link
              variant="body2"
              href='/v5/privacy_policies'
              className={classes.UserTerms_link}
              underline='always'
              target='_blank'
            >
              {I18n.t('v4/privacy_policies.updated.click_here')}
            </Link>
            {` ${I18n.t('v4/privacy_policies.updated.complete_terms')}`}
          </Typography>

          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={state.accept_privacy_policy}
                  onChange={handleChangePrivacyPolicy}
                  value='checked'
                  color='primary'
                />
              }
              label={I18n.t('v4/privacy_policies.updated.accept')}
              classes={{
                label: classes.UserTerms_text
              }}
            />
          </div>
        </main>

        <footer className={classes.UserTerms_actions}>
          <Button
            className={classes.UserTerms_action}
            disabled={!state.accept_privacy_policy}
            color='primary'
            type='submit'
            variant='contained'
            fullWidth
          >
            {I18n.t('actions.continue')}
          </Button>
        </footer>
      </Paper>
    </form>
  )
}

const styles = (theme) => ({
  UserTerms_form: {
    display: 'flex',
    marginTop: 67,
    alignItems: 'center',
    justifyContent: 'center'
  },
  UserTemrs_title: {
    textAlign: 'center'
  },
  UserTerms_imageWrapper: {
    textAlign: 'center',
    margin: `${theme.spacing.unit * 3}px 0`
  },
  UserTerms_image: {
    position: 'relative',
    display: 'inline-block'
  },
  UserTerms_text: {
    fontSize: 13,
    fontWeight: 400
  },
  UserTerms_link: {
    color: theme.palette.text.primary,
    fontSize: 13,
    fontWeight: 500
  },
  UserTerms_sign: {
    position: 'absolute',
    bottom: 38,
    right: 27
  },
  UserTerms_actions: {
    marginTop: theme.spacing.unit * 2
  }
})

export default withStyles(styles)(PrivacyPolicy)
