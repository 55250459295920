import React from 'react'

import WeatherForecast from './Index'
import Dialog from '@ui/ActionDialog'
import { useMenusStatus, MENUS } from '@contexts/MenusStatusProvider'

const Wrapper = ({ addPin, cleanLayer }) => {
  const { menus, toggleMenu, setMenuData } = useMenusStatus()
  const { open } = menus[MENUS.weatherForecastSetupLatLongModal]

  const changeContext = (field, value) => {
    setMenuData(MENUS.weatherForecastProduct, field, value)
  }

  const cancelHandler = () => {
    const flow = menus[MENUS.weatherForecastProduct]

    if(flow.onMapFlow) {
      toggleMenu(MENUS.weatherForecastSetupFixedModal, true)
      cleanLayer('setupFPByMapLayer')
      changeContext('step', 1)
    } else {
      toggleMenu(MENUS.weatherForecastProduct, true)
      changeContext('onFlow', true)
      cleanLayer('setupFPLayer')
    }
  }

  const confirmHandler = () => {
    toggleMenu(MENUS.weatherForecastProduct, true)
    changeContext('onFlow', true)
    changeContext('step', 2)
    cleanLayer('setupFPLayer')
  }

  return(
   <>
     <WeatherForecast
       addPin={addPin}
     />
     <Dialog
       open={open}
       cancelHandler={cancelHandler}
       confirmHandler={confirmHandler}
       disableBackdropClick={true}
       dialogTitle={I18n.t('v4/forecast.configuration.dialog_title')}
       dialogText={I18n.t('v4/forecast.configuration.dialog_text')}
       cancelActionText={I18n.t('confirmation.false')}
       confirmActionText={I18n.t('confirmation.true')}
       cancelButtonType='default'
       confirmButtonType='primary'
       place='flex-end'
     />
   </>
  )
}

export default Wrapper
