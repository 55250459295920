import React from 'react'

import { Button, withStyles } from '@material-ui/core'

import Delete from '@material-ui/icons/Delete'
import Harvest from '@images/area/harvest_white.svg'

const Action = ({
  classes,
  label,
  onChange,
  variant,
  intercomTag,
  isCropLastStage,
  useIcon
}) =>
  <Button
    color='primary'
    className={classes.Action_wrapper}
    onClick={onChange}
    variant={variant}
    data-intercom-target={intercomTag}
  >
    {label}

    {
      useIcon && (
        <>
          {
            isCropLastStage
              ? <img src={Harvest} alt='harvest' className={classes.Action_icon} />
              : <Delete />
          }
        </>
      )
    }
  </Button>

const styles = theme => ({
  Action_wrapper: {
    width: '100%',
    marginTop: theme.spacing.unit 
  },
  Action_icon: {
    width: '25px',
    height: '25px',
    marginLeft: theme.spacing.unit 
  },
})

export default withStyles(styles)(Action)
