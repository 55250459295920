import React from 'react'

import { Button, withStyles, Typography } from '@material-ui/core'

import styles from '@styles/indexPage'

import Select from '@ui/SimpleSelect'

const Header = ({
  classes,
  equipmentList,
  handleOpenDialog,
  handleEquipmentChange,
  currentEquipment
}) =>
  <>
    <header className={classes.page__header}>
      <Typography component='h2' variant='h5'>
        {I18n.t('v4/equipment.pluviometer.dashboard.title')}
      </Typography>
    </header>

    <div className={classes.Header_select}>
      <Button
        className={classes.Header_action}
        variant='outlined'
        color='primary'
        onClick={() => handleOpenDialog('add')}
      >
        {I18n.t('v4/equipment.pluviometer.actions.register_rain')}
      </Button>

      <Select
        values={equipmentList}
        handleChange={({ target }) => handleEquipmentChange(target.value)}
        selectedIndex={currentEquipment}
        isTitle
      />
    </div>

    <header className={classes.page__header} />
  </>

const customStyles = theme => ({
  ...styles(theme),
  Header_select: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  Header_action: {
    marginBottom: theme.spacing.unit
  }
})

export default withStyles(customStyles)(Header)
