import React from 'react'
import Dropzone from '@ui/Fields/Dropzone'
import LoginScreenPreview from './LoginScreenPreview'

const Index = ({ classes, handleChange, login_screen_logo, login_screen_background, logoPreview, backgroundPreview }) =>
  <div>
    <div className={classes.form__field__wrapper}>
      <Dropzone
        label={I18n.t('activerecord.attributes.v4/tenant.login_screen_logo')}
        setFile={file => handleChange('login_screen_logo', file)}
      />
    </div>

    <div className={classes.form__field__wrapper}>
      <Dropzone
        label={I18n.t('activerecord.attributes.v4/tenant.login_screen_background')}
        setFile={file => handleChange('login_screen_background', file)}
      />
    </div>

    <LoginScreenPreview
      logoPreview={
        login_screen_logo
          ? URL.createObjectURL(login_screen_logo)
          : logoPreview
      }
      backgroundPreview={
        login_screen_background
          ? URL.createObjectURL(login_screen_background)
          : backgroundPreview
      }
    />
  </div>

export default Index
