import httpService from '../services/httpService'
import PropTypes from 'prop-types'

export const setCurrentOrganization = (id, getLink) => {
  httpService.put(getLink(`sessions/organization/${id}`))
    .then((result) => window.location.href = `${result.data.location}/maps`)
    .catch((error) => console.error(error))
}

export const organizationShape =
  PropTypes.shape({
    id: PropTypes.number,
    name: PropTypes.string,
    label: PropTypes.label
  })

export const validate = values => {
  const requiredFields = ['name', 'kind', 'predominant_culture']
  const errors = {}

  requiredFields.forEach(field => {
    if (!values[field]) errors[field] = I18n.t('validations.required')
  })

  return errors
}

export const fields = [
  'id', 'name', 'kind_text', 'predominant_culture_text', 'timezone'
]

export const getOrganizationHeaders = () =>
  fields.map(field => ({
    title: I18n.t(`activerecord.attributes.v4/organization.${field}`),
    field,
    cellStyle: customPadding
  }))

export const customPadding = { padding: '4px 20px 4px 24px' }
