import React from "react";
import Button from "@material-ui/core/Button";

import { Form as FinalForm, Field } from "react-final-form";
import { RailsForm } from "@support/RailsForm";

import SelectWithVisitButton from "@ui/Fields/SelectWithVisitButton";
import DatePicker from "@ui/Fields/DatePicker";

import { validateFormCrop } from "@models/area";
import withStyles from "@material-ui/core/styles/withStyles";

import { useRouter } from "@contexts/RouterProvider";

const FormCrop = ({ handleSubmit, classes, cultures, areaName }) => {
  const { visit } = useRouter();

  return (
    <RailsForm
      component={FinalForm}
      validate={validateFormCrop}
      handleSubmit={handleSubmit}
      initialValues={{ planting_date: new Date(), culture_id: "" }}
      render={({ handleSubmit }) => (
        <form
          className={classes.FormCrop_Wrapper}
          onSubmit={handleSubmit}
          data-testid="invitation-form"
        >
          <div className={classes.FormCrop_Fields}>
            <Field
              component={SelectWithVisitButton}
              className={classes.form__fieldWithError}
              InputLabelProps={{ shrink: true, required: true }}
              label={I18n.t("activerecord.models.v4/culture.one")}
              name="culture_id"
              margin="normal"
              fields={{ id: "id", name: "name" }}
              data={cultures}
              fullWidth
              variant="outlined"
              required
              actionTitle={`${I18n.t("actions.add")} ${I18n.t(
                "activerecord.models.v4/culture.one"
              )}`}
              onchange={() => visit("cultures")}
              data-intercom-target="areacard_crop_click_culture"
            />
          </div>

          <div className={classes.FormCrop_Fields}>
            <Field
              className={classes.form__fieldWithError}
              component={DatePicker}
              margin="normal"
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              label={I18n.t(
                "activerecord.attributes.v4/culture.phenological_stages.date"
              )}
              name="planting_date"
              disableFuture
              fullWidth
              required
              data-intercom-target="areacard_crop_click_date"
            />
          </div>

          <div>
            <Button
              color="primary"
              type="submit"
              variant="contained"
              className={classes.FormCrop_Action}
              data-intercom-target="areacard_crop_click_plant"
            >
              {`${I18n.t(
                "activerecord.attributes.v4/area.menu.plant_in"
              )} ${areaName}`}
            </Button>
          </div>
        </form>
      )}
    />
  );
};

const styles = (theme) => ({
  FormCrop_Wrapper: {
    width: "100%",
    marginRight: theme.spacing.unit + 2,
  },
  FormCrop_Action: {
    width: "100%",
  },
  FormCrop_Fields: {
    height: "100px",
  },
});

export default withStyles(styles)(FormCrop);
