import React from 'react'
import ColorsLegend from './ColorsLegend'
import SampleCard from './SampleCard'

import { MENUS, withMenusStatus } from '@contexts/MenusStatusProvider'

import { convertToLocaleDate } from '@services/locale'

import {
  Button,
  IconButton,
  Paper,
  Typography,
  withStyles
} from '@material-ui/core'

import {
  Clear,
  Create,
  KeyboardArrowDown,
  KeyboardArrowUp
} from '@material-ui/icons'

const SatelliteImageryFooterMenu = ({
  applyFilter,
  classes,
  filterMenuName,
  map,
  mapName,
  removeFilter,
  satelliteImageryName,
  scaleMenuName,
  toggle,
  isComparison,
  menusStatus: { menus, setMenuData }
}) => {
  const [image, setImage] = React.useState(null)

  const { data } = menus[MENUS[satelliteImageryName]]
  const { open: openMapSatelliteImageryScale } = menus[MENUS[scaleMenuName]]

  const {
    filterName,
    scale,
    cloudsPercentage,
    startDate,
    endDate,
    currentArea,
    images
  } = data

  const handleSelectImage = (currentImage) => {
    if (image !== currentImage.date) {
      setImage(currentImage.date)
      applyFilter(currentImage.image, currentArea, map, mapName)
    }
    else {
      setImage(null)
      removeFilter(map, mapName)
    }
  }

  const handleClose = () => {
    removeFilter(map, mapName)
    setMenuData(MENUS[filterMenuName], 'anchor', null)
    toggle(satelliteImageryName, false)
    isComparison && toggle(filterMenuName, true)
  }

  const handleEdit = () => {
    removeFilter(map, mapName)
    toggle(filterMenuName, true)
    isComparison && toggle(satelliteImageryName, false)
  }

  const hasScale = _.isEmpty(scale)

  return (
    <>
      {
        openMapSatelliteImageryScale
          ? <>
            {
              !hasScale
                ? (
                  <div
                    className={classes.SatelliteImageryFooterMenu_Minimize}
                    onClick={() => {
                      toggle(satelliteImageryName, true)
                      isComparison && toggle(scaleMenuName, false)
                    }}
                  >
                    <div className={classes.SatelliteImageryFooterMenu_action_minimize_with_scale}>
                      <Typography variant='body2'>
                        {I18n.t('v4/products.view')}
                      </Typography>

                      <KeyboardArrowUp />
                    </div>

                    <ColorsLegend scale={scale} />
                  </div>
                )
                : (
                  <div
                    className={classes.SatelliteImageryFooterMenu_action_minimize_no_scale}
                    onClick={() => {
                      toggle(satelliteImageryName, true)
                      isComparison && toggle(scaleMenuName, false)
                    }}
                  >
                    <Typography variant='body2'>
                      {I18n.t('v4/products.view')}
                    </Typography>

                    <KeyboardArrowUp />
                  </div>
                )
            }
          </>
          : (
            <Paper
              className={classes.SatelliteImageryFooterMenu_Container}
            >
              {
                !hasScale &&
                <ColorsLegend scale={scale} />
              }

              <div className={classes.SatelliteImageryFooterMenu_Wrapper}>
                <div className={classes.SatelliteImageryFooterMenu_infos}>
                  <Typography variant='subtitle2' className={`${classes.SatelliteImageryFooterMenu_info} ${classes.SatelliteImageryFooterMenu_name}`}>
                    {I18n.t('v4/products.view')}
                  </Typography>

                  <Typography variant='caption' className={classes.SatelliteImageryFooterMenu_info}>
                    {
                      `${filterName} | ${I18n.t('v4/satellite_images.attributes.clouds')}: ${cloudsPercentage}% |
                      ${convertToLocaleDate(startDate)} - ${convertToLocaleDate(endDate)}`
                    }
                  </Typography>
                </div>

                <div className={classes.SatelliteImageryFooterMenu_edit}>
                  <Button
                    type='submit'
                    color='secondary'
                    onClick={handleEdit}
                    className={classes.SatelliteImageryFooterMenu_action}
                  >
                    <Create />
                    <Typography variant='body2' className={classes.SatelliteImageryFooterMenu_action_text}>
                      {I18n.t('v4/satellite_images.actions.edit')}
                    </Typography>
                  </Button>

                  <div className={classes.SatelliteImageryFooterMenu_action_minimize}>
                    <IconButton
                      onClick={() => toggle(scaleMenuName, true)}
                      onClick={() => {
                        toggle(scaleMenuName, true)
                        isComparison && toggle(satelliteImageryName, false)
                      }}
                    >
                      <KeyboardArrowDown />
                    </IconButton>

                    <IconButton
                      onClick={handleClose}
                    >
                      <Clear />
                    </IconButton>
                  </div>
                </div>
              </div>

              <div className={classes.SatelliteImageryFooterMenu_Cards}>
                {
                  images.map((currentImage, index) =>
                    <SampleCard
                      key={`sampleCard_${index}`}
                      selected={image === currentImage.date}
                      data={currentImage}
                      handleClick={() => handleSelectImage(currentImage)}
                      filterName={filterName}
                    />
                  )
                }
              </div>
            </Paper>
          )
      }
    </>
  )
}

const styles = theme => ({
  SatelliteImageryFooterMenu_Container: {
    minHeight: theme.spacing.unit * 34,
    height: 'auto',
    position: 'absolute',
    bottom: 0,
    zIndex: 1050,
    right: 0,
    background: theme.palette.white,
    '@media(max-width: 760px)': {
      height: '340px',
    },
    cursor: 'auto',
    borderRadius: 0,
    overflow: 'hidden',
    width: '100%'
  },
  SatelliteImageryFooterMenu_Minimize: {
    height: '15px',
    width: '100%',
    bottom: 0,
    zIndex: 1050,
    right: 0,
    position: 'absolute',
    background: theme.palette.white,
    cursor: 'pointer'
  },
  SatelliteImageryFooterMenu_action_minimize_no_scale: {
    bottom: 0,
    zIndex: 1050,
    right: 0,
    position: 'absolute',
    background: theme.palette.white,
    '&:hover': {
      background: theme.palette.white
    },
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    borderRadius: 0,
    cursor: 'pointer'
  },
  SatelliteImageryFooterMenu_Wrapper: {
    display: 'flex'
  },
  SatelliteImageryFooterMenu_Cards: {
    maxWidth: 'fit-content',
    display: 'flex',
    padding: theme.spacing.unit,
    margin: `0 ${theme.spacing.unit * 3}px ${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px`,
    overflow: 'auto',
    ...theme.scrollbar
  },
  SatelliteImageryFooterMenu_infos: {
    padding: theme.spacing.unit * 2,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%'
  },
  SatelliteImageryFooterMenu_info: {
    display: 'flex',
    marginLeft: theme.spacing.unit * 2
  },
  SatelliteImageryFooterMenu_name: {
    marginLeft: theme.spacing.unit * 2
  },
  SatelliteImageryFooterMenu_action: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit}px ${theme.spacing.unit}px 0 `,
    color: theme.palette.primary.main
  },
  SatelliteImageryFooterMenu_edit: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'flex-end',
    flexWrap: 'wrap-reverse',
    paddingRight: theme.spacing.unit * 2
  },
  SatelliteImageryFooterMenu_action_text: {
    color: theme.palette.primary.main,
    paddingLeft: theme.spacing.unit
  },
  SatelliteImageryFooterMenu_action_minimize: {
    display: 'flex'
  },
  SatelliteImageryFooterMenu_action_minimize_with_scale: {
    bottom: 15,
    zIndex: 1050,
    right: 0,
    position: 'absolute',
    background: theme.palette.white,
    '&:hover': {
      background: theme.palette.white
    },
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    borderRadius: 0
  }
})

SatelliteImageryFooterMenu.defaultProps = {
  isComparison: false,
  applyFilter: () => { },
  removeFilter: () => { }
}

export default withMenusStatus(withStyles(styles)(SatelliteImageryFooterMenu))
