import React from 'react'
import PropTypes from 'prop-types'

import Automatic from './Automatic/Edit'
import Implement from './Implements/Edit'
import Manual from './Manual/Edit'
import Tractor from './Tractor/Edit'
import IrrigationSystem from './IrrigationSystem/Edit'

export const Edit = props => {
  const { model, handleError } = props

  const forms = {
    automatic: Automatic,
    implement: Implement,
    manual: Manual,
    tractor: Tractor,
    irrigation: IrrigationSystem
  }

  const EditForm = forms[model.kind]
  return (
    <EditForm handleError={handleError} {...props} />
  )
}

Edit.propTypes = {
  model: PropTypes.object
}

export default Edit
