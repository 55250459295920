import React, { useState, useEffect } from 'react'

import L from 'leaflet'
import 'leaflet/dist/leaflet.css'
import 'leaflet/src/control'
import 'leaflet.gridlayer.googlemutant'

import { withStyles } from '@material-ui/core'

import { useMenusStatus, MENUS } from '@contexts/MenusStatusProvider'

import locationPickerIcone from '@images/leaflet/picker_default.svg'
import locationPickerActiveIcone from '@images/leaflet/picker_selected.svg'

import {
  createCustomControl,
  changeCustomControl,
  locationPickerPopupContent
} from '@models/leaflet'

import {
  defaultAreaColor,
  getImageBounds,
  getZoom,
  satelliteOptions
} from '@models/map'

import Filter from './Filter'
import SatelliteFooterMenu from '../maps/productsFloatingMenu/View/FooterMenu'

const Map = ({
  areas,
  classes,
  id,
  loading,
  names,
  options
}) => {
  const menusStatus = useMenusStatus()
  const { menus, setMenuData } = menusStatus
  const [map, setMap] = useState(null)
  const [filter, setFilter] = useState(null)
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    const map = L.map(id, options)

    L.gridLayer
      .googleMutant(satelliteOptions)
      .addTo(map)

    map.addControl(
      createCustomControl(
        locationPickerControlOptions()
      )
    )

    setMap(map)
  }, [])

  useEffect(() => {
    !!map && setMenuData(MENUS[names.filterMenu], 'map', map)
  }, [map])

  useEffect(() => {
    drawAreas()
  }, [areas])

  useEffect(() => {
    if (!loading) {
      setMenuData(MENUS[names.filterMenu], 'open', true)
    }
  }, [loading])

  useEffect(() => {
    const icon = isActive ? locationPickerActiveIcone : locationPickerIcone
    const color = isActive ? '#66A343' : '#FFF'
    changeCustomControl(icon, color, names.pickerId)

    !!map && (
      isActive
        ? map.on('click', (e) => {
          openLocationPicker(e.latlng.lat, e.latlng.lng, isActive)
        })
        : map.off('click')
    )
  }, [isActive])

  const locationPickerControlOptions = () => {
    return {
      imageUrl: locationPickerIcone,
      title: I18n.t('leaflet.control.location_picker'),
      onClick: () => setIsActive(prevIsActive => !prevIsActive),
      options: { position: 'topleft' },
      dataSet: {
        intercomTarget: names.dataItercomTargetPicker
      },
      id: names.pickerId
    }
  }

  const changeMapCenter = (center, areaSize) => {
    map.flyTo(center, getZoom(areaSize))
  }

  const createPolygon = (area) => {
    return new L.Polygon(area.coordinates, {
      color: defaultAreaColor,
      id: area.id
    })
      .addTo(map)
      .on('click', () => {
        changeMapCenter([area.latitude, area.longitude], area.size_ha)
      })
  }

  const createCircle = (area) => {
    return new L.Circle([area.latitude, area.longitude], {
      radius: area.radius,
      color: defaultAreaColor,
      id: area.id
    })
      .addTo(map)
      .on('click', () => {
        changeMapCenter([area.latitude, area.longitude], area.size_ha)
      })
  }

  const drawAreas = () => {
    if (!!map) {
      areas.map(area => {
        area.kind_text === 'Polygon'
          && createPolygon(area)

        area.kind_text === 'Circle'
          && createCircle(area)
      })
    }
  }

  const applyFilter = (imageUrl, area) => {
    removeCurrentFilter()

    const filter = L.imageOverlay(imageUrl, getImageBounds(area), {
      zIndex: 200
    })

    setFilter(filter)
    filter.addTo(map)
  }

  const removeCurrentFilter = () => {
    if (filter) {
      setFilter(null)
      map.removeLayer(filter)
    }
  }

  const toggle = (menu, value) => {
    setMenuData(MENUS[menu], 'open', value)
  }

  const openLocationPicker = (latitude, longitude, isActive) => {
    if (isActive) {
      let locationPickerPopup = L.popup({
        minWidth: 94
      })

      locationPickerPopup
        .setLatLng([latitude, longitude])
        .setContent(locationPickerPopupContent(latitude, longitude, classes))
        .openOn(map)

      setIsActive(false)
      map.off('click')
    }
  }

  return (
    <div className={classes.Map_wrapper}>
      <div id={id} className={classes.Map}>
      </div>
      {
        (menus[names.satelliteImageryMenu].open || menus[names.scaleMenu].open)
          ? <SatelliteFooterMenu
            satelliteImageryName={names.satelliteImageryMenu}
            filterMenuName={names.filterMenu}
            scaleMenuName={names.scaleMenu}
            mapName={names.mapName}
            applyFilter={applyFilter}
            removeFilter={removeCurrentFilter}
            map={map}
            toggle={toggle}
            isComparison={true}
          />
          : <Filter
            areas={areas}
            changeMapCenter={changeMapCenter}
            names={names}
            loadingAreas={loading}
          />
      }
    </div>
  )
}

const styles = theme => ({
  Map_wrapper: {
    position: 'relative'
  },
  Map: {
    height: 'calc(100vh - 65px)'
  },
  Picker_unit: {
    margin: `0 !important`,
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    color: theme.palette.gray.dark
  },
  Picker_coordinates: {
    margin: `0 !important`,
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontSize: 13
  },
  Picker_wrapper: {
    marginBottom: theme.spacing.unit
  },
  Picker_button: {
    width: theme.spacing.unit * 12,
    background: theme.palette.white,
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    color: theme.palette.primary.main,
    border: `solid 1px ${theme.palette.primary.main}`,
    textTransform: 'uppercase',
    cursor: 'pointer',
    borderRadius: theme.spacing.unit / 2,
    fontWeight: 500,
    padding: '5px 16px',
    fontSize: 13,
    transition: 'background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,border 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms',
    '&:focus': {
      outline: 'none'
    },
    '&:hover': {
      backgroundColor: `${theme.palette.primary.main}14`
    }
  }
})

export default withStyles(styles)(Map)
