import React from 'react'

import { Grid, Typography, withStyles } from '@material-ui/core'

import WindDirection from "@ui/WindDirection"

import { getIconInfo } from '@models/equipment/equipment'

import { parseDecimalToTime } from '@services/locale'

const CardWithoutDepth = ({
  classes,
  equipmentData,
  hasWindDirection,
  hasWindSpeed
}) =>
  <>
    <Grid item xs={1} className={classes.Card_wrapperIcon}>
      {
        !!getIconInfo[equipmentData.name] &&
        <img src={getIconInfo[equipmentData.name]['src']} className={classes.Card_icon} />
      }
    </Grid>

    <Grid item xs={hasWindSpeed ? 6 : 7}>
      <Typography variant='body1'>
        {
          (equipmentData.name === 'lwh' || equipmentData.name === 'lwl')
            ? equipmentData.short_description
            : equipmentData.description
        }
      </Typography>
    </Grid>

    <Grid item xs={hasWindSpeed ? 5 : 4} className={classes.Card_values}>
      {
        equipmentData.name === 'wd1'
          ? hasWindDirection
            ? <div className={classes.Card_windRose}>
              <WindDirection
                align={'left'}
                angle={equipmentData.value}
              />
            </div>
            : <img src={getIconInfo['wind_rose_no_info']['src']} className={classes.Card_windRose} />
          : equipmentData.name === 'lwl' || equipmentData.name === 'lwh'
            ? <Typography variant='body2' className={classes.Card_RightAlign}>
              {parseDecimalToTime(equipmentData.value)}
            </Typography>
            : <Typography variant='body2' className={classes.Card_RightAlign}>
              {
                equipmentData.value != null
                  ? `${equipmentData.value} ${equipmentData.unit}`
                  : '-'
              }
            </Typography>
      }
    </Grid>
  </>

const styles = theme => ({
  Card_wrapperIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  Card_icon: {
    height: theme.spacing.unit * 2
  },
  Card_values: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center'
  },
  Card_windRose: {
    position: 'absolute'
  }
})

export default withStyles(styles)(CardWithoutDepth)
