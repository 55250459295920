import React from 'react'

import { withStyles } from '@material-ui/core/styles'

import Step from './Step'
import { Paper, Typography, Chip } from '@material-ui/core'

const SelectVariablesStep = ({
  advanceStep,
  retreatStep,
  classes,
  equipments,
  handleVariableSelect,
  selectedVariables,
  variables,
  areasCount
}) =>
  <Step
    title={I18n.t('v4/alerts.new.steps.select_variables.title')}
    description={I18n.t('v4/alerts.new.steps.select_variables.info')}
    showBackButton={false}
    disableButton={areasCount <= 0}
    advanceStep={advanceStep}
    retreatStep={retreatStep}
  >
    {
      Object.entries(variables).map(([kind, variables], index) =>
        <Group
          classes={classes}
          equipment={variables.description}
          handleVariableSelect={handleVariableSelect}
          key={`${variables.description}-${index}`}
          selectedVariables={selectedVariables}
          variables={variables}
          kind={kind}
        />
      )
    }

    <Typography component='div' align='center'>
      {I18n.t('v4/alerts.new.steps.select_variables.you_have')}
      <span className={classes.SelectVariablesStep_colorText}> {areasCount} {I18n.t('activerecord.models.v4/area.other')} </span>
      {I18n.t('v4/alerts.new.steps.select_variables.avaliable_areas')}
    </Typography>
  </Step>

const Group = ({ classes, equipment, handleVariableSelect, selectedVariables, variables, kind }) =>
  <Paper className={classes.Equipment}>
    <Typography variant='body2'>
      {equipment}
    </Typography>
    {
      variables.groups.map((variable, index) =>
        <Equipment
          classes={classes}
          group={variable.group_description}
          handleVariableSelect={handleVariableSelect}
          selectedVariables={selectedVariables}
          key={`${kind}-${index}`}
          variables={variable.variables}
          kind={kind}
          source={equipment}
        />
      )
    }
  </Paper>

const Equipment = ({ classes, group, handleVariableSelect, selectedVariables, variables, kind, source }) =>
  <div>
   {
     group !== 'default' &&
     <Typography variant='caption' className={classes.Group}>
       {group}
     </Typography>
   }

    <Variables
      classes={classes}
      handleVariableSelect={handleVariableSelect}
      selectedVariables={selectedVariables}
      variables={variables}
      kind={kind}
      source={source}
    />
  </div>

const Variables = ({ classes, kind, handleVariableSelect, variables, selectedVariables, source }) =>
  <section className={classes.Variables}>
    {
      variables.map(variable =>
        <Variable
          classes={classes}
          key={`${variable.variable}-${kind}`}
          variable={variable}
          selected={selectedVariables.some(_variable => _variable.variable === variable.variable)}
          onSelect={() => handleVariableSelect(variable, kind, source)}
        />
      )
    }
  </section>

const Variable = ({ classes, variable, onSelect, selected }) =>
  <Chip
    className={classes.Variable}
    label={variable.variable_description}
    onClick={onSelect}
    color={selected ? 'primary' : 'default'}
  />

const styles = theme => ({
  SelectVariablesStep_colorText: {
    color: theme.palette.primary.main
  },
  Group: {
    marginTop: theme.spacing.unit * 2,
    marginLeft: theme.spacing.unit
  },
  Equipment: {
    padding: theme.spacing.unit * 2,
    margin: `0 auto ${theme.spacing.unit * 3}px`,
    maxWidth: '500px'
  },
  Variables: {
    display: 'flex',
    flexFlow: 'row wrap'
  },
  Variable: {
    marginTop: theme.spacing.unit,
    marginRight: theme.spacing.unit
  }
})

SelectVariablesStep.defaultProps = {
  handleVariableSelect: () => { },
  selectedVariables: [],
  areasCount: 0,
  variables: []
}

export default withStyles(styles)(SelectVariablesStep)
