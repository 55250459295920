import React from 'react'

import { Grid, Typography, withStyles, Paper } from '@material-ui/core'
import { useRouter } from '@contexts/RouterProvider'

import SafraImage from '@images/field_notebook/safra_with_celphone.svg'
import AppleStoreBadge from '@images/field_notebook/app-store-badge.png'
import GooglePlayBadge from '@images/field_notebook/google-play-badge.png'

const Card = ({ classes, data }) => {
  const { visit } = useRouter()

  return (
    <Paper
      style={{
        marginTop: '2rem',
      }}
    >
      <Grid
        direction='row'
        justify='space-between'
        alignItems='center'
        className={classes.Card_paper}
      >
        <Grid item >
          <Grid
            item
            className={classes.FieldNotebookTitle}
          >
            <Typography variant='h5' align='center'>
              {data.title}
            </Typography>
          </Grid>
          <Grid item>
            <Typography variant='caption' align='center'>
             {data.subtitle}
            </Typography>
          </Grid>
        </Grid>
        <img src={SafraImage} width='100%' />
        <Grid item >
          <Typography variant='body1' align='center'>
            {data.description}
          </Typography>
        </Grid>
        <Grid item >
          <Typography variant='body1' align='center'>
            <b>
             {data.call_to_action}
            </b>
          </Typography>
        </Grid>
        <Grid item row>
          <Grid item style={{
            padding: 'px 10px'
          }}>
            <a href='https://play.google.com/store/apps/details?id=br.com.agrosmart.app_agrosmart&hl=pt_BR&gl=US' target='_blank' >
              <img src={GooglePlayBadge} width='50%' />
            </a>
            <a href='https://apps.apple.com/br/app/agrosmart/id1539190452#?platform=iphone' target='_blank' >
              <img src={AppleStoreBadge} width='50%' />
            </a>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  )
}

const styles = (theme) => ({
  Card_paper: {
    display: 'flex',
    flexDirection: 'column',
    borderRadius: theme.spacing.unit,
    boxSizing: 'border-box',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px`,
    width: '100%',
    maxWidth: theme.spacing.unit * 60,
    height: theme.spacing.unit * 60,
  },
  FieldNotebookTitle: {
    marginBottom: '16px'
  }
})

export default withStyles(styles)(Card)
