import React, { useState } from 'react'

import {
  Button,
  CardMedia,
  Typography,
  Divider,
  withStyles
} from '@material-ui/core'

import { Icon, getStartAndFinishDateFilter } from '@models/area'

import LoadingComponent from '@ui/LoadingComponent'

import { useMenusStatus, MENUS } from '@contexts/MenusStatusProvider'
import { useSessionData } from '@contexts/SessionDataProvider'
import { useErrorHandler } from '@contexts/ErrorHandlerProvider'
import { useHttpService } from '@contexts/HttpServiceProvider'

const ViewProduct = ({ classes, currentArea }) => {
  const { currentOrganization } = useSessionData()
  const { setMenuData, toggleMenu } = useMenusStatus()
  const { apiRequest } = useHttpService()
  const handleError = useErrorHandler()
  const [loading, setLoading] = useState(false)

  const fetchFilters = async () => {
    try {
      setLoading(true)
      const { data } = await apiRequest('satellite_images/filters', { params: { organization_id: currentOrganization.id } })

      const filter = data.filters.find(filter => filter.name === 'NDVI / Sentinel-2 (L2A)') || {}

      handleFilter(filter)
    }
    catch (error) {
      handleError(error)
    }
  }

  const handleFilter = async (filter) => {
    try {
      const { start_date, end_date } = getStartAndFinishDateFilter()

      const defaultCloudsPercentage = 100

      const params = {
        area_id: currentArea.id,
        filter_id: filter.id,
        clouds_percentage: defaultCloudsPercentage,
        start_date,
        end_date,
        organization_id: currentOrganization.id
      }

      const orderImages = (images) => _.orderBy(images, 'date')

      const { data } = await apiRequest('satellite_images', { params })
      const images = orderImages(data.images)

      if (_.isEmpty(images)) {
        errorHandler(I18n.t('v4/satellite_images.errors.no_image'))
      } else {
        setMenuData(MENUS.mapSatelliteImagery, 'data',
          {
            filterName: filter.name,
            scale: !!filter['legend_info'] ? filter.legend_info : {},
            cloudsPercentage: defaultCloudsPercentage,
            startDate: start_date,
            endDate: end_date,
            currentArea,
            images,
            currentAreaId: currentArea.id,
            filterId: filter.id
          }
        )

        toggleMenu(MENUS.mapSatelliteImagery, true)
      }
    }
    catch (error) {
      handleError(error)
    }

    setLoading(false)
  }

  return (
    <>
      <div className={classes.ViewProduct_wrapper}>
        <div className={classes.ViewProduct_media}>
          <div className={classes.ViewProduct_icon}>
            <Icon variant='view' />
          </div>

          <Typography variant='body2' className={classes.ViewProduct_product}>
            {I18n.t('v4/satellite_images.area_card.view_product')}
          </Typography>
        </div>

        {
          loading
            ? (
              <LoadingComponent classes={classes} size={25} />
            )
            : (
              <div className={classes.ViewProduct_wrapper_action}>
                <Button
                  color='secondary'
                  variant='outlined'
                  className={classes.ViewProduct_action}
                  onClick={fetchFilters}
                  data-intercom-target='quick-search-ndvi-area-card'
                >
                  {I18n.t('v4/satellite_images.area_card.actions.title')}
                </Button>

                <Typography variant='caption' align='right'>
                  {I18n.t('v4/satellite_images.area_card.actions.last_days')}
                </Typography>
              </div>
            )
        }
      </div>
      <Divider />
    </>
  )
}

const Styles = theme => ({
  ViewProduct_wrapper: {
    display: 'flex',
    alignItems: 'baseline',
    justifyContent: 'space-between',
    padding: theme.spacing.unit
  },
  ViewProduct_product: {
    marginLeft: theme.spacing.unit,
    fontSize: '13px'
  },
  ViewProduct_media: {
    display: 'flex',
    alignItems: 'center'
  },
  ViewProduct_icon: {
    width: '25px',
    height: '25px'
  },
  ViewProduct_action: {
    fontSize: '13px'
  },
  Loading_wrapper: {
    marginRight: theme.spacing.unit * 2,
    padding: 0,
    textAlign: 'center'
  },
  ViewProduct_wrapper_action: {
    display: 'flex',
    flexDirection: 'column'
  }
})

export default withStyles(Styles)(ViewProduct)
