import React from 'react'

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'

const ActionDialog = ({
  classes,
  fullScreen,
  onClose,
  open,
  dialogTitle,
  dialogText,
  cancelHandler,
  confirmHandler,
  cancelActionText,
  confirmActionText,
  cancelButtonType,
  confirmButtonType,
  place
}) => {
  const PrimaryButton = (handler, text, classes) =>
    <Button
      color='primary'
      variant='contained'
      onClick={handler}
    >
      {text}
    </Button>

  const DefaultButton = (handler, text, classes) =>
    <Button
      variant='outlined'
      onClick={handler}
    >
      {text}
    </Button>

  const DangerButton = (handler, text, classes) =>
    <Button
      variant='outlined'
      onClick={handler}
      className={classes.ActionDialog__DangerButton}
    >
      {text}
    </Button>

  const buttonTypes = {
    primary: PrimaryButton,
    default: DefaultButton,
    danger: DangerButton
  }

  return(
    <Dialog
      fullScreen={fullScreen}
      open={open}
      onClose={onClose}
      classes={{scrollPaper: place ? classes.customScrollPaper : classes.scrollPaper}}
  >
      <DialogTitle>{dialogTitle}</DialogTitle>

      <DialogContent>
        <DialogContentText>
          {dialogText}
        </DialogContentText>
      </DialogContent>

      <DialogActions>
        {buttonTypes[cancelButtonType](cancelHandler, cancelActionText, classes)}
        {buttonTypes[confirmButtonType](confirmHandler, confirmActionText, classes)}
      </DialogActions>
    </Dialog>
  )
}

const styles = (theme) => ({
  ActionDialog__DangerButton: {
    ...theme.danger
  },
  customScrollPaper: {
    alignItems: 'flex-end'
  }
})

ActionDialog.defaultProps = {
  cancelActionText: I18n.t('v4/delete_dialog.cancel'),
  confirmActionText: I18n.t('v4/delete_dialog.confirm'),
  cancelHandler: () => {},
  confirmHandler: () => {},
  cancelButtonType: 'default',
  confirmButtonType: 'primary'
}

export default withStyles(styles)(ActionDialog)
