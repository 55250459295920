import React from 'react'

import { Button, Divider, Grid, TextField, Typography, FormHelperText } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import { Slider } from 'material-ui-slider'

import Select from '@ui/Fields/Select'
import DeleteDialog from '@ui/DeleteDialog'
import Step from './Step'

import {
  blockCharactersLocale,
  formatValue,
  getDays,
  variableWindSpeed_KM_H,
  variableWindSpeed_M_S,
  setTranslateVariablesRules
} from '@models/alerts'

const defaultValue = 0

const SetValuesForVariablesStep = ({
  advanceStep,
  classes,
  isFromLastStep,
  retreatStep,
  selectedVariables,
  actionCancel,
  actionEdit,
  minHeight
}) => {
  const [state, setState] = React.useState({
    variables: selectedVariables,
    isDialogOpen: false,
    selected: ''
  })

  const checkError = (value, minValue, maxValue) => !(value <= maxValue && value >= minValue)

  const handleChange = (name, value, index, checkValueRange = false) => {
    checkValueRange && blockCharactersLocale('value')

    let newVariables = [...state.variables]
    let selected = { ...newVariables[index] }
    let currentValue = checkValueRange ? formatValue(value) : value
    let error = false

    selected[name] = currentValue

    if (checkValueRange) {
      error = checkError(parseInt(currentValue), selected.min_value, selected.max_value)
      selected['error'] = error
    }

    newVariables[index] = selected

    setState({
      ...state,
      variables: newVariables
    })
  }

  const handleSelect = (frequency, value, index, rules) => {
    let newVariables = [...state.variables]
    let selected = { ...newVariables[index] }

    const currentRule = rules.find(rule => rule.id === value)

    selected[frequency] = currentRule.kind
    selected.name = currentRule.identifier
    selected.data_source_kind = selected.kind
    selected.value = selected.min_value
    selected.error = false
    selected.translation = currentRule.name
    selected.currentRule = value
    newVariables[index] = selected

    setState({
      ...state,
      variables: newVariables
    })
  }

  const handleRemoveRule = (selected) => {
    setState({
      ...state,
      variables: state.variables.filter(variable => variable.variable !== selected.variable && variable.variable_description !== selected.variable_description),
      isDialogOpen: false,
    })
  }

  const validate = () => {
    const checkValues = state.variables.filter(variable => !!variable.name)
    const hasError = state.variables.some(variable => variable.error)

    return !(checkValues.length === state.variables.length) || hasError
  }

  const closeDialog = () => setState({ ...state, isDialogOpen: false })

  const openDialog = variable => setState({ ...state, selected: variable, isDialogOpen: true })

  return (
    <>
      <Step
        title={I18n.t('v4/alerts.new.steps.select_variable_values.title')}
        description={`${I18n.t('v4/alerts.new.steps.select_variable_values.info')}:`}
        advanceStep={() => advanceStep(state.variables, isFromLastStep)}
        retreatStep={retreatStep}
        disableButton={validate()}
        showBackButton={!isFromLastStep}
        actionCancel={actionCancel}
        actionEdit={actionEdit}
        minHeight={minHeight}
      >
        {
          !_.isEmpty(state.variables) &&
          state.variables.map((variable, index) =>
            <div className={classes.SetValuesForVariablesStep__wrapper} key={`variables-${variable.variable}`}>
              <Grid container spacing={24} className={classes.SetValuesForVariablesStep_select_wrapper}>
                <Grid item xs={12} sm={6}>
                  <Typography variant='body2'>
                    {variable.description || variable.variable_description}
                  </Typography>
                  <Typography variant='body1'>
                    {variable.source}
                  </Typography>
                  {
                    !!variable.groupDescription && variable.groupDescription !== 'default' &&
                    <div className={classes.SetValuesForVariablesStepDescription}>
                      <Typography variant='body1'>
                        {variable.groupDescription}
                      </Typography>
                    </div>
                  }
                </Grid>

                <Grid item xs={12} sm={6}>
                  <Select
                    InputLabelProps={{ shrink: true }}
                    label={I18n.t('v4/alerts.new.steps.select_variable_values.define_rule')}
                    value={variable.currentRule || defaultValue}
                    onChange={(event) => handleSelect(event.target.name, event.target.value, index, variable.available_rules)}
                    data={setTranslateVariablesRules(variable.available_rules)}
                    fields={{ id: 'id', name: 'name' }}
                    name={'frequency'}
                    variant='outlined'
                    className={classes.SetValuesForVariablesStep_input}
                  />
                </Grid>
              </Grid>

              {
                !!variable.name &&
                <div className={classes.SetValuesForVariablesStep_slider}>
                  <div className={classes.SetValuesForVariablesStep_value}>
                    <TextField
                      value={variable.value}
                      className={classes.SetValuesForVariablesStep_field}
                      onChange={(event) => handleChange('value', event.target.value, index, true)}
                      margin="normal"
                      inputProps={{
                        className: classes.SetValuesForVariablesStep_textField
                      }}
                      name='value'
                      error={variable.error}
                    />
                    <Typography variant='h5' align='center' className={classes.SetValuesForVariablesStep_unity}>
                      {
                        variable.variable === variableWindSpeed_M_S
                          ? variableWindSpeed_KM_H
                          : variable.unity
                      }
                    </Typography>
                  </div>

                  {
                    variable.error &&
                    <FormHelperText className={classes.SetValuesForVariablesStep_error}>
                      {I18n.t('validations.field_between', { min: variable.min_value, max: variable.max_value })}
                    </FormHelperText>
                  }

                  <Slider
                    value={parseInt(variable.value)}
                    onChange={(value) => handleChange('value', value, index)}
                    min={variable.min_value}
                    max={variable.max_value}
                    name='value'
                  >
                  </Slider>
                </div>
              }

              {
                variable.frequency === 'multiple_days' &&
                <div className={classes.SetValuesForVariablesStep_field_wrapper}>
                  {
                    variable.kind === 'weather_forecast'
                      ? <>
                        <Typography variant='body1'>
                          {I18n.t('v4/alerts.new.steps.select_variable_values.in_the_next_days')}
                        </Typography>

                        <Select
                          value={variable.days || defaultValue}
                          onChange={(event) => handleChange(event.target.name, event.target.value, index)}
                          data={getDays()}
                          fields={{ id: 'name', name: 'name' }}
                          name='days'
                          className={classes.SetValuesForVariablesStep_forecast_point}
                        />

                        <Typography variant='body1'>
                          {I18n.t('v4/alerts.new.steps.select_variable_values.day')}
                        </Typography>
                      </>
                      : <>
                        <Typography variant='body1'>
                          {I18n.t('v4/alerts.new.steps.select_variable_values.info_day')}
                        </Typography>

                        <TextField
                          value={!!variable.days ? variable.days : ''}
                          className={classes.SetValuesForVariablesStep_field}
                          onChange={(event) => handleChange(event.target.name, event.target.value, index)}
                          margin="normal"
                          name='days'
                          inputProps={{
                            className: classes.SetValuesForVariablesStep_textField
                          }}
                        />

                        <Typography variant='body1'>
                          {I18n.t('v4/alerts.new.steps.select_variable_values.day')}
                        </Typography>
                      </>
                  }
                </div>
              }

              <Button
                className={classes.Step_action}
                color='primary'
                onClick={() => openDialog(variable)}
              >
                {I18n.t('actions.remove')}
              </Button>

              {
                index < selectedVariables.length - 1 &&
                <Divider className={classes.SetValuesForVariablesStep_divider} />
              }
            </div>
          )
        }
      </Step>

      <DeleteDialog
        classes={classes.DeleteDialog}
        dialogTitle={`${I18n.t('actions.remove')} ${I18n.t('v4/alerts.alerts.actions_remove', { variable_description: state.selected.variable_description })}`}
        dialogText={I18n.t('confirmation.remove')}
        onCancel={closeDialog}
        onClose={closeDialog}
        open={state.isDialogOpen}
        fullScreen={false}
        handleDelete={() => handleRemoveRule(state.selected)}
      />
    </>
  )
}

const customStyles = theme => ({
  SetValuesForVariablesStep_input: {
    width: '100%'
  },
  SetValuesForVariablesStep_divider: {
    margin: `${theme.spacing.unit * 2}px 0 ${theme.spacing.unit * 5}px 0`,
    '@media(max-width: 599px)': {
      margin: `${theme.spacing.unit}px 0 ${theme.spacing.unit * 5}px 0`
    }
  },
  Step_action: {
    marginTop: theme.spacing.unit * 2,
    '@media(max-width: 599px)': {
      marginTop: 0
    }
  },
  SetValuesForVariablesStep_slider: {
    marginTop: theme.spacing.unit * 6,
    '@media(max-width: 599px)': {
      marginTop: theme.spacing.unit * 2
    }
  },
  SetValuesForVariablesStep_field_wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    '@media(max-width: 599px)': {
      marginBottom: theme.spacing.unit * 2
    }
  },
  SetValuesForVariablesStep_field: {
    maxWidth: theme.spacing.unit * 8,
    margin: `0 ${theme.spacing.unit}px`
  },
  SetValuesForVariablesStep_value: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  SetValuesForVariablesStep_error: {
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.red
  },
  SetValuesForVariablesStep_textField: {
    textAlign: 'center'
  },
  SetValuesForVariablesStep_forecast_point: {
    padding: `0 ${theme.spacing.unit}px`
  },
  SetValuesForVariablesStep_unity: {
    fontSize: '18px'
  }
})

SetValuesForVariablesStep.defaultProps = {
  selectedVariables: [],
  availableRules: []
}

export default withStyles(customStyles)(SetValuesForVariablesStep)
