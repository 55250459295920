export default theme => ({
  Card: {
    border: `3px solid ${theme.palette.irrigationManagement.green}`,
    borderRadius: theme.spacing.unit,
    boxSizing: 'border-box',
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px`,
    minWidth: theme.spacing.unit * 35,
    maxWidth: theme.spacing.unit * 44,
    width: theme.spacing.unit * 44,
    boxShadow: `0px 4px 4px ${theme.palette.irrigationManagement.shadowColor}, 0px 4px 20px ${theme.palette.irrigationManagement.shadowColor}`,
    marginBottom: theme.spacing.unit * 13,
    position: 'relative'
  },
  Card_TitleContainer: {
    width: '100%',
    justifyContent: 'space-between',
    display: 'flex',
    height: theme.spacing.unit * 8,
    alignItems: 'center',
    boxSizing: 'border-box',
  },
  Card_Title: {
    margin: 0,
    textAlign: 'center'
  },
  Card_EditButton: {
    marginLeft: theme.spacing.unit
  },
  Card_ChangeStatusButtonContainer: {
    marginBottom: theme.spacing.unit * 2,
    display: 'flex',
    width: '100%'
  },
  Card_ButtonsContainer: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
  },
  Card_ButtonPrimary: {
    fontFamily: 'Roboto, sans-serif',
    fontSize: '20px',
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    fontWeight: '400',
    cursor: 'pointer',
    textTransform: 'uppercase',
    width: '100%',
    alignItems: 'center',
    display: 'flex',
    justifyContent: 'center',
    color: theme.palette.white,
    background: '#FF3C3C',
    border: 'none',
    borderRadius: theme.spacing.unit,
    boxShadow: `0px 0px 4px ${theme.palette.gray.main}, 0px 0px 4px ${theme.palette.gray.main}`
  },
  Card_ButtonIcon: {
    marginRight: theme.spacing.unit
  },
  Card__hydric_deficit: {
    borderColor: theme.palette.irrigationManagement.red
  },
  Card__ideal_moisture: {
    borderColor: theme.palette.irrigationManagement.green
  },
  Card__alert_moisture: {
    borderColor: theme.palette.irrigationManagement.yellow
  },
  Card__saturated: {
    borderColor: theme.palette.irrigationManagement.blue
  },
  Card__no_data: {
    borderColor: theme.palette.irrigationManagement.gray
  },
  Card_ButtonPrimary__hydric_deficit: {
    background: theme.palette.irrigationManagement.red
  },
  Card_ButtonPrimary__ideal_moisture: {
    background: theme.palette.irrigationManagement.green
  },
  Card_ButtonPrimary__alert_moisture: {
    background: theme.palette.irrigationManagement.yellow
  },
  Card_ButtonPrimary__saturated: {
    background: theme.palette.irrigationManagement.blue
  },
  Card_ButtonPrimary__no_data: {
    background: theme.palette.irrigationManagement.gray
  }
})
