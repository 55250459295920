import React from 'react'
import DefaultColorsForm from './DefaultColorsForm'

import withStyles from '@material-ui/core/styles/withStyles'
import styles from './style'

const PaperBackgroundColorForm = ({ classes, color, updateColor }) =>
  <DefaultColorsForm
    classes={classes}
    title={I18n.t('v4/tenant.styles.paper')}
    subtitle={I18n.t('v4/tenant.styles.paper_explanation')}
    color={color}
    label={I18n.t('v4/tenant.styles.colors.one')}
    updateColor={updateColor}
  >
    <div className={classes.ColorsForm__fields_PaperBackground} />
  </DefaultColorsForm>

export default withStyles(styles)(PaperBackgroundColorForm)
