import React from 'react'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

import { Field } from 'react-final-form'

import TextField from '@ui/Fields/TextField'
import TelephoneField from '@ui/Fields/Telephone'
import Select from '@ui/Fields/Select'
import SelectFlagIcon from '@ui/Fields/FlagIcon/Index'

import { getCountries } from '@models/accounts'

import styles from '@theme/styles/forms'

const Form = ({
  handleSubmit,
  classes
}) => (
    <form className={classes.form} onSubmit={handleSubmit} data-testid='account-form'>
      <Paper className={classes.form__wrapper} elevation={0}>
        <header className={classes.form__header}>
          <Typography component='h2' variant='h5'>
            {I18n.t('v4/manage_users.title')}
          </Typography>
        </header>
        <main className={classes.form__fields}>
          <div className={classes.form__field__wrapper}>
            <Field
              component={TextField}
              className={classes.form__fieldWithError}
              fullWidth
              InputLabelProps={{ shrink: true }}
              label={I18n.t('v4/manage_users.first_name')}
              name='first_name'
              margin='normal'
              variant='outlined'
              required
            />
          </div>
          <div className={classes.form__field__wrapper}>
            <Field
              component={TextField}
              className={classes.form__fieldWithError}
              fullWidth
              InputLabelProps={{ shrink: true }}
              label={I18n.t('v4/manage_users.last_name')}
              name='last_name'
              margin='normal'
              variant='outlined'
              required
            />
          </div>
          <div className={classes.form__field__wrapper}>
            <Field
              component={TelephoneField}
              className={classes.form__fieldWithError}
              fullWidth
              InputLabelProps={{ shrink: true }}
              label={I18n.t('v4/manage_users.phone')}
              name='phone'
              margin='normal'
              variant='outlined'
              required
            />
          </div>
          <div className={classes.form__field__wrapper}>
            <Field
              component={TextField}
              className={classes.form__fieldWithError}
              fullWidth
              InputLabelProps={{ shrink: true }}
              label={I18n.t('v4/manage_users.email')}
              name='email'
              margin='normal'
              variant='outlined'
              required
              type='email'
              disabled
            />
          </div>
          <div className={classes.form__field__wrapper}>
            <Field
              component={TextField}
              className={classes.form__fieldWithError}
              fullWidth
              InputLabelProps={{ shrink: true }}
              label={I18n.t('v4/manage_users.current_password')}
              name='current_password'
              margin='normal'
              variant='outlined'
              type='password'
            />
          </div>
          <div className={classes.form__field__wrapper}>
            <Field
              component={TextField}
              className={classes.form__fieldWithError}
              fullWidth
              InputLabelProps={{ shrink: true }}
              label={I18n.t('v4/manage_users.new_password')}
              name='new_password'
              margin='normal'
              variant='outlined'
              type='password'
            />
          </div>
          <div className={classes.form__field__wrapper}>
            <Field
              component={TextField}
              className={classes.form__fieldWithError}
              fullWidth
              InputLabelProps={{ shrink: true }}
              label={I18n.t('v4/manage_users.retype_password')}
              name='retype_password'
              margin='normal'
              variant='outlined'
              type='password'
            />
          </div>
          <div className={classes.form__field__wrapper}>
            <Field
              component={SelectFlagIcon}
              className={classes.form__fieldWithError}
              InputLabelProps={{ shrink: true, required: true }}
              label={I18n.t('activerecord.attributes.v4/organization.language')}
              name='locale'
              margin='normal'
              fields={{ id: 'id', idIcon: 'idIcon', name: 'name' }}
              data={getCountries()}
              fullWidth
              variant='outlined'
              required
            >
            </Field>
          </div>
        </main>
        <footer className={classes.form__actions}>
          <Button
            className={classes.form__action}
            color='primary'
            type='submit'
            variant='contained'
          >
            {I18n.t('v4/manage_users.title')}
          </Button>
        </footer>
      </Paper>
    </form>
  )

export default withStyles(styles)(Form)
