import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import withStyles from '@material-ui/core/styles/withStyles'

import Table from '@ui/Table'
import DeleteDialog from '../../ui/DeleteDialog'

import styles from '@styles/indexPage'

import { getAccountHeaders } from '@models/account'
import PaymentInfoTabs from '../PaymentInfoTabs'

const Index = ({ classes, model, organization_id, admin, can_edit, o }) => {
  var [dialogOpen, setState] = React.useState(null)
  const tab = 'subscriptions'

  const openDialog = (value) => {
    setState(value)
  }

  const closeDialog = () => {
    setState(null)
  }

  return (
    <PaymentInfoTabs tab={tab}>
      <Table
        tableKey='organizations-subscriptions-index'
        key='account-table'
        components={{
          Container: props => <div>{props.children}</div>
        }}
        columns={getAccountHeaders(tab)}
        data={model}
        options={{ showTitle: false }}
        actions={
          [
            admin && (rowData => ({
              icon: 'delete',
              iconProps: { 'data-testid': `delete-${rowData.id}` },
              tooltip: I18n.t('actions.remove'),
              onClick: (event, rowData) => {
                openDialog(rowData.id)
              }
            })),
            can_edit && (rowData => ({
              icon: 'edit',
              iconProps: { 'data-testid': `edit-${rowData.id}` },
              tooltip: I18n.t('actions.edit'),
              onClick: (event, rowData) => {
                Turbolinks.visit(`/v4/o/${o}/organizations/${organization_id}/subscriptions/${rowData.id}/edit`)
              }
            }))
          ].filter(el => { return el })
        }
      />
      <DeleteDialog
        dialogTitle={`${I18n.t('actions.remove')} ${I18n.t('activerecord.models.v4/organization.one')}?`}
        dialogText={I18n.t('confirmation.remove')}
        successUrl={`/v4/o/${o}/organizations/${organization_id}/subscriptions`}
        deleteUrl={`/v4/organizations/${organization_id}/subscriptions/${dialogOpen}.json`}
        onCancel={closeDialog}
        onClose={closeDialog}
        open={!!dialogOpen}
      />
    </PaymentInfoTabs>
  )
}

export default withStyles(styles)(Index)

