import React from 'react'

import { Paper, Button } from '@material-ui/core'

import Select from '@ui/Fields/Select'
import ManagementDescription from './managementDescription'
import ManagementDisplayOption from './managementDisplayOption'

import { useRouter } from '@contexts/RouterProvider'

import { withStyles } from '@material-ui/core/styles'

const IrrigationManagementNavBar = ({
  classes,
  crop,
  dateVisualization,
  dateVisualizations,
  fetching,
  handleSelectManagement,
  handleSelectDateVisualization,
  managements,
  selectedManagement,
  setVisualization,
  visualization,
  visualizations
}) => {
  const router = useRouter()

  return (
    <Paper className={classes.IrrigationManagementNavBar_wrapper}>
      <section className={classes.IrrigationManagementNavBar}>
        <Button
          className={classes.IrrigationManagementNavBar_action}
          color='primary'
          onClick={() => router.visit('irrigation_managements')}
        >
          {I18n.t('actions.back')}
        </Button>

        <div className={classes.IrrigationManagementNavBar_fields}>
          <Select
            className={classes.IrrigationManagementNavBar_field}
            data={managements.sort((a, b) => a.name.localeCompare(b.name))}
            disabled={fetching}
            fields={{ id: 'id', name: 'name' }}
            InputLabelProps={{ shrink: true }}
            label={I18n.t('v4/irrigation_management.area')}
            margin='normal'
            name='area'
            onChange={({ target }) => handleSelectManagement(target.value)}
            value={selectedManagement}
            variant='outlined'
          />

          <Select
            className={classes.IrrigationManagementNavBar_field}
            data={dateVisualizations}
            disabled={fetching}
            fields={{ id: 'id', name: 'name' }}
            InputLabelProps={{ shrink: true }}
            label={I18n.t('v4/irrigation_management.time_unit')}
            margin='normal'
            name='time_unit'
            onChange={({ target }) => handleSelectDateVisualization(target.value)}
            value={dateVisualization}
            variant='outlined'
          />
        </div>

        <ManagementDescription
          crop={crop}
        />

        <ManagementDisplayOption
          disabled={fetching}
          options={visualizations}
          selected={visualization}
          handleChangeVisualization={setVisualization}
        />
      </section>
    </Paper>
  )
}

const styles = theme => ({
  IrrigationManagementNavBar: {
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap'
  },
  IrrigationManagementNavBar_wrapper: {
    margin: `${theme.spacing.unit * 2}px 0`,
    padding: `${theme.spacing.unit * 1}px ${theme.spacing.unit * 3}px`,
    borderRadius: '10px',
    minWidth: '315px'
  },
  IrrigationManagementNavBar_action: {
    marginRight: `${theme.spacing.unit * 2}px`
  },
  IrrigationManagementNavBar_fields: {
    display: 'flex',
    flexWrap: 'wrap',
    '@media (max-width: 798px)': {
      marginBottom: `${theme.spacing.unit}px`,
    },
  },
  IrrigationManagementNavBar_field: {
    minWidth: '240px',
    minHeight: theme.spacing.unit * 7,
    marginRight: `${theme.spacing.unit * 2}px`
  }
})

IrrigationManagementNavBar.defaultProps = {
  managements: [],
  dateVisualizations: [],
  crop: {},
  fetching: false
}

export default withStyles(styles)(IrrigationManagementNavBar)
