import React, { Component } from 'react'
import PropTypes from 'prop-types'

import MembersIcon from '@material-ui/icons/Group'
import CreditCardIcon from '@material-ui/icons/CreditCard'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import Table from '@ui/Table'

import DeleteDialog from '../ui/DeleteDialog'
import styles from '@styles/indexPage'

const getOrganizationHeaders = (fields) =>
  [
    {
      field: 'name',
      title: I18n.t(`activerecord.attributes.v4/organization.name`),

    },
    {
      field: 'user_role',
      title: I18n.t(`activerecord.attributes.v4/organization.user_role`),
      render: rowData =>
        <span>
          {I18n.t(`v4/users.members.${rowData.user_role}`)}
        </span>
    },
    {
      field: 'kind',
      title: I18n.t(`activerecord.attributes.v4/organization.kind`),
      render: rowData =>
        <span>
          {rowData.kind ? I18n.t(`enumerize.v4/organization.kind.${rowData.kind}`) : null}
        </span>
    }
  ]

class OrganizationList extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isDeleteDialogOpen: false,
      isExitDialogOpen: false,
      id: null
    }
  }

  closeDeleteDialog = (dialog) => {
    this.setState({ isDeleteDialogOpen: false })
  }

  closeExitDialog = (dialog) => {
    this.setState({ isExitDialogOpen: false })
  }

  render() {
    const { model, organizationsHeaders, classes, o } = this.props
    const { isDeleteDialogOpen, isExitDialogOpen, id } = this.state

    return (
      <Paper className={classes.page__wrapper} elevation={0}>
        <header className={classes.page__header}>
          <Typography component='h2' variant='h5'>
            {I18n.t('v4/organization.more')}
          </Typography>
        </header>

        <Table
          components={{
            Container: props => <div>{props.children}</div>,
          }}
          tableKey='accounts-organizationsList'
          columns={getOrganizationHeaders(organizationsHeaders, model)}
          data={model}
          actions={[
            rowData => ({
              icon: CreditCardIcon,
              tooltip: I18n.t('actions.payment'),
              onClick: (event, rowData) => {
                Turbolinks.visit(`/v4/o/${o}/organizations/${rowData.id}/invoices`)
              }
            }),
            rowData => ({
              icon: MembersIcon,
              iconProps: { 'data-testid': `members-of-${rowData.id}` },
              tooltip: I18n.t('actions.members'),
              onClick: (event, rowData) => {
                Turbolinks.visit(`/v4/o/${o}/organizations/${rowData.id}/users`)
              }
            }),
            rowData => ({
              icon: 'delete',
              iconProps: { 'data-testid': `delete-${rowData.id}` },
              tooltip: I18n.t('actions.remove'),
              disabled: (rowData.user_role !== 'owner'),
              onClick: (event, rowData) => {
                this.setState({ isDeleteDialogOpen: true, id: rowData.id })
              }
            }),
            rowData => ({
              icon: 'create',
              iconProps: { 'data-testid': `edit-${rowData.id}` },
              tooltip: I18n.t('actions.edit'),
              disabled: (rowData.user_role !== 'owner'),
              onClick: (event, rowData) => {
                Turbolinks.visit(`/v4/o/${o}/organizations/${rowData.id}/edit`)
              }
            }),
            rowData => ({
              icon: 'exit_to_app',
              iconProps: { 'data-testid': `show-${rowData.id}` },
              tooltip: I18n.t('actions.exit'),
              disabled: (rowData.user_role === 'owner'),
              onClick: (event, rowData) => {
                this.setState({ isExitDialogOpen: true, id: rowData.id })
              }
            })
          ]}
          options={{
            showTitle: false
          }}
        />

        <DeleteDialog
          dialogTitle={`${I18n.t('actions.leave')} ${I18n.t('v4/organization.one')}?`}
          actionText={I18n.t('actions.leave')}
          successUrl={`/v4/o/${o}/account/edit/#organizations`}
          deleteUrl={`/v4/o/${o}/account/organizations/${id}.json`}
          onCancel={this.closeExitDialog}
          onClose={this.closeExitDialog}
          open={isExitDialogOpen}
        />

        <DeleteDialog
          dialogTitle={`${I18n.t('actions.remove')} ${I18n.t('v4/organization.one')}?`}
          dialogText={I18n.t('confirmation.remove')}
          successUrl={`/v4/o/${o}/account/edit/#organizations`}
          deleteUrl={`/v4/o/${o}/organizations/${id}.json`}
          onCancel={this.closeDeleteDialog}
          onClose={this.closeDeleteDialog}
          open={isDeleteDialogOpen}
        />
      </Paper>
    )
  }
}

OrganizationList.propTypes = {
  model: PropTypes.array
}

export default withStyles(styles)(OrganizationList)
