import React from 'react'
import { deserializeHourlyData, getTableHeaders } from '@models/weather/dashboard'
import Table from '@ui/Table'

const DashboardTable = ({ data, descriptors }) =>
  <Table
    tableKey='weatherForecasts-dashboardTable'
    components={{
      Toolbar: () => null
    }}
    columns={getTableHeaders(data, descriptors)}
    data={deserializeHourlyData(data)}
    options={{
      search: false,
      showTitle: false,
      paging: false,
      headerStyle: {
        position: 'sticky',
        top: 0
      }
    }}
  />

export default DashboardTable
