import React from 'react'

import { Typography, withStyles } from '@material-ui/core'

import { getVariableIcon } from '@models/equipment/equipment'
import { getPrecipitation } from '@models/equipment/manual'

const InfoRain = ({ classes, today, precipitationValue }) =>
  <div className={classes.InfoRain_wrapper}>
    <div className={classes.InfoRain_infos}>
      {getVariableIcon(classes)['pl1']}

      <Typography variant='body1'>
        {I18n.t('v4/equipment.pluviometer.precipitation_today')}
      </Typography>
    </div>

    <Typography variant='body2'>
      {getPrecipitation(today, precipitationValue)}
    </Typography>
  </div>

const Styles = theme => ({
  InfoRain_wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    margin: `${theme.spacing.unit}px 0 ${theme.spacing.unit * 2}px`
  },
  InfoRain_infos: {
    display: 'flex',
    alignItems: 'center'
  },
  InfoRain_info_time: {
    color: theme.palette.gray.dark
  },
  Equipment_icons: {
    height: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit
  },
})

export default withStyles(Styles)(InfoRain)
