
import React from 'react'

const Tooltip = ({ active, payload, label, classes, descriptors, chartConfigs, getDescriptor }) => {
  const getStatusRainDescription = (value) => chartConfigs.colorRules(value).description

  return active
    ? (
      <div className={classes.tooltip__wrapper}>
        <p>{label}</p>
        {
          payload.map(({ dataKey, value, payload }) =>
            <div key={dataKey}>
              <p>
                {
                  `${getDescriptor(descriptors, dataKey).description}: ${value}${getDescriptor(descriptors, dataKey).unit
                    ? ` ${getDescriptor(descriptors, dataKey).unit}`
                    : ''}`
                }
              </p>
              <p>
                {I18n.t('v4/weather.charts.accumulated_rain.chance_of_rain')}: {getStatusRainDescription(payload.pop)}
              </p>
            </div>
          )
        }
      </div>
    )
    : null
}

export default Tooltip
