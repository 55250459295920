import React from 'react'

import Button from '@material-ui/core/Button'
import ListItem from '@material-ui/core/ListItem'
import ListItemText from '@material-ui/core/ListItemText'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

import { organizationShape } from '@models/organization'
import styles from '../styles'

const SelectedOrganization = ({ classes, selected, currentUser, router }) => (
  <ListItem>
    <div className={classes.organizations__icon__selected__wrapper}>
      <Typography className={classes.organizations__icon__selected}>
        {selected.name[0]}
      </Typography>
    </div>
    <div>

      <ListItemText primary={selected.name} data-testid='selectedOrganizationName' />

      <Typography variant='caption' data-testid='selectedOrganizationLabel'>
        {selected.label}
      </Typography>

      {
        currentUser.hasWritePermissions &&
        <Button
          variant='contained'
          onClick={() => router.visit(`organizations/${selected.id}/edit`)}
          color='primary'
          data-testid='btnEditOrganization'
          className={classes.organizations__edit}
        >
          {I18n.t('v4/organization.actions.edit')}
        </Button>
      }
    </div>
  </ListItem>
)

SelectedOrganization.propTypes = {
  organization: organizationShape
}

export default withStyles(styles)(SelectedOrganization)
