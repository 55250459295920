import React from 'react'
import PropTypes from 'prop-types'
import Table from '@ui/Table'
import { Form as FinalForm } from 'react-final-form'

import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import Typography from '@material-ui/core/Typography'
import AddIcon from '@material-ui/icons/NoteAdd'

import { getSampleHeaders, validate } from '@models/analysis/sample'
import httpService from '@services/httpService'
import { RailsForm } from '@support/RailsForm'
import DeleteDialog from '../../../ui/DeleteDialog'
import Dialog from '../../../ui/Dialog'
import Form from './Form'
import ShowSample from './Show'
import styles from './styles'

import { withRouter } from '@contexts/RouterProvider'

class SamplesList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      dialog: {
        open: false,
        handler: () => { },
        title: '',
        values: {},
      },
      isDeleteDialogOpen: false,
      selectedSampleId: null,
      samples: []
    }

    this.handleDialogToggle = this.handleDialogToggle.bind(this)
    this.handleDeleteDialogToggle = this.handleDeleteDialogToggle.bind(this)

    this.handleNewSample = this.handleNewSample.bind(this)
    this.handleAddSample = this.handleAddSample.bind(this)

    this.handleSampleEdit = this.handleSampleEdit.bind(this)
    this.handleSampleUpdate = this.handleSampleUpdate.bind(this)

    this.handleSampleDelete = this.handleSampleDelete.bind(this)
  }

  componentDidMount() {
    this.setState({ samples: this.props.samples })
  }

  handleDialogToggle() {
    this.setState({
      dialog: {
        ...this.state.dialog,
        open: !this.state.dialog.open
      }
    })
  }

  handleAddSample = async (values) => {
    const { analysisId, handleError, router } = this.props

    try {
      const { data } = await httpService.post(`${router.baseURL}soil_analyses/${analysisId}/soil_samples`, values)

      this.setState({ samples: [...this.state.samples, data] },
        this.handleDialogToggle
      )
    }
    catch (error) {
      handleError(error)
    }
  }

  handleSampleUpdate = async (values) => {
    const { handleError, router } = this.props

    try {
      const { data } = await httpService.put(`${router.baseURL}soil_samples/${values.id}`, values)

      this.setState({
        samples: this.state.samples.map(sample => {
          if (sample.id === data.id) {
            return values
          }

          return sample
        })
      }, this.handleDialogToggle)
    }
    catch (error) {
      handleError(error)
    }
  }

  handleNewSample() {
    this.setState({
      dialog: {
        open: true,
        handler: this.handleAddSample,
        title: I18n.t('v4/analysis.samples.actions.add'),
        values: {},
      }
    })
  }

  handleSampleEdit(sample) {
    this.setState({
      dialog: {
        open: true,
        handler: this.handleSampleUpdate,
        title: I18n.t('v4/analysis.samples.actions.edit'),
        values: sample,
      }
    })
  }

  handleDeleteDialogToggle(id) {
    this.setState({
      selectedSampleId: id,
      isDeleteDialogOpen: !this.state.isDeleteDialogOpen
    })
  }

  handleSampleDelete = async () => {
    const { handleError, o } = this.props
    const id = this.state.selectedSampleId

    try {
      await httpService.delete(`/v4/o/${o}/soil_samples/${id}`)

      this.setState({
        samples: this.state.samples.filter(sample =>
          sample.id !== id
        )
      }, this.handleDeleteDialogToggle)
    }
    catch (error) {
      handleError(error)
    }
  }

  render() {
    const { classes, handleError, o } = this.props
    const { samples } = this.state

    return (
      <React.Fragment>
        <Table
          tableKey='areas-analyses-samples-index'
          columns={getSampleHeaders()}
          title={I18n.t('activerecord.attributes.v4/analysis.samples')}
          detailPanel={sample =>
            <ShowSample
              sample={sample}
            />
          }
          components={{
            Container: props => <div>{props.children}</div>,
            Toolbar: props =>
              <div className={classes.samples__header}>
                <Typography>
                  {props.title}
                </Typography>
                <Button
                  color='secondary'
                  variant='outlined'
                  className={classes.sample__newSample}
                  onClick={this.handleNewSample}
                  title={I18n.t('v4/analysis.samples.actions.add')}
                >
                  <AddIcon />
                </Button>
              </div>
          }}
          data={samples}
          actions={[
            rowData => ({
              icon: 'edit',
              tooltip: I18n.t('actions.edit'),
              onClick: () => this.handleSampleEdit(rowData)
            }),
            rowData => ({
              icon: 'delete',
              tooltip: I18n.t('actions.remove'),
              onClick: () => this.handleDeleteDialogToggle(rowData.id)
            })
          ]}
          options={{
            search: false,
            paging: false
          }}
        />

        <Dialog
          open={this.state.dialog.open}
          onClose={this.handleDialogToggle}
          title={this.state.dialog.title}
        >
          <RailsForm
            component={FinalForm}
            handleSubmit={this.state.dialog.handler}
            initialValues={this.state.dialog.values}
            validate={validate}
            render={(props) => (
              <Form
                {...props}
                action={this.state.dialog.title}
                onCancel={this.handleDialogToggle}
                calculate={this.calculate}
              />
            )}
          />
        </Dialog>

        <DeleteDialog
          dialogTitle={`${I18n.t('actions.remove')} ${I18n.t('activerecord.attributes.v4/analysis.samples')}?`}
          dialogText={I18n.t('confirmation.remove')}
          onCancel={this.handleDeleteDialogToggle}
          onClose={this.handleDeleteDialogToggle}
          handleDelete={this.handleSampleDelete}
          open={this.state.isDeleteDialogOpen}
        />
      </React.Fragment>
    )
  }
}

SamplesList.defaultProps = {
  samples: []
}

SamplesList.propTypes = {
  samples: PropTypes.array,
  analysisId: PropTypes.number
}

export default withRouter(withStyles(styles)(SamplesList))
