import React from "react";
import moment from "moment";
import { Field } from "react-final-form";
import { Form as FinalForm } from "react-final-form";

import { Button, DialogContentText } from "@material-ui/core";
import withStyles from "@material-ui/core/styles/withStyles";

import { validate } from "@models/dashboardArea";
import { RailsForm } from "@support/RailsForm";
import DatePicker from "@ui/Fields/DatePicker";
import styles from "@theme/styles/forms";
import TextField from "@ui/Fields/TextField";
import Grid from "@material-ui/core/Grid";
import Select from "@ui/Fields/Select";
import { sendAmplitudeEvent } from '@utils/amplitudeEvent'
import { IntercomAPI } from '@utils/IntercomAPI'

import { units } from "@models/area";

const Form = ({
  handler,
  defaultDate,
  action,
  onCancel,
  classes,
  content,
  minDate,
  selectedAction,
  intercomTarget,
  hideDate,
  hideButtons,
  yieldValue,
  yieldUnity
}) => (
  <RailsForm
    component={FinalForm}
    handleSubmit={handler}
    initialValues={{ date: defaultDate ? moment(defaultDate) : moment(), yield: yieldValue, unity: yieldUnity }}
    validate={validate}
    render={(props) => (
      <ChangeStageForm
        {...props}
        minDate={minDate && moment(minDate)}
        initialDate={defaultDate}
        action={action}
        classes={classes}
        onCancel={onCancel}
        content={content}
        selectedAction={selectedAction}
        intercomTarget={intercomTarget}
        hideDate={hideDate}
        hideButtons={hideButtons}
      />
    )}
  />
);

const ChangeStageForm = ({
  handleSubmit,
  classes,
  action,
  onCancel,
  content,
  minDate,
  selectedAction,
  intercomTarget,
  hideDate,
  hideButtons
}) => {
  const customProp = minDate && { minDate };
  return (
    <form
      className={classes.form}
      onSubmit={handleSubmit}
      data-testid="change-stage-form"
    >
      <main className={classes.form__fields}>
        <Grid container spacing={16}>
          {!hideDate && (
            <Grid item xs={12}>
              <Field
                {...customProp}
                className={classes.form__fieldWithError}
                component={DatePicker}
                margin="normal"
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                label={I18n.t(
                  "activerecord.attributes.v4/culture.phenological_stages.date"
                )}
                onFocus={() => {
                  sendAmplitudeEvent(`${intercomTarget} - Date Field`);
                  IntercomAPI('trackEvent', `${intercomTarget} - Date Field`);
                }}
                name="date"
                disableFuture
                fullWidth
                required
              />
            </Grid>
          )}

          {selectedAction && (
            <>
              <Grid item xs={6}>
                <Field
                  className={classes.form__fieldWithError}
                  component={TextField}
                  fullWidth
                  label={I18n.t("v4/area.dashboard.card.yield.title")}
                  variant="outlined"
                  required
                  name="yield"
                  placeholder={I18n.t(
                    "v4/area.dashboard.card.yield.placeholder"
                  )}
                  InputLabelProps={{ shrink: true }}
                  type="number"
                  data-intercom-target="informed_crop_productivity"
                  InputProps={{
                    inputProps: {
                      className: classes.form__input_number_no_arrows,
                    },
                  }}
                  onFocus={() => {
                    sendAmplitudeEvent(`${intercomTarget} - Clicked Productivity Field`);
                    IntercomAPI('trackEvent', `${intercomTarget} - Clicked Productivity Field`);
                  }}
                />
              </Grid>

              <Grid item xs={6}>
                <Field
                  className={classes.form__fieldWithError}
                  component={Select}
                  fullWidth
                  label={I18n.t("v4/area.dashboard.card.unity.title")}
                  placeholder={I18n.t(
                    "v4/area.dashboard.card.unity.placeholder"
                  )}
                  required
                  fields={{ id: "value", name: "value" }}
                  data={units}
                  variant="outlined"
                  name="unity"
                  InputLabelProps={{ shrink: true }}
                  onFocus={() => {
                    sendAmplitudeEvent(`${intercomTarget} - Clicked Unit Field`);
                    IntercomAPI('trackEvent', `${intercomTarget} - Clicked Unit Field`);
                  }}
                />
              </Grid>
            </>
          )}
        </Grid>
      </main>

      <DialogContentText className={classes.Dialog_container}>
        {content}
      </DialogContentText>

      {!hideButtons && 
      <footer className={classes.form__actions}>
        <Button
          className={classes.form__action}
          color="primary"
          type="submit"
          variant="contained"
          data-intercom-target={intercomTarget}
          onFocus={() => {
            if(intercomTarget === 'Harvest Card'){
              sendAmplitudeEvent(`${intercomTarget} - Clicked Harvest Button`);
              IntercomAPI('trackEvent', `${intercomTarget} - Clicked Harvest Button`);
            }else{
              sendAmplitudeEvent(`${intercomTarget} - Clicked Advance Button`);
              IntercomAPI('trackEvent', `${intercomTarget} - Clicked Advance Button`);
            }
          }}
        >
          {action}
        </Button>

        <Button
          className={classes.form__action}
          color="secondary"
          onClick={()=> {
              sendAmplitudeEvent(`${intercomTarget} - Clicked Cancel Button`);
              IntercomAPI('trackEvent', `${intercomTarget} - Clicked Cancel Button`);
              onCancel();
          }}
        >
          {I18n.t("actions.cancel")}
        </Button>
      </footer>
      }
    </form>
  );
};

const customStyles = (theme) => ({
  ...styles(theme),
  Dialog_container: {
    marginLeft: theme.spacing.unit,
  },
});

export default withStyles(customStyles)(Form);
