import React from 'react'
import { Paper, Typography, Button } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import ReactHtmlParser from 'react-html-parser'
import { getCalculationResultInfo } from '@models/equipment/irrigation'
import Table from '@ui/Table'

const CalculationResultInfo = ({ classes, handleSubmit, calculationResult, disabled }) => {
  const { table, texts, mainText, image } = getCalculationResultInfo(calculationResult)

  return <div className={classes.CalculationResultInfo}>
    <Table
      key='irrigation-calculation-result-table'
      tableKey='equipments-irrigatonSystem-calculationResultInfo'
      columns={table.headers}
      components={{
        Toolbar: () => null
      }}
      data={table.data}
      options={{
        showTitle: false,
        paging: false,
        search: false,
        sorting: false
      }}
    />

    <Paper elevation={1}>
      <Typography component='p' className={classes.CalculationResultInfo__result_text}>
        {ReactHtmlParser(mainText)}
      </Typography>

      <div className={classes.CalculationResultInfo__image_wrapper}>
        {image && image()}
      </div>

      <div className={classes.CalculationResultInfo__result_text}>
        {
          texts.map((text, key) =>
            <Typography key={key} component='p'>
              {ReactHtmlParser(text)}
            </Typography>
          )
        }
      </div>
    </Paper>

    <footer className={classes.CalculationResultInfo__actions}>
      <Button
        color='primary'
        variant='contained'
        disabled={disabled}
        onClick={handleSubmit}
      >
        {I18n.t('actions.save')}
      </Button>
    </footer>
  </div>
}

const styles = theme => ({
  CalculationResultInfo: {
    margin: `${theme.spacing.unit * 4}px 0`
  },
  CalculationResultInfo__result_text: {
    padding: `${theme.spacing.unit * 2}px`,
    margin: `${theme.spacing.unit}px`
  },
  CalculationResultInfo__actions: {
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center',
    marginTop: theme.spacing.unit * 3
  },
  CalculationResultInfo__image_wrapper: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    flexWrap: 'wrap',
    alignItems: 'center',
    flexDirection: 'column'
  }
})

CalculationResultInfo.defaultProps = {
  disabled: false
}

export default withStyles(styles)(CalculationResultInfo)
