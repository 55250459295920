import React from 'react'

import {
  Button,
  Grid,
  withStyles
} from '@material-ui/core'

import { Field } from 'react-final-form'

import TextField from '@ui/Fields/TextField'
import DatePicker from '@ui/Fields/DatePicker'

import styles from '@theme/styles/forms'

const Form = ({
  classes,
  handleSubmit,
  handleCloseDialog,
  hideEditDate,
  loading
}) => {
  return (
    <form
      onSubmit={handleSubmit}
      className={classes.Form_wrapper}
    >
      <Grid container spacing={8} alignItems="center">
        <Grid item xs={12}>
          <Field
            className={classes.form__fieldWithError}
            component={TextField}
            fullWidth
            InputLabelProps={{ shrink: true }}
            label={I18n.t('v4/equipment.pluviometer.precipitation')}
            margin='normal'
            name='pl1'
            required
            type='number'
            variant='outlined'
            InputProps={{
              endAdornment: 'mm',
              inputProps: {
                className: classes.Form_no_arrows
              }
            }}
          />
        </Grid>

        {
          !hideEditDate &&
          <Grid item xs={12}>
            <Field
              className={classes.form__fieldWithError}
              component={DatePicker}
              margin='normal'
              InputLabelProps={{ shrink: true }}
              variant='outlined'
              label={I18n.t('v4/equipment.pluviometer.date')}
              name='measured_at'
              fullWidth
              required
              initialValue={new Date()}
            />
          </Grid>
        }
      </Grid>

      <footer className={classes.Form_actions}>
        <Button
          color='secondary'
          onClick={handleCloseDialog}
          disabled={loading}
        >
          {I18n.t('actions.cancel')}
        </Button>

        <Button
          color='primary'
          type='submit'
          variant='contained'
          disabled={loading}
        >
          {I18n.t('actions.save')}
        </Button>
      </footer>
    </form>
  )
}

const customStyles = theme => ({
  ...styles(theme),
  Form_wrapper: {
    maxWidth: '400px'
  },
  Form_no_arrows: {
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none'
    },
    '-moz-appearance': 'textfield'
  },
  Form_actions: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing.unit * 2,
    width: '100%'
  }
})

export default withStyles(customStyles)(Form)
