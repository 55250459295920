import React from 'react'
import httpService from '@services/httpService'

import withStyles from '@material-ui/core/styles/withStyles'
import styles from './styles'

const UserUnconfirmedWarning = ({classes, gracePeriodLimit, userConfirmed, resendEmailURL, userEmail}) => {
  const [display, setState] = React.useState(true)

  const handleCloseClick = () => {
    setState(false)
  }

  const handleResendEmail = async () => {
    await httpService({
      method: 'POST',
      url: resendEmailURL,
      data: { user: { email: userEmail } }
    })

    handleCloseClick()
  }

  if (!display || userConfirmed)
    return null;

  return(
    <div className={classes.Warning_Bar} >
      <span className={classes.Warning_Bar__Text} >
        {I18n.t('devise.confirmations.account_unconfirmed', {date: gracePeriodLimit})}.{` `}
        <p className={classes.Warning_Bar__Link} onClick={handleResendEmail} >{I18n.t('devise.confirmations.resend_email')}</p>
      </span>
      <button onClick={handleCloseClick} className={classes.Warning_Bar__Close_Button} >x</button>
    </div>
  )
}

export default withStyles(styles)(UserUnconfirmedWarning)
