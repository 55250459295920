import React from "react";
import PropTypes from "prop-types";

import { RailsForm } from "@support/RailsForm";
import { Form as FinalForm } from "react-final-form";
import { validate } from "@models/equipment/automatic";
import EquipmentForm from "../Form";
import Form from "./Form";

const Edit = (props) => {
  const {
    manufacturers,
    part_number_registries,
    sensor_configs,
    icons,
    model,
    o,
    handleError,
  } = props;

  const sensorConfigs = sensor_configs.map((element) => {
    const sensors = element.sensors.map((sensor) => {
      if (model.sensors !== undefined && model.sensors.includes(sensor.id)) {
        sensor.active = true;
        const metadataParams = sensor.metadata.params;
        const sensorMetadata = metadataParams.map((params) => {
          model.params.forEach((e) => {
            if (params.param === e.param) {
              params.value = e.value;
            }
          });
          return params;
        });
        sensor.metadata.params = sensorMetadata;
      } else {
        sensor.active = false;
      }
      return sensor;
    });
    return { ...element, sensors };
  });

  return (
    <RailsForm
      component={FinalForm}
      action="update"
      validate={validate}
      initialValues={{ equipment: model }}
      render={(props) => (
        <EquipmentForm
          title={`${I18n.t("actions.update")} ${I18n.t(
            `v4/equipment.kinds.${model.kind}`
          )}`}
        >
          <Form
            manufacturers={manufacturers}
            sensor_configs={sensorConfigs}
            icons={icons}
            url={`/v4/o/${o}/equipments/${model.kind}/${model.id}`}
            successUrl={`/v4/o/${o}/equipments/${model.kind}/${model.id}`}
            part_number_registries={part_number_registries}
            method="put"
            {...props}
            action={I18n.t("actions.update")}
            o={o}
            actionDisabled={true}
            handleError={handleError}
          />
        </EquipmentForm>
      )}
    />
  );
};

Edit.propTypes = {
  id: PropTypes.number,
  kind: PropTypes.string,
  manufacturer: PropTypes.string,
  name: PropTypes.string,
  identifier: PropTypes.string,
  latitude: PropTypes.number,
  latitude: PropTypes.number,
  detail: PropTypes.string,
  pin: PropTypes.string,
};

export default Edit;
