import React from 'react'

import AdminIcon from '@material-ui/icons/Settings'
import IconButton from '@material-ui/core/IconButton'

import withStyles from '@material-ui/core/styles/withStyles'
import styles from './styles'

const Index = ({ classes, router }) =>
  <div data-intercom-target='admin'>
    <IconButton
      title={I18n.t('v4/admin.show.title')}
      href={router.getLink('admin')}
    >
      <AdminIcon className={classes.admin__icon} />
    </IconButton>
  </div>

export default withStyles(styles)(Index)
