import React from 'react'
import PropTypes from 'prop-types'

import { Form as FinalForm } from 'react-final-form'
import { RailsForm } from '@support/RailsForm'
import { partNumberShape } from '@models/partNumber'

import Form, { validate } from './Form'

const Edit = ({ model, connectivities, already_in_use, manufacturers, o }) => (
  <RailsForm
    component={FinalForm}
    action='update'
    url={`/v4/o/${o}/admin/part_number_registries/${model.id}`}
    successUrl={`/v4/o/${o}/admin/part_number_registries/${model.id}`}
    validate={validate}
    initialValues={model}
    render={(props) => (
      <Form
        title={I18n.t('v4/part_number.edit.title')}
        connectivities={connectivities}
        already_in_use={already_in_use}
        manufacturers={manufacturers}
        o={o}
        {...props}
      />
    )}
  />
)

Edit.propTypes = {
  model: partNumberShape.isRequired,
  connectivities: PropTypes.arrayOf(
    PropTypes.shape({
      product: PropTypes.string,
      id: PropTypes.number
    })
  ).isRequired,
  already_in_use: PropTypes.bool
}

export default Edit
