import React from 'react'
import PropTypes from 'prop-types'

import Automatic from './Automatic/New'
import Implement from './Implements/New'
import Manual from './Manual/New'
import Tractor from './Tractor/New'
import IrrigationSystem from './IrrigationSystem/New'

export const New = props => {
  const { model, handleError } = props

  const forms = {
    automatic: Automatic,
    implement: Implement,
    manual: Manual,
    tractor: Tractor,
    irrigation: IrrigationSystem
  }

  const NewForm = forms[model.kind]
  return (
    <NewForm handleError={handleError} {...props} />
  )
}

New.propTypes = {
  model: PropTypes.object
}

export default New
