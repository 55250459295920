import React from 'react'
import Elo from '@ui/Icons/Elo'
import MasterCard from '@ui/Icons/MasterCard'
import Visa from '@ui/Icons/Visa'
import moment from 'moment'
import { apiServiceVindi, apiService } from '@services/httpService'


export const paymentProfilesHeaders = [
  'name',
  'card_expiration',
  'flag',
  'card_number'
]

const avaliableCreditCardTypes = {
  Visa: /^4[0-9]{12}(?:[0-9]{3})/,
  Mastercard: /^5[1-5]\d{14}$|^2(?:2(?:2[1-9]|[3-9]\d)|[3-6]\d\d|7(?:[01]\d|20))\d{12}$/,
  Elo: /^(4011(78|79)|43(1274|8935)|45(1416|7393|763(1|2))|50(4175|6699|67[0-7][0-9]|9000)|50(9[0-9][0-9][0-9])|627780|63(6297|6368)|650(03([^4])|04([0-9])|05(0|1)|05([7-9])|06([0-9])|07([0-9])|08([0-9])|4([0-3][0-9]|8[5-9]|9[0-9])|5([0-9][0-9]|3[0-8])|9([0-6][0-9]|7[0-8])|7([0-2][0-9])|541|700|720|727|901)|65165([2-9])|6516([6-7][0-9])|65500([0-9])|6550([0-5][0-9])|655021|65505([6-7])|6516([8-9][0-9])|65170([0-4]))/
}

const maximum_card_number_size = 16

const creditCardHaveAllNumbers = number => number.length === maximum_card_number_size

const validateCardNumber = number_card => {

  if (!creditCardHaveAllNumbers(number_card)) return false

  if (creditCardHaveAllNumbers(number_card)) {
    Object.values(avaliableCreditCardTypes).some(card => card.test(number_card))
    return true
  }

  return false
}

export const validate = values => {
  const requiredFields = ['holder_name', 'card_number', 'card_cvv']
  const errors = {}

  requiredFields.forEach(field => {
    if (!values[field]) errors[field] = I18n.t('validations.required')
  })

  if (values.card_cvv !== undefined) {
    if (values.card_cvv.length < 3 || values.card_cvv.length > 4) {
      errors.card_cvv = I18n.t('validations.invalid_cvv')
    }
  }

  if (values.card_expiration !== undefined) {
    if (!/_/.test(values.card_expiration)) {
      const expiration = moment(values.card_expiration, 'MM/YY')
      const todayDate = new Date()
      const monthYear = `${todayDate.getUTCMonth() + 1}/${todayDate.getUTCFullYear().toString().substring(2)}`
      const today = moment(monthYear, 'MM/YY')

      if (expiration < today) {
        errors.card_expiration = I18n.t('validations.expired_card')
      }

      if (!expiration.isValid()) {
        errors.card_expiration = I18n.t('validations.card_expiration')
      }
    }
  }

  if (values.card_number !== undefined) {
    if (!validateCardNumber(values.card_number)) {
      errors.card_number = I18n.t('validations.invalid_card')
    }
  }
  return errors
}

export const getTypeCard = (values = {}) => {
  if (values.card_number !== undefined && creditCardHaveAllNumbers(values.card_number)) {
    if (avaliableCreditCardTypes.Elo.test(values.card_number)) {
      return 'elo'
    }
    if (avaliableCreditCardTypes.Mastercard.test(values.card_number)) {
      return 'mastercard'
    }
    if (avaliableCreditCardTypes.Visa.test(values.card_number)) {
      return 'visa'
    }
  }
}

export const getCardFlag = (values = {}) => {
  if (values.card_number !== undefined) {
    return cards[getTypeCard(values)]
  }
}

const characters = [',', '-', '.']
export const blockCharacters = name => {
  document.getElementsByName(name)[0].addEventListener('keypress', (event) => {
    if (characters.includes(event.key)) {
      event.preventDefault()
      return ''
    }
  })
}

const cards = {
  visa: <Visa />,
  elo: <Elo />,
  mastercard: <MasterCard />
}

export const submit = async ({ values, event, handleError, gateway_base_url, gateway_public_key, organization_id, onHandleSubmit, setLoading, o }) => {
  event.preventDefault()

  const creditCardInformation = {
    ...values,
    payment_company_code: getTypeCard(values),
    payment_method_code: 'credit_card'
  }

  try {
    setLoading && setLoading(true)
    const { data } = await apiServiceVindi(gateway_public_key).post(`${gateway_base_url}public/payment_profiles`, creditCardInformation)
    const creditCardResponse = await apiService().post(`/v4/o/${o}/organizations/${organization_id}/payment_profiles`, data)

    if (onHandleSubmit) {
      setLoading && setLoading(false)
      onHandleSubmit(creditCardResponse.data)
    }
    else {
      Turbolinks.visit(`/v4/o/${o}/organizations/${organization_id}/payment_profiles`)
    }
  }
  catch {
    setLoading && setLoading(false)

    handleError(I18n.t('v4/payment_checkout.billing_data.invalid_credit_card'))
  }
}
