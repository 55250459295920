import React from 'react'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import { Field } from 'react-final-form'
import TextField from '../../ui/Fields/TextField'
import Select from '../../ui/Fields/Select'
import Button from '@material-ui/core/Button'
import styles from '@theme/styles/forms'

const FertilizersForm = ({
  unities,
  handleSubmit,
  classes,
  action,
  o,
  ureaValues
}) =>
  <form className={classes.form} onSubmit={handleSubmit} >
    <main className={classes.form__fields}>
      <Grid container spacing={24}>
        <Grid item xs={12}>
          <div className={classes.form__field__wrapper}>
            <Field
              className={classes.form__fieldWithError}
              component={TextField}
              fullWidth
              label={I18n.t('activerecord.attributes.v4/input.name')}
              variant="outlined"
              required
              name="name"
              InputLabelProps={{ shrink: true }}
            />
          </div>
        </Grid>

        <Grid item xs={12}>
          <div className={classes.form__field__wrapper}>
            <Field
              className={classes.form__fieldWithError}
              component={TextField}
              fullWidth
              label={I18n.t('activerecord.attributes.v4/input.brand')}
              required
              variant="outlined"
              name="brand"
              InputLabelProps={{ shrink: true }}
            />
          </div>
        </Grid>

        <Grid item sm={6} xs={12}>
          <div className={classes.form__field__wrapper}>
            <Field
              className={classes.form__fieldWithError}
              component={Select}
              data={unities}
              fields={{ name: 'name' }}
              label={I18n.t('activerecord.attributes.v4/input.unit')}
              fullWidth
              name="unit"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </div>
        </Grid>
        <Grid item sm={6} xs={12}>
          <div className={classes.form__field__wrapper}>
            <Field
              className={classes.form__fieldWithError}
              component={TextField}
              fullWidth
              label={`${I18n.t('activerecord.attributes.v4/input.density')} (Kg/l)`}
              name="density"
              variant="outlined"
              type="number"
              inputProps={{ min: 0, step: 'any' }}
              InputLabelProps={{ shrink: true }}
            />
          </div>
        </Grid>

        <Grid item md={3} sm={6} xs={12}>
          <div className={classes.form__field__wrapper}>
            <Field
              className={classes.form__fieldWithError}
              component={TextField}
              fullWidth
              label={I18n.t('activerecord.attributes.v4/input.nitrogen')}
              name="nitrogen"
              type="number"
              variant="outlined"
              inputProps={{ min: 0, max: 100, step: 'any' }}
              InputLabelProps={{ shrink: true }}
            />
          </div>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <div className={classes.form__field__wrapper}>
            <Field
              className={classes.form__fieldWithError}
              component={TextField}
              fullWidth
              label={I18n.t('activerecord.attributes.v4/input.phosphate')}
              name="phosphate"
              type="number"
              variant="outlined"
              inputProps={{ min: 0, max: 100, step: 'any' }}
              InputLabelProps={{ shrink: true }}
            />
          </div>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <div className={classes.form__field__wrapper}>
            <Field
              className={classes.form__fieldWithError}
              component={TextField}
              fullWidth
              label={I18n.t('activerecord.attributes.v4/input.potassium')}
              name="potassium"
              type="number"
              variant="outlined"
              inputProps={{ min: 0, max: 100, step: 'any' }}
              InputLabelProps={{ shrink: true }}
            />
          </div>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <div className={classes.form__field__wrapper}>
            <Field
              className={classes.form__fieldWithError}
              component={TextField}
              fullWidth
              label={I18n.t('activerecord.attributes.v4/input.zinc')}
              name="zinc"
              type="number"
              variant="outlined"
              inputProps={{ min: 0, max: 100, step: 'any' }}
              InputLabelProps={{ shrink: true }}
            />
          </div>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <div className={classes.form__field__wrapper}>
            <Field
              className={classes.form__fieldWithError}
              component={TextField}
              fullWidth
              label={I18n.t('activerecord.attributes.v4/input.sulfate')}
              name="sulfate"
              type="number"
              variant="outlined"
              inputProps={{ min: 0, max: 100, step: 'any' }}
              InputLabelProps={{ shrink: true }}
            />
          </div>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <div className={classes.form__field__wrapper}>
            <Field
              className={classes.form__fieldWithError}
              component={TextField}
              fullWidth
              label={I18n.t('activerecord.attributes.v4/input.toc')}
              name="toc"
              type="number"
              variant="outlined"
              inputProps={{ min: 0, max: 100, step: 'any' }}
              InputLabelProps={{ shrink: true }}
            />
          </div>
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <div className={classes.form__field__wrapper}>
            <Field
              className={classes.form__fieldWithError}
              component={Select}
              data={ureaValues}
              fields={{id: 'value', name: 'name' }}
              label={I18n.t('activerecord.attributes.v4/input.urea')}
              fullWidth
              name="urea"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </div>
        </Grid>

        <Grid item xs={12}>
          <div className={classes.form__field__wrapper}>
            <Field
              className={classes.form__fieldWithError}
              component={TextField}
              fullWidth
              label={I18n.t('activerecord.attributes.v4/input.detail')}
              multiline
              name="detail"
              variant="outlined"
              InputLabelProps={{ shrink: true }}
            />
          </div>
        </Grid>
      </Grid>
    </main>

    <footer className={classes.form__actions}>
      <Button
        className={classes.form__action}
        color='primary'
        type='submit'
        variant='contained'
      >
        {action}
      </Button>

      <Button
        className={classes.form__action}
        color='secondary'
        href={`/v4/o/${o}/inputs/fertilizer`}
      >
        {I18n.t('actions.back')}
      </Button>
    </footer>
  </form>

const pageWithStyles = withStyles(styles)(FertilizersForm)

export default pageWithStyles
