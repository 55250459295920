import React from 'react'

import { Grid, Typography } from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'

import Card from './Card'
import MiniAreaIcon from '@images/irrigation/Areas.svg'
import MiniDemandedIcon from '@images/irrigation/Demanded_aqua.svg'

const ResumeCard = ({ classes, resume }) =>
  <Card >
    <Grid container spacing={24}>
      <Grid item xs={6} sm={12}>
        <div className={classes.ResumeCard_text}>
          <Typography variant='body1'>
            {I18n.t('v4/irrigation_management_product.cards.resume_card.areas_greatest_demand')}
          </Typography>

          <Typography variant='body2'>
            <img
              src={MiniDemandedIcon}
              className={classes.ResumeCard_miniIcon}
            />
            {resume.greatestDemandArea}
          </Typography>
        </div>
      </Grid>
      <Grid item xs={6} sm={12}>
        <div className={classes.ResumeCard_text}>
          <Typography variant='body1'>
            {I18n.t('v4/irrigation_management_product.cards.resume_card.areas_need_irrigation')}
          </Typography>

          <Typography variant='body2'>
            <img
              src={MiniAreaIcon}
              className={classes.ResumeCard_miniIcon2}
            />
            {`${resume.areasThatNeedIrrigation}/${resume.totalAreas}`}
          </Typography>
        </div>
      </Grid>
    </Grid>
  </Card>

const styles = (theme) => ({
  ResumeCard_text: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    textAlign: 'center'
  },
  ResumeCard_miniIcon: {
    marginRight: theme.spacing.unit,
    width: 30
  },
  ResumeCard_miniIcon2: {
    marginRight: theme.spacing.unit,
    width: 15
  }
})

ResumeCard.defaultProps = {
  resume: {
    areasThatNeedIrrigation: 0,
    totalAreas: 0,
    greatestDemandArea: '--'
  }
}

export default withStyles(styles)(ResumeCard)
