export default (theme) => ({
  irrigationChart: {
    marginBottom: `${theme.spacing.unit * 2}px`
  },
  '@global': {
    '.recharts-brush-texts': {
      fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
      fontSize: '15px'
    }
  }
})
