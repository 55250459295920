import React, { Component } from 'react'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import { Grid, Typography } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './styles'
import renderHTML from 'react-render-html'
import { formatPrice } from '@models/checkout'
import Select from '@ui/Fields/Select'

class DetailProduct extends Component {
  constructor(props) {
    super(props)

    this.state = {
      plans: [],
      currentPlan: 0,
      monthlyPrice: 0,
      totalPrice: 0,
      store_product_id: 0
    }
  }

  componentDidMount() {
    const { model, plans: allPlans } = this.props
    const { store_product_prices } = model

    const plans = allPlans.filter(plan =>
      store_product_prices.some(price => price.plan_id === plan.id)
    )

    const currentPlanId = this.getCurrentPlanIdByUrl()

    const currentPlan = plans.find(plan => plan.id === currentPlanId) || plans[0] || {}
    const currentPlanPrice = store_product_prices.find(price => price.plan_id === currentPlan.id) || {}

    const {
      price: totalPrice,
      store_product_id
    } = currentPlanPrice

    const { interval_count } = currentPlan

    const monthlyPrice = Number(totalPrice / interval_count).toFixed(2)

    this.setState({ currentPlan: currentPlan.id, monthlyPrice, totalPrice, store_product_id, plans: plans })
  }

  getCurrentPlanIdByUrl() {
    const urlParams = new URLSearchParams(window.location.search)
    return Number(urlParams.get('plan_id'))
  }

  handleChangePlan = (event) => {
    const { plans } = this.state
    const { model } = this.props
    const { store_product_prices } = model

    const currentPlan = event.target.value

    const { price: totalPrice } = store_product_prices.find(store => store.plan_id === currentPlan)
    const { interval_count } = plans.find(plan => plan.id === currentPlan)

    const monthlyPrice = Number(totalPrice / interval_count).toFixed(2)

    this.setState({ currentPlan, monthlyPrice, totalPrice })
  }

  render() {
    const { classes, model, o } = this.props
    const { currentPlan, monthlyPrice, totalPrice, store_product_id, plans } = this.state

    return (
      <Paper className={classes.showPage__wrapper} elevation={0} >
        <header className={classes.showPage__header}>
          <Typography component='h2' variant='h5'>
            {model.name}
          </Typography>
        </header>
        <div className={classes.showPage__wrapperImage}>
          <img className={classes.showPage__imageProduct} src={model.detail_image_url} />
        </div>

        <div className={classes.showPage__wrapper__description__plan}>
          <Grid spacing={24} container>
            <Grid item xs={12} sm={6}>
              <Typography className={classes.showPage__descriptionProduct}>
                {renderHTML(model.description)}
              </Typography>
            </Grid>

            <Grid item xs={12} sm={5} className={classes.showPage__billing_column}>
              <div className={classes.showPage__select__wrapper}>
                <Typography className={classes.showPage__select_plan_title} gutterBottom component='h6' variant='h6' >
                  {`${I18n.t('v4/store.select_plan')}: `}
                </Typography>
                <Select
                  value={currentPlan}
                  onChange={this.handleChangePlan}
                  data={plans}
                  fields={{ id: 'id', name: 'name' }}
                />
              </div>
              <Typography gutterBottom variant='subtitle2' className={classes.showPage__wrapper_values}>
                <Typography className={classes.showPage__reference}>
                  {I18n.t('v4/store.referent')}
                </Typography>
                <Typography className={classes.showPage__item__value__monthly}>
                  <span className={classes.showPage__dollar__sign}>R$</span>
                  <span className={classes.showPage__monthly__price}>{monthlyPrice}</span>
                  <span className={classes.showPage__monthly__monthly}>{`/${I18n.t('v4/store.recorrencies.month')}`}</span>
                </Typography>
                <Typography className={classes.showPage__total__price}>
                  {`${I18n.t('v4/store.total')}: ${formatPrice(totalPrice)}`}
                </Typography>
              </Typography>
              <div>
                {
                  model.purchasable ?
                    <Button
                      className={classes.showPage__action}
                      color='primary'
                      type='submit'
                      variant='contained'
                      onClick={() => Turbolinks.visit(`/v4/o/${o}/store/checkout?plan_id=${currentPlan}&store_product_id=${store_product_id}`)}
                    >
                      {I18n.t('v4/store.sign')}
                    </Button>
                  :
                    <span className={classes.showPage__total__price}>
                      { I18n.t('v4/store.already_bought') }
                    </span>
                }
              </div>
            </Grid>
          </Grid>
        </div>

        <footer className={classes.form__actions}>
          <Button
            color='secondary'
            href={`/v4/o/${o}/store`}
          >
            {I18n.t('actions.back')}
          </Button>
        </footer>
      </Paper >
    )
  }
}

export default withStyles(styles)(DetailProduct)
