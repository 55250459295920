import React, { createContext, useContext, useMemo, useReducer } from 'react'
import keyMirror from '@utils/keyMirror'

const MenusStatusContext = createContext()

export const useMenusStatus = () => useContext(MenusStatusContext)

const ACTIONS = keyMirror({
  TOGGLE: null,
  SETDATA: null
})

const initialState = {
  sideBarMenu: {
    open: false,
  },
  mapAreaMenu: {
    open: false
  },
  mapEquipmentMenu: {
    open: false
  },
  mapProductsMenu: {
    open: false,
    anchor: null,
    data: {}
  },
  alertsMenu: {
    open: false,
    data: {}
  },
  mapAlertsMenu: {
    open: false
  },
  searchMenu: {
    open: false
  },
  irrigationProduct: {
    open: false,
    data: {},
    anchor: 'bottom'
  },
  satelliteImageryProduct: {
    anchor: null,
    data: {},
    open: false
  },
  satelliteImageryProductA: {
    anchor: null,
    data: {},
    area: null,
    map: null,
    open: false
  },
  satelliteImageryProductB: {
    anchor: null,
    data: {},
    area: null,
    map: null,
    open: false
  },
  weatherForecastProduct: {
    anchor: null,
    data: {},
    onFlow: false,
    onMapFlow: false,
    onSearchFlow: false,
    open: false,
    step: 0,
    forecastPoint: {}
  },
  weatherForecastSetupLatLongModal: {
    open: false
  },
  weatherForecastSetupFixedModal: {
    open: false
  },
  mapSatelliteImagery: {
    anchor: null,
    data: {},
    open: false
  },
  mapSatelliteImageryA: {
    anchor: null,
    data: {},
    open: false
  },
  mapSatelliteImageryB: {
    anchor: null,
    data: {},
    open: false
  },
  mapSatelliteImageryScale: {
    open: false
  },
  mapSatelliteImageryScaleA: {
    open: false
  },
  mapSatelliteImageryScaleB: {
    open: false
  },
  mapEditAreaMenu: {
    open: false
  }
}

export const MENUS = keyMirror(initialState)

const toggleMenus = (menus, { menu, value }) => {
  const newStatus = value !== null
    ? value
    : !menus[menu].open

  const closedMenus = Object.keys(menus).reduce((newState, key) => {
    newState[key] = {
      ...menus[key],
      open: false
    }

    return newState
  }, {})

  return {
    ...closedMenus,
    [menu]: {
      ...menus[menu],
      open: newStatus
    }
  }
}

const setMenuData = (menus, menu, key, data) => ({
  ...menus,
  [menu]: {
    ...menus[menu],
    [key]: data
  }
})

const reducer = (state, { action, menu, value, key, data }) => {
  switch (action) {
    case ACTIONS.TOGGLE:
      return toggleMenus(state, { menu, value })
    case ACTIONS.SETDATA:
      return setMenuData(state, menu, key, data)
    default:
      return state
  }
}

const MenusStatusProvider = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialState)

  const value = useMemo(() => ({
    menus: state,
    toggleMenu: (menu, value) => dispatch({ action: ACTIONS.TOGGLE, menu, value }),
    setMenuData: (menu, key, data) => dispatch({ action: ACTIONS.SETDATA, menu, key, data })
  }), [state])

  return (
    <MenusStatusContext.Provider value={value}>
      {children}
    </MenusStatusContext.Provider>
  )
}

export const withMenusStatus = component => props =>
  React.createElement(component, { ...props, menusStatus: useMenusStatus() })

export default MenusStatusProvider
