import React from 'react'

import { withStyles } from '@material-ui/core'
import styles from './styles'

import DashboardCharts from './DashboardCharts'
import ForecastCards from '../forecast-cards/Index'

import { charts } from '@models/weather/dashboard'

const ForecastWrapper = ({
  classes,
  data,
  descriptors,
  organizationId,
  dailyData,
  onRef
}) =>
  <div className={classes.Dashboard__content} data-testid='forecast_cards'>
    <ForecastCards
      data={{ forecast_points: dailyData }}
      organizationId={organizationId}
      onRef={onRef}
    />
    <div className={classes.Dashboard__charts_wrapper} data-testid='dashboard_charts'>
      <DashboardCharts
        descriptors={descriptors}
        charts={charts}
        data={data}
      />
    </div>
  </div>

export default withStyles(styles)(ForecastWrapper)
