export const getFilterGroups = (data = []) => {
  let groups = {}

  if (!_.isEmpty(data)) {
    const filters = data.map(images => {
      if (images.name === 'NDVI / Sentinel-2 (L2A)') {
        const kind = /NDVI/.test(images.name) ? 'NDVI' : 'RGB'

        return {
          ...images,
          kind: /NDVI/.test(images.name) ? 'NDVI' : 'RGB',
          data: I18n.t('v4/satellite_images.date_of_images_sentinel_2_ndvi_l2a'),
          description: I18n.t(`v4/satellite_images.filter_${kind}`)
        }
      } else if (images.name === 'RGB / Sentinel-2') {
        const kind = /NDVI/.test(images.name) ? 'NDVI' : 'RGB'
        return {
          ...images,
          kind: /NDVI/.test(images.name) ? 'NDVI' : 'RGB',
          data: I18n.t('v4/satellite_images.date_of_images_sentinel_2_rgb'),
          description: I18n.t(`v4/satellite_images.filter_${kind}`)
        }
      } else if (images.name === 'NDVI / Sentinel-2 (L1C)') {
        return {
          ...images,
          kind: 'NDVI',
          data: I18n.t('v4/satellite_images.date_of_images_sentinel_2_ndvi_l1c'),
          description: I18n.t('v4/satellite_images.filter_NDVI')
        }
      } else {
        return {
          ...images,
          kind: I18n.t('v4/satellite_images.other'),
          data: '',
          description: ''
        }
      }
    })

    groups = _.groupBy(filters, 'kind')

    Object.keys(groups).forEach((images, index) => {
      const { description } = groups[images][0]

      groups[images].unshift({ description, groupName: images })
      groups[images].push({ component: index === Object.keys(groups).length - 1 })
    })
  }

  return groups
}

export const validate = values => {
  const requiredFields = []
  const errors = {}

  requiredFields.forEach(field => {
    if (!values[field]) errors[field] = I18n.t('validations.required')
  })

  return errors
}

export const getSupportLink = () => ({
  'pt-BR': 'http://suporte.cultivointeligente.com.br/pt-BR/articles/3753677',
  'es-MX': 'http://suporte.cultivointeligente.com.br/es/articles/3753677',
  'en': 'http://suporte.cultivointeligente.com.br/en/articles/3753677'
})[I18n.locale]

export const getPercentageCloud = (value) => {
  if (value === 0 || value === 100) {
    const currentStep = value === 100 ? 'end' : 'start'
    return I18n.t(`v4/satellite_images.attributes.${currentStep}_step`)
  } else {
    return I18n.t('v4/satellite_images.attributes.half_step', { cloud: value })
  }
}

export const getInitialValuesSearch = (values = {}) =>
  !_.isEmpty(values['currentArea'])
    ? {
      label: values['currentArea']['name'],
      value: values['currentAreaId']
    }
    : values
