import React from 'react'

import {
  Button,
  Grid,
  Typography,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Paper
} from '@material-ui/core'
import { ArrowDropDown, Clear } from '@material-ui/icons'
import withStyles from '@material-ui/core/styles/withStyles'

import { useRouter } from '@contexts/RouterProvider'

const Header = ({
  anchor,
  classes,
  forecastPoints,
  selectedForecast,
  handleForecastChange,
  handleClose,
  handleCloseMenu,
  handleToggle
}) => {
  const router = useRouter()

  return (
    <Paper square className={classes.Header_wrapper}>
      <Grid container spacing={0}>
        <Grid item xs={6}>
          <Typography variant='subtitle2'>
            {I18n.t('v4/forecast.atmos')}
          </Typography>
        </Grid>
        <Grid item xs={6} className={classes.Header_rightAlign}>
          <IconButton aria-label="Delete" className={classes.Header_button} onClick={handleCloseMenu}>
            <Clear />
          </IconButton>
        </Grid>
      </Grid>

      <Grid container spacing={0}>
        <Grid item xs={6}>
          <div>
            <Button
              aria-controls='forecasts-menu'
              aria-haspopup='true'
              onClick={handleToggle}
              classes={{
                root: classes.Header_button,
                label: classes.Header_label
              }}
            >
              {selectedForecast.name}
              <ArrowDropDown />
            </Button>
            <Menu
              id='forecasts-menu'
              anchorEl={anchor}
              open={Boolean(anchor)}
              onClose={handleClose}
            >
              {
                forecastPoints.map(forecast => (
                  <MenuItem
                    onClick={() => handleForecastChange(forecast)}
                    key={`forecast-option-${forecast.id}`}
                  >
                    {forecast.name}
                  </MenuItem>
                ))
              }
            </Menu>
          </div>
        </Grid>
        <Grid item xs={6} className={classes.Header_rightAlign}>
          <Link
            className={classes.Header_link}
            component='button'
            onClick={() => router.visit('weather')}
            variant="button"
            color='primary'
            data-intercom-target='product-atmosseedetails'
            underline='always'
          >
            {I18n.t('v4/forecast.see_hourly_forecast')}
          </Link>
        </Grid>
      </Grid>
    </Paper>
  )
}

const styles = (theme) => ({
  Header_wrapper: {
    background: theme.palette.white,
    marginBottom: theme.spacing.unit + 2,
    padding: (theme.spacing.unit * 2) + 4
  },
  Header_button: {
    padding: 0
  },
  Header_label: {
    textTransform: 'capitalize',
    fontSize: '10pt',
    fontWeight: 400
  },
  Header_rightAlign: {
    display: 'flex',
    alignItems: 'flex-end',
    justifyContent: 'flex-end'
  },
  Header_link: {
    fontSize: '13px'
  }
})

export default withStyles(styles)(Header)
