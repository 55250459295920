import React from 'react'

import InputLabel from '@material-ui/core/InputLabel'
import ColorPicker from 'material-ui-color-picker'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './styles'

const CustomColorPicker = ({
  label,
  classes,
  ...props
}) =>
  <div className={classes.ColorPicker}>
    <InputLabel
      shrink
      style={{ color: 'gray' }}
    >
      {label}
    </InputLabel>

    <ColorPicker
      placeholder={props.value}
      {...props}
    />
  </div>

export default withStyles(styles)(CustomColorPicker)
