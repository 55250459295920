import React, { Component } from 'react'
import PropTypes from 'prop-types'

import {
  Button,
  CircularProgress,
  Paper,
  Typography,
  withStyles
} from '@material-ui/core'
import Table from '@ui/Table'

import { getAreaHeaders, deserialize } from '@models/area'

import DeleteDialog from '../ui/DeleteDialog'
import styles from '@styles/indexPage'

import { withHttpService } from '@contexts/HttpServiceProvider'
import { withRouter } from '@contexts/RouterProvider'
import { withSessionData } from '@contexts/SessionDataProvider'
import { withErrorHandler } from '@contexts/ErrorHandlerProvider'
import renderHTML from 'react-render-html'

class Index extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isDialogOpen: false,
      id: null,
      areas: [],
      loading: false
    }

    this.closeDialog = this.closeDialog.bind(this)
  }


  componentDidMount() {
    this.fetchAreas()
  }

  fetchAreas = async () => {
    const { errorHandler, httpService: { apiRequest }, sessionData: { currentOrganization } } = this.props
    const params = { organization_id: currentOrganization.id }

    this.setState({ loading: true })

    try {
      const { data } = await apiRequest.get('areas', { params })

      this.setState({ areas: deserialize(data) })
    }
    catch (error) {
      errorHandler(error)
    }

    this.setState({ loading: false })
  }

  closeDialog() {
    this.setState({ isDialogOpen: false })
  }

  calculateAllAreas(areas) {
    const total = areas.reduce(getTotal, 0)

    function getTotal(total, areas) {
       return total + (areas.size_ha);
      }

    return total.toFixed(2);
    
  }

  render() {
    const { classes, has_write_permissions, router } = this.props
    const { isDialogOpen, id, loading, areas } = this.state

    return (
      <Paper className={classes.page__wrapper} elevation={0}>
        <header className={classes.page__header}>
          <Typography component='h2' variant='h5'>
            {I18n.t('activerecord.models.v4/area.other')}
          </Typography>
        </header>
        {
          has_write_permissions && <Button
            variant='outlined'
            color='primary'
            className={classes.page__newResourceButton}
            href={`${router.baseURL}maps`}
          >
            {I18n.t('actions.create')} {I18n.t('activerecord.models.v4/area.one')}
          </Button>
        }

        {
          loading
            ? (
              <div className={classes.Dashboard__loading} >
                <CircularProgress size={80} />
              </div>
            )
            : (
              <div>
                <Typography component="p" variant="subtitle1" style={{display: 'flex'}}>
                  {renderHTML(I18n.t('activerecord.attributes.v4/area.all_areas_total_size', { size: this.calculateAllAreas(areas) }))}
                </Typography>
                <Table
                  tableKey='areas-index'
                  components={{
                    Container: props => <div>{props.children}</div>,
                  }}
                  columns={getAreaHeaders()}
                  data={areas}
                  actions={[
                    rowData => ({
                      icon: 'history',
                      iconProps: { 'data-testid': `show-${rowData.id}` },
                      tooltip: I18n.t('actions.productivity_history'),
                      onClick: (event, rowData) => {
                        router.visit(`areas/${rowData.id}/dashboard`)
                      }
                    }),
                    rowData => ({
                      icon: 'create',
                      iconProps: { 'data-testid': `edit-${rowData.id}` },
                      tooltip: I18n.t('actions.manage'),
                      disabled: !has_write_permissions,
                      onClick: (event, rowData) => {
                        router.visit(`areas/${rowData.id}/edit`)
                      }
                    }),
                    rowData => ({
                      icon: 'delete',
                      iconProps: { 'data-testid': `delete-${rowData.id}` },
                      tooltip: I18n.t('actions.remove'),
                      disabled: !has_write_permissions,
                      onClick: (event, rowData) => {
                        this.setState({ isDialogOpen: true, id: rowData.id })
                      }
                    })
                  ]}
                  options={{
                    showTitle: false
                  }}
                />
              </div>
            )
        }

        <DeleteDialog
          dialogTitle={`${I18n.t('actions.remove')} ${I18n.t('activerecord.models.v4/area.one')}?`}
          dialogText={I18n.t('confirmation.remove')}
          successUrl={`${router.baseURL}areas`}
          deleteUrl={`${router.baseURL}areas/${id}.json`}
          onCancel={this.closeDialog}
          onClose={this.closeDialog}
          open={isDialogOpen}
        />
      </Paper>
    )
  }
}

Index.propTypes = {
  model: PropTypes.array
}

const customStyles = theme => ({
  ...styles(theme),
  Dashboard__loading: {
    display: 'flex',
    justifyContent: 'center',
    margin: '50px'
  }
})

export default
  withSessionData(
    withRouter(
      withHttpService(
        withErrorHandler(
          withStyles(customStyles)(Index)
        )
      )
    )
  )
