import React from 'react'

import { Form as FinalForm } from 'react-final-form'

import { withMenusStatus, MENUS } from '@contexts/MenusStatusProvider'
import { withHttpService } from '@contexts/HttpServiceProvider'
import { withErrorHandler } from '@contexts/ErrorHandlerProvider'
import { withSessionData } from '@contexts/SessionDataProvider'
import { withRouter } from '@contexts/RouterProvider'

import { RailsForm } from '@support/RailsForm'

import { validate, getFilterGroups, getSupportLink } from '@models/satelliteImagery'

import ProductsWrapper from '../../productsFloatingMenu/ProductsWrapper'
import FilterForm from './FilterForm'
import CardCreateArea from './CardCreateArea'
class SatelliteImageryProduct extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      filters: [],
      fetchingImages: false
    }
  }

  handleClose = () => {
    const { menusStatus: { toggleMenu, setMenuData } } = this.props

    setMenuData(MENUS.satelliteImageryProduct, 'anchor', null)

    toggleMenu([MENUS.satelliteImageryProduct], false)
  }

  componentDidMount() {
    this.fetchFilters()
  }

  fetchFilters = async () => {
    const {
      errorHandler,
      httpService: { apiRequest },
      sessionData: { currentOrganization }
    } = this.props

    try {
      const { data } = await apiRequest('satellite_images/filters', { params: { organization_id: currentOrganization.id } })

      this.setState({ filters: data.filters })
    }
    catch (error) {
      errorHandler(error)
    }
  }

  handleLoading = (values) => {
    this.setState({
      fetchingImages: true
    }, () => setTimeout(() => {
      this.handleFilter(values)
    }, 3000)
    )
  }

  handleFilter = async (values) => {
    const {
      sessionData: { currentOrganization },
      httpService: { apiRequest },
      errorHandler,
      menusStatus: { toggleMenu, setMenuData },
      areas
    } = this.props

    const { fetchingImages, filters } = this.state

    if (fetchingImages) {
      try {
        const params = {
          area_id: values.currentAreaId,
          filter_id: values.filterId,
          clouds_percentage: values.cloudsPercentage,
          start_date: values.startDate,
          end_date: values.endDate,
          organization_id: currentOrganization.id
        }

        const orderImages = (images) => _.orderBy(images, 'date')

        const { data } = await apiRequest('satellite_images', { params })
        const images = orderImages(data.images)

        if (_.isEmpty(images)) {
          errorHandler(I18n.t('v4/satellite_images.errors.no_image'))
        } else {
          const currentFilter = filters.find(filter => filter.id === values.filterId)
          const currentArea = areas.find(area => area.id === values.currentAreaId)
          const cloudsPercentage = values.cloudsPercentage
          const startDate = values.startDate
          const endDate = values.endDate
          const filterName = currentFilter.name
          const scale = !!currentFilter['legend_info'] ? currentFilter.legend_info : {}

          setMenuData(MENUS.mapSatelliteImagery, 'data',
            {
              filterName,
              scale,
              cloudsPercentage,
              startDate,
              endDate,
              currentArea,
              images,
              currentAreaId: currentArea.id,
              filterId: currentFilter.id
            }
          )

          toggleMenu(MENUS.mapSatelliteImagery, true)
        }
      }
      catch (error) {
        errorHandler(error)
      }

      this.setState({ fetchingImages: false })
    }
  }

  handleCancelLoading = () => this.setState({ fetchingImages: false })

  render() {
    const { menusStatus: { menus }, areas, changeMapCenter, classes, router } = this.props
    const { filters, fetchingImages } = this.state
    const { open } = menus[MENUS.satelliteImageryProduct]
    const { data: initialValues } = menus[MENUS.mapSatelliteImagery]
    const { anchor } = menus[MENUS.mapProductsMenu]

    return (
      <ProductsWrapper
        open={open}
        onClose={this.handleClose}
        anchor={anchor}
      >
        {
          _.isEmpty(areas)
            ? (
              <CardCreateArea
                handleClose={this.handleClose}
                getSupportLink={getSupportLink}
              />
            )
            : (
              <RailsForm
                component={FinalForm}
                validate={validate}
                handleSubmit={this.handleLoading}
                initialValues={initialValues}
                render={
                  (props) => (
                    <FilterForm
                      {...props}
                      filterGroup={getFilterGroups(filters)}
                      areas={areas}
                      handleClose={this.handleClose}
                      loading={fetchingImages}
                      handleCancelLoading={this.handleCancelLoading}
                      changeMapCenter={changeMapCenter}
                      router={router}
                    />
                  )}
              />
            )
        }
      </ProductsWrapper>
    )
  }
}

export default
  withRouter(
    withHttpService(
      withSessionData(
        withMenusStatus(
          withErrorHandler(
            (SatelliteImageryProduct)
          )
        )
      )
    )
  )
