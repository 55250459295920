export default (theme) => ({
  analysesList: {
    backgroundColor: theme.palette.gray.light,
    alignItems: 'space-around'
  },
  analysesList__newAnalysis: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    margin: '15px 2px 20px'
  },
  analysesList__action: {
    borderRadius: '50%',
    minWidth: 'auto',
    padding: '8px',
    margin: '0 5px'
  },
  analysesList__samples: {
    display: 'block'
  },
  analysis__header: {
    padding: '8px 24px 8px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }
})
