import React from 'react'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import { Field } from 'react-final-form'

import DatePicker from '@ui/Fields/DatePicker'
import TextField from '@ui/Fields/TextField'
import Select from '@ui/Fields/Select'
import styles from '@theme/styles/forms'

import FormControlLabel from '@material-ui/core/FormControlLabel'
import Checkbox from '@material-ui/core/Checkbox'

const Form = ({
  classes,
  products,
  handleSubmit,
  title,
  values,
  currentOrganization,
  o,
  showTrial
}) => {
  const [checked, setChecked] = React.useState({
    trial: values.trial,
    esg_form: values.esg_form
  })

  const handleChange = (name, value) => {
    setChecked({
      ...checked,
      [name]: value
    })

    values[name] = value
  }

  const isFieldNotebook = (product_id) => {
    if (product_id === undefined) {
      return false
    }

    const product = products.find(p => p.id == product_id)
    return product.kind == 'field_notebook'
  }

  return <form className={classes.form} onSubmit={handleSubmit}>
    <Paper className={classes.form__wrapper} elevation={0}>
      <header className={classes.form__header}>
        <Typography component='h2' variant='h5'>
          {title}
        </Typography>
      </header>
      <main className={classes.form__fields}>
        <div className={classes.form__field__wrapper}>
          <Field
            component={TextField}
            className={classes.form__fieldWithError}
            InputLabelProps={{ shrink: true, required: true }}
            label={I18n.t('activerecord.models.v4/organization.one')}
            name='organization_id'
            margin='normal'
            disabled={typeof currentOrganization === "object"}
            fullWidth
            variant="outlined"
            required
          >
          </Field>
          <Field
            component={Select}
            className={classes.form__fieldWithError}
            InputLabelProps={{ shrink: true, required: true }}
            label={I18n.t('activerecord.models.v4/product.one')}
            name='product_id'
            margin='normal'
            fields={{ id: 'id', name: 'label', kind: 'kind' }}
            data={products}
            fullWidth
            variant="outlined"
            required
          >
          </Field>

          {
            isFieldNotebook(values['product_id']) &&
            <div style={{ marginTop: "16px", marginBottom: "8px" }}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked.esg_form}
                    onChange={() => handleChange('esg_form', !checked.esg_form)}
                    color='primary'
                  />
                }
                label={I18n.t('v4/field_notebook.esg_form')}
              />
            </div>
          }

          <div style={{ marginTop: "16px", marginBottom: "8px" }}>
            <Field
              component={DatePicker}
              InputLabelProps={{ shrink: true, required: true }}
              label={I18n.t('v4/organization.products.expiration')}
              name='expire_in'
              margin='dense'
              variant="outlined"
              initialValue={values.expire_in || new Date()}
              disablePast
              required
            >
            </Field>
          </div>

          {
            !!showTrial &&
            <FormControlLabel
              control={
                <Checkbox
                  checked={checked.trial}
                  onChange={() => handleChange('trial', !checked.trial)}
                  color='primary'
                />
              }
              label={I18n.t('activerecord.attributes.v4/organization_products.trial')}
            />
          }

        </div>
      </main>
      <footer className={classes.form__actions}>
        <Button
          className={classes.form__action}
          color='primary'
          type='submit'
          variant='contained'
        >
          {title}
        </Button>

        <Button
          className={classes.form__action}
          color='secondary'
          href={`/v4/o/${o}/admin/organizations/${currentOrganization.id}/products`}
        >
          {I18n.t('actions.back')}
        </Button>
      </footer>
    </Paper>
  </form>
}
export default withStyles(styles)(Form)
