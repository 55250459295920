import React from 'react'
import { Paper, Typography, Button } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import { getAvailableResources } from '@models/admin'
import styles from '@styles/showPage'

const Index = ({ classes, resources, o }) =>
  <Paper
    className={classes.showPage__wrapper}
    elevation={0}
  >
    <header className={classes.showPage__header}>
      <Typography component='h2' variant='h5'>
        {I18n.t('v4/admin.show.title')}
      </Typography>
    </header>
    <main className={classes.showPage__value}>
      {
        getAvailableResources(resources).map(resource =>
          <Button className={classes.showPage__value}
            href={`/v4/o/${o}/${resource.link}`}
            key={resource.id}
            variant='outlined'
            color='primary'
            fullWidth
          >
            {resource.label}
          </Button>
        )
      }
    </main>
  </Paper>

Index.defaultProps = {
  resources: []
}

export default withStyles(styles)(Index)
