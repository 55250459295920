import validateFormFields from '@services/validateFormFields'

export const validate = values => validateFormFields(values, fields)

export const fields = [
  { name: 'depth', measure: 'cm', validations: { required: true, bounds: { min: 1, max: 200 } } },
  { name: 'type_soil_analysis_text' },
  { name: 'type_soil_analysis', validations: { required: true }, hidden: true },
  { name: 'field_capacity', validations: { bounds: { min: 0.01, max: 0.6 } } },
  { name: 'permanent_wilting_point', validations: { bounds: { min: 0.01, max: 0.6 } } },
  { name: 'alpha', validations: { bounds: { min: 0.01, max: 20 } } },
  { name: 'n', validations: { bounds: { min: 0.01, max: 5 } } },
  { name: 'theta', validations: { bounds: { min: 0.01, max: 0.8 } } },
  { name: 'critical_soil_tension', measure: 'kPa', validations: { bounds: { min: 0, max: 100 } } },
  { name: 'soil_density', measure: 'g/cm³', validations: { bounds: { min: 0.5, max: 2.5 } } },
  { name: 'sand', measure: '%', validations: { bounds: { min: 0, max: 100 } } },
  { name: 'silt', measure: '%', validations: { bounds: { min: 0, max: 100 } } },
  { name: 'clay', measure: '%', validations: { bounds: { min: 0, max: 100 } } },
  { name: 'particle_density', validations: { bounds: { min: 0.5, max: 3.0 } } }
]

const headers = [
  'depth',
  'type_soil_analysis_text'
]

const soilAnalysisTypes = [
  'volume',
  'mass'
]

export const getSampleHeaders = () =>
  headers.map(field => ({
    title: I18n.t(`activerecord.attributes.v4/analysis.${field}`),
    field
  }))

export const getSoilAnalysisTypes = () =>
  soilAnalysisTypes.map(type => ({
    id: type,
    name: I18n.t(`v4/analysis.samples.types.${type}`)
  }))
