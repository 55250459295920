import React from 'react'

import { Button, Paper } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import { Field } from 'react-final-form'

import { getAvaliableGroupings } from '@models/equipment/telemetry/dateFilter'

import { sendAmplitudeEvent } from '@utils/amplitudeEvent'
import { IntercomAPI } from '@utils/IntercomAPI';

import DateTimePicker from '@ui/Fields/DateTimePicker'
import Select from '@ui/Fields/Select'

import styles from './styles'

const Filter = ({ handleSubmit, classes, disabled, initialStart, initialFinish, initialGroup }) =>
  <form onSubmit={handleSubmit} data-testid='charts-filter'>
    <Paper className={classes.Filter__fields_wrapper}>
      <Field
        className={classes.Filter__field}
        component={DateTimePicker}
        margin='normal'
        InputLabelProps={{ shrink: true }}
        variant='outlined'
        label={I18n.t('v4/equipment.dashboard.filter.fields.start_date')}
        name='start'
        fullWidth
        initialValue={initialStart}
        disableFuture
        required
        onFocus={() => {
          sendAmplitudeEvent('Telemetry - Clicked start date box')
          IntercomAPI('trackEvent', 'Telemetry - Clicked start date box')
        }}
      />

      <Field
        className={classes.Filter__field}
        component={DateTimePicker}
        margin='normal'
        InputLabelProps={{ shrink: true }}
        variant='outlined'
        label={I18n.t('v4/equipment.dashboard.filter.fields.end_date')}
        name='finish'
        fullWidth
        initialValue={initialFinish}
        disableFuture
        required
        onFocus={() => {
          sendAmplitudeEvent('Telemetry - Clicked end date box')
          IntercomAPI('trackEvent', 'Telemetry - Clicked end date box')
        }}
      />

      <Field
        className={classes.Filter__field}
        component={Select}
        data={getAvaliableGroupings()}
        initialValue={initialGroup}
        fields={{ id: 'id', name: 'description' }}
        InputLabelProps={{ shrink: true }}
        label={I18n.t('v4/equipment.dashboard.filter.fields.grouping')}
        margin='normal'
        name='group_by'
        fullWidth
        required
        variant='outlined'
        onFocus={() => {
          sendAmplitudeEvent('Telemetry - Selected periodicity')
          IntercomAPI('trackEvent', 'Telemetry - Selected periodicity')
        }}
      />

      <Button
        className={classes.Filter__action}
        name='filter'
        color='primary'
        type='submit'
        disabled={disabled}
        variant='contained'
      >
        {I18n.t('v4/equipment.dashboard.filter.action')}
      </Button>
    </Paper>
  </form>

export default withStyles(styles)(Filter)
