import React from 'react'
import styles from './styles'
import withStyles from '@material-ui/core/styles/withStyles'
import { Typography } from '@material-ui/core'

const i18nPrefixer = text => I18n.t(`v4/irrigation_system.${text}`)

const ChartLegend = ({ classes, legends }) =>
  <div className={classes.ChartLegend}>
    {
      legends.map(({ label, icon, onClick, hide }) =>
        <span key={label} className={classes.ChartLegend__legend} onClick={onClick}>
          {icon.isSvg
            ? <img src={icon.src} width={16} height={16} />
            : icon.src
          }
          <Typography
            className={`${classes.ChartLegend__text} ${hide ? classes.ChartLegend__text__hide : ''}`}
            variant='body1'
          >
            {i18nPrefixer(`${label}`)}
          </Typography>
        </span>
      )
    }
  </div>

export default withStyles(styles)(ChartLegend)
