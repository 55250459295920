export default object => {
  if (!(object instanceof Object && !Array.isArray(object))) {
    console.error('Argument must be an object')

    return {}
  }

  const newObject = {}

  Object.keys(object).forEach(key => {
    newObject[key] = key
  })

  return newObject
}
