import React from 'react'

import { Button, Grid, Typography } from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'

import Card from './Card'

import { isDateSelected } from '@models/irrigation_managements/maps'
import { convertToLocaleDate } from '@services/locale'

const IrrigationForecastCard = ({ forecast, selectedDate, classes, hasForecastProduct, currentDate, onDateClick }) =>
  <Card
    title={I18n.t('v4/irrigation_management_product.cards.forecast_card.title')}
  >
    {
      _.isEmpty(forecast)
        ? hasForecastProduct
          ? <>
            <Typography variant='body1' align='center'>
              {I18n.t('v4/irrigation_management_product.cards.forecast_card.no_associated_management.text1')}
            </Typography>

            <Typography variant='body1' align='center'>
              {I18n.t('v4/irrigation_management_product.cards.forecast_card.no_associated_management.text2')}
            </Typography>
          </>
          : <Typography variant='body1' align='center'>
            {I18n.t('v4/irrigation_management_product.cards.forecast_card.no_forecast_product')}
          </Typography>
        :
        <section className={classes.IrrigationForecastCard_days}>
          <Grid container spacing={8}>
            <Grid
              item xs={3} sm={6} md={4} lg={3} xl
              className={classes.IrrigationForecastCard_button}
            >
              <Button
                className={isDateSelected(selectedDate, currentDate) ? classes.IrrigationForecastCard_dayButton__selected : ''}
                onClick={() => onDateClick(currentDate)}
                color='default'
                variant='outlined'
              >
                {
                  convertToLocaleDate(
                    currentDate.date,
                    {
                      timeZone: 'UTC',
                      day: '2-digit',
                      month: '2-digit'
                    }
                  )
                }
              </Button>
            </Grid>

            {
              forecast.map(date =>
                date.date > currentDate.date &&
                <Grid item
                  xs={3} sm={6} md={4} lg={3} xl
                  key={date.date}
                  className={classes.IrrigationForecastCard_button}
                >
                  <Button
                    key={date.date}
                    className={isDateSelected(selectedDate, date) ? classes.IrrigationForecastCard_dayButton__selected : ''}
                    color='default'
                    variant='outlined'
                    onClick={() => onDateClick(date)}
                  >
                    {
                      convertToLocaleDate(
                        date.date,
                        {
                          timeZone: 'UTC',
                          day: '2-digit',
                          month: '2-digit'
                        }
                      )
                    }
                  </Button>
                </Grid>
              )
            }
          </Grid>
        </section>

    }
  </Card>

IrrigationForecastCard.defaultProps = {
  forecast: []
}

const styles = theme => ({
  IrrigationForecastCard_days: {
    marginTop: theme.spacing.unit,
    width: '100%'
  },
  IrrigationForecastCard_button: {
    textAlign: 'center'
  },
  IrrigationForecastCard_dayButton__selected: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.white
  }
})

export default withStyles(styles)(IrrigationForecastCard)
