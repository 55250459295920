import React from 'react'

import { Grid, Paper, Typography, withStyles } from '@material-ui/core'

const NoEquipments = ({ classes }) =>
  <Grid container justify='center'>
    <Grid item xs={12} md={6}>
      <Paper elevation={4} className={classes.Paper_white} >
        <Typography variant='body1' className={classes.GutterBottom}>
          {I18n.t('v4/widgets.empty_text1')}
        </Typography>

        <Typography variant='body1'>
          {I18n.t('v4/widgets.empty_text2')}
        </Typography>
      </Paper>
    </Grid>
  </Grid>

const styles = theme => ({
  Paper_white: {
    background: theme.palette.white,
    padding: theme.spacing.unit * 2
  },
  GutterBottom: {
    marginBottom: theme.spacing.unit * 3
  }
})

export default withStyles(styles)(NoEquipments)
