import React, { useState, useEffect } from 'react'

import { Field } from 'react-final-form'
import { Button, Checkbox, FormControlLabel, Grid, withStyles } from '@material-ui/core'

import TextField from '@ui/Fields/TextField'
import Select from '@ui/Fields/Select'
import CheckBox from '@ui/Fields/CheckBox'

import styles from '@theme/styles/forms'

const Form = ({
  handleSubmit,
  classes,
  action,
  onCancel,
  statuses,
  values
}) => {
  const [checkedKc, setCheckedKc] = useState(values.constant_kc || false)

  useEffect(() => {
    values.constant_kc = checkedKc
  }, [checkedKc])

  const handleChange = event => {
    setCheckedKc(event.target.checked)
  }

  return (
    <form onSubmit={handleSubmit} data-testid="stages-form">
      <main className={classes.form__fields}>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <div className={classes.form__field__wrapper}>
              <Field
                component={TextField}
                className={classes.form__fieldWithError}
                fullWidth
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                label={I18n.t('activerecord.attributes.v4/culture.phenological_stages.name')}
                name='name'
                required
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={classes.form__field__wrapper}>
              <Field
                component={Select}
                className={classes.form__fieldWithError}
                fullWidth
                data={statuses}
                fields={{ id: 'id', name: 'name' }}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                label={I18n.t('activerecord.attributes.v4/culture.phenological_stages.status')}
                name='status'
                required
              />
            </div>
          </Grid>

          <Grid item xs={6}>
            <div className={classes.form__field__wrapper}>
              <Field
                component={TextField}
                className={classes.form__fieldWithError}
                type='number'
                fullWidth
                inputProps={{ min: '0.2', max: '1.9', step: '0.01' }}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                label={I18n.t('activerecord.attributes.v4/culture.phenological_stages.kc')}
                name='kc'
                required
              />
            </div>
          </Grid>

          <Grid item xs={6}>
            <div className={classes.form__field__wrapper}>
              <Field
                component={TextField}
                className={classes.form__fieldWithError}
                type='number'
                fullWidth
                inputProps={{ min: '1', max: '730', step: '1' }}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                label={I18n.t('activerecord.attributes.v4/culture.phenological_stages.duration')}
                name='duration'
                required
              />
            </div>
          </Grid>

          <Grid item xs={6}>
            <div className={classes.form__field__wrapper}>
              <Field
                component={TextField}
                className={classes.form__fieldWithError}
                type='number'
                fullWidth
                inputProps={{ min: '0.5', max: '1', step: '0.01' }}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                label={I18n.t('activerecord.attributes.v4/culture.phenological_stages.kl')}
                name='kl'
                required
              />
            </div>
          </Grid>

          <Grid item xs={6}>
            <div className={classes.form__field__wrapper}>
              <Field
                component={CheckBox}
                className={classes.form__fieldWithError}
                fullWidth
                InputLabelProps={{ shrink: true }}
                margin='normal'
                name='constant_kc'
                checked={checkedKc}
                onChange={handleChange}
                label={I18n.t('activerecord.attributes.v4/culture.phenological_stages.constant_kc')}
              />
            </div>
          </Grid>
        </Grid>
      </main>
      <footer className={classes.form__actions}>
        <Button
          className={classes.form__action}
          color='primary'
          type='submit'
          variant='contained'
        >
          {action}
        </Button>

        <Button
          className={classes.form__action}
          color='secondary'
          onClick={onCancel}
        >
          {I18n.t('actions.cancel')}
        </Button>
      </footer>
    </form>
  )
}

export default withStyles(styles)(Form)
