import React from 'react'

import { Typography } from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'

const ManagementDescription = ({ classes, crop }) =>
  <div className={classes.ManagementDescription}>
    {
      crop.culture &&
      <Typography variant='body2'>
        {I18n.t('v4/irrigation_management.culture')} - {crop.culture}
      </Typography>
    }

    <div className={classes.ManagementDescription_content}>
      <Typography variant='body1'>{I18n.t('v4/irrigation_management.phenological_stage')}: </Typography>
      <Typography variant='body2'>{crop.phenological_stage || '---'}</Typography>
    </div>
    <div className={classes.ManagementDescription_content}>
      <Typography variant='body1'>{I18n.t('v4/irrigation_management.root_depth')}: </Typography>
      <Typography variant='body2'>{crop.root_depth ? `${crop.root_depth} cm` : '---'}</Typography>
    </div>
  </div>

const styles = theme => ({
  ManagementDescription: {
    minWidth: '300px',
    marginRight: `${theme.spacing.unit * 2}px`,
    '@media (min-width: 800px) and (max-width: 1250px)': {
      margin: `${theme.spacing.unit}px 0`,
      order: 1,
      flex: '1 0 100%',
      display: 'flex',
      justifyContent: 'space-between'
    }
  },
  ManagementDescription_content: {
    display: 'flex'
  }
})

ManagementDescription.defaultProps = {
  crop: {}
}

export default withStyles(styles)(ManagementDescription)
