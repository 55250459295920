import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { Field } from 'react-final-form'
import Button from '@material-ui/core/Button'
import withStyles from '@material-ui/core/styles/withStyles'
import TextField from '@ui/Fields/TextField'
import Select from '@ui/Fields/Select'
import PivotForm from './PivotForm'
import BoundedForm from './BoundedForm'
import CalculationResultInfo from './CalculationResultInfo'
import LoadingComponent from '@ui/LoadingComponent'
import { getIdealEfficiencyValue, acceptedAttachmentsMimeTypes } from '@models/equipment/irrigation'
import ControlledFormField from '@ui/Fields/ControlledFormField'
import DirectUploadDropzone from '@ui/Fields/DirectUploadDropzone'
import styles from '@styles/forms'
import { serializeFileData } from '@models/files'

const Form = ({
  calculationResult,
  classes,
  handleSubmit,
  organization_id,
  manufacturers,
  form,
  initialAttachments,
  irrigation_types,
  values,
  loading,
  o
}) => {
  const [attachments, setAttachments] = React.useState(initialAttachments)
  const [uploadingAttachments, setUploadingAttachments] = React.useState(false)

  if (!values.equipment) values.equipment = {}

  const onSubmit = (event, calculate) => {
    values.calculate = calculate

    if (!calculate) {
      values.equipment.attachments = attachments.map(serializeFileData)
    }

    handleSubmit(event)
  }

  const setIdealEfficiencyValue = (type) => {
    const idealEfficiencyValue = getIdealEfficiencyValue(type)

    if (idealEfficiencyValue) {
      form.change('equipment[irrigation_efficiency]', idealEfficiencyValue)
    }
  }

  const getForm = (type) => {
    if (!type) return null

    const selectedForm =
      type === 'pivot'
        ? <PivotForm values={values} manufacturers={getManufacturersList(type)} />
        : <BoundedForm values={values} manufacturers={getManufacturersList(type)} />

    return selectedForm
  }

  const getManufacturersList = (type) => {
    if (!manufacturers) return []

    return type === 'pivot' ? manufacturers.pivot : manufacturers.bounded
  }

  return <form
    className={classes.form}
    onSubmit={event => onSubmit(event, true)}
    data-testid='irrigation-equipment-form'
    noValidate
  >
    <main className={classes.form__fields}>
      <div className={classes.form__field__wrapper}>
        <Field
          component={TextField}
          className={classes.form__fieldWithError}
          InputLabelProps={{ shrink: true }}
          label={I18n.t('v4/irrigation_system.name')}
          name='equipment[name]'
          margin='normal'
          fullWidth
          disabled={loading}
          variant='outlined'
          required
        />
      </div>

      <div className={classes.form__field__wrapper}>
        <ControlledFormField
          component={Select}
          className={classes.form__fieldWithError}
          InputLabelProps={{ shrink: true, required: true }}
          label={I18n.t('v4/irrigation_system.irrigation_system_type')}
          name='equipment[irrigation_system_type]'
          margin='normal'
          fields={{ id: 'id', name: 'name' }}
          data={irrigation_types}
          fullWidth
          disabled={loading}
          onChange={setIdealEfficiencyValue}
          info={I18n.t('v4/irrigation_system.irrigation_system_type_info')}
          variant='outlined'
          required
        />
      </div>

      <div className={classes.form__field__wrapper}>
        <Field
          component={TextField}
          className={classes.form__fieldWithError}
          InputLabelProps={{ shrink: true }}
          label={`${I18n.t('v4/irrigation_system.irrigation_efficiency')} (%)`}
          name='equipment[irrigation_efficiency]'
          margin='normal'
          type='number'
          fullWidth
          disabled={loading}
          inputProps={{ min: '30', max: '100' }}
          variant='outlined'
          required
        />
      </div>

      <div className={classes.form__field__wrapper}>
        <Field
          component={TextField}
          className={classes.form__fieldWithError}
          InputLabelProps={{ shrink: true }}
          label={`${I18n.t('v4/irrigation_system.engine_power')} (cv)`}
          info={I18n.t('v4/irrigation_system.engine_power_info')}
          name='equipment[engine_power]'
          margin='normal'
          type='number'
          fullWidth
          disabled={loading}
          inputProps={{ min: '0', max: '2000' }}
          variant='outlined'
        />
      </div>

      {getForm(values.equipment.irrigation_system_type)}

      {
        values.equipment.irrigation_system_type &&
        <Fragment>
          <div className={classes.form__field__wrapper}>
            <Field
              component={TextField}
              className={classes.form__fieldWithError}
              InputLabelProps={{ shrink: true }}
              label={I18n.t('v4/irrigation_system.detail')}
              name='equipment[detail]'
              margin='normal'
              fullWidth
              multiline
              variant='outlined'
            />
          </div>

          <div className={classes.form__field__wrapper}>
            <DirectUploadDropzone
              initialFiles={attachments}
              loading={uploadingAttachments}
              setLoading={setUploadingAttachments}
              label={I18n.t('v4/irrigation_system.attachments')}
              acceptedMimeTypes={acceptedAttachmentsMimeTypes}
              resource_name='V4::Equipment::Irrigation'
              organization_id={organization_id}
              onFilesChange={setAttachments}
              multiple
            />
          </div>
        </Fragment>
      }
    </main>


    <footer className={classes.form__actions}>
      <Button
        className={classes.form__action}
        color='primary'
        disabled={loading}
        type='submit'
        variant='contained'
      >
        {I18n.t('v4/irrigation_system.calculate')}
      </Button>

      <Button
        className={classes.form__action}
        color='secondary'
        href={`/v4/o/${o}/equipments/irrigation`}
      >
        {I18n.t('actions.back')}
      </Button>
    </footer>

    {
      loading &&
      <div
        style={{
          width: '100%',
          textAlign: 'center'
        }}
      >
        <LoadingComponent />
      </div>
    }

    {
      !_.isEmpty(calculationResult) &&
      <CalculationResultInfo
        handleSubmit={event => onSubmit(event, false)}
        disabled={loading || uploadingAttachments}
        calculationResult={calculationResult}
        data={values.equipment}
      />
    }
  </form>
}

Form.propTypes = {
  action: PropTypes.string
}

Form.defaultProps = {
  irrigation_types: [],
  initialAttachments: []
}

export default withStyles(styles)(Form)
