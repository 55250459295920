import React from 'react'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import { Field } from 'react-final-form'

import TextField from '../../ui/Fields/TextField'
import Select from '../../ui/Fields/Select'
import styles from '@theme/styles/forms'
import { Grid } from '@material-ui/core'

const Form = ({
  handleSubmit,
  classes,
  title,
  payment_profiles,
  organization_id,
  o
}) => (
    <form className={classes.form} onSubmit={handleSubmit} data-testid='organizations-subscriptions-form'>
      <Paper className={classes.form__wrapper} elevation={0}>
        <header className={classes.form__header}>
          <Typography component='h2' variant='h5'>
            {title}
          </Typography>
        </header>
        <main className={classes.form__fields}>
          <Grid
            container
            spacing={24}
          >
            <Grid item xs={12}>
              <Field
                component={TextField}
                className={classes.form__fieldWithError}
                margin='normal'
                fullWidth
                InputLabelProps={{ shrink: true }}
                variant='outlined'
                label={I18n.t('v4/account.attributes.product')}
                name='product'
                required
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={TextField}
                className={classes.form__fieldWithError}
                fullWidth
                InputLabelProps={{ shrink: true }}
                label={I18n.t('v4/account.attributes.plan')}
                margin='normal'
                name='plan'
                type='string'
                variant='outlined'
                required
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={TextField}
                className={classes.form__fieldWithError}
                fullWidth
                InputLabelProps={{ shrink: true }}
                label={I18n.t('v4/account.attributes.price')}
                margin='normal'
                name='price'
                type='string'
                variant='outlined'
                required
                disabled
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={Select}
                className={classes.form__fieldWithError}
                InputLabelProps={{ shrink: true, required: true }}
                label={I18n.t('v4/account.attributes.payment_method')}
                name='payment_profile_id'
                margin='normal'
                fields={{ id: 'id', name: 'name' }}
                data={payment_profiles}
                fullWidth
                variant='outlined'
                required
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={TextField}
                className={classes.form__fieldWithError}
                fullWidth
                InputLabelProps={{ shrink: true }}
                label={I18n.t('v4/account.attributes.next_charge')}
                margin='normal'
                name='next_charge'
                type='string'
                variant='outlined'
                required
                disabled
              />

            </Grid>
          </Grid>
        </main>
        <footer className={classes.form__actions}>
          <Button
            className={classes.form__action}
            color='primary'
            type='submit'
            variant='contained'
          >
            {title}
          </Button>

          <Button
            className={classes.form__action}
            color='secondary'
            href={`/v4/o/${o}/organizations/${organization_id}/subscriptions`}
          >
            {I18n.t('actions.back')}
          </Button>
        </footer>
      </Paper >
    </form >
  )

export default withStyles(styles)(Form)
