import React, { useState, useEffect } from "react";
import { Form as FinalForm, Field } from "react-final-form";
import { Button, withStyles, Divider, Typography } from "@material-ui/core";
import { withSnackbar } from "notistack";
import { useSessionData } from "@contexts/SessionDataProvider";
import { useErrorHandler } from "@contexts/ErrorHandlerProvider";
import { useHttpService } from "@contexts/HttpServiceProvider";
import { units } from "@models/area";
import { sendAmplitudeEvent } from '@utils/amplitudeEvent'
import httpService from "@services/httpService";
import LoadingComponent from "@ui/LoadingComponent";
import DatePicker from "@ui/Fields/DatePicker";
import styles from "@theme/styles/forms";
import TextField from "@ui/Fields/TextField";
import Select from "@ui/Fields/Select";
import Grid from "@material-ui/core/Grid";
import HarvestIconEnabled from "@images/area/harvest_white.svg";
import HarvestIconDisabled from "@images/area/harvest.svg";
import areasMock from "./areas.json";

function RegisterCropForm({ dialog, setDialog, classes, enqueueSnackbar }) {
  const [areas, setAreas] = useState([]);
  const [loading, setLoading] = useState(false);
  const { apiRequest } = useHttpService();

  const { currentOrganization } = useSessionData();
  const handleError = useErrorHandler();

  const fetchAreasData = async () => {
    try {
      setLoading(true);
      const { data } = await httpService.get(
        `/api/v4/areas/delayed_harvest?organization_id=${currentOrganization.id}`
      );
      setAreas(data);
      setLoading(false);
    } catch (error) {
      handleError(error);
    }
  };

  useEffect(() => {
    fetchAreasData({});
    // setAreas(areasMock);
  }, []);

  const onSubmit = async (values) => {
    setLoading(true);
    _.forOwn(values.selectedAreas, async (areaId, key) => {
      try {
        const data = {
          finished_at: values[`date_${areaId}`],
          yield_value: values[`yield_${areaId}`],
          yield_unity: values[`unity_${areaId}`],
        };

        const { data: cropData } = await apiRequest.get(
          `/areas/${areaId}/crops`,
          {
            params: {
              organization_id: currentOrganization.id,
            },
          }
        );

        const activeCrop = cropData.filter(
          (data) => data.status === "active"
        )[0];
        const cropId = activeCrop.id;

        await apiRequest.put(`crops/${cropId}`, {
          _harvest: {
            ...data,
          },
        });
        setLoading(false);
        setDialog(false);
        enqueueSnackbar(
          I18n.t("notifications_central.register_crop_modal_success_snackbar"),
          { variant: "success" }
        );
      } catch (err) {
        handleError(err);
        setLoading(false);
        setDialog(false);
      }
    });
  };

  const isChecked = (values, area_id) => {
    return values.selectedAreas && values.selectedAreas.includes(area_id);
  };

  return (
    <main className={classes.Main_Container}>
      <div className={classes.Areas_Container}>
        {!loading ? (
          <FinalForm
            onSubmit={onSubmit}
            render={({ handleSubmit, values }) => (
              <form onSubmit={handleSubmit}>
                {areas.map((data, index) => (
                  <div key={data.area_id}>
                    <div className={classes.Areas_Container_Items}>
                      <Field
                        name="selectedAreas"
                        type="checkbox"
                        component="input"
                        onFocus={() => sendAmplitudeEvent('Harvest - Notification - Selected Area to Harvest')}
                        value={data.area_id}
                      />
                      <div className={classes.Areas_Container_Field}>
                        <Typography variant="subheading">
                          {data.area_name}
                        </Typography>
                      </div>
                    </div>
                    {isChecked(values, data.area_id) && (
                      <main className={classes.form__fields}>
                        <Grid container spacing={16}>
                          <Grid item xs={12}>
                            <Field
                              name={`date_${data.area_id}`}
                              className={classes.form__fieldWithError}
                              component={DatePicker}
                              margin="normal"
                              InputLabelProps={{ shrink: true }}
                              variant="outlined"
                              initialValue={new Date()}
                              label={I18n.t(
                                "activerecord.attributes.v4/culture.phenological_stages.date"
                              )}
                              fullWidth
                              onFocus={() => sendAmplitudeEvent('Harvest - Notification - Clicked Date Field')}
                              required
                            />
                          </Grid>
                          <Grid item xs={6}>
                            <Field
                              name={`yield_${data.area_id}`}
                              className={classes.form__fieldWithError}
                              component={TextField}
                              fullWidth
                              label={I18n.t(
                                "v4/area.dashboard.card.yield.title"
                              )}
                              variant="outlined"
                              required
                              placeholder={I18n.t(
                                "v4/area.dashboard.card.yield.placeholder"
                              )}
                              InputLabelProps={{ shrink: true }}
                              onClick={() => sendAmplitudeEvent('Harvest - Notification - Clicked Productivity Field')}
                              type="number"
                              InputProps={{
                                inputProps: {
                                  className:
                                    classes.form__input_number_no_arrows,
                                },
                              }}
                            />
                          </Grid>

                          <Grid item xs={6}>
                            <Field
                              name={`unity_${data.area_id}`}
                              className={classes.form__fieldWithError}
                              component={Select}
                              fullWidth
                              label={I18n.t(
                                "v4/area.dashboard.card.unity.title"
                              )}
                              placeholder={I18n.t(
                                "v4/area.dashboard.card.unity.placeholder"
                              )}
                              onFocus={() => sendAmplitudeEvent('Harvest - Notification - Clicked Unit Field')}
                              required
                              fields={{ id: "value", name: "value" }}
                              data={units}
                              variant="outlined"
                              InputLabelProps={{ shrink: true }}
                            />
                          </Grid>
                        </Grid>
                      </main>
                    )}
                    <Divider />
                  </div>
                ))}

                {!loading && _.isEmpty(areas) && (
                  <Typography variant="body1" align="center" className={classes.No_Areas_Text}>
                    {I18n.t(
                      "notifications_central.register_crop_modal_no_areas"
                    )}
                  </Typography>
                )}

                <div className={classes.Areas_Container_Bottom}>
                  {!loading ? (
                    <>
                      <Button
                        type="submit"
                        size="large"
                        color="primary"
                        variant="contained"
                        onClick={() => sendAmplitudeEvent('Harvest - Notification - Clicked Harvest Button')}
                        disabled={_.isEmpty(values.selectedAreas)}
                        fullWidth
                      >
                        {I18n.t("actions.harvest")}
                        <img
                          src={
                            _.isEmpty(values.selectedAreas)
                              ? HarvestIconDisabled
                              : HarvestIconEnabled
                          }
                          alt="Icone de Colheita"
                          className={classes.Submit_Button_Icon}
                        />
                      </Button>
                    </>
                  ) : (
                    <LoadingComponent size={30} />
                  )}
                </div>
              </form>
            )}
          />
        ) : (
          <LoadingComponent size={40} />
        )}
      </div>
    </main>
  );
}

const customStyles = (theme) => ({
  ...styles(theme),
  Main_Container: {
    display: "flex",
    flexDirection: "column",
  },
  Areas_Container: {
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
  },
  Areas_Container_Items: {
    display: "flex",
    alignItems: "center",
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit,
  },
  Areas_Container_Field: {
    marginLeft: theme.spacing.unit,
  },
  Areas_Container_Bottom: {
    display: "flex",
    justifyContent: "center",
    marginTop: theme.spacing.unit * 4,
  },
  No_Areas_Text: {
    marginTop: theme.spacing.unit * 4
  },
  Submit_Button_Icon: {
    marginLeft: theme.spacing.unit,
  },
});

export default withSnackbar(withStyles(customStyles)(RegisterCropForm));
