import React, { createContext, useContext, useCallback } from 'react'
import { SnackbarProvider, withSnackbar } from 'notistack'
import deserializeError from '@services/deserializeError'

const ErrorHandlerContext = createContext()

export const useErrorHandler = () => useContext(ErrorHandlerContext)

const SnackBarContainer = ({ children }) =>
  <SnackbarProvider maxSnack={3}>
    <ErrorHandlerProvider>
      {children}
    </ErrorHandlerProvider>
  </SnackbarProvider>


const ErrorHandlerProvider = withSnackbar(({ children, enqueueSnackbar }) => {
  const errorHandler = useCallback((error, { variant = 'error' } = {}) => {
    if (!_.isString(error)) {
      return deserializeError(error).map(e =>
        enqueueSnackbar(e, { variant })
      )
    }

    enqueueSnackbar(error, { variant })
  }, [])

  return (
    <ErrorHandlerContext.Provider value={errorHandler}>
      {
        React.cloneElement(
          children,
          {
            handleError: errorHandler,
            enqueueSnackbar: (message, options) => enqueueSnackbar(message, options)
          }
        )
      }
    </ErrorHandlerContext.Provider>
  )
})

export const errorHandlerProviderWrapper = component => props =>
  <SnackBarContainer>
    {React.createElement(component, props)}
  </SnackBarContainer>

export const withErrorHandler = component => props =>
  React.createElement(component, { ...props, errorHandler: useErrorHandler() })

export default SnackBarContainer
