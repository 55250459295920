import React from 'react'

import TableExport from 'tableexport'

import { convertToLocaleDate, parseDecimalToTime } from '@services/locale'

import Table from './visualizations/Table'
import Planning from './visualizations/Planning'
import Cards from './visualizations/Cards'
import keyMirror from '@utils/keyMirror'
import moment from 'moment'

export const visualizations = keyMirror({
  table: null,
  card: null,
  planning: null
})

export const irrigationVisualizationsOptions = Object.keys(visualizations).map(
  visualization => ({
    id: visualization,
    name: I18n.t(`v4/irrigation_management.visualizations.${visualization}`)
  })
)

export const irrigationVisualizationsComponents = {
  table: ({ finishedManagements, runningManagements, o, ...props }) => (
    <Table
      {...props}
      data={formatTableManagements(runningManagements, finishedManagements)}
      o={o}
    />
  ),
  card: ({ runningManagements, finishedManagements, o, ...props }) => (
    <Cards
      {...props}
      data={formatCardManagements(runningManagements, finishedManagements)}
      o={o}
    />
  ),
  planning: ({ runningManagements }) => (
    <Planning {...getFormattedPlanningFields(runningManagements)} />
  )
}

const formatTableManagements = (runningManagements, finishedManagements) => ({
  running: getFormattedTableFields(runningManagements),
  finished: finishedManagements
})

const formatCardManagements = (runningManagements) => ({
  running: getFormattedCardFields(runningManagements)
})

const getFormattedPlanningFields = data => {
  if (_.isEmpty(data)) {
    return {}
  }

  const dates = getDates(data)
  const fields = describer
    .filter(datum => datum.field !== 'area')
    .map(datum => ({ name: datum.title, field: datum.field }))

  return {
    describer: [
      { field: 'area', title: I18n.t('v4/irrigation_management.describer_index.area') },
      { field: 'vars', title: I18n.t('v4/irrigation_management.describer_index.variables') },
      ...dates.map((date, index) => ({ field: date, title: convertToLocaleDate(date) }))
    ],
    dates: dates.map((date, index) => ({ id: index, name: convertToLocaleDate(date), field: date })),
    data: data.map(datum => formatPlanningData(datum, fields)),
    fields
  }
}

const getFormattedTableFields = data => {
  if (_.isEmpty(data)) {
    return {}
  }

  const dates = getDates(data)

  return {
    dates: dates.map((date, index) => ({
      id: index,
      name: convertToLocaleDate(date),
    })),
    content: dates.map((date) =>
      data.map((datum) => formatTableData(datum, date))
    ),
    orderBy: [
      { field: "alpha", name: I18n.t("v4/irrigation_management.alpha") },
      ...describer
        .filter(
          (datum) => datum.field !== "area" && datum.field !== "hydricStatus"
        )
        .map((datum) => ({ field: datum.field, name: datum.title })),
    ],
  };
}

const getFormattedCardFields = data => {
  if (_.isEmpty(data)) {
    return {}
  }

  const dates = getDates(data)

  return {
    dates: dates.map((date, index) => ({ id: index, name: convertToLocaleDate(date) })),
    content: dates.map(date => data.map(datum => formatCardData(datum, date))),
    orderBy: [
      { field: 'alpha', name: I18n.t('v4/irrigation_management.alpha') },
      ...describer
        .filter(datum => datum.field !== 'area' && datum.field !== 'hydricStatus')
        .map(datum => ({ field: datum.field, name: datum.title }))
    ]
  }
}

const formatPlanningData = (datum, fields) => (
  {
    ...datum,
    vars: Object.keys(datum).length === 1
      ? I18n.t(`v4/irrigation_management.hydric_status.no_data.title`)
      : fields.map(datum => datum.name)
  }
)

const formatCardData = (datum, date) => {
  let content = []

  if (!datum[date]) {
    content = filteredDescriber().map(description => ({ name: description.field, value: null }))
  } else {
    _.forOwn(datum[date], (value, key) => {
      if (key !== 'hydricStatus' && key !== 'needIrrigation') {
        content.push({ name: key, value })
      }
    })
    if (content[content.length - 1].name === 'accumulated_water_loss') {
      let tmp = content.splice(1, 1);
      content.splice(2, 0, tmp[0]);
    }
  }

  return {
    id: datum.id,
    area: datum.area,
    status: datum.status,
    content,
    hydricStatus: datum[date] ? datum[date].hydricStatus : null
  }
}

const formatTableData = (data, date) => {

  let content = []

  if (!data[date]) {
    content = filteredDescriber().map(description => ({ name: description.field, value: null }))
  } else {
    _.forOwn(data[date], (value, key) => {
      if (key !== 'hydricStatus' && key !== 'needIrrigation') {
        content.push({ name: key, value })
      }
    })
    if (content[content.length - 1].name === 'accumulated_water_loss') {
      let tmp = content.splice(1, 1);
      content.splice(2, 0, tmp[0]);
    }
  }

  return {
  id: data.id,
  area: data.area,
  status: data.status,
  content,
  ...data[date]
  }

}

export const filterOrderOptions = [
  { id: 'up', name: I18n.t('v4/irrigation_management.filter.order_values.ascending') },
  { id: 'down', name: I18n.t('v4/irrigation_management.filter.order_values.descending') },
]

const describer = [
  { field: 'area', title: I18n.t('v4/irrigation_management.describer_index.area') },
  { field: 'hydricStatus', title: I18n.t('v4/irrigation_management.describer_index.hydric_status') },
  { field: 'wa', title: I18n.t('v4/irrigation_management.describer_index.wa') },
  {
    field: 'accumulated_water_loss', title:
      I18n.t('v4/irrigation_management.describer_index.accumulated_water_loss')
  },
  { field: 'di', title: I18n.t('v4/irrigation_management.describer_index.di') },
  { field: 'final_time', title: I18n.t('v4/irrigation_management.describer_index.final_time') }
]

const filteredDescriber = () => (
  describer.filter(description =>
    description.field !== 'area' && description.field !== 'hydricStatus'
  )
)

const validDate = date => isValidDate(new Date(date))

const isValidDate = date => date instanceof Date && !isNaN(date)

const getDates = data => {
  const today = moment().format('YYYY-MM-DD')

  const dates = data.map(
    element => Object.keys(element)
      .filter(validDate)
  ).flat()

  return removeRepeatedDates([today, ...dates])
}

const removeRepeatedDates = (dates) => {
  let selectedDates = []

  dates.forEach(date => {
    if (selectedDates.some(selectedDate => areSameDate(selectedDate, date))) return

    selectedDates.push(date)

  })

  return selectedDates
}

const areSameDate = (date1, date2) => convertToLocaleDate(date1) === convertToLocaleDate(date2)

export const irrigationFields = [
  {
    idType: 'advanced',
    fields: [
      {
        label: I18n.t('v4/irrigation_management.fields.optimal_water_loss'),
        required: true,
        type: "select",
        name: "optimal_water_loss",
        info: I18n.t('v4/irrigation_management.fields.optimal_water_loss_info'),
        data: [
          { name: I18n.t('v4/irrigation_management.field_data.optimal_water_loss.75%_afd'), value: '75_afd' },
          { name: I18n.t('v4/irrigation_management.field_data.optimal_water_loss.50%_afd'), value: '50_afd' },
          { name: I18n.t('v4/irrigation_management.field_data.optimal_water_loss.25%_afd'), value: '25_afd' },
          { name: I18n.t('v4/irrigation_management.field_data.optimal_water_loss.fixed'), value: 'fixed_limit_mm' }
        ],
        fields: { name: 'name', id: 'value' },
        altValue: {
          type: 'number',
          dependency: 'fixed_limit_mm',
          label: I18n.t('v4/irrigation_management.field_data.optimal_water_loss.fixed'),
          default: 0
        }
      },
      {
        label: I18n.t('v4/irrigation_management.fields.type_irrigation_interval'),
        required: true,
        type: "select",
        name: "type_irrigation_interval",
        info: I18n.t('v4/irrigation_management.fields.type_irrigation_interval_info'),
        data: [
          { name: I18n.t('v4/irrigation_management.field_data.type_irrigation_interval.variable'), value: 'variable_irrigation_shift' },
          { name: I18n.t('v4/irrigation_management.field_data.type_irrigation_interval.fixed'), value: 'fixed_shift' },
        ],
        fields: { name: 'name', id: 'value' },
        altValue: {
          type: 'number',
          dependency: 'fixed_shift',
          label: I18n.t('v4/irrigation_management.field_data.type_irrigation_interval.days'),
          default: 1
        }
      },
      {
        label: I18n.t('v4/irrigation_management.fields.proportion_moist_soil'),
        type: 'number',
        name: 'proportion_moist_soil',
        required: true,
        min: 0,
        max: 100,
        info: I18n.t('v4/irrigation_management.fields.proportion_moist_soil_info')
      }
    ]
  },
  {
    idType: 'simplified',
    fields: [
      {
        label: I18n.t('v4/irrigation_management.fields.optimal_water_loss'),
        required: true,
        type: "select",
        name: "optimal_water_loss",
        info: I18n.t('v4/irrigation_management.fields.optimal_water_loss_info'),
        data: [
          { name: I18n.t('v4/irrigation_management.field_data.optimal_water_loss.75%_afd'), value: '75_afd' },
          { name: I18n.t('v4/irrigation_management.field_data.optimal_water_loss.50%_afd'), value: '50_afd' },
          { name: I18n.t('v4/irrigation_management.field_data.optimal_water_loss.25%_afd'), value: '25_afd' },
          { name: I18n.t('v4/irrigation_management.field_data.optimal_water_loss.fixed'), value: 'fixed_limit_mm' }
        ],
        fields: { name: 'name', id: 'value' },
        altValue: {
          type: 'number',
          dependency: 'fixed_limit_mm',
          label: I18n.t('v4/irrigation_management.field_data.optimal_water_loss.fixed'),
          default: 0
        }
      },
      {
        label: I18n.t('v4/irrigation_management.fields.type_irrigation_interval'),
        required: true,
        type: "select",
        name: "type_irrigation_interval",
        info: I18n.t('v4/irrigation_management.fields.type_irrigation_interval_info'),
        data: [
          { name: I18n.t('v4/irrigation_management.field_data.type_irrigation_interval.variable'), value: 'variable_irrigation_shift' },
          { name: I18n.t('v4/irrigation_management.field_data.type_irrigation_interval.fixed'), value: 'fixed_shift' },
        ],
        fields: { name: 'name', id: 'value' },
        altValue: {
          type: 'number',
          dependency: 'fixed_shift',
          label: I18n.t('v4/irrigation_management.field_data.type_irrigation_interval.days'),
          default: 1
        }
      },
      {
        label: I18n.t('v4/irrigation_management.fields.proportion_moist_soil'),
        type: 'number',
        name: 'proportion_moist_soil',
        required: true,
        min: 0,
        max: 100,
        info: I18n.t('v4/irrigation_management.fields.proportion_moist_soil_info')
      }
    ]
  }
]

const requiredFields = ['culture', 'soil_analysis_id', 'irrigation_system', 'v4_area_id']

export const validate = (values) => {
  const errors = {}

  requiredFields.forEach(field => {
    if (!values[field]) errors[field] = I18n.t('validations.required')
  })

  return errors
}

export const irrigationManagementsStatus = keyMirror({
  created: null,
  started: null,
  finished: null
})

export const updateManagement = async (id, managementData, httpService) => {
  const { data } = await httpService.request.put(`irrigation_managements/${id}`, { irrigation_management: managementData })

  return data
}

export const isFinished = (management = {}) => management.status === irrigationManagementsStatus.finished

export const isNotFinished = (management = {}) => !isFinished(management)

export const isCreated = (management = {}) => management.status === irrigationManagementsStatus.created

const getIntercomTags = () => ({
  table: 'visualization-table-aqua',
  card: 'visualization-cards-aqua',
  planning: 'visualization-planning-aqua'
})

export const AddIntecomTag = (visualizations) =>
  visualizations.map(visualization => ({
    ...visualization,
    intercomTag: getIntercomTags()[visualization.id]
  }))

const getHeaders = (translatedHeaders) => {
  const translationGroup = I18n.t('v4/irrigation_management.describer_index')
  let headers = []

  Object.keys(translationGroup).forEach(key => {
    if (!translatedHeaders.includes(translationGroup[key])) {
      delete translationGroup[key]
    } else {
      headers.push({
        title: key === 'hydric_status' ? 'hydricStatus' : key,
        index: translatedHeaders.indexOf(translationGroup[key])
      })
    }
  })

  return _.sortBy(headers, ['index']).map(header => header.title)
}

const getTableValues = (dates, data, header, index) => {
  let currentCells = []

  dates.forEach(date => {
    currentCells.push({
      v: isNaN(data[index][date][header])
        ? I18n.t(`v4/irrigation_management.hydric_status.${data[index][date][header]}`)['title_reduced']
        : data[index][date][header] === null
          ? '---'
          : header === 'final_time'
            ? parseDecimalToTime(data[index][date][header])
            : `${data[index][date][header]} mm`,

      t: ''
    })
  })

  return currentCells
}

export const exportTable = (data) => {
  const table = TableExport(document.getElementsByTagName('table'), {
    exportButtons: false,
    filename: I18n.t('v4/irrigation_management.visualizations.planning'),
    formats: ['xlsx'],
    bootstrap: true
  })

  const exportData = table.getExportData()['tableexport-1'].xlsx

  const translatedHeaders = data[0].vars

  let newCells = []

  data.forEach((currentArea, index) => {
    let currentCells = []

    const dates = Object.keys(data[index]).filter(date => !!data[index][date]['hydricStatus'])

    if (!_.isEmpty(dates)) {
      getHeaders(translatedHeaders).forEach((header, _index) => {
        currentCells.push({ v: _index === 0 ? currentArea.area : '', t: 's' })
        currentCells.push({ v: translatedHeaders[_index], t: 's' })

        newCells.push([...currentCells, ...getTableValues(dates, data, header, index)])

        currentCells = []
      })
    }
  })

  const headers = exportData.data[0].map(header => ({ v: header.v, t: 's' }))

  exportData.data = [headers, ...newCells]

  table.reset()

  table.export2file(
    exportData.data,
    exportData.mimeType,
    exportData.filename,
    exportData.fileExtension,
    exportData.merges
  )
}
