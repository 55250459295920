import React from 'react'

import TextField from '@ui/Fields/TextField'

import { Button, Typography } from '@material-ui/core'
import { handleForecastSetup } from '@models/weather/forecast'
import { isValidCoordinates } from '@models/coordinates'
import LoadingComponent from '@ui/LoadingComponent'

import withStyles from '@material-ui/core/styles/withStyles'
import styles from '@theme/styles/forms'

class ForecastPointsForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      loading: false,
      values: {
        lat: '',
        lon: '',
        name: '',
        set_up: true
      },
      errors: {
        lat: {
          touched: false,
          error: null
        },
        lon: {
          touched: false,
          error: null
        }
      }
    }
  }

  componentDidMount() {
    const { onRef } = this.props
    onRef && onRef(this)
  }

  handleFormChange = ({ target }) => {
    const { name, value } = target
    const { values } = this.state

    this.setState({
      values: {
        ...values,
        [name]: value
      }
    })
  }

  handleSubmit = async (values) => {
    const { handleError, handleForecastUpdate, forecastPoint, organizationId } = this.props

    if (!this.validateLatLng(values)) return

    confirmDialog(async () => {
      this.setState({ loading: true })

      try {
        const updatedForecast = await handleForecastSetup({ values, forecastPoint, organizationId })

        handleForecastUpdate(updatedForecast)
      }
      catch (error) {
        handleError(error)

        this.setState({ loading: false })
      }
    }, {
      title: I18n.t('v4/forecast.configuration.title'),
      content: [
        I18n.t('v4/forecast.configuration.confirmation_location'),
        I18n.t('v4/forecast.configuration.confirmation')
      ]
    })
  }

  setLatLng({ lat, lng }) {
    const { values } = this.state

    this.setState({
      values: {
        ...values,
        lat: _.round(lat, 7),
        lon: _.round(lng, 7)
      }
    }, () => this.touchLatLonFields())
  }

  touchLatLonFields = () => {
    const { errors } = this.state

    this.setState({
      errors: {
        ...errors,
        lat: {
          ...errors.lat,
          touched: false
        },
        lon: {
          ...errors.lon,
          touched: false
        }
      }
    })
  }

  validateLatLng = (values) => {
    if (isValidCoordinates(values)) return true

    this.setState({
      errors: {
        lat: {
          error: I18n.t('v4/forecast.configuration.invalid_location'),
          touched: true
        },
        lon: {
          error: I18n.t('v4/forecast.configuration.invalid_location'),
          touched: true
        }
      }
    })

    return false
  }

  render() {
    const { classes } = this.props
    const { loading, errors } = this.state
    const { lat, lon, name } = this.state.values

    return <form
      className={classes.form}
      onSubmit={e => {
        e.preventDefault()

        this.handleSubmit(this.state.values)
      }}
      data-testid='forecast-points-form'
    >
      <Typography component='h5' variant='h5'>
        {I18n.t('v4/forecast.configuration.title')}
      </Typography>

      <TextField
        className={classes.form__fieldWithError}
        disabled={loading}
        fullWidth
        InputLabelProps={{ shrink: true }}
        label={I18n.t(`activerecord.attributes.v4/organization_products.forecast.forecast_points.name`)}
        margin='normal'
        name='name'
        onChange={this.handleFormChange}
        required
        value={name}
        variant='outlined'
      />


      <div className={classes.form__inline_fields}>
        <TextField
          className={classes.form__inlineField}
          disabled={loading}
          fullWidth
          InputLabelProps={{ shrink: true }}
          inputProps={{ step: '0.00000001' }}
          label={I18n.t('activerecord.attributes.v4/organization_products.forecast.forecast_points.lat')}
          margin='normal'
          meta={errors.lat}
          name='lat'
          onChange={this.handleFormChange}
          onInput={this.touchLatLonFields}
          placeholder={I18n.t('v4/forecast.configuration.placeholder')}
          required
          type='number'
          value={lat}
          variant='outlined'
        />

        <TextField
          className={classes.form__inlineField}
          disabled={loading}
          fullWidth
          InputLabelProps={{ shrink: true }}
          inputProps={{ step: '0.00000001' }}
          label={I18n.t('activerecord.attributes.v4/organization_products.forecast.forecast_points.lon')}
          margin='normal'
          meta={errors.lon}
          name='lon'
          onChange={this.handleFormChange}
          onInput={this.touchLatLonFields}
          placeholder={I18n.t('v4/forecast.configuration.placeholder')}
          required
          type='number'
          value={lon}
          variant='outlined'
        />
      </div>

      {loading && <LoadingComponent />}

      <footer className={classes.form__actions}>
        <Button
          className={classes.form__action}
          color='primary'
          disabled={loading}
          type='submit'
          variant='contained'
        >
          {I18n.t('v4/forecast.configuration.update')}
        </Button>
      </footer>
    </form>
  }
}

export default withStyles(styles)(ForecastPointsForm)
