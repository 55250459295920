import React from 'react'
import PropTypes from 'prop-types'

import { RailsForm } from '@support/RailsForm'
import { Form as FinalForm } from 'react-final-form'
import { validate, getManualTypes } from '@models/equipment/manual'
import EquipmentForm from '../Form'
import httpService from '@services/httpService'
import Form from './Form'

const New = props => {
  const { kind } = props.model
  const { o, sensor_configs, handleError } = props
  
  const handleSubmit = async (values) => {
    const sensor = sensor_configs.find(sensor => sensor.id === values.equipment.sensor_config_id)
    values.equipment['sensors'] =  sensor.sensors.map(sensor => sensor.id)  
    
    try {
      await httpService.post(`/v4/o/${o}/equipments/${kind}`, values)  
      Turbolinks.visit(`/v4/o/${o}/equipments/${kind}`)
    }
    catch(error){
      handleError(error)
    }
  }
  
  return (
    <RailsForm
      component={FinalForm}
      action="create"
      handleSubmit={handleSubmit}
      validate={validate}
      initialValues={{ kind, equipment: {} }}
      render={props => (
        <EquipmentForm
          title={`${I18n.t('actions.add')} ${I18n.t(`v4/equipment.kinds.${kind}`)}`}
        >
          <Form
            {...props}
            sensor_configs={sensor_configs}
            selectValues={{
              manualTypes: getManualTypes()
            }}
            action={I18n.t('actions.add')}
            o={o}
          />
        </EquipmentForm>
      )}
    />
  )
}

New.propTypes = {
  kind: PropTypes.string
}

export default New
