import React from 'react'

import { Typography, withStyles } from '@material-ui/core'
import { parseDecimalToTime } from '@services/locale'

const CardContentContainer = ({ classes, data }) => (
  <div className={classes.Content_Container}>
    {
      data.map((datum, index) => (
        <CardContent
          data={datum}
          classes={classes}
          key={`${datum.label}__${datum.value}__${index}`}
        />
      ))
    }
  </div>
)

const CardContent = ({ classes, data }) => (
  <div className={classes.Content}>
    <Typography variant='body1' className={classes.Content_Text}>
      {I18n.t(`v4/irrigation_management.card_labels.${data.name}`)}
    </Typography>

    <Typography variant='body2' className={classes.Content_Value}>
      {
        data.value !== null
          ? data.name === 'final_speed_relay'
            ? `${data.value} %`
            : data.name === 'final_time'
              ? parseDecimalToTime(data.value)
              : `${data.value} mm`
          : '---'
      }
    </Typography>
  </div>
)

const styles = theme => ({
  Content_Container: {
    marginBottom: theme.spacing.unit * 2,
  },
  Content: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    paddingTop: theme.spacing.unit,
    paddingBottom: theme.spacing.unit
  },
  Content_Text: {
    flex: '1',
    fontSize: '10.5pt'
  },
  Content_Value: {
    flex: '1',
    textAlign: 'right'
  },
})

export default withStyles(styles)(CardContentContainer)
