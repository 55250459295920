import React, { Fragment } from 'react'

import { Grid, Typography } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'

import WindDirectionIcon from '@images/weather-forecast/wi-wind-speed.svg'
import CloudyIcon from '@images/weather-forecast/wi-cloudy.svg'
import CloudyDayIcon from '@images/weather-forecast/wi-day-cloudy.svg'
import RainDayIcon from '@images/weather-forecast/wi-day-rain.svg'
import RainIcon from '@images/weather-forecast/wi-rain.svg'
import SunnyDayIcon from '@images/weather-forecast/wi-day-sunny.svg'
import SunnyOvercastDayIcon from '@images/weather-forecast/wi-day-sunny-overcast.svg'
import ThunderstormDayIcon from '@images/weather-forecast/wi-day-thunderstorm.svg'
import ThunderstormIcon from '@images/weather-forecast/wi-day-thunderstorm.svg'
import VeryLowIcon from '@images/weather-forecast/wi-no-probability.svg'
import LowIcon from '@images/weather-forecast/wi-low-probability'
import MedIcon from '@images/weather-forecast/wi-med-probability.svg'
import HighIcon from '@images/weather-forecast/wi-high-probability.svg'
import VeryHighIcon from '@images/weather-forecast/wi-very-high-probability.svg'
import WithoutRainIcon from '@images/weather-forecast/pluvi-without-rain.svg'
import RainHighIcon from '@images/weather-forecast/pluvi-rain-high.svg'
import RainVeryHighIcon from '@images/weather-forecast/pluvi-rain-very-high.svg'

import ApplicationCondition from './ApplicationCondition'
import { useRouter } from '@contexts/RouterProvider'

const Card = ({ classes, values, header, sprayingCondition }) => {
  const colors = {
    very_high: 'veryHigh',
    high: 'high',
    moderate: 'high',
    low: 'high',
    very_low: 'low'
  }

  const weatherIcons = {
    'wi-cloudy': CloudyIcon,
    'wi-day-cloudy': CloudyDayIcon,
    'wi-day-rain': RainDayIcon,
    'wi-rain': RainIcon,
    'wi-day-sunny': SunnyDayIcon,
    'wi-day-sunny-overcast': SunnyOvercastDayIcon,
    'wi-day-thunderstorm': ThunderstormDayIcon,
    'wi-thunderstorm': ThunderstormIcon,
  }

  const rainIcons = {
    very_high: VeryHighIcon,
    high: HighIcon,
    moderate: MedIcon,
    low: LowIcon,
    very_low: VeryLowIcon
  }

  const pluviRainIcons = {
    very_high: RainVeryHighIcon,
    high: RainHighIcon,
    moderate: RainHighIcon,
    low: RainHighIcon,
    very_low: WithoutRainIcon
  }

  const router = useRouter()

  const hasSprayingCondition = !_.isEmpty(sprayingCondition)

  return (
    <Grid container spacing={0}
      className={
        hasSprayingCondition
          ? `${classes.Card_wrapper} ${classes.Card_link}`
          : classes.Card_wrapper
      }
      direction='row'
      onClick={() => hasSprayingCondition && router.visit('/weather')}
    >
      <Grid item xs={2} container direction='column'>
        <Grid item xs>
          <Typography variant="body2" className={classes.Card_day} align='center' classes={{ body2: classes.Header_body }}>
            {header.shortDay}
          </Typography>
          <Typography variant="body2" className={classes.Card_lightFont_gray} align='center' classes={{ body2: classes.Header_body }}>
            {header.date}
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={2} container direction='column'>
        <img
          src={weatherIcons[header.icon]}
          className={classes.Card_icon}
        />
      </Grid>

      <Grid item xs={1} container direction='column'>
        <Grid item xs>
          <Typography variant="body2" className={classes.Card_max} classes={{ body2: classes.Header_body }}>
            {I18n.t('v4/forecast.max')}
          </Typography>
          <Typography variant="body2" className={classes.Card_min} classes={{ body2: classes.Header_body }}>
            {I18n.t('v4/forecast.min')}
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={1} container direction='column'>
        <Grid item xs className={classes.Card_temperature}>
          <Typography variant="body2" className={classes.Card_font} classes={{ body2: classes.Header_body }}>
            {values.maxTemp.value}
          </Typography>
          <Typography variant="body2" className={classes.Card_font} classes={{ body2: classes.Header_body }}>
            {values.minTemp.value}
          </Typography>
        </Grid>
      </Grid>

      <Grid item xs={6} container className={classes.Card_column}>
        <Grid item xs={12}>
          <Typography
            variant="body2"
            className={classes[`Card_rainChance_${colors[values.rainChance.chance]}`]}
            classes={{ body2: classes.Header_body }}
          >
            <img
              src={rainIcons[values.rainChance.chance]}
              className={classes.Card_miniIcon}
            />
            {
              `${I18n.t(`v4/forecast.${values.rainChance.chance}_chance`)}
              (${values.rainChance.pop}%)`
            }
          </Typography>

          <Grid item xs={12} container direction='row'>
            <Grid item xs={6}>
              <Typography
                variant="body2"
                className={classes[`Card_rainValue_${colors[values.rainChance.chance]}`]}
                classes={{ body2: classes.Header_body }}
              >
                <img
                  src={pluviRainIcons[values.rainChance.chance]}
                  className={classes.Card_miniIcon}
                />
                {values.rain.value}
                <span
                  className={classes[`Card_rainChance_${colors[values.rainChance.chance]}`]}
                >
                  mm
                </span>
              </Typography>
            </Grid>
            <Grid item xs={6}>
              <Typography variant="body2" classes={{ body2: classes.Header_body }}>
                <img
                  src={WindDirectionIcon}
                  className={classes.Card_miniIcon}
                />
                {values.wind.value}
                <span className={classes.Card_lightFont}>
                  km/h
                </span>
              </Typography>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      {
        hasSprayingCondition &&
        <ApplicationCondition sprayingCondition={sprayingCondition} />
      }
    </Grid>
  )
}

const styles = (theme) => ({
  Card_wrapper: {
    padding: `${theme.spacing.unit + 2}px 0`
  },
  Card_day: {
    textTransform: 'capitalize'
  },
  Card_lightFont: {
    fontWeight: 400,
  },
  Card_lightFont_gray: {
    fontWeight: 400,
    color: theme.palette.gray.dark,
  },
  Card_temperature: {
    margin: `0 ${theme.spacing.unit}px`
  },
  Card_max: {
    fontWeight: 400,
    color: theme.palette.forecast.red,
  },
  Card_min: {
    fontWeight: 400,
    color: theme.palette.forecast.blue,
  },
  Card_icon: {
    width: '90%',
    color: theme.palette.forecast.darkBlue
  },
  Card_miniIcon: {
    marginRight: theme.spacing.unit - 3,
    width: 15
  },
  Card_column: {
    paddingLeft: (theme.spacing.unit * 2) + 4
  },
  Card_rainChance_veryHigh: {
    fontWeight: 400,
    color: theme.palette.forecast.darkBlue
  },
  Card_rainChance_high: {
    fontWeight: 400,
    color: theme.palette.forecast.blue,
  },
  Card_rainChance_low: {
    fontWeight: 400,
    color: theme.palette.forecast.gray,
  },
  Card_rainValue_veryHigh: {
    color: theme.palette.forecast.darkBlue
  },
  Card_rainValue_high: {
    color: theme.palette.forecast.blue
  },
  Card_rainValue_low: {
    color: theme.palette.forecast.gray,
  },
  Header_body: {
    fontSize: '10.2pt'
  },
  Card_link: {
    cursor: 'pointer'
  }
})

export default withStyles(styles)(Card)
