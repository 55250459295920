import httpService from '@services/httpService'

export const fields = [
  'name', 'active'
]

export const validate = values => {
  const requiredFields = [
    'name',
    'description_pt',
    'description_en',
    'description_es'
  ]
  const errors = {}

  requiredFields.forEach(field => {
    if (!values[field]) errors[field] = I18n.t('validations.required')
  })

  return errors
}

export const getTermsHeaders = () =>
  fields.map(field => ({
    title: I18n.t(`activerecord.attributes.v4/terms.${field}`),
    field,
    render: rowData =>
      field === 'active'
        ? I18n.t(`activerecord.attributes.v4/terms.status_${rowData['active'].toString()}`)
        : rowData[field]
  }))


export const termsActionsHandler = (id, tenantId, handleError, o) => async () => {
  const actionUrl = `/v4/o/${o}/admin/tenants/${tenantId}/terms/${id}/activate`

  await httpService
    .patch(actionUrl)
    .then(() =>
      Turbolinks.visit(`/v4/o/${o}/admin/tenants/${tenantId}/terms`)
    )
    .catch((error) =>
      handleError(error)
    )
}
