import React from 'react'

import { Form as FinalForm } from 'react-final-form'
import { RailsForm } from '@support/RailsForm'

import { Button, Grid, Typography, Paper, Divider, IconButton } from '@material-ui/core'
import { Add, Create, Delete, EmailOutlined as EmailIcon } from '@material-ui/icons'
import { withStyles } from '@material-ui/core/styles'

import WhatsAppIcon from '@ui/Icons/WhatsApp'
import Dialog from '@ui/Dialog'
import DeleteDialog from '@ui/DeleteDialog'

import { getLocales, getFieldType, validate } from '@models/alerts'
import Form from './MediaForm'

const ReceiveAlert = ({
  classes,
  toggleMedia,
  data,
  closeDialog,
  handleDeleteDialogToggle,
  handleSubmit,
  handleContact,
  handleChangeEnabled
}) => {
  const statusMessage = (mediaType) =>
    <Typography variant="caption" className={classes.Check_alert}>
      {
        data.medias[mediaType]
          ? I18n.t('v4/alerts.new.steps.receive_alert.checked')
          : I18n.t('v4/alerts.new.steps.receive_alert.unchecked')
      }
    </Typography>

  return (
    <Grid container className={classes.WrapperMedias} spacing={16}>
      <Grid item xs={12} className={classes.WrapperButton}>
        <MediaButton
          classes={classes}
          enabled={data.medias.whatsapp}
          toggleMedia={toggleMedia}
          media='whatsapp'
        >
          <span className={classes.Check_alert}>
            {I18n.t('v4/alerts.new.steps.receive_alert.whatsapp')}
            <WhatsAppIcon style={{ width: 24 }} className={classes.Button_icon} />
          </span>
          {statusMessage('whatsapp')}
        </MediaButton>
      </Grid>
      {
        data.medias.whatsapp &&
        <Grid item xs={12} className={classes.WrapperButton}>
          <ContactCard
            classes={classes}
            title={I18n.t('v4/alerts.new.steps.receive_alert.whatsapp')}
            handleContact={handleContact}
            actions={data.whatsapp}
            actionKind={'whatsapp'}
          />
        </Grid>
      }

      <Grid item xs={12} className={classes.WrapperButton}>
        <MediaButton
          classes={classes}
          enabled={data.medias.email}
          toggleMedia={toggleMedia}
          media='email'
        >
          <span className={classes.Check_alert}>
            {I18n.t('v4/alerts.new.steps.receive_alert.email')}
            <EmailIcon className={classes.Button_icon} />
          </span>
          {statusMessage('email')}
        </MediaButton>
      </Grid>

      {
        data.medias.email &&
        <Grid item xs={12} className={classes.WrapperButton}>
          <ContactCard
            classes={classes}
            title={I18n.t('v4/alerts.new.steps.receive_alert.email')}
            handleContact={handleContact}
            actions={data.email}
            actionKind={'email'}
          />
        </Grid>
      }

      <Dialog
        open={data.isDialogOpen}
        onClose={() => closeDialog('isDialogOpen')}
        onCancel={() => closeDialog('isDialogOpen')}
        maxWidth='xs'
        fullScreen={false}
        title={
          _.isEmpty(data.selected)
            ? `${I18n.t('actions.add')} ${I18n.t('v4/alerts.new.steps.receive_alert.contact.title')}`
            : `${I18n.t('actions.edit')} ${I18n.t('v4/alerts.new.steps.receive_alert.contact.title')}`
        }
      >
        <RailsForm
          component={FinalForm}
          handleSubmit={handleSubmit}
          initialValues={data.selected}
          validate={validate()[data.actionKind]}
          render={(props) => (
            <Form
              {...props}
              locales={getLocales()}
              onCancel={() => closeDialog('isDialogOpen')}
              kindContact={data.actionKind}
              action={_.isEmpty(data.selected) ? I18n.t('actions.add') : I18n.t('actions.edit')}
              handleChangeEnabled={handleChangeEnabled}
              enabled={data.enabled}
              fieldType={getFieldType(data.actionKind)}
            />
          )}
        />
      </Dialog>

      <DeleteDialog
        dialogTitle={I18n.t('actions.remove')}
        dialogText={I18n.t('confirmation.remove')}
        onCancel={() => closeDialog('isDeleteDialogOpen')}
        onClose={() => closeDialog('isDeleteDialogOpen')}
        open={data.isDeleteDialogOpen}
        handleDelete={handleDeleteDialogToggle}
        maxWidth='xs'
        fullScreen={false}
      />
    </Grid>
  )
}

const MediaButton = ({ classes, children, enabled, toggleMedia, media }) =>
  <Button
    variant='contained'
    className={enabled ? classes.Button__enabled : classes.Button__disabled}
    onClick={() => toggleMedia(media)}
    classes={{ label: classes.Button_label }}
  >
    {children}
  </Button>

const ContactCard = ({ classes, title, handleContact, actions, actionKind }) =>
  <Paper className={classes.Card_wrapper}>
    <div className={classes.Card_header}>
      <Typography className={classes.Card_title} variant='h5'>
        {title}
      </Typography>
      <IconButton
        title={I18n.t('actions.add')}
        onClick={() => handleContact(actionKind, 'isDialogOpen')}
      >
        <Add />
      </IconButton>
    </div>

    {
      !_.isEmpty(actions)
        ? <>
          {
            actions.map((action, index) =>
              <Grid container key={`action_${index}`}>
                <Grid item xs={12} className={classes.CardContact_wrapper}>
                  <div>
                    <Typography variant='body1' className={classes.Card_info}>
                      {action.name}
                    </Typography>
                    <Typography variant='body1' className={classes.Card_info}>
                      {action.identifier}
                    </Typography>
                    <Typography variant='body1' className={classes.Card_info}>
                      {action.language}
                    </Typography>
                  </div>

                  <div className={classes.Card_wrapper_icon}>
                    <IconButton
                      title={I18n.t('actions.edit')}
                      onClick={() => handleContact(actionKind, 'isDialogOpen', { ...action, index })}
                    >
                      <Create />
                    </IconButton>

                    <IconButton
                      title={I18n.t('actions.delete')}
                      onClick={() => handleContact(actionKind, 'isDeleteDialogOpen', { ...action, index })}
                    >
                      <Delete />
                    </IconButton>
                  </div>
                </Grid>

                {
                  index < actions.length - 1
                    ? <Grid item xs={12} style={{ height: 20 }}>
                      <Divider />
                    </Grid>
                    : null
                }
              </Grid>
            )}
        </>
        : <Typography variant='body1' align='center'>
          {I18n.t('info.no_data')}
        </Typography>
    }
  </Paper >

const styles = theme => ({
  WrapperMedias: {
    paddingTop: 20
  },
  WrapperButton: {
    display: 'flex',
    justifyContent: 'center'
  },
  Button__enabled: {
    width: '70%',
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: 200,
    background: theme.palette.primary.main,
    '&:hover': {
      background: theme.palette.primary.dark
    }
  },
  Button__disabled: {
    width: '70%',
    display: 'flex',
    flexWrap: 'wrap',
    maxWidth: 200,
    background: theme.palette.gray.dark,
    '&:hover': {
      background: '#5d5d5d'
    }
  },
  Button_icon: {
    marginLeft: 10
  },
  Button_label: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  Check_alert: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: 'inherit',
    color: theme.palette.white
  },
  Check: {
    fontSize: 20,
    marginRight: 5
  },
  Card_header: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  Card_wrapper: {
    width: '100%',
    padding: theme.spacing.unit + 2,
    borderRadius: '10px'
  },
  CardContact_wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  Card_button: {
    display: 'flex',
  },
  Card_title: {
    fontSize: '18px'
  },
  Card_info: {
    fontSize: '13px'
  },
  Card_wrapper_icon: {
    display: 'flex'
  }
})

export default withStyles(styles)(ReceiveAlert)
