export default (theme) => ({
  menu__info__wrapper__fade: {
    zIndex: 1050,
    position: 'absolute',
    display: 'flex',
    top: 30,
    '@media (max-width: 410px)': {
      left: 5,
      right: 5,
    },
    '@media (min-width: 411px)': {
      left: 20,
      right: 20,
    },
    '@media (min-width: 501px)': {
      left: 30,
      right: 'auto'
    }
  },
  menu__info__container: {
    background: theme.palette.background.default,
    maxHeight: 'calc(100vh - 75px)',
    overflow: 'auto',
    borderRadius: '10px',
    position: 'relative',
    width: '100%',
    '@media (min-width: 501px)': {
      width: theme.spacing.unit * 49
    }
  },
  menu__info__header: {
    background: theme.palette.secondary.light
  },
  menu__info__header__name: {
    color: '#FFF',
    fontWeight: 500
  },
  close__info__menu: {
    position: 'absolute',
    zIndex: 1,
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.black,
    cursor: 'pointer'
  },
  menu__wrapper__list: {
    paddingBottom: 20,
    width: 350
  },
  menu__info__list: {
    padding: 0,
    maxWidth: '100%'
  },
  menu__list__item: {
    '&:first-child': {
      padding: 0
    }
  },
  menu__empty__img: {
    height: 284,
    background: theme.palette.secondary.light,
    display: 'flex',
    justifyContent: 'center'
  },
  menu__img: {
    maxWidth: '100%'
  },
  menu__pic__icon: {
    fontSize: '10em',
    color: theme.palette.secondary.light,
    background: theme.palette.background.default,
    borderRadius: '50%',
    padding: 30
  },
  menu__info: {
    color: '#fff'
  },
  menu__edit__button: {
    textTransform: 'none',
    position: 'absolute',
    top: -30,
    right: 30,
    display: 'grid',
    background: 'none',
    '&:hover': {
      background: 'none'
    },
    '&:active': {
      background: 'none'
    },
    '&:focus': {
      background: 'none'
    },
    cursor: 'pointer'
  },
  menu__fab__edit: {
    background: theme.palette.background.default
  },
  menu__edit: {
    color: '#fff',
    fontWeight: 600,
    marginTop: 21,
    textAlign: 'center'
  },
  menu__body: {
    padding: '8px 24px'
  },
  menu__item: {
    color: "rgba(0, 0, 0, 0.87)",
    marginLeft: 20,
    fontSize: '0.8em'
  },
  menu__divider: {
    margin: '20px 0'
  },
  menu__actions: {
    display: 'flex',
    justifyContent: 'space-around',
  },
  menu__more__info: {
    padding: '10px 20px',
    display: 'flex',
  },
})
