import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'

import { deserializeData } from '@models/irrigation_managements/maps'

import CurrentIrrigationCard from './Cards/CurrentIrrigationCard'
import IrrigationForecastCard from './Cards/IrrigationForecastCard'
import LegendCard from './Cards/LegendCard'
import ResumeCard from './Cards/ResumeCard'
import { calculateNumberOfAreasThatNeedIrrigation, calculateGreatestDemandArea } from '@models/irrigation_managements/maps'

class IrrigationManagement extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedDate: {},
      forecast: [],
      currentDate: {},
      resume: {
        areasThatNeedIrrigation: 0,
        totalAreas: 0,
        greatestDemandArea: '--'
      }
    }
  }

  componentDidMount() {
    const { data } = this.props

    const [currentDate, ...forecast] = deserializeData(data)

    this.setState({
      currentDate,
      forecast
    })
  }

  componentDidUpdate(prevProps) {
    const { menuIsExpanded } = this.props
    const { currentDate } = this.state

    if (prevProps.menuIsExpanded && !menuIsExpanded) {
      this.handleSelectDate({})
    }

    if (!prevProps.menuIsExpanded && menuIsExpanded && currentDate) {
      this.handleSelectDate(currentDate)
    }
  }

  handleSelectDate = (date) => {
    const { changeAreasColors } = this.props

    this.setState(prevState =>
      prevState.selectedDate === date
        ? { selectedDate: {} }
        : { selectedDate: date }
      , () => {
        const { selectedDate } = this.state

        const { areasThatNeedIrrigation, totalAreas } = calculateNumberOfAreasThatNeedIrrigation(selectedDate.areas)
        const greatestDemandArea = calculateGreatestDemandArea(selectedDate.areas)

        this.setState({
          resume: {
            areasThatNeedIrrigation,
            totalAreas,
            greatestDemandArea
          }
        })

        changeAreasColors(selectedDate.areas)
      })
  }

  dateIsSelected = (date) =>
    !_.isEmpty(date)

  render() {
    const { classes, hasForecastProduct } = this.props
    const { forecast, currentDate, selectedDate, resume } = this.state

    return (
      <div className={classes.CardsList}>
        {this.dateIsSelected(selectedDate) && <LegendCard />}

        <IrrigationForecastCard
          hasForecastProduct={hasForecastProduct}
          selectedDate={selectedDate}
          forecast={forecast}
          onDateClick={this.handleSelectDate}
        />

        <CurrentIrrigationCard
          selectedDate={selectedDate}
          currentDate={currentDate}
          onDateClick={this.handleSelectDate}
        />

        <ResumeCard
          resume={resume}
        />
      </div>
    )
  }
}

IrrigationManagement.defaultProps = {
  data: []
}

const styles = theme => ({
  CardsList: {
    display: 'flex',
    padding: theme.spacing.unit
  }
})

export default withStyles(styles)(IrrigationManagement)
