import React from 'react'

import withStyles from '@material-ui/core/styles/withStyles'

import ProductsMenu from './ProductsMenu'
import OrganizationsMenu from './OrganizationsMenu'
import AdminMenu from './AdminMenu/index'
import Alert from './Alert/index'
import SupportMenu from './SupportMenu/index'

import styles from '../styles'

const RightGroupMenu = ({
  classes,
  products,
  organizations,
  currentOrganization,
  currentUser,
  currentTenant,
  alertsNotifications,
  router,
  handleError,
  currentView,
  expiredTrial
}) =>
  <div className={classes.container}>
    {currentUser.admin && <AdminMenu router={router} />}

    {currentTenant.showSupport && <SupportMenu router={router} />}

      <Alert
        router={router}
        handleError={handleError}
        products={products}
        currentOrganization={currentOrganization}
        {...alertsNotifications}
      />
      
    {
      (!_.isEmpty(products) || expiredTrial) &&
      <ProductsMenu
        activeProducts={products}
        currentView={currentView}
        expiredTrial={expiredTrial}
      />
    }

    <OrganizationsMenu
      currentOrganization={currentOrganization}
      organizations={organizations}
      currentUser={currentUser}
      router={router}
    />
  </div>

export default withStyles(styles)(RightGroupMenu);
