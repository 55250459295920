import React from 'react'

export const validate = values => {
  const requiredFields = [
    'first_name',
    'last_name',
    'email',
  ]
  const errors = {}

  requiredFields.forEach(field => {
    if (!values[field]) errors[field] = I18n.t('validations.required')
  })

  return errors
}

export const fields = () => [
  { name: 'first_name' },
  { name: 'last_name' },
  { name: 'email' },
  { name: 'locale', render: (locale) => I18n.t(`v4/users.languages.${locale}`) }
]

export const getUsersHeaders = () => {
  return [
    {
      field: 'first_name',
      title: I18n.t('v4/users.tabs.first_name'),
      render: rowData =>
        <span>
          {`${rowData.first_name || I18n.t('v4/users.guest')} ${rowData.last_name || ''}`}
        </span>
    },
    {
      field: 'email',
      title: I18n.t('v4/users.tabs.email'),
    }
  ]
}

export const userAvaliableLocales = [
  {
    id: 'pt-BR',
    iconId: 'br',
    name: I18n.t('activerecord.attributes.v4/organization.countries.br')
  },
  {
    id: 'es-MX',
    iconId: 'es',
    name: I18n.t('activerecord.attributes.v4/organization.countries.es')
  },
  {
    id: 'en',
    iconId: 'us',
    name: I18n.t('activerecord.attributes.v4/organization.countries.us')
  }
]

export const userFormFields = [
  'first_name',
  'last_name',
  'locale'
]
