export default theme => ({
  SatelliteImages: {
    alignItems: 'center',
    background: theme.palette.background.default,
    display: 'flex',
    padding: '10px 0',
    width: '100%'
  },
  SatelliteImages__filter_form: {
    minWidth: 360,
    margin: '0 15px',
    alignSelf: 'flex-start'
  },
  SatelliteImages__filter_form__area_select: {
    marginTop: '15px',
    marginBottom: '8px',
    height: 'fit-content',
    alignSelf: 'flex-start'
  },
  SatelliteImages__filter_form__clouds_slider: {
    marginTop: '16px',
    padding: '0 8px'
  },
  SatelliteImages__filter_form__dates: {
    display: 'flex'
  },
  SatelliteImages__filter_form__date: {
    '&:not(:first-child)': {
      marginLeft: '15px'
    }
  },
  SatelliteImages__filter_form__actions: {
    marginTop: '15px',
    textAlign: 'end'
  },
  SatelliteImages__cards: {
    maxWidth: '300px',
    display: 'flex',
    overflow: 'auto',
    padding: '10px',
    margin: '0 15px'
  },
  SampleCard: {
    cursor: 'pointer',
    padding: '10px',
    '&:not(:first-child)': {
      marginLeft: '15px'
    },
    borderRadius: '5%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    '&:hover': {
      outline: `1px solid ${theme.palette.primary.light}`
    }
  },
  SampleCard__selected: {
    background: theme.palette.primary.light,
    '&:hover': {
      outline: 'none'
    }
  },
  SampleCard__image: {
    height: '100px',
    width: '100px',
    marginBottom: '10px'
  },
})
