import React from 'react'
import { Switch, withStyles, Typography } from '@material-ui/core'

const ControlledSwitchField = ({
  classes,
  className,
  input,
  leftLabel,
  rightLabel,
  onSwitchClick
}) =>
  <div className={`${classes.SwitchField_Container} ${className}`}>
    <Typography className={input.value ? '' : classes.SwitchField_Item__Checked}>
      {leftLabel}
    </Typography>

    <Switch {...input} onClick={onSwitchClick} />

    <Typography className={input.value ? classes.SwitchField_Item__Checked : ''}>
      {rightLabel}
    </Typography>
  </div>


ControlledSwitchField.defaultProps = {
  leftLabel: '',
  rightLabel: '',
  className: ''
}

const styles = theme => ({
  SwitchField_Container: {
    display: 'flex',
    alignItems: 'center',
    paddingLeft: theme.spacing.unit * 2,
    textAlign: 'center'
  },
  SwitchField_Item__Checked: {
    color: theme.palette.primary.main
  }
})

export default withStyles(styles)(ControlledSwitchField)
