import React, { useEffect, useState } from 'react'

import { withStyles } from '@material-ui/core'

import Header from './Header'
import HoursTabs from './HoursTabs'
import Body from './Body'
import WrapperOdometer from './WrapperOdometer'
import Action from './Action'
import Battery from '../Equipment/Battery'
import Form from './Form'

import { Form as FinalForm } from 'react-final-form'

import { RailsForm } from '@support/RailsForm'

import Dialog from '@ui/Dialog'

import { useRouter } from '@contexts/RouterProvider'

const Hydrometer = ({
  classes,
  selectedEquipment,
  equipmentMenuData,
  handleSubmitReadingEstimate
}) => {
  const router = useRouter()
  const [dialog, setDialog] = useState(false)
  const [hourtab, setHourTab] = useState(0)
  const [loading, setLoading] = useState(false)
  const [hydrometer, setHydrometer] = useState({
    hoursWithFlow: '',
    hydrometerValue: '000000'
  })

  useEffect(() => {
    setTimeout(() => {
      setHydrometer({
        hydrometerValue: equipmentMenuData['hydrometer']['24hours']['water_volume'],
        hoursWithFlow: equipmentMenuData['hydrometer']['24hours']['hours_with_flow']
      })
    }, 300);
  }, []);

  const handleChangeTabs = (selecteTab, index) => {
    setHourTab(index)

    setHydrometer({
      hydrometerValue: equipmentMenuData['hydrometer'][selecteTab.key]['water_volume'],
      hoursWithFlow: equipmentMenuData['hydrometer'][selecteTab.key]['hours_with_flow']
    })
  }

  const handleDialogOpenOrClose = (value) => {
    setDialog(value)

    setLoading(value)
  }

  return (
    <>
      <div className={classes.ManualEquipment_wrapper}>
        <Header />

        <HoursTabs
          hourtab={hourtab}
          handleChangeTabs={handleChangeTabs}
        />

        <WrapperOdometer
          value={hydrometer.hydrometerValue}
        />

        <Body
          onChange={() => setDialog(true)}
          hoursWithFlow={hydrometer.hoursWithFlow}
          readingEstimate={equipmentMenuData.hydrometer.reading_estimate}
        />

        <Battery
          classes={classes}
          variable={{
            value:
              equipmentMenuData.variableValue['bv1'] ||
              equipmentMenuData.variableValue['value']
          }}
        />

        {
          !equipmentMenuData.hydrometer.reading_estimate &&
          <Action
            label={I18n.t('v4/equipment.card.set_reading_estimate')}
            variant='outlined'
            onChange={() => setDialog(true)}
            intercomTag={'edit-estimative-water-meter'}
          />
        }

        <Action
          label={I18n.t('v4/equipment.dashboard.actions.graphics')}
          variant='contained'
          onChange={() => router.visit(`equipments/${selectedEquipment.id}/dashboard`)}
          intercomTag={'telemetry-water-meter'}
        />
      </div>

      <Dialog
        open={dialog}
        onClose={() => setDialog(false)}
        title={I18n.t('v4/equipment.card.actions.edit')}
        maxWidth='xs'
      >
        <RailsForm
          component={FinalForm}
          handleSubmit={(values) => handleSubmitReadingEstimate(values, handleDialogOpenOrClose, selectedEquipment.id)}
          initialValues={{ reading_estimate: equipmentMenuData.hydrometer.reading_estimate }}
          render={
            (props) => (
              <Form
                onCancel={() => setDialog(false)}
                loading={loading}
                {...props}
              />
            )}
        />
      </Dialog>
    </>
  )
}

const Styles = theme => ({
  ManualEquipment_wrapper: {
    margin: theme.spacing.unit
  },
  Equipment_Battery: {
    display: 'flex',
    alignItems: 'center',
    marginTop: theme.spacing.unit * 2
  },
  Equipment_icons: {
    height: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit
  },
  Equipment_icon: {
    display: 'flex'
  }
})

export default withStyles(Styles)(Hydrometer)
