import React from 'react'

import { withStyles } from '@material-ui/core'

import { parseDecimalToTime } from '@services/locale'

const ObjectCell = ({ data, variablesFields, classes, ...props }) => {
  return (
    <div>
      {
        variablesFields.map(variablesField => {
          const localVariable = !data[variablesField.field] ? 'no_data' : data[variablesField.field]
          const formattedData = isNaN(data[variablesField.field])
            ? I18n.t(`v4/irrigation_management.hydric_status.${localVariable}.title_reduced`)
            : data[variablesField.field] === null
              ? '---'
              : variablesField.field === 'final_time'
                ? parseDecimalToTime(data[variablesField.field])
                : `${data[variablesField.field]} mm`


          return (
            <div
              key={`${variablesField.field}_${data[variablesField.field]}`}
              className={`
                ${classes.ObjectCell}
                ${classes[`ObjectCell_Content__${data[variablesField.field]}`]}
              `}
            >
              {formattedData}
            </div>
          )
        })
      }
    </div>
  )
}

const styles = theme => ({
  ObjectCell: {
    padding: `${theme.spacing.unit}px 0`
  },
  ObjectCell_Content__ideal_moisture: {
    fontWeight: 'bold',
    color: theme.palette.irrigationManagement.green
  },
  ObjectCell_Content__hydric_deficit: {
    fontWeight: 'bold',
    color: theme.palette.irrigationManagement.red
  },
  ObjectCell_Content__alert_moisture: {
    fontWeight: 'bold',
    color: theme.palette.irrigationManagement.yellow
  },
  ObjectCell_Content__saturated: {
    fontWeight: 'bold',
    color: theme.palette.irrigationManagement.blue
  }
})

export default withStyles(styles)(ObjectCell)
