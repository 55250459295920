import React from 'react'

import FlagIcon from '@support/FlagIcon.js'
import IconButton from '@material-ui/core/IconButton'

const SelectLocale = ({classes}) => {
  const countries = [
    { country: 'br', locale: 'pt-BR' },
    { country: 'us', locale: 'en' },
    { country: 'es', locale: 'es-MX' }
  ]

  const pathParams = (locale) => {
    if (window.location.search && !/l=pt-BR|l=en|l=es-MX/.test(window.location.search)) {
      return (`${window.location.search}&l=${locale}`)
    }
    if (window.location.search && /l=pt-BR|l=en|l=es-MX/.test(window.location.search)) {
      return (window.location.search.replace(/l=pt-BR|l=en|l=es-MX/, `l=${locale}`)) 
    }
    return (`?l=${locale}`)
  }

  return (
    <div className={classes.Login__Locale_flags} >
      {
        countries.map(({country, locale}) =>
          <IconButton
            className={classes.button}
            aria-label={`locale-${locale}`}
            onClick={() => window.location.replace(`${window.location.pathname}${pathParams(locale)}`)}
            key={locale}
          >
            <FlagIcon
              className={classes.Login__Locale_flag}
              code={country}
            />
          </IconButton>
        )
      }
    </div>
  )
}

export default SelectLocale
