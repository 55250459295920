import React from 'react'
import { Form as FinalForm } from 'react-final-form'
import PropTypes from 'prop-types'

import { RailsForm } from '@support/RailsForm'
import InputForm from '../Form'
import NewChemicalForm from './Form'
import { validate, chemicalShape } from '@models/inputs/chemical'

const NewChemical = ({ model, categories, formulations, o }) => {
  const initialValues = {
    category: categories[0].id,
    formulation: formulations[0].id
  }

  const title = `${I18n.t('actions.add')} ${I18n.t(`v4/input.kinds.${model.kind}`)}`

  return (
    <RailsForm
      component={FinalForm}
      action='create'
      initialValues={initialValues}
      url={`/v4/o/${o}/inputs/chemical`}
      validade={validate}
      render={(props) => (
        <InputForm
          title={title}
        >
          <NewChemicalForm
            {...props}
            action={title}
            categories={categories}
            formulations={formulations}
            o={o}
          />
        </InputForm>
      )}
    />
  )
}

NewChemical.propTypes = {
  categories: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  })),
  formulations: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.string,
    name: PropTypes.string
  })),
  model: chemicalShape
}

export default NewChemical
