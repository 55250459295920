import React from 'react'

import { withStyles } from '@material-ui/core/styles'
import { Card, Typography } from '@material-ui/core'
import Arrow from '@material-ui/icons/ArrowDropUp'

const i18nPrefixer = text => I18n.t(`v4/irrigation_system.chart_labels.${text}`)

const today = new Date()

const HydricStatus = ({ classes, areaChartsSeries, irrigationData }) => {
  const todayPoint = irrigationData.find(
    item => today.toLocaleDateString() === item.date
  )
  const status = todayPoint && todayPoint.hydric_status ? todayPoint.hydric_status : 'unavailable_data'

  return <Card className={classes.HydricStatus}>
    <Typography className={classes.HydricStatus_legend}>
      {i18nPrefixer('hydric_status')}
    </Typography>
    <div className={classes.HydricStatus_legends}>
      {
        areaChartsSeries.map(serie =>
          <div
            className={classes.HydricStatus_legend}
            key={serie.id}
          >
            <Typography
              className={classes.HydricStatus_legend}
              key={serie.id}
            >
              {i18nPrefixer(serie.name)}
            </Typography>

            <div
              className={classes.HydricStatus_color}
              style={{ background: serie.color }}
            >
              {
                status === serie.name &&
                <Arrow style={{ fontSize: 45 }} />
              }
            </div>
          </div>
        )
      }
    </div>
  </Card>
}

const styles = (theme) => ({
  HydricStatus: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    alignItems: 'center'
  },
  HydricStatus_legends: {
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap',
  },
  HydricStatus_color: {
    width: '100%',
    height: '20px',
    borderRadius: '3px',
    textAlign: 'center'
  },
  HydricStatus_legend: {
    margin: `0 ${theme.spacing.unit * 2}px`
  }
})

export default withStyles(styles)(HydricStatus)
