import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import ClearIcon from '@material-ui/icons/Clear'
import Divider from '@material-ui/core/Divider'
import IconButton from '@material-ui/core/IconButton'
import Grid from '@material-ui/core/Grid'
import List from '@material-ui/core/List'
import ListItem from '@material-ui/core/ListItem'
import Popover from '@material-ui/core/Popover'
import ShoppingCart from '@material-ui/icons/ShoppingCart'
import AddCircle from '@material-ui/icons/AddCircle'
import Image from '@material-ui/icons/Image'
import RemoveCircle from '@material-ui/icons/RemoveCircle'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

import styles from './styles'

class ItemsList extends Component {
  render() {
    const {
      classes,
      handleAdd,
      handleSubtract,
      handleRemove,
      itemsOnCart,
      total
    } = this.props

    return(
      <List className={classes.cart__list}>
        <div className={classes.cart__wrapper}>
          {itemsOnCart.map((item, i) =>
            <div key={`cart_item-${item.id}-${i}`}>
              <ListItem className={classes.cart__item}>
                <Grid container className={classes.root}>
                  <Grid item xs={2}>
                    <Image className={classes.image}/>
                  </Grid>
                  <Grid item xs={6}>
                    <Typography className={classes.cart__item__name}>
                      {item.name}
                    </Typography>
                    <Typography className={classes.cart__item__description}>
                      {item.description}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} className={classes.cart__quantity}>
                    <IconButton
                      className={classes.Navbar__Icon}
                      aria-owns={open ? 'render-props-popover' : null}
                      aria-haspopup="true"
                      variant="contained"
                      name="store-menu"
                      onClick={handleRemove(item, i)}
                    >
                      <ClearIcon />
                    </IconButton>
                  </Grid>
                  <Grid item xs={2}>
                  </Grid>
                  <Grid item xs={6} className={classes.cart__item__value__wrapper}>
                    <Typography className={classes.cart__item__value}>
                      {`R$ ${item.totalValue}`}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} className={classes.cart__quantity}>
                    <AddCircle
                      color="primary"
                      onClick={handleAdd(item)}
                      style={{cursor: 'pointer'}}
                    />
                    <Typography className={classes.cart__item__quantity}>
                      {item.quantity}
                    </Typography>
                    <RemoveCircle
                      color={item.quantity > 1 ? "primary" : "disabled"}
                      onClick={handleSubtract(item)}
                      style={{cursor: 'pointer'}}
                    />
                  </Grid>
                </Grid>
              </ListItem>
            </div>
          )}
        </div>
        <Divider />
        <ListItem className={classes.cart__item}>
          <Grid container className={classes.root}>
            <Grid item xs={8}>
              <Typography className={classes.cart__item__value}>
                Total
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography className={classes.cart__quantity}>
                {`R$ ${total}`}
              </Typography>
            </Grid>
          </Grid>
        </ListItem>
        <Divider />
        <ListItem className={classes.cart__item}>
          <Button variant="contained" color="primary">
            {I18n.t('v4/cart.checkout')}
          </Button>
        </ListItem>
      </List>
    )
  }
}

export default withStyles(styles)(ItemsList)
