import React      from 'react'

import Button     from '@material-ui/core/Button'
import Paper      from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import { Grid }   from '@material-ui/core'

import { Field }  from 'react-final-form'

import TextField  from '@ui/Fields/TextField'

import styles     from '@theme/styles/forms'

const Form = ({
  handleSubmit,
  classes,
  title,
  organization_id,
  o
}) => (
    <form className={classes.form} onSubmit={handleSubmit} data-testid="alert-settings-form">
      <Paper className={classes.form__wrapper} elevation={0}>
        <header className={classes.form__header}>
          <Typography component='h2' variant='h5'>
            {title}
          </Typography>
        </header>
        <main className={classes.form__fields}>
          <Grid
            container
            spacing={24}
          >
            <Grid item xs={6}>
              <Field
                component={TextField}
                className={classes.form__fieldWithError}
                margin='normal'
                fullWidth
                type='number'
                InputLabelProps={{ shrink: true }}
                variant='outlined'
                label={I18n.t('activerecord.attributes.v4/alert_product_configs.active_alerts_limit')}
                name='active_alerts_limit'
                required
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={TextField}
                className={classes.form__fieldWithError}
                margin='normal'
                fullWidth
                type='number'
                InputLabelProps={{ shrink: true }}
                variant='outlined'
                label={I18n.t('activerecord.attributes.v4/alert_product_configs.email_limit')}
                name='email_limit'
                required
              />
            </Grid>
          </Grid>
          <Grid
            container
            spacing={24}
          >
            <Grid item xs={6}>
              <Field
                component={TextField}
                className={classes.form__fieldWithError}
                margin='normal'
                fullWidth
                type='number'
                InputLabelProps={{ shrink: true }}
                variant='outlined'
                label={I18n.t('activerecord.attributes.v4/alert_product_configs.whatsapp_limit')}
                name='whatsapp_limit'
                required
              />
            </Grid>
            <Grid item xs={6}>
              <Field
                component={TextField}
                className={classes.form__fieldWithError}
                margin='normal'
                fullWidth
                type='number'
                InputLabelProps={{ shrink: true }}
                variant='outlined'
                label={I18n.t('activerecord.attributes.v4/alert_product_configs.notification_interval')}
                name='notification_interval'
                required
              />
            </Grid>
          </Grid>
        </main>
        <footer className={classes.form__actions}>
          <Button
            className={classes.form__action}
            color='primary'
            type='submit'
            variant='contained'
          >
            {title}
          </Button>

          <Button
            className={classes.form__action}
            color='secondary'
            href={`/v4/o/${o}/admin/organizations/${organization_id}/products`}
          >
            {I18n.t('actions.back')}
          </Button>
        </footer>
      </Paper>
    </form>
  )

export default withStyles(styles)(Form)
