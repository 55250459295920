import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './styles'
import { Button, Typography } from '@material-ui/core'
import TextField from '@ui/Fields/TextField'
import DeviseScreen from './DeviseScreen'
import httpService from '@services/httpService'

import { themeProviderWrapper } from '@contexts/ThemeProvider'
import { errorHandlerProviderWrapper } from '@contexts/ErrorHandlerProvider'

const RetrievePassword = ({ classes, logo_url, background_image_url, tenant_name, handleError }) => {
  const [email, setEmail] = React.useState('')

  return (
    <DeviseScreen
      classes={classes}
      logo_url={logo_url}
      background_image_url={background_image_url}
    >
      <form
        className={classes.Login__form}
        onSubmit={async (e) => {
          e.preventDefault()
          try {
            const { headers } = await httpService.post('/users/password', { user: { email } })
            location.replace(headers.location)
          }
          catch ({ response }) {
            Object.keys(response.data.errors).forEach((error) =>
              handleError(`${I18n.t(`activerecord.attributes.v4/users.${error}`)} ${response.data.errors[error]}`)
            )
          }
        }}
      >
        <div className={classes.Login__retrieve_password_help}>
          <Typography component='h6' variant='h6' className={classes.Login__retrieve_password_help_text}>
            {I18n.t('devise.passwords.new.forgot_password')}
          </Typography>

          <Typography className={classes.Login__retrieve_password_help_text}>
            {I18n.t('devise.passwords.new.help_text', { company: tenant_name || 'Agrosmart' })}
          </Typography>
        </div>

        <TextField
          className={classes.Login__form_field}
          InputLabelProps={{
            classes: {
              root: classes.Login__form_field_label,
              focused: classes.Login__form_field_label_focused,
            },
            shrink: true
          }}
          InputProps={{
            classes: {
              root: classes.Login__form_field_outlined_input,
              focused: classes.Login__form_field_focused,
              notchedOutline: classes.notchedOutline,
            },
          }}
          margin='normal'
          value={email}
          onChange={({ target }) => setEmail(target.value)}
          fullWidth
          variant='outlined'
          label={I18n.t('activerecord.attributes.v4/users.email')}
          name='email'
          type='email'
          required
        />

        <div className={classes.Login__form_actions}>
          <Button
            className={classes.Login__form_action}
            href={`/users/sign_in?l=${I18n.locale}`}
            variant='contained'
            fullWidth
            color='primary'
          >
            {I18n.t('actions.back')}
          </Button>

          <Button
            className={classes.Login__form_action}
            type='submit'
            variant='contained'
            fullWidth
            color='primary'
            style={{
              marginLeft: '15px'
            }}
          >
            {I18n.t('devise.passwords.new.reset_password')}
          </Button>
        </div>
      </form>
    </DeviseScreen>
  )
}

export default errorHandlerProviderWrapper(
  themeProviderWrapper(
    withStyles(styles)(RetrievePassword)
  )
)
