import React from 'react'

import { Field as FinalFormField } from 'react-final-form'
import Field from '@ui/Fields/Field'

import { Button, Typography } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from '@theme/styles/forms'
import WhatsApp from '@ui/Icons/WhatsApp'
import CheckBox from '@ui/Fields/CheckBox'


const Form = ({
  handleSubmit,
  classes,
  onCancel,
  locales,
  kindContact,
  action,
  handleChangeEnabled,
  enabled,
  fieldType
}) => (
    <form className={classes.form} onSubmit={handleSubmit} data-testid="coupon-form">
      <main className={classes.form__fields}>
        <div className={classes.form__field__wrapper} >
          <Field
            className={classes.form__fieldWithError}
            InputLabelProps={{ shrink: true, required: true }}
            label={I18n.t('v4/alerts.new.steps.receive_alert.name')}
            name='name'
            margin='normal'
            fullWidth
            variant='outlined'
            type='text'
          />
        </div>

        {
          kindContact === 'whatsapp' &&
          <div className={classes.form__field__wrapper}>
            <FinalFormField
              component={CheckBox}
              className={classes.form__fieldWithError}
              fullWidth
              InputLabelProps={{ shrink: true }}
              margin='normal'
              name='enabled'
              checked={enabled}
              onChange={handleChangeEnabled}
              type='string'
              variant='outlined'
              label={
                <div className={classes.Form_wrapper_icon}>
                  <Typography variant='subtitle2' className={classes.Form_info} >
                    {I18n.t('v4/alerts.new.steps.receive_alert.contact.authorization_term')}
                  </Typography>
                  <WhatsApp className={classes.Form_icon} />
                </div>
              }
            />
          </div>
        }

        <div className={classes.form__field__wrapper}>
          <Field
            label={I18n.t(`v4/alerts.new.steps.receive_alert.contact.${kindContact}`)}
            name='identifier'
            type={fieldType}
            gridXs={6}
            required
          />
        </div>
        <div className={classes.form__field__wrapper}>
          <Field
            type='selectFlagIcon'
            className={classes.form__fieldWithError}
            InputLabelProps={{ shrink: true, required: true }}
            label={I18n.t('v4/alerts.new.steps.receive_alert.locale')}
            margin='normal'
            variant='outlined'
            name='locale'
            data={locales}
            fullWidth
          />
        </div>
      </main>
      <footer className={classes.form__actions}>
        <Button
          className={classes.form__action}
          color='primary'
          type='submit'
          variant='contained'
          disabled={kindContact === 'whatsapp' && !enabled}
        >
          {action}
        </Button>

        <Button
          className={classes.form__action}
          color='secondary'
          onClick={onCancel}
        >
          {I18n.t('actions.cancel')}
        </Button>
      </footer>
    </form >
  )

const Styles = theme => ({
  ...styles(theme),
  Form_wrapper_icon: {
    display: 'flex'
  },
  Form_icon: {
    width: '35px',
    height: '35px'
  },
  Form_info: {
    fontSize: '13px'
  }
})

export default withStyles(Styles)(Form)
