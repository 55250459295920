import React from 'react'
import PropTypes from 'prop-types'
import { validate, calculateParams } from '@models/equipment/irrigation'
import { RailsForm } from '@support/RailsForm'
import { Form as FinalForm } from 'react-final-form'
import EquipmentForm from '../Form'
import Form from './Form'
import httpService from '@services/httpService'
import { deserializeFileData } from '@models/files'

const Edit = ({ model, handleError, irrigation_types, manufacturers, o, organization_id, attachments}) => {
  const deserializeData = data => ({
    ...data,
    percentage_irrigated_areas: Number(data.percentage_irrigated_areas),
  })

  const deserializeResult = result =>
    result.calculation_result ? { ...result, ...result.calculation_result } : result.calculation_result

  const [calculationResult, setCalculationResult] = React.useState(deserializeResult(model))
  const [loading, setLoading] = React.useState(false)
  const [equipmentData, setEquipmentData] = React.useState(deserializeData(model))

  const handleCalculation = async (values) => {
    setEquipmentData(values)

    setLoading(true)
    const data = await calculateParams(values, organization_id, handleError)

    setCalculationResult({ ...values, ...data })

    setLoading(false)
  }

  const onSubmit = values => {
    if (values.calculate) {
      return handleCalculation(values.equipment)
    }

    return handleSubmit(values)
  }

  const handleSubmit = async values => {
    try {
      await httpService.put(`/v4/o/${o}/equipments/${model.kind}/${model.id}`, values)

      Turbolinks.visit(`/v4/o/${o}/equipments/${model.kind}`)
    }
    catch (error) {
      handleError(error)
    }
  }

  return <RailsForm
    component={FinalForm}
    action='update'
    url={`/v4/o/${o}/equipments/${model.kind}/${model.id}`}
    successUrl={`/v4/o/${o}/equipments/${model.kind}`}
    handleSubmit={onSubmit}
    validate={validate}
    initialValues={{ kind: model.kind, equipment: deserializeData(equipmentData) }}
    render={props => (
      <EquipmentForm
        title={`${I18n.t('actions.update')} ${I18n.t(`v4/equipment.kinds.${model.kind}`)}`}
      >
        <Form
          {...props}
          loading={loading}
          o={o}
          organization_id={organization_id}
          handleCalculation={handleCalculation}
          manufacturers={manufacturers}
          irrigation_types={irrigation_types}
          calculationResult={calculationResult}
          initialAttachments={attachments.map(deserializeFileData)}
        />
      </EquipmentForm>
    )}
  />
}

Edit.defaultProps = {
  attachments: []
}

Edit.propTypes = {
  kind: PropTypes.string
}

export default Edit
