import styles from '@styles/indexPage'

export default (theme) => ({
  ...styles(theme),
  Login__wrapper: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'static',
    height: '100%',
    padding: '0px',
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '60%',
      padding: '50px'
    },
    [theme.breakpoints.up('md')]: {
      width: '40%',
      padding: '50px'
    },
  },
  Login__background: {
    backgroundSize: 'cover',
    display: 'block',
    zIndex: 0,
    filter: 'blur(5px)',
    position: 'fixed',
    margin: '-5px',
    top: '-5px',
    bottom: '-5px',
    left: '-5px',
    right: '-5px',
    '&:before': {
      content: '',
      position: 'absolute',
      top: 0,
      right: 0,
      bottom: 0,
      left: 0,
      opacity: '.6'
    }
  },
  Login__logo: {
    marginBottom: '15px',
    maxHeight: '30%',
    width: '90%'
  },
  Login__container: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    zIndex: 1,
  },
  Login__form: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    width: '90%'
  },
  Login__form_container: {
    background: theme.palette.background.paper,
    padding: '45px 30px',
    width: '80%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: '0px 0px 15px 5px rgba(60, 60, 60, 0.26)',
    color: 'white'
  },
  Login__form_actions: {
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%',
    marginTop: '16px',
    marginBottom: '16px'
  },
  Login__form_action: {
    background: '#006CC5',
    color: 'white',
    border: 'none',
    borderRadius: '40px',
    fontSize: '17px',
    fontWeight: 500,
    '&:hover': {
      background: '#4294D6'
    }
  },
  Login__form_field: {
    borderRadius: '5px',
    background: '#F6F6F6'
  },
  Login__form_field_label: {
    color: theme.palette.text.primary,
    background: '#F6F6F6'
  },
  Login__form_field_outlined_input: {
    color: 'black !important',
    background: '#F6F6F6 !important',
    borderBottom: '#000',
    borderBottomStyle: 'solid',
    borderBottomWidth: '2px'
  },
  Login__form_field_focused: {
    color: 'black !important',
    borderBottom: '#006CC5',
    borderBottomStyle: 'solid',
    borderBottomWidth: '2px'
  },
  Login__form_field_label_focused: {
    color: '#006CC5 !important',
  },
  notchedOutline: {
    border: '#006CC5'
  },
  Login__password_recovery: {
    color: '#006CC5',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  Login__retrieve_password_help: {
    margin: '15px 0',
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    textAlign: 'center'
  },
  Login__retrieve_password_help_text: {
    color: '#204F91',
    margin: '5px 0 0',
    textAlign: 'center'
  },
  Login__confirm_invite_help_text: {
    margin: '10px 0 20px',
    textAlign: 'center'
  },
  Login__form_field_checkbox: {
    color: `#006CC5 !important`,
    padding: '0 4px 0 0',
  },
  Login__form_field_label: {
    color: `#3E4149 !important`,
    padding: '0 4px 0 0',
    fontSize: 13
  },
  Login__terms_use: {
    textAlign: 'justify',
    color: 'white',
    overflowWrap: 'break-word',
    background: 'rgba(144, 144, 144, 0.31)',
    borderRadius: '5px',
    overflowY: 'scroll',
    maxHeight: '250px',
    width: '90%',
    padding: '0 10px',
    marginTop: '16px',
    marginBottom: '8px',
    '&::-webkit-scrollbar': {
      width: '5px'
    },
    '&::-webkit-scrollbar-track': {
      background: '#888'
    }
  },
  Login__Locale_flags: {
    display: 'flex',
    marginTop: '16px',
  },
  Login__Locale_flag: {
    margin: '0 10px',
    fontSize: '30px'
  },
  Login_remove_autocomplete_background: {
    '-webkit-text-fill-color': '#000',
    'transition': 'background-color 5000s ease-in-out 0s'
  },
  Login_Span__Wrapper: {
    textAlign: 'left',
    width: '100%'
  },
  Login_Span: {
    color: theme.palette.red
  },
  Login_Span__Link: {
    color: theme.palette.red,
    fontWeight: 'bold',
    '&:visited': {
      color: theme.palette.red
    }
  },
  Login_termsLink: {
    color: '#204F91',
    fontSize: 12,
    fontWeight: 500,
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline'
    }
  },
  Login_bottom: {
    display: 'flex',
    gap: '10px'
  },
  Login_checkTerms: {
    width: '100%'
  },
  Login_actions: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    paddingLeft: 14,
    justifyContent: 'space-between',
    marginTop:'20px',
    marginBottom: '5px'
  },
  Login__signUp_button: {
    color: '#204F91',
    borderRadius: '40px',
    width: '90%',
    '&:hover': {
      background: '#EAEEF5'
    }
  }
})
