import React from 'react'
import PropTypes from 'prop-types'

import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

import { fields } from '@models/phenologicalStages'
import styles from './styles'

const Show = ({ classes, phenologicalStage }) =>
  <Paper
    className={classes.showPage__wrapper}
    elevation={0}
  >
    <div className={classes.phenologicalStage__content}>
      {
        fields().map((field) =>
          phenologicalStage[field.name] && !field.hidden &&
          <div key={field.name} className={classes.phenologicalStage__item}>
            <Typography
              color="textSecondary"
              style={{ fontSize: 'inherit' }}
            >
              {I18n.t(`activerecord.attributes.v4/culture.phenological_stages.${field.name}`)}
            </Typography>

            <Typography
              style={{ fontSize: 'inherit' }}
            >
              {
                field.translation
                  ? field.translation(phenologicalStage[field.name])
                  : phenologicalStage[field.name]
              } {field.measure}
            </Typography>
          </div>
        )
      }
    </div>
  </Paper>

Show.propTypes = {
  phenologicalStage: PropTypes.object
}

export default withStyles(styles)(Show)
