import React from 'react'
import PropTypes from 'prop-types'

import { Form as FinalForm } from 'react-final-form'
import { RailsForm } from '@support/RailsForm'

import Form, { validate } from './Form'

const Edit = ({ model, statuses, part_number_registries, o }) => (
  <RailsForm
    component={FinalForm}
    action='update'
    url={`/v4/o/${o}/admin/hardware_products/${model.id}`}
    successUrl={`/v4/o/${o}/admin/hardware_products/${model.id}`}
    validate={validate}
    initialValues={model}
    render={(props) => (
      <Form
        title={I18n.t('v4/hardware_products.actions.update')}
        statuses={statuses}
        part_number_registries={part_number_registries}
        edit={ true }
        o={o}
        {...props}
      />
    )}
  />
)

Edit.propTypes = {
  model: PropTypes.shape({
    barcode: PropTypes.string,
    communication_address: PropTypes.string,
    created_by_id: PropTypes.number,
    id: PropTypes.number,
    modified_by_id: PropTypes.number,
    pin: PropTypes.sting,
    status: PropTypes.string,
    v4_part_number_registry_id: PropTypes.number
  }),
  statuses: PropTypes.array.isRequired,
  part_number_registries: PropTypes.array.isRequired
}

export default Edit
