import React from 'react'

import { Typography } from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'

import Card from './Card'

const ResumeCard = ({ classes, resume }) =>
  <Card
    title={I18n.t('v4/irrigation_management_product.cards.resume_card.title')}
  >
    <article className={classes.ResumeCard__text}>
      <Typography variant='body1' color='error'>
        {I18n.t('v4/irrigation_management_product.cards.resume_card.areas_need_irrigation')}
      </Typography>
      <Typography variant='body2' color='error'>
        {`${resume.areasThatNeedIrrigation}/${resume.totalAreas}`}
      </Typography>
    </article>

    <article className={classes.ResumeCard__text}>
      <Typography variant='body1'>
        {I18n.t('v4/irrigation_management_product.cards.resume_card.areas_greatest_demand')}
      </Typography>

      <Typography variant='body2'>
        {resume.greatestDemandArea}
      </Typography>
    </article>
  </Card>

const styles = () => ({
  ResumeCard__text: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    flexWrap: 'wrap',
    textAlign: 'center'
  }
})

ResumeCard.defaultProps = {
  resume: {
    areasThatNeedIrrigation: 0,
    totalAreas: 0,
    greatestDemandArea: '--'
  }
}

export default withStyles(styles)(ResumeCard)
