import React from 'react'

import { Button, DialogActions, DialogContent, DialogTitle, Typography } from '@material-ui/core'

import withStyles from '@material-ui/core/styles/withStyles'

import Dropzone from '@ui/Fields/Dropzone'
import LoadingComponent from '@ui/LoadingComponent'
import NewAreasTable from './NewAreasTable'

import { handleMultipleAreasSave } from '@models/area'
import { parseFilesToAreas, filesMimeTypes, acceptedFormats } from '@models/leaflet'

import styles from './styles'

const NewAreaFileForm = ({ classes, handleClose, handleError, o }) => {
  const [areas, setAreas] = React.useState([])
  const [isLoading, setLoading] = React.useState(false)

  const handleFilesDrop = async (files) => {
    try {
      const newAreas = await parseFilesToAreas(files)

      setAreas([...areas, ...newAreas])
    }
    catch (error) {
      handleError(error)
    }

    setTimeout(() => {
      setLoading(false)
    }, 3000)
  }

  return <div>
    <DialogTitle id='new-area-form-dialog-title'>
      {I18n.t('v4/area.new.title')}
    </DialogTitle>

    <DialogContent>
      <Typography variant='body1'>
        {I18n.t('v4/area.new.file_save_confirmation')}
      </Typography>

      <Typography className={classes.field} variant='body2'>
        {I18n.t('v4/area.new.accepted_formats')}: {acceptedFormats.toString()}
      </Typography>

      <Dropzone
        className={classes.field}
        disabled={isLoading}
        label=""
        setFile={(files) => {
          setLoading(true)
          
          setTimeout(() => {
            handleFilesDrop(files)
          }, 1000)
        }}
        multiple
        acceptedMimeTypes={filesMimeTypes}
      />

      {
        isLoading
          ? (
            <LoadingComponent />
          )
          : !_.isEmpty(areas) &&
          <NewAreasTable
            areas={areas}
            classes={classes}
            updateAreas={setAreas}
          />
      }
    </DialogContent>

    <DialogActions>
      <Button
        onClick={handleClose}
        color='secondary'
      >
        {I18n.t('actions.cancel')}
      </Button>

      <Button
        onClick={() => handleMultipleAreasSave(areas, handleError, o)}
        color='primary'
        variant='contained'
        disabled={isLoading}
      >
        {I18n.t('actions.save')}
      </Button>
    </DialogActions>
  </div>
}

export default withStyles(styles)(NewAreaFileForm)
