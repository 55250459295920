import React from 'react'

import { Typography } from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'

import BellIcon from '@images/alerts/bell.svg'
import Step from './Step'

const LandingStep = ({ classes, handleClick }) =>
  <Step
    title={I18n.t('v4/alerts.new.steps.landing_step.title')}
    showBackButton={false}
    advanceButtonText={I18n.t('v4/alerts.new.steps.landing_step.action')}
    advanceStep={handleClick}
  >
    <section className={classes.LandingStep}>
      <Typography variant='body1'>
        {I18n.t('v4/alerts.new.steps.landing_step.text1')}
      </Typography>

      <Typography variant='body1'>
        {I18n.t('v4/alerts.new.steps.landing_step.text2')}
      </Typography>

      <img src={BellIcon} alt='bell'></img>

      <Typography variant='body1'>
        <span>
          {I18n.t('v4/alerts.new.steps.landing_step.text3')}
        </span>

        <span className={classes.LandingStep_info}>
          {I18n.t('v4/alerts.products.equipment')}
        </span>

        <span>
          {I18n.t('v4/alerts.new.steps.landing_step.text4')}
        </span>

        <span className={classes.LandingStep_info}>
          {I18n.t('v4/alerts.products.weather_forecast')}
        </span>

        <span>
          {I18n.t('v4/alerts.new.steps.landing_step.text5')}
        </span>
      </Typography>

      <Typography variant='body1'>
        <span className={classes.LandingStep_info_sublimate}>
          {`${I18n.t('v4/alerts.new.steps.landing_step.text6')}: `}
        </span>

        <span>
          {I18n.t('v4/alerts.new.steps.landing_step.text7')}
        </span>
      </Typography>

      <Typography variant='body1'>
        {I18n.t('v4/alerts.new.steps.landing_step.text8')}
      </Typography>
    </section>
  </Step>

const styles = theme => ({
  LandingStep: {
    textAlign: 'center',
    margin: 'auto',
    maxWidth: '500px',
    '& *': {
      marginBottom: theme.spacing.unit * 2
    }
  },
  LandingStep_info: {
    padding: `0 ${theme.spacing.unit}px`,
    fontWeight: '500'
  },
  LandingStep_info_sublimate: {
    'textDecoration': 'underline'
  }
})

export default withStyles(styles)(LandingStep)
