import React from 'react'

import { withStyles } from '@material-ui/core/styles'

import { Typography } from '@material-ui/core'

import ChartTooltip from './chartTooltip'
import IrrigationManagementNavBar from './irrigationManagementNavBar'
import DataSummaryCard from './dataSummaryCard'
import IrrigationManagementContent from './irrigationManagementContent'

import LoadingComponent from '@ui/LoadingComponent'

import { chartSeries, dateVisualizations } from '@models/irrigation_managements/dashboard'

import { useRouter } from '@contexts/RouterProvider'

const i18nPrefixer = text => I18n.t(`v4/irrigation_system.${text}`)

const IrrigationManagement = ({
  weeklyData,
  seasonData,
  crop,
  weeklyEvents,
  seasonEvents,
  fetching,
  managements,
  phenologicalStages,
  currentManagement,
  acumulatedInCrop,
  demandedIrrigation,
  classes
}) => {
  const router = useRouter()
  const areaChartsSeries = chartSeries.filter(serie => serie.type === 'area')
  const nonAreaChartSeries = chartSeries.filter(serie => serie.type !== 'area')

  const visualizations = ['crop', 'weekly']
  const [selectedVisualization, setVisualization] = React.useState(1)

  const setManagement = id => {
    if (id !== currentManagement) {
      router.visit(`irrigation_managements/${id}/dashboard`)
    }
  }

  const [dateVisualization, setDateVisualization] = React.useState(dateVisualizations[0].id)

  return <div className={classes.IrrigationDashboard}>
    <IrrigationManagementNavBar
      crop={crop}
      dateVisualization={dateVisualization}
      dateVisualizations={dateVisualizations}
      fetching={fetching}
      handleSelectDateVisualization={setDateVisualization}
      handleSelectManagement={setManagement}
      managements={managements}
      selectedManagement={currentManagement}
      setVisualization={setVisualization}
      visualization={selectedVisualization}
      visualizations={visualizations}
    />

    {
      !fetching
        ?
        _.isEmpty(weeklyData) && _.isEmpty(seasonData)
          ? <Typography component='h3' variant='h5' align='center'>
            {I18n.t('info.no_data')}
          </Typography>
          : <>
            {
              visualizations[selectedVisualization] === 'weekly'
                ? <div className={classes.IrrigationDashboard_Weekly}>
                  <IrrigationManagementContent
                    areaChartsSeries={areaChartsSeries}
                    classes={classes}
                    ChartTooltip={ChartTooltip}
                    dateVisualization={dateVisualization}
                    deserializedData={weeklyData}
                    events={weeklyEvents}
                    nonAreaChartSeries={nonAreaChartSeries}
                    phenologicalStages={phenologicalStages}
                    selectedVisualization={selectedVisualization}
                    visualizations={visualizations}
                  />

                  {
                    <div className={classes.DataSummaryCard_Wrapper}>
                      <DataSummaryCard
                        title={i18nPrefixer('demanded_irrigation')}
                        data={demandedIrrigation}
                        emptyDataMessage='---'
                      />
                      <DataSummaryCard
                        title={i18nPrefixer('acumulated_in_crop')}
                        data={acumulatedInCrop}
                        emptyDataMessage='---'
                      />
                    </div>
                  }
                </div>
                : <div className={classes.IrrigationDashboard_Season}>
                  <IrrigationManagementContent
                    areaChartsSeries={areaChartsSeries}
                    ChartTooltip={ChartTooltip}
                    classes={classes}
                    dateVisualization={dateVisualization}
                    deserializedData={seasonData}
                    events={seasonEvents}
                    nonAreaChartSeries={nonAreaChartSeries}
                    phenologicalStages={phenologicalStages}
                    selectedVisualization={selectedVisualization}
                    visualizations={visualizations}
                  />

                  {
                    !_.isEmpty(phenologicalStages) &&
                    <div className={classes.PhenologicalStagesCards_Wrapper}>
                      {
                        phenologicalStages.map(({ title, data }, index) =>
                          <DataSummaryCard key={index} title={title} data={data} />
                        )
                      }
                    </div>
                  }
                </div>
            }
          </>
        : <LoadingComponent />
    }
  </div>
}

const styles = theme => ({
  IrrigationDashboard: {
    margin: theme.spacing.unit * 3,
    paddingTop: theme.spacing.unit * 3
  },
  PhenologicalStagesCards_Wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    padding: `0 ${theme.spacing.unit}px`,
    marginTop: `${theme.spacing.unit * 2}px`,
    marginRight: `${theme.spacing.unit * -2}px`,
  },
  DataSummaryCard_Wrapper: {
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    minWidth: '300px',
    padding: `0 ${theme.spacing.unit}px`,
    marginTop: `${theme.spacing.unit * 2}px`,
    marginRight: `${theme.spacing.unit * -2}px`,
    [theme.breakpoints.up('lg')]: {
      padding: 0,
      marginTop: `${theme.spacing.unit * 2}px`,
      marginLeft: theme.spacing.unit
    },
  },
  IrrigationDashboard_Content: {
    width: '100%'
  },
  IrrigationDashboard_Weekly: {
    [theme.breakpoints.up('lg')]: {
      display: 'grid',
      width: '100%'
    },
    minWidth: '700px'
  },
  IrrigationDashboard_Season: {
    minWidth: '700px'
  }
})

IrrigationManagement.defaultProps = {
  crop: {},
  weeklyData: [],
  seasonData: [],
  weeklyEvents: [],
  seasonEvents: [],
  phenologicalStages: [],
  acumulatedInCrop: [],
  demandedIrrigation: [],
  managements: []
}

export default withStyles(styles)(IrrigationManagement)
