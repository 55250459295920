import React from 'react'
import { withSnackbar } from 'notistack'
import { Form as FinalForm } from 'react-final-form'
import PropTypes from 'prop-types'

import httpService from '@services/httpService'
import { RailsForm } from '@support/RailsForm'

import Form from './Form'

export const New = ({ model, enqueueSnackbar, o }) => {

  const submit = data => {
    httpService({
      method: 'POST',
      url: `/v4/o/${o}/organizations/${model.id}/users/invitation.json`,
      data
    })
      .then(res => Turbolinks.visit(`/v4/o/${o}/organizations/${model.id}/users`))
      .catch(({ response }) => enqueueSnackbar(response.data.errors.messages[0], { variant: 'error' }))
  }

  return <RailsForm
    action='create'
    component={FinalForm}
    handleSubmit={data => submit(data)}
    render={(props) => (
      <Form
        {...props}
        title={I18n.t('v4/users.new.title')}
        organization={model}
        o={o}
      />
    )}
  />
}


New.propTypes = {
  model: PropTypes.object,
}

export default withSnackbar(New)
