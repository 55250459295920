export default (theme) => ({
  Error__wrapper: {
    position: 'absolute',
    width: '100%',
    height: '100%',
    backgroundColor: 'transparent',
    textAlign: 'center',
    fontFamily: 'arial, sans-serif',
    margin: 0
  },
  Error__content: {
    width: '95%',
    maxWidth: '33em',
    margin: '0 auto'
  },
  Error__content_message: {
    border: '1px solid #CCC',
    borderRightColor: '#999',
    borderLeftColor: '#999',
    borderBottomColor: '#BBB',
    borderTopLeftRadius: '9px',
    borderTopRightRadius: '9px',
    backgroundColor: 'white',
    padding: '7px 12% 0',
    boxShadow: '0 3px 8px rgba(50, 50, 50, 0.17)'
  },
  Error__message: {
    fontSize: '100%',
    lineHeight: '1.5em',
    color: theme.palette.primary.main
  },
  Error__number: {
    fontSize: '60px',
    color: theme.palette.primary.main
  },
  Error__info: {
    margin: '0 0 1em',
    padding: '1em',
    backgroundColor: '#F7F7F7',
    border: '1px solid #CCC',
    borderRightColor: '#999',
    borderLeftColor: '#999',
    borderBottomColor: '#999',
    borderBottomLeftRadius: '4px',
    borderBottomRightRadius: '4px',
    borderTopColor: '#DADADA',
    color: '#666',
    boxShadow: '0 3px 8px rgba(50, 50, 50, 0.17)'
  },
  Error__logo: {
    width: '200px',
    marginTop: '50px'
  }
})
