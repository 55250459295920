import React from 'react'
import { withStyles } from '@material-ui/core/styles'

import ChartLegend from '../../chartLegend'

const IrrigationChartLegend = ({ classes, legends, toggleChartSerie }) => {
  const fixedLegends = legends.map(legend => ({
    label: `chart_labels.${legend.name}`,
    icon: {
      src: <span className={classes.square} style={{backgroundColor: legend.color}} />
    },
    onClick: () => toggleChartSerie(legend.id),
    hide: legend.hide
  }))

  return <ChartLegend legends={fixedLegends} />
}

const styles = theme => ({
  square: {
    width: `${theme.spacing.unit * 2}px`,
    height: `${theme.spacing.unit * 2}px`
  }
})

export default withStyles(styles)(IrrigationChartLegend)
