
//Função baseada no algoritmo do cpf

export const billingInfoHeaders = [
  'legal_name',
  'registry_code',
  'zipcode',
  'city',
  'state'
]

const validate_number_cpf = (number_cpf) => {
  if (number_cpf === '') return false;

  if (number_cpf.length !== 11 ||
    number_cpf === "00000000000" ||
    number_cpf === "11111111111" ||
    number_cpf === "22222222222" ||
    number_cpf === "33333333333" ||
    number_cpf === "44444444444" ||
    number_cpf === "55555555555" ||
    number_cpf === "66666666666" ||
    number_cpf === "77777777777" ||
    number_cpf === "88888888888" ||
    number_cpf === "99999999999")
    return false

  let accumulator = 0
  let result = 0

  for (let i = 0; i < 9; i++)
    accumulator += parseInt(number_cpf.charAt(i)) * (10 - i)
  result = 11 - (accumulator % 11)
  if (result === 10 || result === 11)
    result = 0
  if (result !== parseInt(number_cpf.charAt(9)))
    return false

  accumulator = 0
  result = 0

  for (let i = 0; i < 10; i++)
    accumulator += parseInt(number_cpf.charAt(i)) * (11 - i)
  result = 11 - (accumulator % 11)

  if (result === 10 || result === 11)
    result = 0

  if (result !== parseInt(number_cpf.charAt(10)))
    return false

  return true
}

//Função baseada no algoritmo do cnpj
const validate_number_number_cnpj = (number_cnpj) => {

  if (number_cnpj == '') return false

  if (number_cnpj.length != 14)
    return false

  // Elimina number_cnpjs invalidos conhecidos
  if (number_cnpj == "00000000000000" ||
    number_cnpj == "11111111111111" ||
    number_cnpj == "22222222222222" ||
    number_cnpj == "33333333333333" ||
    number_cnpj == "44444444444444" ||
    number_cnpj == "55555555555555" ||
    number_cnpj == "66666666666666" ||
    number_cnpj == "77777777777777" ||
    number_cnpj == "88888888888888" ||
    number_cnpj == "99999999999999")
    return false

  // Valida DVs
  let legth_cnpj = number_cnpj.length - 2
  let registration_number = number_cnpj.substring(0, legth_cnpj)
  let digits = number_cnpj.substring(legth_cnpj)
  let sum = 0
  let pos = legth_cnpj - 7

  for (let i = legth_cnpj; i >= 1; i--) {
    sum += registration_number.charAt(legth_cnpj - i) * pos--
    if (pos < 2)
      pos = 9
  }

  let result = sum % 11 < 2 ? 0 : 11 - sum % 11
  if (result != digits.charAt(0))
    return false

  legth_cnpj = legth_cnpj + 1
  registration_number = number_cnpj.substring(0, legth_cnpj)
  sum = 0
  pos = legth_cnpj - 7

  for (let i = legth_cnpj; i >= 1; i--) {
    sum += registration_number.charAt(legth_cnpj - i) * pos--
    if (pos < 2)
      pos = 9
  }

  result = sum % 11 < 2 ? 0 : 11 - sum % 11
  if (result != digits.charAt(1))
    return false;

  return true;

}

export const validate = values => {
  const requiredFields = ['registry_code', 'legal_name', 'zipcode']
  const errors = {}

  requiredFields.forEach(field => {
    if (!values[field]) errors[field] = I18n.t('validations.required')
  })

  if (I18n.locale === 'pt-BR' && values.zipcode !== undefined && values.zipcode.length !== 8) {
    errors.zipcode = I18n.t('validations.zipcode')
  }

  if (values.registry_code !== undefined && I18n.locale == 'pt-BR') {
    if (values.registry_code.length === 11) {
      if (!validate_number_cpf(values.registry_code)) {
        errors.registry_code = I18n.t('validations.registry_code_cpf')
      }
    } else {
      if (values.registry_code.length === 14) {
        if (!validate_number_number_cnpj(values.registry_code)) {
          errors.registry_code = I18n.t('validations.registry_code_cnpj')
        }
      } else {
        errors.registry_code = I18n.t('validations.registry_code_format')
      }
    }
  }

  return errors
}
