import TableExport from 'tableexport'

export const exportTable = ( id, equipment ) => {
  const { name } = equipment
  const table = TableExport(document.getElementsByTagName('table'), {
    exportButtons: false,
    filename: `table-${new Date().toISOString()}-${name.replace(/\s/g, '')}`,
    formats: ['xlsx']
  })
  const exportData = table.getExportData()[id].xlsx

  table.reset();
	table.export2file(exportData.data, exportData.mimeType, exportData.filename, exportData.fileExtension, exportData.merges)
}
