import React, { Component } from "react";

import DeleteDialog from "@ui/DeleteDialog";

import { CustomTable } from "./CustomTable";

class VisualizationsTable extends Component {
  constructor(props) {
    super(props);

    this.state = {
      id: undefined,
      showing: "",
      sortedData: [],
      availableDates: [],
      orderBy: [],
      describer: [],
      isDialogOpen: false,
    };
  }

  setInitialData(data) {
    if (!!data) {
      const { running } = data;

      this.setInitialSortedData(running);
    }
  }

  getTableHeaders = (currentStatus) => {
    const status = {
      running: [
        {
          field: "area",
          title: I18n.t("v4/irrigation_management.describer_index.area"),
        },
        {
          field: "hydricStatus",
          title: I18n.t(
            "v4/irrigation_management.describer_index.hydric_status"
          ),
        },
        {
          field: "wa",
          title: I18n.t("v4/irrigation_management.describer_index.wa"),
          type: "numeric",
        },
        {
          field: "accumulated_water_loss",
          title: I18n.t(
            "v4/irrigation_management.describer_index.accumulated_water_loss"
          ),
          type: "numeric",
        },
        {
          field: "di",
          title: I18n.t("v4/irrigation_management.describer_index.di"),
          type: "numeric",
        },
      ],
      finished: [
        {
          field: "area",
          title: I18n.t("v4/irrigation_management.describer_index.area"),
        },
      ],
    };

    return status[currentStatus] || [];
  };

  setInitialSortedData(running) {
    if (!!running.content && running.content.length > 0) {
      running.content[0].sort((a, b) =>
        a.area < b.area ? -1 : a.area > b.area ? 1 : 0
      );
      this.setState({
        availableDates: running.dates,
        describer: this.getTableHeaders("running"),
        orderBy: running.orderBy,
        sortedData: running.content[0],
        showing: "running",
      });
    }
  }

  componentDidMount() {
    const { data } = this.props;

    this.setInitialData(data);
  }

  setSortedData = (sortDescriber) => {
    const { data } = this.props;
    const { sortedData } = this.state;

    if (sortedData) {
      const { running, finished } = data;

      if (sortDescriber.showFinishedManagements) {
        this.setState({
          sortedData: finished,
          describer: this.getTableHeaders("finished"),
          showing: "finished",
        });
      } else {
        this.setState({
          sortedData: running.content[sortDescriber.date],
          describer: this.getTableHeaders("running"),
          showing: "running",
        });
      }
    }
  };

  closeDialog = () => {
    this.setState({ isDialogOpen: false });
  };

  handleOpenDialog = (id) => this.setState({ isDialogOpen: true, id });

  render() {
    const { id, sortedData, availableDates, describer, isDialogOpen, orderBy } =
      this.state;

    const { o, getManagementStatusButton, data } = this.props;

    const { running } = data;

    return (
      <>
        <CustomTable
          orderBy={orderBy}
          describer={describer}
          sortedData={sortedData}
          availableDates={availableDates}
          setSortedData={this.setSortedData}
          running={running}
          getManagementStatusButton={getManagementStatusButton}
          handleOpenDialog={this.handleOpenDialog}
        />

        <DeleteDialog
          dialogTitle={`${I18n.t("actions.remove")} ${I18n.t(
            "v4/irrigation_management.title"
          )}?`}
          dialogText={I18n.t("confirmation.remove")}
          successUrl={`/v4/o/${o}/irrigation_managements/`}
          deleteUrl={`/v4/o/${o}/irrigation_managements/${id}.json`}
          onCancel={this.closeDialog}
          onClose={this.closeDialog}
          open={isDialogOpen}
        />
      </>
    );
  }
}

export default VisualizationsTable;
