import React from 'react'


const headers = [
  'name', 'active', 'category', 'notification_interval'
]

export const getorganizationAlertsHeaders = () =>
  headers.map(header => ({
    title: I18n.t(`v4/alerts.alerts.${header}`),
    field: header,
    render: rowData => {
      if (header === 'active') {
        return <span>{I18n.t(String(!!rowData.active))}</span>
      }
      return <span>{rowData[header]}</span>
    }
  }))

export const validate = values => {
  const requiredFields = ['name', 'active', 'category']
  const errors = {}

  requiredFields.forEach(field => {
    if (values[field] === undefined) errors[field] = I18n.t('validations.required')
  })

  return errors
}

export const activeValues = [
  { id: 1, name: I18n.t('true'), value: true },
  { id: 2, name: I18n.t('false'), value: false }
]