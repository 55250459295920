import React, { Fragment } from 'react'
import PropTypes from 'prop-types'


import Table from '@ui/Table'
import Paper from '@material-ui/core/Paper'
import Button from '@material-ui/core/Button'
import Tab from '@material-ui/core/Tab'
import Tabs from '@material-ui/core/Tabs'
import withStyles from '@material-ui/core/styles/withStyles'

import DeleteDialog from '../ui/DeleteDialog'
import styles from '@styles/indexPage'

import { getEquipmentHeaders, deserialize } from '@models/equipment/equipment'

import { withRouter } from '@contexts/RouterProvider'

export class Equipment extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isDialogOpen: false,
      id: null
    }
  }

  handleVisit = (kind, rowData) => {
    const { router } = this.props

    if (kind === 'irrigation') {
      return router.visit(`/equipments/irrigation/${rowData.id}/irrigations`)
    }

    if (kind === 'manual') {
      return router.visit(`/equipments/manual/${rowData.id}/measures`)
    }

    return router.visit(`/equipments/${rowData.id}/dashboard`)
  }

  setDisable = (kind) => {
    const kinds = ['automatic', 'irrigation', 'manual']

    return kinds.includes(kind)
  }

  render() {
    const {
      classes,
      kinds,
      kind,
      o
    } = this.props

    const model = this.props.model.map(deserialize)
    const selectedTab = kinds.indexOf(kinds.find(tab => tab.id === kind))

    return (
      <Fragment>
        <Paper className={classes.page__wrapper} elevation={0}>
          <Button
            variant='outlined'
            color='primary'
            href={`/v4/o/${o}/equipments/${kind}/new`}
          >
            {I18n.t('actions.add')} {I18n.t(`v4/equipment.kinds.${kind}`)}
          </Button>
          <header className={classes.page__header} />
          <Tabs
            className={classes.tabs}
            variant="scrollable"
            scrollButtons="on"
            value={selectedTab}
            indicatorColor="primary"
          >
            {
              kinds.map(tab =>
                <Tab
                  label={I18n.t(`v4/equipment.tabs.${tab.id}`)}
                  key={tab.id}
                  onClick={() => Turbolinks.visit(`/v4/o/${o}/equipments/${tab.id}`)}
                />
              )
            }
          </Tabs>

          <Table
            tableKey='equipments-index'
            key='equipment-table'
            components={{
              Container: props => <div>{props.children}</div>,
            }}
            columns={getEquipmentHeaders(kind)}
            data={model}
            options={{
              showTitle: false
            }}
            actions={[
              rowData => ({
                icon: 'bar_chart',
                iconProps: { 'data-testid': `dashboard-${rowData.id}` },
                tooltip: I18n.t('v4/equipment.dashboard.access_measures'),
                disabled: !this.setDisable(kind) || rowData.equipment_kind === 'tensiometer',
                onClick: (event, rowData) => this.handleVisit(kind, rowData)
              }),
              rowData => ({
                icon: 'create',
                iconProps: { 'data-testid': `edit-${rowData.id}` },
                tooltip: I18n.t('actions.edit'),
                onClick: (event, rowData) => {
                  Turbolinks.visit(`/v4/o/${o}/equipments/${kind}/${rowData.id}/edit`)
                }
              }),
              rowData => ({
                icon: 'delete',
                iconProps: { 'data-testid': `delete-${rowData.id}` },
                tooltip: I18n.t('actions.remove'),
                onClick: (event, rowData) => {
                  this.setState({ isDialogOpen: true, id: rowData.id })
                }
              })
            ]}
          />
        </Paper>

        <DeleteDialog
          dialogTitle={`${I18n.t('actions.remove')} ${I18n.t(`v4/equipment.kinds.${kind}`)}?`}
          dialogText={I18n.t('confirmation.remove')}
          successUrl={`/v4/o/${o}/equipments/${kind}`}
          deleteUrl={`/v4/o/${o}/equipments/${kind}/${this.state.id}.json`}
          onCancel={() => this.setState({ isDialogOpen: false })}
          onClose={() => this.setState({ isDialogOpen: false })}
          open={this.state.isDialogOpen}
        />
      </Fragment >
    )
  }
}

Equipment.defaultProps = {
  kinds: [
    { name: 'Módulo Automático', id: 'automatic' },
    { name: 'Implemento', id: 'implement' },
    { name: 'Módulo Manual', id: 'manual' },
    { name: 'Trator', id: 'tractor' }
  ],
  kind: 'automatic',
  model: []
}

Equipment.propTypes = {
  model: PropTypes.array,
  kinds: PropTypes.array,
  kind: PropTypes.string
}

export default withRouter(withStyles(styles)(Equipment))
