import React from 'react'

import { withSnackbar } from 'notistack'

import Dialog from '@material-ui/core/Dialog'
import DialogTrial from '@ui/Dialog'
import ExpiredTrial from '@ui/ExpiredTrial'

import Map from './Map'
import AlertsInfoMenu from './infoMenu/Alerts'
import AreaInfoMenu from './infoMenu/Area'
import EquipmentInfoMenu from './infoMenu/Equipment'
import NewAreaDialog from './NewAreaDialog'
import Search from './search/Index'
import SatelliteFooterMenu from './productsFloatingMenu/View/FooterMenu'
import EdiAreaMenu from './productsFloatingMenu/EditAreaMenu'
import FixedDialog from './productsFloatingMenu/Atmos/SetupNewForecastPoint/Dialog'
import { MENUS, withMenusStatus } from '@contexts/MenusStatusProvider'
import { withHttpService } from '@contexts/HttpServiceProvider'
import { withRouter } from '@contexts/RouterProvider'

import httpService from '@services/httpService'
import { apiService } from '@services/httpService'
import updateIfSameAttribute from '@services/updateIfSameAttribute'

import { getAreasColors } from '@models/irrigation_managements/maps'
import { getTrialInfo } from '@models/productsList'
import { sendAmplitudeEvent } from '@utils/amplitudeEvent'
import { IntercomAPI } from '@utils/IntercomAPI'

import {
  decimalValue,
  defaultInitialCoordinates,
  getInternalResults,
  getZoom,
  latLngValue
} from '@models/map'

import './Styles.css'

class Dashboard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      area: {},
      areas: [],
      dialogOpen: false,
      dialogType: null,
      cultures: [],
      uploadFileDialogOpen: false,
      drawing: false,
      editing: false,
      equipments: [],
      forecastPointsList: [],
      mapCenter: undefined,
      mapElementData: {},
      openInfoMenu: false,
      type: '',
      isOpenProductDialogMenu: false,
      alertMenu: {},
      suggestions: [],
      service: null,
      geocoder: null,
      anchor: null,
      loading: false,
      equipmentMenuData: {
        status: 'unknown',
        variableValue: {},
        descriptionVariables: [],
        hydrometer: {},
        online: false
      },
      equipmentMenu: {},
      areaMenu: {},
      areaMenuData: { data: {} },
      trialInfo: {},
      saveArea: false
    }
    this.references = {
      productsReference: null,
      mapReference: null,
      searchReference: null,
      suggestionsMenuReference: React.createRef()
    }
  }

  componentDidMount() {
    const { circleAreas, polygonAreas, equipments, forecastPointsList, google_maps_api_key, isTrial } = this.props

    this.initializeGoogleMapsScript(google_maps_api_key)
    this.fetchCultures()
    this.fetchTrial()

    this.setState({
      areas: [...circleAreas, ...polygonAreas],
      equipments
    })
    !_.isEmpty(forecastPointsList)
      ? this.setState({ forecastPointsList })
      : this.fetchDataForecastPoints()
  }


  fetchCultures = async () => {
    const { httpService: { apiRequest } } = this.props

    try {
      const { data } = await apiRequest('cultures')

      this.setState({
        cultures: data
      })
    }
    catch (error) {
      console.error(error)
    }
  }

  handleRemoveAllAlerts = async () => {
    const { alertMenu } = this.state
    const { menusStatus: { menus, toggleMenu }, router, handleError, enqueueSnackbar } = this.props
    const { data } = menus[MENUS.alertsMenu]

    const allAlertsIds = alertMenu.alerts.map(alert => alert.ids).flat()

    let { alerts } = data
    const menuAlertsIds = alerts.map(currentAlert => currentAlert.alert.id)

    alertMenu.alerts.forEach(alert => {
      if (menuAlertsIds.includes(alert.alert.id)) {
        alerts = alerts.filter(alertMenu => alertMenu.alert.id !== alert.alert.id)
      }
    })

    try {
      await httpService.post(`${router.baseURL}alerts/notifications/mark_as_read`, { _mark_as_read: allAlertsIds })

      const dateAndTime = data.alerts[data.alerts.length - 1].dateAndTime

      this.setAlertsMenu(data, alerts, dateAndTime)

      enqueueSnackbar(I18n.t('v4/alerts.alerts.alert_message'), { variant: 'default' })

      toggleMenu(MENUS.mapAlertsMenu, false)
    }
    catch (error) {
      handleError(error)
    }
  }

  setAlertsMenu = (data, alerts, dateAndTime) => {
    const { menusStatus: { setMenuData } } = this.props

    let noAlertsTriggered = false
    let active = true
    let lastTimeTriggered = {}

    if (_.isEmpty(alerts)) {
      noAlertsTriggered = true
      active = false
      lastTimeTriggered = dateAndTime
    }

    setMenuData(MENUS.alertsMenu, 'data', { ...data, alerts, noAlertsTriggered, active, lastTimeTriggered })
  }

  handleRemoveAlerts = async (alertId, index) => {
    const { alertMenu } = this.state
    const { menusStatus: { menus, toggleMenu }, router, handleError, enqueueSnackbar } = this.props

    const { alerts: alertsOnTheMap } = alertMenu

    const deletedAlert = { ...alertsOnTheMap[index] }

    const newAlertsOnTheMap = alertsOnTheMap.filter(alertMap => alertMap.alert.id !== alertId)

    try {
      await httpService.post(`${router.baseURL}alerts/notifications/mark_as_read`, { _mark_as_read: deletedAlert.ids })

      this.setState({ alertMenu: { ...alertMenu, alerts: newAlertsOnTheMap } })

      enqueueSnackbar(I18n.t('v4/alerts.alerts.alert_message'), { variant: 'default' })

      const { data } = menus[MENUS.alertsMenu]

      const alerts = data.alerts.filter(alert => alert.alert.id !== alertId)

      this.setAlertsMenu(data, alerts, deletedAlert.dateAndTime)

      if (_.isEmpty(newAlertsOnTheMap)) {
        toggleMenu(MENUS.mapAlertsMenu, false)
      }

    }
    catch (error) {
      handleError(error)
    }
  }

  setPlaces = () => {
    this.setState({
      service: new google.maps.places.AutocompleteService(),
      geocoder: new google.maps.Geocoder()
    })
  }

  initializeGoogleMapsScript = key => {
    const googleMapsScriptId = 'google-maps-script'

    if (!document.querySelector(`#${googleMapsScriptId}`)) {
      const apiKey = key != null ? key : ''

      const script = document.createElement('script')
      script.id = googleMapsScriptId
      script.async = true
      script.defer = true
      script.type = 'text/javascript'
      script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places`

      script.addEventListener('load', () => this.setPlaces())

      return document.head.appendChild(script)
    }

    this.setPlaces()
  }

  //Search
  getSuggestions = _.debounce((value) => {
    this.setState({ loading: true })
    const { areas, service, forecastPointsList } = this.state
    const { equipments } = this.props

    const suggestions = getInternalResults({
      areas,
      value,
      forecastPointsList,
      equipments
    })

    if (latLngValue(value)) {
      const coordinates = latLngValue(value)
      const latLngSuggestions = [{
        name: `${coordinates[0]} ${coordinates[1]}`,
        lat: coordinates[0],
        lon: coordinates[1],
        hasPin: true
      }]
      suggestions.push(...suggestions, ...latLngSuggestions)
    }

    if (decimalValue(value)) {
      const coordinates = decimalValue(value)
      const decimalSuggestions = [{
        name: `${coordinates[0]} ${coordinates[1]}`,
        lat: coordinates[0],
        lon: coordinates[1],
        hasPin: true
      }]
      suggestions.push(...suggestions, ...decimalSuggestions)
    }

    service.getPlacePredictions({ input: value }, (results, status) => {
      if (status === 'OK' && !_.isEmpty(results)) {
        return this.setState({
          suggestions: [...suggestions, ...results.slice(0, 3)],
          loading: false
        })
      }
      if (status === 'ZERO_RESULTS') {
        return this.setState({
          suggestions,
          loading: false
        })
      }
    })
  }, 500)

  addResultInfoOnMap = (coordinates, info, hasPin) => {
    const { menusStatus: { menus, setMenuData, toggleMenu } } = this.props
    const { onMapFlow, step } = menus[MENUS.weatherForecastProduct]
    const { open } = menus[MENUS.weatherForecastSetupFixedModal]
    const { equipments, areas, forecastPointsList } = this.state
    this.references.mapReference.changeMapCenter(coordinates)
    if (open && step === 2) {
      let latLng = { lat: coordinates[0], lng: coordinates[1] }
      return this.references.mapReference.addNewLayerPin(latLng)
    }
    if (!_.isEmpty(equipments) && equipments.find(equip => equip.name == info)) {
      this.handleEquipmentSelect(equipments.find(equip => equip.name == info))
    } else if (!_.isEmpty(areas) && areas.find(area => area.name == info)) {
      const areaSelected = areas.find(area => area.name == info)
      this.handleAreaSelect(areaSelected.id)
    } else if (!_.isEmpty(forecastPointsList) && forecastPointsList.find(area => area.name == info)) {
      setMenuData(MENUS.weatherForecastProduct, 'forecastPoint', forecastPointsList.find(area => area.name == info))
      toggleMenu(MENUS.weatherForecastProduct, true)
    } else {
      this.references.mapReference.addSearchInfoInMap(coordinates, info, hasPin)
    }
  }

  onSearchChange = (value) => {
    if (!_.isEmpty(value)) {
      this.getSuggestions(value)
    } else {
      this.setState({
        suggestions: []
      })
    }
  }

  onDownshiftChange = suggestion => {
    const { geocoder } = this.state

    if (suggestion.lat && suggestion.lon) {
      return this.addResultInfoOnMap([suggestion.lat, suggestion.lon], suggestion.name, suggestion.hasPin)
    }

    geocoder.geocode({ 'address': suggestion.description }, (place, status) => {
      if (status === 'OK') {
        const coordinates = [place[0].geometry.location.lat(), place[0].geometry.location.lng()]
        return this.addResultInfoOnMap(coordinates, suggestion.description, true)
      }
    })
  }

  onSearchClick = (value) => {
    const { areas, service, geocoder, forecastPointsList } = this.state
    const { equipments } = this.props

    const result = getInternalResults({
      value,
      forecastPointsList,
      areas,
      equipments
    })

    if (_.isEmpty(result)) {
      if (latLngValue(value)) {
        return this.addResultInfoOnMap(latLngValue(value), value, true)
      }

      if (decimalValue(value)) {
        return this.addResultInfoOnMap(decimalValue(value), value, true)
      }

      service.getPlacePredictions({ input: value }, (results, status) => {
        if (!_.isEmpty(results)) {
          const address = results[0].description
          geocoder.geocode({ 'address': address }, (place, status) => {
            if (status === 'OK') {
              const coordinates = [place[0].geometry.location.lat(), place[0].geometry.location.lng()]
              this.addResultInfoOnMap(coordinates, address, true)
            }
          })
        }
      })
    } else {
      this.addResultInfoOnMap([result[0].lat, result[0].lon], result[0].name, result.hasPin)
    }
  }

  clearSearch = () => {
    this.setState({ suggestions: [] })
    this.references.mapReference.cleanGeocoderLayer()
  }

  setUpForecastPoint = (point) =>
    point.lat && point.lon

  fetchDataForecastPoints = async () => {
    const { handleError, products, current_organization_id } = this.props
    const params = { organization_id: current_organization_id }

    if (products.some(product => product.kind === 'weather_forecast')) {
      try {
        const { data } = await apiService().get('api/v4/forecasts/points', { params })

        const points = data.filter(this.setUpForecastPoint)

        this.setState({
          forecastPointsList: points.map(point => ({
            ...point,
            handler: () => {
              const { productsReference } = this.references

              productsReference.setForecastProduct(point)
            }
          }))
        })
      }
      catch (error) {
        handleError(error)
        this.setState({ forecastPointsList: [] })
      }
    }
  }

  getCircleValues = (values) => {
    const { lat, lng } = this.state.layer._latlng
    const radius = this.state.layer._mRadius
    const { coordinates } = this.state.layer

    return {
      ...values,
      latitude: lat,
      longitude: lng,
      radius,
      coordinates
    }
  }

  getPolygonValues = (values) => {
    const coordinates = this.state.layer._latlngs[0]
    const center = values.center || this.state.layer._renderer._center

    return {
      ...values,
      latitude: center.lat,
      longitude: center.lng,
      coordinates
    }
  }

  getCenterCoordinates = (areas) => {
    if (areas[0]) {
      const { latitude, longitude, size_ha } = areas[0]

      return {
        centerCoord: [latitude, longitude],
        zoom: getZoom(size_ha)
      }
    }
    return undefined
  }

  handleAreaNameChange = (e) => {
    this.setState({
      area: {
        ...this.state.area,
        name: e.target.value
      }
    })
  }

  handleCloseDialog = () => {
    this.setState({ dialogOpen: false, area: {} })
    this.state.drawItems && this.state.drawItems.removeLayer(this.state.layer)
  }

  handleOpenDialog = (layer, drawItems) => {
    this.setState({
      dialogOpen: true,
      drawItems,
      layer,
      size_ha: layer.size,
      dialogType: 'draw'
    })
  }

  handleUploadAreaFileDialog = () => {
    this.setState({
      dialogOpen: true,
      dialogType: 'file_upload'
    })
  }

  handleSave = async () => {
    const { layer, size_ha, area, areas } = this.state
    const kind = layer.type
    const { handleError, o } = this.props

    let values = {
      kind,
      name: area.name,
      size_ha: size_ha,
      center: layer.center
    }

    kind === 'circle'
      ? values = this.getCircleValues(values)
      : values = this.getPolygonValues(values)

    this.setState({ saveArea: true })

    try {
      const { data } = await httpService.post(`/v4/o/${o}/areas`, values)
      areas.push(data)

      this.setState({
        dialogOpen: false,
        areas
      },
        () => this.references.mapReference.map
          .setView(
            [data.latitude, data.longitude],
            getZoom(data.size_ha)
          )
      )
    }
    catch (error) {
      handleError(error)
    }

    this.setState({ saveArea: false })
  }


  handleEdit = async (area) => {
    const { handleError, o } = this.props
    const areasList = this.state.areas

    try {
      const { data } = await httpService.put(`/v4/o/${o}/areas/${area.id}`, area)

      const areas = areasList.map(area => {
        if (area.id === data.id) {
          return data
        }

        return area
      })

      this.setState({
        areas
      })
    }
    catch (error) {
      handleError(error)
    }
  }

  handleEquipmentSelect = async (data) => {
    await this.fetchEquipmentMeasures(data.id)
    const { menusStatus: { toggleMenu } } = this.props
    const {equipmentMenuData} = this.state;
    toggleMenu(MENUS.mapEquipmentMenu, true)
    sendAmplitudeEvent('Map - Clicked equipment icon', {
      equipment_kind: data.kind,
      equipment_tags: data.tags,
      equipment_status: equipmentMenuData.online
    })
    IntercomAPI('trackEvent', 'Map - Clicked equipment icon')
  }

  handleMapClick = ({ latlng }) => {
    const { menusStatus: { menus } } = this.props
    const { step } = menus[MENUS.weatherForecastProduct]
    const { open } = menus[MENUS.weatherForecastSetupFixedModal]

    open && step === 1
      ? this.references.mapReference.addNewLayerPin(latlng)
      : this.references.productsReference && this.references.productsReference.handleSelectedLatLng(latlng)
  }

  handleCloseInfoMenu = () => {
    const { menusStatus: { menus, toggleMenu } } = this.props
    const { type } = this.state

    toggleMenu(menus[MENUS[type]], false)

    this.setState({ openInfoMenu: false })
  }

  handleCloseDialogProductMenu = () => this.setState({ isOpenProductDialogMenu: false })

  handleDrawAction = (value, action) => {
    this.setState({ [value]: action })
  }

  handleChangeAreasColors = areasStatus => {
    const { areas } = this.state

    const areasWithNewColors = getAreasColors(areas, areasStatus)

    this.setState({
      areas: areasWithNewColors
    })
  }

  handleRemoveAreaColors = () => {
    const { areas } = this.state
    const areasWithNewColors = getAreasColors(areas, [])

    this.setState({
      areas: areasWithNewColors
    })
  }

  fetchEquipmentStatus = async (type) => {
    const { handleError, current_organization_id } = this.props
    const { equipmentMenuData, equipmentMenu } = this.state
    const installed = String(type === 'installed')

    try {
      await apiService().put(`/api/v4/equipments/${equipmentMenu.id}/installed`, { organization_id: current_organization_id, equipment: { installed } })
      this.setState({ equipmentMenuData: { ...equipmentMenuData, status: type } })
    }
    catch (error) {
      handleError(error)
    }
  }

  groupAreasByKind = (areas) => {
    const kinds = ['polygon', 'circle']

    return kinds.reduce((groupedAreas, kind) => {
      groupedAreas[kind] = areas.filter(area => area.kind === kind)

      return groupedAreas
    }, {})
  }

  handleAreaSelect = async (areaId, reload = false) => {
    const { areas,crop } = this.state
    const {
      handleError,
      httpService: { apiRequest },
      menusStatus: { toggleMenu }
    } = this.props

    const selectedArea = areas.find(area => area.id === areaId)
    if (!selectedArea) return

    toggleMenu(MENUS.mapAreaMenu, true)
    this.references.mapReference.changeMapCenter([selectedArea.latitude, selectedArea.longitude], selectedArea.size_ha)

    this.setLoading()

    try {
      const { data } = await apiRequest(`areas/${areaId}`)

      const updatedAreas = areas.map(updateIfSameAttribute(['id', selectedArea.id], area => ({
        ...area,
        data
      })))

      this.setState({
        crop: data.crop,
        areas: updatedAreas,
        areaMenuData: {
          ...selectedArea,
          data
        }
      })

      sendAmplitudeEvent('Clicked Area (Map)', {
        template_name: data.crop ? data.crop.template_name : null,
        planting_date: data.crop ? data.crop.planting_date : null,
        expected_harvest_date: data.crop ? data.crop.expected_harvest_date : null,
        area_size: data.area_size,
        area_id: data.id
      })
      IntercomAPI('trackEvent', 'map_click_area')
    }
    catch (error) {
      handleError(error)
    }
    this.setLoading(false)
  }

  fetchEquipmentMeasures = async (equipmentId, isLoading = true) => {
    const { handleError, current_organization_id, equipments } = this.props
    const params = { organization_id: current_organization_id }

    const newEquipmentMenu = equipments.find(equipment => equipment.id === equipmentId)

    isLoading && this.setState({ loading: true })

    const getEquipmentStatus = (kind, status) =>
      kind === 'manual'
        ? 'manual'
        : status
          ? ''
          : 'unknown'

    try {
      const { data } = await apiService().get(`/api/v4/equipments/${equipmentId}/measures/latest`, { params })

      this.setState({
        equipmentMenuData: {
          descriptionVariables: !_.isEmpty(data.descriptions) ? _.sortBy(data.descriptions, ['index']) : [],
          variableValue: data.data || {},
          status: getEquipmentStatus(newEquipmentMenu.kind, data.equipment_status.online),
          hydrometer: data.water_meter_measures || {},
          online: data.equipment_status.online
        },
        equipmentMenu: newEquipmentMenu
      })
      
    }
    catch (error) {
      handleError(error)

      this.setState({
        equipmentMenuData: {
          descriptionVariables: [],
          variableValue: {},
          status: getEquipmentStatus(newEquipmentMenu.kind, false)
        },
        equipmentMenu: newEquipmentMenu
      })
    }

    isLoading && this.setState({ loading: false })
  }

  setLoading = (value = true) => {
    this.setState(
      ({ loading }) =>
        loading === value
          ? {}
          : { loading: value }
    )
  }

  handleAlertSelect = (data) => {
    const { menusStatus: { toggleMenu } } = this.props

    toggleMenu(MENUS.mapAlertsMenu, true)

    this.setState({ alertMenu: data })
  }

  fetchTrial = async () => {
    const { current_organization_id, handleError, httpService: { apiRequest } } = this.props
    const params = { organization_id: current_organization_id }

    try {
      const { data } = await apiRequest('check_trial', { params })
      const trialInfo = getTrialInfo(data)

      trialInfo['dialogOpen'] = trialInfo.isTrial && !(trialInfo['expirationDate'] >= 1)

      this.setState({ trialInfo })
    } catch (error) {
      handleError(error)
    }
  }

  handleCloseDialogTrial = () => this.setState({ trialInfo: { ...this.state.trialInfo, dialogOpen: false } })

  handleSubmitManualEquipment = async (values) => {
    const { handleError, httpService: { apiRequest }, current_organization_id, enqueueSnackbar } = this.props
    const { equipmentMenu } = this.state

    const params = {
      organization_id: current_organization_id,
      measure: values
    }

    try {
      await apiRequest.post(`equipments/${equipmentMenu.id}/measures`, params)

      this.fetchEquipmentMeasures(equipmentMenu.id, false)

      enqueueSnackbar(I18n.t('v4/equipment.pluviometer.actions.snackbar'), { variant: 'success' })
    }
    catch (error) {
      handleError(error)
    }
  }

  handleSubmitReadingEstimate = async (value, handleDialogOpenOrClose, equipmentId) => {
    const { handleError, httpService: { apiRequest }, current_organization_id } = this.props

    handleDialogOpenOrClose(true)

    const params = {
      organization_id: current_organization_id,
      measuring_adjustment: {
        adjusted_variable: 'water_estimative',
        adjusted_value: value.reading_estimate
      }
    }

    try {
      await apiRequest.post(`equipments/${equipmentId}/measuring_adjustments`, params)

      this.fetchEquipmentMeasures(equipmentId, false)
    }
    catch (error) {
      handleError(error)
    }

    handleDialogOpenOrClose(false)
  }

  toggle = (menu, value) => {
    const { menusStatus: { toggleMenu } } = this.props
    
    toggleMenu(MENUS[menu], value)
  }

  render() {
    const {
      areas,
      cultures,
      dialogOpen,
      dialogType,
      drawing,
      editing,
      equipments,
      forecastPointsList,
      size_ha,
      alertMenu,
      suggestions,
      anchor,
      equipmentMenuData,
      equipmentMenu,
      loading,
      areaMenuData,
      trialInfo,
      saveArea,
      crop
    } = this.state

    const {
      circleAreas,
      polygonAreas,
      products,
      handleError,
      has_write_permissions,
      o,
      menusStatus: { menus },
      router,
      isTrial
    } = this.props

    const {
      groupAreasByKind,
      handleDrawAction,
      handleEdit,
      handleMapClick,
      handleOpenDialog,
      handleRemoveAlerts,
      handleAlertSelect,
      handleRemoveAllAlerts,
      onSearchChange,
      clearSearch,
      onDownshiftChange,
      onSearchClick,
      fetchEquipmentStatus,
      fetchEquipmentMeasures,
      handleEquipmentSelect,
      handleAreaSelect,
      setLoading,
      handleCloseDialogTrial,
    } = this

    const initialCoordinates = this.getCenterCoordinates([...circleAreas, ...polygonAreas]) || defaultInitialCoordinates
    const { circle, polygon } = groupAreasByKind(areas)

    const { data: allAlerts } = menus[MENUS.alertsMenu]

    const { open: openAlerts } = menus[MENUS.mapAlertsMenu]

    const useCluster = false
    
    return (
      <>
        {
          !menus[MENUS.sideBarMenu].open &&
          <Search
            suggestions={suggestions}
            onSearchChange={onSearchChange}
            anchor={anchor}
            clearSearch={clearSearch}
            onSearchClick={onSearchClick}
            suggestionsMenuRef={this.references.suggestionsMenuReference}
            onDownshiftChange={onDownshiftChange}
            loading={loading}
            isTrial={isTrial}
          />
        }

        <Map
          alerts={allAlerts['alerts']}
          changeAreasColors={this.handleChangeAreasColors}
          circleAreas={circle}
          editing={editing}
          equipment={equipmentMenuData}
          forecastPointsList={forecastPointsList}
          hasForecastProduct={products.some(product => product.kind === 'weather_forecast')}
          handleAreaClick={handleAreaSelect}
          handleAreaFileControlClick={this.handleUploadAreaFileDialog}
          handleAlertSelect={handleAlertSelect}
          handleDrawAction={handleDrawAction}
          handleEdit={handleEdit}
          handleEquipmentClick={handleEquipmentSelect}
          handleError={handleError}
          handleMapClick={handleMapClick}
          handleOpenDialog={handleOpenDialog}
          has_write_permissions={has_write_permissions}
          mapAreas={areas}
          markers={equipments}
          onRef={(ref) => this.references.mapReference = ref}
          polygonAreas={polygon}
          products={products}
          removeColorsFromAreas={this.handleRemoveAreaColors}
          areaMenuData={areaMenuData}
          useCluster={useCluster}
          crop={crop}
          {...initialCoordinates}
        />

        <Dialog
          open={dialogOpen}
          onClose={this.handleClose}
          aria-labelledby='new-area-form-dialog'
          disableBackdropClick={true}
        >
          <NewAreaDialog
            size_ha={size_ha}
            dialogType={dialogType}
            handleAreaNameChange={this.handleAreaNameChange}
            handleClose={this.handleCloseDialog}
            handleSave={this.handleSave}
            handleError={handleError}
            saveArea={saveArea}
            o={o}
          />
        </Dialog>

        <DialogTrial
          open={!!trialInfo.dialogOpen}
          onClose={this.handleCloseDialogTrial}
          aria-labelledby='dialog-trial'
          disableBackdropClick={true}
          withCloseButton={true}
          fullScreen={false}
          fullWidth={false}
        >
          <ExpiredTrial
            handleCloseDialogTrial={handleCloseDialogTrial}
            intercomTarget='click_cta_modal_trial_expired'
            router={router}
          />
        </DialogTrial>

        {
          (!editing && !drawing) && menus[MENUS.mapEquipmentMenu].open &&
          <EquipmentInfoMenu
            loading={loading}
            fetchEquipmentStatus={fetchEquipmentStatus}
            equipments={equipments}
            fetchEquipmentMeasures={fetchEquipmentMeasures}
            equipmentMenuData={equipmentMenuData}
            selectedEquipment={equipmentMenu}
            router={router}
            handleSubmitManualEquipment={this.handleSubmitManualEquipment}
            handleSubmitReadingEstimate={this.handleSubmitReadingEstimate}
          />
        }

        {
          (!editing && !drawing) && openAlerts &&
          <AlertsInfoMenu
            handleRemoveAlerts={handleRemoveAlerts}
            handleRemoveAllAlerts={handleRemoveAllAlerts}
            alert={alertMenu}
          />
        }

        {
          (!editing && !drawing) && menus[MENUS.mapAreaMenu].open &&
          <AreaInfoMenu
            area={areaMenuData}
            areas={areas}
            cultures={cultures}
            handleAreaSelect={handleAreaSelect}
            loading={loading}
            setLoading={setLoading}
            handleError={handleError}
            trialInfo={trialInfo}
            handleEditAreaMenu={this.references.mapReference.handleCloseOrOpenEditAreaMenu}
          />
        }

        {
          (menus[MENUS.mapSatelliteImagery].open || menus[MENUS.mapSatelliteImageryScale].open) &&
          <SatelliteFooterMenu
            applyFilter={this.references.mapReference.applyFilter}
            removeFilter={this.references.mapReference.removeCurrentFilter}
            satelliteImageryName='mapSatelliteImagery'
            filterMenuName='satelliteImageryProduct'
            scaleMenuName='mapSatelliteImageryScale'
            toggle={this.toggle}
          />
        }

        {
          !editing && !drawing
          && menus[MENUS.weatherForecastSetupFixedModal].open
          && <FixedDialog getCenterOfView={this.references.mapReference.getCenterOfView} />
        }

        {
          menus[MENUS.mapEditAreaMenu].open &&
          <EdiAreaMenu
            handleSaveAreaMenu={this.references.mapReference.handleSaveAreaMenu}
            handleCancelAreaMenu={this.references.mapReference.handleCancelAreaMenu}
            enableOrDisableArea={this.references.mapReference.enableOrDisableArea}
          />
        }
      </>
    )
  }
}

Dashboard.defaultProps = {
  equipments: [],
  circleAreas: [],
  polygonAreas: [],
  products: []
}

export default
  withRouter(
    withHttpService(
      withMenusStatus(
        withSnackbar(Dashboard)
      )
    )
  )
