import React from 'react'
import { Form as FinalForm } from 'react-final-form'
import PropTypes from 'prop-types'

import { validate } from '@models/organization'
import { RailsForm } from '@support/RailsForm'
import Form from './Form'
import httpService from '@services/httpService'

export const New = ({ model, organization_kinds, predominant_cultures, o }) => {
  const timezone_browser = Intl.DateTimeFormat().resolvedOptions().timeZone

  const submit = data => {
    Object.assign(data, {timezone_user: timezone_browser});
    httpService({
      method: 'POST',
      url: `/v4/o/${o}/organizations`,
      data
    })
    .then(res => location.replace(res.data.url || `/v4/o/${o}/`))
  }

  return (
    <RailsForm
    component={FinalForm}
    action='create'
    url={`/v4/o/${o}/organizations`}
    handleSubmit={data => submit(data)}
    validate={validate}
    render={(props) => (
      <Form
        {...props}
        title={I18n.t('v4/organization.actions.add')}
        organizationKinds={organization_kinds}
        predominantCultures={predominant_cultures}
        hasTenant={true}
        o={o}
        timezone_user={timezone_browser}
      />
      )}
    />
    )
}

New.propTypes = {
  model: PropTypes.object,
  organization_kinds: PropTypes.array
}

export default New
