import React from 'react'
import { MTableCell } from 'material-table'

import { withStyles } from '@material-ui/core'

const TableCell = ({ classes, ...props }) => {
  const { columnDef: { field } } = props

  if (field === 'hydricStatus') {
    return props.value
      ? (
        <MTableCell
          {...props}
          className={classes[`IrrigationManagementTable_Status__${props.value}`]}
          value={I18n.t(`v4/irrigation_management.hydric_status.${props.value}.title`)}
        />
      )
      : (
        <MTableCell
          {...props}
          className={classes[`IrrigationManagementTable_Status__no_data`]}
          value={I18n.t(`v4/irrigation_management.hydric_status.no_data.title`)}
        />
      )
  }

  if (props.value == null) {
    return <MTableCell {...props} value='---' />
  }


  return field === 'area'
    ? <MTableCell {...props} />
    : <MTableCell
      {...props}
      value={
        props.value
          ? `${props.value} mm`
          : '---'
      }
    />
}
const style = theme => ({
  IrrigationManagementTable_Status__ideal_moisture: {
    fontWeight: 'bold',
    color: theme.palette.irrigationManagement.green
  },
  IrrigationManagementTable_Status__hydric_deficit: {
    fontWeight: 'bold',
    color: theme.palette.irrigationManagement.red
  },
  IrrigationManagementTable_Status__alert_moisture: {
    fontWeight: 'bold',
    color: theme.palette.irrigationManagement.yellow
  },
  IrrigationManagementTable_Status__saturated: {
    fontWeight: 'bold',
    color: theme.palette.irrigationManagement.blue
  },
  IrrigationManagementTable_Status__no_data: {
    fontWeight: 'bold',
    color: theme.palette.irrigationManagement.gray
  },
})

export default withStyles(style)(TableCell)
