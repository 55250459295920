import React from "react";

import {
  Button,
  Chip,
  CircularProgress,
  Collapse,
  Fab,
  Grid,
  IconButton,
  Paper,
  Switch,
  Tab,
  Tabs,
  Typography,
  Select,
  MenuItem,
  OutlinedInput,
  FormControl,
  InputLabel,
} from "@material-ui/core";
import Add from "@material-ui/icons/Add";

import { withStyles } from "@material-ui/core/styles";

import Table from "@ui/Table";
import styles from "./styles";

import { Form as FinalForm } from "react-final-form";

import { withHttpService } from "@contexts/HttpServiceProvider";
import { withRouter } from "@contexts/RouterProvider";
import { RailsForm } from "@support/RailsForm";
import Filter from "./Filter";
import { getDateWithHours } from "@services/locale";

import { KeyboardArrowRight, KeyboardArrowDown } from "@material-ui/icons";

import styled, { keyframes } from "styled-components";

import Flash from "react-animations/lib/flash";

import {
  convertValueLocale,
  convertVariableWindSpeed,
  deserialize,
  getLabel,
  getVariableLabel,
  getAlertsHeaders,
  getInitialValues,
  getHistoryIcon,
  setTranslateVariables,
  AlertCategories,
} from "@models/alerts";

const alertsTab = ["title", "history"];

class Index extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      alerts: [],
      areas: [],
      variables: [],
      tabs: 0,
      alertsHistory: [],
      checkeds: [],
      loading: false,
      initialValues: {},
      openCards: [],
      isCard: false,
      animation: true,
      filterCategory: "all",
    };
  }

  componentDidMount() {
    const alertDiv = document.getElementById("alert_index");
    const minWidth = 800;
    const isCard = alertDiv.offsetWidth <= minWidth;

    this.setState({ initialValues: getInitialValues(), isCard });
    this.fetchData();
  }

  async fetchData() {
    const {
      handleError,
      httpService: { apiRequest },
    } = this.props;

    this.setState({ loading: true });

    try {
      const requests = [
        apiRequest("alerts/notifications/history"),
        apiRequest("alerts/notifications/variables"),
        apiRequest("alerts/notifications/alerts"),
        apiRequest("alerts/notifications/areas"),
      ].map((request) =>
        request.catch((error) => {
          handleError(error);

          return { data: { data: [] } };
        })
      );

      await Promise.all(requests).then(
        ([alerts_history, variables, alerts, areas]) => {
          this.setState({
            checkeds: alerts.data.map((alert) => alert.active),
            alerts: this.getValues(alerts.data, variables.data),
            areas: areas.data,
            alertsHistory: this.setVariableWindSpeed(
              this.addDateWithHours(alerts_history.data)
            ),
            openCards: alerts_history.data.map((alert) => !alert.id),
            variables: setTranslateVariables(variables.data),
          });
        }
      );
    } catch (error) {
      handleError(error);
    }

    this.setState({ loading: false });
  }

  getValues = (allAlerts, allVariables) => {
    const deserialize = "deserialize";

    const alerts = allAlerts.map((alert) => {
      const rules = alert.rules.map((rule) => {
        const { unity } = allVariables.find(
          (value) => value.variable === rule.variable
        ) || { unity: null };

        return {
          ...rule,
          unity,
        };
      });
      return {
        ...alert,
        rules: convertValueLocale(
          convertVariableWindSpeed(rules, deserialize),
          deserialize
        ),
      };
    });

    return alerts;
  };

  handleRemoveAlert = async (alertId) => {
    const {
      router,
      handleError,
      httpService: { request },
    } = this.props;

    try {
      await request.delete(`alerts/${alertId}.json`);
      router.visit("alerts");
    } catch (error) {
      handleError(error);
    }
  };

  handleUpdate = async (alert) => {
    const {
      router,
      handleError,
      httpService: { request },
    } = this.props;
    const updatedAlert = !alert.active;

    try {
      await request.post("alerts/toggle_alert", {
        alert_id: alert.id,
        active: updatedAlert,
      });
      router.visit("alerts");
    } catch (error) {
      handleError(error);
    }
  };

  toggleAlert = (index, alert) => {
    const { checkeds } = this.state;

    checkeds[index] = !checkeds[index];

    this.setState(
      {
        checkeds,
      },
      () => this.handleUpdate(alert)
    );
  };

  handleSubmit = async (values) => {
    const {
      handleError,
      httpService: { apiRequest },
    } = this.props;

    try {
      const { data } = await apiRequest("alerts/notifications/history", {
        params: { ...values },
      });

      this.setState({
        alertsHistory: this.setVariableWindSpeed(this.addDateWithHours(data)),
        initialValues: values,
      });
    } catch (error) {
      handleError(error);
    }
  };

  handleChangeTabs = (selectedTab) => this.setState({ tabs: selectedTab });

  handleChangeCard = (currentCard) => {
    const { openCards } = this.state;

    const newOpenCards = openCards;
    newOpenCards[currentCard] = !openCards[currentCard];

    this.setState({ openCards: newOpenCards, animation: false });
  };

  addDateWithHours = (data = []) =>
    data.map((alert) => ({
      ...alert,
      dateAndTime: getDateWithHours(alert.triggered_in),
    }));

  setVariableWindSpeed = (alerts = []) =>
    alerts.map((alert) => ({
      ...alert,
      rules: setTranslateVariables(
        convertVariableWindSpeed(alert.rules, "deserialize")
      ),
    }));

  handleFilterChange = (event) => {
    this.setState({ filterCategory: event.target.value });
  };

  render() {
    const { classes, router } = this.props;
    const {
      alerts,
      tabs,
      checkeds,
      areas,
      variables,
      alertsHistory,
      loading,
      initialValues,
      openCards,
      isCard,
      animation,
      filterCategory,
    } = this.state;

    const filerCategories = [
      {
        name: I18n.t("v4/alerts.new.steps.category.alerts_categories.all"),
        value: "all",
      },
      ...AlertCategories,
    ];

    const filteredAlerts = alerts.filter((alert) => {
      if (filterCategory === "all") {
        return alert;
      } else if (filterCategory === "others") {
        return alert.alert_params.category === "others" ||
          !alert.alert_params.category
          ? alert
          : null;
      }
      return alert.alert_params.category === filterCategory && alert;
    });

    return (
      <>
        <Paper
          className={classes.AlertPage_wrapper}
          elevation={0}
          id="alert_index"
        >
          <Tabs
            className={classes.tabs}
            variant="scrollable"
            scrollButtons="on"
            value={tabs}
            indicatorColor="primary"
          >
            {alertsTab.map((tab, index) => (
              <Tab
                label={I18n.t(`v4/alerts.alerts.${tab}`)}
                key={`alertsTab_${index}`}
                onClick={() => this.handleChangeTabs(index)}
              />
            ))}
          </Tabs>

          {loading ? (
            <div className={classes.Alert_loading}>
              <CircularProgress size={80} />
            </div>
          ) : (
            <>
              {tabs === 0 ? (
                <>
                  {!_.isEmpty(alerts) ? (
                    <Grid
                      container
                      spacing={8}
                      className={classes.AlertContent_index}
                    >
                      <Grid
                        item
                        xs={12}
                        sm={12}
                        md={12}
                      >
                        <FormControl variant="outlined" style={{ marginBottom: "15px" }}>
                          <InputLabel
                            ref={(ref) => {
                              this.InputLabelRef = ref;
                            }}
                            htmlFor="outlined-age-simple"
                          >
                            {I18n.t(
                              "v4/alerts.new.steps.category.filter_title"
                            )}
                          </InputLabel>
                          <Select
                            id="alerts-filter"
                            value={filterCategory}
                            label={I18n.t(
                              "v4/alerts.new.steps.category.filter_title"
                            )}
                            onChange={this.handleFilterChange}
                            defaultValue="all"
                            placeholder={I18n.t(
                              "v4/alerts.new.steps.category.filter_title"
                            )}
                            input={
                              <OutlinedInput
                                labelWidth={120}
                                name="alerts-filter"
                                id="outlined-age-simple"
                              />
                            }
                          >
                            {filerCategories.map((category) => (
                              <MenuItem
                                key={category.value}
                                value={category.value}
                              >
                                {category.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      </Grid>

                      {filteredAlerts.map((alert, index) => (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={4}
                          className={classes.Alert_Card}
                        >
                          <Section
                            classes={classes}
                            alert={alert}
                            key={`alertsCard_${alert.id}`}
                            handleRemoveAlert={this.handleRemoveAlert}
                            toggleAlert={this.toggleAlert}
                            checkeds={checkeds}
                            index={index}
                            router={router}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <Typography
                      className={classes.Alert_noData}
                      component="h3"
                      variant="h5"
                    >
                      {I18n.t("info.no_data")}
                    </Typography>
                  )}
                </>
              ) : (
                <Grid
                  container
                  spacing={8}
                  className={classes.AlertContent_index}
                >
                  <RailsForm
                    component={FinalForm}
                    initialValues={initialValues}
                    handleSubmit={this.handleSubmit}
                    render={(props) => (
                      <Filter
                        areas={areas || []}
                        variables={variables || []}
                        alerts={alerts || []}
                        {...props}
                      />
                    )}
                  />

                  {isCard && (
                    <Typography
                      variant="caption"
                      align="center"
                      className={classes.AlertCardHistory_title}
                    >
                      {_.isEmpty(alertsHistory)
                        ? I18n.t("info.no_data")
                        : I18n.t("v4/alerts.alerts.details_variables")}
                    </Typography>
                  )}

                  {!isCard ? (
                    <div className={classes.Alert_table}>
                      <Table
                        tableKey="store-admin-coupons-plans_products"
                        columns={getAlertsHeaders()}
                        data={deserialize(alertsHistory, classes)}
                        title={
                          <Typography variant="body1" align="center">
                            {I18n.t("v4/alerts.alerts.details_variables")}
                          </Typography>
                        }
                        actions={[]}
                        detailPanel={[
                          {
                            icon: (rowData) => (
                              <KeyboardArrowRight data-intercom-target="seen_historic_parameter" />
                            ),
                            openIcon: (rowData) => <KeyboardArrowDown />,
                            render: (rowData) => (
                              <ShowRules
                                classes={classes}
                                rules={rowData.rules}
                              />
                            ),
                          },
                        ]}
                        localization={{
                          body: {
                            emptyDataSourceMessage: I18n.t("info.no_data"),
                          },
                        }}
                        onRowClick={(event, rowData, togglePanel) =>
                          togglePanel()
                        }
                        options={{
                          showEmptyDataSourceMessage: true,
                          sorting: false,
                          actionsColumnIndex: -1,
                          headerStyle: {
                            backgroundColor: "#f5f5f5",
                          },
                          rowStyle: {
                            cursor: "move",
                          },
                          search: false,
                        }}
                      />
                    </div>
                  ) : (
                    <div className={classes.AlertCardHistory_wrapper}>
                      <Grid
                        container
                        spacing={8}
                        className={classes.AlertContent_index}
                      >
                        {alertsHistory.map((alert, index) => (
                          <Grid
                            item
                            xs={12}
                            sm={6}
                            md={4}
                            className={classes.Alert_Card}
                          >
                            <CardHistory
                              alert={alert}
                              classes={classes}
                              open={openCards[index]}
                              handleChangeCard={() =>
                                this.handleChangeCard(index)
                              }
                              animation={animation}
                            />
                          </Grid>
                        ))}
                      </Grid>
                    </div>
                  )}
                </Grid>
              )}
            </>
          )}
        </Paper>
        <Fab
          className={classes.Alert_add}
          color="primary"
          onClick={() => router.visit("alerts/new")}
        >
          <Add />
        </Fab>
      </>
    );
  }
}

const Section = ({
  classes,
  alert,
  handleRemoveAlert,
  toggleAlert,
  checkeds,
  index,
  router,
}) => {
  return (
    <Paper elevation={3} className={classes.AlertPaper_index}>
      <Typography variant="h6">{alert.name}</Typography>

      <div className={classes.AlertToggleWrapper}>
        <Typography variant="body1" className={classes.AlertInfo}>
          {`${I18n.t("v4/alerts.alerts.alert_triggered")}: ${
            alert.last_triggered
          }`}
        </Typography>

        <Switch
          checked={checkeds[index]}
          onChange={() => toggleAlert(index, alert)}
        />
      </div>

      <Typography variant="body1" className={classes.AlertInfo}>
        {`${I18n.t("v4/alerts.alerts.info_areas")}:`}
      </Typography>
      <section className={classes.AlertVariables}>
        {alert.areas.map((alertValue, index) => (
          <Variable
            classes={classes}
            value={alertValue}
            key={`alert-value-${index}`}
            alert={alert}
          />
        ))}
      </section>

      <Typography variant="body1" className={classes.AlertInfo}>
        {`${I18n.t("v4/alerts.new.steps.category.category_name")}:`}
      </Typography>
      <section className={classes.AlertVariables}>
        <Variable
          classes={classes}
          value={
            !!alert.alert_params.category
              ? I18n.t(
                  `v4/alerts.new.steps.category.alerts_categories.${alert.alert_params.category}`
                )
              : I18n.t(`v4/alerts.new.steps.category.alerts_categories.others`)
          }
          key={`alertCategory-${index}`}
          alert={alert}
        />
      </section>

      <Typography variant="body1" className={classes.AlertInfo}>
        {`${I18n.t("v4/alerts.alerts.info_variables")}:`}
      </Typography>
      <section className={classes.AlertVariables}>
        {alert.rules.map((alertValue, index) => (
          <Variable
            classes={classes}
            value={alertValue}
            key={`alertValue-${index}`}
            alert={alert}
          />
        ))}
      </section>

      <Typography variant="body1" className={classes.AlertInfo}>
        {`${I18n.t("v4/alerts.alerts.info_actions")}:`}
      </Typography>
      <section className={classes.AlertVariables}>
        {!_.isEmpty(alert.actions) ? (
          alert.actions.map((alertValue, index) => (
            <Variable
              classes={classes}
              value={alertValue}
              key={`alertValue-${index}`}
              alert={alert}
            />
          ))
        ) : (
          <Variable
            classes={classes}
            value={{
              kind: I18n.t("v4/alerts.new.steps.preview.empty_medias_index"),
              contacts: [],
            }}
            key={"actions_empty"}
            alert={alert}
          />
        )}
      </section>

      <footer className={classes.Alert_actions}>
        <Button
          className={classes.Alert_action}
          color="primary"
          onClick={() => handleRemoveAlert(alert.id)}
        >
          {I18n.t("actions.delete")}
        </Button>

        <Button
          className={classes.Alert_action}
          color="primary"
          variant="contained"
          onClick={() => router.visit(`alerts/${alert.id}/edit`)}
        >
          {I18n.t("actions.edit")}
        </Button>
      </footer>
    </Paper>
  );
};

const Variable = ({ classes, value, alert }) => {
  const labelValue = getLabel(value);
  const rawLabel =
    typeof labelValue === "string" ? labelValue : getVariableLabel(value);
  const label = FormatLabel(rawLabel);
  return (
    <Chip
      label={label}
      color={alert.active ? "primary" : "default"}
      className={classes.AlertChip}
      classes={{ label: classes.AlertChip_label }}
      component="a"
    />
  );
};

const ShowRules = ({ classes, rules = [], isCard = false }) => {
  const activeRule = { active: true };

  return (
    <section
      className={
        isCard ? classes.AlertCardHistory_card : classes.AlertVariables_table
      }
    >
      {!_.isEmpty(rules) &&
        rules.map((rule, index) => (
          <Variable
            classes={classes}
            value={rule}
            key={`rule-${index}-${rule.max_value}`}
            alert={activeRule}
          />
        ))}
    </section>
  );
};

const CardHistory = ({ classes, alert, open, handleChangeCard, animation }) => (
  <Collapse in={open} collapsedHeight="250px">
    <Paper
      elevation={4}
      className={
        open
          ? classes.AlertCardHistory_collapse
          : classes.AlertCardHistory_not_collapse
      }
      onClick={handleChangeCard}
    >
      <div className={classes.AlertCardHistory_container}>
        <div className={classes.AlertCardHistory_header}>
          <div className={classes.AlertCardHistory_infos}>
            {getHistoryIcon("alert")}

            <Typography
              variant="body2"
              className={classes.AlertCardHistory_info}
            >
              {alert.alert_name}
            </Typography>
          </div>

          <PulseButton classes={classes} animation={animation} open={open} />
        </div>

        <Typography variant="body1" className={classes.AlertCardHistory_area}>
          {`${alert.dateAndTime.infoDate} | ${alert.dateAndTime.infoHour}`}
        </Typography>
      </div>

      <div className={classes.AlertCardHistory_container}>
        <div className={classes.AlertCardHistory_infos}>
          {getHistoryIcon("area")}

          <Typography variant="body2" className={classes.AlertCardHistory_info}>
            {I18n.t("v4/alerts.alerts.variables.areas")}
          </Typography>

          <Typography variant="body1" className={classes.AlertCardHistory_info}>
            {alert.areas}
          </Typography>
        </div>

        <Typography
          variant="body1"
          className={
            !alert.read
              ? `${classes.AlertCardHistory_status} ${classes.Alert_not_viewed}`
              : classes.AlertCardHistory_status
          }
        >
          {alert.read
            ? I18n.t("v4/alerts.alerts.viewed")
            : I18n.t("v4/alerts.alerts.not_viewed")}
        </Typography>
      </div>

      {open && (
        <ShowRules classes={classes} rules={alert.rules || []} isCard={true} />
      )}
    </Paper>
  </Collapse>
);

const PulseButton = ({ classes, animation, open }) => {
  const PulseAnimation = keyframes`${Flash}`;

  const PulseDiv = styled.div`
    animation: infinite 4s ${PulseAnimation};
  `;

  return animation ? (
    <PulseDiv>
      <IconButton className={classes.AlertCardHistory_icon}>
        <KeyboardArrowRight />
      </IconButton>
    </PulseDiv>
  ) : (
    <IconButton>
      {open ? <KeyboardArrowDown /> : <KeyboardArrowRight />}
    </IconButton>
  );
};

const FormatLabel = (label) => {
  return label.charAt(0).toUpperCase() + label.toLowerCase().slice(1);
};

Index.defaultProps = {
  alerts: [],
  areas: [],
  variables: [],
};

export default withHttpService(withRouter(withStyles(styles)(Index)));
