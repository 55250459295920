import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import Table from '@ui/Table'

import httpService from '@services/httpService'
import { getCultureHeaders } from '@models/culture'
import DeleteDialog from '../ui/DeleteDialog'
import styles from '@styles/indexPage'

class Index extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isDialogOpen: false,
      id: null,
      cultures: []
    }

    this.closeDialog = this.closeDialog.bind(this)
    this.handleCloneCulture = this.handleCloneCulture.bind(this)
  }

  componentDidMount() {
    this.setState({ cultures: this.props.model })
  }

  closeDialog() {
    this.setState({ isDialogOpen: false })
  }

  handleCloneCulture = async (culture) => {
    const newCulture = {
      ...culture,
      name: `${culture.name}-${I18n.t('v4/culture.copy')}`
    }
    const { handleError, o } = this.props

    try {
      const { data } = await httpService.post(`/v4/o/${o}/cultures`, newCulture)

      this.setState({
        cultures: [
          data,
          ...this.state.cultures
        ]
      })
    }
    catch (error) {
      handleError(error)
    }
  }

  render() {
    const { classes, handleError, o } = this.props
    const { isDialogOpen, id, cultures } = this.state

    return (
      <Paper className={classes.page__wrapper} elevation={0}>
        <header className={classes.page__header}>
          <Typography component='h2' variant='h5'>
            {I18n.t('activerecord.models.v4/culture.other')}
          </Typography>
        </header>
        <Button
          variant='outlined'
          color='primary'
          className={classes.page__newResourceButton}
          href={`/v4/o/${o}/cultures/new`}
        >
          {I18n.t('actions.add')} {I18n.t('activerecord.models.v4/culture.one')}
        </Button>

        <Table
          tableKey='cultures-index'
          components={{
            Container: props => <div>{props.children}</div>,
          }}
          columns={getCultureHeaders()}
          data={cultures}
          actions={[
            rowData => ({
              icon: 'file_copy',
              iconProps: { 'data-testid': `clone-${rowData.id}` },
              tooltip: I18n.t('v4/culture.actions.copy'),
              onClick: (event, rowData) => {
                this.handleCloneCulture(rowData)
              }
            }),
            rowData => ({
              icon: 'create',
              iconProps: { 'data-testid': `edit-${rowData.id}` },
              tooltip: I18n.t('actions.edit'),
              onClick: (event, rowData) => {
                Turbolinks.visit(`/v4/o/${o}/cultures/${rowData.id}/edit`)
              }
            }),
            rowData => ({
              icon: 'delete',
              iconProps: { 'data-testid': `delete-${rowData.id}` },
              tooltip: I18n.t('actions.remove'),
              onClick: (event, rowData) => {
                this.setState({ isDialogOpen: true, id: rowData.id })
              }
            })
          ]}
          options={{
            showTitle: false
          }}
        />

        <DeleteDialog
          dialogTitle={`${I18n.t('actions.remove')} ${I18n.t('activerecord.models.v4/culture.one')}?`}
          dialogText={I18n.t('confirmation.remove')}
          successUrl={`/v4/o/${o}/cultures`}
          deleteUrl={`/v4/o/${o}/cultures/${id}.json`}
          onCancel={this.closeDialog}
          onClose={this.closeDialog}
          open={isDialogOpen}
          handleError={handleError}
        />
      </Paper>
    )
  }
}

Index.propTypes = {
  model: PropTypes.array
}

export default withStyles(styles)(Index)
