import React from 'react'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import { Typography, InputLabel } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import { Field } from 'react-final-form'
import UploadIcon from '@material-ui/icons/Cloud'
import Dropzone from 'react-dropzone'
import { acceptedMimeTypes } from '@models/admin/product'
import ParamsTable from './ParamsTable'

import Select from '@ui/Fields/Select'
import TextField from '@ui/Fields/TextField'
import styles from '@theme/styles/forms'

const Form = ({
  handleSubmit,
  classes,
  title,
  values,
  disableSelect = false,
  params,
  kinds,
  o
}) => {
  const [currentIcon, setIcon] = React.useState('')
  const [image, setImage] = React.useState(values.icon_url)

  const updateParams = (params) => {
    values['params'] = params
  }

  const onFileDrop = (files, rejectedFiles) => {
    if (rejectedFiles.length > 0) return

    const newFile = files[0]

    values['icon'] = newFile
    setIcon(newFile.name)
    setImage(null)
  }

  const dropzoneClassName = ({ isDragActive, isDragReject }) => {
    if (isDragReject) {
      return classes.form__dropzone_onDragReject
    }
    if (isDragActive) {
      return classes.form__dropzone_onDrag
    }

    return null
  }

  return (
    <form className={classes.form} onSubmit={handleSubmit} data-testid='product-form'>
      <Paper className={classes.form__wrapper} elevation={0}>
        <header className={classes.form__header}>
          <Typography component='h2' variant='h5'>
            {title}
          </Typography>
        </header>
        <main className={classes.form__fields}>
          <div className={classes.form__field__wrapper}>
            <Field
              component={TextField}
              className={classes.form__fieldWithError}
              margin='normal'
              fullWidth
              InputLabelProps={{ shrink: true }}
              variant='outlined'
              label={I18n.t('activerecord.attributes.v4/product.label')}
              name='label'
              required
            />
          </div>

          <div className={classes.form__field__wrapper}>
            <Field
              component={Select}
              className={classes.form__fieldWithError}
              margin='normal'
              fullWidth
              data={kinds}
              fields={{ id: 'id', name: 'name' }}
              InputLabelProps={{ shrink: true }}
              variant='outlined'
              label={I18n.t('activerecord.attributes.v4/product.kind')}
              name='kind'
              disabled={disableSelect}
              required
            />
          </div>

          <div className={classes.form__field__wrapper}>
            <Field
              component={TextField}
              className={classes.form__fieldWithError}
              margin='normal'
              fullWidth
              InputLabelProps={{ shrink: true }}
              variant='outlined'
              label={I18n.t('activerecord.attributes.v4/product.path')}
              name='path'
              placeholder='/v4/weather'
              required
            />
          </div>

          <div className={classes.form__field__wrapper}>
            <InputLabel shrink>
              {I18n.t('activerecord.attributes.v4/product.icon')}
            </InputLabel>
            <Dropzone
              onDrop={onFileDrop}
              accept={acceptedMimeTypes}
              multiple={false}
            >
              {({ getRootProps, getInputProps, isDragActive, isDragReject }) => {
                const className = dropzoneClassName({ isDragActive, isDragReject })

                return (
                  <div
                    variant='contained'
                    color='primary'
                    {...getRootProps()}
                    className={`${classes.form__dropzone} ${className}`}
                  >
                    <input {...getInputProps()} />
                    <UploadIcon />
                    <div>
                      {!isDragReject ?
                        I18n.t('v4/product.new.file.upload_new_file') :
                        I18n.t('v4/product.new.file.reject_new_file')}
                    </div>
                    {
                      currentIcon &&
                      <Typography className={classes.form__dropzone_selectedFile}>
                        {`${I18n.t('v4/product.new.file.selected')}: ${currentIcon}`}
                      </Typography>
                    }
                  </div>
                )
              }}
            </Dropzone>

            {
              image &&
              <div className={classes.form__currentIcon_wrapper}>
                <InputLabel shrink>
                  {I18n.t('v4/product.new.file.current_icon')}
                </InputLabel>
                <div className={classes.form__currentIcon}>
                  <img
                    src={image}
                    alt={I18n.t('activerecord.attributes.v4/product.icon')}
                    width='15%'
                  />
                </div>
              </div>
            }
          </div>
          <ParamsTable
            updateParams={updateParams}
            params={params}
          />
        </main>
        <footer className={classes.form__actions}>
          <Button
            className={classes.form__action}
            color='primary'
            type='submit'
            variant='contained'
          >
            {title}
          </Button>

          <Button
            className={classes.form__action}
            color='secondary'
            href={`/v4/o/${o}/admin/products`}
          >
            {I18n.t('actions.back')}
          </Button>
        </footer>
      </Paper>
    </form>
  )
}

Form.defaultProps = {
  params: []
}

export default withStyles(styles)(Form)
