import React from 'react'
import PropTypes from 'prop-types'
import { Form as FinalForm } from 'react-final-form'

import { validate } from '@models/admin/tenantUsers'
import { RailsForm } from '@support/RailsForm'
import Form from './Form'

export const Edit = ({ model, tenant, users, o }) =>
  <RailsForm
    component={FinalForm}
    action='update'
    url={`/v4/o/${o}/admin/tenants/${tenant.id}/users/${model.id}`}
    successUrl={`/v4/o/${o}/admin/tenants/${tenant.id}/users/${model.id}`}
    validate={validate}
    initialValues={model}
    render={(props) => (
      <Form
        {...props}
        title={I18n.t('v4/tenant.members.edit_admin')}
        users={users}
        tenant={tenant}
        editing={true}
        o={o}
      />
    )}
  />

Edit.defaultProps = {
  model: {},
  tenant: {}
}

Edit.propTypes = {
  model: PropTypes.object
}

export default Edit
