import React from 'react'

import { Typography } from '@material-ui/core'

import { getBatteryStatus } from '@models/equipment/equipment'

const Battery = ({ classes, variable }) => {
  if (!variable || variable.value === 'error' || !variable.value) {
    return (
      <div className={classes.Equipment_icon}>
        <Typography variant='body1'>
          {I18n.t(`v4/equipment.dashboard.battery_status.error`)}
        </Typography>
      </div>
    )
  }

  return (
    <div className={classes.Equipment_Battery} key={`variable_${variable.name}`}>
      <div className={classes.Equipment_icon}>
        {getBatteryStatus(variable.value, classes)['icon']}

        <div>
          <Typography variant='body1'>
            {getBatteryStatus(variable.value, classes)['status']}
          </Typography>

          <Typography variant='body1' variant='caption'>
            {getBatteryStatus(variable.value, classes)['info']}
          </Typography>
        </div>
      </div>
    </div>
  )
}
export default Battery
