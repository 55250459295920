import React from 'react'
import Form from './NewPlantationFormWrapper'
import { Button } from '@material-ui/core'
import Dialog from '@ui/Dialog'
import { validateNewPlantation, getCurrentDate } from '@models/dashboardArea'

class NewAreaCultureForm extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      dialogIsOpen: false
    }
  }

  closeDialog = () => this.setState({ dialogIsOpen: false })
  openDialog = () => this.setState({ dialogIsOpen: true })

  render() {
    const { classes, handleSubmit, cultures } = this.props
    return (
      <div className={classes.Dashboard__newAreaCulture_form}>
        <Button
          color='primary'
          className={classes.Dashboard__newAreaCulture_action}
          variant='contained'
          onClick={this.openDialog}
        >
          {I18n.t('v4/area.dashboard.plant')}
        </Button>

        <Dialog
          open={this.state.dialogIsOpen}
          onClose={this.closeDialog}
          title={I18n.t('v4/area.dashboard.plant')}
        >
            <Form 
              handleSubmit={(values) => {
                handleSubmit(values)
                this.closeDialog()
              }}
              onCancel={this.closeDialog}
              cultures={cultures}
            />

        </Dialog>
      </div>
    )
  }
}

export default NewAreaCultureForm
