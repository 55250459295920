export default () => ({
  Loading: {
    alignItems: 'center',
    justifyContent: 'center',
    position: 'relative'
  },
  Loading_wrapper: {
    padding: '20px',
    textAlign: 'center'
  }
})
