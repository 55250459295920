import React from 'react'

import { Typography } from '@material-ui/core'

import WindDirection from '@ui/WindDirection'

import { getVariableIcon } from '@models/equipment/equipment'

import { parseDecimalToTime } from '@services/locale'

const Variables = ({ classes, variable, equipmentMenuData }) =>
  <div className={classes.Equipment_Card} key={`variable_${variable.name}`}>
    <div className={classes.Equipment_icon}>
      {getVariableIcon(classes)[variable.name]}

      <Typography variant='body1'>
        {
          (variable.name === 'lwh' || variable.name === 'lwl')
            ? variable.short_description
            : variable.description
        }
      </Typography>
    </div>

    <div className={classes.Equipment_Variables}>
      <Typography className={classes.Equipment_icon}>
        {
          !!variable.value
            ? variable.name === 'lwl' || variable.name === 'lwh'
              ? `${parseDecimalToTime(variable.value)}`
              : variable.name !== 'wd1' && `${variable.value} ${variable.unit}`
            : `-- ${variable.unit}`
        }
      </Typography>

      {
        variable.name === 'wd1' && <div>
          {
            !!equipmentMenuData.variableValue['wd1']
              ? <div className={classes.Equipment_wind_rose}>
                <WindDirection
                  classes={classes}
                  angle={equipmentMenuData.variableValue['wd1']}
                  align={'left'}
                />
              </div>
              : getVariableIcon(classes)['wind_rose_no_info']
          }
        </div>
      }
    </div>
  </div>

export default Variables
