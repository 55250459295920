import React from 'react'
import { Field } from 'react-final-form'

import { Button, Paper, withStyles } from '@material-ui/core'

import SwitchField from '@ui/Fields/SwitchField'
import Select from '@ui/Fields/Select'

import { sendAmplitudeEvent } from '@utils/amplitudeEvent'
import { IntercomAPI } from '@utils/IntercomAPI';

import styles from './styles'

const Filter = ({
  handleSubmit,
  classes,
  availableDates,
  orderBy,
  orderOptions,
  form,
  disableFields,
  hiddenFields,
  values
}) => {
  const orderByState = form.getFieldState('orderBy') || {}

  return (
    <form onSubmit={handleSubmit}>
      <Paper className={classes.Filter_FieldsWrapper}>

        {
          hiddenFields.includes('date') || values.showFinishedManagements
            ? null
            : <Field
              className={classes.Filter_Field}
              component={Select}
              data={availableDates || []}
              initialValue={0}
              fields={{ id: 'id', name: 'name' }}
              InputLabelProps={{ shrink: true }}
              label={I18n.t('v4/irrigation_management.filter.field_labels.date')}
              margin='normal'
              name='date'
              disabled={disableFields}
              required
              variant='outlined'
              onFocus={() => {
                sendAmplitudeEvent('Aqua - List - Date box');
                IntercomAPI('trackEvent', 'Aqua - List - Date box')
              }}
            />
        }

        {
          hiddenFields.includes('orderBy')
            ? null
            : (
              <Field
                className={classes.Filter_Field}
                component={Select}
                data={orderBy}
                fields={{ id: 'field', name: 'name' }}
                InputLabelProps={{ shrink: true }}
                label={I18n.t('v4/irrigation_management.filter.field_labels.order_by')}
                margin='normal'
                name='orderBy'
                disabled={disableFields}
                variant='outlined'
                onFocus={() => {
                  sendAmplitudeEvent('Aqua - List - Selected list sorting type ');
                  IntercomAPI('trackEvent', 'Aqua - List - Selected list sorting type ')
                }}
              />
            )
        }

        {
          hiddenFields.includes('order')
            ? null
            : (
              <Field
                className={classes.Filter_Field}
                component={Select}
                data={orderOptions}
                initialValue={orderOptions[0].id}
                fields={{ id: 'id', name: 'name' }}
                InputLabelProps={{ shrink: true }}
                label={I18n.t('v4/irrigation_management.filter.field_labels.order')}
                margin='normal'
                name='order'
                disabled={!orderByState.value}
                fullWidth
                variant='outlined'
                onFocus={() => {
                  sendAmplitudeEvent('Aqua - List - Selected list sequence');
                  IntercomAPI('trackEvent', 'Aqua - List - Selected list sequence')
                }}
              />
            )
        }

        {
          hiddenFields.includes('showFinishedManagements')
            ? null
            : (
              <Field
                className={classes.Finished_Managements_Filter}
                component={SwitchField}
                onSwitchClick={() => {
                  sendAmplitudeEvent('Aqua - List - toggle switch');
                  IntercomAPI('trackEvent', 'Aqua - List - toggle switch')
                }}
                leftLabel={I18n.t('v4/irrigation_management.cards_management_status.running')}
                rightLabel={I18n.t('v4/irrigation_management.cards_management_status.finished')}
                name='showFinishedManagements'
                type='checkbox'
                initialValue={false}
              />
            )
        }

        <Button
          className={classes.Filter_Action}
          name='filter'
          color='primary'
          type='submit'
          variant='contained'
          disabled={disableFields}
          onClick={()=> {
            sendAmplitudeEvent('Aqua - List - Filter button');
            IntercomAPI('trackEvent', 'Aqua - List - Filter button')
          }}
        >
          {I18n.t('v4/irrigation_management.filter.action')}
        </Button>
      </Paper>
    </form>
  )
}

export default withStyles(styles)(Filter)
