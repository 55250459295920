import React from 'react'
import PropTypes from 'prop-types'
import { Form as FinalForm } from 'react-final-form'

import { validate } from '@models/area'
import { RailsForm } from '@support/RailsForm'
import Form from './Form'

export const Edit = ({ model, equipments, soil_analyses, handleError, irrigation_systems, forecast_points, o }) => (
  <RailsForm
    component={FinalForm}
    action='update'
    url={`/v4/o/${o}/areas/${model.id}`}
    successUrl={`/v4/o/${o}/areas`}
    validate={validate}
    initialValues={model}
    render={(props) => (
      <Form
        {...props}
        title={I18n.t('v4/area.actions.update')}
        irrigation_systems={irrigation_systems}
        forecast_points={forecast_points}
        equipments={equipments}
        analyses={soil_analyses}
        handleError={handleError}
        o={o}
      />
    )}
  />
)

Edit.defaultProps = {
  model: {},
  equipments: []
}

Edit.propTypes = {
  model: PropTypes.object,
  equipments: PropTypes.array
}

export default Edit
