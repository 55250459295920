import React from 'react'

import Dialog from '@ui/Dialog'

import Irrigation from './FormWrapper'

const IrrigationDialog = ({ dialogOpen, handleCloseDialog, areas, initialAreas, organization_id }) => (
  <Dialog
    open={dialogOpen}
    onClose={handleCloseDialog}
    title={I18n.t('v4/irrigation.inform_title')}
    maxWidth='md'
  >
    <Irrigation
      areas={areas}
      initialAreas={initialAreas}
      organization_id={organization_id}
      onCancel={handleCloseDialog}
    />
  </Dialog>
)

IrrigationDialog.defaultProps = {
  areas: []
}

export default IrrigationDialog
