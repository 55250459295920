import React from 'react'

import { Chip, Paper, Typography, Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import styles from './styles'

import httpService from '@services/httpService'

import Dialog from '@ui/Dialog'
import TextField from '@ui/Fields/TextField'

import Select from "@ui/Fields/Select";

import SelectAreaStep from './new_alert/SelectAreaStep'
import SetValuesForVariablesStep from './new_alert/SetValuesForVariablesStep'
import ReceiveAlert from './new_alert/ReceiveAlert'

import { withRouter } from '@contexts/RouterProvider'

import {
  convertValueLocale,
  convertVariableWindSpeed,
  getLabel,
  getVariableLabel,
  getLanguages,
  AlertCategories
} from '@models/alerts'

const actionCancel = I18n.t('actions.cancel')
const actionEdit = I18n.t('actions.edit')

export class Edit extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      isDialogOpen: false,
      alerts: {},
      areas: [],
      selected: null,
      actions: {
        isDialogOpen: false,
        isDeleteDialogOpen: false,
        selected: {},
        actionKind: '',
        whatsapp: [],
        email: [],
        medias: {
          whatsapp: true,
          email: true
        },
        enabled: false
      },
      alertName: '',
      alertCategory: '',
      alertParams: {},
    }
  }

  componentDidMount() {
    const { alert_params, alerts, all_variables, name } = this.props
    const areas = alerts.areas.map(area => ({ ...area, selected: true }))
    const rules = alerts.rules.map(rule => {
      const { unity, min_value, max_value } =
        all_variables.find(value => value.variable === rule.variable) || { unity: null, min_value: null, max_value: null }

      return {
        ...rule, unity, min_value, max_value
      }
    })

    let actions = {
      whatsapp: [],
      email: []
    }

    alerts.actions.forEach(action => {
      actions[action.kind] = [...action.contacts].map(contact => ({ ...contact, language: getLanguages()[contact.locale.replace(/[-]+/g, '')] }))
    })

    const deserialize = 'deserialize'

    const newAlerts = {
      ...alerts,
      areas,
      rules: convertValueLocale(convertVariableWindSpeed(rules, deserialize), deserialize)
    }

    this.defineAlertCategory(alert_params);

    this.setState({
      alerts: newAlerts,
      alertParams: alert_params,
      actions: {
        ...this.state.actions,
        medias: {
          whatsapp: !_.isEmpty(actions.whatsapp),
          email: !_.isEmpty(actions.email)
        },
        ...actions
      },
      alertName: name
    })
  }

  defineAlertCategory = (alertParams) => {
    if(alertParams) {
      this.setState({
        alertCategory: alertParams.category
      })
    }
  }

  getMediasValues = (actions = []) => {
    const whatsapp = {}
    const email = {}

    actions.forEach((action, index) => {
      if (action.kind === 'whatsapp') action.contacts.forEach((field, index) => whatsapp["number" + (index + 1)] = field)
      if (action.kind === 'email') action.contacts.forEach((field, index) => email["email" + (index + 1)] = field)
    })

    return { whatsapp, email }
  }

  handleDialog = (selected) => {
    const { alerts } = this.state

    this.setState({ isDialogOpen: true, areas: alerts.areas, selected })
  }

  handleCloseDialog = () => {
    this.setState({ isDialogOpen: false })
  }

  handleAreaSelect = (selectedArea) => {
    const { areas } = this.state

    const newAreas = areas.map(area => ({ ...area, selected: area.id === selectedArea.id ? !selectedArea.selected : area.selected }))
    this.setState({ areas: newAreas })
  }

  handleArea = () => {
    const { areas } = this.state

    this.setState({
      alerts: {
        ...this.state.alerts, areas
      },
      isDialogOpen: false
    })
  }

  handleSetVariableValues = (rules) => {
    this.setState({
      alerts: {
        ...this.state.alerts, rules
      },
      isDialogOpen: false
    })
  }

  toggleMedia = (media) =>
    this.setState({
      actions: { ...this.state.actions, [media]: [], medias: { ...this.state.actions.medias, [media]: !this.state.actions.medias[media] } }
    })

  handleSubmit = async () => {
    const { handleError, alert_id, router } = this.props
    const { alerts, alertName, alertCategory } = this.state
    const { whatsapp, email } = this.state.actions

    let actions = []
    if (!_.isEmpty(whatsapp)) { actions.push({ kind: 'whatsapp', contacts: whatsapp }) }
    if (!_.isEmpty(email)) { actions.push({ kind: 'email', contacts: email }) }

    const serialize = 'serialize'

    try {
      await httpService.put(`${router.baseURL}alerts/${alert_id}`,
        {
          alert: {
            ...alerts,
            actions,
            name: alertName,
            alert_params: {
              category: alertCategory,
            },
            areas: alerts.areas.filter(area => area.selected),
            rules: convertVariableWindSpeed(convertValueLocale(alerts.rules, serialize), serialize),
          }
        })

      router.visit('alerts')
    }
    catch (error) {
      handleError(error)
    }
  }

  handleContact = (actionKind, dialog, selected = {}) => {
    this.setState({ actions: { ...this.state.actions, actionKind, [dialog]: true, selected, enabled: !!selected['enabled'] } })
  }

  handleDeleteDialogToggle = () => {
    const { actions } = this.state
    const { selected, actionKind } = actions

    const currentActions = [...actions[actionKind]]
    const newActions = currentActions.filter((action, index) => index !== selected.index)

    this.setState({
      actions: {
        ...actions,
        [actionKind]: newActions,
        isDeleteDialogOpen: false
      }
    })
  }

  closeDialogReceiveAlert = (dialog) => this.setState({ actions: { ...this.state.actions, [dialog]: false } })

  handleSubmitReceiveAlert = (values) => {
    const { actions } = this.state
    const { selected, actionKind } = actions
    const newActions = [...actions[actionKind]]

    if (actionKind === 'whatsapp') {
      values['enabled'] = actions.enabled
    }

    if (_.isEmpty(selected)) {
      newActions.push({ ...values, language: getLanguages()[values.locale.replace(/[-]+/g, '')] })
    } else {
      newActions[selected.index] = { ...values, language: getLanguages()[values.locale.replace(/[-]+/g, '')] }
    }

    this.setState({
      actions: {
        ...actions,
        [actionKind]: newActions,
        isDialogOpen: false
      }
    })
  }

  handleChangeEnabled = () => this.setState({ actions: { ...this.state.actions, enabled: !this.state.actions.enabled } })

  handleChangeAlertName = (alertName) => this.setState({ alertName })

  handleChangeAlertCategory = (alertCategory) => this.setState({ alertCategory})
  
  render() {
    const { classes, name, available_rules, router } = this.props

    const {
      areas,
      alerts,
      selected,
      isDialogOpen,
      actions,
      alertName,
      alertCategory,
      alertParams
    } = this.state

    return (
      <div className={classes.AlertIndex}>
        <main>
          <div className={classes.AlertWrapper}>
            <Typography variant='h6'>
              {`${I18n.t('actions.update')} ${I18n.t('v4/alerts.alerts.title')}`}
            </Typography>

            <Typography className={classes.AlertDescription} variant='body1' >
              {I18n.t('v4/alerts.new.steps.preview.description')}
            </Typography>

            <div className={classes.Alert_Boxes}>
              <TextField
                className={classes.Alert_error}
                onChange={event => this.handleChangeAlertName(event.target.value)}
                value={alertName}
                InputLabelProps={{ shrink: true }}
                variant='outlined'
                label={I18n.t('v4/alerts.new.steps.alert_name.default_alert_name')}
                error={!alertName}
                helperText={!alertName && I18n.t('validations.required')}
                required
              />

              <Select
                className={classes.Alert_Category}
                onChange={event => this.handleChangeAlertCategory(event.target.value)}
                value={alertCategory}
                InputLabelProps={{ shrink: true }}
                fields={{ id: "value", name: "name" }}
                variant='outlined'
                label={I18n.t('v4/alerts.new.steps.category.category_name')}
                error={!alertCategory}
                helperText={!alertCategory && I18n.t('validations.required')}
                data={AlertCategories}
                required
              />
              
            </div>

            <div className={classes.AlertContent}>
              {
                Object.keys(alerts).map(alert =>
                  <Section
                    classes={classes}
                    alert={alert}
                    key={alert}
                    alerts={alerts}
                    handleDialog={this.handleDialog}
                    available_rules={available_rules}
                    toggleMedia={this.toggleMedia}
                    actions={actions}
                    handleContact={this.handleContact}
                    handleSubmit={this.handleSubmitReceiveAlert}
                    closeDialog={this.closeDialogReceiveAlert}
                    handleDeleteDialogToggle={this.handleDeleteDialogToggle}
                    handleChangeEnabled={this.handleChangeEnabled}
                  />
                )
              }
            </div>

            <footer className={classes.Alert_actions}>
              <Button
                className={classes.Alert_action}
                color='primary'
                variant='contained'
                onClick={() => router.visit('alerts')}
              >
                {I18n.t('actions.cancel')}
              </Button>

              <Button
                className={classes.Alert_action}
                color='primary'
                variant='contained'
                onClick={this.handleSubmit}
                disabled={!alertName}
              >
                {`${I18n.t('actions.update')} ${I18n.t('v4/alerts.alerts.title')}`}
              </Button>
            </footer>
          </div>

          <Dialog
            onClose={this.handleCloseDialog}
            open={isDialogOpen}
            maxWidth='xs'
            fullScreen={false}
          >
            {
              selected === 'areas' &&
              <SelectAreaStep
                areas={areas}
                variables={[]}
                handleAreaSelect={this.handleAreaSelect}
                advanceStep={this.handleArea}
                retreatStep={this.handleCloseDialog}
                actionCancel={actionCancel}
                actionEdit={actionEdit}
              />
            }

            {
              selected === 'rules' &&
              <SetValuesForVariablesStep
                advanceStep={this.handleSetVariableValues}
                selectedVariables={alerts.rules}
                availableRules={available_rules}
                retreatStep={this.handleCloseDialog}
                actionCancel={actionCancel}
                actionEdit={actionEdit}
              />
            }

          </Dialog>
        </main>
      </div>
    )
  }
}

const Section = ({
  classes,
  alert,
  alerts,
  handleDialog,
  available_rules,
  toggleMedia,
  actions,
  handleContact,
  handleSubmit,
  closeDialog,
  handleDeleteDialogToggle,
  handleChangeEnabled
}) =>
  <Paper elevation={3} className={classes.AlertPaper}>
    <Typography variant="subtitle2">
      {I18n.t(`v4/alerts.alerts.variables.${alert}`)}
    </Typography>

    <section className={classes.AlertVariables}>
      {
        alert !== 'actions'
          ? alerts[alert].map((alertValue, index) =>
            <Variable
              classes={classes}
              value={alertValue}
              key={`alert-value-${index}`}
              selected={alert}
              handleDialog={handleDialog}
              available_rules={available_rules}
            />
          )
          : <ReceiveAlert
            toggleMedia={toggleMedia}
            data={actions}
            handleContact={handleContact}
            handleSubmit={handleSubmit}
            closeDialog={closeDialog}
            handleDeleteDialogToggle={handleDeleteDialogToggle}
            handleChangeEnabled={handleChangeEnabled}
          />
      }
    </section>
  </Paper>

const Variable = ({ classes, value, selected, handleDialog, available_rules }) => {
  const labelValue = getLabel(value)
  const label = typeof (labelValue) === 'string' ? labelValue : getVariableLabel(value, available_rules)

  return (
    <Chip
      label={label}
      className={classes.AlertChip}
      classes={{ label: classes.AlertChip_label }}
      component="a"
      clickable
      onClick={() => handleDialog(selected)}
      color={selected === 'areas' ? (!!value.selected ? 'primary' : 'default') : 'primary'}
    />
  )
}

Edit.defaultProps = {
  available_rules: [],
  alerts: {},
  all_variables: [],
  name: '',
  alert_id: 0,
}

export default withRouter(withStyles(styles)(Edit))
