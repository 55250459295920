import React from 'react';

import { Button } from '@material-ui/core';

import Graphics from '@images/equipment/graphics';
import {IntercomAPI} from '@utils/IntercomAPI'
import {sendAmplitudeEvent} from '@utils/amplitudeEvent'

const Action = ({ classes, router, selectedEquipment }) => (
  <div className={classes.Equipment_Actions}>
    <Button
      color="primary"
      className={classes.Equipment_action}
      onClick={() => {
        router.visit(`equipments/${selectedEquipment.id}/dashboard`);
        IntercomAPI('trackEvent', 'Area Card - Clicked Telemetry Button')
        sendAmplitudeEvent('Area Card - Clicked Telemetry Button')
      }}
      variant="contained"
      data-intercom-target="Area Card - Clicked Telemetry Button"
    >
      <img src={Graphics} alt="graphics" className={classes.Equipment_icons} />
      {I18n.t("v4/equipment.dashboard.actions.graphics")}
    </Button>
  </div>
);

export default Action;
