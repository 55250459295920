import httpService from '@services/httpService'

export const deserializeData = (forecastList) =>
  forecastList.map(forecast => ({
    ...forecast,
    name: forecast.name || I18n.t('v4/forecast.configuration.configure')
  }))

const firstDay = 0
const secondDay = 1

export const serializeData = (forecastPoints, selectedForecast) => {
  if (_.isEmpty(forecastPoints)) {
    return { selectedForecast: {}, cards: [], resume: {} }
  }

  let pcpMaxTotal = 0
  let pcpMinTotal = 0
  let daysWithRain = 0
  let greaterVol = 0

  const currentForecast = findForecast(forecastPoints, selectedForecast)

  const forecastPoint = currentForecast ? currentForecast : forecastPoints[0]

  const currentData = forecastPoint.data || []

  const cards = currentData.map((data, index) => {
    const header = {
      date: formatDate(data.forecast_date, { timeZone: 'UTC', day: 'numeric', month: '2-digit' }),
      day: formatDate(data.forecast_date, { timeZone: 'UTC', weekday: 'long' }),
      icon: data.icon,
      shortDay: formatDate(data.forecast_date, { timeZone: 'UTC', weekday: 'short' })
    }

    const pcp_tot = parseInt(data.pcp_tot)
    const pcp_tot_std = parseInt(data.pcp_tot_std)
    const pop = parseInt(data.pop)
    const rain = getRainInfo(pop)

    const max = pcp_tot + pcp_tot_std
    let min = pcp_tot - pcp_tot_std
    min <= 0 ? min = 0 : min = min

    if (max > greaterVol) { greaterVol = max }

    const body = [
      { value: `${!isNaN(data.t2_max) ? (data.t2_max).toFixed(1) : ' '}ºC`, icon: 'arrowUp', label: `${I18n.t('v4/forecast.max_temp')}:` },
      { value: `${!isNaN(data.t2_min) ? (data.t2_min).toFixed(1) : ' '}ºC`, icon: 'arrowDown', label: `${I18n.t('v4/forecast.min_temp')}:` },
      { value: `${data.ws10_avg !== undefined ? (data.ws10_avg).toFixed(1) : ' '} km/h`, icon: 'wind', label: `${I18n.t('v4/forecast.wind_speed')}:` },
      { value: `${!isNaN(min) ? min : ' '} - ${!isNaN(max) ? max : ' '} mm`, icon: rain.chance, label: `${I18n.t('v4/forecast.rain_quantity')}:` }
    ]

    const values = {
      maxTemp: {
        value: `${!isNaN(data.t2_max) ? (data.t2_max).toFixed(1) : ' '}º`,
        icon: 'arrowUp',
        label: `${I18n.t('v4/forecast.max_temp')}:`
      },
      minTemp: {
        value: `${!isNaN(data.t2_min) ? (data.t2_min).toFixed(1) : ' '}º`,
        icon: 'arrowDown',
        label: `${I18n.t('v4/forecast.min_temp')}:`
      },
      wind: {
        value: `${data.ws10_avg !== undefined ? (data.ws10_avg).toFixed(1) : ' '}`,
        icon: 'wind',
        label: `${I18n.t('v4/forecast.wind_speed')}:`
      },
      rain: {
        value: `${!isNaN(min) ? min : ' '}-${!isNaN(max) ? max : ' '}`,
        icon: 'raindrop',
        label: `${I18n.t('v4/forecast.rain_quantity')}:`
      },
      rainChance: getRainInfo(pop)
    }

    const rainChance = getRainInfo(pop)

    if (pop > 5) { daysWithRain += 1 }
    pcpMaxTotal = pcpMaxTotal + pcp_tot + pcp_tot_std
    pcpMinTotal = pcpMinTotal + pcp_tot - pcp_tot_std

    let sprayingCondition = {}

    if ((index === firstDay || index === secondDay) && !!data['spraying_morning']) {
      sprayingCondition['sprayingMorning'] = data['spraying_morning']
      sprayingCondition['sprayingAfternoon'] = data['spraying_afternoon']
      sprayingCondition['sprayingNight'] = data['spraying_night']
    }

    return {
      header,
      body,
      rainChance,
      values,
      sprayingCondition
    }
  })

  return {
    cards,
    resume: {
      pcpMaxTotal,
      pcpMinTotal,
      daysWithRain,
      greaterVol
    },
    selectedForecast: forecastPoint
  }
}

const getRainInfo = (pop) => {
  if (pop > 90) {
    return {
      chance: 'very_high',
      pop
    }
  }
  if (pop > 70 && pop <= 90) {
    return {
      chance: 'high',
      pop
    }
  }
  if (pop > 50 && pop <= 70) {
    return {
      chance: 'moderate',
      pop
    }
  }
  if (pop > 5 && pop <= 50) {
    return {
      chance: 'low',
      pop
    }
  }
  return {
    chance: 'very_low',
    pop
  }
}

const formatDate = (date, options) =>
  new Date(date).toLocaleDateString(
    I18n.locale,
    options
  )

const findForecast = (forecastList, selectedForecast) =>
  forecastList.find(
    forecast =>
      forecast.id === (selectedForecast
        ? selectedForecast.id
        : selectedForecast)
  )

export const isSetup = (forecast) => forecast.set_up === true

export const handleForecastSetup = async ({ values, forecastPoint, organizationId }) => {
  const { data } = await httpService.put(`/api/v4/forecasts/points/${forecastPoint.id}?organization_id=${organizationId}`, values)
  return data
}
