import React, { useState } from 'react'

import PropTypes from 'prop-types'

import withStyles from '@material-ui/core/styles/withStyles'
import TextField from '@ui/Fields/TextField'

import styles from './styles'

const PillTextInput = ({ 
  classes, 
  input: { value, onChange }, 
  meta: { touched, error },
  initialValue,
  limit,
  ...props
}) => {
  const [items, setItems] = useState(initialValue || value || [])
  const [textValue, setTextValue] = useState('')

  const setItemsOnChange = items => {
    setItems(items)
    onChange && onChange(items)
  }

  const changeState = event => {
    if (event.key === ' ' && textValue !== '') {
      setItemsOnChange([...items, textValue])
      setTextValue('')
    } else if (event.key === 'Backspace' && textValue === '') {
      items.pop()
      setItemsOnChange([...items])
    }
  }

  return (
    <TextField 
      {...props}
      error={!!(touched && error)}
      helperText={touched && error}
      value={textValue}
      onChange={e => {
        if (items.length < limit) {
          setTextValue(e.target.value.trim())
        } else {
          setTextValue('')
        }
      }}
      onKeyDown={changeState}
      InputProps={{
        startAdornment: (
          <ul className={classes.PillTextInput__pill__container}>
            {
              items.map((item, index) => 
                <li 
                  className={classes.PillTextInput__pills} 
                  key={`${item}-${index}`}
                >
                  {item}
                </li>
              )
            }
          </ul>
        )}}
      />
  )
}

PillTextInput.defaultProps = {
  limit: 5
}

export default withStyles(styles)(PillTextInput)
