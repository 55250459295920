import React from "react";

import {
  Typography,
  IconButton,
  Popover,
  Button,
  Tab,
  Tabs,
} from "@material-ui/core";
import { CheckCircleRounded as Check, Notifications } from "@material-ui/icons";
import { Close } from "@material-ui/icons";
import withStyles from "@material-ui/core/styles/withStyles";
import NotificationsCards from "../Notifications/";

import styles from "./styles";

import httpService from "@services/httpService";
import { getDateWithHours } from "@services/locale";
import { MENUS, withMenusStatus } from "@contexts/MenusStatusProvider";
import { sendAmplitudeEvent } from "@utils/amplitudeEvent";
import { IntercomAPI } from "@utils/IntercomAPI";
import { withSnackbar } from "notistack";
import NotificationsMock from "./notificationsMock.json";

class AlertsMenu extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      anchorEl: null,
      value: 0,
      notificationsData: [],
    };
  }
  componentDidMount() {
    const {
      notifications,
      lastTimeTriggered,
      noAlert,
      active,
      noAlertsTriggered,
      menusStatus: { setMenuData },
    } = this.props;

    const alerts = notifications.map((notification) => ({
      ...notification,
      dateAndTime: getDateWithHours(notification.date),
    }));

    const CurrentLastTimeTriggered = !!lastTimeTriggered
      ? getDateWithHours(lastTimeTriggered)
      : "";

    const data = {
      alerts,
      noAlertsTriggered,
      lastTimeTriggered: CurrentLastTimeTriggered,
      active,
    };

    setMenuData(MENUS.alertsMenu, "data", data);

    if (!noAlert) this.fetchNotifications();

    this.setState({
      noAlert,
    });
  }

  fetchNotifications = async () => {
    const { currentOrganization } = this.props;

    try {
      const { data } = await httpService.get(
        `${process.env.NOTIFICATION_SERVICE_URL}/api/v1/notifications?organization_id=${currentOrganization.id}`
      );

      this.setState({
        notificationsData: data,
      });
    } catch (error) {
      handleError(error);
    }
  };

  sentDataEventToAmplitude = (eventName, eventType) => {
    const AmplitudeAPI = window.amplitude;
    const { notificationsData } = this.state;
    const { notifications } = this.props;
    const hasNotification = !_.isEmpty(notificationsData);
    const hasAlerts = !_.isEmpty(notifications);
    const hasAlertIcon =
      !_.isEmpty(notificationsData) || !_.isEmpty(notifications);

    switch (eventType) {
      case "notification_tab":
        return AmplitudeAPI.getInstance().logEvent(eventName, {
          has_notification_badge: hasNotification,
        });
      case "alerts_tab":
        return AmplitudeAPI.getInstance().logEvent(eventName, {
          has_alert_badge: hasAlerts,
        });
      case "alert_icon":
        return AmplitudeAPI.getInstance().logEvent(eventName, {
          has_notification_badge: hasAlertIcon,
        });
    }
  };

  handleClick = (event) => {
    const {
      menusStatus: { toggleMenu },
    } = this.props;

    toggleMenu(MENUS.alertsMenu, true);

    this.fetchNotifications();
    this.setState({
      anchorEl: event.currentTarget,
    });

    this.sentDataEventToAmplitude("Used Alerts", "alert_icon");
  };

  handleClose = () => {
    const {
      menusStatus: { toggleMenu },
    } = this.props;

    toggleMenu(MENUS.alertsMenu, false);

    this.setState({
      anchorEl: null,
    });
    sendAmplitudeEvent("Notification Center - Closed card");
    IntercomAPI("trackEvent", "Notification Center - Closed card");
  };

  handleRemoveAlert = async (alertId) => {
    const {
      menusStatus: { menus, setMenuData },
      handleError,
      router,
      enqueueSnackbar,
    } = this.props;

    const { data } = menus[MENUS.alertsMenu];

    const alerts = data.alerts.filter((alert) => alert.alert.id !== alertId);

    const deletedAlert = data.alerts.find(
      (alert) => alert.alert.id === alertId
    );

    try {
      await httpService.post(
        `${router.baseURL}alerts/notifications/mark_as_read`,
        { _mark_as_read: deletedAlert.ids }
      );

      let noAlertsTriggered = false;
      let active = true;
      let lastTimeTriggered = {};

      if (_.isEmpty(alerts)) {
        noAlertsTriggered = true;
        active = false;
        lastTimeTriggered = deletedAlert.dateAndTime;
      }

      setMenuData(MENUS.alertsMenu, "data", {
        ...data,
        alerts,
        noAlertsTriggered,
        active,
        lastTimeTriggered,
      });

      enqueueSnackbar(I18n.t("v4/alerts.alerts.alert_message"), {
        variant: "default",
      });
    } catch (error) {
      handleError(error);
    }
  };

  handleRemoveAllAlerts = async () => {
    const {
      menusStatus: { menus, setMenuData },
      handleError,
      router,
      enqueueSnackbar,
    } = this.props;

    const { data } = menus[MENUS.alertsMenu];

    const allAlertsId = data.alerts.map((alert) => alert.ids).flat();

    try {
      await httpService.post(
        `${router.baseURL}alerts/notifications/mark_as_read`,
        { _mark_as_read: allAlertsId }
      );

      const noAlertsTriggered = true;
      const active = false;
      const lastTimeTriggered = data.alerts[data.alerts.length - 1].dateAndTime;

      setMenuData(MENUS.alertsMenu, "data", {
        ...data,
        alerts: [],
        noAlertsTriggered,
        active,
        lastTimeTriggered,
      });

      enqueueSnackbar(I18n.t("v4/alerts.alerts.alert_message"), {
        variant: "default",
      });
    } catch (error) {
      handleError(error);
    }
  };

  handleChangeTab = (event, value) => {
    this.setState({ value });
  };

  render() {
    const {
      classes,
      router,
      menusStatus: { menus },
      noAlert,
    } = this.props;

    const { anchorEl, value, notificationsData } = this.state;

    const {
      open,
      data: { noAlertsTriggered, lastTimeTriggered, active, alerts },
    } = menus[MENUS.alertsMenu];

    const { products } = this.props;

    const checkNotificationStatus = notificationsData.map((notification) =>
      notification.status === "newly" ? true : false
    );

    const hasNotification = checkNotificationStatus.includes(true, 0);

    return (
      <div data-intercom-target="alerts">
        <IconButton
          className={classes.Navbar__Icon}
          aria-owns={open ? "render-props-popover" : null}
          aria-haspopup="true"
          variant="contained"
          name="alert-menu"
          onClick={this.handleClick}
        >
          {(active || hasNotification) && (
            <div className={classes.Alert_Received}></div>
          )}
          <Notifications className={classes.Alert_Icon} />
        </IconButton>

        <Popover
          id="simple-popper"
          open={open}
          anchorEl={anchorEl}
          onClose={this.handleClose}
          classes={{ paper: classes.Alert_Popover }}
          anchorOrigin={{
            vertical: "bottom",
            horizontal: "center",
          }}
          transformOrigin={{
            vertical: "top",
            horizontal: "center",
          }}
        >
          <div className={classes.Notification_Central_Header}>
            <Typography variant="body2">
              {I18n.t("notifications_central.notifications_central_title")}
            </Typography>

            <Close onClick={this.handleClose} cursor="pointer" />
          </div>

          <Tabs value={value} onChange={this.handleChangeTab}>
            <Tab
              label={I18n.t("notifications_central.notifications_tab")}
              onClick={() =>
                this.sentDataEventToAmplitude(
                  "Notification - Used Notification tab",
                  "notification_tab"
                )
              }
            />
            <Tab
              label={I18n.t("notifications_central.alerts_tab")}
              onClick={() =>
                this.sentDataEventToAmplitude(
                  "Alerts - Used Alerts tab",
                  "alerts_tab"
                )
              }
            />
          </Tabs>
          {value === 0 && (
            <div className={classes.Notification_Wrapper}>
              <div className={classes.Notification_List}>
                {notificationsData.map((notification) => (
                  <NotificationsCards
                    id={notification.id}
                    notificationType={notification.kind}
                    priority={notification.priority}
                    status={notification.status}
                    created_at={notification.created_at}
                    updated_at={notification.updated_at}
                    key={notification.id}
                    notification={notification}
                  />
                ))}

                {_.isEmpty(notificationsData) && (
                  <div className={classes.Empty_Notification}>
                    <Typography variant="caption" align="center">
                      {I18n.t("notifications_central.no_notifications")}
                    </Typography>
                  </div>
                )}
              </div>

              <div className={classes.Notification_Footer}>
                <Button
                  color="primary"
                  onClick={() => {
                    router.visit(`/notification_center`);
                    sendAmplitudeEvent(
                      "Notification - Used Notification manage"
                    );
                  }}
                >
                  {I18n.t("notifications_central.manage_notifications")}
                </Button>
              </div>
            </div>
          )}

          {value === 1 &&
            products.find((product) => product.kind === "alert") !==
              undefined && (
              <div className={classes.Alert_Wrapper}>
                <div className={classes.Alert_Header}>
                  {!_.isEmpty(alerts) && (
                    <div className={classes.Alert_Button}>
                      <Button
                        color="primary"
                        onClick={() => {
                          this.handleRemoveAllAlerts();
                          sendAmplitudeEvent(
                            "Alerts - Used All Alerts completed"
                          );
                        }}
                        variant="outlined"
                      >
                        {I18n.t("v4/alerts.alerts.completed_alerts")}
                      </Button>
                    </div>
                  )}
                </div>

                {active && (
                  <>
                    {!_.isEmpty(alerts) && (
                      <div className={classes.Alert_Menu}>
                        {alerts.map((alert, index) => (
                          <div className={classes.Alert_Container} key={index}>
                            {alert.count > 1 && (
                              <Typography
                                variant="body1"
                                className={classes.Alert_count}
                              >
                                {`${I18n.t("v4/alerts.alerts.count_area", {
                                  count: alert.count,
                                })}`}
                              </Typography>
                            )}
                            <div className={classes.Alerts}>
                              <Typography variant="body2">
                                {alert.alert.name}
                              </Typography>

                              <Check
                                className={classes.Alert_check}
                                onClick={() => {
                                  sendAmplitudeEvent("Used Alert Check");
                                  this.handleRemoveAlert(alert.alert.id);
                                }}
                              />
                            </div>

                            <Typography
                              variant="body1"
                              className={classes.Alert_areas}
                            >
                              {`${I18n.t("v4/alerts.alerts.last_time")}: ${
                                alert.dateAndTime.infoDate
                              }, ${alert.dateAndTime.infoHour}`}
                            </Typography>

                            <Typography
                              variant="body1"
                              className={classes.Alert_areas}
                            >
                              {alert.areas
                                .map((area) => area.name)
                                .toString()
                                .replace(/,/g, ", ")}
                            </Typography>
                          </div>
                        ))}
                      </div>
                    )}
                  </>
                )}

                {noAlertsTriggered && (
                  <>
                    <Typography variant="caption" align="center">
                      {I18n.t("v4/alerts.alerts.no_alerts_triggered")}
                    </Typography>

                    {!_.isEmpty(lastTimeTriggered) && (
                      <Typography
                        variant="caption"
                        align="center"
                        className={classes.Alert_empty}
                      >
                        {`${I18n.t("v4/alerts.alerts.last_time")}: ${
                          lastTimeTriggered.infoDate
                        }, ${lastTimeTriggered.infoHour}`}
                      </Typography>
                    )}
                  </>
                )}

                {noAlert && (
                  <>
                    <Typography
                      variant="caption"
                      align="center"
                      className={classes.Alert_empty}
                    >
                      {I18n.t("v4/alerts.alerts.no_alerts")}
                    </Typography>
                    <div className={classes.Alert_Button}>
                      <Button
                        color="primary"
                        onClick={() => router.visit("alerts/new")}
                      >
                        {I18n.t("v4/alerts.alerts.create")}
                      </Button>
                    </div>
                  </>
                )}
                <div className={classes.Alert_Footer}>
                  <Button
                    color="primary"
                    onClick={() => router.visit("alerts")}
                    data-intercom-target="product-alert-manage"
                  >
                    {I18n.t("v4/alerts.alerts.manage_alerts")}
                  </Button>
                </div>
              </div>
            )}
        </Popover>
      </div>
    );
  }
}

export default withMenusStatus(withSnackbar(withStyles(styles)(AlertsMenu)));
