import React, { Component } from 'react'

import { Paper, withStyles } from '@material-ui/core'

import { Form as FinalForm } from 'react-final-form'

import { RailsForm } from '@support/RailsForm'

import Dialog from '@ui/Dialog'
import DeleteDialog from '@ui/DeleteDialog'

import { withHttpService } from '@contexts/HttpServiceProvider'
import { withSessionData } from '@contexts/SessionDataProvider'
import { withRouter } from '@contexts/RouterProvider'

import styles from '@styles/indexPage'

import ModalForm from './ModalForm'
import DashboardTable from './DashboardTable'
import Header from './Header'
import FilterForm from './FilterForm'

import { getInitialValues } from '@models/alerts'
import moment from 'moment'

class ManualEquipment extends Component {
  constructor(props) {
    super(props)

    this.state = {
      dialog: {
        open: false,
        title: I18n.t('v4/equipment.pluviometer.actions.register_rain'),
        action: 'unknown'
      },
      id: null,
      currentEquipment: 0,
      currentMeasure: {},
      measures: [],
      equipmentList: [],
      initialValues: {
        pl1: '',
        measured_at: new Date()
      },
      loading: false,
      filterInitialValues: {},
      filterDisabled: false
    }
  }

  componentDidMount() {
    const initialValues = getInitialValues()

    this.setState({
      filterInitialValues: {
        start: initialValues['start_date'],
        finish: initialValues['end_date']
      }
    })

    this.fetchManualEquipment()

    this.fetchMeasuresManualEquipment()
  }

  handleEquipmentChange = (selectedEquipment) => {
    const { equipmentList, currentEquipment } = this.state
    const { router } = this.props

    if (currentEquipment === selectedEquipment) {
      return
    }

    if (selectedEquipment !== null) {
      router.visit(`/equipments/manual/${equipmentList[selectedEquipment].id}/measures/?change_equipment=true`)
    }
  }

  handleCloseDialog = (action = 'unknown') =>
    this.setState({ dialog: { ... this.state.dialog, open: false, action } })

  handleOpenDialog = (action, currentMeasure = {}) => {
    this.setState({
      dialog: {
        open: true,
        title: action === 'add'
          ? I18n.t('v4/equipment.pluviometer.actions.register_rain')
          : I18n.t('v4/equipment.pluviometer.dashboard.actions.edit'),
        action
      },
      initialValues: {
        pl1: currentMeasure['pl1']
      },
      currentMeasure
    })
  }

  handleDelete = async () => {
    const { currentMeasure } = this.state
    const {
      handleError,
      httpService: { apiRequest },
      sessionData: { currentOrganization },
      model: { equipment_id }
    } = this.props

    const params = {
      organization_id: currentOrganization.id
    }

    try {
      await apiRequest.delete(`/equipments/${equipment_id}/measures/${currentMeasure.id}`, { params })

      this.fetchMeasuresManualEquipment()

      this.setState({ dialog: { ...this.state.dialog, action: 'unknown', open: false } })
    }
    catch (error) {
      handleError(error)
    }
  }

  handleSubmit = async (values, action) => {
    const { currentMeasure, filterInitialValues } = this.state
    const {
      handleError,
      httpService: { apiRequest },
      sessionData: { currentOrganization },
      model: { equipment_id }
    } = this.props

    const params = {
      organization_id: currentOrganization.id,
      measure: values
    }

    this.setState({ loading: true })

    try {
      const method = action === 'add' ? 'post' : 'put'

      const urlRequest =
        action === 'edit'
          ? `/equipments/${equipment_id}/measures/${currentMeasure.id}`
          : `/equipments/${equipment_id}/measures`

      await apiRequest[method](urlRequest, params)

      this.fetchMeasuresManualEquipment(filterInitialValues)

      this.setState({ dialog: { ...this.state.dialog, open: false, action: 'unknown' } })
    }
    catch (error) {
      handleError(error)
    }

    this.setState({ loading: false })
  }

  fetchMeasuresManualEquipment = async (values = {}, filter = false) => {
    const {
      handleError,
      httpService: { apiRequest },
      sessionData: { currentOrganization },
      model: { equipment_id }
    } = this.props

    const params = {
      organization_id: currentOrganization.id,
      group_by: 'none',
      timezone: currentOrganization.timezone,
      ...values
    }

    !_.isEmpty(values) && filter && this.setState({ filterDisabled: true })
    if (params.finish && params.start) {
      params.finish = moment(params.finish).set('hour', 23).set('minute', 59).format() 
      params.start = moment(params.start).set('hour', 0).set('minute', 0).format()
    }

    try {
      const { data } = await apiRequest.get(`/equipments/${equipment_id}/measures`, { params })

      this.setState({
        measures: data.data || []
      })
    }
    catch (error) {
      handleError(error)
    }

    !_.isEmpty(values) && this.setState({ filterDisabled: false })
  }

  fetchManualEquipment = async () => {
    const {
      handleError,
      httpService: { apiRequest },
      sessionData: { currentOrganization },
      model: { equipment_id }
    } = this.props

    const params = {
      organization_id: currentOrganization.id,
    }

    try {
      const { data } = await apiRequest.get(`/equipments/manual`, { params })

      this.setState({
        equipmentList: data,
        currentEquipment: data.findIndex(equipment => equipment.id === equipment_id)
      })
    }
    catch (error) {
      handleError(error)
    }
  }

  render() {
    const { classes } = this.props
    const {
      dialog,
      initialValues,
      filterInitialValues,
      measures,
      loading,
      equipmentList,
      currentEquipment,
      filterDisabled
    } = this.state

    return (
      <>
        <Paper className={classes.page__wrapper} elevation={0}>
          <Header
            equipmentList={equipmentList}
            handleOpenDialog={this.handleOpenDialog}
            handleEquipmentChange={this.handleEquipmentChange}
            currentEquipment={currentEquipment}
          />

          <RailsForm
            component={FinalForm}
            initialValues={filterInitialValues}
            handleSubmit={(values) => this.fetchMeasuresManualEquipment(values, true)}
            render={props => (
              <FilterForm
                disabled={filterDisabled}
                {...props}
              />
            )}
          />

          <DashboardTable
            handleOpenDialog={this.handleOpenDialog}
            measures={measures}
          />
        </Paper>

        <Dialog
          open={dialog.open && dialog.action !== 'delete'}
          onClose={this.handleCloseDialog}
          title={dialog.title}
          maxWidth='xs'
        >
          <RailsForm
            component={FinalForm}
            initialValues={initialValues}
            handleSubmit={(values) => this.handleSubmit(values, dialog.action)}
            render={
              (props) => (
                <ModalForm
                  hideEditDate={dialog.action === 'edit'}
                  loading={loading}
                  handleCloseDialog={this.handleCloseDialog}
                  {...props}
                />
              )}
          />
        </Dialog>

        <DeleteDialog
          dialogTitle={I18n.t('v4/equipment.pluviometer.dashboard.actions.delete')}
          dialogText={I18n.t('confirmation.remove')}
          handleDelete={this.handleDelete}
          onCancel={this.handleCloseDialog}
          onClose={this.handleCloseDialog}
          open={dialog.action === 'delete'}
          changeDefaultColor={true}
        />
      </>
    )
  }
}

export default
  withSessionData(
    withRouter(
      withHttpService(
        withStyles(styles)(ManualEquipment)
      )
    )
  )
