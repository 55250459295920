import React from 'react'

import {
  getHeadersEquipmentMenu,
  getGroups,
  getUseCustomPadding,
  customPadding
} from '@models/equipment/equipment'

import Table from '@ui/Table'

const DepthTable = ({ classes, equipmentMenuData, depthVariables }) =>
  <Table
    tableKey='equipments-menu'
    key='equipment-table'
    components={{
      Container: props => <div>{props.children}</div>,
    }}
    columns={getHeadersEquipmentMenu(equipmentMenuData.descriptionVariables, classes)}
    data={depthVariables}
    options={{
      showTitle: false,
      search: false,
      paging: false,
      toolbar: false,
      sorting: false,
      headerStyle: getUseCustomPadding(getGroups(equipmentMenuData.descriptionVariables)) && customPadding
    }}
  />

export default DepthTable
