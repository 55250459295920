import React from 'react'

import { DialogActions, Button } from '@material-ui/core'

import AvailableWater from './AvailableWater'
import RootDepth from './RootDepth'
import CorrectionFactor from './CorrectionFactor'

const findSensorDepthValues = sensor =>
  sensor.params
    .filter(({ param }) => param.match(/soil_depth\d+/i))
    .map(({value}) => value)

const sensorContainsDepthGap = sensor =>
  !_.isEmpty(sensor)
  && !_.isEmpty(sensor.params)
  && !!findSensorDepthValues(sensor)

const getSensorDepthValues = sensor => {
  const values = findSensorDepthValues(sensor)

  return values.map(value => ({
    depth: value,
    value: 1
  }))
}

const returnIfIsnotEmpty = (value, defaultValue) =>
  _.isEmpty(value) ? defaultValue : value

const getInitialValues = ({ available_water_client, zr_client, correction_factor }, selectedSensor) => {
  const initialValues = {
    available_water_client: returnIfIsnotEmpty(available_water_client, []),
    zr_client: returnIfIsnotEmpty(zr_client, []),
    correction_factor: []
  }

  if (sensorContainsDepthGap(selectedSensor)) {
    if (!_.isEmpty(correction_factor)) {
      initialValues.correction_factor = correction_factor
    }
    else {
      initialValues.correction_factor = getSensorDepthValues(selectedSensor)
    }
  }

  return initialValues
}

const AdvancedParams = ({ initialValues, selectedSensor, handleSubmit, handleCancel }) => {
  const [state, setState] = React.useState(getInitialValues(initialValues, selectedSensor))

  const handleChange = (param, data) => {
    setState({
      ...state,
      [param]: data
    })
  }

  const validate = ({ correction_factor }) => {
    const correctionFactorSum = correction_factor.reduce((sum, { value }) => sum += Number(value), 0)

    return correctionFactorSum === correction_factor.length
  }

  const removeTableDataFromValues = values =>
    Object.keys(values).reduce(
      (valuesObject, key) => {
        valuesObject[key] = values[key].map(({ tableData, ...value }) => value)
        return valuesObject
      }
      , {})

  const onSubmit = (values) => {
    if (validate(values)) {
      return handleSubmit(removeTableDataFromValues(values))
    }

    window.confirmDialog(() => { }, {
      title: I18n.t('errors.save'),
      content: `${I18n.t('v4/irrigation_management.advanced_params.correction_factor.info')} (${values.correction_factor.length})`,
      showCancelButton: false
    })
  }

  return <>
    <AvailableWater
      update={data => handleChange('available_water_client', data)}
      data={state.available_water_client}
    />

    <RootDepth
      update={data => handleChange('zr_client', data)}
      data={state.zr_client}
    />

    <CorrectionFactor
      update={data => handleChange('correction_factor', data)}
      data={state.correction_factor}
    />

    <DialogActions>
      <Button
        color='secondary'
        onClick={handleCancel}
      >
        {I18n.t('actions.cancel')}
      </Button>

      <Button
        color='primary'
        type='submit'
        variant='contained'
        onClick={() => onSubmit(state)}
      >
        {I18n.t('actions.save')}
      </Button>
    </DialogActions>

  </>
}

AdvancedParams.defaultProps = {
  initialValues: {}
}

export default AdvancedParams
