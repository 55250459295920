import validateFormFields from '@services/validateFormFields'

const headers = ['order', 'name']

export const getPhenologicalStagesHeaders = () =>
  [
    ...headers.map(header => ({
      title: I18n.t(`activerecord.attributes.v4/culture.phenological_stages.${header}`),
      field: header
    })),
    {
      title: I18n.t('activerecord.attributes.v4/culture.phenological_stages.status'),
      field: 'status',
      render: rowData => I18n.t(`enumerize.v4/culture/phenological_stage.status.${rowData.status}`)
    }
  ]

export const validate = values => validateFormFields(values, fields())

export const fields = () => [
  { name: 'name', validations: { required: true } },
  { name: 'kc', validations: { required: true, bounds: { min: 0.2, max: 1.9 } } },
  { name: 'duration', measure: I18n.t('days'), validations: { required: true, bounds: { min: 1, max: 730 } } },
  { name: 'kl', validations: { required: true, bounds: { min: 0.5, max: 1 } } },
  { name: 'status', validations: { required: true }, translation: (value) => I18n.t(`enumerize.v4/culture/phenological_stage.status.${value}`) },
]

export const getStageStatuses = () => [
  { id: 'restrict', name: I18n.t('enumerize.v4/culture/phenological_stage.status.restrict') },
  { id: 'normal', name: I18n.t('enumerize.v4/culture/phenological_stage.status.normal') },
  { id: 'critical', name: I18n.t('enumerize.v4/culture/phenological_stage.status.critical') }
]

export const updateStagesOrder = (stage, index) => ({
  ...stage,
  order: index + 1
})
