export default theme => ({
  Filter_FieldsWrapper: {
    display: 'flex',
    margin: `${theme.spacing.unit * 5}px 0`,
    justifyContent: 'space-between',
    alignItems: 'center',
    minHeight: theme.spacing.unit * 12.5,
    borderRadius: 10,
    flexWrap: 'wrap'
  },
  Filter_Field: {
    flex: 1,
    minHeight: theme.spacing.unit * 6,
    minWidth: theme.spacing.unit * 21.9,
    margin: theme.spacing.unit * 2,
  },
  Finished_Managements_Filter: {
    flex: 1,
    minHeight: theme.spacing.unit * 6,
    minWidth: theme.spacing.unit * 21.9,
    margin: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit * 2,
  },
  Filter_Action: {
    margin: theme.spacing.unit * 2,
    minWidth: theme.spacing.unit * 10,
    padding: theme.spacing.unit
  }
})
