import React from 'react'
import Table from '@material-ui/core/Table'
import TableBody from '@material-ui/core/TableBody'
import TableCell from '@material-ui/core/TableCell'
import TableHead from '@material-ui/core/TableHead'
import TableRow from '@material-ui/core/TableRow'
import { Paper, Button } from '@material-ui/core'
import ExportTableIcon from '@material-ui/icons/SaveAlt'
import { exportTable } from '@models/equipment/telemetry/table'

import { sendAmplitudeEvent } from '@utils/amplitudeEvent'
import { IntercomAPI } from '@utils/IntercomAPI';

import WindDirection from "@ui/WindDirection"

const Header = ({ header, classes }) =>
  <TableCell
    align='center'
    className={`${classes.Dashboard__table__cell_header} ${classes.Dashboard__table__cell}`}
    colSpan={header.data ? header.data.length : 1}
    rowSpan={header.field ? 2 : 1}
  >
    <span className={`${classes.Dashboard__table__cell_header_title}`}>{header.title}</span>
    {header.legend || ''}
  </TableCell>

const SecondaryHeaders = ({ header, classes }) =>
  !header.field &&
  header.data.map((data, index) =>
    <TableCell
      key={data.title}
      className={`${classes.Dashboard__table__cell_header} ${classes.Dashboard__table__cell} ${index === 1 ? classes.Dashboard__table__avg : ''}`}
    >
      {data.title}
    </TableCell>
  )

const DashboardTable = ({ data, classes, tableHeaders, equipment }) =>
  <Paper
    className={classes.Dashboard__table_wrapper}
  >
    <div className={classes.Dashboard__table_head}>
      <Button
        className={classes.Dashboard__table_export_button}
        title={I18n.t('v4/equipment.dashboard.table.export_table')}
        onClick={() => {
          exportTable('equipment-dashboard-table-export', equipment);
          sendAmplitudeEvent('Telemetry - Exported table')
          IntercomAPI('trackEvent', 'Telemetry - Exported table')
        }}
      >
        <ExportTableIcon />
      </Button>
    </div>
    <div className={classes.Dashboard__table}>
      <Table id='equipment-dashboard-table'>
        <TableHead>
          <TableRow>
            {
              tableHeaders.map(header =>
                <Header key={header.title} header={header} classes={classes} />
              )
            }
          </TableRow>
          <TableRow>
            {
              tableHeaders.map(
                header =>
                  <SecondaryHeaders key={header.title} header={header} classes={classes} />
              )
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {
            data.map((dataRow, index) =>
              <TableRow key={index}>
                {
                  tableHeaders.map((row, index) =>
                    row.field ?
                      <TableCell
                        className={`${classes.Dashboard__table__cell} tableexport-string`}
                        align='center'
                        key={`${row.title}-${index}`}
                      >
                        {
                          row.field === 'wd1'
                            ? (
                              !!dataRow[row.field]
                                ? <WindDirection
                                  classes={classes}
                                  angle={dataRow[row.field]}
                                  align={'right'}
                                />
                                : ''
                            )
                            : dataRow[row.field] !== null ? dataRow[row.field] : ''
                        }
                      </TableCell>
                      :
                      row.data.map((value, index) =>
                        <TableCell
                          key={`${value.title}-${index}`}
                          className={`${classes.Dashboard__table__cell} ${index === 1 ? classes.Dashboard__table__avg : ''}`}
                        >
                          {
                            value.field === 'wd1_mode'
                              ? (
                                !!dataRow[value.field]
                                ? <WindDirection
                                  angle={dataRow[value.field]}
                                  align={'right'}
                                />
                                : '---'
                              )
                              : dataRow[value.field] || '---'
                          }
                        </TableCell>
                      )
                  )
                }
              </TableRow>
            )
          }
        </TableBody>
      </Table>
    </div>
    <div className={classes.Dashboard__table_export}>
      <Table id='equipment-dashboard-table-export'>
        <TableHead>
          <TableRow>
            {
              tableHeaders.map(header =>
                <Header key={header.title} header={header} classes={classes} />
              )
            }
          </TableRow>
          <TableRow>
            {
              tableHeaders.map(
                header =>
                  <SecondaryHeaders key={header.title} header={header} classes={classes} />
              )
            }
          </TableRow>
        </TableHead>
        <TableBody>
          {
            data.map((dataRow, index) =>
              <TableRow key={index}>
                {
                  tableHeaders.map((row, index) =>
                    row.field ?
                      <TableCell
                        className={`${classes.Dashboard__table__cell} tableexport-string`}
                        align='center'
                        key={`${row.title}-${index}`}
                      >
                        {dataRow[row.field] !== null ? dataRow[row.field] : ''}
                      </TableCell>
                      :
                      row.data.map((value, index) =>
                        <TableCell
                          key={`${value.title}-${index}`}
                          className={`${classes.Dashboard__table__cell} ${index === 1 ? classes.Dashboard__table__avg : ''}`}
                        >
                          {dataRow[value.field] || ''}
                        </TableCell>
                      )
                  )
                }
              </TableRow>
            )
          }
        </TableBody>
      </Table>
    </div>

  </Paper>

export default DashboardTable
