import React from 'react'

import classNames from 'classnames'

import {
  Divider,
  Grid,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  SwipeableDrawer,
  withStyles
} from '@material-ui/core'

import {
  ChevronLeft as ChevronLeftIcon,
  Menu as MenuIcon
} from '@material-ui/icons'

import AreasIcon from '@images/sidebar/areas.svg'
import MapsIcon from '@images/sidebar/maps.svg'
import EquipmentsIcon from '@images/sidebar/equipments.svg'
import InputsIcon from '@images/sidebar/inputs.svg'
import CulturesIcon from '@images/sidebar/cultures.svg'
import SeasonsIcon from '@images/sidebar/seasons.svg'
import StoreIcon from '@images/sidebar/store.svg'
import WidgetsIcon from '@images/sidebar/widgets.svg'

import styles from './styles'
import Logo from '../NavBar/Logo/index'

import { useRouter } from '@contexts/RouterProvider'
import { useSessionData } from '@contexts/SessionDataProvider'
import { MENUS, useMenusStatus } from '@contexts/MenusStatusProvider'

const getSideBarOptions = showStore => [
  { text: I18n.t('activerecord.models.v4/map.other'), icon: MapsIcon, path: 'maps', key: 'map' },
  { text: I18n.t('activerecord.models.v4/widgets.one'), icon: WidgetsIcon, path: 'widgets', key: 'widgets' },
  { text: I18n.t('activerecord.models.v4/area.other'), icon: AreasIcon, path: 'areas', key: 'areas' },
  { text: I18n.t('activerecord.models.v4/equipment.other'), icon: EquipmentsIcon, path: 'equipments', key: 'equipments' },
  { text: I18n.t('activerecord.models.v4/culture.other'), icon: CulturesIcon, path: 'cultures', key: 'crops' },
  { text: I18n.t('activerecord.models.v4/season.other'), icon: SeasonsIcon, path: 'seasons', key: 'seasons' },
  { text: I18n.t('activerecord.models.v4/input.other'), icon: InputsIcon, path: 'inputs', key: 'inputs' },
  { text: I18n.t('activerecord.models.v4/store.one'), icon: StoreIcon, path: 'store', key: 'store', hide: !showStore }  
]

export const SideBar = ({ classes, isTrial, theme }) => {
  const router = useRouter()
  const { menus, toggleMenu } = useMenusStatus()

  const open = menus[MENUS.sideBarMenu].open

  const {
    showStore,
    currentTenant,
    currentView
  } = useSessionData()

  const menuOptions = getSideBarOptions(showStore)
  const currentPath = currentView.split(/\//)[0]

  return (
    <>
      {
        !open &&
        <IconButton
          onClick={() => toggleMenu(MENUS.sideBarMenu, true)}
          className={isTrial ? classes.SideBar_IconTrial : classes.SideBar_Icon}
          data-intercom-target='left-side-menu'
        >
          <MenuIcon />
        </IconButton>
      }

      <SwipeableDrawer
        className={classNames(classes.drawer, {
          [classes.drawerOpen]: open
        })}
        classes={{
          paper: classNames(classes.paper, {
            [classes.drawerOpen]: open
          })
        }}
        onClose={() => toggleMenu(MENUS.sideBarMenu, false)}
        onOpen={() => toggleMenu(MENUS.sideBarMenu, true)}
        open={open}
      >
        <Grid container className={classes.toolbar} spacing={0}>
          <Grid item xs={10}>
            <a
              href={`${router.baseURL}maps`}
              className={classes.menu__logoLink}
            >
              <Logo
                logo={currentTenant.logoUrl}
                smallLogo={currentTenant.smallLogoUrl}
              />
            </a>
          </Grid>

          <Grid item xs={2}>
            <IconButton
              onClick={() => toggleMenu(MENUS.sideBarMenu, false)}
            >
              <ChevronLeftIcon className={classes.SideBar_Icon__open} />
            </IconButton>
          </Grid>
        </Grid>

        <Divider />

        <List className={classes.SideBar_scrollable}>
          {
            menuOptions.map(option => (
              !option.hide &&
              <div data-intercom-target={option.key} key={option.key}>
                <ListItem
                  className={classes.SideBar__item}
                  onClick={() => router.visit(option.path)}
                  title={option.text}
                  key={option.text}
                  component={Link}
                  button
                >
                  <ListItemIcon
                    className={classes.SideBar__Icon}
                  >
                    <span style={{
                      WebkitMask: `url(${option.icon}) no-repeat center`,
                      mask: `url(${option.icon}) no-repeat center`,
                      background: currentPath === option.path
                        ? theme.palette.primary.main
                        : theme.palette.text.primary,
                      height: 23,
                      width: 22
                    }} />
                  </ListItemIcon>

                  <ListItemText
                    primary={option.text}
                    classes={{
                      primary: currentPath === option.path && classes.SideBar_current
                    }}
                  />
                </ListItem>
              </div>
            ))
          }
        </List>
      </SwipeableDrawer>
    </>
  )
}

export default withStyles(styles, { withTheme: true })(SideBar)
