import React from 'react'
import {
  ResponsiveContainer, ComposedChart, XAxis, YAxis,
  Tooltip, Bar, CartesianGrid, Legend, Cell
} from 'recharts'
import { getYAxisOrientation, chartsFontFamily } from '@models/chart'
import PropTypes from 'prop-types'
import CustomLegend from './Legend'
import CustomTooltip from './Tooltip'

const AccumulatedRainChart = ({ data, xData, classes, descriptors, showingData, chartConfigs, getDescriptor }) =>
  <ResponsiveContainer width='100%' height={400}>
    <ComposedChart
      data={data}
    >
      <CartesianGrid strokeDasharray='3 3' />

      <XAxis
        fontFamily={chartsFontFamily}
        dataKey={xData}
      />
      {
        showingData.filter(serie => !serie.yAxisId).map((serie, index) =>
          <YAxis
            fontFamily={chartsFontFamily}
            yAxisId={serie.id}
            key={serie.id}
            orientation={getYAxisOrientation(index)}
          />
        )
      }

      <Legend
        content={
          <CustomLegend
            classes={classes}
          />
        }
      />

      <Tooltip
        content={
          <CustomTooltip
            getDescriptor={getDescriptor}
            series={showingData}
            descriptors={descriptors}
            classes={classes}
            chartConfigs={chartConfigs}
          />
        }
      />

      {
        showingData.map(chart =>
          <Bar
            type='monotone'
            yAxisId={chart.id}
            dataKey={chart.id}
            key={chart.id}
            hide={chart.hide}
          >
            {
              data.map((entry, index) => {
                const color = chartConfigs.colorRules(entry.pop).color

                return <Cell key={index} fill={color} />
              })
            }
          </Bar>
        )
      }
    </ComposedChart>
  </ResponsiveContainer>

AccumulatedRainChart.defaultProps = {
  data: [],
  series: [],
  descriptors: []
}

AccumulatedRainChart.propTypes = {
  data: PropTypes.array,
  showingData: PropTypes.array,
  xData: PropTypes.string.isRequired,
  classes: PropTypes.object,
  series: PropTypes.array,
  descriptors: PropTypes.array
}

export default AccumulatedRainChart
