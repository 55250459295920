import React, { Component } from 'react'

import { Button, Paper, Typography, withStyles } from '@material-ui/core'

import { withRouter } from '@contexts/RouterProvider'
import { withSessionData } from '@contexts/SessionDataProvider'
import { withHttpService } from '@contexts/HttpServiceProvider'
import { withErrorHandler } from '@contexts/ErrorHandlerProvider'
import { getFieldNotebookHeaders } from '@models/fieldNotebook'
import MaterialTable from '@ui/Table'
import DeleteDialog from '@ui/DeleteDialog'
import Dialog from '@ui/Dialog'
import styles from '@theme/styles/forms'
import DialogForm from './DialogForm.js'

class Edit extends Component {
  constructor(props) {
    super(props)

    this.state = {
      action: null,
      field_notebooks: [],
      isOpenDeleteDialog: false,
      isOpenFormDialog: false,
      selectedInsight: null,
      editedInsight: null,
      newInsight: {
        name: '',
        url: ''
      }
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  indexProductsUrl = () => {
    const {
      router,
      sessionData: { currentOrganization }
    } = this.props

    return `${router.baseURL}admin/organizations/${this.productBI().organization_id}/products`
  }

  productBI = () => {
    const { model } = this.props

    return model
  }

  organizationId = () => {
    const {
      sessionData: { currentOrganization }
    } = this.props

    return currentOrganization.id
  }

  fetchData = async () => {
    const {
      errorHandler,
      httpService: { apiRequest }
    } = this.props

    const params = {
      organization_id: this.organizationId(),
      organization_product_id: this.productBI().id
    }

    try {
      // const fetchUrl = '/field_notebooks/get_field_notebooks'
      const { data } = await apiRequest(fetchUrl, { params })
      this.setState({ field_notebooks: data })
    }
    catch (error) {
      errorHandler(error)
    }
  }

  handleDeleteDialogToggle = (selectedInsight) => {
    this.setState({
      isOpenDeleteDialog: !this.state.isOpenDeleteDialog,
      selectedInsight
    })
  }

  handleDeleteInsight = async (deletedInsight) => {
    const {
      errorHandler,
      httpService: { apiRequest }
    } = this.props

    try {
      const { field_notebooks } = this.state
      const indexDeleted = deletedInsight.tableData.id
      const newInsights = field_notebooks.filter((field_notebook, index) => index !== indexDeleted)
      const params = {
        organization_id: this.organizationId(),
        id: deletedInsight.id
      }

      await apiRequest.delete('/field_notebooks/destroy', { params })

      this.setState({ field_notebooks: newInsights })
    }
    catch (error) {
      errorHandler(error)
    }
  }

  handleNewInsight = () => this.setState({
    action: 'create',
    isOpenFormDialog: true,
    newInsight: {
      name: '',
      url: ''
    }
  })

  handleEditInsight = (selectedInsight) => {
    this.setState({
      action: 'edit',
      isOpenFormDialog: true,
      editedInsight: selectedInsight,
      newInsight: {
        name: selectedInsight.name,
        url: selectedInsight.url
      }
    })
  }

  handleCloseFormDialog = () => this.setState({ isOpenFormDialog: false })

  handleSubmit = (data) => {
    const { action } = this.state

    switch (action) {
      case 'create':
        return this.handleCreate(data)
      case 'edit':
        return this.handleEdit(data)
    }    
  }

  handleEdit = async (data) => {
    const {
      errorHandler,
      httpService: { apiRequest }
    } = this.props

    const { field_notebooks, editedFieldNotebook } = this.state

    const params = {
      organization_id: this.organizationId(),
      field_notebook: {
        id: editedFieldNotebook.id,
        name: data.name,
        url: data.url,
        organization_product_id: this.productBI().id
      }
    }
    
    try {
      await apiRequest.patch('field_notebooks/edit', params)

      const newFieldNotebooks = [...field_notebooks]

      newFieldNotebooks[editedFieldNotebook.tableData.id] = { ...editedFieldNotebook, ...data }

      this.setState({ field_notebooks: newFieldNotebooks, isOpenFormDialog: false })
    }
    catch (error) {
      errorHandler(error)
    }
  }

  handleCreate = async (data) => {
    const {
      errorHandler,
      httpService: { apiRequest }
    } = this.props

    const params = {
      organization_id: this.organizationId(),
      field_notebook: {
        ...data,
        organization_product_id: this.productBI().id
      }
    }

    try {
      await apiRequest.post('/field_notebooks/create', params)
      .then(response => {
        const newFieldNotebooks = [...this.state.field_notebooks]

        newFieldNotebooks.push(response.data)

        this.setState({field_notebooks: newFieldNotebooks, isOpenFormDialog: false})
      })
    }
    catch (error) {
      errorHandler(error)
    }
  }

  render() {
    const { classes } = this.props

    const {
      action,
      field_notebooks,
      isOpenDeleteDialog,
      isOpenFormDialog,
      selectedFieldNotebook
    } = this.state

    return (
      <Paper className={classes.form__wrapper} elevation={0}>
        <header className={classes.form__header}>
          <Typography component='h2' variant='h5'>
            {I18n.t('v4/field_notebook.config.title')}
          </Typography>
        </header>

        <main className={classes.form__fields}>
          <MaterialTable
            columns={getFieldNotebookHeaders()}
            data={field_notebooks}
            title={I18n.t('v4/field_notebook.table_title')}
            actions={[
              {
                icon: 'add',
                tooltip: I18n.t('actions.add'),
                isFreeAction: true,
                onClick: this.handleNewFieldNotebook
              },
              rowData => ({
                icon: 'edit',
                iconProps: { 'data-testid': `edit-${rowData.id}` },
                tooltip: I18n.t('actions.edit'),
                onClick: () => this.handleEditFieldNotebook(rowData)
              }),
              rowData => ({
                icon: 'delete',
                iconProps: { 'data-testid': `delete-${rowData.id}` },
                tooltip: I18n.t('actions.remove'),
                onClick: () => this.handleDeleteDialogToggle(rowData)
              })
            ]}
            options={{
              search: false,
              sorting: false,
              actionsColumnIndex: -1,
              headerStyle: {
                backgroundColor: '#f5f5f5'
              }
            }}
          >
          </MaterialTable>
        </main>

        <footer className={classes.form__actions}>
          <Button
            className={classes.form__action}
            color='secondary'
            href={this.indexProductsUrl()}
          >
            {I18n.t('actions.back')}
          </Button>
        </footer>

        <Dialog
          open={isOpenFormDialog}
          title={
            action === 'create'
            ? I18n.t('v4/field_notebook.add_dialog_title')
            : I18n.t('v4/field_notebook.edit_dialog_title')
          }
        >
          <DialogForm
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit}
            onCancel={this.handleCloseFormDialog}
            initialValues={this.state.newFieldNotebook}
          />
        </Dialog>

        <DeleteDialog
          dialogTitle={I18n.t('v4/field_notebook.remove_dialog_title')}
          dialogText={I18n.t('confirmation.remove')}
          onCancel={this.handleDeleteDialogToggle}
          onClose={this.handleDeleteDialogToggle}
          handleDelete={() => {
            this.handleDeleteFieldNotebook(selectedFieldNotebook)
            this.handleDeleteDialogToggle(selectedFieldNotebook)
          }}
          open={isOpenDeleteDialog}
        />
      </Paper>
    )
  }
}


export default
  withSessionData(
    withRouter(
      withHttpService(
        withErrorHandler(
          withStyles(styles)(Edit)
        )
      )
    )
  )
