import React, { Fragment } from 'react'
import { Paper, Typography, Button } from '@material-ui/core'
import CheckIcon from '@material-ui/icons/CheckCircle'

import { withStyles } from '@material-ui/core/styles'

const SuccessScreen = ({ classes, organization_id, o }) =>
  <Fragment>
    <Paper className={classes.SuccessScreen}>
      <Typography variant='h5' className={classes.SuccessScreen__title}>
        {I18n.t('v4/payment_checkout.success_screen.success_text')}
      </Typography>

      <CheckIcon className={classes.SuccessScreen__icon} fontSize='large' />
    </Paper>

    <div className={classes.SuccessScreen__Actions}>
      <Button
        className={classes.SuccessScreen__Action}
        variant='contained'
        color='primary'
        onClick={() => location.replace(`/v4/o/${o}/organizations/${organization_id}/invoices`)}
      >
        {I18n.t('v4/payment_checkout.success_screen.order_status')}
      </Button>
    </div>
  </Fragment>

const styles = (theme) => ({
  SuccessScreen: {
    margin: `${theme.spacing.unit * 3}px 0`,
    maxWidth: 'max-content',
    padding: theme.spacing.unit * 4,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
  },
  SuccessScreen__title: {
    color: theme.palette.primary.main,
    fontWeight: 'bold',
    marginBottom: `${theme.spacing.unit * 3}px`
  },
  SuccessScreen__icon: {
    color: theme.palette.primary.main,
  },
  SuccessScreen__Actions: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: `${theme.spacing.unit * 2}px`
  },
  SuccessScreen__Action: {
    '&:not(:last-child)': {
      marginRight: `${theme.spacing.unit * 2}px`
    }
  },
})

export default withStyles(styles)(SuccessScreen)
