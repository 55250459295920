import React from 'react'

import { Typography } from '@material-ui/core'

const Uninstalled = ({ classes }) =>
  <div className={classes.Equipment_Status}>
    <Typography variant='body1' className={classes.Equipment_installed_or_uninstalled}>
      {I18n.t('v4/equipment.dashboard.status.uninstalled')}
    </Typography>
  </div>

export default Uninstalled
