import React, { createContext, useContext, useMemo } from 'react'

import getNormalizedProps from './getNormalizedProps'

const SessionDataContext = createContext()

export const useSessionData = () => useContext(SessionDataContext)

const SessionDataProvider = ({ data, children }) => {
  const acceptedProps = [
    'alerts_notifications',
    'current_organization',
    'current_tenant',
    'current_user',
    'current_view',
    'organizations',
    'products',
    'show_store',
    'show_app'
  ]


  const selectedProps = getNormalizedProps(data, acceptedProps)

  const value = useMemo(() => selectedProps, [selectedProps])

  return (
    <SessionDataContext.Provider value={value}>
      {children}
    </SessionDataContext.Provider>
  )
}

export const withSessionData = component => props =>
  React.createElement(component, { ...props, sessionData: useSessionData() })

export default SessionDataProvider
