import React from 'react'
import MenuItem from '@material-ui/core/MenuItem'
import TextField from '@material-ui/core/TextField'
import FlagIcon from '@support/FlagIcon.js'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './styles'

const SelectFlagIcon = ({
  data,
  input,
  meta: { error, touched },
  selectBy,
  fields,
  classes,
  ...textFieldProps
}) =>
  <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    select
    {...input}
    {...textFieldProps}
  >
    {
      data.map(datum => {
        const value = datum[fields.id] === undefined ? datum[fields.name] : datum[fields.id]
        return (
          <MenuItem value={value} key={value}>
            <FlagIcon code={datum[fields.idIcon]} />
            <span className={classes.countryLabel}>
              {datum[fields.name]}
            </span>
          </MenuItem>
        )
      })
    }
  </TextField>

export default withStyles(styles)(SelectFlagIcon)
