import React from 'react'
import { convertToLocaleDate } from '@services/locale'
import ShowProdutivity from '../../components/v4/areas/dashboard/ShowProdutivity'
import InformProductivity from '../../components/v4/areas/dashboard/InformProdutivity'


const renderProdutivity = (yield_value, yield_unity, id, harvesting_date) => {
  if (yield_value != null && yield_unity != null) {
    return <ShowProdutivity yield_value={yield_value} yield_unity={yield_unity} id={id} harvesting_date={harvesting_date}/>
  }
  else if (harvesting_date != null) {
    return <InformProductivity id={id} harvesting_date={harvesting_date} />
  }
  else return null
}

export const lastPlantationsTableHeaders = () => [
  {
    title: I18n.t('activerecord.attributes.v4/culture.name'),
    field: 'name'
  },
  {
    title: I18n.t('activerecord.attributes.v4/culture.planting_date'),
    field: 'planting_date',
    render: ({ planting_date }) => planting_date && convertToLocaleDate(planting_date)
  },
  {
    title: I18n.t('activerecord.attributes.v4/culture.harvesting_date'),
    field: 'harvesting_date',
    render: ({ harvesting_date }) => harvesting_date && convertToLocaleDate(harvesting_date)
  },
  {
    title: I18n.t('activerecord.attributes.v4/culture.productivity'),
    field: 'culture_productivity',
    render: ({ yield_unity, yield_value, id, harvesting_date }) => renderProdutivity(yield_value, yield_unity, id, harvesting_date)
  }
]

export const phenologicalStagesFields = () => [
  { name: 'started_at', render: (value) => convertToLocaleDate(value) },
  { name: 'days_on_stage' },
  { name: 'duration' },
  { name: 'status', render: (value) => I18n.t(`enumerize.v4/culture/phenological_stage.status.${value}`) },
  { name: 'kc' }
]

export const validate = values => {
  const errors = {}
  const requiredFields = ['date']
  const date = new Date(values.date)
  const currentDate = new Date()

  requiredFields.forEach(field => {
    if (!values[field]) errors[field] = I18n.t('validations.required')
  })

  if (date > currentDate) {
    errors.date = I18n.t('validations.invalid_date')
  }

  return errors
}

export const validateNewPlantation = values => {
  const errors = {}
  const requiredFields = ['planting_date', 'culture_id']
  const date = new Date(values.planting_date)
  const currentDate = new Date()

  requiredFields.forEach(field => {
    if (!values[field]) errors[field] = I18n.t('validations.required')
  })

  if (date > currentDate) {
    errors.planting_date = I18n.t('validations.invalid_date')
  }

  return errors
}

export const selectStageClass = (stage, currentStage) => {
  switch (true) {
    case (stage.order < currentStage):
      return 'PhenologicalStage--previous'
    case (stage.order === currentStage):
      return 'PhenologicalStage--current'
    case (stage.order > currentStage):
      return 'PhenologicalStage--next'
    default:
      return ''
  }
}
