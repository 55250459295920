import React from 'react'

import { getManualDashboardHeaders, deserialize } from '@models/equipment/manual'

import Table from '@ui/Table'

const DashboardTable = ({ handleOpenDialog, measures }) =>
  <Table
    key='manual-equipment-table'
    tableKey='manual-equipment-index'
    components={{
      Container: props => <div>{props.children}</div>,
    }}
    columns={getManualDashboardHeaders()}
    data={deserialize(measures)}
    options={{
      showTitle: false,
      search: false,
      toolbar: false
    }}
    actions={[
      rowData => ({
        icon: 'create',
        iconProps: { 'data-testid': `edit-${rowData.id}` },
        tooltip: I18n.t('actions.edit'),
        onClick: (event, rowData) => handleOpenDialog('edit', rowData)
      }),
      rowData => ({
        icon: 'delete',
        iconProps: { 'data-testid': `delete-${rowData.id}` },
        tooltip: I18n.t('actions.remove'),
        onClick: (event, rowData) => handleOpenDialog('delete', rowData)
      })
    ]}
  />

export default DashboardTable
