import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { withSnackbar } from 'notistack';

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import Table from '@ui/Table'

import { getProductsHeaders } from '@models/admin/product'
import httpService from '@services/httpService'
import DeleteDialog from '@ui/DeleteDialog'
import styles from '@styles/indexPage'

class Index extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isDialogOpen: false,
      productId: null,
      isSnackbarOpen: false
    }
  }

  closeDialog = () => {
    this.setState({ isDialogOpen: false })
  }

  handleDelete = productId => {
    const { enqueueSnackbar, o } = this.props

    httpService.delete(`/v4/o/${o}/admin/products/${productId}.json`)
      .then(res => Turbolinks.visit(`/v4/o/${o}/admin/products`))
      .catch(err => enqueueSnackbar(I18n.t('v4/organization.products.forecast.delete_error'), { variant: 'error' }))
  }

  render() {
    const { model, classes, o } = this.props
    const { isDialogOpen, isSnackbarOpen, productId } = this.state

    return (
      <Paper className={classes.page__wrapper} elevation={0}>
        <header className={classes.page__header}>
          <Typography component='h2' variant='h5'>
            {I18n.t('activerecord.models.v4/product.other')}
          </Typography>
        </header>
        <Button
          variant='outlined'
          color='primary'
          className={classes.page__newResourceButton}
          href={`/v4/o/${o}/admin/products/new`}
        >
          {I18n.t('actions.create')} {I18n.t('activerecord.models.v4/product.one')}
        </Button>

        <Table
          tableKey='admin-products-index'
          components={{
            Container: props => <div>{props.children}</div>,
          }}
          columns={getProductsHeaders()}
          data={model}
          actions={[
            rowData => ({
              icon: 'create',
              iconProps: { 'data-testid': `edit-${rowData.id}` },
              tooltip: I18n.t('actions.edit'),
              onClick: (event, rowData) => {
                Turbolinks.visit(`/v4/o/${o}/admin/products/${rowData.id}/edit`)
              }
            }),
            rowData => ({
              icon: 'delete',
              iconProps: { 'data-testid': `delete-${rowData.id}` },
              tooltip: I18n.t('actions.remove'),
              onClick: (event, rowData) => {
                this.setState({ isDialogOpen: true, productId: rowData.id })
              }
            })
          ]}
          options={{
            showTitle: false
          }}
        />

        <DeleteDialog
          dialogTitle={`${I18n.t('actions.remove')} ${I18n.t('activerecord.models.v4/product.one')}?`}
          dialogText={I18n.t('confirmation.remove')}
          handleDelete={() => this.handleDelete(productId)}
          onCancel={this.closeDialog}
          onClose={this.closeDialog}
          open={isDialogOpen}
        />
      </Paper>
    )
  }
}

Index.propTypes = {
  model: PropTypes.array
}

export default withSnackbar(
  withStyles(styles)(Index)
)
