import React from 'react'

import { Button, Grid, Paper, withStyles } from '@material-ui/core'

import { Field } from 'react-final-form'

import DatePicker from '@ui/Fields/DatePicker'
import styles from '@theme/styles/forms'

const FilterForm = ({ handleSubmit, classes, disabled }) =>
  <form onSubmit={handleSubmit} data-testid='charts-filter'>
    <Paper className={classes.Form_wrapper}>
      <Grid container spacing={16} alignItems="center">
        <Grid item xs={12} sm={5}>
          <Field
            className={classes.form__fieldWithError}
            component={DatePicker}
            margin='normal'
            InputLabelProps={{ shrink: true }}
            variant='outlined'
            label={I18n.t('v4/equipment.dashboard.filter.fields.start_date')}
            name='start'
            fullWidth
            disableFuture
            required
          />
        </Grid>

        <Grid item xs={12} sm={5}>
          <Field
            className={classes.form__fieldWithError}
            component={DatePicker}
            margin='normal'
            InputLabelProps={{ shrink: true }}
            variant='outlined'
            label={I18n.t('v4/equipment.dashboard.filter.fields.end_date')}
            name='finish'
            fullWidth
            initialValue={new Date()}
            disableFuture
            required
          />
        </Grid>

        <Grid item xs={12} sm={2}>
          <Button
            className={classes.Filter_action}
            name='filter'
            color='primary'
            type='submit'
            disabled={disabled}
            variant='contained'
          >
            {I18n.t('v4/equipment.dashboard.filter.action')}
          </Button>
        </Grid>
      </Grid>

    </Paper>
  </form>

const customStyles = theme => ({
  ...styles(theme),
  Form_wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    marginBottom: theme.spacing.unit * 2
  },
  Filter_action: {
    width: '100%'
  }
})

export default withStyles(customStyles)(FilterForm)
