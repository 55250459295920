export default theme => ({
  mdeditor__container: {
    marginTop: 16,
    marginBottom: 8
  },
  mdeditor__wrapper__warning: {
    border: '1px solid #f44336',
    borderRadius: 2,
  },
  mdeditor__label: {
    color: 'rgba(0, 0, 0, 0.54)',
    margin: '5px 12px 0',
    fontSize: '0.8rem'
  },
  mdeditor__label__warning: {
    color: '#f44336',
    margin: '5px 12px 0',
    fontSize: '0.8rem'
  }
})
