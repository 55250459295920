import React from 'react'

import SelectLocale from './SelectLocale'
import { Link, Typography } from '@material-ui/core'

import backgroundImage from '../../../../assets/images/bg-agro-login.jpg'
import defaultLogo from '../../../../assets/images/new_logo.png'

const DeviseScreen = ({ classes, logo_url, background_image_url, children }) => {
  return (
    <div className={classes.Login__wrapper}>
      <div
        className={classes.Login__background}
        style={{
          backgroundImage: `url(${background_image_url || backgroundImage})`
        }}
      >
      </div>
      <div className={classes.Login__container}>
        <div className={classes.Login__form_container}>
          <div className={classes.Login__logo}>
            <img
              src={logo_url || defaultLogo}
              width='100%'
            >
            </img>
          </div>
          {children}
          <SelectLocale classes={classes} />
          <Typography variant="h6" > <small>Agrosmart {new Date().getFullYear()}</small> </Typography>

          <div className={classes.Login_bottom}>
            <Link
              href={`/v5/terms`}
              target='_blank'
              className={classes.Login_termsLink}
              variant='h6'
            >
              {I18n.t('v4/users.terms_of_use')}
            </Link>

            <Link
              href={`/v5/privacy_policies`}
              target='_blank'
              className={classes.Login_termsLink}
              variant='h6'
            >
              {I18n.t('v4/users.privacy_policies')}
            </Link>
          </div>

        </div>
      </div>
    </div>
  )
}
export default DeviseScreen
