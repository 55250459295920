import React from 'react'

import ThemeProvider from './ThemeProvider'
import RouterProvider from './RouterProvider'
import ErrorHandlerProvider from './ErrorHandlerProvider'
import HttpServiceProvider from './HttpServiceProvider'
import ConfirmDialogProvider from './ConfirmDialogProvider'
import SessionDataProvider from './SessionDataProvider'
import MenusStatusProvider from './MenusStatusProvider'

const ContextsProvider = ({ children, o, sessionData, themeOption }) =>
  <SessionDataProvider data={sessionData}>
    <ThemeProvider themeOption={themeOption}>
      <RouterProvider organizationIndex={o}>
        <HttpServiceProvider>
          <MenusStatusProvider>
            <ConfirmDialogProvider>
              <ErrorHandlerProvider>
                {children}
              </ErrorHandlerProvider>
            </ConfirmDialogProvider>
          </MenusStatusProvider>
        </HttpServiceProvider>
      </RouterProvider>
    </ThemeProvider>
  </SessionDataProvider>

export default ContextsProvider
