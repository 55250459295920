import React from 'react'

export const getBoundedCalculationResultInfo = ({ calculationResult }) => ({
  mainText: getMainText(calculationResult),
  texts: getTexts(calculationResult, calculationResult.flow_unit),
  table: getTable(calculationResult),
  image: () => getImage(calculationResult.operations)
})

const getImage = (operations) => {
  if (!operations) return null

  const squaresPerLine = Math.round(Math.sqrt(Number(operations)))
  const operationsArray = [...Array(Number(operations)).keys()]
  const chunckedArray = _.chunk(operationsArray, squaresPerLine)

  return <div>
    {
      chunckedArray.map(line =>
        <div key={line} style={{ display: 'flex' }}>
          {line.map(square => <Square key={square} />)}
        </div>
      )
    }
  </div>
}

const Square = () =>
  <div
    style={{
      width: '15px',
      height: '15px',
      border: '1px solid black'
    }}
  />

const getMainText = (calculationResult) => {
  const total_time = Number(calculationResult.operations) * Number(calculationResult.max_blade.time_final)

  return I18n.t('v4/irrigation_system.bounded.calculation.main_text', {
    operations: calculationResult.operations,
    time_final: calculationResult.max_blade.time_final,
    total_time
  })
}

const getTexts = (calculationResult, flow_unit) => [
  I18n.t('v4/irrigation_system.bounded.calculation.texts.flow_unit', { flow_unit }),
  I18n.t('v4/irrigation_system.bounded.calculation.texts.time_final', { time_final: _.round(calculationResult.max_blade.time_final, 2) }),
  I18n.t('v4/irrigation_system.bounded.calculation.texts.irrigation_final', { irrigation_final: _.round(calculationResult.max_blade.irrigation_final, 2) })
]

const getTable = (calculationResult) => {
  const time_area1 = 1
  const time_area2 = _.round(calculationResult.max_blade.time_final, 2)
  const time_full_area1 = _.round(time_area1 * calculationResult.operations, 2)
  const time_full_area2 = _.round(time_area2 * calculationResult.operations, 2)
  const irrigation_blade1 = _.round(calculationResult.irrigation_flow * time_area1, 2)
  const irrigation_blade2 = _.round(calculationResult.irrigation_flow * time_area2, 2)
  const full_irrigation_blade1 = _.round(irrigation_blade1 * calculationResult.operations, 2)
  const full_irrigation_blade2 = _.round(irrigation_blade2 * calculationResult.operations, 2)

  return {
    headers: [
      {
        title: I18n.t('v4/irrigation_system.bounded.calculation.table.time_area'),
        field: 'time_area'
      },
      {
        title: I18n.t('v4/irrigation_system.bounded.calculation.table.time_full_area', { operations: calculationResult.operations }),
        field: 'time_full_area'
      },
      {
        title: I18n.t('v4/irrigation_system.bounded.calculation.table.irrigation_blade'),
        field: 'irrigation_blade'
      },
      {
        title: I18n.t('v4/irrigation_system.bounded.calculation.table.full_irrigation_blade', { operations: calculationResult.operations }),
        field: 'full_irrigation_blade'
      }
    ],
    data: [
      {
        time_area: time_area1 > 1 ? `${time_area1} ${I18n.t('hours')}` : `${time_area1} ${I18n.t('hour')}`,
        time_full_area: time_full_area1 > 1 ? `${time_full_area1} ${I18n.t('hours')}` : `${time_full_area1} ${I18n.t('hour')}`,
        irrigation_blade: `${irrigation_blade1} mm`,
        full_irrigation_blade: `${full_irrigation_blade1} mm`
      },
      {
        time_area: time_area2 > 1 ? `${time_area2} ${I18n.t('hours')}` : `${time_area2} ${I18n.t('hour')}`,
        time_full_area: time_full_area2 > 1 ? `${time_full_area2} ${I18n.t('hours')}` : `${time_full_area2} ${I18n.t('hour')}`,
        irrigation_blade: `${irrigation_blade2} mm`,
        full_irrigation_blade: `${full_irrigation_blade2} mm`
      }
    ]
  }
}

export const irrigation_limitations = [
  { id: 'max_time', name: I18n.t('v4/irrigation_system.bounded.form.irrigation_limitations.max_time') },
  { id: 'max_blade', name: I18n.t('v4/irrigation_system.bounded.form.irrigation_limitations.max_blade') }
]

export const flow_units = [
  { id: 'mm/h', name: I18n.t('v4/irrigation_system.bounded.form.flow_units.mm/h') },
  { id: 'l/h', name: I18n.t('v4/irrigation_system.bounded.form.flow_units.l/h') }
]
