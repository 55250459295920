import React from 'react'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import { Field } from 'react-final-form'

import { userAvaliableLocales } from '@models/admin/tenantUsers'
import SelectFlagIcon from '@ui/Fields/FlagIcon/Index'
import TextField from '@ui/Fields/TextField'
import styles from '@theme/styles/forms'

const Form = ({
  handleSubmit,
  classes,
  title,
  tenant,
  o
}) => {
  return <form className={classes.form} onSubmit={handleSubmit} data-testid='tenants-users-form'>
    <Paper className={classes.form__wrapper} elevation={0}>
      <header className={classes.form__header}>
        <Typography component='h2' variant='h5'>
          {title}
        </Typography>
      </header>
      <main className={classes.form__fields}>
        <div className={classes.form__field__wrapper}>
          <Field
            component={TextField}
            className={classes.form__fieldWithError}
            fullWidth
            InputLabelProps={{ shrink: true }}
            label={I18n.t('activerecord.attributes.v4/users.email')}
            margin='normal'
            name='email'
            type='email'
            variant='outlined'
            required
          />
        </div>

        <div className={classes.form__field__wrapper}>
          <Field
            component={SelectFlagIcon}
            className={classes.form__fieldWithError}
            InputLabelProps={{ shrink: true }}
            label={I18n.t('activerecord.attributes.v4/users.locale')}
            name='locale'
            margin='normal'
            fields={{ id: 'id', idIcon: 'iconId', name: 'name' }}
            data={userAvaliableLocales}
            fullWidth
            variant='outlined'
          >
          </Field>
        </div>
      </main>
      <footer className={classes.form__actions}>
        <Button
          className={classes.form__action}
          color='primary'
          type='submit'
          variant='contained'
        >
          {title}
        </Button>

        <Button
          className={classes.form__action}
          color='secondary'
          href={`/v4/o/${o}/admin/tenants/${tenant.id}/users`}
        >
          {I18n.t('actions.back')}
        </Button>
      </footer>
    </Paper>
  </form>
}

export default withStyles(styles)(Form)
