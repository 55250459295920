import React, { Component } from 'react'

import withStyles from '@material-ui/core/styles/withStyles'

import Navbar from './Navbar'
import Store from './Store'

import styles from './styles'

class Wrapper extends Component {
  state = {
    currentPlan: {}
  }

  componentDidMount() {
    const { plans, model } = this.props

    const products = model

    !_.isEmpty(plans) &&
      this.setState({
        currentPlan: plans.find(plan => plan.interval_count === 1) || plans[0]
      })

    products.forEach(product => {
      let maxPrice = null

      product.store_product_prices.forEach(price => {
        let plan = plans.filter(plan => plan.id === price.plan_id)
        let interval = plan[0]['interval_count']

        let monthlyPriceByPlan = Number(price.price) / interval

        price.monthly = Number(monthlyPriceByPlan.toFixed(2))
        price.interval = interval

        !maxPrice
          ? maxPrice = price
          : maxPrice.monthly < price.monthly
            ? maxPrice = price
            : null
      })
      product.maxPrice = maxPrice
    })
  }

  handleChangePlan = (event) => {
    this.setState({ currentPlan: event.target.value })
  }

  render() {
    const {
      handleChangePlan
    } = this

    const {
      currentPlan
    } = this.state

    const {
      classes,
      organization_id,
      model,
      plans,
      o
    } = this.props

    return (
      <div className={classes.page__wrapper}>
        <Navbar
          plans={plans}
          currentPlan={currentPlan}
          handleChangePlan={handleChangePlan}
          organization_id={organization_id}
          o={o}
        />
        <Store
          products={model}
          plans={plans}
          currentPlan={currentPlan}
          o={o}
        />
      </div>
    )
  }
}

Wrapper.defaultProps = {
  plans: []
}

export default withStyles(styles)(Wrapper)
