import React from 'react'

import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import Chip from '@material-ui/core/Chip'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import InputLabel from '@material-ui/core/InputLabel'
import FormControl from '@material-ui/core/FormControl'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './styles'

const MultipleSelect = ({
  classes,
  data,
  input,
  meta: { error, touched },
  selectBy,
  fields,
  label,
  ...textFieldProps
}) =>
  <FormControl
    {...textFieldProps}
    variant="outlined"
  >
    <InputLabel shrink>
      {label}
    </InputLabel>
    <Select
      error={!!(touched && error)}
      multiple
      input={<OutlinedInput notched labelWidth={label.length * 8.5} />}
      renderValue={selected => (
        <div className={classes.chips}>
          {
            selected.map((value) => {
              const dataValue = data.find(d => d[fields.id] === value)

              return <Chip
                className={classes.chip}
                key={value}
                label={!!dataValue ? dataValue[fields.name] : ''}
              />

            })
          }
        </div>
      )}
      {...input}
    >
      {
        data.map(datum => {
          const value = datum[fields.id] === undefined ? datum[fields.name] : datum[fields.id]

          return (
            <MenuItem value={value} key={value}>
              {datum[fields.name]}
            </MenuItem>
          )
        })
      }
    </Select>
  </FormControl>

MultipleSelect.defaultProps = {
  meta: {}
}

export default withStyles(styles)(MultipleSelect)
