export default theme => ({
  Filter__fields_wrapper: {
    display: 'flex',
    margin: '10px',
    justifyContent: 'space-between',
    alignItems: 'baseline',
    minHeight: theme.spacing.unit * 12.5,
    borderRadius: '10px'
  },
  Filter__field: {
    minHeight: theme.spacing.unit * 6,
    margin: '20px',
  },
  Filter__action: {
    margin: '0 20px',
    minWidth: '80px',
    padding: '10px'
  },
  Dashboard__wrapper: {
    backgroundColor: 'transparent',
    margin: `${theme.spacing.unit * 5}px`,
    padding: theme.spacing.unit * 4,
    maxWidth: 1600
  },
  Dashboard__header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: theme.spacing.unit * 4
  },
  Dashboard__loading: {
    display: 'flex',
    justifyContent: 'center',
    margin: '50px'
  },
  Dashboard__no_data: {
    display: 'flex',
    justifyContent: 'center',
    margin: '50px'
  },
  Dashboard__table_wrapper: {
    margin: '30px 0'
  },
  Dashboard__table_head: {
    display: 'flex',
    justifyContent: 'flex-end'
  },
  Dashboard__table_export_button: {
    margin: '20px',
    minWidth: 0,
    padding: '10px'
  },
  Dashboard__table: {
    maxHeight: '600px',
    overflow: 'auto'
  },
  Dashboard__table_export: {
    display: 'none'
  },
  Dashboard__table__cell: {
    border: '1px solid #e0e0e0',
    padding: '4px 24px',
    textAlign: 'center',
  },
  Dashboard__table__cell_header: {
    color: theme.palette.gray.dark,
    fontWeight: 'bold',
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  Dashboard__table__cell_header_title: {
    display: 'block'
  },
  Dashboard__table__avg: {
    fontWeight: 'bold',
    color: theme.palette.black
  },
  Dashboard__modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  }
})
