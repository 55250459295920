import styles from '@styles/indexPage'
import formStyles from '@theme/styles/forms'

export default theme => {
  const indexPageTheme = styles(theme)

  return {
    ...formStyles(theme),
    ...indexPageTheme,
    Field_IconButtonContainer: {
      display: 'flex',
      alignItems: 'center'
    },
    Field_IconButton: {
      height: '50%',
      marginLeft: '5px'
    },
    ChangeStatusButton__finish: {
      background: '#DC143C',
      color: '#FAFAFA',
      '&:hover': {
        background: '#AA1130'
      }
    },
    AdvancedParams_Button: {
      marginTop: theme.spacing.unit * 2
    }
  }
}
