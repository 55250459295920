import React from 'react'
import { Form as FinalForm } from 'react-final-form'

import { handleForecastSetup } from '@models/weather/forecast'
import { isValidCoordinates } from '@models/coordinates'

import httpService from '@services/httpService'
import { RailsForm } from '@support/RailsForm'

import Form from './Form'

export const ForecastPointForm = ({
  handleError,
  organizationId,
  forecastPoint,
  handleForecastUpdate,
  ...props 
}) => {
  const handleSubmit = async (values) => {
    try {
      if (!isValidCoordinates(values)) {
        throw(I18n.t('v4/forecast.configuration.invalid_location'))
      }

      const updatedForecast = await handleForecastSetup({ values, forecastPoint, organizationId })
      handleForecastUpdate && handleForecastUpdate(updatedForecast)
    }
    catch (error) {
      console.error(error)
      handleError(error)
    }
  }

  return (
    <RailsForm
      component={FinalForm}
      action='create'
      handleSubmit={handleSubmit}
      render={(props) => (
        <Form
          {...props}
          forecastPoint={forecastPoint}
          checkError={handleError}
        />
      )}
    />
  )
}

export default ForecastPointForm
