import React from "react";
import {Button as Action} from "@material-ui/core";
import {withStyles} from "@material-ui/core"

const Button = ({
  text,
  onClick,
  color,
  variant,
  intercomTag,
  useIcon,
  rightIcon,
  leftIcon,
  icon,
  size,
  className,
  classes,
  disabled
}) => {
  return (
    <Action
      color={color}
      onClick={onClick}
      variant={variant}
      data-intercom-target={intercomTag}
      size={size}
      className={className && classes.Action_Button }
      disabled={disabled}
    >
      {text}

      {useIcon && icon}
    </Action>
  );
};

const styles = theme => ({
    Action_Button: {
        width: '100%'
    }
})

export default withStyles(styles)(Button)