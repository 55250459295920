import React from 'react'
import { Form as FinalForm } from 'react-final-form'
import PropTypes from 'prop-types'

import httpService from '@services/httpService'
import { RailsForm } from '@support/RailsForm'
import Form from './Form'

export const New = ({ tenant, handleError, o }) => {
  const submit = data => {
    httpService({
      method: 'POST',
      url: `/v4/o/${o}/admin/tenants/${tenant.id}/users/invitation.json`,
      data
    })
      .then(() =>
        Turbolinks.visit(`/v4/o/${o}/admin/tenants/${tenant.id}/users`)
      )
      .catch(error => handleError(error))
  }

  return <RailsForm
    component={FinalForm}
    action='create'
    url={`/v4/o/${o}/admin/tenants/${tenant.id}/users`}
    handleSubmit={data => submit(data)}
    successUrl={`/v4/o/${o}/admin/tenants/${tenant.id}/users`}
    render={(props) => (
      <Form
        {...props}
        title={I18n.t('v4/tenant.members.add_admin')}
        tenant={tenant}
        o={o}
      />
    )}
  />
}

New.defaultProps = {
  tenant: {}
}

New.propTypes = {
  model: PropTypes.object,
}

export default New
