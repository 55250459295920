import React, { Component } from 'react'
import PropTypes from 'prop-types'


import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import Table from '@ui/Table'
import { partNumberShape } from '@models/partNumber'

import DeleteDialog from '../../ui/DeleteDialog'

import styles from './styles'

class Index extends Component {
  constructor (props) {
    super(props)

    this.state = {
      isDialogOpen: false,
      id: null
    }

    this.closeDialog = this.closeDialog.bind(this)
  }

  closeDialog () {
    this.setState({ isDialogOpen: false })
  }

  render () {
    const { model, classes, o } = this.props
    const { isDialogOpen, id } = this.state

    return (
      <Paper className={classes.part_number_registries} elevation={0}>
        <header className={classes.part_number_registries__header}>
          <Typography component='h2' variant='h5'>
            Part Numbers
          </Typography>
        </header>
        <Button
          variant='outlined'
          color='primary'
          className={classes.part_number_registries__newPart}
          href={`/v4/o/${o}/admin/part_number_registries/new`}
        >
          {I18n.t('v4/part_number.index.create')}
        </Button>

        <Table
          tableKey='admin-partNumberRegistries-index'
          components={{
            Container: props => <div>{props.children}</div>,
          }}
          columns={[
            { title: 'Part Number', field: 'part_number' },
            { title: 'Part Name', field: 'part_name' },
            { title: I18n.t('v4/part_number.index.connectivity_row'), field: 'hardware_integration_product' }
          ]}
          data={model}
          actions={[
            rowData => ({
              icon: 'create',
              iconProps: { 'data-testid': `edit-${rowData['id']}` },
              tooltip: I18n.t('v4/part_number.index.tooltip_edit'),
              onClick: (event, rowData) => {
                Turbolinks.visit(`/v4/o/${o}/admin/part_number_registries/${rowData['id']}/edit`)
              }
            }),
            rowData => ({
              icon: 'delete',
              iconProps: { 'data-testid': `delete-${rowData['id']}` },
              tooltip: I18n.t('v4/part_number.index.tooltip_remove'),
              onClick: (event, rowData) => {
                this.setState({ isDialogOpen: true, id: rowData['id'] })
              }
            }),
            rowData => ({
              icon: 'remove_red_eye',
              iconProps: { 'data-testid': `show-${rowData['id']}` },
              tooltip: I18n.t('v4/part_number.index.tooltip_view'),
              onClick: (event, rowData) => {
                Turbolinks.visit(`/v4/o/${o}/admin/part_number_registries/${rowData['id']}`)
              }
            })
          ]}
          options={{
            showTitle: false
          }}
        />

        <DeleteDialog
          dialogTitle={I18n.t('v4/part_number.dialog.title')}
          dialogText={I18n.t('v4/part_number.dialog.text')}
          successUrl={`/v4/o/${o}/admin/part_number_registries`}
          deleteUrl={`/v4/o/${o}/admin/part_number_registries/${id}.json`}
          onCancel={this.closeDialog}
          onClose={this.closeDialog}
          open={isDialogOpen}
        />
      </Paper>
    )
  }
}

Index.propTypes = {
  model: PropTypes.arrayOf(partNumberShape)
}

export default withStyles(styles)(Index)
