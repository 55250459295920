import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

import styles from '@styles/showPage'

export class Show extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const {
      classes,
      model,
      o
    } = this.props

    const custom_messages = {
      'en': model.custom_messages['en'] || '',
      'es': model.custom_messages['es-MX'] || '',
      'pt': model.custom_messages['pt-BR'] || ''
    }

    return (
      <Paper
        className={classes.showPage__wrapper}
        elevation={0}
      >
        <header className={classes.showPage__header}>
          <Typography component='h2' variant='h5'>
            {I18n.t('v4/alerts.title.show')}
          </Typography>
        </header>

        <main className={classes.showPage__values}>
          <div className={classes.showPage__value}>
            <Typography
              color='textSecondary'
              component='h6'
              gutterBottom
              variant='h6'
            >
              {I18n.t('v4/alerts.alerts.name')}
            </Typography>

            <Typography paragraph variant='subtitle1' data-testid='name'>
              {model.name}
            </Typography>
          </div>

          <div className={classes.showPage__value}>
            <Typography
              color='textSecondary'
              component='h6'
              gutterBottom
              variant='h6'
            >
              {I18n.t('v4/alerts.alerts.active')}
            </Typography>

            <Typography variant='subtitle1' paragraph data-testid='active'>
              {I18n.t(String(!!model.active))}
            </Typography>
          </div>
          <div className={classes.showPage__value}>
            <Typography
              color='textSecondary'
              component='h6'
              gutterBottom
              variant='h6'
            >
              {I18n.t('v4/alerts.alerts.category')}
            </Typography>

            <Typography variant='subtitle1' paragraph data-testid='category'>
              {model.category}
            </Typography>
          </div>

          <div className={classes.showPage__value}>
            <Typography
              color='textSecondary'
              component='h6'
              gutterBottom
              variant='h6'
            >
              {I18n.t('v4/alerts.alerts.notification_interval')}
            </Typography>

            <Typography paragraph variant='subtitle1' data-testid='notification_interval'>
              {model.notification_interval}
            </Typography>
          </div>

          <div className={classes.showPage__value}>
            <Typography
              color='textSecondary'
              component='h6'
              gutterBottom
              variant='h6'
            >
              {I18n.t('v4/alerts.alerts.custom_message_en')}
            </Typography>

            <Typography paragraph variant='subtitle1' data-testid='custom_message_en'>
              { custom_messages.en }
            </Typography>
          </div>

          <div className={classes.showPage__value}>
            <Typography
              color='textSecondary'
              component='h6'
              gutterBottom
              variant='h6'
            >
              {I18n.t('v4/alerts.alerts.custom_message_es')}
            </Typography>

            <Typography paragraph variant='subtitle1' data-testid='custom_message_es'>
              { custom_messages.es }
            </Typography>
          </div>

          <div className={classes.showPage__value}>
            <Typography
              color='textSecondary'
              component='h6'
              gutterBottom
              variant='h6'
            >
              {I18n.t('v4/alerts.alerts.custom_message_pt')}
            </Typography>

            <Typography paragraph variant='subtitle1' data-testid='custom_message_pt'>
              { custom_messages.pt }
            </Typography>
          </div>

          
        </main>

        <footer className={classes.showPage__actions}>
          <Button
            className={classes.showPage__action}
            color='primary'
            variant='contained'
            href={`/v4/o/${o}/admin/organizations/${model.organization_id}/alerts/${model.id}/edit`}
          >
            {I18n.t('actions.edit')}
          </Button>

          <Button
            className={classes.showPage__action}
            color='secondary'
            href={`/v4/o/${o}/admin/organizations/${model.organization_id}/alerts`}
          >
            {I18n.t('actions.back')}
          </Button>
        </footer>
      </Paper>
    )
  }
}

Show.propTypes = {
  model: PropTypes.object
}

export default withStyles(styles)(Show)
