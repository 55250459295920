import React from 'react'

import { withMenusStatus, MENUS } from '@contexts/MenusStatusProvider'
import { withHttpService } from '@contexts/HttpServiceProvider'
import { withErrorHandler } from '@contexts/ErrorHandlerProvider'
import { withRouter } from '@contexts/RouterProvider'

import { deserializeData, serializeData } from '@models/weather/forecast'

import Loading from '@ui/LoadingComponent'
import ProductsWrapper from '../../productsFloatingMenu/ProductsWrapper'
import Header from './Header'
import Body from './Body'
import Footer from './Footer'
import EmptyData from './EmptyData'
import SetupNewForecastPoint from './SetupNewForecastPoint/Index'
import ExpiredProductCard from '../ExpiredProductCard'
import {sendAmplitudeEvent} from '@utils/amplitudeEvent'
import {IntercomAPI} from '@utils/IntercomAPI'


class ForecastProduct extends React.Component {
  state = {
    anchorEl: null,
    cards: [],
    forecastList: [],
    loading: false,
    resume: {},
    selectedForecast: {}
  }

  handleForecastChange = (forecast) => {
    const { forecastList } = this.state
    const { cards, resume, selectedForecast } = serializeData(forecastList, forecast)
    const { menusStatus: { setMenuData } } = this.props

    if (_.isEmpty(selectedForecast.data)) {
      setMenuData(MENUS.weatherForecastProduct, 'onFlow', false)
      setMenuData(MENUS.weatherForecastProduct, 'onMapFlow', false)
      setMenuData(MENUS.weatherForecastProduct, 'onSearchFlow', false)
      setMenuData(MENUS.weatherForecastProduct, 'step', 0)
      setMenuData(MENUS.weatherForecastProduct, 'data', {})
    }

    this.setState({
      cards,
      resume,
      selectedForecast,
      anchorEl: null
    })
  }

  handleCloseList = () => {
    this.setState({ anchorEl: null })
  }

  handleToggle = (event) => {
    this.setState({ anchorEl: event.currentTarget })
    sendAmplitudeEvent('Atmos Card - Used area selection')
    IntercomAPI('trackEvent', 'Atmos Card - Used area selection')
  }

  componentDidMount() {
    this.fetchWeatherForecastData()
  }

  fetchWeatherForecastData = async () => {
    const {
      httpService: { apiRequest },
      errorHandler,
      menusStatus: { menus, setMenuData }
    } = this.props

    this.setState({ loading: true })

    try {
      const { forecastPoint } = menus[MENUS.weatherForecastProduct]
      const { data } = await apiRequest('forecasts/daily')
      const forecastList = deserializeData(data.forecast_points || [])

      const { cards, resume, selectedForecast } = serializeData(forecastList, forecastPoint)
      !_.isEmpty(forecastPoint) && setMenuData(MENUS.weatherForecastProduct, 'forecastPoint', {})

      this.setState({
        cards,
        forecastList,
        resume,
        selectedForecast,
        loading: false
      })
    }
    catch (error) {
      errorHandler(error)
      this.setState({ loading: false })
      !_.isEmpty(forecastPoint) && setMenuData(MENUS.weatherForecastProduct, 'forecastPoint', {})
    }
  }

  handleUpdate = async (updatedForecast) => {
    const { forecastList } = this.state

    this.setState({
      forecastList: forecastList.map(forecast => {
        if (forecast.id === updatedForecast.data.id) return updatedForecast.data

        return forecast
      })
    }, () => this.handleForecastChange(updatedForecast.data))
  }

  handleClose = () => {
    const { menusStatus: { menus, toggleMenu, setMenuData } } = this.props
    const { onFlow, onMapFlow } = menus[MENUS.weatherForecastProduct]

    toggleMenu(MENUS.weatherForecastProduct, false)
    onFlow && setMenuData(MENUS.weatherForecastProduct, 'onFlow', false)
    onMapFlow && setMenuData(MENUS.weatherForecastProduct, 'onMapFlow', false)
    sendAmplitudeEvent('Atmos Card - Used Close Button')
    IntercomAPI('trackEvent', 'Atmos Card - Used Close Button')
  }

  render() {
    const {
      addPin,
      menusStatus: { menus, toggleMenu },
      router
    } = this.props
    const { open, data } = menus[MENUS.weatherForecastProduct]
    const { anchorEl, cards, forecastList, loading, resume, selectedForecast } = this.state
    const { anchor } = menus[MENUS.mapProductsMenu]

    return (
      <ProductsWrapper
        open={open}
        onClose={this.handleClose}
        anchor={anchor}
      >
        {
          loading
            ? <Loading />
            : <>
              <Header
                forecastPoints={forecastList || []}
                handleForecastChange={this.handleForecastChange}
                selectedForecast={selectedForecast}
                handleToggle={this.handleToggle}
                handleClose={this.handleCloseList}
                handleCloseMenu={this.handleClose}
                anchor={anchorEl}
              />
              {
                !!selectedForecast.set_up
                  ? _.isEmpty(selectedForecast.data)
                    ? <EmptyData />
                    : <>
                      <Body
                        cards={cards}
                      />
                      <Footer
                        resume={resume}
                      />
                    </>
                  :
                  <SetupNewForecastPoint
                    addPin={addPin}
                    selectedForecast={selectedForecast}
                    updateForecastList={this.handleUpdate}
                  />

              }
            </>
        }

      </ProductsWrapper>
    )
  }
}

export default
  withRouter(
    withHttpService(
      withMenusStatus(
        withErrorHandler(
          ForecastProduct
        )
      )
    )
  )
