import httpService from '@services/httpService'

const headers = [
  'label', 'path'
]

export const getProductsHeaders = () =>
  headers.map(head => ({
    title: I18n.t(`activerecord.attributes.v4/product.${head}`),
    field: head
  }))

export const paramsTableHeaders = [
    { title: I18n.t('activerecord.attributes.v4/product.param.name'),  field: 'name' },
    { title: I18n.t('activerecord.attributes.v4/product.param.label'), field: 'label' },
    { title: I18n.t('activerecord.attributes.v4/product.param.data'),  field: 'data' }
  ]

export const getOrganizationProductsHeaders = () =>
  headers.map(head => ({
    title: I18n.t(`activerecord.attributes.v4/organization_products.${head}`),
    field: head
  }))

export const validate = (values) => {
  const requiredFields = ['label', 'path']
  const errors = {}

  requiredFields.forEach(field => {
    if (!values[field]) errors[field] = I18n.t('validations.required')
  })

  return errors
}

export const fields = [
  'label', 'path', 'kind', 'params'
]

export const acceptedMimeTypes = [
  'image/jpeg',
  'image/png'
]

export const handleProductSubmit = async ({ data, url, method, successUrl, handleError }) => {
  const formFields = ['label', 'path', 'icon', 'kind', 'params']
  const formData = new FormData();

  formFields.forEach(field => {
    if (data[field]) {
      formData.append(`product[${field}]`, field === 'params' ? JSON.stringify(data[field]) : data[field])
    }
  })

  try {
    const { data } = await httpService[method](url, formData)
    Turbolinks.visit(successUrl || `${url}/${data.id}`)
  }
  catch (error) {
    handleError(error)
  }
}
