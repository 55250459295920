import React, { useState, useEffect } from "react";
import { Paper, Typography } from "@material-ui/core";
import Card from "./NotificationCard";
import httpService from "@services/httpService";
import dialogs from "./dialogs.js";

function NotificationsCards({
  notificationType,
  priority,
  id,
  status,
  created_at,
  updated_at,
}) {
  const dialogHandler = (action) => {
    const { component, title, subtitle } = dialogs(dialog, setDialog)[action];

    if (component) {
      setDialog({
        ...dialog,
        selectedAction: action,
        isOpen: true,
        component,
        subtitle,
        title,
      });
    }
  };

  const [dialog, setDialog] = useState({
    isOpen: false,
    title: "",
    component: "",
    subtitle: "",
    selectedAction: "",
  });

  const notificationAPIUrl =
    `${process.env.NOTIFICATION_SERVICE_URL}/api/v1/notifications`;

  const markAsRead = async (notificationID) => {
    try {
      await httpService.put(`${notificationAPIUrl}/${notificationID}`, {
        status: "viewed",
      });
    } catch (error) {
      console.error(error);
    }
  };

  const sentNotificationDataToAmplitude = (eventName, notificationType) => {
    const isNotificationNew = status === "newly";
    const AmplitudeAPI = window.amplitude;

    AmplitudeAPI.getInstance().logEvent(eventName, {
      type_of_notification: notificationType,
      with_new_tag: isNotificationNew,
    });
  };

  const depthDelayedFunction = () => {
    dialogHandler("depthDelayed");
    markAsRead(id);
    sentNotificationDataToAmplitude(
      "Notification - Clicked Notification main",
      "depthDelayed"
    );
  };

  const advancePhenologicalStage = () => {
    dialogHandler("advanceStage")
    markAsRead(id);
    sentNotificationDataToAmplitude(
      "Notification - Clicked Notification main",
      "advancePhenological"
    );
  };

  const registerCropEventStage = () => {
    dialogHandler("registerCrop")
    markAsRead(id);
    sentNotificationDataToAmplitude(
      "Notification - Clicked Notification main",
      "registerCropEvent"
    );
  };

  switch (notificationType) {
    case "delayed_irrigation_depth":
      return (
        <Card
          title={I18n.t("notifications_central.depth_delayed_title")}
          subtitle={I18n.t("notifications_central.depth_delayed_subtitle")}
          type={I18n.t("notifications_central.important_tag")}
          onClick={() => depthDelayedFunction()}
          priority={priority}
          status={status}
          created_at={created_at}
          updated_at={updated_at}
          dialog={dialog}
          setDialog={setDialog}
        />
      );

    case "advance_phenological_stage":
      return (
        <Card
          title={I18n.t("notifications_central.advance-phenological-title")}
          subtitle={I18n.t(
            "notifications_central.advance-phenological-subtitle"
          )}
          type={I18n.t("notifications_central.important_tag")}
          onClick={() => advancePhenologicalStage()}
          priority={priority}
          status={status}
          created_at={created_at}
          updated_at={updated_at}
          dialog={dialog}
          setDialog={setDialog}
        />
      );

    case "register_crop_event":
      return (
        <>
          <Card
            title={I18n.t("notifications_central.register-crop-event-title")}
            subtitle={I18n.t(
              "notifications_central.register-crop-event-subtitle"
            )}
            type={I18n.t("notifications_central.important_tag")}
            onClick={() => registerCropEventStage()}
            priority={priority}
            status={status}
            created_at={created_at}
            updted_at={updated_at}
            dialog={dialog}
            setDialog={setDialog}
          />
        </>
      );
    default:
      return (
        <Paper>
          <Typography variant="body1">
            {I18n.t("notifications_central.no_notifications")}
          </Typography>
        </Paper>
      );
  }
}

export default NotificationsCards;
