import React from 'react'

import { Grid, Paper, Typography, withStyles } from '@material-ui/core'

import CardWithoutDepth from './CardWithoutDepth'
import CardWithDepth from './CardWithDepth'
import BatteryStatus from './BatteryStatus'
import DateAndTime from './DateAndTime'
import TelemetryButton from './TelemetryButton'
import Offline from './Offline'
import Loading from '@ui/LoadingComponent'
import Hydrometer from '@components/v4/maps/infoMenu/Hydrometer'

const Card = ({ classes, data, fetchOfflineStatus, loadingOffline, handleSubmitReadingEstimate }) =>
  <Grid item xs={12} sm md={6} lg={4} className={classes.Grid_item}>
    <Paper elevation={4} className={classes.Paper_white}>
      <Grid container spacing={16}>
        <Grid item xs={12}>
          <Typography variant='body2'>
            {data.name}
          </Typography>
        </Grid>

        {
          !data.equipment_status.online
            ? loadingOffline
              ? <div className={classes.Loading}>
                <Loading />
              </div>
              : <Offline
                dateAndTime={data.dateAndTime}
                installed={data.equipment_status.installed}
                fetchOfflineStatus={fetchOfflineStatus}
                data={data}
              />
            : !_.isEmpty(data.hydrometer)
              ? (
                <Hydrometer
                  equipmentMenuData={data.hydrometer.equipmentMenuData}
                  selectedEquipment={data.hydrometer.selectedEquipment}
                  handleSubmitReadingEstimate={handleSubmitReadingEstimate}
                />
              )
              : <>
                {
                  !_.isEmpty(data.equipmentsWithoutDepth) &&
                  data.equipmentsWithoutDepth.map(equipmentData =>
                    equipmentData.name !== 'bv1' &&
                    <CardWithoutDepth
                      hasWindDirection={data.hasWindDirection}
                      hasWindSpeed={data.hasWindSpeed}
                      equipmentData={equipmentData}
                      key={equipmentData.name}
                    />
                  )
                }
                {
                  !_.isEmpty(data.equipmentsWithDepth) &&
                  <CardWithDepth data={data} />
                }
                {
                  !_.isEmpty(data.batteryInfo) &&
                  <BatteryStatus data={data.batteryInfo} />
                }
                <DateAndTime dateAndTime={data.dateAndTime} />
                <TelemetryButton equipmentId={data.equipment_id} />
              </>
        }
      </Grid>
    </Paper>
  </Grid>

const styles = theme => ({
  Paper_white: {
    background: theme.palette.white,
    width: 360,
    padding: theme.spacing.unit * 2
  },
  Grid_item: {
    display: 'flex',
    height: 'fit-content',
    justifyContent: 'center'
  },
  Loading: {
    width: '100%'
  }
})

Card.defaultProps = {
  data: {}
}

export default withStyles(styles)(Card)
