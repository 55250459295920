import React from 'react'
import PropTypes from 'prop-types'

import { Field as FinalField } from 'react-final-form'

import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'

import styles from '@theme/styles/forms'

import Fields from './Fields'


const FieldWrapper = ({ gridItem, gridXs, withWrapper, allowEmpty, ...props }) => {
  const fieldProps = {
    ...props,
  }

  if (allowEmpty) {
    fieldProps.parse = value => value == null ? '' : value
    fieldProps.allowEmpty = allowEmpty
  }

  if (gridItem) {
    return (
      <GridItem
        component={
          withWrapper
            ? <FieldWithWrapper {...fieldProps} />
            : <Field {...fieldProps} />
        }
        xs={gridXs}
      />
    )
  }

  return withWrapper
    ? <FieldWithWrapper {...fieldProps} />
    : <Field {...fieldProps} />
}

const GridItem = ({ component, xs }) =>
  <Grid item xs={xs}>
    {component}
  </Grid>

const FieldWithWrapper = ({ classes, wrapperClassName, component, ...props }) => (
  <div className={wrapperClassName || classes.form__field__wrapper}>
    <Field classes={classes} {...props} />
  </div>
)

const Field = ({
  classes,
  type,
  format,
  ...props
}) => (
  <FinalField
    format={format}
    component={Fields[type]}
    classes={classes}
    allowNull
    parse={value => (value === '' ? null : value)}
    {...props}
  />
)

FieldWrapper.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(['text', 'select', 'controlledSelect', 'multiSelect', 'telephone', 'number', 'date', 'selectWithVisitButton']),
  withWrapper: PropTypes.bool,
  label: PropTypes.string.isRequired,
  className: PropTypes.string,
  wrapperClassName: PropTypes.string,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  InputLabelProps: PropTypes.object,
  inputProps: PropTypes.object,
  fullWidth: PropTypes.bool,
  variant: PropTypes.string,
  margin: PropTypes.string,
  data: PropTypes.array,
  format: PropTypes.func,
  gridItem: PropTypes.bool,
  gridXs: PropTypes.number,
  allowEmpty: PropTypes.bool
}

FieldWrapper.defaultProps = {
  type: 'text',
  withWrapper: true,
  required: false,
  InputLabelProps: { shrink: true },
  fullWidth: true,
  variant: 'outlined',
  margin: 'normal',
  gridItem: false,
  gridXs: 6,
  allowEmpty: false
}

export default withStyles(styles)(FieldWrapper)
