import React from 'react'
import PropTypes from 'prop-types'

import { MobileStepper } from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'

const Steppers = ({ classes, steps, children, currentStep }) =>
  <>
    {
      !_.isEmpty(children) &&
      <div className={classes.stepContent}>
        {children[currentStep]}
      </div>
    }
    <MobileStepper
      variant='dots'
      steps={steps.length}
      position='static'
      activeStep={currentStep}
      className={classes.dots}
    />
  </>

Steppers.propTypes = {
  steps: PropTypes.array,
  children: PropTypes.array,
  currentStep: PropTypes.number
}

Steppers.defaultProps = {
  steps: {},
  children: [],
  currentStep: 0
}

const styles = (theme) => ({
  dots: {
    justifyContent: 'center',
    flexGrow: 1
  },
  stepContent: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  }
})

export default withStyles(styles)(Steppers)
