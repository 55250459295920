import React, { Component } from 'react'
import httpService from '../services/httpService'
import PropTypes from 'prop-types'

export const validate = values => {
  const errors = {}
  const requiredFields = ['name', 'start_date', 'end_date']
  const startDate = new Date(values.start_date)
  const endDate = new Date(values.end_date)

  requiredFields.forEach(field => {
    if (!values[field]) errors[field] = I18n.t('validations.required')
  })

  if(startDate && endDate && startDate > endDate) {
    errors.start_date = I18n.t('validations.start_date')
    errors.end_date = I18n.t('validations.end_date')
  }

  return errors
}

export const fields = [
  'season_name', 'start_date', 'end_date', 'areas'
]

export const dashboard_fields = [
  'area_name',
  'culture_name',
  'days_after',
  'current_stage',
  'stage_start_date',
  'stage_finishing_day',
  'status'
]

const getData = (rowData, field) => {
  if(field === 'days_after' && rowData['planting_date']) {
    return <span>{`${rowData['planting_date']} (${rowData['days_since_started']})`}</span>
  }

  if(field === 'stage_start_date' && rowData['stage_started_at']) {
    return <span>{`${rowData['stage_started_at']} (${rowData['days_on_current_stage']})`}</span>
  }

  return <span>{rowData[field]}</span>
}

export const getDashboardSeasonsHeaders = () =>
  dashboard_fields.map((field, i) => ({
    title: I18n.t(`v4/seasons_dashboard.tabs.${field}`),
    render: rowData => (getData(rowData, field))
  }))

export const getSeasonsHeaders = () =>
  fields.map(field => ({
    title: I18n.t(`v4/seasons.tabs.${field}`),
    field
  }))

export const today = () => new Date()

export const seasonActionsHandler = (id, params, o) => () => {
  const actionUrl = `/v4/o/${o}/seasons/${id}`

  httpService
    .patch(actionUrl, params)
    .then(() =>
      Turbolinks.visit(`/v4/o/${o}/seasons`)
    )
    .catch(() =>
      console.error('boom!', error)
    )
}
