import React from 'react'
import Table from '@ui/Table'
import { paramsTableHeaders } from '@models/admin/sensor'

const ParamsTable = ({ params, updateParams }) => {
  const [state, setState] = React.useState({
    columns: paramsTableHeaders,
    data: params,
  });

  const handleUpdate = (data) => {
    updateParams(data)

    setState({
      ...state,
      data
    })
  }

  return (
    <Table
      tableKey='admin-sensors-paramsTable'
      title={I18n.t('activerecord.attributes.v4/sensor.params')}
      columns={state.columns}
      data={state.data}
      options={{
        search: false,
        paging: false
      }}
      editable={{
        onRowAdd: newData =>
          new Promise(resolve => {
            const data = [...state.data, newData]
            handleUpdate(data)

            resolve()
          }),
        onRowUpdate: (newData, oldData) =>
          new Promise(resolve => {
            const data = [...state.data]
            data[data.indexOf(oldData)] = newData
            handleUpdate(data)

            resolve()
          }),
        onRowDelete: oldData =>
          new Promise(resolve => {
            const data = [...state.data];
            data.splice(data.indexOf(oldData), 1)
            handleUpdate(data)

            resolve()
          }),
      }}
    />
  );
}

ParamsTable.defaultProps = {
  params: []
}

export default ParamsTable
