import React, { createContext, useContext, useCallback } from 'react'

import Dialog from '@ui/ConfirmDialog/Dialog'
import { withStyles } from '@material-ui/core/styles'

const ConfirmDialogContext = createContext()

export const useConfirmDialog = () => useContext(ConfirmDialogContext)

const ConfirmDialogProvider = ({ classes, children }) => {
  const [confirmDialogState, setDialogState] = React.useState({
    open: false,
    title: undefined,
    content: undefined,
    confirmText: undefined,
    cancelText: undefined,
    handleConfirm: () => { },
    handleClose: () => { }
  })

  const closeDialog = () => {
    setDialogState({
      ...confirmDialogState,
      open: false
    })
  }

  const handleDialogCancel = () => {
    confirmDialogState.handleClose()

    closeDialog()
  }

  const handleDialogConfirm = () => {
    confirmDialogState.handleConfirm()

    closeDialog()
  }

  const confirmDialog = (onConfirm, options) => {
    setDialogState({
      ...confirmDialogState,
      handleConfirm: onConfirm || confirmDialogState.handleConfirm,
      ...options,
      open: true
    })
  }

  window.confirmDialog = confirmDialog

  const value = useCallback(confirmDialog, [])

  return <>
    <ConfirmDialogContext.Provider value={value}>
      {children}
    </ConfirmDialogContext.Provider>

    <Dialog
      {...confirmDialogState}
      classes={classes}
      onConfirm={handleDialogConfirm}
      onCancel={handleDialogCancel}
    />
  </>
}

export const withConfirmDialog = component => props =>
  React.createElement(component, { ...props, confirmDialog: useConfirmDialog() })

const styles = () => ({
  Dialog__paper: {
    maxWidth: '620px'
  }
})

export default withStyles(styles)(ConfirmDialogProvider)
