const cardWidth = 260

export default (theme) => ({
  map__forecast: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  forecast__header: {
    padding: 5,
    textAlign: 'center'
  },
  forecast: {
    minWidth: 100,
    cursor: 'pointer',
    margin: 10,
    width: cardWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  forecast__info__wrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  forecastOpen: {
    width: cardWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  forecastClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: theme.spacing.unit * 14,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing.unit * 14,
    },
  },
  forecast__title: {
    fontSize: '15px',
    color: 'rgba(51, 51, 51, 0.87)',
    fontWeight: 500,
    textTransform: 'uppercase'
  },
  forecast__subtitle: {
    color: 'rgba(101, 101, 101, 0.87)',
    fontSize: '13px'
  },
  forecast__icon: {
    padding: 0,
    textAlign: 'center'
  },
  forecast__weather: {
    padding: '0 10px'
  },
  forecast_weather_wrapper: {
    display: 'flex',
    justifyContent: 'space-between'
  },
  forecast_weather_info: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '13px',
    justifyContent: 'center'
  },
  forecast_weather_info_very_low: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '13px',
    justifyContent: 'center',
    color: theme.palette.forecast.gray
  },
  forecast_weather_info_low: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '13px',
    justifyContent: 'center',
    color: theme.palette.forecast.blue
  },
  forecast_weather_info_moderate: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '13px',
    justifyContent: 'center',
    color: theme.palette.forecast.blue
  },
  forecast_weather_info_high: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '13px',
    justifyContent: 'center',
    color: theme.palette.forecast.blue
  },
  forecast_weather_info_very_high: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '13px',
    justifyContent: 'center',
    color: theme.palette.forecast.darkBlue
  },
  forecast_weather_rain_low: {
    fontSize: '12px',
    padding: 5,
    textAlign: 'center',
    display: 'block',
    textTransform: 'uppercase',
    fontWeight: 500
  },
  forecast_weather_rain_very_low: {
    fontSize: '12px',
    padding: 5,
    textAlign: 'center',
    display: 'block',
    textTransform: 'uppercase',
    fontWeight: 500
  },
  forecast_weather_rain_moderate: {
    fontSize: '12px',
    padding: 5,
    color: '#FFF',
    background: '#9ECAE1',
    textAlign: 'center',
    display: 'block',
    textTransform: 'uppercase',
    fontWeight: 500
  },
  forecast_weather_rain_high: {
    fontSize: '12px',
    padding: 5,
    color: '#FFF',
    background: '#4292C6',
    textAlign: 'center',
    display: 'block',
    textTransform: 'uppercase',
    fontWeight: 500
  },
  forecast_weather_rain_very_high: {
    fontSize: '12px',
    padding: 5,
    color: '#FFF',
    background: '#08519C',
    textAlign: 'center',
    display: 'block',
    textTransform: 'uppercase',
    fontWeight: 500
  },
  forecast_weather_rain_footer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  forecast_weather_rain_chance: {
    fontSize: 25
  },
  forecast_weather_resume: {
    width: 180,
    margin: 10,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    textAlign: 'center'
  },
  forecast__resume__h2: {
    color: 'rgba(51, 51, 51, 0.87)',
    fontSize: 15,
    fontWeight: 500,
  },
  forecast__resume__h3: {
    color: 'rgba(101, 101, 101, 0.87)',
    fontSize: 13,
    marginBottom: 10
  },
  arrow__up: {
    transform: 'rotate(270deg)',
    color: '#ff1744'
  },
  arrow__down: {
    transform: 'rotate(90deg)',
    color: '#2196f3'
  },
  wind: {
    fontSize: 25,
    color: '#7b7b7b'
  },
  raindrop: {
    fontSize: 20,
    color: '#1769aa'
  },
  Dashboard__data: {
    minHeight: 276,
    padding: '10px',
    width: 'fit-content'
  },
  no__data: {
    marginTop: theme.spacing.unit * 4,
    flexWrap: 'wrap',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  forecast_points__form: {
    padding: '15px'
  },
  Forecast_wrapper: {
    display: 'flex'
  },
  Forecast_mobile: {
    maxWidth: '300px',
    overflowX: 'scroll'
  }
})
