import React, { Component } from 'react'
import PropTypes from 'prop-types'

import MembersIcon from '@material-ui/icons/Group'
import TermsIcon from '@material-ui/icons/Assignment'
import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import Table from '@ui/Table'

import { getTenantHeaders } from '@models/admin/tenant'
import DeleteDialog from '@ui/DeleteDialog'
import styles from '@styles/indexPage'

class Index extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isDialogOpen: false,
      id: null
    }

    this.closeDialog = this.closeDialog.bind(this)
  }

  closeDialog() {
    this.setState({ isDialogOpen: false })
  }

  render() {
    const { model, classes, is_admin, o } = this.props
    const { isDialogOpen, id } = this.state

    return (
      <Paper className={classes.page__wrapper} elevation={0}>
        <header className={classes.page__header}>
          <Typography component='h2' variant='h5'>
            {I18n.t('activerecord.models.v4/tenant.other')}
          </Typography>
        </header>
        {
          is_admin &&
          <Button
            variant='outlined'
            color='primary'
            className={classes.page__newResourceButton}
            href={`/v4/o/${o}/admin/tenants/new`}
          >
            {I18n.t('actions.create')} {I18n.t('activerecord.models.v4/tenant.one')}
          </Button>
        }

        <Table
          tableKey='admin-tenant-index'
          components={{
            Container: props => <div>{props.children}</div>,
          }}
          columns={getTenantHeaders()}
          data={model}
          actions={[
            rowData => ({
              icon: MembersIcon,
              iconProps: { 'data-testid': `members-of-${rowData.id}` },
              tooltip: I18n.t('v4/users.members.other'),
              onClick: (event, rowData) => {
                Turbolinks.visit(`/v4/o/${o}/admin/tenants/${rowData.id}/users`)
              }
            }),
            rowData => ({
              icon: TermsIcon,
              iconProps: { 'data-testid': `terms-of-${rowData.id}` },
              tooltip: I18n.t('activerecord.models.v4/terms.other'),
              onClick: (event, rowData) => {
                Turbolinks.visit(`/v4/o/${o}/admin/tenants/${rowData.id}/terms`)
              }
            }),
            rowData => ({
              icon: 'create',
              iconProps: { 'data-testid': `edit-${rowData.id}` },
              tooltip: I18n.t('actions.edit'),
              onClick: (event, rowData) => {
                Turbolinks.visit(`/v4/o/${o}/admin/tenants/${rowData.id}/edit`)
              }
            }),
            rowData => ({
              icon: 'delete',
              disabled: !is_admin,
              iconProps: { 'data-testid': `delete-${rowData.id}` },
              tooltip: I18n.t('actions.remove'),
              onClick: (event, rowData) => {
                this.setState({ isDialogOpen: true, id: rowData.id })
              }
            })
          ]}
          options={{
            showTitle: false
          }}
        />

        <DeleteDialog
          dialogTitle={`${I18n.t('actions.remove')} ${I18n.t('activerecord.models.v4/tenant.one')}?`}
          dialogText={I18n.t('confirmation.remove')}
          successUrl={`/v4/o/${o}/admin/tenants`}
          deleteUrl={`/v4/o/${o}/admin/tenants/${id}.json`}
          onCancel={this.closeDialog}
          onClose={this.closeDialog}
          open={isDialogOpen}
        />
      </Paper>
    )
  }
}

Index.propTypes = {
  model: PropTypes.array
}

Index.defaultProps = {
  model: []
}

export default withStyles(styles)(Index)
