import React from 'react'
import DateFnsUtils from "@date-io/date-fns"
import { TimePicker, MuiPickersUtilsProvider } from "material-ui-pickers"
import { localeFormats, localeMap } from "@services/locale"
import { withStyles } from '@material-ui/core/styles'

const ControlledTimePicker = ({
  classes,
  initialValue,
  input,
  label,
  meta: { touched, error },
  InputProps,
  ...props
}) => {
  if (input.value === '') input.value = null

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[I18n.locale]}>
      <TimePicker
        keyboard
        error={error}
        helperText={error}
        clearable
        variant='outlined'
        label={label}
        {...input}
        {...props}
        InputProps={{
          ...InputProps,
          className: classes.withInfo,
        }}
        format={localeFormats.time[I18n.locale]}
        clearLabel={I18n.t('datepicker.clear')}
        cancelLabel={I18n.t('datepicker.cancel')}
        okLabel={I18n.t('datepicker.accept')}
        mask={localeFormats.mask[I18n.locale]}
      />
    </MuiPickersUtilsProvider>
  )
}

const styles = () => ({
  withInfo: {
    boxSizing: 'border-box'
  }
})

export default withStyles(styles)(ControlledTimePicker)
