import React from 'react'
import { Form as FinalForm } from 'react-final-form'

import { Typography, Paper, Button } from '@material-ui/core'
import { Field } from 'react-final-form'
import withStyles from '@material-ui/core/styles/withStyles'

import TextField from '@ui/Fields/TextField'
import Select from '@ui/Fields/Select'
import styles from '@theme/styles/forms'
import { RailsForm } from '@support/RailsForm'

export const New = () =>
  <RailsForm
    component={FinalForm}
    handleSubmit={() => { }}
    render={(props) => (
      <Form
        {...props}
        title={I18n.t('v4/organization.actions.add')}
        organizationKinds={[
          { id: 1, name: 'Kind 1' },
          { id: 2, name: 'Kind 2' }
        ]}
      />
    )}
  />

const Form = withStyles(styles)(({
  handleSubmit,
  classes,
  title,
  organizationKinds
}) => (
    <form className={classes.form} onSubmit={handleSubmit} data-testid='organization-form'>
      <Paper className={classes.form__wrapper} elevation={0}>
        <header className={classes.form__header}>
          <Typography component='h2' variant='h5'>
            {title}
          </Typography>
        </header>
        <main className={classes.form__fields}>
          <div className={classes.form__field__wrapper}>
            <Field
              component={TextField}
              className={classes.form__fieldWithError}
              margin='normal'
              fullWidth
              InputLabelProps={{ shrink: true }}
              variant='outlined'
              label={I18n.t('activerecord.attributes.v4/organization.name')}
              name='name'
              required
            />
          </div>
          <div className={classes.form__field__wrapper}>
            <Field
              component={Select}
              className={classes.form__fieldWithError}
              InputLabelProps={{ shrink: true, required: true }}
              label={I18n.t('activerecord.attributes.v4/organization.kind')}
              name='kind'
              margin='normal'
              fields={{ id: 'id', name: 'name' }}
              data={organizationKinds}
              fullWidth
              variant='outlined'
              required
            >
            </Field>
          </div>
        </main>
        <footer className={classes.form__actions}>
          <Button
            className={classes.form__action}
            color='primary'
            variant='contained'
          >
            {title}
          </Button>

          <Button
            className={classes.form__action}
            color='secondary'
          >
            {I18n.t('actions.back')}
          </Button>
        </footer>
      </Paper>
    </form>
  ))

export default New
