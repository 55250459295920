import React from 'react'

import CustomizedDotHandler from './customizedDotHandler'
import EventsMapLegend from './eventsMapLegend'
import EventsMapTooltip from './eventsMapTooltip'
import Card from '@material-ui/core/Card'

import {
  Brush,
  CartesianGrid,
  Line,
  ComposedChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis
} from 'recharts'

import { chartsFontFamily } from '@models/chart'
import { eventsTypes, getArrayMaxIndexMinus } from '@models/irrigation_managements/dashboard'

const EventsMap = ({ irrigationData, dateVisualization }) =>
  <Card>
    <ResponsiveContainer width='100%' height={200}>
      <ComposedChart
        data={irrigationData}
        syncId='irrigation'
        margin={{ top: 10, right: 30, left: 0, bottom: 0 }}
      >
        <XAxis
          fontFamily={chartsFontFamily}
          tick={{ fontSize: 15 }}
          dataKey={dateVisualization}
        />

        <YAxis
          fontFamily={chartsFontFamily}
          stroke='#00000000'
          type='number'
          tickCount={eventsTypes.length + 1}
        />

        <CartesianGrid vertical={false} stroke='#8884d8' />
        <Tooltip
          content={
            <EventsMapTooltip dateVisualization={dateVisualization} />
          }
        />
        {
          eventsTypes.map(
            event =>
              <Line
                activeDot={<CustomizedDotHandler event={event} color='#66A343'/>}
                type='monotone'
                key={event.name}
                dataKey={event.axisValue}
                name={event.name}
                stroke='#8884d8'
                fill='#8884d8'
                dot={
                  <CustomizedDotHandler event={event} />
                }
              />
          )
        }

        <Brush
          height={0.00001}
          width={0}
          startIndex={getArrayMaxIndexMinus(irrigationData, 7)}
          endIndex={getArrayMaxIndexMinus(irrigationData)}
        />
      </ComposedChart>
    </ResponsiveContainer>
    <EventsMapLegend />
  </Card>

export default EventsMap
