import React from 'react'

import { Grid, Typography, withStyles } from '@material-ui/core'

const EmptyData = ({ classes }) =>
  <div className={classes.EmptyData_wrapper}>
    <div className={classes.EmptyData_container}>
      <Typography variant='body1' className={classes.EmptyData_success}>
        {I18n.t('v4/forecast.empty.success')}
      </Typography>
      <Typography variant='body1' className={classes.EmptyData_text}>
        {I18n.t('v4/forecast.empty.loading')}
      </Typography>
      <Typography variant='body1' className={classes.EmptyData_text}>
        {I18n.t('v4/forecast.empty.available_soon')}
      </Typography>
    </div>
  </div>

const styles = (theme) => ({
  EmptyData_success: {
    fontWeight: 500,
    marginBottom: 5,
    color: theme.palette.gray.dark
  },
  EmptyData_text: {
    color: theme.palette.gray.dark
  },
  EmptyData_wrapper: {
    padding: `0 ${theme.spacing.unit + 2}px`,
    display: 'flex',
    flexWrap: 'wrap',
    justifyContent: 'center',
    alignItems: 'center',
    height: 'calc(100vh - 160px)'
  },
  EmptyData_container: {
    textAlign: 'center'
  }
})

export default withStyles(styles)(EmptyData)
