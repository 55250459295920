import React from 'react'

import { Button, Divider, Link, Typography } from '@material-ui/core'
import { Store as StoreIcon } from '@material-ui/icons'

import { useSessionData } from '@contexts/SessionDataProvider'
import { useRouter } from '@contexts/RouterProvider'

import { getHydricStatus, Icon } from '@models/area'
import IrrigationDialog from '../../../equipments/irrigations/Dialog'
import Intercom from './Intercom'
import ViewProduct from './ViewProduct'
import { parseDecimalToTime } from '@services/locale'

const hasProduct = (products, kind) =>
  products.some(product => product.kind === kind)

const availableProductKinds = [
  'alert',
  'irrigation_management',
  'satellite_imagery'
]

const ProductsContent = ({
  irrigationManagement,
  classes,
  alertsCount,
  areas,
  handleError,
  trialInfo,
  currentArea
}) => {
  const router = useRouter()
  const { products, currentOrganization } = useSessionData()
  const [irrigationDialogOpen, setIrrigationDialogOpen] = React.useState(false)

  const hasSomeAvailableProduct = availableProductKinds.some(
    productKind => hasProduct(products, productKind)
  )

  return hasSomeAvailableProduct
    ? <>
      {
        hasProduct(products, 'irrigation_management') &&
        <IrrigationProduct
          irrigation={irrigationManagement}
          classes={classes}
          router={router}
          openDialog={setIrrigationDialogOpen}
        />
      }

      {
        hasProduct(products, 'satellite_imagery') &&
        <ViewProduct currentArea={currentArea} />
      }

      {
        hasProduct(products, 'alert') &&
        <Alerts
          alertsCount={alertsCount}
          router={router}
          classes={classes}
        />
      }

      <IrrigationDialog
        dialogOpen={irrigationDialogOpen}
        organization_id={currentOrganization.id}
        handleCloseDialog={() => setIrrigationDialogOpen(false)}
        areas={areas}
        handleError={handleError}
      />
    </>
    : (
      trialInfo.isTrial
        ? <NoProductsMessage classes={classes} router={router} />
        : <Intercom />
    )
}

const NoProductsMessage = ({ router, classes }) =>
  <div className={classes.NoProductsMessage}>
    <Typography align='center' variant='subtitle2' paragraph>
      {I18n.t('v4/product.trial.renew_subscription')}
    </Typography>

    <Button
      className={classes.NoProductsMessage_Button}
      color='primary'
      variant='contained'
      onClick={() => router.visit('store')}
    >
      {I18n.t('v4/product.trial.action.renew')}
      <StoreIcon className={classes.NoProductsMessage_Icon} />
    </Button>
  </div >

const IrrigationProduct = ({ irrigation = {}, classes, router, openDialog }) => {
  if (_.isEmpty(irrigation)) return null

  const data =
    irrigation.data
      ? irrigation.data[1]
        ? irrigation.data[1] : irrigation.data[0]
      : {}

  const isPivot = irrigation.irrigation_system_type ? irrigation.irrigation_system_type === 'pivot' : false
  return (
    <div className={classes.Area_Wrapper}>
      <div className={classes.Area_Product_Wrapper_Title}>
        <div className={classes.Area_Icon} onClick={() => router.visit(`irrigation_managements/${irrigation.id}/dashboard`)}>
          <Icon variant='aqua' />
        </div>
        <div className={classes.Area_Product_Title}>
          <Typography variant='subtitle2' data-intercom-target='aqua-via-card' className={classes.Area_Product_Title__Info} onClick={() => router.visit(`irrigation_managements/${irrigation.id}/dashboard`)}>
            <Link color='inherit'>{I18n.t('v4/products.aqua')}</Link>
          </Typography>
          <Button
            color='secondary'
            variant='outlined'
            data-intercom-target='inform-irrigation-aqua'
            onClick={() => openDialog(true)}
            className={classes.Area_Product_Title__Info}
          >
            {I18n.t('activerecord.attributes.v4/area.menu.inform')}
          </Button>
        </div>
      </div>

      {
        !_.isEmpty(data) &&
        <>
          <div className={classes.Area_Infos}>
            <Typography variant='subtitle2' className={classes.Area_Info}>
              {getHydricStatus(data).title}
            </Typography>
            <Typography variant='subtitle2' className={classes.Area_Info}>
              {getHydricStatus(data).value}
            </Typography>
          </div>

          <div className={classes.Area_Infos}>
            <Typography variant='body1' className={classes.Area_Info}>
              {I18n.t('v4/irrigation_management.card_labels.di')}
            </Typography>
            <Typography variant='body1' className={classes.Area_Info}>
              {
                data.demanded_irrigation != null
                  ? `${_.round(data.demanded_irrigation, 2)} mm`
                  : '---'
              }
            </Typography>
          </div>

          {
            isPivot && <div className={classes.Area_Infos}>
              <Typography variant='body1' className={classes.Area_Info}>
                {I18n.t('v4/irrigation_system.pivot.pivot_percentage')}
              </Typography>

              <Typography variant='body1' className={classes.Area_Info}>
                {
                  data.final_speed_relay != null
                    ? `${_.round(data.final_speed_relay, 2)} %`
                    : '---'
                }
              </Typography>
            </div>
          }
          {
            !isPivot && <div className={classes.Area_Infos}>
              <Typography variant='body1' className={classes.Area_Info}>
                {I18n.t('v4/irrigation_system.pivot.irrigation_time')}
              </Typography>

              <Typography variant='body1' className={classes.Area_Info}>
                {
                  data.final_time != null
                    ? parseDecimalToTime(data.final_time)
                    : '---'
                }
              </Typography>
            </div>
          }
        </>
      }
      <Divider />
    </div>
  )
}

const Alerts = ({ alertsCount, classes, router }) =>
  <div className={classes.Area_Product_Wrapper_Title}>
    <div className={classes.Area_Icon}>
      <Icon
        variant={!!alertsCount ? 'alert' : 'noAlert'}
      />
    </div>

    <div className={classes.Area_Product_Title}>
      <Typography variant='subtitle2' className={classes.Area_Product_Title__Info}>
        {
          !!alertsCount
            ? `${alertsCount} ${I18n.t('activerecord.attributes.v4/area.menu.alert')}`
            : I18n.t('activerecord.attributes.v4/area.menu.no_alert')
        }
      </Typography>

      <Button
        color='secondary'
        variant='outlined'
        onClick={() => router.visit('alerts')}
        className={classes.Area_Product_Title__Info}
      >
        {I18n.t('activerecord.attributes.v4/area.menu.action')}
      </Button>
    </div>
  </div>

export default ProductsContent
