import * as localStorageService from '@services/localStorage'

const tableConfigurationsId = 'tablesConfigurations'

export const getInitialConfigurations = key => {
  const currentConfigurations = localStorageService.getLocalStorageItem(tableConfigurationsId) || {}

  return currentConfigurations[key] || {}
}

export const setTableConfiguration = (key, configuration) => {
  if (key !== 'weather-index') {
    const currentConfigurations = localStorageService.getLocalStorageItem(tableConfigurationsId) || {}

    const currentTableConfiguration = currentConfigurations[key] || {}

    localStorageService.setLocalStorageItem(tableConfigurationsId,
      {
        ...currentConfigurations,
        [key]: {
          ...currentTableConfiguration,
          ...configuration
        }
      }
    )
  }
}

export const getColumnsSorting = (sorting, columns) => {
  if (_.isEmpty(sorting)) {
    return columns
  }

  return columns.map((column, index) =>
    index === sorting.id
      ? {
        ...column,
        defaultSort: sorting.order
      }
      : column
  )
}
