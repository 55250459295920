import React from 'react'

import InfoRain from './InfoRain'
import RainHistory from './RainHistory'
import Form from './Form'

import { Form as FinalForm } from 'react-final-form'
import { RailsForm } from '@support/RailsForm'

import { withStyles } from '@material-ui/core'

const ManualEquipmentInfoMenu = ({
  classes,
  dateAndTime,
  equipmentId,
  handleSubmitManualEquipment,
  precipitationValue
}) =>
  <div className={classes.ManualEquipment_wrapper}>
    <InfoRain
      today={dateAndTime.today}
      precipitationValue={precipitationValue}
    />

    <RainHistory
      dateAndTime={dateAndTime}
      equipmentId={equipmentId}
    />

    <RailsForm
      component={FinalForm}
      initialValues={{ measured_at: new Date() }}
      render={
        (props) => (
          <Form
            handleSubmitManualEquipment={handleSubmitManualEquipment}
            {...props}
          />
        )}
    />
  </div>

const Styles = theme => ({
  ManualEquipment_wrapper: {
    margin: theme.spacing.unit
  }
})

export default withStyles(Styles)(ManualEquipmentInfoMenu)
