import React from 'react'
import PropTypes from 'prop-types'
import { Form as FinalForm } from 'react-final-form'
import moment from 'moment'

import { 
  dateToHours,
  deserializeValues, 
  convertDateAndTime 
} from '@models/equipment/irrigation'

import { RailsForm } from '@support/RailsForm'

import httpService from '@services/httpService'

import Form from './EditForm'

const Edit = ({ model: { area, irrigation_system, ...model }, handleError, o, organization_id }) => {
  const [loading, setLoading] = React.useState(false)
    
  return <RailsForm
    component={FinalForm}
    handleSubmit={data => handleSubmit(data, irrigation_system, handleError, o, setLoading)}
    initialValues={deserializeValues(model)}
    render={props => (
      <Form
        {...props}
        area={area}
        equipment={irrigation_system}
        organization_id={organization_id}
        o={o}
        loading={loading}
      />
    )}
  />
}

const handleSubmit = async (data, equipment, handleError, o, setLoading) => {
  setLoading(true)

  try {
    if (!!data.started_at) {
      data.started_at = convertDateAndTime(data.irrigation_date, data.started_at)
    }

    if (!!data.finished_at) {
      data.finished_at = convertDateAndTime(data.irrigation_date, data.finished_at)
    }

    const irrigation = {
      ...data,
      irrigation: data.unit === 'h' ? dateToHours(data.irrigation) : data.irrigation
    }

    await httpService.put(`/v4/o/${o}/irrigations/${data.id}`, {
      irrigation
    })

    Turbolinks.visit(`/v4/o/${o}/equipments/irrigation/${equipment.id}/irrigations`)
  } catch (err) {
    setLoading(false)

    handleError(err)
  }
}

Edit.propTypes = {
  equipment: PropTypes.object,
  areas: PropTypes.array
}

export default Edit
