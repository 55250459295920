import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import MaterialTable from 'material-table'

import { getUsersHeaders } from '@models/users'
import DeleteDialog from '../../ui/DeleteDialog'
import httpService from '@services/httpService'
import styles from '@styles/indexPage'

class Index extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isDialogOpen: false,
      id: null
    }

    this.closeDialog = this.closeDialog.bind(this)
  }

  closeDialog() {
    this.setState({ isDialogOpen: false })
  }

  handleDelete = async (organizationId) => {
    const { handleError, o } = this.props

    try {
      await httpService.get(`/v4/o/${o}/organizations/${organizationId}/users/${this.state.id}/remove.json`)

      Turbolinks.visit(`/v4/o/${o}/organizations/${organizationId}/users`)
    }
    catch (error) {
      handleError(error)
    }
  }

  render() {
    const { model, classes, organization, user_is_owner, has_write_permissions, o } = this.props
    const { isDialogOpen } = this.state
    return (
      <Paper className={classes.page__wrapper} elevation={0}>
        <header className={classes.page__header}>
          <Typography component='h2' variant='h5'>
            {I18n.t('v4/users.title')}
          </Typography>
        </header>

        {
          has_write_permissions && <Button
            variant='outlined'
            color='primary'
            className={classes.page__newResourceButton}
            href={`/v4/o/${o}/organizations/${organization.id}/users/new_invitation`}
          >
            {I18n.t('v4/users.invite')}
          </Button>
        }

        <MaterialTable
          components={{
            Container: props => <div>{props.children}</div>,
          }}
          columns={getUsersHeaders()}
          data={model}
          actions={
            user_is_owner ? [
              rowData => ({
                icon: 'delete',
                iconProps: { 'data-testid': `delete-${rowData.id}` },
                tooltip: I18n.t('actions.remove'),
                disabled: rowData.roles.some(role => role.name === 'owner'),
                onClick: (event, rowData) => {
                  this.setState({ isDialogOpen: true, id: rowData.id })
                }
              })
            ] : []
          }
          options={{
            showTitle: false,
            showEmptyDataSourceMessage: true,
            actionsColumnIndex: -1
          }}
        />
        <DeleteDialog
          dialogTitle={`${I18n.t('actions.remove')} ${I18n.t('activerecord.models.v4/users.one')}?`}
          dialogText={I18n.t('v4/users.confirmation.remove')}
          handleDelete={() => this.handleDelete(organization.id)}
          onCancel={this.closeDialog}
          onClose={this.closeDialog}
          open={isDialogOpen}
        />
      </Paper>
    )
  }
}

Index.propTypes = {
  model: PropTypes.array
}

export default withStyles(styles)(Index)
