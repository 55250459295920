import React from 'react'

import {
  Button,
  Divider,
  IconButton,
  Link,
  List,
  ListItem,
  Popover,
  Typography,
  withStyles
} from '@material-ui/core'

import ProfileIcon from '@material-ui/icons/PersonRounded'

import Organizations from './OrganizationsList'
import SelectedOrganization from './SelectedOrganization'

import styles from './styles'

const OrganizationsMenu = ({
  classes,
  organizations,
  currentOrganization,
  currentUser,
  router
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null)

  const open = !!anchorEl

  const handleOpen = event => setAnchorEl(event.currentTarget)

  const handleClose = () => setAnchorEl(null)

  return (
    <div data-intercom-target='organizations'>
      <IconButton
        className={classes.Navbar__Icon}
        aria-owns={open ? 'render-props-popover' : null}
        aria-haspopup='true'
        variant='contained'
        name='organization-menu'
        onClick={handleOpen}
      >
        <ProfileIcon />
      </IconButton>

      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        classes={{ paper: classes.Popover__List }}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={classes.organization__header}>
          <Typography className={classes.organization__label} >
            {I18n.t('v4/organization.show.account')} {currentUser.email}
          </Typography>
        </div>

        {
          !_.isEmpty(currentOrganization) &&
          <SelectedOrganization
            selected={currentOrganization}
            currentUser={currentUser}
            router={router}
          />
        }

        <List className={classes.organizationsMenu__list}>
          <Organizations
            organizations={organizations}
            router={router}
          />
        </List>
        <ListItem
          className={classes.organizationsMenu__footer}
        >
          <Button
            data-intercom-target='user_management'
            variant='outlined'
            color='secondary'
            className={classes.organizationsMenu__button}
            onClick={() => router.visit('account/edit')}
            data-testid='btnOrganizationsManage'
          >
            {I18n.t('v4/organization.actions.area')}
          </Button>

          <Button
            variant='outlined'
            className={classes.organizationsMenu__button}
            onClick={() => location.replace('/users/sign_out')}
            data-testid='btnLogout'
          >
            {I18n.t('actions.exit')}
          </Button>
        </ListItem>

        <Divider />
        <div className={classes.organizationsMenu_terms}>
          <Link
            href={`/v5/privacy_policies`}
            target='_blank'
            underline='always'
            className={classes.organizationsMenu_termsLink}
          >
            <Typography variant="caption">
              {I18n.t('v4/users.privacy_policies')}
            </Typography>
          </Link>

          <Link
            href={`/v5/terms`}
            target='_blank'
            underline='always'
            className={classes.organizationsMenu_termsLink}
          >
            <Typography variant="caption">
              {I18n.t('v4/users.terms_of_use')}
            </Typography>
          </Link>
        </div>

      </Popover>
    </div>
  )
}

export default withStyles(styles)(OrganizationsMenu)
