import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { Paper, Typography } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import Table from '@ui/Table'

import DeleteDialog from '@ui/DeleteDialog'
import indexPageStyles from '@styles/indexPage'
import MultipleSelect from '@ui/Fields/MultipleSelect'
import { tableHeaders } from '@models/attachments'

class Index extends Component {
  constructor(props) {
    super(props)

    this.state = {
      showingFiles: [],
      filters: [],
      id: null,
      isDialogOpen: false
    }
  }

  componentDidMount() {
    const { model, resources } = this.props

    const urlParams = new URLSearchParams(window.location.search)
    const filter = urlParams.get('filter') || ''

    const selectedFilter = resources.find(resource => resource.resource_name.toLowerCase() === filter.toLowerCase())

    if (!!selectedFilter) {
      return this.setState({
        filters: [selectedFilter.resource_name]
      }, this.handleFilterFiles)
    }

    this.setState({
      showingFiles: model
    })
  }

  handleFilterFiles = () => {
    const { model } = this.props
    const { filters } = this.state

    if (_.isEmpty(filters)) {
      return this.setState({
        showingFiles: model
      })
    }

    const filteredFiles = model.filter(file => filters.includes(file.resource_type))

    this.setState({
      showingFiles: filteredFiles
    })
  }

  handleFilterChange = ({ target }) => {
    this.setState({
      filters: target.value
    }, this.handleFilterFiles)
  }

  closeDialog = () => {
    this.setState({ isDialogOpen: false })
  }

  render() {
    const { classes, resources, organization_id, o } = this.props
    const { isDialogOpen, id, showingFiles, filters } = this.state

    return (
      <Paper className={classes.page__wrapper} elevation={0}>
        <header className={classes.page__header}>
          <Typography component='h2' variant='h5'>
            {I18n.t('activerecord.models.v4/attachments.other')}
          </Typography>
        </header>

        <MultipleSelect
          className={classes.FilesManagement_filterField}
          data={resources}
          label={I18n.t('v4/attachments.filter')}
          fields={{ id: 'resource_name', name: 'name' }}
          input={{
            value: filters,
            onChange: this.handleFilterChange
          }}
        />

        <Table
          tableKey='attachments-index'
          components={{
            Container: props => <div>{props.children}</div>,
          }}
          columns={tableHeaders}
          data={showingFiles}
          actions={[
            rowData => ({
              icon: 'delete',
              iconProps: { 'data-testid': `delete-${rowData.id}` },
              tooltip: I18n.t('actions.remove'),
              onClick: (event, rowData) => {
                this.setState({ isDialogOpen: true, id: rowData.id })
              }
            })
          ]}
          options={{
            showTitle: false
          }}
        />

        <DeleteDialog
          dialogTitle={`${I18n.t('actions.remove')} ${I18n.t('activerecord.models.v4/attachments.one')}?`}
          dialogText={I18n.t('confirmation.remove')}
          successUrl={`/v4/o/${o}/attachments`}
          deleteUrl={`/api/v4/organizations/${organization_id}/attachments/${id}.json`}
          onCancel={this.closeDialog}
          onClose={this.closeDialog}
          open={isDialogOpen}
        />
      </Paper>
    )
  }
}

Index.propTypes = {
  model: PropTypes.array,
  resources: PropTypes.array
}

Index.defaultProps = {
  model: [],
  resources: []
}

const styles = theme => ({
  ...indexPageStyles(theme),
  FilesManagement_filterField: {
    minWidth: '50%'
  }
})

export default withStyles(styles)(Index)
