import safeJsonParse from './safeJSONparse'

export const setLocalStorageItem = (key, content) => {
  if (content == null) {
    return
  }

  const formattedContent = typeof content === 'object'
    ? JSON.stringify(content)
    : content

  window.localStorage.setItem(key, formattedContent)
}

export const getLocalStorageItem = key => {
  const item = window.localStorage.getItem(key)

  return safeJsonParse(item)
}

export const createLocalStorageObject = key => ({
  get: defaultParam => getLocalStorageItem(key) || defaultParam,
  set: content => setLocalStorageItem(key, content)
})
