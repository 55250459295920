import React from 'react'
import { Form as FinalForm } from 'react-final-form'

import { validate, deserialize, handleTenantSubmit } from '@models/admin/tenant'
import { RailsForm } from '@support/RailsForm'
import Form from './Form'

export const Edit = ({ model, products, is_admin, handleError, o }) =>
  <RailsForm
    component={FinalForm}
    initialValues={deserialize(model)}
    validate={validate}
    handleSubmit={
      async data => {
        try {
          await handleTenantSubmit({
            data,
            url: `/v4/o/${o}/admin/tenants/${model.id}`,
            method: 'put',
            successUrl: `/v4/o/${o}/admin/tenants`
          })
        }
        catch (error) {
          handleError(error)
        }
      }
    }
    render={(props) => (
      <Form
        {...props}
        title={I18n.t('v4/tenant.actions.update')}
        isAdmin={is_admin}
        products={products}
        o={o}
      />
    )}
  />

Edit.defaultProps = {
  model: {},
  products: [],
  is_admin: false
}

export default Edit
