import React from 'react'

import {
  Grid,
  Typography
} from '@material-ui/core'

import { useRouter } from '@contexts/RouterProvider'

const Header = ({
  classes,
  isFilled
}) => {
  const router = useRouter()

  return (
    <Grid container spacing={0} style={{
      marginTop: '4rem'
    }}>
      <Grid item xs={12}>
        <Typography variant='h5'>
          {I18n.t('v4/field_notebook.title')}
        </Typography>
      </Grid>
     {!isFilled &&
      <Grid item xs={12}>
        <Typography align='left' variant='body2'>
          {I18n.t('v4/field_notebook.text')}
        </Typography>
      </Grid>
     }
    </Grid>
  )
}

export default (Header)
