import React from 'react'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { Field } from 'react-final-form'
import TextField from '@ui/Fields/TextField'
import Grid from '@material-ui/core/Grid'
import Select from '@ui/Fields/Select'

import withStyles from '@material-ui/core/styles/withStyles'
import styles from '@theme/styles/forms'

const Form = ({
  classes,
  handleSubmit,
  title,
  statuses,
  values,
  o
}) =>
    <form className={classes.form} onSubmit={handleSubmit} data-testid='plans-form'>
      <Paper className={classes.form__wrapper} elevation={0}>
        <header className={classes.form__header}>
          <Typography component='h2' variant='h5'>
            {title}
          </Typography>
        </header>
        <main className={classes.form__fields}>
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <div className={classes.form__field__wrapper}>
                <Field
                  component={TextField}
                  className={classes.form__fieldWithError}
                  margin='normal'
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  variant='outlined'
                  label={I18n.t('v4/plans.name')}
                  name='name'
                  required
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className={classes.form__field__wrapper}>
                <Field
                  component={Select}
                  className={classes.form__fieldWithError}
                  margin='normal'
                  fullWidth
                  data={statuses}
                  fields={{id: 'id', name: 'name'}}
                  InputLabelProps={{ shrink: true }}
                  variant='outlined'
                  label={I18n.t('v4/plans.status')}
                  name='status'
                  required
                />
              </div>
            </Grid>
            <Grid item xs={6}>
              <div className={classes.form__field__wrapper}>
                <Field
                  component={TextField}
                  className={classes.form__fieldWithError}
                  margin='normal'
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  variant='outlined'
                  label={I18n.t('v4/plans.periodicity')}
                  name='interval_count'
                  type='number'
                  required
                />
              </div>
            </Grid>

            <Grid item xs={12} md={4}>
              <Field
                component={TextField}
                id="pt-translation"
                className={classes.form__fieldWithError}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                label={`${I18n.t('v4/plans.recurrence')} ${I18n.t('v4/plans.pt_label')}`}
                fullWidth
                margin='normal'
                name="recurrence_translations[pt-BR]"
                required
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Field
                component={TextField}
                id="en-translation"
                className={classes.form__fieldWithError}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                label={`${I18n.t('v4/plans.recurrence')} ${I18n.t('v4/plans.en_label')}`}
                fullWidth
                margin='normal'
                name="recurrence_translations[en]"
                required
              />
            </Grid>

            <Grid item xs={12} md={4}>
              <Field
                component={TextField}
                id="es-translation"
                className={classes.form__fieldWithError}
                InputLabelProps={{ shrink: true }}
                variant="outlined"
                label={`${I18n.t('v4/plans.recurrence')} ${I18n.t('v4/plans.es_label')}`}
                fullWidth
                margin='normal'
                name="recurrence_translations[es-MX]"
                required
              />
            </Grid>
            <Grid item xs={12}>
              <div className={classes.form__field__wrapper}>
                <Field
                  component={TextField}
                  className={classes.form__fieldWithError}
                  margin='normal'
                  fullWidth
                  multiline
                  InputLabelProps={{ shrink: true }}
                  variant='outlined'
                  label={I18n.t('v4/plans.description')}
                  name='description'
                />
              </div>
            </Grid>

          </Grid>
        </main>
        <footer className={classes.form__actions}>
          <Button
            className={classes.form__action}
            color='primary'
            type='submit'
            variant='contained'
          >
            {title}
          </Button>

          <Button
            className={classes.form__action}
            color='secondary'
            href={`/v4/o/${o}/store/admin/plans`}
          >
            {I18n.t('actions.back')}
          </Button>
        </footer>
      </Paper>
    </form>

Form.defaultProps = {
  statuses: []
}

export default withStyles(styles)(Form)
