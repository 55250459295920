import React, { Component } from 'react'

import withStyles from '@material-ui/core/styles/withStyles'

import ForecastCard from './card'
import ForecastResume from './forecast-resume'

import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery'

import styles from './styles'

const Forecast = ({
  classes,
  cards,
  resume
}) => {
  const isMobile = useMediaQuery('(max-width:400px)')

  return <div className={classes.map__forecast}>
    <div className={isMobile ? `${classes.Forecast_wrapper} ${classes.Forecast_mobile}` : classes.Forecast_wrapper} >
      {
        cards.map(card =>
          <ForecastCard
            key={`forecast-card-${card.header.day}`}
            header={card.header}
            body={card.body}
            rain={card.rainChance}
          />
        )
      }

    </div>

    <ForecastResume
      pcpMaxTotal={resume.pcpMaxTotal}
      pcpMinTotal={resume.pcpMinTotal}
      daysWithRain={resume.daysWithRain}
      greaterVol={resume.greaterVol}
    />
  </div>

}
export default withStyles(styles)(Forecast)
