const windowHeigth = window.innerWidth;
export default theme => ({
  page__wrapper: {
    backgroundColor: 'transparent',
    margin: `${theme.spacing.unit * 5}px auto 0`,
    maxWidth: 1200,
    padding: theme.spacing.unit * 4,
  },

  page__header: {
    marginBottom: theme.spacing.unit * 4
  },

  page__newResourceButton: {
    marginBottom: theme.spacing.unit * 4
  },

  page__select: {
    display: 'flex',
    justifyContent: 'space-between'
  },

  Field_notebook_container: {
    margin: '40px auto 0',
    padding: `${windowHeigth < 375 ? 16 : 32}`,
    maxWidth: `${windowHeigth < 375 ? '95%' : '80%'}`,
    height: '100vh !important',
  },
})
