import React, { Component } from 'react'

import { withStyles } from '@material-ui/core/styles'
import { Paper, Typography, Divider, IconButton, Grid, Button } from '@material-ui/core'
import {
  Clear,
  KeyboardArrowDown,
  KeyboardArrowUp
} from '@material-ui/icons'

import { deserializeData } from '@models/irrigation_managements/maps'
import { withHttpService } from '@contexts/HttpServiceProvider'
import { withRouter } from '@contexts/RouterProvider'
import { MENUS, withMenusStatus } from '@contexts/MenusStatusProvider'
import { calculateNumberOfAreasThatNeedIrrigation, calculateGreatestDemandArea } from '@models/irrigation_managements/maps'

import { sendAmplitudeEvent } from '@utils/amplitudeEvent'
import { IntercomAPI } from '@utils/IntercomAPI';

import IrrigationForecastCard from './Cards/IrrigationForecastCard'
import LegendCard from './Cards/LegendCard'
import ResumeCard from './Cards/ResumeCard'

class IrrigationManagement extends Component {
  constructor(props) {
    super(props)

    this.state = {
      selectedDate: {},
      forecast: [],
      currentDate: {},
      resume: {
        areasThatNeedIrrigation: 0,
        totalAreas: 0,
        greatestDemandArea: '--'
      },
      minimize: false
    }
  }

  handleClose = () => {
    const { menusStatus: { toggleMenu }, removeColorsFromAreas } = this.props
    
    removeColorsFromAreas()
    toggleMenu(MENUS.irrigationProduct, false)
  }

  fetchIrrigationData = async () => {
    const {
      httpService: { apiRequest },
      errorHandler,
    } = this.props

    try {
      const { data } = await apiRequest('irrigation_managements/hydric_balances')
      const [currentDate, ...forecast] = deserializeData(data)
      this.setState({
        currentDate,
        forecast
      })
      if (currentDate) {
        this.handleSelectDate(currentDate)
      }
    }
    catch (error) {
      errorHandler(error)
    }
  }

  componentDidMount() {
    this.fetchIrrigationData()
  }

  handleSelectDate = (date) => {
    const { changeAreasColors } = this.props
    const { areasThatNeedIrrigation, totalAreas } = calculateNumberOfAreasThatNeedIrrigation(date.areas)
    const greatestDemandArea = calculateGreatestDemandArea(date.areas)

    this.setState(prevState =>
      prevState.selectedDate === date
        ? { selectedDate: {} }
        : { selectedDate: date }
      , () => {
        const { selectedDate } = this.state
        changeAreasColors(selectedDate.areas)
      })

    this.setState({
      resume: {
        areasThatNeedIrrigation,
        totalAreas,
        greatestDemandArea
      }
    })
  }

  render() {
    const { classes, hasForecastProduct, menusStatus: { menus, toggleMenu }, router } = this.props
    const { forecast, selectedDate, resume, currentDate, minimize } = this.state

    return (
      minimize
        ?
        <div className={classes.AquaMenu_action}>
          <div className={classes.AquaMenu_infos}>
            <Typography variant='subtitle1' className={`${classes.AquaMenu_info} ${classes.AquaMenu_name}`}>
              {I18n.t('v4/products.aqua')}
            </Typography>
          </div>
          <div className={classes.AquaMenu_edit} onClick={() => this.setState({ minimize: false })}>
            <IconButton >
              <KeyboardArrowUp />
            </IconButton>

            <IconButton
              onClick={this.handleClose}
            >
              <Clear />
            </IconButton>
          </div>
        </div>
        :
        <Paper className={classes.AquaMenu_Container}>
          <div className={classes.AquaMenu_Wrapper}>
            <div className={classes.AquaMenu_infos}>
              <Typography variant='subtitle1' className={`${classes.AquaMenu_info} ${classes.AquaMenu_name}`}>
                {I18n.t('v4/products.aqua')}
              </Typography>
            </div>
            <div className={classes.AquaMenu_edit}>
              <Button
                color='secondary'
                variant='outlined'
                onClick={() => {
                  router.visit('/irrigation_managements');
                  sendAmplitudeEvent('Aqua - Map - More details')
                  IntercomAPI('trackEvent', 'Aqua - Map - More details')
                }}
              >
                {I18n.t('v4/forecast.details')}
              </Button>
              <IconButton onClick={() => this.setState({ minimize: true })}>
                <KeyboardArrowDown />
              </IconButton>

              <IconButton
                onClick={this.handleClose}
              >
                <Clear />
              </IconButton>
            </div>
          </div>
          <Divider />
          <div className={classes.CardsList}>
            <Grid container alignItems='stretch' spacing={8}>
              <Grid item xs={12} sm={4}>
                <IrrigationForecastCard
                  hasForecastProduct={hasForecastProduct}
                  selectedDate={selectedDate}
                  currentDate={currentDate}
                  forecast={forecast}
                  onDateClick={this.handleSelectDate}
                />
              </Grid>
              
              <Grid item xs={12} sm={5}>
                <LegendCard />
              </Grid>

              <Grid item xs={12} sm={3}>
                <ResumeCard
                  resume={resume}
                />
              </Grid>
            </Grid>
          </div>
        </Paper>
    )
  }
}

IrrigationManagement.defaultProps = {
  data: []
}

const styles = theme => ({
  AquaMenu_Container: {
    height: '270px',
    position: 'fixed',
    bottom: 0,
    zIndex: 1050,
    right: 0,
    background: theme.palette.white,
    '@media(max-width: 760px)': {
      height: '340px',
    },
    cursor: 'auto',
    borderRadius: 0,
    overflow: 'hidden',
    width: '100%'
  },
  AquaMenu_Minimize: {
    height: '5px',
    width: '100%',
    bottom: 0,
    zIndex: 1050,
    right: 0,
    position: 'fixed',
    background: theme.palette.white,
    cursor: 'pointer'
  },
  AquaMenu_action: {
    bottom: 0,
    zIndex: 1050,
    right: 0,
    position: 'fixed',
    background: theme.palette.white,
    '&:hover': {
      background: theme.palette.white
    },
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    borderRadius: 0
  },
  AquaMenu_edit: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'flex-end',
    flexWrap: 'wrap-reverse',
    paddingRight: theme.spacing.unit * 2
  },
  AquaMenu_infos: {
    padding: theme.spacing.unit * 2,
    display: 'flex',
    alignItems: 'center',
    flexWrap: 'wrap',
    width: '100%'
  },
  AquaMenu_info: {
    display: 'flex',
    marginLeft: theme.spacing.unit * 2
  },
  AquaMenu_name: {
    marginLeft: theme.spacing.unit * 2
  },
  AquaMenu_Wrapper: {
    display: 'flex'
  },
  CardsList: {
    display: 'flex',
    flexGrow: 1,
    padding: theme.spacing.unit,
    overflow: 'auto',
    maxHeight: '100%'
  }
})

export default
  withRouter(
    withHttpService(
      withMenusStatus(
        withStyles(styles)(IrrigationManagement)
      )
    )
  )