import React, { Component } from 'react'

import {
  Button,
  Popover,
  List,
  ListItem,
  Typography
} from '@material-ui/core'

import NoteDialog from '../areas/notes/Dialog'
import IrrigationDialog from '../equipments/irrigations/Dialog'

import { withRouter } from '@contexts/RouterProvider'
import { sendAmplitudeEvent } from '@utils/amplitudeEvent'
import { IntercomAPI } from '@utils/IntercomAPI';

class InformMenu extends Component {
  constructor(props) {
    super(props)

    this.state = {
      anchorEl: null,
      noteDialogOpen: false,
      irrigationDialogOpen: false
    }
  }

  handlePopoverClick = (event) => {
    this.setState({ anchorEl: event.currentTarget });
    sendAmplitudeEvent('Aqua - List - Inform button');
    IntercomAPI('trackEvent', 'Aqua - List - Inform button')
  }

  handlePopoverClose = () => this.setState({ anchorEl: null })

  handleOpenNoteDialog = () => {
    this.setState({
      noteDialogOpen: true,
      anchorEl: null
    })
  }

  handleOpenIrrigationDialog = () => {
    this.setState({
      irrigationDialogOpen: true,
      anchorEl: null
    })
  }

  handleCloseDialogs = () => {
    this.setState({
      irrigationDialogOpen: false,
      noteDialogOpen: false
    })
  }

  render() {
    const { areas, handleError, organization_id, router } = this.props
    const { anchorEl, irrigationDialogOpen, noteDialogOpen } = this.state

    const open = !!anchorEl

    return (
      <>
        <div>
          <Button color='primary' variant='contained' onClick={this.handlePopoverClick}>
            {I18n.t('v4/irrigation_management.inform')}
          </Button>

          <Popover
            open={open}
            anchorEl={anchorEl}
            onClose={this.handlePopoverClose}
            anchorOrigin={anchorOrigin}
            transformOrigin={transformOrigin}
          >
            <List>
              <Item
                intercomTag='inform-notes-aqua-internal'
                onClick={this.handleOpenNoteDialog}
                label={I18n.t('v4/irrigation_management.inform_items.notes')}
              />
              
              <Item
                intercomTag='inform-irrigation-aqua-internal'
                onClick={this.handleOpenIrrigationDialog}
                label={I18n.t('v4/irrigation_management.inform_items.irrigation')}
              />
            </List>
          </Popover>
        </div>

        <NoteDialog
          dialogOpen={noteDialogOpen}
          organization_id={organization_id}
          handleCloseDialog={this.handleCloseDialogs}
          areas={areas}
          handleError={handleError}
        />

        <IrrigationDialog
          dialogOpen={irrigationDialogOpen}
          organization_id={organization_id}
          handleCloseDialog={this.handleCloseDialogs}
          areas={areas}
          handleError={handleError}
        />
      </>
    )
  }
}

const Item = ({ onClick, label, intercomTag }) => (
  <ListItem
    data-intercom-target={intercomTag}
    button onClick={onClick}
  >
    <Typography variant='body2'>
      {label}
    </Typography>
  </ListItem>
)

const anchorOrigin = {
  vertical: 'bottom',
  horizontal: 'right'
}

const transformOrigin = {
  vertical: 'top',
  horizontal: 'right'
}

export default withRouter(InformMenu)
