import React from 'react'

import { Typography, withStyles } from '@material-ui/core'

const NoCards = ({ classes }) =>
  <div className={classes.Index_NoCards}>
    <Typography variant='h5'>
      {I18n.t('v4/irrigation_management.no_managements')}
    </Typography>
  </div>

const styles = () => ({
  Index_NoCards: {
    paddingTop: '50px',
    textAlign: 'center'
  }
})

export default withStyles(styles)(NoCards)
