import React from 'react'

import { Button, withStyles } from '@material-ui/core'

import Graphics from '@images/equipment/graphics'

const Action = ({ classes, label, onChange, variant, intercomTag }) =>
  <Button
    color='primary'
    className={classes.Action_wrapper}
    onClick={onChange}
    variant={variant}
    data-intercom-target={intercomTag}
  >
    {
      variant === 'contained' &&
      <img src={Graphics} alt='graphics' className={classes.Action_icon} />
    }

    {label}
  </Button>

const styles = theme => ({
  Action_wrapper: {
    width: '100%',
    marginTop: theme.spacing.unit * 2
  },
  Action_icon: {
    height: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit
  },
})

export default withStyles(styles)(Action)
