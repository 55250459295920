import React, { Component } from 'react'

import IconButton from '@material-ui/core/IconButton'
import Popover from '@material-ui/core/Popover'
import ShoppingCart from '@material-ui/icons/ShoppingCart'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

import ItemsList from './list'
import EmptyList from './empty'

import styles from './styles'

class Cart extends Component {
  state = {
    anchorEl: null
  }

  handleClick = event => {
    this.setState({
      anchorEl: event.currentTarget,
    })
  }

  handleClose = () => {
    this.setState({
      anchorEl: null,
    })
  }

  render() {
    const {
      classes,
      counter,
      handleAdd,
      handleSubtract,
      handleRemove,
      itemsOnCart,
      total
    } = this.props

    const {
      handleClick,
      handleClose
    } = this

    const {
      anchorEl
    } = this.state

    const open = Boolean(anchorEl)

    return <div>
      <IconButton
        className={classes.Navbar__Icon}
        aria-owns={open ? 'render-props-popover' : null}
        aria-haspopup="true"
        variant="contained"
        name="store-menu"
        onClick={handleClick}
      >
        <ShoppingCart />
        { counter > 0 &&
          <Typography className={classes.cart__counter}>
            {counter}
          </Typography> }
      </IconButton>

      <Popover
        className={classes.root}
        id="store-menu-popper"
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right',
        }}
      >
      {!_.isEmpty(itemsOnCart)
        ? <ItemsList
            handleRemove={handleRemove}
            itemsOnCart={itemsOnCart}
            total={total}
            handleAdd={handleAdd}
            handleSubtract={handleSubtract}
          />
        : <EmptyList />
      }
      </Popover>
    </div>
  }
}

export default withStyles(styles)(Cart)
