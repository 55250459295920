import React, { Component } from 'react'

import {
  Button,
  IconButton,
  Menu,
  MenuItem,
  Paper,
  Typography,
  withStyles
} from '@material-ui/core'

import { withSessionData } from '@contexts/SessionDataProvider'
import { withHttpService } from '@contexts/HttpServiceProvider'
import { withErrorHandler } from '@contexts/ErrorHandlerProvider'

import { ArrowDropDown, Print } from '@material-ui/icons'

import './Styles.css'

class Insights extends Component {
  constructor(props) {
    super(props)

    this.state = {
      anchorEl: null,
      insights: [],
      currentInsight: {
        name: '',
        id: null,
        url: ''
      },
      removeStyle: false,
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData = async () => {
    const {
      errorHandler,
      httpService: { apiRequest },
      sessionData: { currentOrganization }
    } = this.props

    const params = {
      organization_id: currentOrganization.id
    }

    try {
      const fetchUrl = '/insights/get_insights'
      const { data } = await apiRequest(fetchUrl, { params })

      this.setState({ insights: data, currentInsight: data[0] })
    }
    catch (error) {
      errorHandler(error)
    }
  }

  handleAnchorMenu = event => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  handleSelectInsight = insight => {
    this.setState({ currentInsight: insight })
    this.handleClose()
  }

  handlePrint = () => {
    this.setState({ removeStyle: true })

    setTimeout(() => {
      window.print()

      this.setState({ removeStyle: false })
    }, 100)
  }

  render() {
    const { anchorEl, currentInsight, insights, removeStyle } = this.state
    const { classes } = this.props

    return (
      <Paper
        className={
          removeStyle
            ? classes.Dashboards_wrapper_print
            : classes.Dashboards_wrapper}
        elevation={0}
      >
        {
          insights.length > 1 &&
          <Selector
            anchorEl={anchorEl}
            currentInsight={currentInsight}
            handleAnchorMenu={this.handleAnchorMenu}
            handleClose={this.handleClose}
            handleSelectInsight={this.handleSelectInsight}
            insights={insights}
            classes={classes}
          />
        }

        <IconButton onClick={this.handlePrint}>
          <Print ></Print>
        </IconButton>

        {
          !_.isEmpty(insights)
            ? (
              <div className={classes.Dashboards_iframeWrapper}>
                <iframe
                  id='section-to-print'
                  allowFullScreen
                  src={currentInsight.url}
                  className={!removeStyle && classes.Dashboards_iframe}
                />
              </div>
            )
            : (
              <Typography variant='body1'>
                {I18n.t('v4/insight.empty_data_message')}
              </Typography>
            )
        }
      </Paper >
    )
  }
}

const Selector = ({
  anchorEl,
  currentInsight,
  handleSelectInsight,
  handleClose,
  handleAnchorMenu,
  insights
}) =>
  <>
    <Button onClick={handleAnchorMenu}>
      {currentInsight.name}

      <ArrowDropDown />
    </Button>

    <Menu
      anchorEl={anchorEl}
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      {
        insights.map(insight =>
          <MenuItem
            onClick={() => handleSelectInsight(insight)}
            key={insight.id}
          >
            {insight.name}
          </MenuItem>
        )
      }
    </Menu>
  </>

const styles = theme => ({
  Dashboards_wrapper: {
    marginTop: 60,
    textAlign: 'center'
  },
  Dashboards_wrapper_print: {
    textAlign: 'center'
  },
  Dashboards_iframeWrapper: {
    position: 'relative',
    height: 'calc(100vh - 60px)',
    paddingTop: '60%',
    marginTop: theme.spacing.unit,
    visibility: 'visible'
  },
  Dashboards_iframe: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    border: 0,
    visibility: 'visible'
  },
  Dashboards_iframe_remove: {
    position: 'absolute',
    top: 0,
    left: 0,
    border: 0,
    visibility: 'visible'
  },
  Dashboard__loading: {
    display: 'flex',
    justifyContent: 'center',
    margin: '50px',
  },
})

export default
  withSessionData(
    withHttpService(
      withErrorHandler(
        withStyles(styles)(Insights)
      )
    )
  )
