import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import Table from '@ui/Table'

import { getSeasonsHeaders, today, seasonActionsHandler } from '@models/seasons'
import Dialog from '../ui/DeleteDialog'
import { MTableCell } from 'material-table'

import styles from './styles'

class Index extends Component {
  state = {
    isDeleteDialogOpen: false,
    isTimerDialogOpen: false,
    id: null,
    season: {},
    endDate: '',
  }

  render() {
    const { model, classes, o } = this.props
    const { season, endDate } = this.state

    return (
      <Paper className={classes.page__wrapper} elevation={0}>
        <header className={classes.page__header}>
          <Typography component='h2' variant='h5'>
            {I18n.t('v4/seasons.index.title')}
          </Typography>
        </header>
        <Button
          variant='outlined'
          color='primary'
          className={classes.page__newResourceButton}
          onClick={() => Turbolinks.visit(`/v4/o/${o}/seasons/new`)}
        >
          {I18n.t('actions.create')} {I18n.t('v4/seasons.index.season')}
        </Button>

        <Table
          tableKey='seasons-index'
          components={{
            Container: props => <div>{props.children}</div>,
            Cell: props =>
              <MTableCell
                {...props}
                value={!Array.isArray(props.value)
                  ? props.value
                  : props.value.map(val =>
                    <span key={val} className={classes.Dashboard__season__table__cell}>
                      {val}
                    </span>
                  )
                }
              />
          }}
          columns={getSeasonsHeaders()}
          data={model}
          actions={[
            rowData => ({
              icon: 'dashboard',
              iconProps: { 'data-testid': `dashboard-${rowData.id}` },
              tooltip: I18n.t('actions.dashboard'),
              onClick: (event, rowData) => {
                Turbolinks.visit(`/v4/o/${o}/seasons/${rowData.id}/dashboard`)
              }
            }),
            rowData => ({
              icon: 'delete',
              iconProps: { 'data-testid': `delete-${rowData.id}` },
              tooltip: I18n.t('actions.remove'),
              onClick: (event, rowData) => {
                this.setState({ isDeleteDialogOpen: true, id: rowData.id })
              }
            }),
            rowData => (!rowData.ended && {
              icon: rowData.started ? 'alarm_off' : 'alarm_on',
              iconProps: { 'data-testid': `control-${rowData.id}` },
              tooltip: rowData.started ? I18n.t('v4/seasons.actions.end') : I18n.t('v4/seasons.actions.start'),
              onClick: (event, rowData) => {
                this.setState({ isTimerDialogOpen: true, id: rowData.id, season: rowData, endDate: new Date(rowData.end_date) })
              }
            }),
            rowData => (!rowData.ended && {
              icon: 'create',
              iconProps: { 'data-testid': `edit-${rowData.id}` },
              tooltip: I18n.t('actions.edit'),
              onClick: (event, rowData) => {
                Turbolinks.visit(`/v4/o/${o}/seasons/${rowData.id}/edit`)
              }
            })
          ]}
          options={{
            showTitle: false
          }}
        />

        <Dialog
          dialogTitle={
            (season.started && today() > endDate && I18n.t('v4/seasons.index.dialog_title_end')) ||
            (season.started && today() < endDate && I18n.t('v4/seasons.index.dialog_title_antecipate')) ||
            (!season.started && I18n.t('v4/seasons.index.dialog_title_start'))
          }
          dialogText={
            season.started
              ? I18n.t('v4/seasons.index.dialog_text_end')
              : I18n.t('v4/seasons.index.dialog_text_start', { date: season.start_date })
          }
          handleDelete={
            seasonActionsHandler(
              season.id,
              season.started ? { season: { _end_now: true } } : { season: { _start_now: true } },
              o
            )
          }
          onCancel={() => this.setState({ isTimerDialogOpen: false })}
          onClose={() => this.setState({ isTimerDialogOpen: false })}
          open={this.state.isTimerDialogOpen}
          actionText={season.started ? I18n.t('v4/seasons.actions.end') : I18n.t('v4/seasons.actions.start')}
        />

        <Dialog
          dialogTitle={I18n.t('v4/seasons.index.dialog_delete')}
          dialogText={I18n.t('confirmation.remove')}
          deleteUrl={`/v4/o/${o}/seasons/${this.state.id}`}
          onCancel={() => this.setState({ isDeleteDialogOpen: false })}
          onClose={() => this.setState({ isDeleteDialogOpen: false })}
          open={this.state.isDeleteDialogOpen}
        />
      </Paper>
    )
  }
}

Index.defaultProps = {
  model: []
}

export default withStyles(styles)(Index)
