import React from 'react'
import { Form as FinalForm } from 'react-final-form'

import { validate, submit } from '@models/account/payment_profiles'

import { RailsForm } from '@support/RailsForm'
import Form from '@components/v4/organizations/payment_profiles/Form'

import { Button } from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'

export const BillingDataForm = ({ organization_id, classes, onCancel, onHandleSubmit, gateway_base_url, gateway_public_key, handleError, o }) => {

  const [loading, setLoading] = React.useState(false)

  const payment_method_code = I18n.t('activerecord.attributes.v4/organization_account.payment_profiles.payment_method_code')

  return (
    <RailsForm
      component={FinalForm}
      validate={validate}
      initialValues={{ payment_method_code }}
      handleSubmit={(values) => submit({ values, event, handleError, gateway_base_url, gateway_public_key, organization_id, onHandleSubmit, setLoading, o })}
      render={(props) => (
        <Form
          {...props}
          gateway_base_url={gateway_base_url}
          gateway_public_key={gateway_public_key}
          setLoading={setLoading}
          onHandleSubmit={onHandleSubmit}
          checkoutScreenStyle={classes.BillingData__Form}
          disableFields={loading}
          isOnCheckoutScreen
          organization_id={organization_id}
          o={o}
          actions={<BillingDataActions classes={classes} onCancel={onCancel} loading={loading} />}
        />
      )}
    />
  )
}

const BillingDataActions = ({ classes, onCancel, loading }) =>
  <div className={classes.BillingData__Actions}>
    <Button
      className={classes.BillingData__Action}
      disabled={!!loading}
      onClick={onCancel}
      variant='contained'
    >
      {I18n.t('actions.back')}
    </Button>

    <Button
      className={classes.BillingData__Action}
      color='primary'
      disabled={!!loading}
      type='submit'
      variant='contained'
      data-intercom-target="New Credit Card"
    >
      {I18n.t('actions.advance')}
    </Button>
  </div>


const styles = theme => ({
  BillingData__Actions: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: `${theme.spacing.unit * 2}px`
  },
  BillingData__Action: {
    '&:not(:last-child)': {
      marginRight: `${theme.spacing.unit * 2}px`
    }
  },
  BillingData__Form: {
    margin: `${theme.spacing.unit}px 0`,
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    width: '567px',
    minHeight: '480px',
    '@media screen and (min-width: 300px) and (max-width: 566px)': {
      width: '279px'
    }
  },
})

export default withStyles(styles)(BillingDataForm)
