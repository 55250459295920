import React from 'react'
import PropTypes from 'prop-types'

import { CircularProgress, withStyles } from '@material-ui/core'

import { getPreviewMode } from '@models/weather/dashboard'
import { apiService } from "@services/httpService"

import styles from './styles'

import Dashboard from './Dashboard'

class Index extends React.Component {
  constructor(props) {
    super(props)

    this.forecastCardReference = React.createRef()

    this.state = {
      loading: true,
      dailyData: [],
      hourlyData: [],
      forecastList: [],
      dailyDescriptions: [],
      hourlyDescriptions: [],
      selectedForecastIndex: 0,
      previewMode: 'hourly'
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData = async () => {
    const { handleError, current_organization_id } = this.props
    const params = { organization_id: current_organization_id }

    try {
      const requests = [
        apiService().get('api/v4/forecasts/daily', { params }),
        apiService().get('api/v4/forecasts/hourly', { params })
      ].map(request =>
        request.catch((error) => {
          handleError(error)

          return { data: { forecast_points: [], descriptions: [], trial: {} } }
        }))

      await Promise.all(requests).then(([day, hour]) => {
        const dailyData = day.data.forecast_points
        const dailyDescriptions = day.data.descriptions
        const hourlyData = hour.data.forecast_points ? hour.data.forecast_points : null
        const hourlyDescriptions = hour.data.descriptions

        const forecastList = dailyData.map((forecast, index) => ({
          name: forecast.name || I18n.t('v4/forecast.configuration.configure'),
          index
        }))

        this.setState({
          dailyData,
          dailyDescriptions,
          hourlyData,
          hourlyDescriptions,
          forecastList,
          loading: false
        })
      })
    } catch (error) {
      handleError(error)

      this.setState({
        dailyData: [],
        dailyDescriptions: [],
        hourlyData: [],
        hourlyDescriptions: [],
        loading: false
      })
    }
  }

  handleSelectedPreviewMode = (previewMode) =>
    this.setState({ previewMode: getPreviewMode(previewMode) })

  handleSelectedForecastPoint = (index) => {
    const { dailyData, previewMode } = this.state

    previewMode !== 'hourly' && this.forecastCardReference.handleForecastChange(dailyData[index])

    this.setState({ selectedForecastIndex: index })
  }

  render() {
    const { classes, current_organization_id } = this.props
    const {
      dailyData,
      dailyDescriptions,
      forecastList,
      hourlyDescriptions,
      hourlyData,
      loading,
      previewMode,
      selectedForecastIndex,
    } = this.state

    return (
      loading
        ? (
          <div className={classes.Dashboard__loading} >
            <CircularProgress size={80} />
          </div>
        )
        : (
          <Dashboard
            previewMode={previewMode}
            handleSelectedPreviewMode={this.handleSelectedPreviewMode}
            forecastList={forecastList}
            selectedForecastIndex={selectedForecastIndex}
            handleSelectedForecastPoint={this.handleSelectedForecastPoint}
            descriptions={hourlyDescriptions}
            hourlyData={hourlyData}
            dailyData={dailyData}
            organizationId={current_organization_id}
            onRef={(ref) => this.forecastCardReference = ref}
            dailyDescriptions={dailyDescriptions}
          />
        )
    )
  }
}

Index.defaultProps = {
  model: {
    data: []
  }
}

Index.propTypes = {
  model: PropTypes.object
}

export default withStyles(styles)(Index)
