import React from 'react'
import { NavBar } from '@ui/NavBar'
import withStyles from '@material-ui/core/styles/withStyles'

const NavBarPreview = (props) =>
  <NavBar
    {...props}
  />

const navBarStyles = (theme) => ({
  container: {
    display: 'flex',
    position: 'unset',
    pointerEvents: 'none'
  },
  menu: {
    backgroundColor: theme.menus.background,
    display: 'flex',
    justifyContent: 'space-between'
  },
  menu__logoLink: {
    textDecoration: 'none',
    width: 50,
    display: 'grid'
  },
  wrapper: {
    minHeight: '64px'
  },
  menu_floating: {
    backgroundColor: 'transparent',
    alignSelf: 'flex-end',
    top: theme.spacing.unit + 58,
    right: theme.spacing.unit,
    zIndex: 1000,
    position: 'absolute',
    pointerEvents: 'none'
  }
})

export default withStyles(navBarStyles)(NavBarPreview)
