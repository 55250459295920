export default (theme) => ({
  showPage__wrapper: {
    margin: '2px 3%',
    maxWidth: 700,
    padding: theme.spacing.unit
  },
  phenologicalStage__content: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  phenologicalStage__item: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '2px',
    width: '100%',
    fontSize: '0.8125rem'
  }
})
