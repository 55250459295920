import React from 'react'
import { Form as FinalForm, Field } from 'react-final-form'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'

import TextField from '@ui/Fields/TextField'

import styles from '@theme/styles/forms'

const Form = ({ handleSubmit, classes, onCancel, forecastPoint, initialValues }) => {
  return <FinalForm
    onSubmit={values => handleSubmit(values)}
    initialValues={initialValues}
    render={({ handleSubmit }) =>
      <form onSubmit={handleSubmit}>
        <main className={classes.form__fields}>
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <div className={classes.form__field__wrapper}>
                <Field
                  className={classes.form__fieldWithError}
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  label={I18n.t(`activerecord.attributes.v4/organization_products.forecast.forecast_points.name`)}
                  name='name'
                  initialValue={forecastPoint ? forecastPoint.name : ''}
                  render={({ initialValue, ...props }) =>
                    <TextField
                      {...props}
                    />
                  }
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className={classes.form__field__wrapper}>
                <Field
                  className={classes.form__fieldWithError}
                  fullWidth
                  type="number"
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  label={I18n.t(`activerecord.attributes.v4/organization_products.forecast.forecast_points.lat`)}
                  name='lat'
                  initialValue={forecastPoint ? forecastPoint.lat : ''}
                  render={({ initialValue, ...props }) =>
                    <TextField
                      {...props}
                    />
                  }
                />
              </div>
            </Grid>

            <Grid item xs={6}>
              <div className={classes.form__field__wrapper}>
                <Field
                  className={classes.form__fieldWithError}
                  type="number"
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  label={I18n.t(`activerecord.attributes.v4/organization_products.forecast.forecast_points.lon`)}
                  name='lon'
                  initialValue={forecastPoint ? forecastPoint.lon : ''}
                  render={({ initialValue, ...props }) =>
                    <TextField
                      {...props}
                    />
                  }
                />
              </div>
            </Grid>

          </Grid>
        </main>
        <footer className={classes.form__actions}>
          <Button
            className={classes.form__action}
            color='primary'
            type='submit'
            variant='contained'
          >
            {I18n.t('actions.add')}
          </Button>

          <Button
            className={classes.form__action}
            color='secondary'
            onClick={onCancel}
          >
            {I18n.t('actions.cancel')}
          </Button>
        </footer>
      </form>
    }
  />
}

export default withStyles(styles)(Form)
