import React, { useState } from 'react'

import { Typography, Button, CircularProgress } from '@material-ui/core'

import httpService from '@services/httpService'
import Check from '@ui/Icons/Check'

const ContentControl = ({ classes, email, handleError }) => {
  const [confirmed, setConfirmed] = useState(false)
  const [loading, setLoading] = useState(false)

  const sendEmail = async email => {
    setLoading(true)
    try {
      await httpService.post('/users/confirmation', { user: { email } })
      setConfirmed(true)
    } catch (err) {
      console.error(err)
      handleError(I18n.t('errors.default'))
    }
    setLoading(false)
  }

  return (
    confirmed
      ? (
        <div className={classes.DialogControl_ConfirmedMessageContainer}>
          <div className={classes.DialogControl_ConfirmedMessageTitleContainer}>
            <Typography className={classes.DialogControl_ConfirmedMessageTitle} variant='h6'>
              {I18n.t('v4/login.confirm_email_dialog.sent')}
            </Typography>
            <Check />
          </div>

          <Typography variant='body1'>
            {I18n.t('v4/login.confirm_email_dialog.follow_instructions')}
          </Typography>
        </div>
      )
      : (
        <>
          <Button
            variant='contained'
            color='primary'
            className={classes.DialogControl_Button}
            onClick={() => sendEmail(email)}
          >
            {I18n.t('v4/login.confirm_email_dialog.get_email')}
          </Button>
          
          {loading && <CircularProgress size={40} />}
        </>
      )
  )
}

export default ContentControl
