import React from 'react'

import { Button, Typography, withStyles } from '@material-ui/core'

import { useRouter } from '@contexts/RouterProvider'

const RainHistory = ({ classes, dateAndTime, equipmentId }) => {
  const router = useRouter()

  return (
    <>
      <Typography variant='body1' className={classes.RainHistory_info} >
        {`${I18n.t('v4/equipment.dashboard.last_update.info')} ${dateAndTime.infoDate} ${dateAndTime.infoHour}`}
      </Typography>

      <Button
        className={classes.RainHistory_action}
        color='primary'
        variant='outlined'
        onClick={() => router.visit(`/equipments/manual/${equipmentId}/measures`)}
        data-intercom-target='rain-history-manualraingauge'
      >
        {I18n.t('v4/equipment.pluviometer.actions.history')}
      </Button>
    </>
  )
}

const Styles = theme => ({
  RainHistory_info: {
    fontSize: '13px'
  },
  RainHistory_action: {
    width: '100%',
    marginTop: theme.spacing.unit
  }
})

export default withStyles(Styles)(RainHistory)
