import React, {Component} from 'react'

import { Button, Paper, Typography, withStyles } from '@material-ui/core'

import { Field } from 'react-final-form'

import MarkdownEditor from '@ui/MarkdownEditor/Index'
import TextField from '@ui/Fields/TextField'

import styles from '@theme/styles/forms'

const Form = ({
  handleSubmit,
  classes,
  title,
  data,
  o
}) =>
  <form className={classes.form} onSubmit={handleSubmit} data-testid='privacy-policies-form'>
    <Paper className={classes.form__wrapper} elevation={0}>
      <header className={classes.form__header}>
        <Typography component='h2' variant='h5'>
          {title}
        </Typography>
      </header>
      <main className={classes.form__fields}>
        <div className={classes.form__field__wrapper}>
          <Field
            component={TextField}
            className={classes.form__fieldWithError}
            margin='normal'
            fullWidth
            InputLabelProps={{ shrink: true }}
            variant='outlined'
            label={I18n.t('v4/terms.fields.name')}
            name='name'
            required
          />
        </div>
        <div className={classes.form__field__wrapper}>
          <Field
            component={MarkdownEditor}
            className={classes.form__fieldWithError}
            margin='normal'
            fullWidth
            InputLabelProps={{ shrink: true }}
            variant='outlined'
            label={I18n.t('v4/terms.fields.description_pt')}
            name='description_pt'
            readOnly={data.activated_at !== undefined}
            required
          />
        </div>

        <div className={classes.form__field__wrapper}>
          <Field
            component={MarkdownEditor}
            className={classes.form__fieldWithError}
            margin='normal'
            fullWidth
            InputLabelProps={{ shrink: true }}
            variant='outlined'
            label={I18n.t('v4/terms.fields.description_en')}
            name='description_en'
            readOnly={!_.isEmpty(data) && data.activated_at !== undefined}
            required
          />
        </div>

        <div className={classes.form__field__wrapper}>
          <Field
            component={MarkdownEditor}
            className={classes.form__fieldWithError}
            margin='normal'
            fullWidth
            InputLabelProps={{ shrink: true }}
            variant='outlined'
            label={I18n.t('v4/terms.fields.description_es')}
            name='description_es'
            readOnly={!_.isEmpty(data) && data.activated_at !== undefined}
            required
          />
        </div>
      </main>
      <footer className={classes.form__actions}>
        <Button
          className={classes.form__action}
          color='primary'
          type='submit'
          variant='contained'
        >
          {title}
        </Button>

        <Button
          className={classes.form__action}
          color='secondary'
          href={`/v4/o/${o}/admin/privacy_policies`}
        >
          {I18n.t('actions.back')}
        </Button>
      </footer>
    </Paper>
  </form>

export default withStyles(styles)(Form)
