import React, { useEffect, useRef } from 'react'
import Downshift from 'downshift'

import withStyles from '@material-ui/core/styles/withStyles'
import {
  Clear as ClearIcon,
  LandscapeRounded as AreaIcon,
  PlaceRounded as MapIcon,
  Search as SearchIcon,
  SettingsRemoteRounded as EquipmentIcon,
  WbSunnyRounded as SunnyIcon
} from '@material-ui/icons'
import {
  CircularProgress,
  Divider,
  IconButton,
  InputAdornment,
  InputBase,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Typography
} from '@material-ui/core'

import NoResultsPin from '@images/no_results_pin.svg'
import LoadingComponent from '@ui/LoadingComponent'
import { useMenusStatus, MENUS } from '@contexts/MenusStatusProvider'
import { useSessionData } from '@contexts/SessionDataProvider'

const NoResultsMessage = ({ classes }) =>
  <div className={classes.Search_noResults}>
    <Typography
      paragraph
      variant='subtitle1'
      data-testid='no-results-map-search'
      className={classes.Search_noResults_head}
    >
      <img
        className={classes.Search_noResults_pin}
        src={NoResultsPin}
        alt='No results pin'
      />
      {I18n.t('geocoder.no_results')}
    </Typography>
    <Typography paragraph variant='subtitle1' className={classes.Search_noResults_body}>
      <span style={{ display: 'block', lineHeight: 'initial' }}>
        {I18n.t('geocoder.formats_text')}
      </span>
      <span>{I18n.t('geocoder.helper_text')}</span>
    </Typography>
    <Typography paragraph variant='subtitle1' className={classes.Search_noResults_body}>
      {I18n.t('geocoder.lat_long_text')}
    </Typography>
    <Typography paragraph variant='subtitle1' className={classes.Search_noResults_body}>
      {I18n.t('geocoder.degrees_text', { coordinate: `22º50'48.041"S 47°5'3.818"W` })}
    </Typography>
  </div>

const Loading = ({ classes }) =>
  <MenuItem className={classes.Search_loadingWrapper}>
    <LoadingComponent
      className={classes.Search_loading}
      disableShrink
      size={24}
      thickness={4}
    />
  </MenuItem>

const useFocus = () => {
  const htmlElRef = useRef(null)
  const setFocus = () => { htmlElRef.current && htmlElRef.current.focus() }

  return [htmlElRef, setFocus]
}

const Search = ({
  classes,
  clearSearch,
  suggestions,
  onSearchChange,
  anchor,
  onDownshiftChange,
  loading,
  onSearchClick,
  isTrial
}) => {
  const { menus, toggleMenu } = useMenusStatus()
  const { open } = menus[MENUS.weatherForecastSetupFixedModal]
  const { step } = menus[MENUS.weatherForecastProduct]
  const [inputRef, setInputFocus] = useFocus()
  const { currentTenant } = useSessionData()

  useEffect(() => {
    open && step === 2 && setInputFocus()
  })

  const getIcon = (classes, type) => {
    switch (type) {
      case 'wb_sunny':
        return <SunnyIcon className={classes.Search_icon} />
      case 'settings_remote':
        return <EquipmentIcon className={classes.Search_icon} />
      case 'landscape':
        return <AreaIcon className={classes.Search_icon} />
      default:
        return <MapIcon className={classes.Search_icon} />
    }
  }

  const searchFirstItem = (setState, suggestions) => {
    if (!_.isEmpty(suggestions)) {
      const value = suggestions[0].name || suggestions[0].description
      setState({ isOpen: false, inputValue: value })
      onSearchClick(value)
    }
  }

  const renderInput = (inputProps) => {
    const {
      InputProps,
      clearSelection,
      classes,
      ref,
      selectedItem,
      setState,
      ...props
    } = inputProps

    return (
      <InputBase
        inputRef={inputRef}
        className={classes.Search_input}
        inputProps={{
          className: classes.Search_inputBase,
          ...InputProps,
        }}
        {...props}
        endAdornment={
          <InputAdornment position='end'>
            {
              !_.isEmpty(InputProps.value) &&
              <IconButton
                color='primary'
                className={classes.Search_iconButton}
                onClick={() => {
                  clearSearch()
                  clearSelection()
                }}
              >
                <ClearIcon />
              </IconButton>
            }
            <Divider className={classes.Search_divider} />
            <IconButton
              className={classes.Search_iconButton}
              aria-label='Search'
              aria-owns={open ? 'suggestions-search-list' : undefined}
              disabled={loading || _.isEmpty(suggestions)}
              onClick={() => searchFirstItem(setState, suggestions)}
            >
              <SearchIcon />
            </IconButton>
          </InputAdornment>
        }
      />
    )
  }

  const renderSuggestion = ({
    suggestion,
    index,
    classes,
    itemProps,
    highlightedIndex,
    selectedItem
  }) => {
    const isHighlighted = highlightedIndex === index
    const label = suggestion.name || suggestion.description
    const isSelected = (selectedItem || '').indexOf(label) > -1;

    return (
      <MenuItem
        {...itemProps}
        selected={isHighlighted}
        key={`suggestion-item-${index}`}
      >
        {getIcon(classes, suggestion.iconName)}
        <span className={classes.Search_suggestion}>
          {suggestion.description || suggestion.name}
        </span>
      </MenuItem>
    )
  }

  return (
    <Downshift id='map-search-autocomplete'
      onChange={selection => !!selection && onDownshiftChange(selection)}
      itemToString={item => (item ? item.description || item.name : '')}
      onInputValueChange={value => onSearchChange(value)}
      onStateChange={state => state.isOpen && toggleMenu(MENUS.searchMenu, true)}
    >
      {({
        getInputProps,
        getItemProps,
        getMenuProps,
        isOpen,
        inputValue,
        highlightedIndex,
        selectedItem,
        getRootProps,
        clearSelection,
        clearItems,
        setState
      }) => (
          <div className={isTrial ? classes.Search_wrapperTrial : classes.Search_wrapper}>
            <Paper className={classes.root} elevation={1}>
              <img src={currentTenant.smallLogoUrl} className={classes.Search_orgIcon} />
              {renderInput({
                fullWidth: true,
                clearSelection,
                classes,
                selectedItem,
                setState,
                InputProps: getInputProps({
                  placeholder: I18n.t('geocoder.search'),
                  value: inputValue
                }),
                ref: node => {
                  anchor = node
                },
              })}
            </Paper>
            <Popper open={isOpen} anchorEl={anchor} disablePortal className={classes.Search_suggestionsList}>
              <div {...(isOpen ? getMenuProps({}, { suppressRefError: true }) : {})}>
                <Paper square style={{ marginTop: -3, width: anchor ? anchor.clientWidth : null }}>
                  {
                    (!_.isEmpty(inputValue) && _.isEmpty(suggestions))
                      ? loading
                        ? <Loading classes={classes} />
                        : <NoResultsMessage classes={classes} />
                      : suggestions
                        .map((item, index) =>
                          <MenuItem
                            selected={highlightedIndex === index}
                            {...getItemProps({
                              key: item.name || item.description,
                              index,
                              item
                            })}
                          >
                            {getIcon(classes, item.iconName)}
                            <span className={classes.Search_suggestion}>
                              {item.description || item.name}
                            </span>
                          </MenuItem>
                        )
                  }
                </Paper>
              </div>
            </Popper>
          </div>
        )}
    </Downshift>
  )
}

const styles = (theme) => ({
  root: {
    padding: 4,
    display: 'flex',
    alignItems: 'center'
  },
  Search_input: {
    marginLeft: theme.spacing.unit,
    flex: 1,
  },
  Search_orgIcon: {
    height: theme.spacing.unit * 4
  },
  Search_iconButton: {
    padding: theme.spacing.unit
  },
  Search_divider: {
    width: 1,
    height: 28,
    margin: theme.spacing.unit,
  },
  Search_wrapper: {
    position: 'fixed',
    zIndex: 1050,
    top: 70,
    left: 20,
    right: 20,
    '@media(min-width: 680px)': {
      top: 11,
      left: 64,
      width: 350
    }
  },
  Search_suggestionsList: {
    width: '100%'
  },
  Search_icon: {
    marginRight: theme.spacing.unit * 2,
    color: theme.palette.gray.dark
  },
  Search_suggestion: {
    textOverflow: 'ellipsis',
    overflow: 'hidden'
  },
  Search_loadingWrapper: {
    justifyContent: 'center'
  },
  Search_loading: {
    animationDuration: '550ms'
  },
  Search_noResults: {
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    cursor: 'default'
  },
  Search_noResults_head: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  Search_noResults_body: {
    textAlign: 'center',
    fontSize: 14,
    color: theme.palette.gray.dark,
    marginBottom: theme.spacing.unit
  },
  Search_noResults_pin: {
    width: 25,
    marginRight: theme.spacing.unit
  },
  Search_wrapperTrial: {
    position: 'fixed',
    zIndex: 1050,
    top: 120,
    left: 20,
    right: 20,
    '@media(min-width: 680px)': {
      top: 64,
      left: 64,
      width: 350
    }
  },
})

export default withStyles(styles)(Search)
