import React from 'react'
import PropTypes from 'prop-types'

import { Field } from 'react-final-form'
import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import withStyles from '@material-ui/core/styles/withStyles'
import TextField from '../../ui/Fields/TextField'
import Select from '../../ui/Fields/Select'
import styles from '@styles/forms'

const Form = props => {
  const {
    action,
    classes,
    handleSubmit,
    selectValues: { ownershipTypes, implementTypes },
    o
  } = props

  const currentYear = new Date().getFullYear()

  return (
    <form className={classes.form} onSubmit={handleSubmit} data-testid="implement-equipment-form">
      <main className={classes.form__fields}>
        <Grid container spacing={24}>
          <Grid item xs={8}>
            <div className={classes.form__field__wrapper}>
              <Field
                className={classes.form__fieldWithError}
                component={TextField}
                fullWidth
                InputLabelProps={{ shrink: true }}
                label={I18n.t('v4/equipment.attributes.name')}
                margin="normal"
                name="equipment[name]"
                required
                variant="outlined"
              />
            </div>
          </Grid>

          <Grid item xs={4}>
            <div className={classes.form__field__wrapper}>
              <Field
                className={classes.form__fieldWithError}
                component={TextField}
                fullWidth
                InputLabelProps={{ shrink: true }}
                label={I18n.t('v4/equipment.attributes.year')}
                margin="normal"
                name="equipment[year]"
                type="number"
                inputProps={{ min: '1900', max: currentYear }}
                variant="outlined"
                required
              />
            </div>
          </Grid>

          <Grid item xs={6}>
            <div className={classes.form__field__wrapper}>
              <Field
                className={classes.form__fieldWithError}
                component={Select}
                data={implementTypes}
                fields={{ id: 'id', name: 'name' }}
                fullWidth
                margin="normal"
                InputLabelProps={{ shrink: true }}
                label={I18n.t('v4/equipment.attributes.type')}
                name="equipment[equipment_type]"
                required
                variant="outlined"
              />
            </div>
          </Grid>

          <Grid item xs={6}>
            <div className={classes.form__field__wrapper}>
              <Field
                className={classes.form__fieldWithError}
                component={Select}
                data={ownershipTypes}
                fields={{ id: 'id', name: 'name' }}
                fullWidth
                InputLabelProps={{ shrink: true }}
                label={I18n.t('v4/equipment.attributes.ownership')}
                name="equipment[ownership]"
                required
                margin="normal"
                variant="outlined"
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={classes.form__field__wrapper}>
              <Field
                className={classes.form__fieldWithError}
                component={TextField}
                fullWidth
                InputLabelProps={{ shrink: true }}
                label={I18n.t('v4/equipment.attributes.detail')}
                margin="normal"
                name="equipment[detail]"
                multiline
                variant="outlined"
              />
            </div>
          </Grid>
        </Grid>
      </main>

      <footer className={classes.form__actions}>
        <Button
          className={classes.form__action}
          color='primary'
          type='submit'
          variant='contained'
        >
          {`${action} ${I18n.t('v4/equipment.kinds.implement')}`}
        </Button>

        <Button
          className={classes.form__action}
          color='secondary'
          href={`/v4/o/${o}/equipments/implement`}
        >
          {I18n.t('actions.back')}
        </Button>
      </footer>
    </form>
  )
}

Form.propTypes = {
  action: PropTypes.string,
  selectValues: PropTypes.shape({
    ownershipTypes: PropTypes.array,
    implementTypes: PropTypes.array
  })
}

export default withStyles(styles)(Form)
