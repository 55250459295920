import { Typography, Paper } from '@material-ui/core'

import React from 'react'
import styles from './styles'
import withStyles from '@material-ui/core/styles/withStyles'

const DataSummaryCard = ({ title, data, classes, emptyDataMessage }) =>
  <Paper className={classes.Data_summary_card}>
    <Typography variant='body2' className={classes.Data_summary_card__title}>
      {title}
    </Typography>
    {
      _.isEmpty(data)
        ? <Typography variant='body1' align='center'>
          {emptyDataMessage}
        </Typography>
        : data.map(({ title, value, unit }) =>
          <span key={title} className={classes.Title_value_wrapper}>
            <Typography variant='body1'>
              {title}
            </Typography>

            {
              value != null
                ? unit === ''
                  ? <Typography variant='body2'>{value}</Typography>
                  : <Typography variant='body2'>{_.round(value, 1)} {unit}</Typography>
                : '---'
            }
          </span>
        )
    }
  </Paper>

export default withStyles(styles)(DataSummaryCard)
