import React from "react";
import PropTypes from "prop-types";
import { Form as FinalForm } from "react-final-form";
import { RailsForm } from "@support/RailsForm";
import MaterialTable from "@ui/Table";
import ReactDragListView from "react-drag-listview/lib/index.js";
import Form from "./Form";
import Dialog from "../../ui/Dialog";
import DeleteDialog from "../../ui/DeleteDialog";
import ShowStage from "./Show";
import {
  getPhenologicalStagesHeaders,
  getStageStatuses,
  validate,
} from "@models/phenologicalStages";
import { Typography } from "@material-ui/core";

class StagesList extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      stages: [],
      dialog: {
        open: false,
        title: "",
        values: {},
        handler: () => {},
      },
      isDeleteDialogOpen: false,
      selectedStage: null,
    };

    this.handleDialogToggle = this.handleDialogToggle.bind(this);
    this.handleNewPhenologicalStage =
      this.handleNewPhenologicalStage.bind(this);
    this.handlePhenologicalStageEdit =
      this.handlePhenologicalStageEdit.bind(this);
    this.handleDeleteDialogToggle = this.handleDeleteDialogToggle.bind(this);
  }

  componentDidMount() {
    if (this.props.stages) {
      this.setState({ stages: this.props.stages });
    }
  }

  handleDeleteDialogToggle(id) {
    this.setState({
      isDeleteDialogOpen: !this.state.isDeleteDialogOpen,
      selectedStage: id,
    });
  }

  handleDialogToggle() {
    this.setState({
      dialog: {
        ...this.state.dialog,
        open: !this.state.dialog.open,
      },
    });
  }

  handleNewPhenologicalStage() {
    const { handlers } = this.props;

    this.setState({
      dialog: {
        open: true,
        title: I18n.t("v4/culture.phenological_stages.actions.add"),
        handler: (values) => {
          this.handleDialogToggle();
          handlers.new(values);
        },
        values: { order: this.props.stages.length + 1 },
      },
    });
  }

  handlePhenologicalStageEdit(stage) {
    const { handlers } = this.props;

    this.setState({
      dialog: {
        open: true,
        title: I18n.t("v4/culture.phenological_stages.actions.edit"),
        handler: (values) => {
          this.handleDialogToggle();
          handlers.edit(values);
        },
        values: stage,
      },
    });
  }

  render() {
    const { stages, handlers } = this.props;
    return (
      <React.Fragment>
        {stages.length === 0 && (
          <Typography
            variant="caption"
            style={{ color: "red", marginBottom: "10px" }}
          >
            {I18n.t("v4/culture.phenological_stages.mandatory")}
          </Typography>
        )}
        <ReactDragListView onDragEnd={handlers.changeOrder} handleSelector="td">
          <MaterialTable
            tableKey="cultures-stages"
            columns={getPhenologicalStagesHeaders()}
            detailPanel={(stage) => <ShowStage phenologicalStage={stage} />}
            data={stages}
            title={I18n.t("v4/culture.phenological_stages.index")}
            actions={[
              {
                icon: "add",
                tooltip: I18n.t("actions.add"),
                isFreeAction: true,
                onClick: this.handleNewPhenologicalStage,
              },
              (rowData) => ({
                icon: "create",
                iconProps: { "data-testid": `edit-${rowData.id}` },
                tooltip: I18n.t("actions.edit"),
                onClick: () => this.handlePhenologicalStageEdit(rowData),
              }),
              (rowData) => ({
                icon: "delete",
                iconProps: { "data-testid": `delete-${rowData.id}` },
                tooltip: I18n.t("actions.remove"),
                onClick: () => this.handleDeleteDialogToggle(rowData.order),
              }),
            ]}
            localization={{
              body: {
                emptyDataSourceMessage: I18n.t("info.no_data"),
              },
            }}
            options={{
              search: false,
              paging: false,
              showEmptyDataSourceMessage: true,
              sorting: false,
              actionsColumnIndex: -1,
              headerStyle: {
                backgroundColor: "#f5f5f5",
              },
              rowStyle: {
                cursor: "move",
              },
            }}
          />
        </ReactDragListView>

        <Dialog
          open={this.state.dialog.open}
          onClose={this.handleDialogToggle}
          title={this.state.dialog.title}
        >
          <RailsForm
            component={FinalForm}
            handleSubmit={this.state.dialog.handler}
            initialValues={this.state.dialog.values}
            validate={validate}
            render={(props) => (
              <Form
                {...props}
                statuses={getStageStatuses()}
                onCancel={this.handleDialogToggle}
                action={this.state.dialog.title}
              />
            )}
          />
        </Dialog>

        <DeleteDialog
          dialogTitle={`${I18n.t("actions.remove")} ${I18n.t(
            "v4/culture.phenological_stages.one"
          )}?`}
          dialogText={I18n.t("confirmation.remove")}
          onCancel={this.handleDeleteDialogToggle}
          onClose={this.handleDeleteDialogToggle}
          handleDelete={() => {
            handlers.delete(this.state.selectedStage);
            this.handleDeleteDialogToggle();
          }}
          open={this.state.isDeleteDialogOpen}
        />
      </React.Fragment>
    );
  }
}

StagesList.defaultProps = {
  stages: [],
};

StagesList.propTypes = {
  stages: PropTypes.array,
};

export default StagesList;
