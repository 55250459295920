import React from 'react'
import { Field } from 'react-final-form'

import { Grid, Paper, Button, Typography, withStyles } from '@material-ui/core'

import {
  irrigationUnits as units,
  irrigationFieldComponent
} from '@models/equipment/irrigation'

import DatePicker from '@ui/Fields/DatePicker'
import TimePicker from '@ui/Fields/TimePicker'
import SelectField from '@ui/Fields/Select'
import TextField from '@ui/Fields/TextField'

import styles from '@theme/styles/forms'

import InformIrrigation from './InformIrrigation'

const EditForm = ({
  classes,
  equipment,
  handleSubmit,
  onCancel,
  o,
  area,
  values,
  loading
}) => {
  const irrigationSystemType = equipment.irrigation_system_type
  const operations = equipment.data
    ? equipment.data.operations
    : equipment.operations

  const getValues = () => ({
    startedAtState: values.started_at,
    irrigationState: values.irrigation,
    finishedAtState: values.finished_at,
    unitState: values.unit,
    customFieldState: values[irrigationSystemType === 'pivot' ? 'sensor_irrigated' : 'operations']
  })

  const fieldStates = getValues()

  const {
    irrigationComponent,
    irrigationProps
  } = irrigationFieldComponent(fieldStates.unitState)

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <Paper className={classes.form__wrapper} elevation={0}>
        {
          !!equipment && (
            <header className={classes.form__header}>
              <Typography component='h2' variant='h5'>
                {equipment.name ? `${equipment.name} - ` : ''}{I18n.t(`enumerize.v4/equipment/irrigation.irrigation_system_type.${equipment.irrigation_system_type}`)}
              </Typography>
            </header>
          )
        }
        <main className={classes.form__fields}>
          <header className={classes.form__header}>
            <Typography variant='h6'>
              {area.name}
            </Typography>
          </header>

          <div className={classes.form__fields}>
            <Grid container spacing={24}>
              <Grid item xs={12} sm={4}>
                <Field
                  component={DatePicker}
                  margin='normal'
                  InputLabelProps={{ shrink: true }}
                  variant='outlined'
                  label={I18n.t('v4/irrigation.area_fields.irrigation_date')}
                  name='irrigation_date'
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <Field
                  component={TimePicker}
                  margin='normal'
                  InputLabelProps={{ shrink: true }}
                  variant='outlined'
                  label={I18n.t('v4/irrigation.area_fields.started_at')}
                  name='started_at'
                  required
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <Field
                  component={TimePicker}
                  margin='normal'
                  InputLabelProps={{ shrink: true }}
                  variant='outlined'
                  label={I18n.t('v4/irrigation.area_fields.finished_at')}
                  name='finished_at'
                  fullWidth
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <Field
                  component={SelectField}
                  margin='normal'
                  InputLabelProps={{ shrink: true }}
                  variant='outlined'
                  label={I18n.t('v4/irrigation.area_fields.unit')}
                  name='unit'
                  fields={{ name: 'name', id: 'id' }}
                  data={units(equipment.irrigation_system_type)}
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                <Field
                  component={irrigationComponent || TextField}
                  margin='normal'
                  InputLabelProps={{ shrink: true }}
                  variant='outlined'
                  label={I18n.t('v4/irrigation.area_fields.irrigation')}
                  name='irrigation'
                  {...irrigationProps}
                  fullWidth
                  required
                />
              </Grid>

              <Grid item xs={12} sm={4}>
                {
                  irrigationSystemType === 'pivot'
                    ? (
                      <Field
                        component={SelectField}
                        margin='normal'
                        InputLabelProps={{ shrink: true }}
                        variant='outlined'
                        label={I18n.t('v4/irrigation.area_fields.sensor_irrigated')}
                        name='sensor_irrigated'
                        fields={{ name: 'name', id: 'id' }}
                        data={[
                          { id: true, name: I18n.t('true') },
                          { id: false, name: I18n.t('false') }
                        ]}
                        fullWidth
                        required
                      />
                    )
                    : (
                      <Field
                        component={TextField}
                        margin='normal'
                        InputLabelProps={{ shrink: true }}
                        variant='outlined'
                        label={I18n.t('v4/irrigation.area_fields.operations')}
                        name='operations'
                        {...irrigationProps}
                        inputProps={{ min: '0', max: operations }}
                        initialValue={1}
                        type='number'
                        fullWidth
                        required
                      />
                    )
                }
              </Grid>

              <InformIrrigation
                fieldStates={fieldStates}
                irrigationSystem={equipment}
              />

            </Grid>
          </div>
        </main>
        <footer className={classes.form__actions}>
          <Button
            className={classes.form__action}
            color='primary'
            type='submit'
            variant='contained'
            disabled={loading}
          >
            {I18n.t('v4/irrigation.actions.save')}
          </Button>
          <Button
            className={classes.form__action}
            disabled={loading}
            onClick={() => onCancel
              ? onCancel()
              : Turbolinks.visit(`/v4/o/${o}/equipments/irrigation/${equipment.id}/irrigations`)
            }
            color='secondary'
          >
            {I18n.t('v4/irrigation.actions.cancel')}
          </Button>
        </footer>
      </Paper>
    </form>
  )
}

export default withStyles(styles)(EditForm)
