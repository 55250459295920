export default theme => ({
  DialogContent: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    padding: `0 ${theme.spacing.unit * 3}px`
  },
  DialogContent_ConfirmEmail: {
    width: '50%',
    margin: `${theme.spacing.unit * 3.75}px 0`
  },
  DialogContent_BottomText: {
    color: theme.palette.gray.dark,
    textAlign: 'center'
  },
  DialogControl_Button: {
    margin: `${theme.spacing.unit * 3.75}px 0`,
    padding: `${theme.spacing.unit * 2.5}px ${theme.spacing.unit * 5}px`
  },
  DialogControl_ConfirmedMessageContainer: {
    margin: `${theme.spacing.unit * 3.75}px 0`,
    padding: `${theme.spacing.unit * 2.5}px ${theme.spacing.unit * 5}px`,
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center'
  },
  DialogControl_ConfirmedMessageTitleContainer: {
    display: 'flex'
  },
  DialogControl_ConfirmedMessageTitle: {
    marginRight: theme.spacing.unit,
    color: theme.palette.primary.main
  }
})