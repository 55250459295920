import React from 'react'
import { Form as FinalForm } from 'react-final-form'

import { RailsForm } from '@support/RailsForm'
import Form from './Form'

export const IrrigationSystemConfiguration = ({ model, o }) =>
  <RailsForm
    component={FinalForm}
    action='patch'
    url={`/v4/o/${o}/admin/organizations/${model.organization_id}/products/${model.id}/irrigation_management`}
    successUrl={`/v4/o/${o}/admin/organizations/${model.organization_id}/products`}
    initialValues={model.metadata}
    render={(props) => (
      <Form
        {...props}
        organization_id={model.organization_id}
        title={I18n.t('v4/irrigation_system.configuration.title')}
        o={o}
      />
    )}
  />

IrrigationSystemConfiguration.defaultProps = {
  model: {
    metadata: {}
  }
}

export default IrrigationSystemConfiguration
