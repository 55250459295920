import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import { Field } from 'react-final-form'
import { InputAdornment, IconButton } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import ControlledFormField from '@ui/Fields/ControlledFormField'
import withStyles from '@material-ui/core/styles/withStyles'
import TextField from '@ui/Fields/TextField'
import Select from '@ui/Fields/Select'
import { irrigation_limitations, flow_units } from '@models/equipment/irrigation_system_bounded'
import { includesInfo } from '@models/equipment/irrigation'
import styles from '@styles/forms'

const Form = ({ classes, manufacturers, values }) => {
  values.form_type = 'bounded'

  const [isSelect, setSelect] = React.useState(
    !values.equipment.manufacturer || includesInfo(manufacturers, 'name', values.equipment.manufacturer)
  )

  return <Fragment>
    <div className={classes.form__field__wrapper}>
      <Field
        component={Select}
        className={classes.form__fieldWithError}
        InputLabelProps={{ shrink: true, required: true }}
        label={I18n.t('v4/irrigation_system.bounded.irrigation_limitation')}
        name='equipment[irrigation_limitation]'
        margin='normal'
        fields={{ id: 'id', name: 'name' }}
        data={irrigation_limitations}
        fullWidth
        variant='outlined'
        required
      />
    </div>

    <Fragment>
      {
        values.equipment.irrigation_limitation &&
        <div className={classes.form__field__wrapper}>
          <Field
            component={TextField}
            className={classes.form__fieldWithError}
            InputLabelProps={{ shrink: true }}
            label={I18n.t(`v4/irrigation_system.bounded.irrigation_limit_value_label.${values.equipment.irrigation_limitation}`)}
            name='equipment[irrigation_limit_value]'
            margin='normal'
            type='number'
            title={I18n.t('validations.type_a_number')}
            fullWidth
            inputProps={{ step: '0.01', min: '0', max: '100' }}
            variant='outlined'
            required
          />
        </div>
      }
      <div className={classes.form__field__wrapper}>
        <ControlledFormField
          component={Select}
          select={isSelect}
          className={classes.form__fieldWithError}
          InputLabelProps={{ shrink: true, required: true }}
          label={I18n.t('v4/irrigation_system.bounded.manufacturer')}
          name='equipment[manufacturer]'
          margin='normal'
          fields={{ id: 'name', name: 'name' }}
          data={manufacturers}
          fullWidth
          InputProps={{
            endAdornment: !isSelect &&
              <InputAdornment position='end'>
                <IconButton
                  onClick={() => {
                    values.equipment.manufacturer = ''
                    setSelect(true)
                  }}
                >
                  <Close />
                </IconButton>
              </InputAdornment>
          }}
          variant='outlined'
          required
        />
      </div>

      <div className={classes.form__field__wrapper}>
        <Field
          component={TextField}
          className={classes.form__fieldWithError}
          InputLabelProps={{ shrink: true }}
          label={I18n.t('v4/irrigation_system.bounded.operations')}
          info={I18n.t('v4/irrigation_system.bounded.operations_info')}
          name='equipment[operations]'
          margin='normal'
          type='number'
          title={I18n.t('validations.type_a_number')}
          fullWidth
          inputProps={{ min: '0', max: '100' }}
          required
          variant='outlined'
        />
      </div>

      <div className={classes.form__field__wrapper}>
        <Field
          component={Select}
          className={classes.form__fieldWithError}
          InputLabelProps={{ shrink: true, required: true }}
          label={I18n.t('v4/irrigation_system.bounded.flow_unit')}
          name='equipment[flow_unit]'
          margin='normal'
          fields={{ id: 'id', name: 'name' }}
          data={flow_units}
          fullWidth
          variant='outlined'
          required
        />
      </div>

      <div className={classes.form__field__wrapper}>
        <Field
          component={TextField}
          className={classes.form__fieldWithError}
          InputLabelProps={{ shrink: true }}
          label={
            `${I18n.t('v4/irrigation_system.bounded.emitters_flow_rate')}
                ${values.equipment.flow_unit ? `(${values.equipment.flow_unit})` : ''}
              `
          }
          name='equipment[emitters_flow_rate]'
          margin='normal'
          type='number'
          title={I18n.t('validations.type_a_number')}
          fullWidth
          inputProps={{ step: '0.01', min: '0', max: '500' }}
          variant='outlined'
          required
        />
      </div>

      <div className={classes.form__field__wrapper}>
        <Field
          component={TextField}
          className={classes.form__fieldWithError}
          InputLabelProps={{ shrink: true }}
          label={`${I18n.t('v4/irrigation_system.bounded.spacing_between_plants')} (m)`}
          name='equipment[spacing_between_plants]'
          margin='normal'
          type='number'
          title={I18n.t('validations.type_a_number')}
          fullWidth
          inputProps={{ step: '0.01', min: '0', max: '50' }}
          variant='outlined'
          required={values.equipment.flow_unit === 'l/h'}
        />
      </div>

      <div className={classes.form__field__wrapper}>
        <Field
          component={TextField}
          className={classes.form__fieldWithError}
          InputLabelProps={{ shrink: true }}
          label={`${I18n.t('v4/irrigation_system.bounded.spacing_between_lanes')} (m)`}
          name='equipment[spacing_between_lanes]'
          margin='normal'
          type='number'
          title={I18n.t('validations.type_a_number')}
          fullWidth
          inputProps={{ step: '0.01', min: '0', max: '50' }}
          variant='outlined'
          required={values.equipment.flow_unit === 'l/h'}
        />
      </div>

      <div className={classes.form__field__wrapper}>
        <Field
          component={TextField}
          className={classes.form__fieldWithError}
          InputLabelProps={{ shrink: true }}
          label={I18n.t('v4/irrigation_system.bounded.emitters_per_plant')}
          name='equipment[emitters_per_plant]'
          margin='normal'
          type='number'
          title={I18n.t('validations.type_a_number')}
          fullWidth
          inputProps={{ step: '0.01', min: '0', max: '10' }}
          variant='outlined'
          required={values.equipment.flow_unit === 'l/h'}
        />
      </div>

      <div className={classes.form__field__wrapper}>
        <Field
          component={TextField}
          className={classes.form__fieldWithError}
          InputLabelProps={{ shrink: true }}
          label={`${I18n.t('v4/irrigation_system.bounded.percentage_irrigated_areas')} (%)`}
          name='equipment[percentage_irrigated_areas]'
          margin='normal'
          type='number'
          title={I18n.t('validations.type_a_number')}
          fullWidth
          inputProps={{ step: '0.01', min: '0', max: '100' }}
          variant='outlined'
          required
        />
      </div>
    </Fragment>
  </Fragment>
}

Form.propTypes = {
  action: PropTypes.string
}

Form.defaultProps = {
  manufacturers: []
}

export default withStyles(styles)(Form)
