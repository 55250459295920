import { parsePhoneNumberFromString } from 'libphonenumber-js'

export const fields = [
  'name', 'phone', 'forecast_point', 'notification_status'
]

export const getNotificationsHeaders = () =>
  fields.map(field => ({
    title: I18n.t(`activerecord.attributes.v4/organization_products.forecast.forecast_points.${field}`),
    field
  }))

export const deserialize = data => data.map((notification = []) => ({
  ...notification,
  name: notification.v4_notifications_contact ? notification.v4_notifications_contact.name : null,
  phone: notification.v4_notifications_contact ? notification.v4_notifications_contact.contact : null,
  forecast_point: notification.forecast_point ? notification.forecast_point.name : null,
  notification_status: (typeof notification.notifications_enabled === 'boolean' || typeof notification.notificationsEnabled === 'boolean') ?
    (notification.notifications_enabled !== undefined ? I18n.t(String(notification.notifications_enabled)) : I18n.t(String(notification.notificationsEnabled))) : null
}))

export const validate = values => {
  const fields = Object.keys(values)
  let errors = {}

  fields.forEach((field, index) => {
    errors[field] = errors[field] || {}

    if (values[field].notifications_enabled) {
      const phone = values[field]['contact'] && parsePhoneNumberFromString(values[field]['contact'])

      if (!values[field].name) {
        errors[field]['name'] = I18n.t('validations.required')
      }
      if (!values[field].contact) {
        errors[field]['contact'] = I18n.t('validations.required')
      }
      if (!values[field].forecast_point_id) {
        errors[field]['forecast_point_id'] = I18n.t('validations.required')
      }
      if (!phone || !phone.isValid()) {
        errors[field]['contact'] = I18n.t('validations.invalid_phone')
      }
    }
  })

  return errors
}
