import React, { Component } from 'react'

import {
  Button,
  Menu,
  MenuItem,
  Typography
} from '@material-ui/core'

import ArrowDropDown from '@material-ui/icons/ArrowDropDown'
import Forecast from './forecast'
import ForecastPointForm from './forecast-point-form'
import { deserializeData, serializeData, isSetup } from '@models/weather/forecast'

import withStyles from '@material-ui/core/styles/withStyles'
import styles from './styles'

export class Index extends Component {
  constructor(props) {
    super(props)

    this.state = {
      data: {
        cards: [],
        resume: {}
      },
      forecastList: [],
      selectedForecast: {},
      anchorEl: null,
    }
    this.forecastPointsFormRef = null
  }

  componentDidMount() {
    const { onRef, data } = this.props

    onRef && onRef(this)

    const forecastList = deserializeData(data.forecast_points || [])
    const { cards, resume, selectedForecast } = serializeData(forecastList)

    this.setState({
      cards,
      forecastList,
      resume,
      selectedForecast
    })
  }

  handleForecastChange = (forecast) => {
    const { forecastList } = this.state
    const { onForecastChange } = this.props
    const { cards, resume, selectedForecast } = serializeData(forecastList, forecast)

    this.setState({
      cards,
      resume,
      selectedForecast,
      anchorEl: null
    }, () => {
      if (isSetup(selectedForecast)) {
        onForecastChange
          ? onForecastChange([selectedForecast.lat, selectedForecast.lon])
          : {}
      }
    })
  }

  handleForecastUpdate = (updatedForecast) => {
    const { forecastList } = this.state

    this.setState({
      forecastList: forecastList.map(forecast => {
        if (forecast.id === updatedForecast.id) return updatedForecast

        return forecast
      })
    }, () => this.handleForecastChange(updatedForecast))
  }

  handleToggle = (event) => {
    this.setState({ anchorEl: event.currentTarget })
  }

  handleClose = () => {
    this.setState({ anchorEl: null })
  }

  setSelectedLatLng = ({ lat, lng }) => {
    this.forecastPointsFormRef && this.forecastPointsFormRef.setLatLng({ lat, lng })
  }

  render() {
    const { handleToggle, handleForecastChange, handleClose, handleForecastUpdate } = this
    const { classes, hasControl, handleError, organizationId } = this.props
    const { cards, resume, forecastList, selectedForecast, anchorEl, selectedLatLng } = this.state

    return (
      <div className={classes.Dashboard__data}>
        {
          hasControl &&
          <div>
            <Button
              aria-controls='forecasts-menu'
              aria-haspopup='true'
              onClick={handleToggle}
            >
              {selectedForecast.name}
              <ArrowDropDown />
            </Button>
            <Menu
              id='forecasts-menu'
              anchorEl={anchorEl}
              keepMounted
              open={Boolean(anchorEl)}
              onClose={handleClose}
            >
              {
                forecastList.map(forecast => (
                  <MenuItem
                    onClick={() => handleForecastChange(forecast)}
                    key={`forecast-option-${forecast.id}`}
                  >
                    {forecast.name}
                  </MenuItem>
                ))
              }
            </Menu>
          </div>
        }
        {
          _.isEmpty(selectedForecast)
            ? <Typography component='h3' variant='h5' className={classes.no__data}>
              {I18n.t('info.no_data')}
            </Typography>
            : isSetup(selectedForecast)
              ? _.isEmpty(cards)
                ? <Typography component='h3' variant='h5' className={classes.no__data}>
                  {I18n.t('v4/forecast.configuration.no_data_yet')}
                </Typography>
                : <Forecast
                  cards={cards}
                  resume={resume}
                />
              : <div className={classes.forecast_points__form}>
                <ForecastPointForm
                  onRef={ref => this.forecastPointsFormRef = ref}
                  handleError={handleError}
                  handleForecastUpdate={handleForecastUpdate}
                  forecastPoint={selectedForecast}
                  selectedLatLng={selectedLatLng}
                  organizationId={organizationId}
                />
              </div>
        }
      </div>
    )
  }
}

export default withStyles(styles)(Index)
