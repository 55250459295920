import React from 'react'
import { Typography } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const Card = ({ children, onClick, classes, title, style }) =>
  <div onClick={onClick} className={classes.Card} style={style}>
    <Typography variant='subtitle2' align='center'>
      {title}
    </Typography>

    {children}
  </div>

Card.defaultProps = {
  style: {}
}

const styles = theme => ({
  Card: {
    height: '100%',
    alignItems: 'center',
    backgroundColor: theme.palette.gray.light,
    flex: '1 1 0px',
    justifyContent: 'space-between',
    width: '100%',
    padding: theme.spacing.unit,
    '&:not(:last-child)': {
      marginRight: `${theme.spacing.unit}px`
    }
  }
})

export default withStyles(styles)(Card)
