import React from 'react'

import { Typography, withStyles } from '@material-ui/core'

import { getControlIcon, handleClickControlMenu } from '@models/map'

const Control = ({
  classes,
  intercomTag,
  handleClick,
  kind,
  name,
  value,
  useControl,
  onClose
}) =>
  <div
    className={classes.Control_wrapper}
    data-intercom-target={intercomTag}
    onClick={() => {
      onClose()

      !!useControl
        ? handleClickControlMenu(useControl)
        : handleClick()
    }}
  >
    <Typography
      variant='body1'
      className={classes.Control_name}
    >
      {name}
    </Typography>

    {
      getControlIcon(classes, value, kind)
    }
  </div>

const styles = theme => ({
  Control_wrapper: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    cursor: 'pointer',
    '&:not(:last-child)': {
      marginBottom: theme.spacing.unit
    }
  },
  Control_name: {
    marginRight: theme.spacing.unit
  },
  Control_icon: {
    width: '20px',
    heigth: '20px'
  }
})

export default withStyles(styles)(Control)
