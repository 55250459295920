import React from 'react'
import Dialog from './Dialog'
import { withStyles } from '@material-ui/core/styles'

const ConfirmDialog = ({ classes }) => {
  const [confirmDialogState, setDialogState] = React.useState({
    open: false,
    title: undefined,
    content: undefined,
    confirmText: undefined,
    cancelText: undefined,
    showCancelButton: true,
    handleConfirm: () => { },
    handleClose: () => { }
  })

  const closeDialog = () => {
    setDialogState({
      ...confirmDialogState,
      open: false
    })
  }

  const handleDialogCancel = () => {
    confirmDialogState.handleClose()

    closeDialog()
  }

  const handleDialogConfirm = () => {
    confirmDialogState.handleConfirm()

    closeDialog()
  }

  const confirmDialog = (onConfirm, options) => {
    setDialogState({
      ...confirmDialogState,
      handleConfirm: onConfirm || confirmDialogState.handleConfirm,
      ...options,
      open: true
    })
  }

  window.confirmDialog = confirmDialog

  return <Dialog
    {...confirmDialogState}
    classes={classes}
    onConfirm={handleDialogConfirm}
    onCancel={handleDialogCancel}
  />
}

const styles = () => ({
  Dialog__paper: {
    maxWidth: '620px'
  }
})

export default withStyles(styles)(ConfirmDialog)
