import React from 'react'
import PropTypes from 'prop-types'

import { Form as FinalForm } from 'react-final-form'
import { RailsForm } from '@support/RailsForm'

import Form, { validate } from './Form'

const New = ({ model, statuses, part_number_registries, o }) => {
  const {
    barcode,
    pin,
    status,
    v4_part_number_registry_id,
    notes1,
    notes2,
    firmware,
    communication_address,
    pcb_version
  } = model

  return (
    <RailsForm
      component={FinalForm}
      action='create'
      url={`/v4/o/${o}/admin/hardware_products`}
      validate={validate}
      initialValues={{ barcode, pin, status, v4_part_number_registry_id, notes1, notes2, firmware, communication_address, pcb_version }}
      render={(props) =>(
        <Form
          title={I18n.t('v4/hardware_products.new.title')}
          statuses={statuses}
          part_number_registries={part_number_registries}
          o={o}
          {...props}
        />
      )}
    />
  )
}

New.propTypes = {
  model: PropTypes.shape({
    pin: PropTypes.string,
    status: PropTypes.string
  }).isRequired,
  statuses: PropTypes.array.isRequired,
  part_number_registries: PropTypes.array.isRequired
}

export default New
