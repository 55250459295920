export default theme => ({
  showPage__action: {
    '&:not(:last-child)': {
      marginLeft: theme.spacing.unit * 2
    }
  },

  showPage__actions: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'row-reverse',
    justifyContent: 'flex-start',
    marginTop: theme.spacing.unit * 4
  },

  showPage__header: {
    marginBottom: theme.spacing.unit * 4
  },

  showPage__value: {
    '&:not(:last-child)': {
      marginBottom: theme.spacing.unit * 3
    }
  },

  showPage__wrapper: {
    backgroundColor: 'transparent',
    margin: `${theme.spacing.unit * 5}px auto`,
    maxWidth: 700,
    padding: theme.spacing.unit * 4
  }
  
})
