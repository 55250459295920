import React from 'react'

import { Button } from '@material-ui/core'
import { RailsForm } from '@support/RailsForm'
import { Form as FinalForm } from 'react-final-form'
import { validate } from '@models/account/billing_info'
import httpService from '@services/httpService'

import Form from '@components/v4/organizations/billing_info/Form'

import { withStyles } from '@material-ui/core/styles'

const PersonalDataForm = ({ model, classes, advanceStep, retreatStep, handleError, setPersonalData, o }) => {
  const { organization_id } = model
  const [loading, setLoading] = React.useState(false)

  const handleSubmit = async (values) => {
    try {
      setLoading(true)
      const { data } = await httpService.put(`/v4/o/${o}/organizations/${organization_id}/billing_info`, values)

      setPersonalData(data)
      setLoading(false)

      advanceStep()
    }
    catch (error) {
      handleError(error)

      setLoading(false)
    }
  }

  const deserializeModel = (modelObject = {}) =>
    Object.keys(modelObject).reduce((object, key) => {
      if (modelObject[key] == null) {
        object[key] = ''

        return object
      }

      object[key] = modelObject[key]

      return object
    }, {})

  const initialValues = deserializeModel(model)

  return (
    <RailsForm
      component={FinalForm}
      handleSubmit={handleSubmit}
      validate={validate}
      initialValues={initialValues}
      render={(props) => (
        <Form
          {...props}
          disableFields={loading}
          isOnCheckoutScreen
          checkoutScreenStyle={classes.PersonalData__Form}
          organization_id={organization_id}
          o={o}
          controller={<PersonalDataActions classes={classes} retreatStep={retreatStep} loading={loading} />}
        />
      )}
    />
  )
}

const PersonalDataActions = ({ classes, retreatStep, loading }) =>
  <div className={classes.PersonalData__Actions}>
    <Button
      className={classes.PersonalData__Action}
      disabled={!!loading}
      onClick={retreatStep}
      variant='contained'
    >
      {I18n.t('actions.back')}
    </Button>

    <Button
      className={classes.PersonalData__Action}
      color='primary'
      disabled={!!loading}
      type='submit'
      variant='contained'
      data-intercom-target="Store Step 3"
    >
      {I18n.t('actions.advance')}
    </Button>
  </div>

const styles = theme => ({
  PersonalData__Actions: {
    display: 'flex',
    justifyContent: 'center',
    marginTop: `${theme.spacing.unit * 2}px`
  },
  PersonalData__Action: {
    '&:not(:last-child)': {
      marginRight: `${theme.spacing.unit * 2}px`
    }
  },
  PersonalData__Form: {
    margin: `${theme.spacing.unit}px 0`,
    padding: `${theme.spacing.unit}px ${theme.spacing.unit * 2}px`,
    width: '673px',
    minHeight: '660px',
    '@media screen and (min-width: 300px) and (max-width: 672px)': {
      width: '279px'
    }
  },
})

export default withStyles(styles)(PersonalDataForm)
