import React from 'react'

import { IconButton } from '@material-ui/core'
import DownloadIcon from '@material-ui/icons/CloudDownload'

const fileSizes = ['Bytes', 'KB', 'MB', 'GB', 'TB']

export const getFileSize = bytes => {
  if (!bytes) return '0 B'

  const sizeIndex = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)))

  return `${Math.round(bytes / Math.pow(1024, sizeIndex), 2)}  ${fileSizes[sizeIndex]}`
}

export const filesTableHeaders = [
  {
    field: 'name',
    title: I18n.t('direct_upload_dropzone.table.headers.name')
  },
  {
    field: 'size',
    title: I18n.t('direct_upload_dropzone.table.headers.size'),
    render: (rowData) => getFileSize(rowData.size)
  },
  {
    field: 'url',
    title: I18n.t('direct_upload_dropzone.table.headers.url'),
    render: ({ url }) =>
      <IconButton
        title={I18n.t('actions.download')}
        href={url}
      >
        <DownloadIcon />
      </IconButton>
  }
]

export const serializeFileData = ({
  id,
  key,
  name,
  size,
  type,
  url,
}) => ({
  id,
  key,
  filename: name,
  byte_size: size,
  mimetype: type,
  url,
})

export const deserializeFileData = ({
  id,
  key,
  filename,
  byte_size,
  mimetype,
  url,
}) => ({
  id,
  key,
  name: filename,
  size: byte_size,
  type: mimetype,
  url,
})
