export const validate = ({ equipment }) => {
  const currentYear = new Date().getFullYear()

  const fields = ['name', 'equipment_type', 'ownership']
  const errors = {}

  fields.forEach(field => {
    if (!equipment[field]) errors[field] = I18n.t('validations.required')
  })

  if(equipment.year && equipment.year <= 0 || equipment.year > currentYear){
    errors.year = I18n.t('validations.field_between', { min: 1900, max: currentYear })
  }

  return { equipment: errors }
}

export const implementHeaders = [
  'name',
  'year',
  'equipment_type',
  'ownership'
]

const implementTypes = [
  'fertilizer',
  'harvester',
  'planter',
  'sprayer',
  'plower',
  'subsoiler',
  'other',
]

export const getImplementTypes = () =>
  implementTypes.map(type => ({
    id: type,
    name: I18n.t(`v4/equipment.enums.types.${type}`)
  }))

const ownershipTypes = [
  'own',
  'rented'
]

export const getOwnershipTypes = () =>
  ownershipTypes.map(ownership => ({
    id: ownership,
    name: I18n.t(`v4/equipment.enums.ownerships.${ownership}`)
  }))
