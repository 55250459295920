import React from 'react'
import { SideBar } from '@ui/SideBar'
import withStyles from '@material-ui/core/styles/withStyles'

const SideBarPreview = (props) =>
  <SideBar
    {...props}
  />

const sideBarStyles = (theme) => ({
  drawer: {
    width: 240,
    flexShrink: 0,
    whiteSpace: 'nowrap'
  },
  paper: {
    top: 64 + 58,
    backgroundColor: theme.menus.background,
    '@media(max-width: 599px)': {
      top: 49
    },
    '@media(max-width: 599px)': {
      top: 49
    },
    '@media(max-width: 376px)': {
      top: 56
    }
  },
  drawerOpen: {
    width: 240,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    })
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    overflowX: 'hidden',
    width: 0
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar
  },
  SideBar_Icon: {
    color: theme.menus.icons,
    zIndex: 1300,
    position: 'absolute',
    pointerEvents: 'none'
  },
  SideBar__item: {
    pointerEvents: 'none'
  },
  Menu_Icon: {
    color: theme.menus.icons
  },
  menu__logoLink: {
    textDecoration: 'none',
    display: 'grid'
  }
})

export default withStyles(sideBarStyles)(SideBarPreview)
