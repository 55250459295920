import React from 'react'
import { Form as FinalForm } from 'react-final-form'

import { validate, handleProductSubmit } from '@models/admin/product'
import { RailsForm } from '@support/RailsForm'
import Form from './Form'

export const New = ({ product_kinds, handleError, o }) =>
  <RailsForm
    component={FinalForm}
    action='create'
    url={`/v4/o/${o}/admin/products`}
    validate={validate}
    handleSubmit={
      data =>
        handleProductSubmit({
          data,
          url: `/v4/o/${o}/admin/products`,
          successUrl: `/v4/o/${o}/admin/products`,
          method: 'post',
          handleError
        })
    }
    render={(props) => (
      <Form
        {...props}
        kinds={product_kinds}
        title={I18n.t('v4/product.actions.add')}
        o={o}
      />
    )}
  />

export default New
