import React from 'react'
import PropTypes from 'prop-types'
import { Form as FinalForm } from 'react-final-form'
import { RailsForm } from '@support/RailsForm'
import Form, { validate } from './Form'

const New = ({ model, connectivities, already_in_use, manufacturers, o }) => {
  const { part_number, part_name, hardware_integration_id } = model

  return (
    <RailsForm
      component={FinalForm}
      action='create'
      url={`/v4/o/${o}/admin/part_number_registries`}
      validate={validate}
      render={(props) => (
        <Form
          title={I18n.t('v4/part_number.new.title')}
          connectivities={connectivities}
          already_in_use={already_in_use}
          manufacturers={manufacturers}
          o={o}
          {...props}
        />
      )}
    />
  )
}

New.defaultProps = {
  // [M]
  connectivities: [
    { id: 1, product: 'Produto 1' }, { id: 2, product: 'Produto 2' }
  ]
}

New.propTypes = {
  connectivities: PropTypes.array.isRequired
}

export default New
