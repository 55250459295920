import React from 'react'
import { CircularProgress, withStyles } from '@material-ui/core'
import styles from './styles'

export default withStyles(styles)(
  ({ size, classes, ...props }) =>
  <div className={classes.Loading_wrapper}>
    <CircularProgress size={size || 80} className={classes.Loading} {...props} />
  </div>
)
