import React from 'react'

import { Typography, withStyles } from '@material-ui/core'

import { Create } from '@material-ui/icons'

const Body = ({
  classes,
  onChange,
  hoursWithFlow,
  readingEstimate
}) =>
  <div className={classes.Body_wrapper}>
    <div className={classes.Body_infos}>
      <Typography variant='body1'>
        {I18n.t('v4/equipment.card.estimated_reading')}
      </Typography>

      <div
        className={classes.Body_wrapper_edit}
        onClick={onChange}
        data-intercom-target='edit-estimative-water-meter'
      >
        <Create
          className={classes.Body_edit}
          color='primary'
        />

        <Typography variant='body2'>
          {
            !!readingEstimate
              ? `${readingEstimate} m³`
              : I18n.t('v4/equipment.card.actions.defined')
          }
        </Typography>
      </div>
    </div>

    <div className={classes.Body_infos}>
      <Typography variant='body1'>
        {I18n.t('v4/equipment.card.hours_with_flow')}
      </Typography>

      <Typography variant='body1'>
        {hoursWithFlow}
      </Typography>
    </div>
  </div>

const Styles = theme => ({
  Body_wrapper: {
    margin: `${theme.spacing.unit}px 0`
  },
  Body_infos: {
    display: 'flex',
    alignItems: 'center',
    width: '100%',
    justifyContent: 'space-between',
    '&:not(:last-child)': {
      marginBottom: theme.spacing.unit * 2
    }
  },
  Body_wrapper_edit: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
  },
  Body_edit: {
    marginRight: theme.spacing.unit
  }
})

export default withStyles(Styles)(Body)
