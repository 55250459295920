export const alphaSorting = (a, b, sortDescriber) => {
  if (sortDescriber.order === 'up') {
    return a.area > b.area ? 1 : -1
  } else {
    return a.area < b.area ? 1 : -1
  }
}

export const notAlphaSorting = (a, b, sortDescriber) => {
  const aContent = a.content.find(field => field.name === sortDescriber.orderBy)
  const bContent = b.content.find(field => field.name === sortDescriber.orderBy)

  if (!aContent || !aContent.value) { return 1 }
  if (!bContent || !bContent.value) { return -1 }

  const aValue = aContent.value
  const bValue = bContent.value

  if (sortDescriber.order === 'up') {
    return aValue > bValue ? 1 : -1
  } else {
    return aValue < bValue ? 1 : -1
  }
}
