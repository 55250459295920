import React from 'react'


import withStyles from '@material-ui/core/styles/withStyles'
import InsertDriveFileIcon from '@material-ui/icons/InsertDriveFile';
import ListAltIcon from '@material-ui/icons/ListAlt';

import Table from '@ui/Table'

import styles from './styles'

import { getAccountHeaders } from '@models/account'
import PaymentInfoTabs from '../PaymentInfoTabs'

const Index = ({ classes, model }) => {
  const tab = 'invoices'

  const add_print_link_reducer = (acc, data) => {
    let slip_url = data['print_slip_url']
    let bank_slip_link
    let link = <a className={classes.Invoice__Detail_link} href={data['print_url']} target='_blank'><InsertDriveFileIcon /></a>
    slip_url ? bank_slip_link = <a className={classes.Invoice__Detail_link} href={data['print_slip_url']} target='_blank'><ListAltIcon /></a> : "-"

    return [...acc, { ...data, details: link, bank_slip: bank_slip_link }]
  }

  return (
    <PaymentInfoTabs tab={tab}>
      <Table
        tableKey='organizations-invoices-index'
        key='account-table'
        components={{
          Container: props => <div>{props.children}</div>
        }}
        columns={getAccountHeaders(tab)}
        data={model.reduce(add_print_link_reducer, [])}
        options={{ showTitle: false }}
      />
    </PaymentInfoTabs>
  )
}

export default withStyles(styles)(Index)
