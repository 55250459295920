import React, { Component } from 'react'

import { Fade } from '@material-ui/core'
import CloseIcon from '@material-ui/icons/Clear'

import withStyles from '@material-ui/core/styles/withStyles'

import Menu from './InfoMenu'

import styles from './styles'

const Index = ({
  classes,
  mapElementData,
  handleClose,
  openInfoMenu,
  type,
  handleRemoveAlert,
  o
}) =>
  <div className={classes.menu__info__wrapper__fade}>
    <Fade in={openInfoMenu} timeout={800}>
      <div className={classes.menu__info__wrapper__fade}>
        <CloseIcon
          className={classes.close__info__menu}
          onClick={handleClose}
        />
        <Menu
          type={type}
          data={mapElementData}
          handleCloseInfoAlert={handleClose}
          openInfoAlert={openInfoMenu}
          o={o}
        />
      </div>
    </Fade>
  </div>

export default withStyles(styles)(Index)
