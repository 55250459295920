export default (theme) => ({
  showPage__wrapper: {
    margin: '2px 3%',
    maxWidth: 700,
    padding: theme.spacing.unit
  },
  sensor__content: {
    display: 'flex',
    flexWrap: 'wrap'
  },
  sensor__variable: {
    display: 'flex',
    justifyContent: 'space-between',
    margin: '2px',
    width: '100%',
    fontSize: '0.8125rem'
  },
  variables__actions: {
    margin: '15px 0 0',
    padding: '10px',
    display: 'flex',
    justifyContent: 'flex-end',
    alignItems: 'center'
  },
  variables__action: {
    '&:not(:first-child)': {
      marginLeft: theme.spacing.unit * 2
    }
  }
})
