import React from 'react'
import PropTypes from 'prop-types'

import {
  Button,
  Paper,
  Typography,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from '@styles/showPage'
import { fields } from '@models/seasons'

const Show = ({ classes, model, o }) =>
  <Paper
    className={classes.showPage__wrapper}
    elevation={0}
  >
    <header className={classes.showPage__header}>
      <Typography component="h2" variant="h5">
        {I18n.t('v4/seasons.show.title')}
      </Typography>
    </header>

    <main className={classes.showPage__values}>
      {
        Object.keys(model).map(attribute =>
          fields.includes(attribute) &&
          <div key={attribute} className={classes.showPage__value}>
            <Typography
              color="textSecondary"
              component="h6"
              gutterBottom
              variant="h6"
            >
              {I18n.t(`v4/seasons.tabs.${attribute}`)}
            </Typography>

            {
              !Array.isArray(model[attribute])
                ? (
                  <Typography paragraph variant="subtitle1" data-testid={attribute}>
                    {model[attribute] || ''}
                  </Typography>
                )
                : (
                  <List>
                    {model[attribute].map(att =>
                      <ListItem key={att}>
                        <ListItemText primary={att} />
                      </ListItem>)}
                  </List>
                )
            }
          </div>
        )
      }
    </main>

    <footer className={classes.showPage__actions}>
      <Button
        className={classes.showPage__action}
        color="primary"
        variant="contained"
        href={`//v4/o/${o}/seasons/${model.id}/edit`}
      >
        {`${I18n.t('actions.update')} ${I18n.t('v4/seasons.index.season')}`}
      </Button>

      <Button
        className={classes.showPage__action}
        color='secondary'
        href={`/v4/o/${o}/seasons`}
      >
        {I18n.t('actions.back')}
      </Button>
    </footer>
  </Paper >


Show.propTypes = {
  model: PropTypes.object
}

Show.defaultProps = {
  model: {
    id: 0,
    season_name: '',
    start_date: '',
    end_date: '',
    areas: []
  }
}

const pageWithStyles = withStyles(styles)(Show)

export default pageWithStyles
