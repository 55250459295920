import React, {useState} from 'react'

import {
  Button,
  Checkbox,
  FormControlLabel,
  FormHelperText,
  Link,
  Typography,
  withStyles
} from '@material-ui/core'

import SimpleTextField from '@ui/SimpleFields/TextField'
import SimpleTelephoneField from '@ui/SimpleFields/Telephone'
import SimpleTextFieldWithIcon from '@ui/SimpleFields/TextFieldWithIcon'
import httpService from '@services/httpService'
import { themeProviderWrapper } from '@contexts/ThemeProvider'
import { errorHandlerProviderWrapper } from '@contexts/ErrorHandlerProvider'
import { validateName, validPhone, validatePassword } from '@models/users'
import DeviseScreen from './DeviseScreen'
import styles from './styles'

const ConfirmInvite = ({
  classes,
  logo_url,
  background_image_url,
  handleError,
  invitation_token,
  terms_of_use,
  user_privacy_policy
}) => {
  const [state, setState] = React.useState({
    user: {
      password: '',
      first_name: '',
      last_name: '',
      phone: '',
      accepted_terms_and_conditions: false,
      accepted_user_privacy_policy: false
    },
    firstNameError: '',
    lastNameError: '',
    phoneError: '',
    passwordError: ''
  })

  const [loading, setLoading] = useState(false)

  const handleChange = ({ target }) => {
    setState({
      ...state,
      user: {
        ...state.user,
        [target.name]: target.value
      }
    })
  }

  const handleSubmit = async (e) => {
    e.preventDefault()

    let values = _.cloneDeep(state.user)

    if (!terms_of_use)
      values['accepted_terms_and_conditions'] = true

    if (!user_privacy_policy)
      values['accepted_user_privacy_policy'] = true

    if (validForm(values)) {
      setLoading(true)

      try {       
        await httpService.put('/users/invitation', { user: { ...values, invitation_token } })
        location.replace('/v4')
      }
      catch ({ response }) {
        Array.isArray(response.data)
          ? (
            Object.keys(response.data.errors).forEach((error) =>
              handleError(`${I18n.t(`activerecord.attributes.v4/users.${error}`)} ${response.data.errors[error]}`)
            )
          ) : (
            handleError(I18n.t('errors.default'))
          )
      }

      setLoading(false)
    }
  }

  const validForm = (values) => {
    formBlank(values)

    let is_valid = true

    if (state.phoneError || state.passwordError || state.firstNameError || state.lastNameError) {
      handleError(I18n.t('validations.form_error'))

      is_valid = false
    }

    if (!values.accepted_terms_and_conditions && !!terms_of_use) {
      handleError(I18n.t('validations.accept_terms_of_use'))

      is_valid = false
    }

    if (!values.accepted_user_privacy_policy && !!user_privacy_policy) {
      handleError(I18n.t('validations.accept_terms_of_use'))

      is_valid = false
    }

    return is_valid
  }

  const formBlank = (values) => {
    const required = I18n.t('validations.required')
    const { first_name, last_name, phone, password } = values

    setState({
      ...state,
      firstNameError: first_name ? '' : required,
      lastNameError: last_name ? '' : required,
      phoneError: phone ? state.phoneError : required,
      passwordError: password ? state.passwordError : required
    })
  }

  const checkName = ({ target }) => {
    const name = target.value
    const fieldName = target.name
    const stateName = fieldName === 'first_name' ? 'firstNameError' : 'lastNameError'
    const { message } = validateName(name)

    setState({
      ...state,
      [stateName]: message
    })
  }

  const checkPhone = ({ target }) => {
    const phone = target.value
    const { message } = validPhone(phone)

    setState({
      ...state,
      phoneError: message
    })
  }

  const checkPassword = ({ target }) => {
    const password = target.value
    const { message } = validatePassword(password)
    setState({
      ...state,
      passwordError: message
    })
  }

  return (
    <DeviseScreen
      classes={classes}
      logo_url={logo_url}
      background_image_url={background_image_url}
    >
      <form
        className={classes.Login__form}
        onSubmit={handleSubmit}
        noValidate
      >
        <Typography
          variant='body1'
          className={classes.Login__confirm_invite_help_text}
        >
          {I18n.t('devise.invitations.password_register')}
        </Typography>

        <SimpleTextField
          value={state.user.first_name}
          name='first_name'
          type='text'
          label={I18n.t('activerecord.attributes.v4/users.first_name')}
          classes={classes}
          onChange={handleChange}
          onBlur={checkName}
          required
        />

        {
          !!state.firstNameError &&
          <div className={classes.Login_Span__Wrapper}>
            <FormHelperText className={classes.Login_Span}>* {state.firstNameError}</FormHelperText>
          </div>
        }

        <SimpleTextField
          value={state.user.last_name}
          name='last_name'
          type='text'
          label={I18n.t('activerecord.attributes.v4/users.last_name')}
          classes={classes}
          onChange={handleChange}
          onBlur={checkName}
          required
        />

        {
          !!state.lastNameError &&
          <div className={classes.Login_Span__Wrapper}>
            <FormHelperText className={classes.Login_Span}>* {state.lastNameError}</FormHelperText>
          </div>
        }

        <SimpleTelephoneField
          value={state.user.phone}
          name='phone'
          label={I18n.t('activerecord.attributes.v4/users.phone')}
          classes={classes}
          onChange={handleChange}
          onBlur={checkPhone}
          required
        />

        {
          !!state.phoneError &&
          <div className={classes.Login_Span__Wrapper}>
            <FormHelperText className={classes.Login_Span}>* {state.phoneError}</FormHelperText>
          </div>
        }

        <SimpleTextFieldWithIcon
          value={state.user.password}
          name='password'
          label={I18n.t('activerecord.attributes.v4/users.password')}
          classes={classes}
          onChange={handleChange}
          onBlur={checkPassword}
          required
        />

        {
          !!state.passwordError &&
          <div className={classes.Login_Span__Wrapper}>
            <FormHelperText className={classes.Login_Span}>* {state.passwordError}</FormHelperText>
          </div>
        }

        {
          !!terms_of_use && <FormControlLabel className={classes.Login_checkTerms}
            control={
              <Checkbox
                checked={state.user.accepted_terms_and_conditions}
                onChange={
                  (event) => handleChange({
                    target: {
                      name: 'accepted_terms_and_conditions',
                      value: event.target.checked
                    }
                  })
                }
                color='primary'
              />
            }
            label={
              <Typography className={classes.Login_termsLink} variant='body2'>
                {I18n.t('activerecord.attributes.v4/users.accept_terms')}
                <Link
                  href={`/v5/terms`}
                  target='_blank'
                  underline='always'
                  variant='body1'
                  className={classes.Login_termsLink}
                >
                  {I18n.t('activerecord.attributes.v4/users.terms_of_use')}
                </Link>
              </Typography>
            }
          />
        }

        {!!user_privacy_policy && <FormControlLabel className={classes.Login_checkTerms}
          control={
            <Checkbox
              checked={state.user.accepted_user_privacy_policy}
              onChange={
                (event) => handleChange({
                  target: {
                    name: 'accepted_user_privacy_policy',
                    value: event.target.checked
                  }
                })
              }
              color='primary'
            />
          }
          label={
            <Typography className={classes.Login_termsLink} variant='body2'>
              {I18n.t('activerecord.attributes.v4/users.accept_privacy_policies')}
              <Link
                href={`/v5/privacy_policies`}
                target='_blank'
                underline='always'
                variant='body1'
                className={classes.Login_termsLink}
              >
                {I18n.t('activerecord.attributes.v4/users.privacy_policies')}
              </Link>
            </Typography>}
        />
        }

        <div className={classes.Login__form_actions}>
          <Button
            className={classes.Login__form_action}
            type='submit'
            color='primary'
            variant='contained'
            fullWidth
          >
            {I18n.t('devise.invitations.edit.submit_button')}
          </Button>
        </div>
      </form>
    </DeviseScreen >
  )
}

export default errorHandlerProviderWrapper(
  themeProviderWrapper(
    withStyles(styles)(ConfirmInvite)
  )
)
