import React, { createContext, useContext, useMemo } from 'react'
import { getRequestMethods } from '@services/httpService'

import { useRouter } from '@contexts/RouterProvider'
import { useSessionData } from '@contexts/SessionDataProvider'

const HttpServiceContext = createContext()

export const useHttpService = () => useContext(HttpServiceContext)

const HttpServiceProvider = ({ children }) => {
  const { baseURL } = useRouter()
  const { currentOrganization } = useSessionData()

  const { request, apiRequest } = getRequestMethods(baseURL, currentOrganization.id)

  const value = useMemo(() => ({
    request,
    apiRequest
  }), [currentOrganization, baseURL])

  return (
    <HttpServiceContext.Provider value={value}>
      {children}
    </HttpServiceContext.Provider>
  )
}

export const withHttpService = component => props =>
  React.createElement(component, { ...props, httpService: useHttpService() })

HttpServiceProvider.defaultProps = {
  currentOrganization: {}
}

export default HttpServiceProvider
