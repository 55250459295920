import React from 'react'
import { Form as FinalForm } from 'react-final-form'

import { RailsForm } from '@support/RailsForm'
import { validate } from '@models/privacyPolicy'
import { useHttpService } from '@contexts/HttpServiceProvider'
import { useErrorHandler } from '@contexts/ErrorHandlerProvider'

import Form from './Form'

const New = ({ o }) => {
  const { apiRequest } = useHttpService()
  const handleError = useErrorHandler()

  const handleSubmit = async (data) => {
    try {
      await apiRequest.post('/admin/privacy_policies/create', { privacy_policy: data })
      Turbolinks.visit(`/v4/o/${o}/admin/privacy_policies`)
    }
    catch (error) {
      handleError(error)
    }
  }

return (
  <RailsForm
    component={FinalForm}
    action='create'
    url={'api/v4/admin/privacy_policy/create'}
    successUrl={`/v4/o/${o}/admin/privacy_policies`}
    validate={validate}
    handleSubmit={(data) => handleSubmit(data)}
    render={(props) => (
      <Form
        {...props}
        title={I18n.t('v4/terms.actions.create')}
        o={o}
        data={{}}
      />
    )}
  />
)
}

New.defaultProps = {
  model: {
    name: '',
    description_pt: '',
    description_en: '',
    description_es: ''
  }
}

export default New
