import React from 'react'
import DashboardIndex from './dashboard/Index'

const Dashboard = ({ model, area_id, areas_list, organization_id, o }) =>
  <DashboardIndex
    areasList={areas_list}   
    lastPlantations={model}
    areaId={area_id}
    organizationId={organization_id}
    o={o}
  />

Dashboard.defaultProps = {
  model: {}
}

export default Dashboard
