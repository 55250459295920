import React, { Component } from 'react'
import PropTypes from 'prop-types'
import moment from "moment"
import { withSnackbar } from 'notistack';

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import Table from '@ui/Table'

import { getOrganizationProductsHeaders } from '@models/admin/organizationProducts'
import DeleteDialog from '@ui/DeleteDialog'
import httpService from '@services/httpService'
import styles from '@styles/indexPage'

class Index extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isDialogOpen: false,
      id: null,
      model: []
    }
  }

  closeDialog() {
    this.setState({ isDialogOpen: false })
  }

  handleDelete = organizationId => {
    const { enqueueSnackbar, o } = this.props

    httpService.delete(`/v4/o/${o}/admin/organizations/${organizationId}/products/${this.state.id}`)
      .then(res => Turbolinks.visit(`/v4/o/${o}/admin/organizations/${organizationId}/products`))
      .catch(err => {
        enqueueSnackbar(I18n.t('v4/organization.products.forecast.delete_error'), { variant: 'error' })
        this.setState({ isDialogOpen: false })
      })
  }

  componentDidMount() {
    const { model } = this.props
    this.setState({
      model: model.map(m => {
        m.expire_in = moment(m.expire_in).format('DD/MM/YYYY')
        return m
      })
    })
  }

  render() {
    const { classes, organization, o } = this.props
    const { model, isDialogOpen } = this.state

    return (
      <Paper className={classes.page__wrapper} elevation={0}>
        <header className={classes.page__header}>
          <Typography component='h2' variant='h5'>
            {organization.name}: {I18n.t('v4/organization.products.title')}
          </Typography>
        </header>
        <main style={{ marginTop: "16px", marginBottom: "8px" }}>
          <Button
            variant='outlined'
            color='primary'
            className={classes.page__newResourceButton}
            href={`/v4/o/${o}/admin/organizations/${organization.id}/products/new`}
          >
            {I18n.t('actions.add')} {I18n.t('activerecord.models.v4/product.one')}
          </Button>

          <Table
            tableKey='admin-organizations-organizationProducts-index'
            components={{
              Container: props => <div>{props.children}</div>,
            }}
            columns={getOrganizationProductsHeaders()}
            data={model}
            actions={[
              rowData => ({
                icon: 'create',
                iconProps: { 'data-testid': `edit-${rowData.id}` },
                tooltip: I18n.t('actions.edit'),
                onClick: (event, rowData) =>
                  Turbolinks.visit(`/v4/o/${o}/admin/organizations/${organization.id}/products/${rowData.id}/edit`)
              }),
              rowData => ({
                icon: 'settings',
                iconProps: { 'data-testid': `config-${rowData.id}` },
                tooltip: I18n.t('actions.manage'),
                disabled: rowData.kind === 'others' || rowData.kind === 'field_notebook',
                onClick: (event, rowData) =>
                  Turbolinks.visit(`/v4/o/${o}/admin/organizations/${organization.id}/products/${rowData.id}/config/edit`)
              }),
              rowData => ({
                icon: 'delete',
                iconProps: { 'data-testid': `delete-${rowData.id}` },
                tooltip: I18n.t('actions.remove'),
                onClick: (event, rowData) => {
                  this.setState({ isDialogOpen: true, id: rowData.id })
                }
              })
            ]}
            options={{ showTitle: false }}
          />
        </main>

        <DeleteDialog
          dialogTitle={`${I18n.t('actions.remove')} ${I18n.t('activerecord.models.v4/product.one')}?`}
          dialogText={I18n.t('v4/organization.products.confirmation.remove')}
          handleDelete={() => this.handleDelete(organization.id)}
          onCancel={() => this.closeDialog()}
          onClose={() => this.closeDialog()}
          open={isDialogOpen}
        />
      </Paper>
    )
  }
}

Index.propTypes = {
  model: PropTypes.array
}

export default withSnackbar(
  withStyles(styles)(Index)
)
