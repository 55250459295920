export default theme => ({
  root: {
    maxWidth: 400,
    minWidth: 350
  },
  cart__counter: {
    fontSize: 12,
    fontWeight: 700,
    background: `${theme.palette.primary.main}b0`,
    color: '#FFF',
    padding: '2px 3px',
    borderRadius: 10,
    position: 'absolute',
    bottom: 5,
    right: 1,
    height: 16,
    width: 16
  },
  cart__wrapper: {
    maxHeight: 200,
    overflowY: 'auto',
    overflowX: 'hidden'
  },
  cart__item__name: {
    fontWeight: 500
  },
  cart__item__description: {
    fontSize: 13,
    color: theme.palette.gray.dark
  },
  cart__item__value__wrapper: {
    display: 'flex',
    alignItems: 'center'
  },
  cart__quantity: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  cart__item__quantity: {
    fontWeight: 500,
    margin: 10
  },
  image: {
    position: 'absolute',
    top: 0,
    left: 0,
    fontSize: 75,
    color: theme.palette.gray.main
  },
  cart__empty__wrapper: {
    maxWidth: 300
  },
  cart__empty: {
    textAlign: 'center',
  },
  empty__message: {
    color: theme.palette.gray.dark
  },
  cart__icon: {
    color: theme.palette.gray.main,
    fontSize: 30,
    marginTop: 15,
    border: `2px solid ${theme.palette.gray.light}`,
    borderRadius: 32,
    padding: 7
  }
})
