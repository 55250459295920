import React, { Component } from 'react'

import { Button, Paper, Typography, withStyles } from '@material-ui/core'

import { withRouter } from '@contexts/RouterProvider'
import { withSessionData } from '@contexts/SessionDataProvider'
import { withHttpService } from '@contexts/HttpServiceProvider'
import { withErrorHandler } from '@contexts/ErrorHandlerProvider'
import { getInsightHeaders } from '@models/insight'
import MaterialTable from '@ui/Table'
import DeleteDialog from '@ui/DeleteDialog'
import Dialog from '@ui/Dialog'
import styles from '@theme/styles/forms'
import DialogForm from './DialogForm.js'

class Edit extends Component {
  constructor(props) {
    super(props)

    this.state = {
      action: null,
      insights: [],
      isOpenDeleteDialog: false,
      isOpenFormDialog: false,
      selectedInsight: null,
      editedInsight: null,
      newInsight: {
        name: '',
        url: ''
      }
    }
  }

  componentDidMount() {
    this.fetchData()
  }

  indexProductsUrl = () => {
    const {
      router,
      sessionData: { currentOrganization }
    } = this.props

    return `${router.baseURL}admin/organizations/${this.productBI().organization_id}/products`
  }

  productBI = () => {
    const { model } = this.props

    return model
  }

  organizationId = () => {
    const {
      sessionData: { currentOrganization }
    } = this.props

    return currentOrganization.id
  }

  fetchData = async () => {
    const {
      errorHandler,
      httpService: { apiRequest }
    } = this.props

    const params = {
      organization_id: this.organizationId(),
      organization_product_id: this.productBI().id
    }

    try {
      const fetchUrl = '/insights/get_insights'
      const { data } = await apiRequest(fetchUrl, { params })
      this.setState({ insights: data })
    }
    catch (error) {
      errorHandler(error)
    }
  }

  handleDeleteDialogToggle = (selectedInsight) => {
    this.setState({
      isOpenDeleteDialog: !this.state.isOpenDeleteDialog,
      selectedInsight
    })
  }

  handleDeleteInsight = async (deletedInsight) => {
    const {
      errorHandler,
      httpService: { apiRequest }
    } = this.props

    try {
      const { insights } = this.state
      const indexDeleted = deletedInsight.tableData.id
      const newInsights = insights.filter((insight, index) => index !== indexDeleted)
      const params = {
        organization_id: this.organizationId(),
        id: deletedInsight.id
      }

      await apiRequest.delete('/insights/destroy', { params })

      this.setState({ insights: newInsights })
    }
    catch (error) {
      errorHandler(error)
    }
  }

  handleNewInsight = () => this.setState({
    action: 'create',
    isOpenFormDialog: true,
    newInsight: {
      name: '',
      url: ''
    }
  })

  handleEditInsight = (selectedInsight) => {
    this.setState({
      action: 'edit',
      isOpenFormDialog: true,
      editedInsight: selectedInsight,
      newInsight: {
        name: selectedInsight.name,
        url: selectedInsight.url
      }
    })
  }

  handleCloseFormDialog = () => this.setState({ isOpenFormDialog: false })

  handleSubmit = (data) => {
    const { action } = this.state

    switch (action) {
      case 'create':
        return this.handleCreate(data)
      case 'edit':
        return this.handleEdit(data)
    }    
  }

  handleEdit = async (data) => {
    const {
      errorHandler,
      httpService: { apiRequest }
    } = this.props

    const { insights, editedInsight } = this.state

    const params = {
      organization_id: this.organizationId(),
      insight: {
        id: editedInsight.id,
        name: data.name,
        url: data.url,
        organization_product_id: this.productBI().id
      }
    }
    
    try {
      await apiRequest.patch('insights/edit', params)

      const newInsights = [...insights]

      newInsights[editedInsight.tableData.id] = { ...editedInsight, ...data }

      this.setState({ insights: newInsights, isOpenFormDialog: false })
    }
    catch (error) {
      errorHandler(error)
    }
  }

  handleCreate = async (data) => {
    const {
      errorHandler,
      httpService: { apiRequest }
    } = this.props

    const params = {
      organization_id: this.organizationId(),
      insight: {
        ...data,
        organization_product_id: this.productBI().id
      }
    }

    try {
      await apiRequest.post('/insights/create', params)
      .then(response => {
        const newInsights = [...this.state.insights]

        newInsights.push(response.data)

        this.setState({insights: newInsights, isOpenFormDialog: false})
      })
    }
    catch (error) {
      errorHandler(error)
    }
  }

  render() {
    const { classes } = this.props

    const {
      action,
      insights,
      isOpenDeleteDialog,
      isOpenFormDialog,
      selectedInsight
    } = this.state

    return (
      <Paper className={classes.form__wrapper} elevation={0}>
        <header className={classes.form__header}>
          <Typography component='h2' variant='h5'>
            {I18n.t('v4/insight.title')}
          </Typography>
        </header>

        <main className={classes.form__fields}>
          <MaterialTable
            columns={getInsightHeaders()}
            data={insights}
            title={I18n.t('v4/insight.table_title')}
            actions={[
              {
                icon: 'add',
                tooltip: I18n.t('actions.add'),
                isFreeAction: true,
                onClick: this.handleNewInsight
              },
              rowData => ({
                icon: 'edit',
                iconProps: { 'data-testid': `edit-${rowData.id}` },
                tooltip: I18n.t('actions.edit'),
                onClick: () => this.handleEditInsight(rowData)
              }),
              rowData => ({
                icon: 'delete',
                iconProps: { 'data-testid': `delete-${rowData.id}` },
                tooltip: I18n.t('actions.remove'),
                onClick: () => this.handleDeleteDialogToggle(rowData)
              })
            ]}
            options={{
              search: false,
              sorting: false,
              actionsColumnIndex: -1,
              headerStyle: {
                backgroundColor: '#f5f5f5'
              }
            }}
          >
          </MaterialTable>
        </main>

        <footer className={classes.form__actions}>
          <Button
            className={classes.form__action}
            color='secondary'
            href={this.indexProductsUrl()}
          >
            {I18n.t('actions.back')}
          </Button>
        </footer>

        <Dialog
          open={isOpenFormDialog}
          title={
            action === 'create'
            ? I18n.t('v4/insight.add_dialog_title')
            : I18n.t('v4/insight.edit_dialog_title')
          }
        >
          <DialogForm
            handleChange={this.handleChange}
            handleSubmit={this.handleSubmit}
            onCancel={this.handleCloseFormDialog}
            initialValues={this.state.newInsight}
          />
        </Dialog>

        <DeleteDialog
          dialogTitle={I18n.t('v4/insight.remove_dialog_title')}
          dialogText={I18n.t('confirmation.remove')}
          onCancel={this.handleDeleteDialogToggle}
          onClose={this.handleDeleteDialogToggle}
          handleDelete={() => {
            this.handleDeleteInsight(selectedInsight)
            this.handleDeleteDialogToggle(selectedInsight)
          }}
          open={isOpenDeleteDialog}
        />
      </Paper>
    )
  }
}


export default
  withSessionData(
    withRouter(
      withHttpService(
        withErrorHandler(
          withStyles(styles)(Edit)
        )
      )
    )
  )
