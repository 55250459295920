import React from 'react'

import { Button, Typography, withStyles } from '@material-ui/core'

import { SimpleDatePicker } from '@ui/Fields/DatePicker'
import Dialog from '@ui/Dialog'

import formStyles from '@theme/styles/forms'

const ChangeStatusDateDialog = ({ open, title, handleSubmit, handleClose, description, action, selectedId }) =>
  <Dialog
    open={open}
    onClose={handleClose}
    title={title}
  >
    {
      description &&
      <Typography variant='body1'>
        {description}
      </Typography>
    }

    <Form
      action={action}
      handleSubmit={values => handleSubmit(selectedId, values)}
      handleCancel={handleClose}
    />
  </Dialog>

const Form = withStyles(formStyles)(({ handleSubmit, handleCancel, classes, action }) => {
  const today = new Date()
  const [date, setDate] = React.useState(today)
  const [error, setError] = React.useState('')

  const handleChange = (selectedDate) => {
    if (validDate(selectedDate)) {
      setError('')
    }
    else {
      setError(I18n.t('validations.invalid_date'))
    }

    setDate(selectedDate)
  }

  const validDate = selectedDate => {
    const selectedDateWithoutHours = new Date(selectedDate.toDateString())
    const todayWithoutHours = new Date(today.toDateString())

    if (todayWithoutHours.getTime() === selectedDateWithoutHours.getTime()) return true

    return selectedDateWithoutHours < todayWithoutHours
  }

  return <form
    onSubmit={event => {
      event.preventDefault()

      handleSubmit({ date })
    }}
  >
    <SimpleDatePicker
      margin='normal'
      error={!!error}
      helperText={error}
      InputLabelProps={{ shrink: true }}
      variant='outlined'
      label={I18n.t('v4/irrigation_management.fields.date')}
      value={date}
      onChange={handleChange}
      required
      fullWidth
    />

    <footer className={classes.form__actions}>
      <Button
        className={classes.form__action}
        color='secondary'
        disabled={!!error}
        type='submit'
      >
        {action}
      </Button>

      <Button
        className={classes.form__action}
        onClick={handleCancel}
      >
        {I18n.t('actions.cancel')}
      </Button>
    </footer>
  </form>
})

export default ChangeStatusDateDialog
