import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

const ToolTip = ({ active, payload, label, classes, data }) => {

  const getDescription = (serie) =>
    !Array.isArray(serie.value)
      ? getShortDescription(serie.dataKey)
      : `${serie.dataKey.split('_')[1]} (${I18n.t('v4/telemetry.min')}/${I18n.t('v4/telemetry.max')})`

  const getShortDescription = (dataKey) => {
    const selectedData = data.find(data => data.id === dataKey)

    if (!selectedData || _.isEmpty(selectedData)) {
      return dataKey
    }

    return selectedData.legend
      ? selectedData.legend
      : selectedData.short_description ?
        selectedData.short_description :
        dataKey
  }

  if (active) {
    return (
      <div className={classes.tooltip__wrapper}>
        <p>{label}</p>
        {
          payload.map(serie => {
            const { value } = serie

            if (Array.isArray(serie.value)) {
              const selectedData = data.find(data => data.id === serie.dataKey)
              const baseName = selectedData.legend ?
                selectedData.legend :
                selectedData.short_description ?
                  selectedData.short_description.split('(')[0] :
                  serie.dataKey.split('_')[1]
              const minDataKey = `${baseName} (${I18n.t('v4/telemetry.min')})`
              const maxDataKey = `${baseName} (${I18n.t('v4/telemetry.max')})`

              return <Fragment key={serie.dataKey}>
                <p style={{ color: serie.stroke || serie.fill }}>
                  {`${minDataKey}: ${value[0]}`}
                </p>
                <p style={{ color: serie.stroke || serie.fill }}>
                  {`${maxDataKey}: ${value[1]}`}
                </p>
              </Fragment>
            }

            return <p key={serie.dataKey} style={{ color: serie.stroke || serie.fill }}>
              {`${getDescription(serie)}: ${value}`}
            </p>
          })
        }
      </div>
    )
  }

  return null
}

ToolTip.defaultProps = {
  series: []
}

ToolTip.propTypes = {
  series: PropTypes.array,
  classes: PropTypes.object
}

export default ToolTip
