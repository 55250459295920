export const formatPrice = price => Number(price).toLocaleString(
  I18n.locale,
  {
    currency: 'BRL',
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
    style: 'currency'
  }
)

export const calculateTotalValue = (price, coupon) =>
  Number(price || 0) - Number(coupon ? coupon.discount || 0 : 0)
