import React from 'react'

import { Button, Typography } from '@material-ui/core'

const Unknown = ({ classes, fetchEquipmentStatus }) =>
  <div className={classes.Equipment_Infos}>
    <Typography align='center' align='center' variant='body1'>
      {I18n.t('v4/equipment.dashboard.info_uninstalled')}
    </Typography>

    <div className={classes.Equipment_ActionsStatus}>
      <Button
        color='secondary'
        className={classes.Equipment_actionStatus}
        onClick={() => fetchEquipmentStatus('installed')}
      >
        {I18n.t('false')}
      </Button>

      <Button
        color='primary'
        className={classes.Equipment_actionStatus}
        onClick={() => fetchEquipmentStatus('uninstalled')}
        variant='contained'
      >
        {I18n.t('true')}
      </Button>
    </div>
  </div>

export default Unknown
