export default theme => ({
    Radio__Button__Container: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        marginBottom: theme.spacing.unit * 3,
    },
    Radio__Button__Title: {
        marginRight: '10px',
    },
    Radio__Button: {
        fontSize: '16px',
        background: 'none',
        margin: '0px 7px',
        padding: '8px 20px',
        position: 'relative',
        borderRadius: '9px',
        border: `1px solid ${theme.palette.gray.dark}`,
        outline: 'none',
        cursor: 'pointer',
        '&:disabled': {
            cursor: 'auto',
            background: 'linear-gradient(0deg, rgba(102, 163, 67, 0.1), rgba(102, 163, 67, 0.1)), #FFFFFF',
            color: 'inherit',
            border: `1px solid ${theme.palette.primary.main}`,
            boxShadow: '0px 0px 2px #66A343',
            '&::after': {
                border: `1px solid ${theme.palette.primary.main}`
            },
            '&::before': {
                content: "''",
                position: 'absolute',
                top: '7px',
                right: '7px',
                display: 'inline-block',
                width: '5px',
                height: '5px',
                borderRadius: '100%',
                background: theme.palette.primary.main,
                border: 'none'
            }
        },
        '&::after': {
            content: "''",
            position: 'absolute',
            top: '5px',
            right: '5px',
            display: 'inline-block',
            width: '7px',
            height: '7px',
            borderRadius: '100%',
            border: `1px solid ${theme.palette.gray.dark}`
        }
    }
})
