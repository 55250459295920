import React from 'react'

import { Grid } from '@material-ui/core'
import SelectWithVisitButton from '@ui/Fields/SelectWithVisitButton'

import Field from '@ui/Fields/Field'
import { Field as FinalFormField } from 'react-final-form'

const AreaFields = ({
  classes,
  handleOpenCultureDialog,
  handleOpenSoilAnalyzes,
  handleOpenIrrigationSystemDialog,
  handleSelectForecastPoint,
  irrigationSystems,
  weatherStations,
  sensors,
  pluviometers,
  soilAnalyzes,
  cultures,
  isCropSelected,
  forecastPoints,
  isIrrigationSystemSelected,
  isAlgorithmSimplified,
  selectedIrrigationSystemId,
  selectedCulture,
  selectedForecastPointId
}) => (
  <Grid container spacing={24}>
    <Field
      gridItem
      type='select'
      data={cultures}
      label={I18n.t('activerecord.models.v4/crop.other')}
      disabled={isCropSelected}
      required
      input={{
        onChange: handleOpenCultureDialog,
        value: selectedCulture
      }}
      name='culture'
    />

    <Field
      data={irrigationSystems}
      label={I18n.t('v4/irrigation_management.fields.irrigation_systems')}
      name='irrigation_system'
      required
      type='select'
      disabled={isIrrigationSystemSelected}
      input={{
        value: selectedIrrigationSystemId,
        onChange: handleOpenIrrigationSystemDialog
      }}
      gridItem
    />

    <Field
      data={weatherStations}
      label={I18n.t('v4/irrigation_management.fields.weather_stations')}
      name='weather_station_id'
      type='select'
      disabled={isAlgorithmSimplified}
      gridItem
      allowEmpty
    />

    <Field
      data={sensors}
      label={I18n.t('v4/irrigation_management.fields.soil_sensors')}
      name='soil_sensor_id'
      gridItem
      type='select'
      disabled={isAlgorithmSimplified}
      allowEmpty
    />

    <Field
      data={pluviometers}
      label={I18n.t('v4/irrigation_management.fields.pluviometers')}
      info={I18n.t('v4/irrigation_management.fields.pluviometers_info')}
      name='pluviometer_id'
      gridItem
      type='select'
      allowEmpty
    />

    <Field
      data={forecastPoints}
      label={I18n.t('v4/irrigation_management.fields.forecast_point')}
      name='forecast_point_id'
      gridItem
      type='select'
      input={{
        value: selectedForecastPointId,
        onChange: handleSelectForecastPoint
      }}
      allowEmpty
    />

    <Grid item xs={6}>
      <div className={classes.Field_IconButtonContainer}>
        <FinalFormField
          component={SelectWithVisitButton}
          className={classes.form__fieldWithError}
          InputLabelProps={{ shrink: true, required: true }}
          label={I18n.t('v4/irrigation_management.fields.soil_analyzes')}
          name='soil_analysis_id'
          margin='normal'
          fields={{ id: 'id', name: 'name' }}
          data={soilAnalyzes}
          fullWidth
          variant='outlined'
          required
          actionTitle={`${I18n.t('v4/analysis.actions.add')}`}
          onchange={handleOpenSoilAnalyzes}
        />
      </div>
    </Grid>

  </Grid>
)

export default AreaFields
