export default (theme) => ({
  ChartLegend: {
    padding: `${theme.spacing.unit * 2}px`,
    display: 'flex',
    justifyContent: 'space-between',
    flexWrap: 'wrap'
  },
  ChartLegend__title: {
    textAlign: 'center',
    marginBottom: `${theme.spacing.unit * 3}px`
  },
  ChartLegend__legend: {
    display: 'flex',
    alignItems: 'center',
    margin: `0 ${theme.spacing.unit}px`,
    cursor: 'pointer'
  },
  ChartLegend__text__hide: {
    textDecoration: 'line-through',
    color: theme.palette.text.secondary
  },
  ChartLegend__text: {
    marginLeft: `${theme.spacing.unit * 2}px`
  }
})
