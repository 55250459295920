import React from 'react'
import { Button, Typography } from '@material-ui/core'
import ColorPicker from '@ui/Fields/ColorPicker'

import withStyles from '@material-ui/core/styles/withStyles'
import styles from './style'

const MainColorsForm = ({ classes, colors, updateColors, name, title }) => {
  const setColor = (name, color) => {
    updateColors({
      ...colors,
      [name]: color
    })
  }

  return <div className={classes.ColorsForm__fields_section}>
    <Typography component='h3' variant='h6' style={{ color: 'rgba(0, 0, 0, 0.87)' }}>
      {title}
    </Typography>
    <div className={classes.ColorsForm__fields_wrapper}>
      <div className={classes.ColorsForm__fields}>
        <ColorPicker
          value={colors.main}
          label='Main'
          onChange={(color) => setColor('main', color)}
        />

        <ColorPicker
          value={colors.light}
          label='Light'
          onChange={(color) => setColor('light', color)}
        />

        <ColorPicker
          value={colors.dark}
          label='Dark'
          onChange={(color) => setColor('dark', color)}
        />
      </div>

      <div className={classes.ColorsForm__fields}>
        <Button
          className={classes.ColorsForm__button}
          color={name}
          variant='contained'
        >
          {I18n.t('v4/tenant.styles.button')}
        </Button>

        <Button
          className={classes.ColorsForm__button}
          color={name}
          variant='outlined'
        >
          {I18n.t('v4/tenant.styles.button')}
        </Button>

        <Button
          className={classes.ColorsForm__button}
          color={name}
        >
          {I18n.t('v4/tenant.styles.button')}
        </Button>
      </div>
    </div>
  </div>
}

export default withStyles(styles)(MainColorsForm)
