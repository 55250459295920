export default (theme) => ({
  Data_summary_card: {
    flexGrow: '1',
    marginRight: `${theme.spacing.unit * 2}px`,
    marginBottom: `${theme.spacing.unit}px`,
    padding: `${theme.spacing.unit * 2}px ${theme.spacing.unit * 3}px`,
    borderRadius: '4px',
    height: 'fit-content',
    maxWidth: '350px'
  },
  Data_summary_card__title: {
    marginBottom: `${theme.spacing.unit * 2}px`
  },
  Title_value_wrapper: {
    margin: `0 0 ${theme.spacing.unit * 2}px 0`,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  }
})
