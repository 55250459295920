import React from 'react'
import moment from 'moment'
import PropTypes from 'prop-types'
import { Form as FinalForm } from 'react-final-form'

import httpService from '@services/httpService'
import { RailsForm } from '@support/RailsForm'
import Form from './EditForm'

export const Edit = ({ organization, products, model, handleError, o, show_trial }) => {
  let product = products.find(p => p.id === model.product_id)
  model.expire_in = moment(model.expire_in).toISOString()

  const initialValues = {
    organization_name: organization.name,
    product_label: product ? product.label : '',
    expire_in: model.expire_in,
    trial: model.trial
  }

  return <RailsForm
    component={FinalForm}
    action='update'
    url={`/v4/o/${o}/admin/organizations/${organization.id}/users/`}
    initialValues={initialValues}
    handleSubmit={({ expire_in, trial }) => {
      handleSubmit({ expire_in, trial }, organization.id, model.id, handleError, o)
    }}
    render={(props) => (
      <Form
        {...props}
        product={product}
        model={model}
        products={products}
        organization={organization}
        title={`${I18n.t('actions.edit')} ${I18n.t('activerecord.models.v4/product.one')}`}
        o={o}
        showTrial={show_trial}
      />
    )}
  />
}

const handleSubmit = (data, organizationId, productId, handleError, o) => {
  httpService({
    method: 'PUT',
    url: `/v4/o/${o}/admin/organizations/${organizationId}/products/${productId}`,
    data
  })
    .then(() => {
      Turbolinks.visit(`/v4/o/${o}/admin/organizations/${organizationId}/products`)
    })
    .catch(error => {
      handleError(error)
    })
}

Edit.propTypes = {
  model: PropTypes.object
}

export default Edit
