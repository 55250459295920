import PropTypes from 'prop-types'

export const partNumberShape =
  PropTypes.shape({
    id: PropTypes.number,
    part_name: PropTypes.string,
    part_number: PropTypes.string,
    hardware_integration_id: PropTypes.number,
    created_at: PropTypes.string,
    updated_at: PropTypes.string
  })
