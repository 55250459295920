import React from "react";

import { Typography } from "@material-ui/core";

import { getProgressBarStatus, Icon } from "@models/area";

import { convertToLocaleDate } from "@services/locale";

import FormCrop from "./FormCrop";
import Action from "./Action";

import Dialog from "@ui/Dialog";
import EditIcon from "@material-ui/icons/Edit";
import ExpiredPhase from "./ExpiredPhase";
import { _ } from "core-js";

const cropData = [
  {
    name: "name",
    title: I18n.t("activerecord.attributes.v4/area.menu.planted_culture"),
  },
  {
    name: "planting_date",
    title: I18n.t("activerecord.attributes.v4/area.menu.planted_at"),
    render: ({ planting_date, dap }) =>
      `${convertToLocaleDate(planting_date)} (${dap} DAP)`,
  },
  {
    name: "expected_harvest_date",
    title: I18n.t("activerecord.attributes.v4/area.menu.harvest"),
    render: ({ expected_harvest_date }) =>
      convertToLocaleDate(expected_harvest_date),
  },
  {
    name: "expected_phase_change",
    title: I18n.t("activerecord.attributes.v4/area.menu.phenological_phase"),
    render: (crop) =>
      isCropLastStage(crop)
        ? crop.current_stage.name
        : I18n.t("activerecord.attributes.v4/area.menu.expected_change", {
            day: crop.expected_phase_change,
          }),
  },
];

const isCropLastStage = ({ next_stage }) => _.isEmpty(next_stage);

const CropContent = ({
  area,
  classes,
  crop,
  handleCropSubmit,
  cultures,
  router,
  dialog,
  setDialog,
  dialogHandler,
}) => {
  return (
    <>
      {_.isEmpty(crop) ? (
        <FormCrop
          handleSubmit={handleCropSubmit}
          cultures={cultures}
          areaName={area.name}
        />
      ) : (
        <>
          {cropData.map((data) => (
            <div key={data.name} className={classes.Area_Product_Wrapper_Title}>
              <div className={classes.Area_Icon}>
                <Icon variant={data.name} />
              </div>
              <div className={classes.Area_Product_Title}>
                <Typography
                  variant="subtitle2"
                  className={classes.Area_Product_Title__Info}
                >
                  {data.title}
                </Typography>

                <Typography variant="caption">
                  {data.render ? data.render(crop) : crop[data.name] || "--"}
                </Typography>
              </div>
            </div>
          ))}

          {isCropLastStage(crop) ? (
            <Typography variant="body1" className={classes.Area_Last_Stage}>
              {I18n.t("activerecord.attributes.v4/area.menu.date_last_stage", {
                days: crop.current_stage.days_in_stage,
                date: convertToLocaleDate(crop.current_stage.started_at),
              })}
            </Typography>
          ) : (
            <>
              <div className={classes.Area_Product_Wrapper_Title}>
                <div className={classes.Area_Product_Phase}>
                  <Typography variant="body1" className={classes.Area_Info}>
                    {crop.current_stage.name}
                  </Typography>

                  <Typography variant="caption">
                    {crop.next_stage.name}
                  </Typography>
                </div>
              </div>

              <div className={classes.Area_ProgressBar_Wrapper}>
                <div
                  className={classes.Area__ProgressBar}
                  style={{
                    width: `${getProgressBarStatus(crop.current_stage)}%`,
                  }}
                ></div>
              </div>

              <div className={classes.Area_Product_Wrapper_Title}>
                <div className={classes.Area_Product_Phase}>
                  <div className={classes.Wrapper_edit}>
                    <Typography variant="caption">
                      {convertToLocaleDate(crop.current_stage.started_at)}
                    </Typography>

                    <EditIcon
                      color="primary"
                      onClick={() => dialogHandler("editDate")}
                      className={classes.Edit_phenologicalStage}
                      data-intercom-target="areacard_click_editbutton"
                    />
                  </div>

                  <Typography variant="caption">
                    {convertToLocaleDate(
                      crop.current_stage.expected_stage_finish
                    )}
                  </Typography>
                </div>
              </div>
            </>
          )}
        </>
      )}

      {!_.isEmpty(crop) && !(crop.expected_phase_change > 0) && (
        <ExpiredPhase
          phaseName={
            isCropLastStage(crop)
              ? crop.current_stage.name
              : crop.next_stage.name
          }
          isCropLastStage={isCropLastStage(crop)}
        />
      )}

      <>
        {!_.isEmpty(crop) && (
          <div className={classes.Wrapper_actions}>
            <Action
              label={I18n.t("actions.cancel")}
              variant="outlined"
              onChange={() => dialogHandler("cancel")}
              useIcon
              intercomTag="areacard_click_cancelbutton"
            />

            {isCropLastStage(crop) ? (
              <Action
                label={I18n.t("actions.harvest")}
                variant="contained"
                onChange={() => dialogHandler("harvest")}
                isCropLastStage={isCropLastStage(crop)}
                useIcon={isCropLastStage(crop)}
                intercomTag="areacard_click_harvestbutton"
              />
            ) : (
              <Action
                label={I18n.t("actions.advance-stage")}
                variant="contained"
                onChange={() => dialogHandler("advance")}
                isCropLastStage={isCropLastStage(crop)}
                useIcon={isCropLastStage(crop)}
                intercomTag="areacard_click_advancebutton"
              />
            )}
          </div>
        )}

        <Action
          label={I18n.t("v4/area.dashboard.card.history")}
          variant="outlined"
          onChange={() => router.visit(`areas/${area.id}/dashboard`)}
          intercomTag="clicked_productivity_history_area_card"
        />
      </>

      <Dialog
        onClose={() => setDialog({ ...dialog, isOpen: false })}
        open={dialog.isOpen}
        title={dialog.title}
      >
        {dialog.component}
      </Dialog>
    </>
  );
};

export default CropContent;
