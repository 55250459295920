import React from 'react'
import { Field } from 'react-final-form'

import { Button, withStyles } from '@material-ui/core'

import TextField from '@ui/Fields/TextField'
import styles from '@theme/styles/forms'

const Form = ({ classes, handleSubmit, onCancel }) => {
  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <main className={classes.form__fields}>
        <div className={classes.form__field__wrapper}>
          <Field
            component={TextField}
            className={classes.form__fieldWithError}
            fullWidth
            InputLabelProps={{ shrink: true }}
            label={I18n.t('v4/insight.headers.name')}
            name='name'
            margin='normal'
            variant='outlined'
            required
          />
        </div>

        <div className={classes.form__field__wrapper}>
          <Field
            component={TextField}
            className={classes.form__fieldWithError}
            fullWidth
            InputLabelProps={{ shrink: true }}
            label={I18n.t('v4/insight.headers.url')}
            name='url'
            margin='normal'
            variant='outlined'
            required
          />
        </div>
      </main>

      <footer className={classes.form__actions}>
        <Button
          className={classes.form__action}
          color='primary'
          type='submit'
          variant='contained'
        >
          {I18n.t('actions.add')}
        </Button>

        <Button
          className={classes.form__action}
          color='secondary'
          onClick={onCancel}
        >
          {I18n.t('actions.cancel')}
        </Button>
      </footer>
    </form>
  )
}

export default withStyles(styles)(Form)
