import { isFinished, isNotFinished } from '.'

export const getManagementStatus = data => {
  return { status: data.hydric_status }
}

export const formatApiData = data => {
  const runningManagements = data.filter(isNotFinished)
  const finishedManagements = data.filter(isFinished)

  const formattedRunningManagements = runningManagements.map(formatManagement)
  const formattedFinishedManagements = finishedManagements.map(formatManagement)

  return {
    runningManagements: formattedRunningManagements,
    finishedManagements: formattedFinishedManagements
  }
}

const formatManagement = management => {
  const area = management.area_name
  const formattedData = formatManagementData(management.data)

  const result = { id: management.id, area, status: management.status, ...formattedData }

  let formattedForecast = management.forecast.map(formatManagementData)

  formattedForecast.forEach(forecast => {
    const key = Object.keys(forecast)[0]

    if (!result[key]) {
      result[key] = forecast[key]
    }
  })

  return result
}

const formatManagementData = data => {
  const managementStatus = getManagementStatus(data)

  if (!data.date) { return null }

  return {
    [data.date]: {
      hydricStatus: managementStatus.status,
      needIrrigation: managementStatus.need_irrigation,
      wa: data.availability_soil_water != undefined ? Number(data.availability_soil_water).toFixed(2) : null,
      accumulated_water_loss: data.accumulated_water_loss != undefined ? Number(data.accumulated_water_loss).toFixed(2) : null,
      final_speed_relay: data.final_speed_relay != undefined ? Number(data.final_speed_relay).toFixed(2) : null,
      final_time: data.final_time != undefined ? Number(data.final_time).toFixed(2) : null,
      di: data.demanded_irrigation != undefined ? Number(data.demanded_irrigation).toFixed(2) : null
    }
  }
}
