import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

import httpService from '@services/httpService'
import { fields } from '@models/users'
import DeleteDialog from '../../ui/DeleteDialog'
import styles from '@styles/showPage'

export class Show extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isDialogOpen: false
    }

    this.toggleDialog = this.toggleDialog.bind(this)
  }

  toggleDialog() {
    this.setState({ isDialogOpen: !this.state.isDialogOpen })
  }

  handleDelete = async () => {
    const { organization, model, handleError } = this.props

    try {
      await httpService.get(`/v4/organizations/${organization.id}/users/${model.id}/remove.json`)

      Turbolinks.visit(`/v4/organizations/${organization.id}/users`)
    }
    catch (error) {
      handleError(error)
    }
  }

  render() {
    const {
      classes,
      model,
      organization
    } = this.props

    const { isDialogOpen } = this.state

    return (
      <Paper
        className={classes.showPage__wrapper}
        elevation={0}
      >
        <header className={classes.showPage__header}>
          <Typography component='h2' variant='h5'>
            {I18n.t('v4/users.show.title')}
          </Typography>
        </header>

        <main className={classes.showPage__values}>
          {
            fields().map(field =>
              model[field.name] && !field.hidden &&
              <div key={field.name} className={classes.showPage__value}>
                <Typography
                  color="textSecondary"
                  component="h6"
                  gutterBottom
                  variant="h6"
                >
                  {I18n.t(`activerecord.attributes.v4/users.${field.name}`)}
                </Typography>

                <Typography paragraph variant="subtitle1" data-testid={field.name}>
                  {
                    field.render
                      ? field.render(model[field.name])
                      : model[field.name]
                  }
                </Typography>
              </div>
            )
          }
        </main>

        <footer className={classes.showPage__actions}>
          <Button
            className={classes.showPage__action}
            color='primary'
            variant="contained"
            onClick={this.toggleDialog}
          >
            {I18n.t('v4/users.actions.remove')}
          </Button>
          <Button
            className={classes.showPage__action}
            color='secondary'
            href={`/v4/organizations/${organization.id}/users`}
          >
            {I18n.t('actions.back')}
          </Button>
        </footer>

        <DeleteDialog
          dialogTitle={`${I18n.t('v4/users.actions.remove')}?`}
          dialogText={I18n.t('v4/users.confirmation.remove')}
          handleDelete={this.handleDelete}
          onCancel={this.toggleDialog}
          onClose={this.toggleDialog}
          open={isDialogOpen}
        />
      </Paper>
    )
  }
}

Show.propTypes = {
  model: PropTypes.object
}

export default withStyles(styles)(Show)
