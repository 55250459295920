export const validate = ({ equipment }) => {
  const currentYear = new Date().getFullYear()

  const fields = [
    'name', 'ownership', 'power_output',
    'manufacturer', 'equipment_model'
  ]
  const errors = {}

  fields.forEach(field => {
    if(!equipment[field]) errors[field] = I18n.t('validations.required')
  })

  if(equipment.year && equipment.year <= 0 || equipment.year > currentYear){
    errors.year = I18n.t('validations.field_between', { min: 1900, max: currentYear })
  }

  return { equipment: errors }
}

export const tractorHeaders = [
  'name',
  'power_output',
  'manufacturer',
  'equipment_model',
  'year'
]

const ownershipTypes = [
  'own',
  'rented'
]

export const getOwnershipTypes = () =>
  ownershipTypes.map(ownership => ({
    id: ownership,
    name: I18n.t(`v4/equipment.enums.ownerships.${ownership}`)
  }))
