import React, { useEffect, useState } from 'react'

import { Paper, Button, Typography, withStyles, Divider } from '@material-ui/core'
import { sendAmplitudeEvent } from '@utils/amplitudeEvent'
import { IntercomAPI } from '@utils/IntercomAPI';
import DeleteIcon from '@material-ui/icons/Delete'

import AreaChangeHandler from '@ui/AreaChangeHandler'

import AreaFields from './AreaFields'

import httpService from '@services/httpService'
import styles from '@theme/styles/forms'
import LoadingComponent from '@ui/LoadingComponent'

const IrrigationSheetForm = ({
  areas,
  classes,
  form,
  equipment,
  handleSubmit,
  handleError,
  loading,
  onCancel,
  organization_id,
  withWrapper,
  values,
  o,
  initialAreas,
  ...props
}) => {
  const [selectedAreas, setSelectedAreas] = useState([])

  useEffect(() => {
      if (initialAreas) {
        initialAreas.forEach((area) => {
          onSetAreaId(area.id)
        })
      }
  }, [])

  const fetchIrrigationSystem = async (irrigation_system_id) => {
    const params = { organization_id }
    const { data } = await httpService.get(`/api/v4/equipments/irrigation/${irrigation_system_id}`, { params })
    return data;
  }
  
  const onSetAreaId = async id => {
    try {
      const selectedArea = areas.find(area => area.id === id)
      if (!equipment) {
        const irrigationSystemData = await fetchIrrigationSystem(selectedArea.irrigation_system_id);
        selectedArea.equipment = irrigationSystemData
      }

      setSelectedAreas((selectedAreas) => [
        ...selectedAreas,
        {
          ...selectedArea,
          uuid: new Date().valueOf()
        }
      ])
    } catch (err) {
      console.error(err)
      handleError(err)
    }
  }

  const onRemoveAreaIndex = async uuid => {
    setSelectedAreas(selectedAreas.filter((area) => area.uuid !== uuid))
    values[`area_${uuid}`] = null
  }

  if (areas.length === 1 && _.isEmpty(selectedAreas)) {
    onSetAreaId(areas[0].id)
  }

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <Paper className={withWrapper ? classes.form__wrapper : ""} elevation={0}>
        {!!equipment && (
          <header className={classes.form__header}>
            <Typography component="h2" variant="h5">
              {equipment.name ? `${equipment.name} - ` : ""}
              {I18n.t(
                `enumerize.v4/equipment/irrigation.irrigation_system_type.${equipment.irrigation_system_type}`
              )}
            </Typography>
          </header>
        )}
        <main className={classes.form__fields}>
          {selectedAreas.map((area, index) => (
            <div key={area.uuid} className={classes.form__area_fields}>
              <AreaFields
                values={values}
                area={area}
                equipment={equipment}
                form={form}
              />
              <Button
                onClick={() => {
                  onRemoveAreaIndex(area.uuid);
                  sendAmplitudeEvent(
                    "Aqua - Inform Depths - Removed informations"
                  );
                }}
                color="secondary"
              >
                <DeleteIcon />
                {I18n.t("v4/irrigation.actions.remove")}
              </Button>
              <Divider />
            </div>
          ))}

          <AreaChangeHandler
            handleChange={({ target }) => {
              onSetAreaId(target.value);
              sendAmplitudeEvent("Aqua - Inform Depths - Used Area Selection");
              IntercomAPI("Aqua - Inform Depths - Used Area Selection");
            }}
            areas={areas}
          />
        </main>
        <footer className={classes.form__actions}>
          {loading ? (
            <LoadingComponent size={25} />
          ) : (
            <Button
              className={classes.form__action}
              color="primary"
              type="submit"
              variant="contained"
              disabled={loading}
              onClick={() => {
                sendAmplitudeEvent("Aqua - Inform Depths - Used Save Button");
                IntercomAPI("Aqua - Inform Depths - Used Save Button");
              }}
            >
              {I18n.t("v4/irrigation.actions.save")}
            </Button>
          )}
          <Button
            className={classes.form__action}
            onClick={() => {
              onCancel
                ? onCancel()
                : Turbolinks.visit(
                    `/v4/o/${o}/equipments/irrigation/${equipment.id}/irrigations`
                  );
              sendAmplitudeEvent("Aqua - Inform Depths - Used Cancel Button");
              IntercomAPI("Aqua - Inform Depths - Used Cancel Button");
            }}
            color="secondary"
          >
            {I18n.t("v4/irrigation.actions.cancel")}
          </Button>
        </footer>
      </Paper>
    </form>
  );
}

IrrigationSheetForm.defaultProps = {
  values: {},
  withWrapper: true
}

export default withStyles(styles)(IrrigationSheetForm)
