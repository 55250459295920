import React from 'react'

import { Typography } from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'

import Area from '@images/alerts/area.svg'
import Step from './Step'

const CreateAreaStep = ({ classes, advanceStep }) =>
  <Step
    title={I18n.t('v4/alerts.new.steps.create_area.title')}
    showBackButton={false}
    advanceButtonText={I18n.t('v4/alerts.new.steps.create_area.action')}
    advanceStep={advanceStep}
  >
    <section className={classes.CreateAreaStep}>
      <img src={Area} alt='area bell'></img>

      <Typography variant='body1'>
        {I18n.t('v4/alerts.new.steps.create_area.text1')}
      </Typography>

      <Typography variant='body1'>
        {I18n.t('v4/alerts.new.steps.create_area.text2')}
      </Typography>

      <Typography variant='body1'>
        {I18n.t('v4/alerts.new.steps.create_area.text3')}
      </Typography>
    </section>
  </Step>

const styles = theme => ({
  CreateAreaStep: {
    textAlign: 'center',
    margin: 'auto',
    maxWidth: '500px',
    '& *': {
      marginBottom: theme.spacing.unit * 2
    }
  }
})

export default withStyles(styles)(CreateAreaStep)
