export const fields = [
  'code', 'discount', 'discount_type', 'expiration_date', 'number_subscriptions'
]

export const getCouponHeaders = () =>
  fields.map(field => ({
    title: I18n.t(`activerecord.attributes.v4/coupon.${field}`),
    field
  }))

export const fieldsProductsPlans = [
  'plan_kind', 'product'
]

export const getProductsPlansHeaders = () =>
  fieldsProductsPlans.map(field => ({
    title: I18n.t(`activerecord.attributes.v4/coupon.${field}`),
    field
  }))

export const validate = values => {
  const requiredFields = ['code', 'discount', 'description', 'discount_type']
  const errors = {}

  requiredFields.forEach(field => {
    if (!values[field]) errors[field] = I18n.t('validations.required')
  })

  if (values.code !== undefined) {
    if (values.code.replace(/\s/g, '').length !== values.code.length) {
      errors.code = I18n.t('validations.code_coupan_invalid')
    }
    if (values.code.length > 20) {
      errors.code = I18n.t('validations.code_coupan_invalid')
    }
  }

  return errors
}

export const deserialize = data => data.map((cupon) => ({
  ...cupon, expiration_date: new Date(cupon.expiration_date).toLocaleDateString(I18n.locale, {timeZone: 'UTC'})
}))
