import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import ExportIcon from '@material-ui/icons/Image'
import { Button } from '@material-ui/core'
import { exportChartToPng } from '@models/chart'
import PropTypes from 'prop-types'
import { getDescriptor } from '@models/weather/dashboard'

import { Typography } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from '../styles'

import DefaultChart from './Default/DefaultChart'
import AccumulatedRainChart from './AccumulatedRain/AccumulatedRainChart'
import WindVelocityChart from './WindVelocity/WindVelocityChart'

class Chart extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      showingData: []
    }

    this.currentChart = null
  }

  exportChart = () => {
    const chartSVG = ReactDOM.findDOMNode(this.currentChart)
    if (chartSVG) {
      exportChartToPng(chartSVG)
    }
  }

  componentDidMount() {
    this.setState({
      showingData: this.props.chart.rows.map(data =>
        ({ ...data, hide: false })
      )
    })
  }

  handleToggleData = ({ value }) => {
    const { showingData } = this.state

    this.setState({
      showingData: showingData.map(data => data.id === value ? { ...data, hide: !data.hide } : data)
    })
  }

  render() {
    const { data, xData, classes, descriptors, chart } = this.props
    const { showingData } = this.state

    const chartProps = {
      data,
      xData,
      classes,
      descriptors,
      getDescriptor,
      chartConfigs: chart,
      showingData,
      handleToggleData: this.handleToggleData
    }

    const chartsTypes = {
      wind_velocity: <WindVelocityChart
        {...chartProps}
      />,
      accumulated_rain: <AccumulatedRainChart
        {...chartProps}
      />
    }

    return (
      !!data.length && !!chart.rows.length &&
      <div
        className={classes.chart__wrapper}
        ref={(chart) => this.currentChart = chart}
      >
        <div className={classes.chart__header}>
          <Typography component='h6' variant='h6'>
            {chart.title}
          </Typography>

          <Button
            className={classes.export_button}
            title={I18n.t('v4/equipment.dashboard.charts.export_chart')}
            onClick={this.exportChart}
          >
            <ExportIcon />
          </Button>
        </div>

        {
          chartsTypes[chart.id] ||
          <DefaultChart
            {...chartProps}
          />
        }
      </ div>
    )
  }
}

Chart.defaultProps = {
  data: [],
  series: []
}

Chart.propTypes = {
  data: PropTypes.array,
  xData: PropTypes.string.isRequired,
  classes: PropTypes.object,
  series: PropTypes.array
}

export default withStyles(styles)(Chart)
