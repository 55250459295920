export default (theme) => ({
  Products__wrapper: {
    position: 'fixed',
    bottom: 0,
    zIndex: 1050,
    right: 0,
    maxWidth: '90%'
  },
  Products__wrapper__editing: {
    display: 'none'
  },
  Products__collapse: {
    maxWidth: 'calc(100vw - 70px)',
    overflowX: 'auto'
  },
  Products__content: {
    background: `${theme.palette.background.default}C7`,
    display: 'flex',
    justifyContent: 'center',
  },
  Products__wrapperInner: {
    width: 'auto'
  },
  Collapse__wrapper: {
    display: 'block'
  }
})
