import React from 'react'

import { MenuItem, TextField, Button } from '@material-ui/core'

import withStyles from '@material-ui/core/styles/withStyles'

const SelectWithVisitButton = ({
  data,
  input,
  meta: { error, touched },
  selectBy,
  fields,
  classes,
  actionTitle,
  color,
  onchange,
  ...textFieldProps
}) => {
  const component = {
    'name':
      <Button
        className={classes.Select_Button}
        color={color || 'secondary'}
        onClick={onchange}
      >
        {actionTitle}
      </Button>
  }

  return <TextField
    error={!!(touched && error)}
    helperText={touched && error}
    select
    {...input}
    {...textFieldProps}
  >
    {
      [...data, { ...component }].map(datum => {
        const value = datum[fields.id] === undefined ? datum[fields.name] : datum[fields.id]
        return (
          <MenuItem value={value} key={value}>
            {datum[fields.name]}
          </MenuItem>
        )
      })
    }
  </TextField>
}

const styles = theme => ({
  Select_Button: {
    padding: `${theme.spacing.unit}px 0`,
    display: 'flex',
    justifyContent: 'left',
    width: '100%'
  }
})

export default withStyles(styles)(SelectWithVisitButton)
