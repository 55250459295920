import React from 'react'

import { MuiThemeProvider, CssBaseline } from '@material-ui/core'

import { useSessionData } from '@contexts/SessionDataProvider'

import mountTheme from '@theme/mountTheme'

const deserializeTheme = (theme, themeOption) =>
  mountTheme(theme ? JSON.parse(theme) : theme, themeOption)

const MaterialThemeProviderWrapper = ({ theme, children, themeOption }) => (
  <MuiThemeProvider theme={deserializeTheme(theme, themeOption)}>
    <CssBaseline></CssBaseline>

    {children}
  </MuiThemeProvider>
)

const ThemeProvider = ({ children, themeOption }) => {
  const { currentTenant } = useSessionData()

  const theme = currentTenant
    ? currentTenant.theme
    : null

  return (
    <MaterialThemeProviderWrapper theme={theme} themeOption={themeOption}>
      {children}
    </MaterialThemeProviderWrapper>
  )
}

export const themeProviderWrapper = component => ({ theme, ...props }) =>
  <MaterialThemeProviderWrapper theme={theme}>
    {React.createElement(component, props)}
  </MaterialThemeProviderWrapper>

export default ThemeProvider
