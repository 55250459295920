import React, { PureComponent } from 'react'
import ReactDOM from 'react-dom'
import {
  ResponsiveContainer, ComposedChart, XAxis, YAxis, Tooltip, Line, Bar, Area, CartesianGrid, Brush, Legend
} from 'recharts'
import ExportIcon from '@material-ui/icons/Image'
import { Button } from '@material-ui/core'
import { chartsFontFamily, getYAxisLabelPosition, getYAxisOrientation, exportChartToPng, getMinMaxValues } from '@models/chart'
import PropTypes from 'prop-types'

import CustomTooltip from './tooltip'
import withStyles from '@material-ui/core/styles/withStyles'
import styles from './styles'

class Chart extends PureComponent {
  constructor(props) {
    super(props)

    this.state = {
      allSeries: [],
      showingData: []
    }

    this.currentChart = null
  }

  exportChart = () => {
    const chartSVG = ReactDOM.findDOMNode(this.currentChart)
    if (chartSVG) {
      exportChartToPng(chartSVG)
    }
  }

  /**
   * Formata os registros de forma que em caso de variáveis repetidas
   * (ex. vwc1, vwc2, vwc3) só apareça um eixo Y no gráfico.
   * Caso o mencionado acima ocorra, o eixo Y não pode ser escondido!
   **/
  formatYAxisInfo = (data, index, self) => {
    const hasRange = !!data.rangeArea

    let newId = data.id.split('_')[hasRange ? 1 : 0]
    if (data.id.includes('water_estimative')) {
      newId = 'water_estimative'
    }
    if (data.id.includes('water_volume')) {
      newId = 'water_volume'
    }
    newId = isNaN(newId[newId.length - 1]) ? newId : newId.slice(0, -1)

    const mainIndex = self.findIndex(el => el.yId === newId)
    if (mainIndex === -1) {
      data.yId = newId
    } else {
      data.yAxisId = newId

      if (!hasRange) {
        self[mainIndex].yAxisAlwaysShown = true
      }
    }

    data.hide = hasRange
    return data
  }

  componentDidMount() {
    const formatedSeries = this.props.series.map(this.formatYAxisInfo)
    this.setState({
      showingData: formatedSeries,
      allSeries: formatedSeries
    })
  }

  handleToggleData = ({ value }) => {
    const { showingData } = this.state

    this.setState({
      showingData: showingData.map(data => data.id === value ? { ...data, hide: !data.hide } : data)
    })
  }

  render() {
    const { data, xData, classes, xInterval } = this.props
    const { showingData, allSeries } = this.state
    const customYaxis = showingData.find(serie => serie.yReference)

    return (
      !_.isEmpty(data) &&
      <div className={classes.chart__wrapper} >
        <Button
          className={classes.export_button}
          title={I18n.t('v4/equipment.dashboard.charts.export_chart')}
          onClick={this.exportChart}
        >
          <ExportIcon />
        </Button>

        <ResponsiveContainer width="100%" height={500}>
          <ComposedChart
            data={data}
            ref={(chart) => this.currentChart = chart}
          >
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis
              fontFamily={chartsFontFamily}
              dataKey={xData}
              interval={xInterval || 'preserveEnd'}
              minTickGap={20}
            />

            {
              allSeries.filter(serie => !serie.yAxisId && !serie.yReference).map((serie, index) =>
                <YAxis
                  fontFamily={chartsFontFamily}
                  yAxisId={serie.yReference || serie.yId}
                  key={serie.yId}
                  domain={getMinMaxValues(data, serie.id)}
                  label={{
                    value: `${serie.description} (${serie.unit})`,
                    angle: -90,
                    position: getYAxisLabelPosition(index),
                    offset: (serie.yId === 'water_estimative' || serie.yId === 'water_volume' ? -1 : -15),
                    style: {
                      textAnchor: 'middle',
                      fontFamily: chartsFontFamily
                    }
                  }}
                  orientation={getYAxisOrientation(index)}
                  hide={!serie.yAxisAlwaysShown && serie.hide}
                />
              )
            }

            {
              customYaxis &&
              <YAxis
                yAxisId={customYaxis.yReference || customYaxis.id}
                key={customYaxis.id}
                fontFamily={chartsFontFamily}
                domain={getMinMaxValues(data, customYaxis.id)}
                label={{
                  value: `${customYaxis.description} (${customYaxis.unit})`,
                  angle: -90,
                  position: getYAxisLabelPosition(0),
                  offset: -15,
                  style: {
                    textAnchor: 'middle',
                    fontFamily: chartsFontFamily
                  }
                }}
                orientation={getYAxisOrientation(0)}
              />
            }

            <Legend
              onClick={this.handleToggleData}
              formatter={value => {
                const data = showingData.find(serie => serie.id === value)
                return (<span style={{ textDecoration: data.hide ? 'line-through' : 'none' }}>
                  {
                    data.legend ?
                      `${data.legend}` :
                      data.short_description ?
                        `${data.short_description}` :
                        `${data.description}`
                  }
                </span>
                )
              }}
            />

            <Brush
              height={25}
            />

            <Tooltip
              content={<CustomTooltip series={showingData} classes={classes} data={showingData} />}
            />

            {
              showingData.map(serie => chartsTypes[serie.type](serie, serie.hide))
            }

          </ComposedChart>
        </ResponsiveContainer>
      </div>
    )
  }
}

const CustomDot = ({ cx, cy, stroke, payload }) =>
  <svg x={cx - 15} y={cy - 15} width='20' height='20' viewBox='0 0 23 23'>
    <path
      transform={`rotate(${payload.wd1_mode || 0} 12 12)`}
      fill={stroke}
      d='M2.47677 0.80731L9.43234 4.85958C9.73689 5.03701 10.1123 5.04101 10.4206 4.87012L17.8618 0.744833C18.6882 0.286699 19.6304 1.12448 19.2719 1.99875L10.8718 22.4891C10.5294 23.3244 9.34297 23.3142 9.0149 22.4732L1.04175 2.0348C0.697586 1.15256 1.65851 0.3306 2.47677 0.80731Z'
    />
  </svg>

const chartsTypes = {
  line: (data, hide) =>
    <Line
      type="monotone"
      strokeWidth={2}
      yAxisId={data.yReference || data.yAxisId || data.yId || data.id}
      dataKey={data.id}
      key={data.id}
      stroke={data.color}
      hide={hide}
      dot={data.id === 'wd1_mode' ? <CustomDot /> : undefined}
    />,
  bar: (data, hide) =>
    <Bar
      type="monotone"
      yAxisId={data.yReference || data.yAxisId || data.yId || data.id}
      dataKey={data.id}
      key={data.id}
      stroke={data.color}
      fill={data.color}
      hide={hide}
    />,
  area: (data, hide) =>
    <Area
      type="monotone"
      yAxisId={data.yReference || data.yAxisId || data.yId || data.id}
      dataKey={data.id}
      key={data.id}
      stroke={data.color}
      fill={data.color}
      hide={hide}
      fillOpacity={data.opacity || 0.5}
    />
}

Chart.defaultProps = {
  data: [],
  series: []
}

Chart.propTypes = {
  data: PropTypes.array,
  xData: PropTypes.string.isRequired,
  classes: PropTypes.object,
  series: PropTypes.array
}

export default withStyles(styles)(Chart)
