import React from 'react'

import { getLanguages } from '@models/alerts'
import Step from './Step'

import ReceiveAlert from './ReceiveAlert'

const ReceiveAlertStep = ({
  advanceStep,
  retreatStep,
  isFromLastStep,
  showBackButton,
  actionCancel,
  actionEdit,
  mediasValues
}) => {
  const [state, setState] = React.useState({
    actions: [],
    isDialogOpen: false,
    isDeleteDialogOpen: false,
    selected: {},
    actionKind: '',
    whatsapp: mediasValues.whatsapp,
    email: mediasValues.email,
    medias: {
      whatsapp: !_.isEmpty(mediasValues.whatsapp),
      email: !_.isEmpty(mediasValues.email)
    },
    enabled: false
  })

  const handleChangeEnabled = () => setState({ ...state, enabled: !state.enabled })

  const closeDialog = (dialog) => setState({ ...state, [dialog]: false })

  const handleContact = (actionKind, dialog, selected = {}) => setState({ ...state, actionKind, [dialog]: true, selected, enabled: !!selected['enabled'] })

  const handleSubmit = (values) => {
    const actions = [...state[state.actionKind]]

    if (state.actionKind === 'whatsapp') {
      values['enabled'] = state.enabled
    }

    if (_.isEmpty(state.selected)) {
      actions.push({ ...values, language: getLanguages()[values.locale.replace(/[-]+/g, '')] })
    } else {
      actions[state.selected.index] = { ...values, language: getLanguages()[values.locale.replace(/[-]+/g, '')] }
    }

    setState({
      ...state,
      [state.actionKind]: actions,
      isDialogOpen: false
    })
  }

  const hasError = () => {
    if ((state.medias.whatsapp && _.isEmpty(state.whatsapp)) || (state.medias.email && _.isEmpty(state.email))) {
      return true
    }

    return false
  }

  const toggleMedia = (media) => setState({ ...state, [media]: [], medias: { ...state.medias, [media]: !state.medias[media] } })

  const handleDeleteDialogToggle = () => {
    const currentActions = [...state[state.actionKind]]
    const newActions = currentActions.filter((action, index) => index !== state.selected.index)

    setState({
      ...state,
      [state.actionKind]: newActions,
      isDeleteDialogOpen: false
    })
  }

  const customDescription = () =>
    <span>
      <span style={{ fontWeight: 500 }}>
        {`${I18n.t('v4/alerts.new.steps.receive_alert.info_bold')} `}
      </span>
      <span>
        {I18n.t('v4/alerts.new.steps.receive_alert.info')}
      </span>
    </span>

  return (
    <Step
      title={I18n.t('v4/alerts.new.steps.receive_alert.title')}
      description={customDescription()}
      advanceStep={() => advanceStep(state.whatsapp, state.email, isFromLastStep)}
      retreatStep={retreatStep}
      disableButton={hasError()}
      showBackButton={showBackButton}
      actionCancel={actionCancel}
      actionEdit={actionEdit}
    >
      <ReceiveAlert
        toggleMedia={toggleMedia}
        data={state}
        closeDialog={closeDialog}
        handleDeleteDialogToggle={handleDeleteDialogToggle}
        handleSubmit={handleSubmit}
        handleContact={handleContact}
        handleChangeEnabled={handleChangeEnabled}
      />
    </Step >
  )
}

export default (ReceiveAlertStep)
