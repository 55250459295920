import React from 'react'
import { Form as FinalForm } from 'react-final-form'

import { validate, handlePlanSubmit } from '@models/admin/plans'
import { RailsForm } from '@support/RailsForm'
import Form from './Form'

export const Edit = ({ model, handleError, statuses, o }) =>
  <RailsForm
    component={FinalForm}
    action='update'
    url={`/v4/o/${o}/store/admin/plans/${model.id}`}
    initialValues={model}
    successUrl={`/v4/o/${o}/store/admin/plans`}
    validate={validate}
    render={(props) => (
      <Form
        {...props}
        title={I18n.t('v4/plans.actions.update')}
        statuses={statuses}
        o={o}
      />
    )}
  />

Edit.defaultProps = {
  model: {}
}

export default Edit
