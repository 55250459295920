export default (theme) => ({
  Stepper:{
    paddingRight: 0,
    paddingLeft: 0
  },
  root: {
    backgroundColor: '#CCC',
    zIndex: 1,
    color: theme.palette.white,
    width: 50,
    height: 50,
    display: 'flex',
    borderRadius: '50%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  stepActive: {
    backgroundImage:
      `linear-gradient( 136deg, ${theme.palette.primary.light} 0%, ${theme.palette.primary.main} 50%, ${theme.palette.primary.dark} 100%)`,
    boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  },
  stepCompleted: {
    backgroundImage:
      `linear-gradient( 136deg, ${theme.palette.secondary.light} 0%, ${theme.palette.secondary.main} 50%, ${theme.palette.secondary.dark} 100%)`
  },
  stepContent: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column'
  }
})
