import React from 'react'

import { Grid, Typography, Paper } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'

const Footer = ({ classes, resume }) =>
  <Paper square className={classes.Footer_wrapper}>
    <Grid container spacing={0} className={classes.Footer_container}>
      <Grid item xs>
        <Typography variant='subtitle2' gutterBottom>
          {I18n.t('v4/forecast.rain_forecast_summary')}
        </Typography>
      </Grid>
    </Grid>

    <Grid container spacing={0} className={classes.Footer_container}>
      <Grid item xs>
        <Typography variant='subtitle2' className={classes.Footer_lightFont}>
          {I18n.t('v4/forecast.vol')}
        </Typography>
        <Typography variant='subtitle2'>
          {`${resume.greaterVol} mm`}
        </Typography>
      </Grid>
      <Grid item xs>
        <Typography variant='subtitle2' className={classes.Footer_lightFont}>
          {I18n.t('v4/forecast.days_with_rain')}
        </Typography>
        <Typography variant='subtitle2'>
          {`${resume.daysWithRain} ${resume.daysWithRain > 1 ? I18n.t('v4/forecast.days') : I18n.t('v4/forecast.day')}`}
        </Typography>
      </Grid>
      <Grid item xs>
        <Typography variant='subtitle2' className={classes.Footer_lightFont}>
          {I18n.t('v4/forecast.total')}
        </Typography>
        <Typography variant='subtitle2'>
          {`${resume.pcpMinTotal} - ${resume.pcpMaxTotal} mm`}
        </Typography>
      </Grid>
    </Grid>
  </Paper>

const styles = (theme) => ({
  Footer_wrapper: {
    background: theme.palette.white,
    padding: `${(theme.spacing.unit * 2) + 4}px 0px`
  },
  Footer_container: {
    textAlign: 'center'
  },
  Footer_lightFont: {
    fontWeight: 400,
    fontSize: '10pt'
  }
})

export default withStyles(styles)(Footer)
