import React from 'react'

import { Button, Grid, Typography, withStyles } from '@material-ui/core'

import { getInfo, getVariableIcon } from '@models/equipment/equipment'

const Offline = ({
  classes,
  data,
  installed,
  dateAndTime,
  fetchOfflineStatus
}) => <>
    <Grid container spacing={0} className={classes.Offline_container}>
      <Grid item xs={12}>
        {
          !!installed || installed === null
            ? <Typography variant='body1' align='center'>
              {getVariableIcon(classes)['offline']}

              {I18n.t('v4/equipment.dashboard.status.offline')}
            </Typography>
            : <Typography variant='body1' align='center'>
              {I18n.t('v4/equipment.dashboard.status.uninstalled')}
            </Typography>
        }
      </Grid>

      <Grid item xs={12}>
        <Typography variant='body1' align='center'>
          {
            `${I18n.t('v4/equipment.dashboard.info_since_the_day')}
          ${dateAndTime.infoDate}
          ${dateAndTime.infoHour}`
          }
        </Typography>
      </Grid>
    </Grid>

    <Grid container spacing={0} className={classes.Offline_container}>
      <Grid item xs={12}>
        <Typography variant='body1' align='center'>
          {getInfo(installed)}
        </Typography>
      </Grid>
      <Grid item xs={12}>
        {
          installed !== null
            ? <Typography variant='body1' align='center' className={classes.Offline_Bold}>
              {
                !installed
                  ? I18n.t('v4/equipment.dashboard.info_equipment')
                  : I18n.t('v4/equipment.dashboard.info_contact')
              }
            </Typography>
            : <div className={classes.Equipment_ActionsStatus}>
              <Button
                color='secondary'
                className={classes.Equipment_actionStatus}
                onClick={() => fetchOfflineStatus(data, true)}
              >
                {I18n.t('false')}
              </Button>

              <Button
                color='primary'
                className={classes.Equipment_actionStatus}
                onClick={() => fetchOfflineStatus(data, false)}
                variant='contained'
              >
                {I18n.t('true')}
              </Button>
            </div>
        }
      </Grid>
    </Grid>
  </>

const styles = theme => ({
  Offline_container: {
    marginBottom: theme.spacing.unit * 2
  },
  Equipment_icons: {
    height: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit
  },
  Equipment_ActionsStatus: {
    padding: `${theme.spacing.unit}px 0`,
    display: 'flex',
    justifyContent: 'space-around'
  },
  Equipment_actionStatus: {
    width: '40%'
  },
  Offline_Bold: {
    fontWeight: 500
  }
})

Offline.defaultProps = {
  dateAndTime: ''
}

export default withStyles(styles)(Offline)
