import React, { useState, useEffect } from "react";
import { Typography, Link } from "@material-ui/core";
import { useHttpService } from "@contexts/HttpServiceProvider";
import Dialog from "@ui/Dialog";
import Form from "@ui/Form";

const InformProductivityDialog = ({
  isOpen,
  setIsOpen,
  harvesting_date,
  id
}) => {
  const { apiRequest } = useHttpService()
  const handleChangeState = async (action, values) => {
    try {
      await apiRequest.put(
        `/crops/${id}`, { crop:values }
      )
    } catch (error) {
      console.log(`Erro ao atualizar ${action} Erro: ${error}`)
    }
    window.location.reload()
  }

  const handleEdit = (values) => {
    try {
      handleChangeState("_harvest", {
        finished_at: harvesting_date,
        yield_value: values.yield,
        yield_unity: values.unity
      })
    } catch (error) {
      console.log(`Erro ao atualizar ${action} Erro: ${error}`)
    }
  }

  return (
    <Dialog
      open={isOpen}
      onClose={() => setIsOpen(false)}
      title={I18n.t("v4/area.dashboard.card.inform_produtivity")}
    >
      <Form
        action={I18n.t("actions.register")}
        handler={(values) => handleEdit(values)}
        onCancel={() => setIsOpen(false)}
        defaultDate={harvesting_date}
        selectedAction
      />
    </Dialog>
  );
};

function InformProductivity({ id, harvesting_date }) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(isOpen)
  }, [isOpen])

  return (
    <>
      <div>
        <Typography variant="caption">
          <Link
            underline="always"
            onClick={() => setIsOpen(true)}
            style={{ cursor: "pointer" }}
          >
            {I18n.t("v4/area.dashboard.inform_produtivity").toUpperCase()}
          </Link>
        </Typography>
      </div>
      <div>
        <InformProductivityDialog
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          harvesting_date={harvesting_date}
          id={id}
        />
      </div>
    </>
  );
}

export default InformProductivity;
