import React from 'react'

import {
  Button,
  Grid,
  withStyles,
} from '@material-ui/core'

import { Field } from 'react-final-form'

import TextField from '@ui/Fields/TextField'
import DatePicker from '@ui/Fields/DatePicker'

import styles from '@theme/styles/forms'

const Form = ({
  classes,
  handleSubmitManualEquipment,
  values,
  form
}) => {
  const submit = (event) => {
    event.preventDefault()

    handleSubmitManualEquipment(values)

    form.getFieldState('pl1').change('')
  }

  return (
    <form
      onSubmit={submit}
      className={classes.Form_wrapper}
    >
      <Grid container spacing={8} alignItems="center">
        <Grid item xs={4} className={classes.Form_precipitation}>
          <Field
            className={classes.form__fieldWithError}
            component={TextField}
            fullWidth
            InputLabelProps={{ shrink: true }}
            label={I18n.t('v4/equipment.pluviometer.precipitation')}
            margin='normal'
            name='pl1'
            required
            type='number'
            variant='outlined'
            InputProps={{
              endAdornment: 'mm',
              inputProps: {
                className: classes.Form_no_arrows
              }
            }}
          />
        </Grid>

        <Grid item xs={8} className={classes.Form_date}>
          <Field
            className={classes.form__fieldWithError}
            component={DatePicker}
            margin='normal'
            InputLabelProps={{ shrink: true }}
            variant='outlined'
            label={I18n.t('v4/equipment.pluviometer.date')}
            name='measured_at'
            fullWidth
            required
            disableFuture
          />
        </Grid>
      </Grid>

      <Button
        type='submit'
        color='primary'
        variant='contained'
        className={classes.Form_action}
        disabled={!values['pl1']}
        data-intercom-target='add-rain-manualraingauge-card'
      >
        {I18n.t('v4/equipment.pluviometer.actions.register_rain')}
      </Button>
    </form>
  )
}

const customStyles = theme => ({
  ...styles(theme),
  Form_wrapper: {
    marginTop: theme.spacing.unit * 2
  },
  Form_action: {
    width: '100%',
    marginTop: theme.spacing.unit * 2
  },
  Form_no_arrows: {
    '&::-webkit-outer-spin-button, &::-webkit-inner-spin-button': {
      '-webkit-appearance': 'none'
    },
    '-moz-appearance': 'textfield'
  },
  Form_date: {
    maxWidth: '55%',
    flexBasis: '55%'
  },
  Form_precipitation: {
    maxWidth: '45%',
    flexBasis: '45%'
  }
})

export default withStyles(customStyles)(Form)
