import React, { useState } from "react";

import { withStyles } from "@material-ui/core/styles";

import NavBar from "@ui/NavBar";
import SideBar from "@ui/SideBar";
import Banner from "@ui/Banner";
import { useSessionData } from "@contexts/SessionDataProvider";
import { useHttpService } from "@contexts/HttpServiceProvider";
import { useErrorHandler } from "@contexts/ErrorHandlerProvider";
import { getTrialInfo } from "@models/productsList";
import ForecastProduct from "./maps/productsFloatingMenu/Atmos/Index";
const minimumTrialDay = 1;

const App = ({ Component, classes, o, ...props }) => {
  const { currentOrganization, currentView, products } = useSessionData();
  const { apiRequest } = useHttpService();
  const handleError = useErrorHandler();

  const isNotMapCurrentView = currentView !== "maps/Index";
  const isForecastProductActive = products.find(
    (product) => product.kind === "weather_forecast"
  );

  const [stateTrial, setTrial] = useState({
    trial: {},
  });

  const fetchData = async () => {
    try {
      const params = { organization_id: currentOrganization.id };
      const { data } = await apiRequest("check_trial", { params });
      const trialInfo = getTrialInfo(data);

      setTrial({ ...stateTrial, trial: trialInfo });
    } catch (error) {
      handleError(error);
    }
  };

  if (currentOrganization.id !== undefined)
    _.isEmpty(stateTrial.trial) && fetchData();

  const expirationDate =
    stateTrial.trial.isTrial &&
    stateTrial.trial.expirationDate >= minimumTrialDay;
  const isTrial = stateTrial.trial.isTrial;

  return (
    <>
      {isTrial && (
        <Banner
          remainingDays={stateTrial.trial.expirationDate}
          expirationDate={!expirationDate}
        />
      )}
      <NavBar
        isTrial={isTrial}
        expiredTrial={!expirationDate && stateTrial.trial.isTrial}
      />
      <main className={classes.App_main}>
        <aside>
          <SideBar isTrial={isTrial} />
        </aside>

        <article className={classes.App_content}>
          <Component isTrial={isTrial} o={o} {...props} />
        </article>
        {isNotMapCurrentView && isForecastProductActive && <ForecastProduct />}
      </main>
    </>
  );
};

const styles = () => ({
  App_main: {
    display: "flex",
    width: "100%",
  },
  App_content: {
    width: "100%",
  },
});

export default withStyles(styles)(App);
