import React from 'react'

import withStyles from '@material-ui/core/styles/withStyles'
import styles from '@styles/indexPage'

import LoadingComponent from '@ui/LoadingComponent'

import { useMenusStatus, MENUS } from '@contexts/MenusStatusProvider'

import InfoMenuWrapper from '../InfoMenuWrapper'
import Uninstalled from './Uninstalled'
import Offline from './Offline'
import Unknown from './Unknown'
import Installed from './Installed'
import Support from './Support'
import Action from './Action'
import Battery from './Battery'
import Variables from './Variables'
import DepthTable from './DepthTable'
import InfoDate from './InfoDate'
import EquipmentSelect from './EquipmentSelect'
import ManualEquipmentInfoMenu from '../ManualEquipment'
import Hydrometer from '../Hydrometer'

import {IntercomAPI} from '@utils/IntercomAPI'
import {sendAmplitudeEvent} from '@utils/amplitudeEvent'

import { deserializeEquipmentMenu, getTime } from '@models/equipment/equipment'

const EquipmentInfoMenu = ({
  classes,
  fetchEquipmentStatus,
  selectedEquipment,
  equipments,
  fetchEquipmentMeasures,
  router,
  equipmentMenuData,
  loading,
  handleSubmitManualEquipment,
  handleSubmitReadingEstimate
}) => {
  const setVariableValues = () =>
    equipmentMenuData.descriptionVariables
      .map(equipment => ({
        ...equipment,
        value:
          equipment.name === 'bv1'
            ? equipmentMenuData.variableValue[equipment.name]
            : equipment.short_description === 'CE'
              ? Number(equipmentMenuData.variableValue[equipment.name]).toFixed(2)
              : Number(equipmentMenuData.variableValue[equipment.name]).toFixed(1)
      }))

  const handleMenuClose = () => {
    toggleMenu(MENUS.mapEquipmentMenu, false)
    IntercomAPI('trackEvent', 'Area Card - Clicked Close Button')
    sendAmplitudeEvent('Area Card - Clicked Close Button')
  }
  const depthVariables = deserializeEquipmentMenu(setVariableValues())
  const variablesNoDepth = setVariableValues().filter(variable => !variable.depth)
  const dateAndTime = getTime(equipmentMenuData.variableValue['measured_at'])

  const { menus, toggleMenu } = useMenusStatus()
  const open = menus[MENUS.mapEquipmentMenu]

  return (
    <InfoMenuWrapper open={open} onClose={() => handleMenuClose()}>
      <>
        {
          !loading
            ? <div className={!!equipmentMenuData.status
              ? classes.Equipment_Offline
              : classes.Equipment_Online}
            >
              <EquipmentSelect
                classes={classes}
                selectedEquipment={selectedEquipment}
                equipments={equipments}
                fetchEquipmentMeasures={fetchEquipmentMeasures}
              />

              {
                !!equipmentMenuData.status && selectedEquipment.kind !== 'manual'
                  ? <>
                    {
                      equipmentMenuData.status === 'uninstalled'
                        ? <Uninstalled classes={classes} />
                        : <Offline classes={classes} />
                    }

                    <InfoDate
                      classes={classes}
                      dateAndTime={dateAndTime}
                      label={I18n.t('v4/equipment.dashboard.info_since_the_day')}
                      alignCenter={true}
                    />

                    {
                      equipmentMenuData.status === 'unknown' &&
                      <Unknown classes={classes} fetchEquipmentStatus={fetchEquipmentStatus} />
                    }

                    {
                      equipmentMenuData.status === 'installed' &&
                      <Installed classes={classes} />
                    }

                    {
                      equipmentMenuData.status === 'uninstalled' &&
                      <Support classes={classes} />
                    }

                    {
                      equipmentMenuData.status !== 'unknown' &&
                      <Action
                        classes={classes}
                        router={router}
                        selectedEquipment={selectedEquipment}
                      />
                    }
                  </>
                  : selectedEquipment.kind === 'manual'
                    ? (
                      <ManualEquipmentInfoMenu
                        dateAndTime={dateAndTime}
                        equipmentId={selectedEquipment.id}
                        handleSubmitManualEquipment={handleSubmitManualEquipment}
                        precipitationValue={equipmentMenuData.variableValue['pl1']}
                      />
                    )
                    : !_.isEmpty(equipmentMenuData.hydrometer)
                      ? (
                        <Hydrometer
                          equipmentMenuData={equipmentMenuData}
                          selectedEquipment={selectedEquipment}
                          handleSubmitReadingEstimate={handleSubmitReadingEstimate}
                        />
                      )
                      : <>
                        {
                          !_.isEmpty(variablesNoDepth) &&
                          variablesNoDepth.map(variable =>
                            variable.name !== 'bv1' && variable.value !== 'error'
                              ? (
                                <Variables
                                  classes={classes}
                                  variable={variable}
                                  equipmentMenuData={equipmentMenuData}
                                />
                              )
                              : undefined
                          )
                        }

                        {
                          !_.isEmpty(depthVariables) &&
                          <>
                            <DepthTable
                              classes={classes}
                              depthVariables={depthVariables}
                              equipmentMenuData={equipmentMenuData}
                            />

                          </>
                        }

                        <Battery
                          classes={classes}
                          variable={variablesNoDepth.find(variable => variable.name === 'bv1')}
                        />

                        <InfoDate
                          classes={classes}
                          dateAndTime={dateAndTime}
                          label={I18n.t('v4/equipment.dashboard.last_update.info')}
                          alignCenter={false}
                        />

                        <Action
                          classes={classes}
                          router={router}
                          selectedEquipment={selectedEquipment}
                        />
                      </>
              }
            </div>
            : (
              <LoadingComponent classes={classes} />
            )
        }
      </>
    </InfoMenuWrapper >
  )
}

const Styles = theme => ({
  ...styles(theme),
  Equipment_Online: {
    padding: theme.spacing.unit,
    display: 'flex',
    flexDirection: 'column'
  },
  Equipment_Offline: {
    padding: theme.spacing.unit,
    minWidth: '300px'
  },
  Equipment_Actions: {
    display: 'flex',
    justifyContent: 'center',
    padding: `${theme.spacing.unit}px 0`
  },
  Equipment_action: {
    width: '95%'
  },
  Equipment_Select: {
    margin: `${theme.spacing.unit * 2}px ${theme.spacing.unit}px ${theme.spacing.unit}px ${theme.spacing.unit}px`,
    width: '85%'
  },
  Equipment_icon: {
    display: 'flex'
  },
  Equipment_Card: {
    padding: theme.spacing.unit,
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  Equipment_Battery: {
    display: 'flex',
    alignItems: 'center',
    padding: `${theme.spacing.unit}px 0 0 ${theme.spacing.unit}px`,
    marginTop: theme.spacing.unit
  },
  Equipment_last_update: {
    padding: theme.spacing.unit,
    fontSize: '13px'
  },
  Equipment_Status: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: theme.spacing.unit * 2
  },
  Equipment_installed_or_uninstalled: {
    paddingLeft: theme.spacing.unit
  },
  Equipment_date: {
    display: 'flex',
    justifyContent: 'center',
    paddingTop: theme.spacing.unit
  },
  Equipment_ActionsStatus: {
    padding: `${theme.spacing.unit}px 0`,
    display: 'flex',
    justifyContent: 'space-around'
  },
  Equipment_actionStatus: {
    width: '40%'
  },
  Equipment_Infos: {
    paddingTop: theme.spacing.unit * 2
  },
  Equipment_Variables: {
    display: 'flex',
    alignItems: 'center'
  },
  Equipment_icons: {
    height: theme.spacing.unit * 2,
    paddingRight: theme.spacing.unit
  },
  Equipment_wind_rose: {
    display: 'flex',
    justifyContent: 'center'
  }
})

EquipmentInfoMenu.defaultProps = {
  equipmentMenuData: {
    status: '',
    variableValue: {},
    descriptionVariables: []
  }
}

export default withStyles(Styles)(EquipmentInfoMenu)
