import { parsePhoneNumberFromString } from 'libphonenumber-js'

export const validate = values => {
  const errors = {}
  const requiredFields = [
    'first_name',
    'last_name',
    'phone',
    'email',
    'locale'
  ]

  requiredFields.forEach(field => {
    if (!values[field]) errors[field] = I18n.t('validations.required')
  })

  if (values.new_password !== values.retype_password) {
    let error_message = I18n.t('v4/account.validations.password.errors.new_password_dont_match_retype_password')

    errors.new_password = error_message
    errors.retype_password = error_message
  }

  const phone = values.phone && parsePhoneNumberFromString(values.phone)

  if (!phone || !phone.isPossible()) {
    errors.phone = I18n.t('validations.invalid_phone')
  }

  return errors
}

export const getCountries = () =>
  [
    {
      id: 'pt-BR',
      idIcon: 'br',
      name: I18n.t('activerecord.attributes.v4/organization.countries.br')
    },
    {
      id: 'es-MX',
      idIcon: 'es',
      name: I18n.t('activerecord.attributes.v4/organization.countries.es')
    },
    {
      id: 'en',
      idIcon: 'us',
      name: I18n.t('activerecord.attributes.v4/organization.countries.us')
    }
  ]
