import React, { useState } from 'react'

import { Paper, Button, Typography, withStyles } from '@material-ui/core'

import AreaChangeHandler from '@ui/AreaChangeHandler'
import AreaFields from './AreaFields'

import styles from '@theme/styles/forms'

const Form = ({ classes, onCancel, form, ...props }) => {
  const [areas, setAreas] = useState([...props.areas])
  const [selectedAreas, setSelectedAreas] = useState([])

  const handleSubmit = data => {
    setAreas([...props.areas])
    setSelectedAreas([])

    props.handleSubmit(data)
  }

  const onSetAreaId = id => {
    const selectedArea = areas.find(area => area.id === id)
    setSelectedAreas([ selectedArea ])
  }

  const removeArea = id => {
    const titleFieldState = form.getFieldState(`area_${id}[title]`)
    const descriptionFieldState = form.getFieldState(`area_${id}[description]`)

    const targetAreaIndex = selectedAreas.findIndex(area => area.id === id)
    const targetArea = selectedAreas.splice(targetAreaIndex, 1)

    titleFieldState.change('')
    descriptionFieldState.change('')

    setSelectedAreas(selectedAreas)
  }

  return (
    <form className={classes.form} onSubmit={handleSubmit}>
      <main className={classes.form__fields}>
        {
          selectedAreas.map((area, index) =>
            <AreaFields
              key={area.id}
              area={area}
              areaIndex={index}
              removeArea={removeArea}
            />
          )
        }

        <AreaChangeHandler
          handleChange={({ target }) => onSetAreaId(target.value)}
          label={I18n.t('v4/area.notes.select')}
          areas={areas}
        />
      </main>
      <footer className={classes.form__actions}>
        <Button
          className={classes.form__action}
          color='primary'
          type='submit'
          variant='contained'
        >
          {I18n.t('v4/irrigation.actions.save')}
        </Button>
        <Button
          className={classes.form__action}
          onClick={onCancel}
          color='secondary'
        >
          {I18n.t('v4/irrigation.actions.cancel')}
        </Button>
      </footer>
    </form>
  )
}

export default withStyles(styles)(Form)
