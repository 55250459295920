import React from 'react'
import moment from 'moment'

import Button from '@material-ui/core/Button'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import { Field } from 'react-final-form'

import DatePicker from '@ui/Fields/DatePicker'
import TextField from '@ui/Fields/TextField'
import MultipleSelect from '@ui/Fields/MultipleSelect'
import styles from '@theme/styles/forms'

const Form = ({
  areas,
  classes,
  handleSubmit,
  title,
  values: { start_date, end_date },
  o,
  ...props
}) => (
    <form className={classes.form} onSubmit={handleSubmit} data-testid="seasons-form">
      <Paper className={classes.form__wrapper} elevation={0}>
        <header className={classes.form__header}>
          <Typography component='h2' variant='h5'>
            {title}
          </Typography>
        </header>
        <main className={classes.form__fields}>
          <Grid container spacing={24}>
            <Grid item xs={12}>
              <div className={classes.form__field__wrapper}>
                <Field
                  component={TextField}
                  className={classes.form__fieldWithError}
                  margin='normal'
                  fullWidth
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  label={I18n.t('v4/seasons.tabs.season_name')}
                  name='name'
                  required
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className={classes.form__field__wrapper}>
                <Field
                  component={DatePicker}
                  className={classes.form__fieldWithError}
                  margin='normal'
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  label={I18n.t('v4/seasons.tabs.start_date')}
                  name='start_date'
                  maxDate={new Date(end_date)}
                  initialValue={start_date ? moment(start_date) : new Date()}
                  required
                  fullWidth
                />
              </div>
            </Grid>
            <Grid item xs={12} md={6}>
              <div className={classes.form__field__wrapper}>
                <Field
                  component={DatePicker}
                  className={classes.form__fieldWithError}
                  margin='normal'
                  InputLabelProps={{ shrink: true }}
                  variant="outlined"
                  label={I18n.t('v4/seasons.tabs.end_date')}
                  name='end_date'
                  minDate={new Date(start_date)}
                  initialValue={end_date ? moment(end_date) : new Date()}
                  required
                  fullWidth
                />
              </div>
            </Grid>
            <Grid item xs={12}>
              <div className={classes.form__field__wrapper}>
                <Field
                  format={value => Array.isArray(value) ? value : []}
                  component={MultipleSelect}
                  className={classes.form__fieldWithError}
                  margin='normal'
                  fullWidth
                  label={I18n.t('v4/seasons.tabs.areas')}
                  name='areas'
                  fields={{ id: 'id', name: 'name' }}
                  data={areas}
                />
              </div>
            </Grid>
          </Grid>
        </main>
        <footer className={classes.form__actions}>
          <Button
            className={classes.form__action}
            color='primary'
            type='submit'
            variant='contained'
          >
            {title}
          </Button>

          <Button
            className={classes.form__action}
            color='secondary'
            href={`/v4/o/${o}/seasons`}
          >
            {I18n.t('actions.back')}
          </Button>
        </footer>
      </Paper>
    </form>
  )

Form.defaultProps = {
  areas: []
}

export default withStyles(styles)(Form)
