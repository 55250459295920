import React from 'react'

import IrrigationDashboard from '../irrigation/management'

import {
  getDemandedIrrigationData,
  getAcumulatedInCropData,
  deserializeData,
  deserializeEventsData,
  deserializePhenologicalStages
} from '@models/irrigation_managements/dashboard'
import httpService from '@services/httpService'


class Dashboard extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      data: [],
      weeklyData: [],
      seasonData: [],
      events: [],
      demandedIrrigationData: [],
      fetching: false
    }
  }

  async componentDidMount() {
    const { model, organization_id, handleError } = this.props

    const { weeklyData, weeklyEvents, seasonEvents, seasonData } = await this.getData(model.id, organization_id, handleError)
    const demandedIrrigationData = getDemandedIrrigationData(weeklyData)

    this.setState({
      weeklyData,
      seasonData,
      weeklyEvents,
      seasonEvents,
      demandedIrrigationData
    })
  }

  getData = async (id, organization_id, handleError) => {
    this.setState({ fetching: true })

    try {
      const promises = [
        httpService.get(`/api/v4/irrigation_managements/${id}/hydric_balance`, { params: { organization_id, type: 'weekly' } }),
        httpService.get(`/api/v4/irrigation_managements/${id}/hydric_balance`, { params: { organization_id, type: 'season' } }),
        httpService.get(`/api/v4/irrigation_managements/${id}/events`, { params: { organization_id, type: 'weekly' } }),
        httpService.get(`/api/v4/irrigation_managements/${id}/events`, { params: { organization_id, type: 'season' } })
      ]

      const [weeklyData, seasonData, weeklyEvents, seasonEvents] = await Promise.all(
        promises.map(promise => promise.catch(() => ({ data: [] })))
      )

      this.setState({ fetching: false })

      const deserializedWeeklyData = deserializeData(weeklyData.data)
      const deserializedWeeklyEvents = deserializeEventsData(weeklyEvents.data, deserializedWeeklyData)

      const deserializedSeasonData = deserializeData(seasonData.data)
      const deserializedSeasonEvents = deserializeEventsData(seasonEvents.data, deserializedSeasonData)

      return {
        weeklyData: deserializedWeeklyData,
        seasonData: deserializedSeasonData,
        weeklyEvents: deserializedWeeklyEvents,
        seasonEvents: deserializedSeasonEvents
      }
    }
    catch (error) {
      handleError(error)

      this.setState({ fetching: false })

      return {
        weeklyData: [],
        seasonData: [],
        weeklyEvents: [],
        seasonEvents: []
      }
    }
  }

  render() {
    const { weeklyData, seasonData, fetching, demandedIrrigationData, weeklyEvents, seasonEvents } = this.state
    const { model, crop, phenological_stages, managements } = this.props

    const phenologicalStages = deserializePhenologicalStages(phenological_stages)
    const acumulatedInCrop = getAcumulatedInCropData(crop)

    return (
      <IrrigationDashboard
        crop={crop}
        weeklyData={weeklyData}
        seasonData={seasonData}
        weeklyEvents={weeklyEvents}
        seasonEvents={seasonEvents}
        demandedIrrigation={demandedIrrigationData}
        fetching={fetching}
        currentManagement={model.id}
        phenologicalStages={phenologicalStages}
        managements={managements}
        acumulatedInCrop={acumulatedInCrop}
      />
    )
  }
}

Dashboard.defaultProps = {
  model: {},
  crop: {},
  phenological_stages: [],
  managements: []
}

export default Dashboard
