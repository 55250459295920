import React from 'react'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import MaterialTable from 'material-table'

import ShowStage from './stages/Show'
import styles from '@styles/showPage'
import { fields } from '@models/culture'
import { getPhenologicalStagesHeaders } from '@models/phenologicalStages'

export const Show = ({ classes, model, o }) =>
  <Paper
    className={classes.showPage__wrapper}
    elevation={0}
  >
    <header className={classes.showPage__header}>
      <Typography component='h2' variant='h5'>
        {I18n.t('v4/culture.show.title')}
      </Typography>
    </header>

    <main className={classes.showPage__values}>
      {
        fields().map((field) =>
          model[field.name] && !field.hidden &&
          <div key={field.name} className={classes.showPage__value}>
            <Typography
              color='textSecondary'
              component='h6'
              gutterBottom
              variant='h6'
            >
              {I18n.t(`activerecord.attributes.v4/culture.${field.name}`)}
            </Typography>

            <Typography paragraph variant='subtitle1' data-testid={field.name}>
              {
                field.translation
                  ? field.translation(model[field.name])
                  : model[field.name]
              } {field.measure}
            </Typography>
          </div>
        )
      }

      <MaterialTable
        columns={getPhenologicalStagesHeaders()}
        detailPanel={stage =>
          <ShowStage
            phenologicalStage={stage}
          />
        }
        data={_.sortBy(model.phenological_stages, ['order'])}
        title={I18n.t('v4/culture.phenological_stages.index')}
        options={{
          search: false,
          paging: false,
          sorting: false,
          headerStyle: {
            backgroundColor: '#f5f5f5'
          }
        }}
      />
    </main>

    <footer className={classes.showPage__actions}>
      <Button
        className={classes.showPage__action}
        color='primary'
        variant='contained'
        href={`/v4/o/${o}/cultures/${model.id}/edit`}
      >
        {`${I18n.t('actions.edit')} ${I18n.t('activerecord.models.v4/culture.one')}`}
      </Button>

      <Button
        className={classes.showPage__action}
        color='secondary'
        href={`/v4/o/${o}/cultures`}
      >
        {I18n.t('actions.back')}
      </Button>
    </footer>
  </Paper>

Show.propTypes = {
  model: PropTypes.object
}

const pageWithStyles = withStyles(styles)(Show)

export default pageWithStyles
