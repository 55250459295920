import PropTypes from 'prop-types'

export const validate = values => {
  const fields = [
    'name', 'brand', 'category', 'formulation', 'reg_code',
    'security_interval', 'active_principle'
  ]

  const errors = {}

  fields.forEach(field => {
    if (!values[field]) errors[field] = I18n.t('validations.required')
  })

  return errors
}

export const chemicalHeaders = [
  { title: I18n.t('activerecord.attributes.v4/input.category'), field: 'category_text' },
  { title: I18n.t('activerecord.attributes.v4/input.formulation'), field: 'formulation_text' }
]

export const chemicalShape = PropTypes.shape({
  name: PropTypes.string,
  brand: PropTypes.string,
  category: PropTypes.string,
  category_text: PropTypes.string,
  formulation: PropTypes.string,
  formulation_text: PropTypes.string,
  security_interval: PropTypes.number,
  reg_code: PropTypes.string,
  active_principle: PropTypes.string,
  detail: PropTypes.string,
  kind: PropTypes.string
})
