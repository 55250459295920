import React, { useEffect } from 'react'

import { Paper, Typography, withStyles } from '@material-ui/core'

import Action from './Action'

const EditAreaMenu = ({
  classes,
  handleCancelAreaMenu,
  handleSaveAreaMenu,
  enableOrDisableArea
}) => {
  useEffect(() => {
    enableOrDisableArea().editing.enable()
  }, [])

  return (
    <Paper
      className={classes.EditAreaMenu_wrapper}
    >
      <Typography
        variant='body2'
        align='center'
      >
        {I18n.t('v4/map.edit_area_menu.title')}
      </Typography>

      <div className={classes.EditAreaMenu_actions}>
        <Action
          variant='outlined'
          label={I18n.t('actions.cancel')}
          onChange={handleCancelAreaMenu}
        />

        <Action
          variant={'contained'}
          label={I18n.t('actions.save')}
          onChange={handleSaveAreaMenu}
        />
      </div>
    </Paper>
  )
}

const styles = theme => ({
  EditAreaMenu_wrapper: {
    height: '110px',
    zIndex: 1500,
    position: 'absolute',
    cursor: 'pointer',
    bottom: 0,
    left: '50%',
    marginLeft: '-115px',
    padding: theme.spacing.unit * 2
  },
  EditAreaMenu_actions: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: theme.spacing.unit * 2
  }
})

export default withStyles(styles)(EditAreaMenu)
