import React from 'react'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

import { fields, paramsTableHeaders } from '@models/admin/sensor'
import Table from '@ui/Table'
import styles from '@styles/showPage'

export const Show = ({ classes, model, o }) =>
  <Paper
    className={classes.showPage__wrapper}
    elevation={0}
  >
    <header className={classes.showPage__header}>
      <Typography component='h2' variant='h5'>
        {I18n.t('v4/sensor.show.title')}
      </Typography>
    </header>

    <main className={classes.showPage__values}>
      {
        fields.map((field) =>
          model[field] !== undefined &&
          <div key={field} className={classes.showPage__value}>
            <Typography
              color='textSecondary'
              component='h6'
              gutterBottom
              variant='h6'
            >
              {I18n.t(`activerecord.attributes.v4/sensor.${field}`)}
            </Typography>

            <Typography paragraph variant='subtitle1' data-testid={field}>
              {
                _.isArray(model[field])
                  ? model[field].map((value, index) =>
                    index === model[field].length - 1
                      ? value
                      : `${value}, `)
                  : _.isBoolean(model[field])
                    ? I18n.t(model[field].toString())
                    : model[field]
              }
            </Typography>
          </div>
        )
      }

      <Table
        tableKey='admin-sensors-show'
        title={I18n.t('activerecord.attributes.v4/sensor.params')}
        columns={paramsTableHeaders}
        data={model.params}
        options={{
          search: false,
          paging: false
        }}
      />
    </main>

    <footer className={classes.showPage__actions}>
      <Button
        className={classes.showPage__action}
        color='primary'
        variant='contained'
        href={`/v4/o/${o}/admin/sensors/${model.id}/edit`}
      >
        {I18n.t('v4/sensor.actions.update')}
      </Button>

      <Button
        className={classes.showPage__action}
        color='secondary'
        href={`/v4/o/${o}/admin/sensors`}
      >
        {I18n.t('actions.back')}
      </Button>
    </footer>
  </Paper>

Show.propTypes = {
  model: PropTypes.object
}

export default withStyles(styles)(Show)
