export default theme => ({
    card: {
        borderLeft: `4px solid ${theme.palette.trialColorRed}`,
        marginTop: theme.spacing.unit,
        marginBottom: theme.spacing.unit,
        cursor: 'pointer'
    },
    card_container: {
        
    },
    card_header: {
        display: 'flex',
        justifyContent: 'space-between',
    },
    card_tag_important: {
        margin: theme.spacing.unit,
        color: theme.palette.trialColorRed,
        backgroundColor: '#FFDEDE',
        height: theme.spacing.unit * 3,
    },
    card_tag_new: {
        margin: theme.spacing.unit,
        color: theme.palette.white,
        backgroundColor: theme.palette.trialColorRed,
        height: theme.spacing.unit * 3,
    },
    card_subtitle: {
        fontSize: '12px',
        color: theme.palette.gray.dark
      },
    card_area: {
        padding: theme.spacing.unit,
        paddingTop: 0
    },
    card_icon: {
        color: theme.palette.cards.yellow
    }
})