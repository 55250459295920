import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'
import MembersIcon from '@material-ui/icons/Group'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import Table from '@ui/Table'

import { getCouponHeaders } from '@models/admin/coupon'
import DeleteDialog from '@ui/DeleteDialog'
import styles from '@styles/indexPage'
import { deserialize } from '@models/admin/coupon'

class Index extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isDialogOpen: false,
      id: null
    }

  }

  closeDialog = () => {
    this.setState({ isDialogOpen: false })
  }

  render() {
    const { classes, model, o } = this.props
    const { isDialogOpen, id } = this.state
    const cupons = deserialize(model)

    return (
      <Paper className={classes.page__wrapper} elevation={0}>
        <header className={classes.page__header}>
          <Typography component='h2' variant='h5'>
            {I18n.t('activerecord.models.v4/coupons.other')}
          </Typography>
        </header>
        <Button
          variant='outlined'
          color='primary'
          className={classes.page__newResourceButton}
          href={`/v4/o/${o}/store/admin/coupons/new`}
        >
          {I18n.t('actions.create')} {I18n.t('activerecord.models.v4/coupons.one')}
        </Button>

        <Table
          tableKey='store-admin-coupons-index'
          components={{
            Container: props => <div>{props.children}</div>,
          }}
          columns={getCouponHeaders()}
          data={cupons}
          actions={[
            rowData => ({
              icon: 'create',
              iconProps: { 'data-testid': `edit-${rowData.id}` },
              tooltip: I18n.t('actions.edit'),
              onClick: (event, rowData) => {
                Turbolinks.visit(`/v4/o/${o}/store/admin/coupons/${rowData.id}/edit`)
              }
            }),
            rowData => ({
              icon: 'delete',
              iconProps: { 'data-testid': `delete-${rowData.id}` },
              tooltip: I18n.t('actions.remove'),
              onClick: (event, rowData) => {
                this.setState({ isDialogOpen: true, id: rowData.id })
              }
            })
          ]}
          options={{
            showTitle: false
          }}
        />

        <DeleteDialog
          dialogTitle={`${I18n.t('actions.remove')} ${I18n.t('activerecord.models.v4/coupons.one')}?`}
          dialogText={I18n.t('confirmation.remove')}
          successUrl={`/v4/o/${o}/store/admin/coupons`}
          deleteUrl={`/v4/o/${o}/store/admin/coupons/${id}.json`}
          onCancel={this.closeDialog}
          onClose={this.closeDialog}
          open={isDialogOpen}
        />
      </Paper>
    )
  }
}

Index.propTypes = {
  model: PropTypes.array
}

export default withStyles(styles)(Index)
