import React from 'react'
import PropTypes from 'prop-types'
import { Form as FinalForm } from 'react-final-form'

import httpService from '@services/httpService'
import { serialize } from '@models/admin/sensor_config'
import { RailsForm } from '@support/RailsForm'
import Form from './Form'

export const Edit = ({ model, sensors, part_number_registries, handleError, o }) => {

  const handleSubmit = async values => {
    try {
      const body = serialize(values)

      await httpService.put(`/v4/o/${o}/admin/sensor_configs/${model.id}`, body)
      Turbolinks.visit(`/v4/o/${o}/admin/sensor_configs`)
    } catch (e) {
      handleError(e)
    }
  }

  return <RailsForm
    component={FinalForm}
    action='update'
    url={`/v4/o/${o}/admin/sensor_configs/${model.id}`}
    successUrl={`/v4/o/${o}/admin/sensor_configs/${model.id}`}
    handleSubmit={handleSubmit}
    initialValues={model}
    render={(props) => {
      return <Form
        {...props}
        params={model.params}
        sensors={sensors}
        partNumbers={part_number_registries}
        title={I18n.t('v4/configuration.actions.update')}
        o={o}
      />
    }}
  />
}

Edit.defaultProps = {
  model: {}
}

Edit.propTypes = {
  model: PropTypes.object,
}

export default Edit
