import React from 'react'

const BoundedGraphic = ({ operations }) => {
  const squaresPerLine = Math.round(Math.sqrt(Number(operations)))
  const operationsArray = [...Array(Number(operations)).keys()]
  const chunckedArray = _.chunk(operationsArray, squaresPerLine)

  return !!operations && (
    <div>
      {
        chunckedArray.map(line =>
          <div key={line} style={{ display: 'flex' }}>
            {line.map(square => <Square key={square} />)}
          </div>
        )
      }
    </div>
  )
}

const Square = () =>
  <div
    style={{
      width: '15px',
      height: '15px',
      border: '1px solid #c6c6c6',
      background: '#3e98c7'
    }}
  />

BoundedGraphic.defaultProps = {
  operations: 0
}

export default BoundedGraphic
