import React from 'react'

import { Popover, withStyles } from '@material-ui/core'

import Control from './Control'

import { controlsMenu } from '@models/map'

const CustomMenu = ({
  classes,
  handleAreaFileControlClick,
  onClose,
  open
}) =>
  <Popover
    id='ControlCustom'
    open={open}
    anchorEl={document.getElementById('leaflet-control-area-menu')}
    onClose={onClose}
    classes={{ paper: classes.Popover }}
    anchorOrigin={{
      vertical: 'bottom',
      horizontal: 'left'
    }}
    transformOrigin={{
      vertical: 'bottom',
      horizontal: 'right'
    }}
  >
    <div className={classes.CustomMenu_wrapper}>
      {
        controlsMenu(handleAreaFileControlClick).map(menu =>
          <Control
            key={menu.value}
            onClose={onClose}
            {...menu}
          />
        )
      }
    </div>
  </Popover>

const styles = theme => ({
  Popover: {
    overflow: 'hidden',
    minWidth: '170px',
    maxWidth: '200px',
    padding: theme.spacing.unit
  },
  CustomMenu_wrapper: {
    display: 'flex',
    flexDirection: 'column'
  }
})

export default withStyles(styles)(CustomMenu)
