import React from 'react'
import { Field as FinalFormField } from 'react-final-form'

import { Button, Paper, Typography, Grid, Divider } from '@material-ui/core'

import withStyles from '@material-ui/core/styles/withStyles'
import Field from '@ui/Fields/Field'
import CheckBox from '@ui/Fields/CheckBox'
import styles from './styles'
import WhatsApp from '@ui/Icons/WhatsApp'


class Form extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      enableNotifications: []
    }
  }

  componentDidMount() {
    const { values } = this.props
    let enableNotifications = []

    if (!(Object.keys(values).length === 0)) {
      enableNotifications = Object.keys(values).map((field, index) => values[field].notifications_enabled)
    }

    this.setState({ enableNotifications })
  }

  handleChangeNotification = ({ target, fieldCurrent, index }) => {
    const { form } = this.props

    form.getFieldState(`${fieldCurrent}[notifications_enabled]`).change(target.value)

    const enableNotifications = [...this.state.enableNotifications]
    enableNotifications[index] = target.value

    this.setState({ enableNotifications })
  }

  render() {
    const { handleSubmit, classes, title, customFields, current_forecast_points, o } = this.props
    const { enableNotifications } = this.state

    return (
      <form className={classes.form} onSubmit={handleSubmit} data-testid="notification-form">
        <Paper className={classes.form__wrapper} elevation={0}>
          <header className={classes.form__header}>
            <Typography component='h2' variant='h5'>
              {title}
            </Typography>
          </header>
          {
            customFields.map((element, index) =>
              <main className={classes.form__fields} key={index}>
                <Grid container spacing={24}>
                  <Field
                    label={I18n.t('activerecord.attributes.v4/organization_products.forecast.forecast_points.name')}
                    name={`custom_field${index + 1}[name]`}
                    type='text'
                    gridItem
                    required={!!enableNotifications[index]}
                  />
                  <Field
                    label={I18n.t('activerecord.attributes.v4/organization_products.forecast.forecast_points.forecast_point')}
                    name={`custom_field${index + 1}[forecast_point_id]`}
                    data={current_forecast_points}
                    required={!!enableNotifications[index]}
                    gridItem
                    type='select'
                  />
                  <Grid item xs={6}>
                    <div className={classes.form__field__wrapper}>
                      <FinalFormField
                        component={CheckBox}
                        className={classes.form__fieldWithError}
                        fullWidth
                        InputLabelProps={{ shrink: true }}
                        margin='normal'
                        key={index}
                        name={`custom_field${index + 1}[notifications_enabled]`}
                        checked={!!enableNotifications[index]}
                        onChange={(event) => this.handleChangeNotification({
                          target: {
                            value: !!event.target.checked
                          },
                          fieldCurrent: `custom_field${index + 1}`,
                          index: index
                        })}
                        type='string'
                        variant="outlined"
                        label={
                          <div className={classes.Whatsapp_Container}>
                            <Typography variant='subtitle2' className={classes.Whatsapp_Label}>
                              {I18n.t('activerecord.attributes.v4/organization_products.forecast.forecast_points.notification')}
                            </Typography>
                            
                            <div className={classes.Whatsapp_Wrapper}>
                              <WhatsApp className={classes.Whatsapp_Image} />
                            </div>
                          </div>
                        }
                      />
                    </div>
                  </Grid>
                  <Field
                    label={I18n.t('activerecord.attributes.v4/organization_products.forecast.forecast_points.phone')}
                    name={`custom_field${index + 1}[contact]`}
                    required={!!enableNotifications[index]}
                    type='telephone'
                    gridItem
                    gridXs={6}
                  />
                </Grid>
                <Divider variant='middle' />
              </main>
            )}

          <footer className={classes.form__actions}>
            <Button
              className={classes.form__action}
              color='primary'
              type='submit'
              variant='contained'
            >
              {title}
            </Button>

            <Button
              className={classes.form__action}
              color='secondary'
              href={`/v4/o/${o}/maps`}
            >
              {I18n.t('actions.back')}
            </Button>
          </footer>
        </Paper>
      </form>
    )
  }
}

export default withStyles(styles)(Form)
