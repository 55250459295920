import React from 'react'
import { Form as FinalForm } from 'react-final-form'
import PropTypes from 'prop-types'

import { validate, submit } from '@models/account/payment_profiles'

import { RailsForm } from '@support/RailsForm'
import Form from './Form'

export const New = ({ organization_id, gateway_base_url, gateway_public_key, handleError, o }) => {
  const payment_method_code = I18n.t('activerecord.attributes.v4/organization_account.payment_profiles.payment_method_code')

  return (
    <RailsForm
      component={FinalForm}
      action='create'
      url={`/v4/o/${o}/organizations/${organization_id}/payment_profiles`}
      successUrl={`/v4/o/${o}/organizations/${organization_id}/payment_profiles`}
      validate={validate}
      initialValues={{ payment_method_code }}
      handleSubmit={(values) => submit({ values, event, handleError, gateway_base_url, gateway_public_key, organization_id, o })}
      render={(props) => (
        <Form
          {...props}
          gateway_base_url={gateway_base_url}
          gateway_public_key={gateway_public_key}
          title={I18n.t('activerecord.attributes.v4/organization_account.payment_profiles.actions.add')}
          organization_id={organization_id}
          o={o}
        />
      )}
    />
  )
}

export default New
