import React from 'react'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import { Field } from 'react-final-form'

import PillTextField from '@ui/Fields/PillTextField'
import TextField from '@ui/Fields/TextField'
import MultipleSelect from '@ui/Fields/MultipleSelect'
import ParamsTable from '../sensors/ParamsTable'
import Select from '@ui/Fields/Select'
import styles from '@theme/styles/forms'

import { equipments } from '@models/equipment/equipment'

const Form = ({
  handleSubmit,
  classes,
  partNumbers,
  params,
  title,
  sensors,
  values,
  showSelecEquipment,
  o
}) => {
  const updateParams = (params) => {
    values['params'] = params
  }

  return (
    <form className={classes.form} onSubmit={handleSubmit} data-testid='configuration-form'>
      <Paper className={classes.form__wrapper} elevation={0}>
        <header className={classes.form__header}>
          <Typography component='h2' variant='h5'>
            {title}
          </Typography>
        </header>
        <main className={classes.form__fields}>
          <div className={classes.form__field__wrapper}>
            <Field
              component={TextField}
              className={classes.form__fieldWithError}
              margin='normal'
              fullWidth
              InputLabelProps={{ shrink: true }}
              variant='outlined'
              label={I18n.t('activerecord.attributes.v4/configuration.name')}
              name='name'
              required
            />
          </div>

          {
            showSelecEquipment &&
            <div className={classes.form__field__wrapper}>
              <Field
                component={Select}
                className={classes.form__fieldWithError}
                InputLabelProps={{ shrink: true }}
                label={I18n.t('activerecord.models.v4/equipment.one')}
                name='usage'
                margin='normal'
                fields={{ id: 'usage', name: 'name' }}
                data={equipments}
                fullWidth
                variant='outlined'
                required
              />
            </div>
          }

          {
            values['usage'] === 'automatic' &&
            <div className={classes.form__field__wrapper}>
              <Field
                component={Select}
                className={classes.form__fieldWithError}
                InputLabelProps={{ shrink: true }}
                label={I18n.t('activerecord.attributes.v4/configuration.part_number')}
                name='part_number_id'
                margin='normal'
                fields={{ id: 'id', name: 'value' }}
                data={partNumbers}
                fullWidth
                variant='outlined'
                required
              />
            </div>
          }

          <div className={classes.form__field__wrapper}>
            <Field
              format={value => Array.isArray(value) ? value : []}
              component={MultipleSelect}
              className={classes.form__fieldWithError}
              margin='normal'
              fullWidth
              label={I18n.t('activerecord.models.v4/sensor.other')}
              name='sensor_ids'
              fields={{ id: 'id', name: 'name' }}
              data={sensors}
            />
          </div>

          <div className={classes.form__field__wrapper}>
            <Field
              component={TextField}
              className={classes.form__fieldWithError}
              margin='normal'
              fullWidth
              InputLabelProps={{ shrink: true }}
              variant='outlined'
              label={I18n.t('activerecord.attributes.v4/configuration.description')}
              name='description'
              multiline
            />
          </div>

          <div className={classes.form__field__wrapper}>
            <Field
              component={PillTextField}
              variant='outlined'
              margin='normal'
              fullWidth
              InputLabelProps={{ shrink: true }}
              name='tags'
              label={I18n.t('activerecord.attributes.v4/sensor.tags')}
            />
          </div>
          <ParamsTable
            updateParams={updateParams}
            params={params}
          />
        </main>
        <footer className={classes.form__actions}>
          <Button
            className={classes.form__action}
            color='primary'
            type='submit'
            variant='contained'
          >
            {title}
          </Button>
          <Button
            className={classes.form__action}
            color='secondary'
            href={`/v4/o/${o}/admin/sensor_configs`}
          >
            {I18n.t('actions.back')}
          </Button>
        </footer>
      </Paper>
    </form>
  )
}
export default withStyles(styles)(Form)

Form.defaultProps = {
  partNumbers: [],
  sensors: [],
  params: []
}
