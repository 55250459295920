import React from 'react'
import PropTypes from 'prop-types'
import { Form as FinalForm } from 'react-final-form'

import { RailsForm } from '@support/RailsForm'
import { validate, handleSubmit } from '@models/admin/sensor'

import Form from './Form'

export const Edit = (props) => {
  const { model, all_variables, handleError, o } = props
  const variables = all_variables.map(variable => ({
    ...variable,
    id: variable.name
  }))

  return <RailsForm
    component={FinalForm}
    action='update'
    validate={validate}
    initialValues={model}
    handleSubmit={values => handleSubmit(values, handleError, model.id, o)}
    render={(props) => {
      const params = model.params
      return <Form
        variables={variables}
        {...props}
        params={params}
        title={I18n.t('v4/sensor.actions.update')}
        o={o}
      />
    }}
  />
}

Edit.defaultProps = {
  model: {}
}

Edit.propTypes = {
  model: PropTypes.object
}

export default Edit
