import React from 'react'
import { getAccumulatedRainStatus } from '@models/weather/dashboard'
import { Typography } from '@material-ui/core'

const Legend = ({ classes }) => {
  const rainStatus = getAccumulatedRainStatus()

  return (
    <div className={classes.chart__legend_wrapper}>
      <Typography>
        {I18n.t('v4/weather.charts.accumulated_rain.chance_of_rain')}:
      </Typography>
      <div className={classes.chart__legend_colors}>
        {Object.keys(rainStatus).map(legend =>
          <div className={classes.chart__legend} key={legend}>
            <div
              className={classes.chart__legend_color}
              style={{ backgroundColor: rainStatus[legend].color }}
            />
            <Typography>
              {rainStatus[legend].description}
            </Typography>
          </div>
        )}
      </div>
    </div>
  )
}

export default Legend
