import React, { useState } from 'react'
import { Grid } from '@material-ui/core'

import Field from '@ui/Fields/Field'

const CustomField = ({ classes, altValue, form, values, ...field }) => {
  const props = {
    type: field.type,
    inputProps: {},
    ...field
  }

  if (field.type === 'select') {
    props.initialValue = !!field.id ? 0 : field.data[0].value || field.data[0].name

    if (altValue !== undefined) {
      if (field.name === 'optimal_water_loss') {
        props.initialValue = values[field.name] ? values[field.name] : field.data[0].value || field.data[0].name
      }
      const initialValue =
        values[field.name]
          ? values[field.name] === altValue.dependency
            ? values[`${field.name}_real`]
            : props.initialValue
          : ''

      const [isRealDisabled, setIsRealDisabled] = useState(values[field.name] !== altValue.dependency)

      return (
        <div className={classes.form__field__wrapper}>
          <Grid container spacing={24}>
            <Field
              gridItem
              gridXs={8}
              {...props}
              type='controlledSelect'
              onInputChange={v => {
                const tgtValue = v.target.value
                const isDependency = tgtValue === altValue.dependency
                const value = isDependency ? altValue.default : tgtValue

                form.change(`${field.name}__real`, value)
                setIsRealDisabled(!isDependency)
              }}
            />
            <Field
              gridItem
              gridXs={4}
              withWrapper={false}
              name={`${field.name}__real`}
              type={altValue.type}
              disabled={isRealDisabled}
              initialValue={initialValue}
              label={altValue.label}
            />
          </Grid>
        </div>
      )
    }
  }

  if (field.type === 'number') {
    if (!isNaN(field.min)) {
      props.inputProps.min = field.min
    }

    if (!isNaN(field.max)) {
      props.inputProps.max = field.max
    }
  }

  return <Field {...props} />
}

export default CustomField
