import React from 'react'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import { Field } from 'react-final-form'

import TextField from '../../../ui/Fields/TextField'
import Select from '../../../ui/Fields/Select'
import styles from '@theme/styles/forms'


const Form = ({
  handleSubmit,
  classes,
  title,
  activeValues,
  alertCategories,
  organizationId,
  o
}) => (
    <form className={classes.form} onSubmit={handleSubmit} data-testid="alerts-form">
      <Paper className={classes.form__wrapper} elevation={0}>
        <header className={classes.form__header}>
          <Typography component='h2' variant='h5'>
            {title}
          </Typography>
        </header>
        <main className={classes.form__fields}>
          <div className={classes.form__field__wrapper}>
            <Field
              component={TextField}
              className={classes.form__fieldWithError}
              margin='normal'
              fullWidth
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              label={I18n.t('v4/alerts.alerts.name')}
              name='name'
              required
            />
          </div>
          <div className={classes.form__field__wrapper}>
            <Field
              component={Select}
              className={classes.form__fieldWithError}
              InputLabelProps={{ shrink: true, required: true }}
              label={I18n.t('v4/alerts.alerts.active')}
              name='active'
              margin='normal'
              fields={{id: 'value', name: 'name' }}
              data={activeValues}
              fullWidth
              variant="outlined"
              required
            />
          </div>
          <div className={classes.form__field__wrapper}>
            <Field
              component={Select}
              className={classes.form__fieldWithError}
              InputLabelProps={{ shrink: true, required: true }}
              label={I18n.t('v4/alerts.alerts.category')}
              name='category'
              margin='normal'
              fields={{ id: 'value', name: 'name' }}
              data={alertCategories}
              fullWidth
              variant="outlined"
              required
            />
          </div>
          <div className={classes.form__field__wrapper}>
            <Field
              component={TextField}
              className={classes.form__fieldWithError}
              margin='normal'
              fullWidth
              type='number'
              InputLabelProps={{ shrink: true }}
              InputProps={{ inputProps: { min: "1" } }}
              variant="outlined"
              label={I18n.t('v4/alerts.alerts.notification_interval')}
              name='notification_interval'
            />
          </div>
          <div className={classes.form__field__wrapper}>
            <Field
              component={TextField}
              className={classes.form__fieldWithError}
              margin='normal'
              fullWidth
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              label={I18n.t('v4/alerts.alerts.custom_message_en')}
              name='custom_message_en'
            />
          </div>
          <div className={classes.form__field__wrapper}>
            <Field
              component={TextField}
              className={classes.form__fieldWithError}
              margin='normal'
              fullWidth
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              label={I18n.t('v4/alerts.alerts.custom_message_es')}
              name='custom_message_es'
            />
          </div>
          <div className={classes.form__field__wrapper}>
            <Field
              component={TextField}
              className={classes.form__fieldWithError}
              margin='normal'
              fullWidth
              InputLabelProps={{ shrink: true }}
              variant="outlined"
              label={I18n.t('v4/alerts.alerts.custom_message_pt')}
              name='custom_message_pt'
            />
          </div>
        </main>
        <footer className={classes.form__actions}>
          <Button
            className={classes.form__action}
            color='primary'
            type='submit'
            variant='contained'
          >
            {title}
          </Button>
          <Button
            className={classes.form__action}
            color='secondary'
            href={`/v4/o/${o}/admin/organizations/${organizationId}/alerts`}
          >
            {I18n.t('actions.back')}
          </Button>
        </footer>
      </Paper>
    </form>
  )

export default withStyles(styles)(Form)
