import React, { Component } from 'react'

import { Paper, Button, withStyles, Typography } from '@material-ui/core'
import ExportTableIcon from '@material-ui/icons/SaveAlt'
import { exportTable } from '@models/equipment/irrigation'

import { getIrrigationDashboardHeaders } from '@models/equipment/irrigation_dashboard'
import DeleteDialog from '@ui/DeleteDialog'
import Table from '@ui/Table'
import Select from '@ui/SimpleSelect'

import styles from '@styles/indexPage'

import { MTableToolbar } from "material-table";

class Irrigation extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isDialogOpen: false,
      id: null,
      currentEquipment: 0,
      pageSizeOptions: [5, 10, 20]
    }
  }

  componentDidMount() {
    const { model, equipments_list, irrigations } = this.props
    const { pageSizeOptions } = this.state
    const maximumPage = 20

    if (irrigations.length > maximumPage) {
      pageSizeOptions.push(irrigations.length)
    }

    this.setState({
      currentEquipment: equipments_list.findIndex(equip => equip.id === model.id),
      pageSizeOptions
    })
  }

  handleEquipmentChange = (index) => {
    const { model, o, equipments_list } = this.props
    const { currentEquipment } = this.state

    if (model === currentEquipment) {
      return
    }
    if (index !== null) {
      Turbolinks.visit(`/v4/o/${o}/equipments/irrigation/${equipments_list[index].id}/irrigations/?change_equipment=true`)
    }
  }

  render() {
    const { classes, model, irrigations, o, equipments_list } = this.props
    const { currentEquipment, pageSizeOptions } = this.state

    return (
      <>
        <Paper className={classes.page__wrapper} elevation={0}>
          <header className={classes.page__header}>
            <Typography component='h2' variant='h5'>
              {model.name || ''}
            </Typography>
          </header>
          <div className={classes.page__select}>
            <Button
              variant='outlined'
              color='primary'
              href={`/v4/o/${o}/equipments/irrigation/${model.id}/irrigations/new`}
            >
              {`${I18n.t('actions.add')} ${I18n.t('v4/equipment.irrigation_dashboard.title')}`}
            </Button>
            <Select
              values={equipments_list}
              handleChange={({ target }) => this.handleEquipmentChange(target.value)}
              selectedIndex={currentEquipment}
              isTitle
            />
          </div>
          <header className={classes.page__header} />

          <Table
            id='equipment-irrigation-system'
            key='equipment-table'
            tableKey='irrigation-index'
            components={{
              Container: props => <div>{props.children}</div>,
              Toolbar: props => (
                <div className={classes.Toolbar_wrapper}>
                  <MTableToolbar {...props} />

                  <Button
                    className={classes.Dashboard__table_export_button}
                    title={I18n.t('v4/equipment.dashboard.table.export_table')}
                    onClick={() => exportTable(model.name)}
                  >
                    <ExportTableIcon />
                  </Button>
                </div>
              )
            }}
            columns={getIrrigationDashboardHeaders(model.data.irrigation_system_type === 'pivot')}
            data={irrigations}
            options={{
              exportAllData: true,
              showTitle: false,
              headerStyle: {
                paddingLeft: '10px',
                paddingRight: '10px'
              },
              pageSizeOptions: pageSizeOptions
            }}
            actions={[
              rowData => ({
                icon: 'create',
                iconProps: { 'data-testid': `edit-${rowData.id}` },
                tooltip: I18n.t('actions.edit'),
                onClick: (event, rowData) => Turbolinks.visit(`/v4/o/${o}/irrigations/${rowData.id}/edit`)
              }),
              rowData => ({
                icon: 'delete',
                iconProps: { 'data-testid': `delete-${rowData.id}` },
                tooltip: I18n.t('actions.remove'),
                onClick: (event, rowData) => {
                  this.setState({ isDialogOpen: true, id: rowData.id })
                }
              })
            ]}
          />
        </Paper>

        <DeleteDialog
          dialogTitle={`${I18n.t('actions.remove')} ${I18n.t('v4/equipment.irrigation_dashboard.title')}`}
          dialogText={I18n.t('confirmation.remove')}
          successUrl={`/v4/o/${o}/equipments/irrigation/${model.id}/irrigations`}
          deleteUrl={`/v4/o/${o}/irrigations/${this.state.id}.json`}
          onCancel={() => this.setState({ isDialogOpen: false })}
          onClose={() => this.setState({ isDialogOpen: false })}
          open={this.state.isDialogOpen}
        />
      </>
    )
  }
}

const Styles = theme => ({
  ...styles(theme),
  Toolbar_wrapper: {
    display: 'flex',
    justifyContent: 'flex-end'
  }
})

export default withStyles(Styles)(Irrigation)
