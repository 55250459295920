import React from 'react'
import PropTypes from 'prop-types'
import { Form as FinalForm } from 'react-final-form'

import { validate, getUserTypes } from '@models/users'
import { RailsForm } from '@support/RailsForm'
import Form from './EditForm'

export const Edit = ({ model, organization, role, o }) => {
  const initialValues = { ...model, role: role.name }

  return <RailsForm
    component={FinalForm}
    action='update'
    url={`/v4/o/${o}/admin/organizations/${organization.id}/users/${model.id}`}
    successUrl={`/v4/o/${o}/admin/organizations/${organization.id}/users/${model.id}`}
    validate={validate}
    initialValues={initialValues}
    render={(props) => (
      <Form
        {...props}
        title={I18n.t('v4/users.edit.title')}
        userTypes={getUserTypes()}
        organization={organization}
        o={o}
      />
    )}
  />
}

Edit.propTypes = {
  model: PropTypes.object
}

export default Edit
