import React from 'react'
import { Form as FinalForm } from 'react-final-form'

import { RailsForm } from '@support/RailsForm'
import Form from './Form'

export const SatelliteImageryConfiguration = ({ filters, initial_values, organization, organization_product, o }) =>
  <RailsForm
    component={FinalForm}
    action='patch'
    url={`/v4/o/${o}/admin/organizations/${organization.id}/products/${organization_product.id}/satellite_imagery`}
    successUrl={`/v4/o/${o}/admin/organizations/${organization.id}/products`}
    initialValues={initial_values}
    render={(props) => (
      <Form
        {...props}
        filters={filters}
        organization_id={organization.id}
        title={I18n.t('v4/satellite_images.configuration.update_filters')}
        o={o}
      />
    )}
  />

SatelliteImageryConfiguration.defaultProps = {
  filters: []
}

export default SatelliteImageryConfiguration
