import React from 'react'
import backgroundImage from '../../../../../../assets/images/bg-agro-login.jpg'
import defaultLogo from '../../../../../../assets/images/logo_branco.svg'

export default ({ logoPreview, backgroundPreview }) =>
  <div
    style={{
      margin: '20px 0 0',
      width: '100%',
      height: '300px',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      borderRadius: '5px'
    }}
  >
    <div
      style={{
        backgroundSize: 'cover',
        position: 'absolute',
        width: '100%',
        height: '100%',
        display: 'block',
        zIndex: 0,
        filter: 'blur(5px)',
        '&:before': {
          content: '',
          position: 'absolute',
          top: 0,
          right: 0,
          bottom: 0,
          left: 0,
          opacity: '.6'
        },
        backgroundImage: `url(${backgroundPreview || backgroundImage})`
      }}
    >
    </div>
    <div
      style={{
        zIndex: 1,
        display: 'flex',
        position: 'relative',
        flexDirection: 'column',
        alignItems: 'center',
        width: '40%',
        height: '50%'
      }}>
      <div
        style={{
          width: '100%',
          background: 'rgba(16, 16, 16, 0.75)',
          padding: '10px',
          minHeight: '70%',
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          boxShadow: '0px 0px 15px 5px rgba(60, 60, 60, 0.26)',
          color: 'white',
          maxWidth: '500px'
        }}
      >
        <div
          style={{
            minHeight: '30%',
            padding: '10px',
            textAlign: 'center'
          }}>
          <img
            height='100%'
            src={logoPreview || defaultLogo}>
          </img>
        </div>
      </div>
    </div>
  </div>
