import { chemicalHeaders } from './chemical'
import { fertilizerHeaders } from './fertilizer'
import { cultivarHeaders } from './cultivar'
import { otherHeaders } from './other'

const inputHeaders = [
  { title: I18n.t('activerecord.attributes.v4/input.name'), field: 'name' },
  { title: I18n.t('activerecord.attributes.v4/input.brand'), field: 'brand' }
]

export const inputsTableHeaders = {
  fertilizer: [ ...inputHeaders, ...fertilizerHeaders ],
  chemical  : [ ...inputHeaders, ...chemicalHeaders ],
  cultivar  : [ ...inputHeaders, ...cultivarHeaders ],
  other     : [ ...inputHeaders, ...otherHeaders ],
}

export const fields = [
  'active_principle', 'brand', 'category_text',
  'cultivar', 'density', 'detail',
  'formulation_text', 'name', 'nitrogen',
  'phosphate', 'potassium', 'reg_code',
  'security_interval', 'sulfate',
  'unit', 'zinc', 'toc', 'urea'
]
