import React from 'react'

import Icon from '@images/irrigation/irrigation_forecast.svg'

import { Button, Typography } from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'

import Card from './Card'

import { isDateSelected } from '@models/irrigation_managements/maps'
import { convertToLocaleDate } from '@services/locale'

const IrrigationForecastCard = ({ forecast, selectedDate, classes, onDateClick, hasForecastProduct }) =>
  <Card
    title={I18n.t('v4/irrigation_management_product.cards.forecast_card.title')}
  >
    {
      _.isEmpty(forecast)
        ?
        hasForecastProduct
          ? <>
            <Typography variant='body1' align='center'>
              {I18n.t('v4/irrigation_management_product.cards.forecast_card.no_associated_management.text1')}
            </Typography>

            <Typography variant='body1' align='center'>
              {I18n.t('v4/irrigation_management_product.cards.forecast_card.no_associated_management.text2')}
            </Typography>
          </>
          : <>
            <Typography variant='body1' align='center'>
              {I18n.t('v4/irrigation_management_product.cards.forecast_card.no_forecast_product')}
            </Typography>

            <div />
          </>
        : <>
          <img src={Icon} alt='Irrigation Forecast icon' />
          <section className={classes.IrrigationForecastCard_days}>
            {
              forecast.map(date =>
                <Button
                  key={date.date}
                  className={isDateSelected(selectedDate, date) ? classes.IrrigationForecastCard_dayButton__selected : ''}
                  onClick={() => onDateClick(date)}
                  color={isDateSelected(selectedDate, date) ? 'secondary' : 'default'}
                  variant={isDateSelected(selectedDate, date) ? 'outlined' : 'text'}
                >
                  {
                    convertToLocaleDate(
                      date.date,
                      {
                        timeZone: 'UTC',
                        day: '2-digit',
                        month: '2-digit'
                      }
                    )
                  }
                </Button>
              )
            }
          </section>
        </>
    }
  </Card>

IrrigationForecastCard.defaultProps = {
  forecast: []
}

const styles = theme => ({
  IrrigationForecastCard_days: {
    display: 'flex',
    flexWrap: 'wrap',
    width: '100%',
    justifyContent: 'space-between'
  },
  IrrigationForecastCard_dayButton__selected: {
    padding: `${theme.spacing.unit * 0.6}px ${theme.spacing.unit}px`
  }
})

export default withStyles(styles)(IrrigationForecastCard)
