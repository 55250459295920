import React from 'react'

import { withStyles } from '@material-ui/core/styles'

import { Button, Typography } from '@material-ui/core'

const Step = ({
  advanceStep,
  retreatStep,
  classes,
  disableButton,
  title,
  description,
  children,
  showBackButton,
  advanceButtonText,
  isFromLastStep,
  save,
  actionCancel,
  actionEdit
}) =>
  <div className={classes.Step}>
    <Typography className={classes.Step_title} variant='h6'>
      {title}
    </Typography>

    <Typography className={classes.Step_description} variant='body1' >
      {description}
    </Typography>

    <div className={classes.Step_content}>
      {children}
    </div>

    <footer className={classes.Step_actions}>
      {
        showBackButton &&
        <Button
          className={classes.Step_action}
          color='primary'
          onClick={retreatStep}
        >
          {!!actionCancel ? actionCancel : I18n.t('actions.back')}
        </Button>
      }

      <Button
        className={classes.Step_action}
        color='primary'
        variant='contained'
        onClick={isFromLastStep ? save : advanceStep}
        disabled={disableButton}
      >
        {!!actionEdit ? actionEdit : advanceButtonText || I18n.t('actions.continue')}
      </Button>
    </footer>
  </div>

const styles = theme => ({
  Step: {
    position: 'relative',
    width: '100%',
    minHeight: 'calc(100vh - 135px)',
    paddingBottom: '40px',
    maxWidth: '700px',
    [theme.breakpoints.up('md')]: {
      minHeight: 'calc(100vh - 240px)'
    }
  },
  Step_description: {
    margin: `${theme.spacing.unit * 2}px 0`,
    [theme.breakpoints.up('md')]: {
      margin: `${theme.spacing.unit * 5}px 0`,
    }
  },
  Step_content: {
    margin: `${theme.spacing.unit * 2}px auto`
  },
  Step_actions: {
    bottom: 0,
    display: 'flex',
    justifyContent: 'center',
    width: '100%',
    [theme.breakpoints.up('md')]: {
      marginTop: `${theme.spacing.unit * 2}px`
    }
  },
  Step_action: {
    '&:not(:last-child)': {
      marginRight: `${theme.spacing.unit * 2}px`
    }
  }
})

Step.defaultProps = {
  title: '',
  disableButton: false,
  description: '',
  showBackButton: true
}

export default withStyles(styles)(Step)
