import React from 'react'
import Card from './Card'

import { irrigationStatusLegends } from '@models/irrigation_managements/maps'
import { withStyles } from '@material-ui/core/styles'
import { Link, Grid, Typography } from '@material-ui/core'
import { unstable_useMediaQuery as useMediaQuery } from '@material-ui/core/useMediaQuery'

const LegendCard = ({ classes }) => {
  const matches = useMediaQuery('(min-width:600px)')
  const [showLegend, setShowLegend] = React.useState(!matches)

  return (
    <>
      {
        !showLegend && !matches && <Typography align='right'>
          <Link
            onClick={() => setShowLegend(true)}
            color='primary'
            variant='button'
            align='right'
          >
            {I18n.t('v4/irrigation_management_product.cards.legend_card.show')}
          </Link>
        </Typography>
      }
      {
        (showLegend || matches) && <Card>
          <Grid container spacing={8}>
            <Grid item xs={12}>
              <Typography variant='subtitle2'>
                {I18n.t('v4/irrigation_management_product.cards.legend_card.legend')}
              </Typography>
            </Grid>
            {
              irrigationStatusLegends.map(legend =>
                <Grid item xs={6} sm={12} md={6} key={legend.title}>
                  <section className={classes.Legend} key={legend.title}>
                    <div className={classes.Legend__color} style={{ background: legend.color }} />

                    <div>
                      <Typography variant='body1' className={classes.LegendCard__title}>
                        {legend.title}
                      </Typography>

                      <Typography variant='caption' className={classes.LegendCard__subtitle}>
                        {legend.subtitle}
                      </Typography>
                    </div>
                  </section>
                </Grid>
              )
            }
            {
              !matches && <Grid item xs={6}>
                <Link
                  onClick={() => setShowLegend(false)}
                  color='primary'
                  variant='button'
                >
                  {I18n.t('v4/irrigation_management_product.cards.legend_card.hide')}
                </Link>
              </Grid>
            }
          </Grid>
        </Card>
      }
    </>
  )
}


const styles = theme => ({
  Legend: {
    display: 'flex'
  },
  Legend__color: {
    minWidth: theme.spacing.unit * 3,
    height: theme.spacing.unit * 3,
    marginRight: theme.spacing.unit,
    marginTop: 5,
    border: 'none',
    borderRadius: 3
  },
  LegendCard__title: {
    fontSize: '10pt',
    fontWeight: 500
  },
  LegendCard__subtitle: {
    fontSize: '8.5pt'
  }
})

export default withStyles(styles)(LegendCard)
