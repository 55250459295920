export default theme => ({
  products__menu: {
    width: 300,
    display: 'flex',
    flexWrap: 'wrap',
    cursor: 'pointer'
  },
  products__item: {
    width: 88,
    height: 140,
    marginLeft: theme.spacing.unit,
    borderRadius: 3,
    textAlign: 'center',
    alignItems: 'start',
    justifyContent: 'center',
    '&:hover': {
      outline: `1px solid ${theme.palette.primary.light}`,
    }
  },
  products__link: {
    padding: '5px 0 0',
    display: 'inline-block',
    textDecoration: 'none'
  },
  products__icon: {
    height: 60,
    width: '100%',
    display: 'inline-flex',
    alignItems: 'center',
    justifyContent: 'center',
    borderRadius: 5
  },
  products__label: {
    fontSize: 13
  },
  products__icon__center: {
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    padding: '5px 10px',
    borderRadius: 50
  },
  Navbar__Icon: {
    color: theme.menus.icons
  },
  ExpiredCardTrial_wrapper: {
    padding: theme.spacing.unit * 2
  },
  ExpiredCardTrial_content: {
    marginTop: theme.spacing.unit * 2
  },
  Dialog_close_button: {
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    position: 'absolute'
  },
  product_icon: {
    width: '80px',
    height: '80px'
  }
})
