import React from 'react'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import { Field } from 'react-final-form'

import TextField from '../../../ui/Fields/TextField'
import Select from '../../../ui/Fields/Select'
import styles from '@theme/styles/forms'

const Form = ({
  handleSubmit,
  classes,
  title,
  userType,
  organization,
  o
}) =>
  <form className={classes.form} onSubmit={handleSubmit} data-testid="users-form">
    <Paper className={classes.form__wrapper} elevation={0}>
      <header className={classes.form__header}>
        <Typography component='h2' variant='h5'>
          {title}
        </Typography>
      </header>
      <main className={classes.form__fields}>
        <div className={classes.form__field__wrapper}>
          <Field
            component={TextField}
            className={classes.form__fieldWithError}
            fullWidth
            InputLabelProps={{ shrink: true }}
            label={I18n.t('activerecord.attributes.v4/users.first_name')}
            margin='normal'
            name='first_name'
            variant="outlined"
            required
          />
        </div>
        <div className={classes.form__field__wrapper}>
          <Field
            component={TextField}
            className={classes.form__fieldWithError}
            margin='normal'
            fullWidth
            InputLabelProps={{ shrink: true }}
            variant="outlined"
            label={I18n.t('activerecord.attributes.v4/users.last_name')}
            name='last_name'
            required
          />
        </div>
        <div className={classes.form__field__wrapper}>
          <Field
            component={TextField}
            className={classes.form__fieldWithError}
            fullWidth
            InputLabelProps={{ shrink: true }}
            label={I18n.t('activerecord.attributes.v4/users.email')}
            margin='normal'
            disabled
            name='email'
            type='email'
            variant="outlined"
            required
          />
        </div>
        <div className={classes.form__field__wrapper}>
          <Field
            component={Select}
            className={classes.form__fieldWithError}
            InputLabelProps={{ shrink: true, required: true }}
            label={I18n.t('activerecord.attributes.v4/organization.kind')}
            name='role'
            margin='normal'
            fields={{ id: 'id', name: 'name' }}
            data={userType}
            fullWidth
            variant="outlined"
            required
          >
          </Field>
        </div>
      </main>
      <footer className={classes.form__actions}>
        <Button
          className={classes.form__action}
          color='primary'
          type='submit'
          variant='contained'
        >
          {title}
        </Button>

        <Button
          className={classes.form__action}
          color='secondary'
          href={`/v4/o/${o}/admin/organizations/${organization.id}/users`}
        >
          {I18n.t('actions.back')}
        </Button>
      </footer>
    </Paper>
  </form>

Form.defaultProps = {
  userType: [
    { id: 'owner', name: I18n.t('v4/users.members.owner') },
    { id: 'member', name: I18n.t('v4/users.members.member') }
  ]
}

export default withStyles(styles)(Form)
