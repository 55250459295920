import React, { Component } from 'react'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import withMobileDialog from '@material-ui/core/withMobileDialog'

import httpService from '@services/httpService'

import { withStyles } from '@material-ui/core'

class DeleteDialog extends Component {
  constructor(props) {
    super(props)

    this.defaultHandleDelete = this.defaultHandleDelete.bind(this)
  }

  defaultHandleDelete() {
    const {
      deleteUrl,
      successUrl,
      handleError
    } = this.props

    httpService
      .delete(deleteUrl)
      .then(() =>
        Turbolinks.visit(successUrl)
      )
      .catch((error) => {
        handleError
          ? handleError(error)
          : console.error('boom!', error)
      }
      )
  }

  render() {
    const { classes, changeDefaultColor, fullScreen, onCancel, onClose, open, dialogTitle, dialogText, handleDelete, actionText } = this.props

    const handler = handleDelete || this.defaultHandleDelete

    return (
      <Dialog fullScreen={fullScreen} open={open} onClose={onClose}>
        <DialogTitle>{dialogTitle}</DialogTitle>

        <DialogContent>
          <DialogContentText>
            {dialogText}
          </DialogContentText>
        </DialogContent>

        <DialogActions>
          <Button
            variant='outlined'
            onClick={onCancel}
          >
            {I18n.t('v4/delete_dialog.cancel')}
          </Button>

          <Button
            color='primary'
            variant='contained'
            onClick={handler}
            className={!!changeDefaultColor && classes.Dialog_delete}
          >
            {actionText || I18n.t('v4/delete_dialog.remove')}
          </Button>

        </DialogActions>
      </Dialog>
    )
  }
}

const styles = theme => ({
  Dialog_delete: {
    backgroundColor: theme.palette.trialColorRed,
    '&:hover': {
      backgroundColor: theme.palette.hoverRed,
    }
  }
})

export default withStyles(styles)(withMobileDialog()(DeleteDialog))
