import React from 'react'
import PropTypes from 'prop-types'

import EditFertilizer from './fertilizers/Edit'
import EditChemical from './chemicals/Edit'
import EditCultivar from './cultivar/Edit'
import EditOther from './others/Edit'

const EditInput = (props) => {
  const kinds = {
    fertilizer: <EditFertilizer {...props} />,
    chemical  : <EditChemical {...props} />,
    cultivar  : <EditCultivar {...props} />,
    other     : <EditOther {...props} />
  }

  return kinds[props.model.kind]
}

EditInput.propTypes = {
  model: PropTypes.object
}

export default EditInput
