import React from 'react'

import { Divider, Grid, Typography, Button, withStyles } from '@material-ui/core'

import Field from '@ui/Fields/Field'

import styles from '@theme/styles/forms'

const AreaFields = ({ classes, area, areaIndex, removeArea }) => (
  <div key={`${area.id}-${areaIndex}`} className={classes.AreaField__container}>
    <header className={classes.form__header}>
      <Typography variant='h6'>
        {area.name}
      </Typography>
      <Button
        className={classes.form__action}
        onClick={() => removeArea(area.id)}
        color='secondary'
      >
        {I18n.t('v4/area.notes.remove_area')}
      </Button>
    </header>

    <div className={classes.form__fields}>
      <Grid container spacing={24}>
        <Field
          type='text'
          gridItem
          label={I18n.t('v4/area.notes.fields.title')}
          name={`area_${area.id}[title]`}
          required
        />

        <Field
          type='date'
          gridItem
          label={I18n.t('v4/area.notes.fields.note_at')}
          name={`area_${area.id}[note_at]`}
          initialValue={new Date()}
          disableFuture
          required
        />
      </Grid>

      <Field
        type='text'
        label={I18n.t('v4/area.notes.fields.description')}
        name={`area_${area.id}[description]`}
        required
      />
    </div>
    <Divider />
  </div>
)

const AreaFieldStyles = theme => ({
  ...styles(theme),
  AreaField__container: {
    marginBottom: theme.spacing.unit * 5
  }
})

export default withStyles(AreaFieldStyles)(AreaFields)
