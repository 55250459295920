import React from 'react'
import { Form as FinalForm } from 'react-final-form'
import { RailsForm } from '@support/RailsForm'
import httpService from '@services/httpService'
import Form from './Form'
import { validate } from '@models/notifications'

export const Index = ({ current_forecast_points, model, contacts, handleError, o }) => {
  const handleSubmit = async (values) => {
    const data = Object.keys(values).reduce((notificationData, field) => {
      notificationData[field] = values[field]
      return notificationData
    }, {})

    try {
      await httpService.post(`/v4/o/${o}/notifications/weather_forecasts`, data)

      Turbolinks.visit(`/v4/o/${o}/notifications/weather_forecasts`)
    }
    catch (error) {
      handleError(error)
    }
  }

  const customFields = model.map(field => {
    if (field.v4_notifications_contact_id !== null) {
      const { contact, name } = contacts.find(contact => contact.id === field.v4_notifications_contact_id) || {}
      const { forecast_point_id, id, notifications_enabled } = field
      return { forecast_point_id, id, notifications_enabled, contact, name }
    }
    else {
      const { id, notifications_enabled } = field
      return { id, notifications_enabled }
    }
  })

  const initialValues = {}
  customFields.forEach((field, index) => initialValues["custom_field" + (index + 1)] = field)

  return <RailsForm
    component={FinalForm}
    handleSubmit={handleSubmit}
    initialValues={initialValues}
    validate={validate}
    render={(props) => (
      <Form
        {...props}
        title={I18n.t('v4/forecast.actions.config')}
        customFields={customFields}
        current_forecast_points={current_forecast_points}
        o={o}
      />
    )}
  />
}

export default Index
