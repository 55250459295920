import React from 'react'

import Icon from '@images/irrigation/current_irrigation.svg'

import { Button, Typography } from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'

import Card from './Card'

import { isDateSelected } from '@models/irrigation_managements/maps'
import { convertToLocaleDate } from '@services/locale'

const CurrentIrrigationCard = ({ selectedDate, classes, currentDate, onDateClick }) =>
  <Card
    title={I18n.t('v4/irrigation_management_product.cards.current_irrigation_card.title')}
  >
    <img src={Icon} alt='Current Irrigation icon'/>

    <section className={classes.CurrentIrrigationCard_day}>
      {
        _.isEmpty(currentDate)
          ? (
            <Typography variant='body1'>
              {I18n.t('v4/irrigation_management_product.cards.current_irrigation_card.no_data')}
            </Typography>
          )
          : (
            <>
              <Typography variant='body1'>
                {I18n.t('v4/irrigation_management_product.cards.current_irrigation_card.today')}
              </Typography>
              <Button
                className={isDateSelected(selectedDate, currentDate) ? classes.CurrentIrrigationCard_dayButton__selected : ''}
                onClick={() => onDateClick(currentDate)}
                color={isDateSelected(selectedDate, currentDate) ? 'secondary' : 'default'}
                variant={isDateSelected(selectedDate, currentDate) ? 'outlined' : 'text'}
              >
                {
                  convertToLocaleDate(
                    currentDate.date,
                    {
                      timeZone: 'UTC',
                      day: '2-digit',
                      month: '2-digit'
                    }
                  )
                }
              </Button>
            </>
          )
      }
    </section>
  </Card>

CurrentIrrigationCard.defaultProps = {
  currentDate: {}
}

const styles = theme => ({
  CurrentIrrigationCard_day: {
    textAlign: 'center'
  },
  CurrentIrrigationCard_dayButton__selected: {
    padding: `${theme.spacing.unit * 0.6}px ${theme.spacing.unit}px`
  }
})

export default withStyles(styles)(CurrentIrrigationCard)
