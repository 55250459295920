import React from 'react'
import { CircularProgressbar, buildStyles } from 'react-circular-progressbar'
import 'react-circular-progressbar/dist/styles.css'

export const getPivotCalculationResultInfo = ({ calculationResult }) => ({
  mainText: getMainText(calculationResult),
  texts: getTexts(calculationResult),
  table: getTable(calculationResult),
  image: () => getImage(calculationResult.percentage_irrigated_areas)
})

const getImage = (percentage) =>
  <div style={{ width: '15%' }}>
    <CircularProgressbar
      value={percentage}
      strokeWidth={50}
      styles={buildStyles({
        strokeLinecap: 'butt',
        pathColor: '#767676',
        trailColor: 'inherit',
      })}
    />
  </div>

const getMainText = (calculationResult) => I18n.t('v4/irrigation_system.pivot.calculation.main_text', {
  area_percent: _.round(calculationResult.percentage_irrigated_areas, 2)
})

const getTexts = (calculationResult) => {
  const slicesText = calculationResult.slices === 1
    ? I18n.t('v4/irrigation_system.pivot.calculation.texts.slice')
    : I18n.t('v4/irrigation_system.pivot.calculation.texts.slices', { slices: calculationResult.slices })

  return [
    slicesText,
    I18n.t('v4/irrigation_system.pivot.calculation.texts.time_final', { time_final: _.round(calculationResult.max_blade.time_final, 2) }),
    I18n.t('v4/irrigation_system.pivot.calculation.texts.irrigation_final', { irrigation_final: _.round(calculationResult.max_blade.irrigation_final, 2) }),
    I18n.t('v4/irrigation_system.pivot.calculation.texts.speed_relay_final', { speed_relay_final: _.round(calculationResult.max_blade.speed_relay_final, 2) })
  ]
}

const getTable = (calculationResult) => {
  const { area_percentage, lap_time } = calculationResult

  const percent1 = 100
  const percent2 = 75
  const percent3 = 50
  const percent4 = 25
  const irrigation_blade1 = _.round(calculationResult.irrigation_flow, 2)
  const irrigation_blade2 = _.round(calculationResult.irrigation_flow * percent1 / percent2, 2)
  const irrigation_blade3 = _.round(calculationResult.irrigation_flow * percent1 / percent3, 2)
  const irrigation_blade4 = _.round(calculationResult.irrigation_flow * percent1 / percent4, 2)
  const full_lap_time1 = lap_time
  const full_lap_time2 = _.round(lap_time * percent1 / percent2, 2)
  const full_lap_time3 = _.round(lap_time * percent1 / percent3, 2)
  const full_lap_time4 = _.round(lap_time * percent1 / percent4, 2)
  const fraction_lap_time1 = _.round(full_lap_time1 * area_percentage, 2)
  const fraction_lap_time2 = _.round(full_lap_time2 * area_percentage, 2)
  const fraction_lap_time3 = _.round(full_lap_time3 * area_percentage, 2)
  const fraction_lap_time4 = _.round(full_lap_time4 * area_percentage, 2)

  return {
    headers: [
      {
        title: I18n.t('v4/irrigation_system.pivot.calculation.table.percentimeter'),
        field: 'percentimeter'
      },
      {
        title: I18n.t('v4/irrigation_system.pivot.calculation.table.blade'),
        field: 'blade'
      },
      {
        title: I18n.t('v4/irrigation_system.pivot.calculation.table.full_lap_time'),
        field: 'full_lap_time'
      },
      {
        title: I18n.t('v4/irrigation_system.pivot.calculation.table.fraction_lap_time', { percentage: calculationResult.percentage_irrigated_areas }),
        field: 'fraction_lap_time'
      }
    ],
    data: [
      {
        percentimeter: `${percent1} %`,
        blade: `${irrigation_blade1} mm`,
        full_lap_time: full_lap_time1 > 1 ? `${full_lap_time1} ${I18n.t('hours')}` : `${full_lap_time1} ${I18n.t('hour')}`,
        fraction_lap_time: fraction_lap_time1 > 1 ? `${fraction_lap_time1} ${I18n.t('hours')}` : `${fraction_lap_time1} ${I18n.t('hour')}`
      },
      {
        percentimeter: `${percent2} %`,
        blade: `${irrigation_blade2} mm`,
        full_lap_time: full_lap_time2 > 1 ? `${full_lap_time2} ${I18n.t('hours')}` : `${full_lap_time2} ${I18n.t('hour')}`,
        fraction_lap_time: fraction_lap_time2 > 1 ? `${fraction_lap_time2} ${I18n.t('hours')}` : `${fraction_lap_time2} ${I18n.t('hour')}`
      },
      {
        percentimeter: `${percent3} %`,
        blade: `${irrigation_blade3} mm`,
        full_lap_time: full_lap_time3 > 1 ? `${full_lap_time3} ${I18n.t('hours')}` : `${full_lap_time3} ${I18n.t('hour')}`,
        fraction_lap_time: fraction_lap_time3 > 1 ? `${fraction_lap_time3} ${I18n.t('hours')}` : `${fraction_lap_time3} ${I18n.t('hour')}`
      },
      {
        percentimeter: `${percent4} %`,
        blade: `${irrigation_blade4} mm`,
        full_lap_time: full_lap_time4 > 1 ? `${full_lap_time4} ${I18n.t('hours')}` : `${full_lap_time4} ${I18n.t('hour')}`,
        fraction_lap_time: fraction_lap_time4 > 1 ? `${fraction_lap_time4} ${I18n.t('hours')}` : `${fraction_lap_time4} ${I18n.t('hour')}`
      },
    ]
  }
}

export const irrigation_limitations = [
  { id: 'max_time', name: I18n.t('v4/irrigation_system.pivot.form.irrigation_limitations.max_time') },
  { id: 'max_blade', name: I18n.t('v4/irrigation_system.pivot.form.irrigation_limitations.max_blade') },
  { id: 'min_percentimeter', name: I18n.t('v4/irrigation_system.pivot.form.irrigation_limitations.min_percentimeter') }
]

export const pivot_slices = [
  { id: 1, name: I18n.t('v4/irrigation_system.pivot.form.pivot_slices.1') },
  { id: 2, name: I18n.t('v4/irrigation_system.pivot.form.pivot_slices.2') },
  { id: 3, name: I18n.t('v4/irrigation_system.pivot.form.pivot_slices.3') },
  { id: 4, name: I18n.t('v4/irrigation_system.pivot.form.pivot_slices.4') }
]

export const pivot_percentages = [
  { id: 100, name: I18n.t('v4/irrigation_system.pivot.form.pivot_percentages.100') },
  { id: 75, name: I18n.t('v4/irrigation_system.pivot.form.pivot_percentages.75') },
  { id: 50, name: I18n.t('v4/irrigation_system.pivot.form.pivot_percentages.50') },
  { id: 25, name: I18n.t('v4/irrigation_system.pivot.form.pivot_percentages.25') },
  { id: 'other', name: I18n.t('v4/irrigation_system.pivot.form.pivot_percentages.other') }
]
