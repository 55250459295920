import React from 'react'
import { Form as FinalForm } from 'react-final-form'
import { RailsForm } from '@support/RailsForm'
import InputForm from '../Form'
import FertilizerForm from './Form'
import { validate, unities, ureaValues, fertilizerShape } from '@models/inputs/fertilizer'

const EditFertilizer = ({ model, o }) => {
  const {
    name, brand, unit, density, nitrogen, sulfate,
    phosphate, potassium, zinc, toc, urea, detail, kind
  } = model;

  return (
    <RailsForm
      component={FinalForm}
      action='update'
      initialValues={{
        name, brand, unit, density, nitrogen, sulfate,
        phosphate, potassium, zinc, toc, urea, detail, kind
      }}
      url={`/v4/o/${o}/inputs/fertilizer/${model.id}`}
      successUrl={`/v4/o/${o}/inputs/fertilizer/${model.id}`}
      validade={validate}
      render={(props) => (
        <InputForm
          title={`${I18n.t('actions.edit')} ${I18n.t(`v4/input.kinds.${kind}`)}`}
        >
          <FertilizerForm
            {...props}
            o={o}
            unities={unities}
            action={I18n.t('actions.update')}
            ureaValues={ureaValues}
          />
        </InputForm>
      )}
    />
  )
}

EditFertilizer.propTypes = {
  model: fertilizerShape
}

export default EditFertilizer
