import React from 'react'

import {
  withStyles,
  IconButton,
  Popover,
  ListItem,
  ListItemText,
  List
} from '@material-ui/core'
import ContactSupportIcon from '@material-ui/icons/ContactSupport'
import styles from './styles'

const SupportMenu = ({
  classes,
  router
}) => {
  const [anchorEl, setAnchorEl] = React.useState(null)
  const open = !!anchorEl

  const handleOpen = event => setAnchorEl(event.currentTarget)

  const handleClose = () => setAnchorEl(null)

  return (
    <div data-intercom-target='support'>
      <IconButton
        className={classes.support_icon}
        aria-owns={open ? 'render-props-popover' : null}
        aria-haspopup='true'
        variant='contained'
        name='support-menu'
        onClick={handleOpen}
      >
        <ContactSupportIcon />
      </IconButton>

      <Popover
        id='support-popover'
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <List className={classes.supportMenu__list}>
      <ListItem
        button
        onClick={() => window.open('https://wa.me/551931993399', '_blank')}
        key='menu-list-whatsapp'
        className={classes.support__item}
        data-intercom-target='help-supportwhatsapp'
      >
        <ListItemText
          primary={I18n.t('v4/support.whatsapp.title')}
        />
      </ListItem>

          <ListItem
            button
            onClick={() => window.Intercom('show')}
            key='menu-list-chat'
            className={classes.support__item}
            data-intercom-target='help-supportchat'
          >
            <ListItemText
              primary={I18n.t('v4/support.chat.title')}
            />
          </ListItem>

          <ListItem
            button
            onClick={() => window.open('http://suporte.cultivointeligente.com.br', '_blank')}
            key='menu-list-support'
            className={classes.support__item}
            data-intercom-target='help-supportpage'
          >
            <ListItemText
              primary={I18n.t('v4/support.show.title')}
            />
          </ListItem>
          {/* <ListItem
            button
            onClick={() => router.visit('maps?product_tour_id=141143')}
            key='menu-list-tour'
            className={classes.support__item}
            data-intercom-target='help-redotour'
          >
            <ListItemText
              primary={I18n.t('v4/tour.remake.title')}
            />
          </ListItem> */}
        </List>
      </Popover>
    </div>
  )
}

export default withStyles(styles)(SupportMenu)
