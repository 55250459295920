import React from 'react'
import PropTypes from 'prop-types'

import clsx from 'clsx'
import { Stepper, Step, StepLabel, StepConnector } from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'
import styles from './styles'

const stepperLineStyle = (theme) => ({
  alternativeLabel: {
    top: 22,
  },
  active: {
    '& $line': {
      backgroundImage:
        `linear-gradient( -95deg, ${theme.palette.secondary.light} 100%, ${theme.palette.secondary.main} 50%, ${theme.palette.secondary.dark} 0%)`,
    },
  },
  completed: {
    '& $line': {
      backgroundImage:
        `linear-gradient( -95deg, ${theme.palette.secondary.light} 100%, ${theme.palette.secondary.main} 50%, ${theme.palette.secondary.dark} 0%)`
    },
  },
  line: {
    height: 3,
    border: 0,
    backgroundColor: '#EAEAF0',
    borderRadius: 1,
  }
})

const ColorlibConnector = withStyles(stepperLineStyle)(StepConnector)

const ColorlibStepIcon = ({ active, completed, classes, step }) =>
  <div
    className={clsx(classes.root, {
      [classes.stepActive]: active,
      [classes.stepCompleted]: completed,
    })}
  >
    {step.icon}
  </div>

ColorlibStepIcon.propTypes = {
  active: PropTypes.bool,
  completed: PropTypes.bool,
  icon: PropTypes.node,
}

const Steppers = ({ classes, steps, children, currentStep }) =>
  <React.Fragment>
    <Stepper alternativeLabel activeStep={currentStep} connector={<ColorlibConnector />} className={classes.Stepper}>
      {
        steps.map((step, index) =>
          <Step key={index}>
            <StepLabel
              StepIconComponent={
                (props) =>
                  <ColorlibStepIcon
                    {...props}
                    step={step}
                    classes={classes}
                  />
              }
            >
              {step.label}
            </StepLabel>
          </Step>
        )
      }
    </Stepper>

    {
      !_.isEmpty(children) &&
      <div className={classes.stepContent}>
        {children[currentStep]}
      </div>
    }
  </React.Fragment>

Steppers.propTypes = {
  steps: PropTypes.array,
  children: PropTypes.array,
  currentStep: PropTypes.number
}

Steppers.defaultProps = {
  steps: {},
  children: [],
  currentStep: 0
}

export default withStyles(styles)(Steppers)
