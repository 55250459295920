import React from 'react'

import { Typography, withStyles } from '@material-ui/core'

const Tabs = ({ classes, changeContent, active = 'search' }) =>
  <div className={classes.Tabs_wrapper}>
    <Typography
      variant="button"
      display="block"
      gutterBottom
      className={active === 'search' ? classes.Tabs_active : classes.Tabs_text}
      onClick={() => changeContent('search')}
    >
      {I18n.t('v4/satellite_images.tabs.search_images')}
    </Typography>

    <Typography
      variant="button"
      display="block"
      gutterBottom
      className={active === 'compare' ? classes.Tabs_active : classes.Tabs_text}
      onClick={() => changeContent('compare')}
    >
      {I18n.t('v4/satellite_images.tabs.compare')}
    </Typography>
  </div>

const styles = theme => ({
  Tabs_wrapper: {
    display: 'flex',
    justifyContent: 'space-around'
  },
  Tabs_text: {
    cursor: 'pointer'
  },
  Tabs_active: {
    cursor: 'pointer',
    borderBottom: `solid ${theme.palette.primary.main}`
  },
})

export default withStyles(styles)(Tabs)
