export const locate_forecast_machine = ({ lat, lon }) => {
  let hyp_max = 0
  let machine_node = null

  coordinates.forEach((c, index) => {
    if (checkCoordinates(lat, lon, c)) {
      let dx = calc_lat(lat, index)
      let dy = calc_lon(lon, index)
      let hyp = Math.sqrt((dx * dx) + (dy * dy))

      if (dx >= 1 && dy >= 1 && hyp > hyp_max) {
        hyp_max = hyp
        machine_node = c.id
      }
    }
  })

  return machine_node
}

// if changes here, update the app/models/forecast/point.rb file as well
const coordinates = [
  {
    id: 1,
    WLON: -65.2986,
    ELON: -47.1014,
    SLAT: -32.6938,
    NLAT: -20.559,
  },
  {
    id: 2,
    WLON: -51.2581,
    ELON: -36.1419,
    SLAT: -26.8141,
    NLAT: -11.3333,
  },
  {
    id: 3,
    WLON: -66.9309,
    ELON: -49.0691,
    SLAT: -22.5708,
    NLAT: -8.75005,
  },
  {
    id: 4,
    WLON: -51.2637,
    ELON: -30.0983,
    SLAT: -14.1006,
    NLAT: 1.21702,
  },
  {
    id: 5,
    WLON: -69.5627,
    ELON: -50.6373,
    SLAT: -42.7627,
    NLAT: -30.1188,
  },
  {
    id: 6, // Grade 09
    WLON: -62.3631,
    ELON: -47.6369,
    SLAT: -10.9296,
    NLAT: 4.98748,
  },
  {
    id: 7, // Grade 10
    WLON: -74.5042,
    ELON: -59.4958,
    SLAT: -12.1894,
    NLAT: 5.86879,
  }
]

const checkCoordinates = (lat, lon, coord) =>
  ((lat > coord.SLAT && lat < coord.NLAT) && (lon > coord.WLON && lon < coord.ELON))

const calc_lat = (lat, index) => {
  let dist_north = Math.abs(coordinates[index].NLAT - lat)
  let dist_south = Math.abs(coordinates[index].SLAT - lat)

  return Math.min(dist_north, dist_south)
}

const calc_lon = (lon, index) => {
  let dist_west = Math.abs(coordinates[index].WLON - lon)
  let dist_east = Math.abs(coordinates[index].ELON - lon)

  return Math.min(dist_west, dist_east)
}

export const isValidCoordinates = ({ lat, lon }) => !!locate_forecast_machine({ lat, lon })
