import React from 'react'

import { Button, Grid, withStyles } from '@material-ui/core'

import Graphics from '@images/equipment/graphics'
import { useRouter } from '@contexts/RouterProvider'

const TelemetryButton = ({ classes, equipmentId }) => {
  const router = useRouter()

  return (
    <Grid item xs={12}>
      <Button
        color='primary'
        onClick={() => router.visit(`equipments/${equipmentId}/dashboard`)}
        variant='contained'
        fullWidth
      >
        <img src={Graphics} alt='graphics' className={classes.Card_graphics} />
        {I18n.t('v4/equipment.dashboard.actions.graphics')}
      </Button>
    </Grid>
  )
}

const styles = theme => ({
  Card_graphics: {
    height: theme.spacing.unit * 2,
    marginRight: theme.spacing.unit
  }
})

export default withStyles(styles)(TelemetryButton)
