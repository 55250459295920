import React from 'react'

import TextField from '@ui/Fields/TextField'

const SimpleTextField = ({ value, name, type, label, required, onChange, onBlur, classes }) =>
  <TextField
    className={classes.Login__form_field}
    InputLabelProps={{
      classes: {
        root: classes.Login__form_field_label,
        focused: classes.Login__form_field_label_focused,
      },
      shrink: true
    }}
    InputProps={{
      classes: {
        root: classes.Login__form_field_outlined_input,
        focused: classes.Login__form_field_focused,
        notchedOutline: classes.notchedOutline,
      },
      inputProps: {
        className: classes.Login_remove_autocomplete_background
      }
    }}
    margin='normal'
    value={value}
    onChange={onChange}
    onBlur={onBlur}
    fullWidth
    variant='outlined'
    label={label}
    name={name}
    type={type}
    required={required}
  />

export default SimpleTextField
