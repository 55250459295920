import React from 'react'
import PropTypes from 'prop-types'

import Button from '@material-ui/core/Button'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

import { fields } from '@models/admin/tenantUsers'
import styles from '@styles/showPage'

const Show = ({ classes, model, tenant, o }) =>
  <Paper
    className={classes.showPage__wrapper}
    elevation={0}
  >
    <header className={classes.showPage__header}>
      <Typography component='h2' variant='h5'>
        {I18n.t('v4/users.show.title')}
      </Typography>
    </header>

    <main className={classes.showPage__values}>
      {
        fields().map(field =>
          model[field.name] && !field.hidden &&
          <div key={field.name} className={classes.showPage__value}>
            <Typography
              color='textSecondary'
              component='h6'
              gutterBottom
              variant='h6'
            >
              {I18n.t(`activerecord.attributes.v4/users.${field.name}`)}
            </Typography>

            <Typography paragraph variant='subtitle1' data-testid={field.name}>
              {
                field.render
                  ? field.render(model[field.name])
                  : model[field.name]
              }
            </Typography>
          </div>
        )
      }
    </main>

    <footer className={classes.showPage__actions}>
      <Button
        className={classes.showPage__action}
        color='primary'
        variant='contained'
        href={`/v4/o/${o}/admin/tenants/${tenant.id}/users/${model.id}/edit`}
      >
        {I18n.t('v4/users.edit.title')}
      </Button>
      <Button
        className={classes.showPage__action}
        color='secondary'
        href={`/v4/o/${o}/admin/tenants/${tenant.id}/users`}
      >
        {I18n.t('actions.back')}
      </Button>
    </footer>
  </Paper>

Show.defaultProps = {
  model: {},
  tenant: {}
}

Show.propTypes = {
  model: PropTypes.object
}

export default withStyles(styles)(Show)
