import styles from '@styles/indexPage'

export default theme => ({
    ...styles(theme),
    Dashboard__tag__cells: {
        display: 'inline-block',
        margin: '2px 3px',
        padding: '5px 7px',
        border: '1px solid white',
        borderRadius: '15px',
        background: theme.palette.gray.main,
        borderColor: theme.palette.gray.main,
    }
})
