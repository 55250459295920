import React from "react";

import { withStyles } from "@material-ui/core/styles";

import TextField from "@ui/Fields/TextField";
import { Typography, Paper, Divider } from "@material-ui/core";
import CheckIcon from "@material-ui/icons/CheckCircleOutline";

import Select from "@ui/Fields/Select";

import Step from "./Step";

import { getTimeLabel } from "@models/alerts";

import { AlertCategories } from "@models/alerts";

const AlertNameStep = ({
  areas,
  alertName,
  alertCategory,
  classes,
  handleChangeAlertName,
  handleChangeAlertCategory,
  retreatStep,
  advanceStep,
}) => (
  <Step
    title={I18n.t("v4/alerts.new.steps.alert_name.title")}
    description={I18n.t("v4/alerts.new.steps.alert_name.info")}
    retreatStep={retreatStep}
    advanceStep={advanceStep}
    disableButton={_.isEmpty(alert.name.trim())}
  >
    <section className={classes.AlertNameStep}>
      <TextField
        className={classes.AlertNameStep_input}
        fullWidth
        onChange={(event) => handleChangeAlertName(event.target.value)}
        placeholder={I18n.t(
          "v4/alerts.new.steps.alert_name.alert_name_placeholder"
        )}
        value={alertName}
      />

      <Typography variant="body2" className={classes.AlertNameStep_info}>
        {I18n.t("v4/alerts.new.steps.alert_name.how_alert_will_apear")}
      </Typography>

      <AlertPreview areas={areas} classes={classes} name={alertName} />

      <Divider />

      <AlertCategory
        alertCategory={alertCategory}
        classes={classes}
        handleChangeAlertCategory={handleChangeAlertCategory}
      />
    </section>
  </Step>
);

const AlertPreview = ({ areas, name, classes }) => (
  <Paper className={classes.AlertPreview}>
    <section>
      <section className={classes.AlertPreview_title}>
        <Typography
          className={classes.AlertPreview_alertName}
          variant="body2"
          color="textSecondary"
        >
          {name || I18n.t("v4/alerts.new.steps.alert_name.default_alert_name")}
        </Typography>

        <Typography variant="body1" color="textSecondary">
          {getTimeLabel()}
        </Typography>
      </section>

      <Typography variant="body1" color="textSecondary" align="left">
        {areas
          .filter((area) => area.selected)
          .map((area) => area.name)
          .toString()
          .replace(/,/g, "| ")}
      </Typography>
    </section>

    <CheckIcon fontSize="large" color="primary" />
  </Paper>
);

const AlertCategory = ({
  alertCategory,
  classes,
  handleChangeAlertCategory,
}) => (
  <div className={classes.AlertCategory}>
    <div className={classes.AlertCategory_Text}>
      <Typography component="h2" variant="body1">
        {I18n.t("v4/alerts.new.steps.category.title")}
      </Typography>
    </div>

    <Select
      label={I18n.t("v4/alerts.new.steps.category.category_name")}
      onChange={(event) => handleChangeAlertCategory(event.target.value)}
      data={AlertCategories}
      InputLabelProps={{ shrink: true }}
      fields={{ id: "value", name: "name" }}
      name="Categoria"
      variant="outlined"
      value={alertCategory}
      error={!alertCategory}
      helperText={!alertCategory && I18n.t("validations.required")}
      required
    />
  </div>
);

AlertPreview.defaultProps = {
  name: I18n.t("v4/alerts.new.steps.alert_name.default_alert_name"),
};

const styles = (theme) => ({
  AlertNameStep: {
    textAlign: "center",
  },
  AlertNameStep_info: {
    marginBottom: `${theme.spacing.unit * 3}px`,
  },
  AlertNameStep_input: {
    margin: `0 auto ${theme.spacing.unit * 3}px`,
    maxWidth: "500px",
  },
  AlertPreview: {
    padding: theme.spacing.unit * 2,
    margin: `${theme.spacing.unit * 3}px auto`,
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: "500px",
  },
  AlertPreview_title: {
    display: "flex",
    flexWrap: "wrap",
    marginBottom: theme.spacing.unit,
  },
  AlertPreview_alertName: {
    marginRight: theme.spacing.unit,
  },
  AlertCategory: {
    display: "flex",
    flexDirection: "column",
  },
  AlertCategory_Text: {
    marginTop: theme.spacing.unit * 2,
    marginBottom: theme.spacing.unit * 2,
    textAlign: "start",
  },
});

AlertNameStep.defaultProps = {
  areas: [],
  alert: {},
  handleChangeAlertName: () => {},
  handleChangeAlertCategory: () => {},
};

export default withStyles(styles)(AlertNameStep);
