import React from 'react'
import Card from './Card'

import { irrigationStatusLegends } from '@models/irrigation_managements/maps'
import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'

const LegendCard = ({ classes }) =>
  <Card
    title={I18n.t('v4/irrigation_management_product.cards.legend_card.title')}
  >
    <section>
      {
        irrigationStatusLegends.map(legend =>
          <section className={classes.Legend} key={legend.title}>
            <div className={classes.Legend__color} style={{ background: legend.color }} />

            <div>
              <Typography variant='body1'>
                {legend.title}
              </Typography>

              <Typography variant='caption'>
                {
                  legend.subtitle
                    ? `(${legend.subtitle})`
                    : null
                }
              </Typography>
            </div>
          </section>
        )
      }
    </section>
  </Card>

const styles = theme => ({
  Legend: {
    display: 'flex',
    alignItems: 'center',
    '&:not(:last-child)': {
      marginBottom: theme.spacing.unit
    }
  },
  Legend__color: {
    width: '15px',
    height: '20px',
    marginRight: `${theme.spacing.unit * 2}px`,
    border: `1px solid ${theme.palette.black}`
  }
})

export default withStyles(styles)(LegendCard)
