import React, { Component } from 'react'
import PropTypes from 'prop-types'
import MembersIcon from '@material-ui/icons/Group'
import CreditCardIcon from '@material-ui/icons/CreditCard';
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import Table from '@ui/Table'
import { getOrganizationHeaders } from '@models/organization'
import DeleteDialog from '../ui/DeleteDialog'
import styles from '@styles/indexPage'

class Index extends Component {
  constructor(props) {
    super(props)

    this.state = {
      isDialogOpen: false,
      id: null
    }

    this.closeDialog = this.closeDialog.bind(this)
  }

  closeDialog() {
    this.setState({ isDialogOpen: false })
  }

  render() {
    const { model, classes, o } = this.props
    const { isDialogOpen, id } = this.state

    return (
      <Paper className={classes.page__wrapper} elevation={0}>
        <header className={classes.page__header}>
          <Typography component='h2' variant='h5'>
            {I18n.t('activerecord.models.v4/organization.other')}
          </Typography>
        </header>

        <Table
          tableKey='organizations-index'
          components={{
            Container: props => <div>{props.children}</div>,
          }}
          columns={getOrganizationHeaders()}
          data={model}
          actions={[
            rowData => ({
              icon: CreditCardIcon,
              tooltip: I18n.t('actions.payment'),
              onClick: (event, rowData) => {
                Turbolinks.visit(`/v4/o/${o}/organizations/${rowData.id}/invoices`)
              }
            }),
            rowData => ({
              icon: MembersIcon,
              iconProps: { 'data-testid': `members-of-${rowData.id}` },
              tooltip: I18n.t('actions.members'),
              onClick: (event, rowData) => {
                Turbolinks.visit(`/v4/o/${o}/organizations/${rowData.id}/users`)
              }
            }),
            rowData => ({
              icon: 'create',
              iconProps: { 'data-testid': `edit-${rowData.id}` },
              tooltip: I18n.t('actions.edit'),
              onClick: (event, rowData) => {
                Turbolinks.visit(`/v4/o/${o}/organizations/${rowData.id}/edit`)
              }
            })
          ]}
          options={{
            showTitle: false
          }}
        />

        <DeleteDialog
          dialogTitle={`${I18n.t('actions.remove')} ${I18n.t('activerecord.models.v4/organization.one')}?`}
          dialogText={I18n.t('confirmation.remove')}
          successUrl='/v4/organizations'
          deleteUrl={`/v4/organizations/${id}.json`}
          onCancel={this.closeDialog}
          onClose={this.closeDialog}
          open={isDialogOpen}
        />
      </Paper>
    )
  }
}

Index.propTypes = {
  model: PropTypes.array
}

export default withStyles(styles)(Index)
