const drawerWidth = 264

export default theme => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  paper: {
    zIndex: 400,
    backgroundColor: theme.menus.background
  },
  drawerOpen: {
    width: drawerWidth
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  SideBar_Icon: {
    color: theme.menus.icons,
    position: 'absolute',
    zIndex: 1300,
    left: theme.spacing.unit,
    top: theme.spacing.unit,
  },
  SideBar_Icon__open: {
    color: theme.menus.sideBarIcons
  },
  Menu_Icon: {
    color: theme.menus.icons
  },
  menu__logoLink: {
    textDecoration: 'none',
    display: 'grid',
  },
  SideBar__item: {},
  SideBar_IconTrial: {
    color: theme.menus.icons,
    position: 'absolute',
    zIndex: 1300,
    left: theme.spacing.unit,
    top: theme.spacing.unit * 8,
  },
  SideBar_current: {
    fontWeight: 500,
    color: theme.palette.primary.main
  },
  SideBar_scrollable: {
    ...theme.scrollbar,
    overflow: 'auto'
  }
})
