import React from 'react'

import { Typography } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'

import styles from './styles'

const CustomRadioButton = ({ classes, options, selected, onClick, translate, title }) =>
  <div className={classes.Radio__Button__Container}>
    {
      title && (
        <Typography component='h2' variant='h6' className={classes.Radio__Button__Title}>
          {title}
        </Typography>
      )
    }

    {
      options.map((option, index) =>
        <button
          key={`${option}-${index}`}
          disabled={index === selected}
          className={classes.Radio__Button}
          onClick={() => onClick(index)}
        >
          {!!translate ? I18n.t(translate(option)) : option}
        </button>
      )
    }
  </div>

export default withStyles(styles)(CustomRadioButton)
