import React from 'react'

import { validatePhoneRegex, validatePasswordRegex, validateEmailRegex } from './validates'

import httpService from '@services/httpService'

export const validate = values => {
  const requiredFields = [
    'first_name',
    'last_name',
    'email',
    'role'
  ]
  const errors = {}

  requiredFields.forEach(field => {
    if (!values[field]) errors[field] = I18n.t('validations.required')
  })

  if (values['password'] !== values['password_confirmation']) {
    errors['password_confirmation'] = I18n.t('validations.match_password')
  }

  return errors
}

export const validateNewUser = values => {
  const requiredFields = [
    'email', 'role'
  ]

  const errors = {}

  requiredFields.forEach(field => {
    if (!values[field]) errors[field] = I18n.t('validations.required')
  })

  return errors
}

export const validateName = value => {
  return {
    message: !value ? I18n.t('validations.required') : '' 
  }
}

export const validPhone = value => {
  let message = ''
  value && value.length <= 5
    ? message = I18n.t('validations.required')
    : message = validatePhoneRegex(value)
      ? ''
      : I18n.t('validations.invalid_phone')
  return {
    message: message
  }
}

export const validatePassword = value => {
  if (!value) {
    return {
      message: I18n.t('validations.required')
    }
  }

  return {
    message: validatePasswordRegex(value) ? '' : I18n.t('validations.invalid_password')
  }
}

export const validateEmail = async (value) => {
  try {
    if (!value) {
      return {
        status: 'blank',
        message: I18n.t('validations.required')
      }
    }

    if (!validateEmailRegex(String(value).toLowerCase())) {
      return {
        status: 'invalid',
        message: I18n.t('validations.invalid_email')
      }
    }

    const params = { email: value }

    const { data } = await httpService.post('/api/v4/users/validate/check_email', params)
    const { status, message } = data

    return {
      status: status,
      message: message ? message : ''
    }
  } catch ({ response }) {
    console.error(response)
  }
}

export const fields = () => [
  { name: 'first_name' },
  { name: 'last_name' },
  { name: 'email' },
  { name: 'roles', render: (roles) => roles.reduce((value, role) => `${I18n.t(`v4/users.members.${role.name}`)} ${value}`, '') }
]

export const getUserTypes = () => [
  { id: 'owner', name: I18n.t('v4/users.members.owner') },
  { id: 'member', name: I18n.t('v4/users.members.member') }
]

export const getUsersHeaders = () => {
  return [
    {
      field: 'first_name',
      title: I18n.t('v4/users.tabs.first_name'),
      render: rowData =>
        <span>
          {`${rowData.first_name || I18n.t('v4/users.guest')} ${rowData.last_name || ''}`}
        </span>
    },
    {
      field: 'email',
      title: I18n.t('v4/users.tabs.email'),
    },
    {
      field: 'role',
      title: I18n.t('v4/users.tabs.role'),
      render: rowData =>
        <span>
          {rowData.roles.reduce((value, role) => `${I18n.t(`v4/users.members.${role.name}`)} ${value}`, '')}
        </span>
    },
  ]
}
