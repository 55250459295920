import React from 'react'

import { Button, Paper, Typography } from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'
import { Field } from 'react-final-form'

import TextField from '@ui/Fields/TextField'
import styles from '@theme/styles/forms'

const Form = ({
  handleSubmit,
  classes,
  title,
  organization_id,
  o
}) =>
  <form
    className={classes.form}
    data-testid='irrigation-system-configuration-form'
    onSubmit={handleSubmit}
  >
    <Paper className={classes.form__wrapper} elevation={0}>
      <header className={classes.form__header}>
        <Typography component='h2' variant='h5'>
          {title}
        </Typography>
      </header>

      <main className={classes.form__fields}>
        <div className={classes.form__field__wrapper}>
          <Field
            className={classes.form__fieldWithError}
            component={TextField}
            fullWidth
            InputLabelProps={{ shrink: true }}
            label={I18n.t('v4/irrigation_system.configuration.managment_number')}
            margin='normal'
            name='slots'
            type='number'
            variant='outlined'
          />
        </div>
      </main>

      <footer className={classes.form__actions}>
        <Button
          className={classes.form__action}
          color='primary'
          type='submit'
          variant='contained'
        >
          {title}
        </Button>

        <Button
          className={classes.form__action}
          color='secondary'
          href={`/v4/o/${o}/admin/organizations/${organization_id}/products`}
        >
          {I18n.t('actions.back')}
        </Button>
      </footer>
    </Paper>
  </form>

export default withStyles(styles)(Form)
