import React from 'react'

import WindDirection from "@ui/WindDirection"

export const charts = [
  {
    title: I18n.t('v4/weather.charts.air_temperature.title'),
    id: 'air_temperature',
    rows: [
      { id: 't2_max', type: 'line', color: '#FF6961' },
      { id: 't2_min', type: 'line', color: '#00969E', yAxisId: 't2_max' }
    ]
  },
  {
    title: I18n.t('v4/weather.charts.air_humidity.title'),
    id: 'air_humidity',
    rows: [
      { id: 'rh2_max', type: 'line', color: '#4DAF4A' },
      { id: 'rh2_min', type: 'line', color: '#FF7F00', yAxisId: 'rh2_max' },
    ]
  },
  {
    title: I18n.t('v4/weather.charts.accumulated_rain.title'),
    id: 'accumulated_rain',
    colorRules: (value) => getRainStatus(value),
    rows: [
      { id: 'pcp_tot', type: 'bar', color: '#E398A6' },
    ]
  },
  {
    title: I18n.t('v4/weather.charts.wind_velocity.title'),
    id: 'wind_velocity',
    rows: [
      { id: 'ws10_max', type: 'line', color: '#454545', yAxisId: 'ws10_avg' },
      { id: 'ws10_avg', type: 'line', color: '#767676' }
    ]
  }
]

export const getAccumulatedRainStatus = () => ({
  low: {
    description: I18n.t('v4/weather.charts.accumulated_rain.chance_amount.low'),
    color: '#BEBEBE'
  },
  medium: {
    description: I18n.t('v4/weather.charts.accumulated_rain.chance_amount.medium'),
    color: '#9ECAE1'
  },
  high: {
    description: I18n.t('v4/weather.charts.accumulated_rain.chance_amount.high'),
    color: '#4292C6'
  },
  veryHigh: {
    description: I18n.t('v4/weather.charts.accumulated_rain.chance_amount.very_high'),
    color: '#08519C'
  }
})

const getRainStatus = (value) => {
  const descriptions = getAccumulatedRainStatus()

  switch (true) {
    case value <= 5:
      return {
        description: I18n.t('v4/weather.charts.accumulated_rain.chance_amount.none'),
        color: '#FFF'
      }
    case value > 5 && value <= 50:
      return descriptions.low
    case value > 50 && value <= 70:
      return descriptions.medium
    case value > 70 && value <= 90:
      return descriptions.high
    case value > 90:
      return descriptions.veryHigh
  }
}

export const deserializeData = (weatherForecast) =>
  weatherForecast
    ? weatherForecast.map(data => ({
      ...data,
      forecast_date: _.capitalize(new Date(data.forecast_date).toLocaleDateString(I18n.locale,
        {
          timeZone: 'UTC',
          weekday: 'short'
        }
      ))
    }))
    : []

export const dashboardVisualizations = [
  'daily',
  'hourly'
]

const decimalValues = [
  'pcp_tot',
  'pcp_cum'
]

export const deserializeHourlyData = weatherForecast =>
  weatherForecast
    ? weatherForecast.map(data => {
      _.forOwn(data, (value, key) => {
        if (!isNaN(value)) {
          data[key] = decimalValues.includes(key)
            ? Number(value).toFixed(2)
            : Math.floor(value)
        }
      })

      let formattedDate = ''

      if ((I18n.locale === 'pt-BR' || I18n.locale === 'es-MX')) {
        const localeDate = new Date(data.forecast_date)
          .toLocaleDateString(I18n.locale, {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            weekday: 'long'
          }).split(' ')
        formattedDate = `${_.capitalize(localeDate[0])} ${localeDate[1]}, ${localeDate[2]}h`
      } else {
        const localeDate = new Date(data.forecast_date)
          .toLocaleDateString(I18n.locale, {
            year: '2-digit',
            month: '2-digit',
            day: '2-digit',
            hour: '2-digit',
            weekday: 'long',
            hour12: true
          }).split(' ')
        formattedDate = `${_.capitalize(localeDate[0])} ${localeDate[1]} ${localeDate[2]} ${localeDate[3]}`
      }

      return {
        ...data,
        date: data.forecast_date,
        forecast_date: formattedDate,
        wd10: <WindDirection align={'right'} angle={data.wd10} size='20' />
      }
    })
    : []

export const getTableHeaders = (data, descriptors) => {
  if (!data || !data[0]) return []

  const dataKeys = data[0]
  const headers = Object.keys(dataKeys).reduce((headers, field) => {
    const descriptor = getDescriptor(descriptors, field)

    if (descriptor) {
      headers.push({
        title: descriptor.description,
        cellStyle: {
          textAlign: 'center',
          padding: 0,
          paddingLeft: 10
        },
        headerStyle: {
          textAlign: 'center',
          padding: 0,
          paddingLeft: 15,
        },
        field
      })
    }

    return headers
  }, [])

  // Forecast_Date should always be the first column, this moves from wherever it is
  // to the first position.
  let index = headers.findIndex(el => el.field === 'forecast_date')
  headers.splice(0, 0, headers.splice(index, 1)[0])

  return headers
}

export const getDescriptor = (descriptors, name) =>
  descriptors.find(descriptor => descriptor.name === name)

export const isHourly = (kind) => kind === 'hourly'

export const getPreviewMode = (kind) => ({
  hourly: 'daily',
  daily: 'hourly'
})[kind]

export const getDropColor = (color) => ({
  [dropTypes[0]]: 'rgba(135,218,88,0.6)',
  [dropTypes[1]]: 'rgba(255, 214, 0, 0.6)',
  [dropTypes[2]]: 'rgba(255, 65, 65, 0.6)'
})[color]

const dropTypes = [
  I18n.t('v4/weather.dashboard.drop.preferred'),
  I18n.t('v4/weather.dashboard.drop.marginal'),
  I18n.t('v4/weather.dashboard.drop.unsuitable')
]

const mobileTableHeaders = [
  'forecast_date',
  'ws10',
  'wd10',
  'pcp_tot',
  'drop_medium',
  'drop_coarse',
  'drop_fine'
]

export const getForecastPointHeaders = (headers = [], isMobile) => {
  const tableHeaders = headers.map(header => {
    if (header.name === 'forecast_date') {
      return {
        title: header.description,
        field: header.name,
        customSort: (a, b) => new Date(a.date).getTime() - new Date(b.date).getTime(),
        defaultSort: 'asc',
        cellStyle: customPadding
      }
    } else {
      return {
        title: header.description,
        field: header.name,
        cellStyle: rowData => dropTypes.some(drop => drop === rowData)
          ? ({ background: `${getDropColor(rowData)}`, textAlign: 'center' })
          : ({ textAlign: 'center' })
      }
    }
  })

  return !isMobile
    ? tableHeaders
    : tableHeaders.filter(header => mobileTableHeaders.find(mobileHeader => mobileHeader === header.field))
}

export const customPadding = { padding: '4px', textAlign: 'center' }
