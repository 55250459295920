import React, { Component } from 'react'

import withStyles from '@material-ui/core/styles/withStyles'

import {
  Button,
  Card,
  CardActionArea,
  CardActions,
  CardContent,
  CardMedia,
  Paper,
  Typography
} from '@material-ui/core'

import styles from './styles'

import { truncateString } from '@models/store'

class Product extends Component {
  state = {
    price: {},
    maxPrice: 0,
    description: ''
  }

  getPrice = (price) => {
    const { planId } = this.props

    return price.plan_id === planId
  }

  componentDidMount() {
    const { product } = this.props

    const description = product.description.length > 60
      ? `${product.description.slice(0, 60)}...`
      : product.description

    this.setState({ description })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps !== this.props) {
      const { product, currentPlan, interval, plans } = this.props

      var discountPercentage = 0
      var discountValue = 0

      const price = {}
      const priceOnPlan = product.store_product_prices.filter(this.getPrice)
      const max = Number(product.maxPrice.monthly)

      price.onPlan = !_.isEmpty(priceOnPlan) ? Number(priceOnPlan[0]['price']) : -1
      price.monthly = Number(price.onPlan) / interval
      price.kind = price.onPlan === price.monthly ? 'monthly' : ''

      price.monthly > 0 ? discountPercentage = (max - price.monthly) * 100 / max : null
      price.discountPercentage = (discountPercentage).toFixed()

      price.monthly > 0 ? discountValue = (max - Number((price.monthly).toFixed(2))) * interval : null
      price.discountValue = discountValue.toFixed(2)

      this.setState({ price })
    }
  }

  render() {
    const { classes, product, planId } = this.props
    const { price, description } = this.state

    return (
      <div className={classes.card_content}>
        <Card className={classes.card}>
          <CardActionArea onClick={() => Turbolinks.visit(`store/${product.id}?plan_id=${planId}`)}>
            <CardMedia
              className={classes.media}
              image={product.image_url}
            />
            <CardContent className={classes.card__center}>
              <Typography gutterBottom variant='h5' component='h2' className={classes.card__green}>
                {product.name}
              </Typography>

              <Typography gutterBottom variant='caption' component='h2' className={classes.card_subtitle}>
                {
                  !!product.subtitle
                    ? truncateString(product.subtitle)
                    : ''
                }
              </Typography>
            </CardContent>
          </CardActionArea>

          <CardActions className={classes.card__footer}>
            <Typography gutterBottom variant='subtitle2' className={classes.card__center}>
              {
                price.monthly > 0 ?
                  <div>
                    {
                      price.discountPercentage > 0 &&
                      <Typography className={classes.cart__item__value}>
                        <span className={classes.card__max__value}>
                          {`R$ ${product.maxPrice.monthly}/${I18n.t('v4/store.recorrencies.month')}`}
                        </span>
                        <span className={classes.card__discount}>
                          {`-${price.discountPercentage}%`}
                        </span>
                      </Typography>
                    }
                    <Typography className={classes.card__item__value__monthly}>
                      <span className={classes.card__sign}>R$</span>
                      <span className={classes.card__value}>{(price.monthly).toFixed(2)}</span>
                      <span className={classes.card__monthly}>{`/${I18n.t('v4/store.recorrencies.month')}`}</span>
                    </Typography>
                  </div>
                  : (
                    <Typography variant='body2' className={classes.card__item__value}>
                      {I18n.t('v4/store.unavailable')}
                    </Typography>
                  )
              }
            </Typography>
          </CardActions>

          <CardActions>
            {
              product.purchasable ?
                <Button
                  variant='contained'
                  color='primary'
                  className={classes.card__button}
                  onClick={() => Turbolinks.visit(`store/checkout?plan_id=${planId}&store_product_id=${product.id}`)}
                  disabled={price.monthly < 0}
                >
                  {I18n.t('v4/store.sign')}
                </Button>
                :
                <div className={classes.card__center}>
                  <Typography variant='body1' className={classes.card__item__value}>
                    {I18n.t('v4/store.already_bought')}
                  </Typography>
                </div>
            }
          </CardActions>

          <CardActionArea onClick={() => Turbolinks.visit(`store/${product.id}?plan_id=${planId}`)}>
            <Typography variant='body2' color='textSecondary' component='p' className={classes.card__center}>
              {I18n.t('v4/store.more')}
            </Typography>
          </CardActionArea>
        </Card>

        {
          price.discountValue > 0 && product.purchasable &&
          <Paper className={classes.card_discount}>
            <Typography className={classes.card_discount_text} variant='caption'>
              {`${I18n.t('v4/store.total')} R$${(price.onPlan).toFixed(2)}`}
            </Typography>

            <Typography variant='body2' component='p' className={classes.card_discount_text}>
              {`${I18n.t('v4/store.save')} R$${price.discountValue}`}
            </Typography>
          </Paper>
        }
      </div>
    )
  }
}

export default withStyles(styles)(Product)
