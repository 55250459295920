import React from "react";
import { Chip, Paper, Typography } from "@material-ui/core";
import { Error } from "@material-ui/icons/"
import styles from "./styles";
import { withStyles } from "@material-ui/core/styles";
import { getDateWithHours } from "@services/locale";
import Dialog from "@ui/Dialog";

const Card = ({
  type,
  title,
  subtitle,
  onClick,
  classes,
  priority,
  status,
  created_at,
  dialog,
  setDialog
}) => {
  const time = getDateWithHours(created_at);
  return (
    <>
      <Paper onClick={onClick} className={classes.card}>
        <div className={classes.card_container}>
          <div className={classes.card_header}>
            {priority === "important" && (
              <Chip className={classes.card_tag_important} label={type} />
            )}

            {status == "newly" && (
              <Chip
                className={classes.card_tag_new}
                label={I18n.t("notifications_central.new_alert")}
              />
            )}
          </div>
          <div className={classes.card_area}>
            <Typography variant="body2">{title}</Typography>
            <Typography variant="body1" className={classes.card_subtitle}>
              {subtitle}
            </Typography>
            <Typography variant="body1" className={classes.card_subtitle}>
              {`${time.infoDate} ` + "|" + ` ${time.infoHour}`}
            </Typography>
          </div>
        </div>
      </Paper>
      <Dialog
        title={dialog.title}
        subtitle={dialog.subtitle}
        open={dialog.isOpen}
        withCloseButton
        onClose={() => setDialog({ ...dialog, isOpen: false })}
        hasTitleIcon
        titleIcon={<Error className={classes.card_icon} />}
        disableTypography
      >
        {dialog.component}
      </Dialog>
    </>
  );
};

export default withStyles(styles)(Card);
