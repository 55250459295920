import React from 'react'

import UploadFileIcon from '@images/leaflet/upload_file_icon.png'

import AreaMenu from '@images/map/draw_area.svg'
import AreaMenuActive from '@images/map/draw_area_selected.svg'
import Circle from '@images/map/circle.svg'
import Polygon from '@images/map/polygon.svg'
import Edit from '@images/map/edit.svg'

export const defaultInitialCoordinates = {
  zoom: 5,
  centerCoord: [-15.7801, -47.9292] //Brasilia
}

export const defaultAreaColor = '#00F0FF'
export const maxZoom = 20

export const getImageBounds = (area) => {
  const { coordinates } = area

  const latitudes = coordinates.map(coordinate => coordinate.lat)
  const longitudes = coordinates.map(coordinate => coordinate.lng === undefined ? coordinate.lon : coordinate.lng)

  return [
    [_.max(latitudes), _.max(longitudes)],
    [_.min(latitudes), _.min(longitudes)]
  ]
}

export const circleToPolygon = (L, circle, vertices, map) => {
  map = map || circle._map

  var points = [],
    crs = map.options.crs,
    DOUBLE_PI = Math.PI * 2,
    angle = 0.0,
    projectedCentroid, radius,
    point, project, unproject

  if (crs === L.CRS.EPSG3857) {
    project = map.latLngToLayerPoint.bind(map)
    unproject = map.layerPointToLatLng.bind(map)
    radius = circle._radius
  } else {
    project = crs.projection.project.bind(crs.projection)
    unproject = crs.projection.unproject.bind(crs.projection)
    radius = circle._mRadius
  }

  projectedCentroid = project(circle._latlng)

  vertices = vertices || L.Circle.SECTIONS_COUNT;

  for (var i = 0; i < vertices; i++) {
    angle -= (DOUBLE_PI / vertices); // clockwise
    point = new L.Point(
      projectedCentroid.x + (radius * Math.cos(angle)),
      projectedCentroid.y + (radius * Math.sin(angle))
    );
    if (i > 0 && point.equals(points[i - 1])) {
      continue
    }
    points.push(unproject(point))
  }

  return [...points, points[0]]
}

export const getLeafLetTranslations = () => ({
  draw: {
    toolbar: {
      actions: {
        title: I18n.t('leaflet.draw.toolbar.actions.title'),
        text: I18n.t('leaflet.draw.toolbar.actions.text')
      },
      finish: {
        title: I18n.t('leaflet.draw.toolbar.finish.title'),
        text: I18n.t('leaflet.draw.toolbar.finish.text')
      },
      undo: {
        title: I18n.t('leaflet.draw.toolbar.undo.title'),
        text: I18n.t('leaflet.draw.toolbar.undo.text')
      },
      buttons: {
        polyline: I18n.t('leaflet.draw.toolbar.buttons.polyline'),
        polygon: I18n.t('leaflet.draw.toolbar.buttons.polygon'),
        rectangle: I18n.t('leaflet.draw.toolbar.buttons.rectangle'),
        circle: I18n.t('leaflet.draw.toolbar.buttons.circle'),
        marker: I18n.t('leaflet.draw.toolbar.buttons.marker'),
        circlemarker: I18n.t('leaflet.draw.toolbar.buttons.circlemarker')
      }
    },
    handlers: {
      circle: {
        tooltip: {
          start: I18n.t('leaflet.draw.handlers.circle.tooltip.start')
        },
        radius: I18n.t('leaflet.draw.handlers.circle.radius')
      },
      circlemarker: {
        tooltip: {
          start: I18n.t('leaflet.draw.handlers.circlemarker.tooltip.start')
        }
      },
      marker: {
        tooltip: {
          start: I18n.t('leaflet.draw.handlers.marker.tooltip.start')
        }
      },
      polygon: {
        tooltip: {
          start: I18n.t('leaflet.draw.handlers.polygon.tooltip.start'),
          cont: I18n.t('leaflet.draw.handlers.polygon.tooltip.cont'),
          end: I18n.t('leaflet.draw.handlers.polygon.tooltip.end')
        }
      },
      polyline: {
        error: I18n.t('leaflet.draw.handlers.polyline.error'),
        tooltip: {
          start: I18n.t('leaflet.draw.handlers.polyline.tooltip.start'),
          cont: I18n.t('leaflet.draw.handlers.polyline.tooltip.cont'),
          end: I18n.t('leaflet.draw.handlers.polyline.tooltip.end')
        }
      },
      rectangle: {
        tooltip: {
          start: I18n.t('leaflet.draw.handlers.rectangle.tooltip.start')
        }
      },
      simpleshape: {
        tooltip: {
          end: I18n.t('leaflet.draw.handlers.simpleshape.tooltip.end')
        }
      }
    }
  },
  edit: {
    toolbar: {
      actions: {
        save: {
          title: I18n.t('leaflet.edit.toolbar.actions.save.title'),
          text: I18n.t('leaflet.edit.toolbar.actions.save.text')
        },
        cancel: {
          title: I18n.t('leaflet.edit.toolbar.actions.cancel.title'),
          text: I18n.t('leaflet.edit.toolbar.actions.cancel.text')
        },
        clearAll: {
          title: I18n.t('leaflet.edit.toolbar.actions.clearAll.title'),
          text: I18n.t('leaflet.edit.toolbar.actions.clearAll.text')
        }
      },
      buttons: {
        edit: I18n.t('leaflet.edit.toolbar.buttons.edit'),
        editDisabled: I18n.t('leaflet.edit.toolbar.buttons.editDisabled'),
        remove: I18n.t('leaflet.edit.toolbar.buttons.remove'),
        removeDisabled: I18n.t('leaflet.edit.toolbar.buttons.removeDisabled')
      }
    },
    handlers: {
      edit: {
        tooltip: {
          text: I18n.t('leaflet.edit.handlers.edit.tooltip.text'),
          subtext: I18n.t('leaflet.edit.handlers.edit.tooltip.subtext')
        }
      },
      remove: {
        tooltip: {
          text: I18n.t('leaflet.edit.handlers.remove.tooltip.text')
        }
      }
    }
  }
})

export const getZoom = (areaSize) => {
  const defaultZoom = 16
  const maxZoom = 18

  if (!areaSize || isNaN(Number(areaSize))) return defaultZoom

  return calculateZoom(areaSize, maxZoom)
}

const calculateZoom = (value, maxZoom) => {
  const zoom = Math.floor(-((Math.log(value / 1.09951162e12)) / Math.log(4)))
  return zoom > maxZoom ? maxZoom : zoom
}

const convertDMStoDD = (degrees, minutes, seconds, direction) => {
  let dd = degrees + minutes / 60 + seconds / (60 * 60)

  if (direction == 'S' || direction == 'W' || direction == 'O') {
    dd = dd * -1;
  }

  return dd
}

export const isValidLatValue = (value) => typeof (value) === 'number' && value >= -90 && value <= 90

export const isValidLongValue = (value) => typeof (value) === 'number' && value >= -180 && value <= 180

export const latLngValue = (searchValue) => {
  const reg = /[\d]+|[nNeEwWsS]/
  const parts = searchValue.match(/[\d|.|,]+|[nNeEwWsS|nNlLoOsS]/g)
  if (!parts) {
    return false
  }
  const latParts = convertDMStoDD(Number(parts[0]), Number(parts[1]), Number(parts[2]), (parts[3]))
  const lngParts = convertDMStoDD(Number(parts[4]), Number(parts[5]), Number(parts[6]), (parts[7]))

  if (isValidLatValue(latParts) && isValidLongValue(lngParts)) {
    return [latParts.toFixed(4), lngParts.toFixed(4)]
  } else {
    return false
  }
}

export const decimalValue = (searchValue) => {
  const parts = searchValue.split(/[\,\s]+/)
  if (!_.isEmpty(parts[1])) {
    const latParts = Number(parts[0])
    const lngParts = Number(parts[1])

    if (isValidLatValue(latParts) && isValidLongValue(lngParts)) {
      return [latParts.toFixed(4), lngParts.toFixed(4)]
    } else {
      return false
    }
  } else { return false }
}

const formatGeocoder = (data, iconName) =>
  data.map(d => {
    if (!!d.latitude) {
      d.lat = d.latitude
    }

    if (!!d.longitude) {
      d.lon = d.longitude
    }

    d.iconName = iconName
    d.hasPin = false

    return d
  })

const searchByValue = (value, name) =>
  new RegExp(normalizeString(value), 'i').exec(normalizeString(name))

export const getInternalResults = ({
  areas,
  value,
  forecastPointsList,
  equipments
}) => {
  const geocoderFP = formatGeocoder(forecastPointsList, 'wb_sunny')
  const geocoderEquipment = formatGeocoder(equipments, 'settings_remote')
  const geocoderArea = formatGeocoderArea(areas, 'landscape')

  const fpResults = geocoderFP.filter(fp => searchByValue(value, fp.name))

  const equipmentResults = geocoderEquipment.filter(equipment => searchByValue(value, equipment.name))

  const areaResults = geocoderArea.filter(area => searchByValue(value, area.name))

  return [
    ...fpResults.slice(0, 2),
    ...equipmentResults.slice(0, 2),
    ...areaResults.slice(0, 2)
  ]
}

export const formatGeocoderArea = (areas, iconName) =>
  formatGeocoder(areas, iconName)

export const normalizeString = (string) =>
  string.toLowerCase().normalize('NFD').replace(/[\u0300-\u036f]/g, "")

const icons = {
  polygon: Polygon,
  circle: Circle,
  file: UploadFileIcon,
  edit: Edit
}

export const getControlIcon = (classes, value, kind) =>
  <img
    src={icons[value]}
    alt={`${value}_icon`}
    className={classes.Control_icon}
  />

export const controlsMenu = (customHandleClick) =>
  [
    {
      value: 'polygon',
      kind: 'icon',
      name: I18n.t('v4/map.controls.draw_field'),
      useControl: '.leaflet-draw-draw-polygon',
      intercomTag: 'draw-polygon-map'
    },
    {
      value: 'circle',
      kind: 'icons',
      name: I18n.t('v4/map.controls.draw_pivot'),
      useControl: '.leaflet-draw-draw-circle',
      intercomTag: 'draw-pivot-map'
    },
    {
      handleClick: customHandleClick,
      value: 'file',
      kind: 'svg',
      name: I18n.t('v4/map.controls.upload_file'),
      intercomTag: 'upload_area'
    }
  ]

export const handleClickControlMenu = (menu) => document.querySelector(menu).click()

export const changeMapStyle = (isOpenMapAreaMenu) => {
  const controlDiv = document.getElementsByClassName('leaflet-bottom')

  return isOpenMapAreaMenu ? controlDiv[1].style.top = '25%' : controlDiv[1].style.top = ''
}

export const changeAreaMenuStyle = (isActive) => {
  const controlDiv = document.getElementById('leaflet-control-area-menu')

  if (isActive) {
    controlDiv.style.background = '#66A343'
    controlDiv.style['background-image'] = `url(${AreaMenuActive}`

  } else {
    controlDiv.style.background = '#FFF'
    controlDiv.style['background-image'] = `url(${AreaMenu})`
  }

  controlDiv.style['background-repeat'] = 'no-repeat'
  controlDiv.style['background-position'] = 'center'
  controlDiv.style['background-clip'] = 'padding-box'
}

export const getCenterCoordinates = (areas) => {
  if (areas[0]) {
    const { latitude, longitude, size_ha } = areas[0]

    return {
      centerCoord: [latitude, longitude],
      zoom: getZoom(size_ha)
    }
  }
  return defaultInitialCoordinates
}

export const satelliteOptions = {
  type: 'hybrid',
  styles: [
    {
      featureType: 'poi',
      stylers: [{ visibility: 'off' }]
    },
  ]
}

export const streetsOptions = {
  type: 'roadmap',
  styles: [
    {
      featureType: 'poi',
      stylers: [{ visibility: 'off' }]
    }
  ]
}
