import React from 'react'
import PropTypes from 'prop-types'

const ToolTip = ({ active, payload, label, classes, descriptors, getDescriptor }) =>
  active
    ? (
      <div className={classes.tooltip__wrapper}>
        <p>{label}</p>
        {
          payload.map(({ dataKey, stroke, fill, value }) =>
            <p
              key={dataKey}
              style={{
                color: stroke || fill
              }}
            >
              {
                `${getDescriptor(descriptors, dataKey).description}: ${value}${getDescriptor(descriptors, dataKey).unit
                  ? ` ${getDescriptor(descriptors, dataKey).unit}`
                  : ''}`
              }
            </p>
          )
        }
      </div>
    )
    : null

ToolTip.defaultProps = {
  descriptors: []
}

ToolTip.propTypes = {
  descriptors: PropTypes.array,
  classes: PropTypes.object
}

export default ToolTip
