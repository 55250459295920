import React from 'react'

import {
  Switch,
  withStyles
} from '@material-ui/core'

const CustomSwitch = ({
  checked,
  classes,
  handleSelectedPreviewMode,
  previewMode
}) =>
  <Switch
    classes={{ icon: classes.Switch_icon }}
    checked={checked}
    onChange={() => handleSelectedPreviewMode(previewMode)}
    value='checked'
  />

const styles = theme => ({
  Switch_icon: {
    backgroundColor: theme.palette.primary.main
  }
})

export default withStyles(styles)(CustomSwitch)
