import React from 'react'
import PropTypes from 'prop-types'

import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'

import { fields } from '@models/analysis/sample'
import styles from './styles'

const Show = ({ classes, sample }) =>
  <Paper
    className={classes.showPage__wrapper}
    elevation={0}
  >
    <div className={classes.sample__content}>
      {
        fields.map((field) =>
          sample[field.name] && !field.hidden &&
          <div key={field.name} className={classes.sample__item}>
            <Typography
              color="textSecondary"
            >
              {I18n.t(`activerecord.attributes.v4/analysis.${field.name}`)}
            </Typography>

            <Typography>
              {sample[field.name]} {field.measure}
            </Typography>
          </div>
        )
      }
    </div>
  </Paper>

Show.propTypes ={
  sample: PropTypes.object
}

export default withStyles(styles)(Show)
