import React from 'react'

import { DialogTitle, DialogContent, DialogContentText, Button, DialogActions } from '@material-ui/core'
import MaterialDialog from '@material-ui/core/Dialog'
import withMobileDialog from '@material-ui/core/withMobileDialog'

const Dialog = ({
  cancelText,
  classes,
  confirmText,
  content,
  onCancel,
  onConfirm,
  open,
  showCancelButton,
  title
}) =>
  <MaterialDialog
    classes={{ paper: classes.Dialog__paper }}
    fullWidth
    open={open}
  >
    <DialogTitle>
      {title}
    </DialogTitle>

    <DialogContent>
      {
        !_.isArray(content)
          ? <DialogContentText>
            {content}
          </DialogContentText>
          : content.map((line, index) =>
            <DialogContentText key={index}>
              {line}
            </DialogContentText>
          )}
    </DialogContent>

    <DialogActions>
      {
        showCancelButton &&
        <Button
          color='secondary'
          onClick={onCancel}
        >
          {cancelText}
        </Button>
      }
      <Button
        color='primary'
        type='submit'
        variant='contained'
        onClick={onConfirm}
      >
        {confirmText}
      </Button>
    </DialogActions>
  </MaterialDialog >

Dialog.defaultProps = {
  title: I18n.t('confirm_dialog.title'),
  content: I18n.t('confirm_dialog.content'),
  cancelText: I18n.t('confirm_dialog.cancelText'),
  confirmText: I18n.t('confirm_dialog.confirmText')
}

export default withMobileDialog()(Dialog)
