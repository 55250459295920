import React from 'react'

import { Typography, Switch } from '@material-ui/core'

import { withStyles } from '@material-ui/core/styles'

const ManagementDisplayOption = ({ classes, disabled, selected, handleChangeVisualization, options }) =>
  <section className={classes.ManagementDisplayOption}>
    <Typography
      className={classes.ManagementDisplayOption_Label}
      variant='body2'
    >
      {I18n.t(`v4/irrigation_management.exhibition`)}:
    </Typography>

    <div className={classes.ManagementDisplayOption_Options}>
      <Typography
        color={selected === 0 ? 'primary' : 'textPrimary'}
        variant={selected === 0 ? 'body2' : 'body1'}
      >
        {I18n.t(`v4/irrigation_management.visualizations.${options[0]}`)}
      </Typography>

      <Switch
        disabled={disabled}
        checked={selected === 1}
        onChange={() => handleChangeVisualization(Number(!selected))}
        classes={{
          switchBase: classes.ManagementDisplayOption_Switch
        }}
        color='default'
        data-intercom-target='switch-weekly-season-aqua'
      />

      <Typography
        color={selected === 1 ? 'primary' : 'textPrimary'}
        variant={selected === 1 ? 'body2' : 'body1'}
      >
        {I18n.t(`v4/irrigation_management.visualizations.${options[1]}`)}
      </Typography>
    </div>
  </section>

const styles = theme => ({
  ManagementDisplayOption: {
    marginLeft: 'auto',
    '@media (max-width: 612px)': {
      marginLeft: 0,
      marginTop: `${theme.spacing.unit}px`
    }
  },
  ManagementDisplayOption_Options: {
    display: 'flex',
    alignItems: 'center',
  },
  ManagementDisplayOption_Label: {
    marginRight: `${theme.spacing.unit}px`
  },
  ManagementDisplayOption_Switch: {
    color: theme.palette.primary.main
  }
})

ManagementDisplayOption.defaultProps = {
  disabled: false,
  handleChangeVisualization: () => { },
  options: [],
  selected: 0
}

export default withStyles(styles)(ManagementDisplayOption)
