import React, { Component } from 'react'
import { OutlinedInput, Paper } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import withStyles from '@material-ui/core/styles/withStyles'
import Select from '@ui/SimpleSelect'

import LastPlantationsTable from './LastPlantationsTable'
import styles from './styles'


class DashboardIndex extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentAreaIndex: 0
    }
  }

  componentDidMount() {
    const { areasList, areaId } = this.props

    this.setState({
      currentAreaIndex: areasList.findIndex(area => area.id === areaId)
    })
  }


  handleAreaChange = ({ target }) => {
    const { areasList, o } = this.props
    const { currentAreaIndex } = this.state
    const index = target.value

    if (index === currentAreaIndex) {
      return
    }

    Turbolinks.visit(`/v4/o/${o}/areas/${areasList[index].id}/dashboard`)
  }

  render() {
    const {
      classes,
      lastPlantations,
      areasList,
    } = this.props
    const {currentAreaIndex } = this.state
    
    return (
      <Paper
        elevation={0}
        className={classes.Dashboard__wrapper}
      >
        <header className={classes.Dashboard__header}>
          <Typography component='h2' variant='h5'>
            {I18n.t('v4/area.dashboard.title')}:
          </Typography>
          <Select
              values={areasList}
              selectedIndex={currentAreaIndex}
              handleChange={v => this.handleAreaChange(v)}
              input={<OutlinedInput name={I18n.t('v4/area.dashboard.area_input_name')}
              className={classes.Dashboard__select}
              />}
            />
        </header>
        <main>
          <Typography component='h2' variant='h6'>
            {I18n.t('v4/area.dashboard.last_plantations')}
          </Typography>
          <LastPlantationsTable
            lastPlantations={lastPlantations}
          />
        </main>
      </Paper>
    )
  }
}

export default withStyles(styles)(DashboardIndex)
