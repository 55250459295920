import React from 'react'

import Select from '@ui/Fields/Select'

const ControlledSelect = ({
  onInputChange,
  input,
  ...props
}) =>
  <Select
    input={{
      ...input,
      onChange: event => {
        input.onChange(event)
        onInputChange && onInputChange(event)
      }
    }}
    {...props}
  />

ControlledSelect.defaultProps = {
  input: {
    onChange: () => { }
  }
}

export default ControlledSelect
