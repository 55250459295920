import React from 'react'
import PropTypes from 'prop-types'
import CultureForm from './CultureForm'

const New = ({ cultivars, template_cultures, handleError, o }) =>
  <CultureForm
    cultivars={cultivars}
    cultureTemplates={template_cultures}
    initialValues={{}}
    title={I18n.t('v4/culture.actions.add')}
    action='create'
    url={`/v4/o/${o}/cultures`}
    handleError={handleError}
    o={o}
  />

New.propTypes = {
  template_cultures: PropTypes.array,
  cultivars: PropTypes.array
}

export default New
