import React from 'react'
import PropTypes from 'prop-types'

import NewFertilizer from './fertilizers/New'
import NewChemical from './chemicals/New'
import NewCultivar from './cultivar/New'
import NewOther from './others/New'

const NewInput = (props) => {
  const kinds = {
    fertilizer: <NewFertilizer {...props} />,
    chemical  : <NewChemical {...props} />,
    cultivar  : <NewCultivar {...props} />,
    other     : <NewOther {...props} />
  }

  return kinds[props.model.kind]
}

NewInput.propTypes = {
  model: PropTypes.object
}

export default NewInput
