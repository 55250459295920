import React from 'react'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { Field } from 'react-final-form'

import TextField from '../ui/Fields/TextField'
import Select from '../ui/Fields/Select'
import Stages from './stages/Index'
import { Typography } from '@material-ui/core'

const Form = ({
  handleSubmit,
  classes,
  cultivars,
  action,
  values,
  perennialOptions,
  stages,
  stagesHandlers,
  hideBackButton,
  edited,
  o
}) => {
  const isStagesEmpty = stages.length === 0;
  
  if (!values.perennial) {
    values['perennial_zr'] = null
  }

  values['phenological_stages'] = stages

  let helperText = false

  if (values['zrme'] != null && values['zrma'] != null && (values['zrma'] > values['zrme'])) {
    helperText = true
  }

  return (
    <form className={classes.form} onSubmit={handleSubmit} data-testid='culture-form'>
      <main className={classes.form__fields}>
        <Grid container spacing={24}>
          <Grid item xs={12}>
            <div className={classes.form__field__wrapper}>
              <Field
                component={TextField}
                className={classes.form__fieldWithError}
                margin='normal'
                fullWidth
                InputLabelProps={{ shrink: true }}
                variant='outlined'
                label={I18n.t('activerecord.attributes.v4/culture.name')}
                name='name'
                required
              />
            </div>
          </Grid>

          <Grid item xs={12}>
            <div className={classes.form__field__wrapper}>
              <Field
                component={Select}
                className={classes.form__fieldWithError}
                InputLabelProps={{ shrink: true }}
                label={I18n.t('activerecord.attributes.v4/culture.cultivar')}
                name='cultivar_id'
                margin='normal'
                fields={{ id: 'id', name: 'name' }}
                data={cultivars}
                fullWidth
                variant='outlined'
              >
              </Field>
            </div>
          </Grid>

          <Grid item xs={6}>
            <div className={classes.form__field__wrapper}>
              <Field
                component={Select}
                className={classes.form__fieldWithError}
                name='perennial'
                label={I18n.t('activerecord.attributes.v4/culture.perennial')}
                fields={{ id: 'id', name: 'name' }}
                data={perennialOptions}
                fullWidth
                margin='normal'
                InputLabelProps={{ shrink: true }}
                variant='outlined'
                required
              >
              </Field>
            </div>
          </Grid>

          <Grid item xs={6}>
            <div className={classes.form__field__wrapper}>
              <Field
                component={TextField}
                className={classes.form__fieldWithError}
                margin='normal'
                fullWidth
                type='number'
                inputProps={{ min: '1', max: '365', step: '1' }}
                InputLabelProps={{ shrink: true }}
                variant='outlined'
                label={I18n.t('activerecord.attributes.v4/culture.ndc')}
                name='ndc'
                required
              />
            </div>
          </Grid>

          <Grid item xs={6}>
            <div className={classes.form__field__wrapper}>
              <Field
                component={TextField}
                className={classes.form__fieldWithError}
                margin='normal'
                fullWidth
                type='number'
                inputProps={{ min: '0', max: '200' }}
                error={helperText}
                helperText={helperText ? I18n.t('activerecord.attributes.v4/culture.initial') : ''}
                InputLabelProps={{ shrink: true }}
                variant='outlined'
                label={I18n.t('activerecord.attributes.v4/culture.zrma')}
                name='zrma'
                required
              />
            </div>
          </Grid>

          <Grid item xs={6}>
            <div className={classes.form__field__wrapper}>
              <Field
                component={TextField}
                className={classes.form__fieldWithError}
                margin='normal'
                fullWidth
                type='number'
                inputProps={{ min: '0', max: '200' }}
                InputLabelProps={{ shrink: true }}
                error={helperText}
                helperText={helperText ? I18n.t('activerecord.attributes.v4/culture.final') : ''}
                variant='outlined'
                label={I18n.t('activerecord.attributes.v4/culture.zrme')}
                name='zrme'
                required
              />
            </div>
          </Grid>

          <Grid item xs={6}>
            <div className={classes.form__field__wrapper}>
              <Field
                component={TextField}
                className={classes.form__fieldWithError}
                margin='normal'
                fullWidth
                type='number'
                inputProps={{ min: '0.1', max: '0.7', step: '0.01' }}
                InputLabelProps={{ shrink: true }}
                variant='outlined'
                label={I18n.t('activerecord.attributes.v4/culture.ftab')}
                name='ftab'
                required
              />
            </div>
          </Grid>

          <Grid item xs={6}>
            <div className={classes.form__field__wrapper}>
              <Field
                component={TextField}
                className={classes.form__fieldWithError}
                margin='normal'
                fullWidth
                type='number'
                inputProps={{ min: '0', max: '0.04', step: '0.01' }}
                InputLabelProps={{ shrink: true }}
                variant='outlined'
                label={I18n.t('activerecord.attributes.v4/culture.coeff')}
                name='coeff'
                required
              />
            </div>
          </Grid>
        </Grid>

       
        <Stages
          stages={stages}
          handlers={stagesHandlers}
        />

      </main>
      <footer className={classes.form__actions}>
        <Button
          className={classes.form__action}
          color='primary'
          type='submit'
          variant='contained'
          disabled={helperText || isStagesEmpty}
        >
          {action}
        </Button>
        {hideBackButton || (
          <Button
            className={classes.form__action}
            color='secondary'
            onClick={() => {
              if (!edited) {
                Turbolinks.visit(`/v4/o/${o}/cultures`)
                return
              }

              if (window.confirm(I18n.t('confirmation.unsaved_changes'))) {
                Turbolinks.visit(`/v4/o/${o}/cultures`)
              }
            }}
          >
            {I18n.t('actions.back')}
          </Button>
        )}
      </footer>
    </form>
  )
}

Form.defaultProps = {
  cultivars: [],
  perennialOptions: []
}

export default Form
