import React from 'react'

import { withStyles } from '@material-ui/core'

const OdometerDigit = ({ classes, initialValue, customId }) =>
  <div
    id={customId}
    className={classes.OdometerDigit_wrapper}
  >
    {initialValue}
  </div>

const styles = theme => ({
  OdometerDigit_wrapper: {
    width: ' 40.13px',
    height: ' 57.33px',
    background: 'linear-gradient(180deg, #E3E3E3 0%, #FFFFFF 37.52%, #FFFFFF 69.46%, #F1F1F1 100%)',
    border: `2px solid ${theme.palette.hydrometerValueColor}`,
    boxSizing: 'border-box',
    borderRadius: '4.7774px',
    fontSize: '42.2148px',
    textAlign: 'center',
    color: theme.palette.hydrometerValueColor,
    fontFamily: 'Helvetica Neue, sans-serif',
    lineHeight: '1.1em',
    marginRight: theme.spacing.unit,
    fontWeight: 'bold',
  }
})

export default withStyles(styles)(OdometerDigit)
