export const getDefaultChartTypes = (descriptors = [], noGrouping, keys) => {
  const formattedDescriptors = formatDescriptors(descriptors)

  _.remove(formattedDescriptors, { name: 'eto' })

  return formattedDescriptors.reduce((selectedCharts, descriptor) => {
    selectedCharts.push(defaultCharts(noGrouping, descriptor, keys))
    return selectedCharts
  }, []).flat()
}

const defaultCharts = (noGrouping, descriptor = {}, keys = []) => {
  const { name, description, legend, short_description } = descriptor

  if (noGrouping) {
    return { id: name, type: "line", ...descriptor }
  }

  return keys.includes(`${name}_sum`)
    ? [{ id: `${name}_sum`, type: "bar", ...descriptor }]
    : keys.includes(`${name}_mode`)
      ? [{ id: `${name}_mode`, type: "line", ...descriptor }]
      : [
        { id: `${name}_avg`, type: "line", ...descriptor },
        {
          description: `${description} (Min/Max)`,
          name: `${name} (Min/Max)`, id: `range_${name}`,
          rangeArea: [`${name}_min`, `${name}_max`], type: "area", group: descriptor.group,
          opacity: 0.3, yAxisId: `${name}_avg`, color: descriptor.color,
          legend: legend ? `${legend} (Min/Max)` : null,
          short_description: short_description ? `${short_description} (Min/Max)` : null
        }
      ]
}

export const getDefaultTableHeaders = (descriptors, noGrouping, keys) =>
  [
    tableHeaders(noGrouping),
    ...descriptors.reduce((headers, descriptor) => {
      headers.push(tableHeaders(noGrouping, descriptor, keys))
      return headers
    }, [])
  ]


const tableHeaders = (noGrouping, descriptor, keys = []) => {
  if (!descriptor) {
    return { title: I18n.t("v4/telemetry.date"), field: "date" }
  }

  const { description, name, unit, legend } = descriptor

  if (name === 'eto') {
    return {
      title: `${description} ${unit ? `(${unit})` : ""}`,
      field: null, name,
      legend: legend,
      data: [{ title: I18n.t("v4/telemetry.sum"), field: `${name}` }]
    }
  }

  if (noGrouping) {
    return {
      title: `${description} ${unit ? `(${unit})` : ""}`,
      field: name, name,
      legend: legend, legend
    }
  }

  return keys.includes(`${name}_sum`)
    ? {
      title: `${description} ${unit ? `(${unit})` : ""}`,
      field: null, name,
      legend: legend, legend,
      data: [
        { title: I18n.t("v4/telemetry.sum"), field: `${name}_sum` },
      ]
    }
    : keys.includes(`${name}_mode`)
      ? {
        title: `${description} ${unit ? `(${unit})` : ""}`,
        field: null, name,
        legend: legend, legend,
        data: [{ title: I18n.t("v4/telemetry.avg"), field: `${name}_mode` }]
      }
      : {
        title: `${description} ${unit ? `(${unit})` : ""}`,
        field: null, name,
        legend: legend, legend,
        data: [
          { title: I18n.t("v4/telemetry.min"), field: `${name}_min` },
          { title: I18n.t("v4/telemetry.avg"), field: `${name}_avg` },
          { title: I18n.t("v4/telemetry.max"), field: `${name}_max` }
        ]
      }
}

export const deserialize = (measure, groupBy = "hour") => {
  const date = measure.date || measure.measured_at

  return {
    ...roundMeasures(measure),
    date: groupings[groupBy](new Date(date))
  }
}

const roundMeasures = (measures) => Object.keys(measures).reduce((measure, key) => {
  if (measures[key] === null) {
    return measure
  }
  measure[key] = key.slice(0, 2) === 'ec'
    ? _.round(Number(measures[key]), 2)
    : Number(measures[key])
      ? _.round(Number(measures[key]), 1)
      : measures[key]
  return measure
}, {})

const groupings = {
  hour: (date) => date.toLocaleDateString(I18n.locale, { timeZone: "UTC", hour: "2-digit", minute: "2-digit" }),
  day: (date) => date.toLocaleDateString(I18n.locale, { timeZone: "UTC" }),
  week: (date) => date.toLocaleDateString(I18n.locale, { timeZone: "UTC", week: "long" }),
  biweek: (date) => date.toLocaleDateString(I18n.locale, { timeZone: "UTC", week: "long" }),
  month: (date) => date.toLocaleDateString(I18n.locale, { timeZone: "UTC", month: "long" }),
  year: (date) => date.getUTCFullYear(),
  none: (date) => date.toLocaleDateString(I18n.locale, { timeZone: "UTC", hour: "2-digit", minute: "2-digit" }),
}

export const group = (lines) => _.groupBy(lines, "group")

export const getDataRanges = (data, rules) =>
  data.map(serie => {
    let ranges = {}

    rules.forEach(rule => {
      const { rangeArea } = rule

      if (rangeArea) {
        ranges[rule.id] = [serie[rangeArea[0]], serie[rangeArea[1]]]
      }
    })

    return { ...serie, ...ranges }
  })

const formatDescriptors = descriptors => {
  const groupsObject = groups()
  const groupsArray = Object.keys(groupsObject).map(key => groupsObject[key])

  let lastGroupNumber = _.max(groupsArray.map(group => group.group)) + 1

  return descriptors.map((descriptor, index) => {
    const descriptorName = descriptor.name
    const variableName = descriptorName.match(/\D*/)[0]
    const variableInfo = groups(descriptorName)[variableName]

    if (!!variableInfo) {
      return { ...descriptor, ...variableInfo }
    }

    lastGroupNumber = lastGroupNumber + 1

    return {
      ...descriptor,
      color: defaultColors[index % defaultColors.length],
      group: lastGroupNumber
    }
  })
}

const colors = {
  vwc: [
    "hsl(204, 100%, 40%)",
    "hsl(210, 100%, 70%)",
    "hsl(210, 100%, 50%)",
    "hsl(210, 100%, 30%)",
    "hsl(210, 100%, 15%)",
    "hsl(210, 100%, 5%)"
  ],
  vic: [
    "hsl(0, 100%, 80%)",
    "hsl(0, 100%, 75%)",
    "hsl(0, 100%, 70%)",
    "hsl(0, 100%, 65%)",
    "hsl(0, 100%, 60%)",
    "hsl(0, 100%, 55%)"
  ],
  st: [
    "hsl(120, 100%, 70%)",
    "hsl(120, 100%, 50%)",
    "hsl(120, 100%, 40%)",
    "hsl(120, 100%, 25%)",
    "hsl(120, 100%, 15%)",
    "hsl(120, 100%, 5%)"
  ],
  ec: [
    "hsl(204, 100%, 40%)",
    "hsl(210, 100%, 70%)",
    "hsl(210, 100%, 50%)",
    "hsl(210, 100%, 30%)",
    "hsl(210, 100%, 15%)",
    "hsl(210, 100%, 5%)"
  ],
  ws: "#2980B9",
  wg: "#87BB52",
  pl: "#5D6D7E",
  at: "#CB4335",
  ah: "#3498DB",
  wd: "#8C448B",
  bv: "#B7950B",
  asr: "#E59866",
  sr: "#D5A846",
  lwl: "#00BFFF",
  lwh: "#87CEEB"
}

const defaultColors = [
  "#2980B9",
  "#87BB52",
  "#5D6D7E",
  "#CB4335",
  "#3498DB",
  "#8C448B",
  "#B7950B",
  "#E59866",
  "#D5A846"
]

const groups = name => ({
  asr: { group: 1, color: colors['asr'] },
  sr: { group: 1, color: colors['sr'] },
  at: { group: 1, color: colors['at'] },
  pl: { group: 2, color: colors['pl'] },
  ah: { group: 2, color: colors['ah'] },
  ws: { group: 3, color: colors['ws'] },
  wg: { group: 3, color: colors['wg'] },
  wd: { group: 3, color: colors['wd'] },
  vwc: { group: 4, color: colors['vwc'][`${name}`[3]] },
  st: { group: 5, color: colors['st'][`${name}`[2]] },
  vic: { group: 6, color: colors['vic'][`${name}`[3]] },
  lwl: { group: 7, color: colors['lwl'], yReference: 'lw' },
  lwh: { group: 7, color: colors['lwh'], yReference: 'lw' },
  ec: { group: 10, color: colors['ec'][`${name}`[2]] },
  bv: { group: 9, color: colors['bv'] },
  water_estimative:{ group: 8, color: colors['ah'] },
  water_volume:{ group: 8, color: colors['pl'] }
})
