import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

import ControlledFormField from '@ui/Fields/ControlledFormField'
import { InputAdornment, IconButton } from '@material-ui/core'
import { Close } from '@material-ui/icons'
import { Field } from 'react-final-form'
import withStyles from '@material-ui/core/styles/withStyles'
import TextField from '@ui/Fields/TextField'
import Select from '@ui/Fields/Select'
import { irrigation_limitations, pivot_slices, pivot_percentages } from '@models/equipment/irrigation_system_pivot'
import { includesInfo } from '@models/equipment/irrigation'
import styles from '@styles/forms'

const Form = ({ classes, manufacturers, values }) => {
  values.form_type = 'pivot'

  const [isSelect, setSelect] = React.useState({
    manufacturer:
      !values.equipment.manufacturer || includesInfo(manufacturers, 'name', values.equipment.manufacturer),
    percentage_irrigated_areas:
      _.isUndefined(values.equipment.percentage_irrigated_areas) || includesInfo(pivot_percentages, 'id', Number(values.equipment.percentage_irrigated_areas))
  })

  return <Fragment>
    <div className={classes.form__field__wrapper}>
      <Field
        component={Select}
        className={classes.form__fieldWithError}
        InputLabelProps={{ shrink: true, required: true }}
        label={I18n.t('v4/irrigation_system.pivot.irrigation_limitation')}
        name='equipment[irrigation_limitation]'
        margin='normal'
        fields={{ id: 'id', name: 'name' }}
        data={irrigation_limitations}
        fullWidth
        variant='outlined'
        required
      />
    </div>

    <Fragment>
      {
        values.equipment.irrigation_limitation &&
        <div className={classes.form__field__wrapper}>
          <Field
            component={TextField}
            className={classes.form__fieldWithError}
            InputLabelProps={{ shrink: true }}
            label={I18n.t(`v4/irrigation_system.pivot.irrigation_limit_value_label.${values.equipment.irrigation_limitation}`)}
            name='equipment[irrigation_limit_value]'
            margin='normal'
            type='number'
            title={I18n.t('validations.type_a_number')}
            fullWidth
            inputProps={{ step: '0.01', min: '0', max: '100' }}
            variant='outlined'
            required
          />
        </div>
      }

      <div className={classes.form__field__wrapper}>
        <ControlledFormField
          component={Select}
          className={classes.form__fieldWithError}
          InputLabelProps={{ shrink: true, required: true }}
          label={I18n.t('v4/irrigation_system.pivot.manufacturer')}
          name='equipment[manufacturer]'
          margin='normal'
          fields={{ id: 'name', name: 'name' }}
          data={manufacturers}
          fullWidth
          variant='outlined'
          select={isSelect.manufacturer}
          InputProps={{
            endAdornment: !isSelect.manufacturer &&
              <InputAdornment position='end'>
                <IconButton
                  onClick={() => {
                    values.equipment.manufacturer = ''

                    setSelect({ ...isSelect, manufacturer: true })
                  }}
                >
                  <Close />
                </IconButton>
              </InputAdornment>
          }}
          required
        />
      </div>

      <div className={classes.form__field__wrapper}>
        <Field
          component={TextField}
          className={classes.form__fieldWithError}
          InputLabelProps={{ shrink: true }}
          label={`${I18n.t('v4/irrigation_system.pivot.lap_time')} (${I18n.t('hours')})`}
          name='equipment[lap_time]'
          margin='normal'
          type='number'
          title={I18n.t('validations.type_a_number')}
          fullWidth
          inputProps={{ step: '0.01', min: '0', max: '100' }}
          variant='outlined'
          required
        />
      </div>

      <div className={classes.form__field__wrapper}>
        <Field
          component={TextField}
          className={classes.form__fieldWithError}
          InputLabelProps={{ shrink: true }}
          label={`${I18n.t('v4/irrigation_system.pivot.maximum_system_capacity')} (mm)`}
          name='equipment[maximum_system_capacity]'
          margin='normal'
          type='number'
          title={I18n.t('validations.type_a_number')}
          fullWidth
          inputProps={{ step: '0.01', min: '0', max: '100' }}
          variant='outlined'
          required
        />
      </div>

      <div className={classes.form__field__wrapper}>
        <ControlledFormField
          component={Select}
          className={classes.form__fieldWithError}
          InputLabelProps={{ shrink: true, required: true }}
          label={`${I18n.t('v4/irrigation_system.pivot.percentage_irrigated_areas')} (%)`}
          info={I18n.t('v4/irrigation_system.pivot.percentage_irrigated_areas_info')}
          name='equipment[percentage_irrigated_areas]'
          margin='normal'
          type='number'
          title={I18n.t('validations.type_a_number')}
          select={isSelect.percentage_irrigated_areas}
          data={pivot_percentages}
          inputProps={{ step: '0.01', min: '0', max: '100' }}
          fields={{ id: 'id', name: 'name' }}
          onChange={(value) => {
            if (value === 'other' && isSelect.percentage_irrigated_areas) {
              values.equipment.percentage_irrigated_areas = ''

              setSelect({ ...isSelect, percentage_irrigated_areas: false })
            }
          }}
          InputProps={{
            endAdornment: !isSelect.percentage_irrigated_areas &&
              <InputAdornment position='end'>
                <IconButton
                  onClick={() => {
                    values.equipment.percentage_irrigated_areas = ''

                    setSelect({ ...isSelect, percentage_irrigated_areas: true })
                  }}
                >
                  <Close />
                </IconButton>
              </InputAdornment>
          }}
          fullWidth
          variant='outlined'
          required
        />
      </div>

      <div className={classes.form__field__wrapper}>
        <Field
          component={Select}
          className={classes.form__fieldWithError}
          InputLabelProps={{ shrink: true, required: true }}
          label={I18n.t('v4/irrigation_system.pivot.slices')}
          name='equipment[slices]'
          margin='normal'
          fields={{ id: 'id', name: 'name' }}
          data={pivot_slices}
          fullWidth
          variant='outlined'
          required
        />
      </div>

      <div className={classes.form__field__wrapper}>
        <Field
          component={TextField}
          className={classes.form__fieldWithError}
          InputLabelProps={{ shrink: true }}
          label={`${I18n.t('v4/irrigation_system.pivot.pivot_adequacy_degree')} (mm)`}
          info={I18n.t('v4/irrigation_system.pivot.pivot_adequacy_degree_info')}
          name='equipment[pivot_adequacy_degree]'
          margin='normal'
          type='number'
          title={I18n.t('validations.type_a_number')}
          fullWidth
          inputProps={{ step: '0.01', min: '0', max: '100' }}
          variant='outlined'
        />
      </div>

      <div className={classes.form__field__wrapper}>
        <Field
          component={TextField}
          className={classes.form__fieldWithError}
          InputLabelProps={{ shrink: true }}
          label={`${I18n.t('v4/irrigation_system.pivot.irrigation_engine_power')} (cv)`}
          name='equipment[irrigation_engine_power]'
          margin='normal'
          type='number'
          title={I18n.t('validations.type_a_number')}
          fullWidth
          inputProps={{ step: '0.01', min: '0', max: '2000' }}
          variant='outlined'
        />
      </div>
    </Fragment>
  </Fragment>
}

Form.propTypes = {
  action: PropTypes.string
}

Form.defaultProps = {
  manufacturers: []
}

export default withStyles(styles)(Form)
