import React from 'react'
import { Field } from 'react-final-form'

import {
  Divider,
  Grid,
  Typography
} from '@material-ui/core'
import withStyles from '@material-ui/core/styles/withStyles'

import {
  irrigationUnits as units,
  irrigationFieldComponent,
  getIrrigationFieldStates
} from '@models/equipment/irrigation'

import { sendAmplitudeEvent } from '@utils/amplitudeEvent'
import { IntercomAPI } from '@utils/IntercomAPI';

import DatePicker from '@ui/Fields/DatePicker'
import TimePicker from '@ui/Fields/TimePicker'
import SelectField from '@ui/Fields/Select'
import TextField from '@ui/Fields/TextField'

import styles from '@theme/styles/forms'

import InformIrrigation from './InformIrrigation'

const AreaFields = ({ classes, area, equipment, values }) => {
  if (values[`area_${area.uuid}`]) values[`area_${area.uuid}`].areaId = area.id

  const irrigationSystem = area.equipment || equipment

  const irrigationSystemType = irrigationSystem.irrigation_system_type
  const operations = irrigationSystem.data
    ? irrigationSystem.data.operations
    : irrigationSystem.operations

  const fieldStates = getIrrigationFieldStates(values, area, irrigationSystemType)

  const {
    irrigationComponent,
    irrigationProps
  } = irrigationFieldComponent(fieldStates.unitState)

  const dateWithoutUTC = () => {
    var today = new Date()
    return new Date(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0, 0)
  }
  return (
    <div key={`${area.name}-${area.type}-${area.uuid}`} className={classes.IrrigationAreaField__container}>
      <header className={classes.form__header}>
        <Typography variant='h6'>
          {area.name}
          {area.equipment ? ` - ${I18n.t(`enumerize.v4/equipment/irrigation.irrigation_system_type.${irrigationSystemType}`)}` : ''}
        </Typography>
      </header>

      <div className={classes.form__fields}>
        <Grid container spacing={24}>
          <Grid item xs={12} sm={4}>
            <Field
              component={DatePicker}
              margin='normal'
              InputLabelProps={{ shrink: true }}
              variant='outlined'
              label={I18n.t('v4/irrigation.area_fields.irrigation_date')}
              name={`area_${area.uuid}[irrigation_date]`}
              initialValue={dateWithoutUTC()}
              fullWidth
              required
              onFocus={() => {
                sendAmplitudeEvent('Aqua - Inform Depths - Start Date - Used Calendar')
                IntercomAPI('trackEvent', 'Aqua - Inform Depths - Start Date - Used Calendar')
              }}
            />
          </Grid>

          <Grid item xs={12} sm={4}>
            <Field
              component={TimePicker}
              margin='normal'
              InputLabelProps={{ shrink: true }}
              variant='outlined'
              label={I18n.t('v4/irrigation.area_fields.started_at')}
              name={`area_${area.uuid}[started_at]`}
              fullWidth
              required
              onFocus={() => {
                sendAmplitudeEvent('Aqua - Inform Depths - Start Hour - Used Calendar')
                IntercomAPI('trackEvent', 'Aqua - Inform Depths - Start Hour - Used Calendar')
              }} 
          />
          </Grid>

          <Grid item xs={12} sm={4}>
            <Field
              component={TimePicker}
              margin='normal'
              InputLabelProps={{ shrink: true }}
              variant='outlined'
              label={I18n.t('v4/irrigation.area_fields.finished_at')}
              name={`area_${area.uuid}[finished_at]`}
              fullWidth
              onFocus={() => {
                sendAmplitudeEvent('Aqua - Inform Depths - End Hour - Used Calendar ')
                IntercomAPI('trackEvent', 'Aqua - Inform Depths - End Hour - Used Calendar ')
              }}           
              />
          </Grid>


          <Grid item xs={12} sm={4}>
            <Field
              component={SelectField}
              margin='normal'
              InputLabelProps={{ shrink: true }}
              variant='outlined'
              label={I18n.t('v4/irrigation.area_fields.unit')}
              name={`area_${area.uuid}[unit]`}
              fields={{ name: 'name', id: 'id' }}
              data={units(irrigationSystem.irrigation_system_type)}
              fullWidth
              required
              onFocus={() => {
                sendAmplitudeEvent('Aqua - Inform Depths - Unity - Used unity selection')
                IntercomAPI('trackEvent', 'Aqua - Inform Depths - Unity - Used unity selection')
              }}
            /> 
          </Grid>

          <Grid item xs={12} sm={4}>
            <Field
              component={irrigationComponent || TextField}
              margin='normal'
              InputLabelProps={{ shrink: true }}
              variant='outlined'
              label={I18n.t('v4/irrigation.area_fields.irrigation')}
              name={`area_${area.uuid}[irrigation]`}
              {...irrigationProps}
              fullWidth
              required
              onFocus={() => {
                sendAmplitudeEvent('Aqua - Inform Depths - Depth - Used amount seletion')
                IntercomAPI('trackEvent', 'Aqua - Inform Depths - Depth - Used amount seletion')
              }}        
              />
          </Grid>

          <Grid item xs={12} sm={4}>
            {
              irrigationSystemType === 'pivot'
                ? (
                  <Field
                    component={SelectField}
                    margin='normal'
                    InputLabelProps={{ shrink: true }}
                    variant='outlined'
                    label={I18n.t('v4/irrigation.area_fields.sensor_irrigated')}
                    info={I18n.t('v4/irrigation.area_fields.sensor_irrigated_info')}
                    name={`area_${area.uuid}[sensor_irrigated]`}
                    fields={{ name: 'name', id: 'id' }}
                    data={[
                      { id: true, name: I18n.t('true') },
                      { id: false, name: I18n.t('false') }
                    ]}
                    onFocus={() => {
                      sendAmplitudeEvent('Aqua - Inform Depths - Operations - Used options selection')
                      IntercomAPI('trackEvent', 'Aqua - Inform Depths - Operations - Used options selection')
                    }}
                    fullWidth
                    required
                  />
                )
                : (
                  <Field
                    component={TextField}
                    margin='normal'
                    InputLabelProps={{ shrink: true }}
                    variant='outlined'
                    label={I18n.t('v4/irrigation.area_fields.operations')}
                    name={`area_${area.uuid}[operations]`}
                    {...irrigationProps}
                    inputProps={{ min: '0', max: operations }}
                    type='number'
                    fullWidth
                    required
                    onFocus={() => {
                      sendAmplitudeEvent('Aqua - Inform Depths - Operations - Inform Numbers of Operations')
                      IntercomAPI('trackEvent', 'Aqua - Inform Depths - Operations - Inform Numbers of Operations')
                    }}
                  />
                )
            }
          </Grid>

          <InformIrrigation
            fieldStates={fieldStates}
            irrigationSystem={irrigationSystem}
          />

        </Grid>
      </div>
    </div>
  )
}

const AreaFieldStyles = theme => ({
  ...styles(theme),
  IrrigationAreaField__container: {
    marginBottom: theme.spacing.unit
  },
})

export default withStyles(AreaFieldStyles)(AreaFields)
