import PropTypes from 'prop-types'

export const validate = values => {
  const fields = [
    'name', 'brand', 'cultivar'
  ]

  const errors = {}

  fields.forEach(field => {
    if (!values[field]) errors[field] = I18n.t('validations.required')
  })

  return errors
}

export const cultivarHeaders = [
  { title: I18n.t('activerecord.attributes.v4/input.cultivar'), field: 'cultivar' }
]

export const cultivarShape = PropTypes.shape({
  name: PropTypes.string,
  brand: PropTypes.string,
  cultivar: PropTypes.string,
  detail: PropTypes.string,
  kind: PropTypes.string
})
